import React, { Fragment } from 'react';
import CorrectIcon from '@mui/icons-material/CheckCircle';
import { Line } from 'rc-progress';
import { useStores } from '../../../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function AnswersRow({ data, y, totalParticipants }) {
    const { t } = useTranslation('assessment');
    const { brandingStore } = useStores();

    const color = (x) => {
        if (x.value || x.value === 0) {
            let def = brandingStore.getScoreDefinition(x.value);
            if (def) return def.color;
            if (x.value <= -1) return brandingStore.grayColor;
            if (x.value <= 24) return brandingStore.redColor;
            if (x.value <= 49) return brandingStore.orangeColor;
            if (x.value <= 74) return brandingStore.yellowColor;
            if (x.value <= 99) return brandingStore.lightgreenColor;
            return brandingStore.greenColor;
        } else if (x.valid) {
            return x.valid ? brandingStore.greenColor : brandingStore.redColor;
        }
    };

    return (
        <div className="answers-row">
            <span className="answers-row-text">
                {t(data.description)}
                {data.valid && (
                    <Fragment>
                        &nbsp; <CorrectIcon fontSize="small" color="primary" />
                    </Fragment>
                )}
            </span>
            <div className="answers-row-percentage">
                <Line
                    percent={
                        totalParticipants && data.count
                            ? Math.round((100 * data.count) / totalParticipants)
                            : 0
                    }
                    strokeColor={color(data)}
                    style={{ width: '62px', height: '8px' }}
                    strokeWidth="8"
                    trailWidth="8"
                />
                <span>
                    {isNaN(Math.round((100 * data.count) / totalParticipants))
                        ? '0'
                        : Math.round((100 * data.count) / totalParticipants)}
                    %
                </span>
            </div>
        </div>
    );
}

export default observer(AnswersRow);
