import React, { Fragment } from 'react';
import { FlexDiv, TextDefault } from '@awarego/awarego-components';

export default ({
    price,
    futureCredit,
    nextPlanPrice,
    nextBilling,
    my,
    planPeriod,
}) => (
    <Fragment>
        {my ? (
            <FlexDiv column>
                <TextDefault>
                    Current payment:&nbsp;
                    <span className="currency">$</span>
                    <span className="price">{price}</span>
                </TextDefault>
                {futureCredit ? (
                    <TextDefault className="price-description">
                        Credited amount:&nbsp;
                        <span className="currency">$</span>
                        <span className="price">
                            {(futureCredit / 100).toFixed(0)}
                        </span>
                    </TextDefault>
                ) : null}
                {nextBilling ? (
                    <TextDefault className="price-description ">
                        Next billing at: {nextBilling} &nbsp;&nbsp;
                        <span className="currency">$</span>
                        <span className="price">{nextPlanPrice}</span>
                    </TextDefault>
                ) : null}
            </FlexDiv>
        ) : (
            <TextDefault>
                <span className="currency">$</span>
                <span className="price">{price}</span>
                <span className="period">/{planPeriod}</span>
            </TextDefault>
        )}
    </Fragment>
);
