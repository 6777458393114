import RestService from './RestService';

export default class CMI5 extends RestService {
    constructor(parentService, companyId) {
        if (parentService && companyId)
            super(`${parentService.baseUrl}/${companyId}/cmi5`);
        else throw new Error('company is required');
    }

    cmiZipPackage(packageId, options, format) {
        return this.get(
            `${this.baseUrl}/${packageId}/${format || 'scorm2004'}.zip`,
            {},
            options
        );
    }

    deleteEmployeeFromScormAssessment(sessionId) {
        const request = this.del(`${this.baseUrl}/userFromAssessment`, {
            sessionId: sessionId,
        });
        return request;
    }
}
