/* eslint-disable */
import { observable, reaction, makeAutoObservable, runInAction } from 'mobx';
import { extractErrorMessage } from '../utils/helpers';
import { downloadPDF } from '../utils/fileDownload';
import { USER_ROLE } from '../utils/constants';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import services from '../services';
import i18n from 'i18next';
import moment from 'moment';
import { palette } from '@awarego/awarego-components';

class MainStore {
    creatingCertificate = false;
    currentSubjectTextContent = '';
    campaign_id = null;
    showSubjectDoneAnimation = false;
    managerCompanies = null;
    hasConfirmed = false;
    loadingData = false;
    loadingTokens = [];
    loadingScorms = [];
    loadingDashboardData = observable([]);
    loadingCalendarData = false;
    dashboardData = observable([]);
    loadingVideoUrl = false;
    error = undefined;
    trialMessageBar = undefined;
    currentCompany = undefined;
    serverData = undefined;
    companyId = parseInt(window.localStorage.getItem('companyId'));
    language = window.localStorage.getItem('language');
    player = null;
    tokens = [];
    scorms = [];
    uploadingCurrentCompanyLogo = false;
    savingCurrentCompany = false;
    addingManager = false;
    addManagerError = null;
    removeManagerError = null;
    primaryColor = palette.evergreen.aspargus;
    deliveryCalendarMonth = moment().format('YYYY-MM');
    deliveryCalendarDate = moment();
    calendarData = observable([]);
    latestSubjects = observable([]);
    loadingLatestSubjects = false;

    constructor(commonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
        reaction(
            () => this.currentCompany,
            (company) => {
                if (company && company.chargebeeEnabled) {
                    window.Chargebee.init({
                        site: company.chargebeeSite,
                        publishableKey: company.chargebeeClientKey,
                    });
                }
                if (company && company.defaultLanguage) {
                    this.setLanguage(company.defaultLanguage || 'en');
                }
            }
        );

        reaction(
            () => this.player,
            (player) => {
                //console.log("player updated", player)
            }
        );
        reaction(
            () => this.deliveryCalendarMonth,
            () => {
                this.loadCalendarData(this.currentCompany.company_id);
            }
        );
    }

    setAuthStore(authStore) {
        this.authStore = authStore;
    }

    setPartnerStore(partnerStore) {
        this.partnerStore = partnerStore;
    }

    logout = () => {
        this.companies = null;
        this.company = null;
        this.subject = null;
        this.campaign_id = null;
        this.subject = null;
        this.managerCompanies = null;
        this.currentCompany = null;
        this.serverData = null;
        window.localStorage.removeItem('companyId');
    };

    setI18n(i18n) {
        this.i18n = i18n;
    }

    async setLanguage(language_two_letter_code) {
        this.language = language_two_letter_code;
        i18n.changeLanguage(language_two_letter_code);
    }

    setHasConfirmed = (hasConfirmed) => {
        this.hasConfirmed = hasConfirmed;
    };

    setError = (error) => {
        this.error = error;
    };

    setDeliveryCalendarMonth = (date) => {
        this.deliveryCalendarMonth = moment(date).format('YYYY-MM');
    };

    setDeliveryCalendarDate = (date) => {
        this.deliveryCalendarDate = date;
    };

    setPrimaryColor = (color) => {
        this.primaryColor = color;
    };
    resetPrimaryColor = () => {
        this.primaryColor = palette.evergreen.aspargus;
    };

    async setCurrentCompany(company_id) {
        company_id = parseInt(company_id);
        let company = this.serverData.find((x) => x.company_id === company_id);
        if (company) {
            this.currentCompany = company;
            window.localStorage.setItem('companyId', company_id);
            this.companyId = company_id;
            if (company.color_main !== null)
                this.setPrimaryColor(company.color_main);
            if (company.account_id === 1)
                this.setAppcuesInfo(company, this.authStore.currentUser);
        } else {
            if (this.authStore.isPartnerAdmin) {
                await this.partnerStore.loadCompany(company_id);
                company = this.partnerStore.editingCompany;

                if (company) {
                    this.currentCompany = company;
                    window.localStorage.setItem('companyId', company_id);
                    this.companyId = company_id;
                }
            }
        }
    }

    async saveCurrentCompany(values) {
        this.savingCurrentCompany = true;
        try {
            let result = await services.Companies.update({
                id: this.currentCompany.company_id,
                ...values,
            });
            Object.assign(this.currentCompany, result, {
                company_name: result.name,
            });
            return result;
        } catch (e) {
            console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCurrentCompany = false;
        }
    }

    async addManagerToCurrentCompany(data) {
        this.addingManager = true;
        this.addManagerError = null;
        try {
            let result = await services.Companies.managersService(
                this.currentCompany.company_id
            ).create(data);

            runInAction(() => {
                if (this.currentCompany.managers) {
                    this.currentCompany.managers.push(result);
                } else {
                    this.currentCompany.managers = [result];
                }
            });

            return result;
        } catch (e) {
            this.addManagerError = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    async removeManagerFromCurrentCompany(email) {
        this.removeManagerError = null;
        try {
            await services.Companies.managersService(
                this.currentCompany.company_id
            ).delete(email);
            if (this.currentCompany.managers) {
                runInAction(() => {
                    this.currentCompany.managers =
                        this.currentCompany.managers.filter(
                            (manager) => manager.email !== email
                        );
                });
            }
            return true;
        } catch (e) {
            this.removeManagerError = extractErrorMessage(e);
        }
    }

    async uploadCurrentCompanyLogo(formData) {
        this.uploadingCurrentCompanyLogo = true;
        try {
            let result = await services.Resources.addLogo(
                this.currentCompany.company_id,
                formData
            );
            Object.assign(this.currentCompany, result, {
                logo_name: result.logo_name,
            });
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingCurrentCompanyLogo = false;
        }
    }

    async removeCurrentCompanyLogo() {
        try {
            let result = await services.Resources.removeLogo(
                this.currentCompany.company_id
            );
            Object.assign(this.currentCompany, result, { logo_name: null });
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async downloadPreviewCertificate(companyId, name, token) {
        if (this.creatingCertificate) return;
        this.creatingCertificate = true;
        try {
            const response = await services.PreviewCertificate.download({
                name,
                token,
                companyId,
            });

            downloadPDF(
                response,
                `Preview certificate - ${this.currentCompany.company_name}.pdf`
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.creatingCertificate = false;
        }
    }

    get currentRole() {
        return (
            (this.currentCompany && this.currentCompany.role) ||
            USER_ROLE.VIEWER
        );
    }

    get isAdmin() {
        return this.currentRole === USER_ROLE.ADMIN;
    }

    get isTrial() {
        return this.currentCompany && this.currentCompany.trial_ends;
    }

    get ssoEnabled() {
        return (
            this.currentCompany &&
            this.currentCompanyUISettings['ui.sso.configured']
        );
    }

    get assessmentManualScheduleEnabled() {
        return (
            this.currentCompany &&
            this.currentCompanyUISettings['ui.assessment_manual_schedule'] ===
                '1'
        );
    }

    get haveValidPackage() {
        return (
            this.currentCompany &&
            ((this.currentCompany.subscription &&
                this.currentCompany.subscription.package_valid) ||
                (this.currentCompany.packages &&
                    this.currentCompany.packages.some((p) => p.valid)))
        );
    }

    /** Is the current company a partner company? */
    get havePartnerSubscription() {
        return (
            this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_type === 'partner'
        );
    }

    /** Is the current company a partner company? */
    get haveSubscriptionDefinition() {
        return (
            this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_definition !== null
        );
    }

    get subscriptionBillingPeriod() {
        return this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.billingPeriod === 'year'
            ? 'Yearly'
            : 'Monthly';
    }

    get isTrialOnly() {
        return this.isTrial;
    }

    async loadDashboardData(companyId) {
        this.loadingDashboardData = true;
        try {
            let result = await services.Companies.dashboardData(companyId);
            if (result) this.dashboardData = result;
        } catch (e) {
            console.error(e.stack);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingDashboardData = false;
        }
    }

    async loadCalendarData(companyId, options) {
        this.loadingCalendarData = true;
        try {
            let result = await services.Companies.dashboardCalendarData(
                companyId,
                this.deliveryCalendarMonth,
                options
            );
            runInAction(() => {
                this.calendarData.replace(result);
                this.loadingCalendarData = false;
                // select first scheduled event in the month if the event is in the future
                if (
                    this.calendarData[0] &&
                    moment().isBefore(this.calendarData[0].date)
                )
                    this.setDeliveryCalendarDate(this.calendarData[0].date);
            });
            if (result) {
            }
        } catch (e) {
            console.error(e.stack);
            this.setError(extractErrorMessage(e));
        }
    }

    async loadLatestSubjects() {
        this.loadingLatestSubjects = true;
        try {
            let result = await services.Subjects.getLatestSubjects();
            if (result) this.latestSubjects.replace(result);
        } catch (e) {
            console.error(e.stack);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingLatestSubjects = false;
        }
    }

    async loadServerData() {
        this.loadingData = true;
        this.setError(null);
        try {
            let companies = await services.Companies.myCompanies();
            this.setServerData(companies);

            if (companies.length > 0) {
                //switching context, so local storage companyId no more valid

                if (
                    this.companyId &&
                    !companies.find((x) => x.company_id === this.companyId)
                ) {
                    //Remove companyId from localStorage if the user is not a partner admin
                    if (!this.authStore.isPartnerAdmin) {
                        window.localStorage.removeItem('companyId');
                        this.companyId = null;
                    } else {
                        //Remove companyId from localStorage if the partner admin does not 'own' the current company (split from the check above for performance reasons)
                        await this.partnerStore.loadCompany(this.companyId);
                        if (!this.partnerStore.editingCompany) {
                            window.localStorage.removeItem('companyId');
                            this.companyId = null;
                        }
                    }
                }

                await this.setCurrentCompany(
                    this.companyId || companies[0].company_id
                );
            } else {
                // User doesn't own any companies, but if he is partner admin he might be viewing one if its companies
                if (this.companyId && this.authStore.isPartnerAdmin) {
                    //Remove companyId from localStorage if the partner admin does not 'own' the current company (split from the check above for performance reasons)
                    await this.partnerStore.loadCompany(this.companyId);
                    if (!this.partnerStore.editingCompany) {
                        window.localStorage.removeItem('companyId');
                        this.companyId = null;
                    } else {
                        await this.setCurrentCompany(this.companyId);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingData = false;
        }
    }

    setServerData = (data) => {
        //for backward compatibility right now
        this.serverData = data;
    };

    resetVideoTokens() {
        this.tokens.replace([]);
    }

    async videoToken(videoId, language) {
        if (this.loadingTokens.includes(videoId)) return false;

        this.loadingTokens.push(videoId);
        try {
            let token = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getVideoToken(videoId, language); //videoId

            let t = this.tokens.find((x) => x.id === videoId);
            if (t) this.tokens.remove(t);
            this.tokens.push({ id: videoId, token, language });
        } finally {
            this.loadingTokens.remove(videoId);
        }
    }

    async scormInfo(subjectId, language) {
        if (this.loadingScorms.includes(subjectId)) return false;

        this.loadingScorms.push(subjectId);
        try {
            let response = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getScormInfo(subjectId, language);
            let url = null;

            if (typeof response === 'object') {
                url = response.url;
                const domain = response.domain || window.location.host;
                const path = response.path || '/scorm';
                Object.keys(response.signedCookies).forEach((key) => {
                    document.cookie = `${key}=${response.signedCookies[key]}; path=${path}; domain=${domain};`;
                });
            } else url = response;

            let t = this.scorms.find((x) => x.id === subjectId);
            if (t) this.scorms.remove(t);
            this.scorms.push({ id: subjectId, url, language });
        } finally {
            this.loadingScorms.remove(subjectId);
        }
    }

    async loadVideoPlayerUrl() {
        if (this.loadingVideoUrl) return false;
        this.player = null;
        this.loadingVideoUrl = true;
        try {
            this.player = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getVideoPlayerUrl();
        } catch (e) {
        } finally {
            this.loadingVideoUrl = false;
        }
    }

    get videoPlayerUrl() {
        if (
            this.player &&
            this.player.url &&
            this.player.exp > new Date().getTime()
        )
            return this.player.url;
        return null;
    }

    get tokenIndex() {
        return keyBy(this.tokens, 'id');
    }

    get scormIndex() {
        return keyBy(this.scorms, 'id');
    }

    get assessmentDashboardEnabled() {
        return (
            this.currentCompany &&
            this.currentCompanyUISettings['ui.assessment_dashboard'] === '1'
        );
    }

    get recommendationsEnabled() {
        return (
            this.currentCompany &&
            this.currentCompanyUISettings['ui.assessment.recommendations'] ===
                '1'
        );
    }

    get currentCompanyUISettings() {
        if (!this.currentCompany) return {};

        return (
            this.currentCompany.uiSettings || this.currentCompany.settings || {}
        );
    }

    get assessmentEnabled() {
        if (!this.currentCompany) return false;
        //Enable for all non partner companies with assessment enabled through settings
        if (this.currentCompanyUISettings['ui.assessments'] === '0')
            return false;
        //For partners show/hide assements tab.
        if (this.havePartnerSubscription) {
            if (
                this.currentCompanyUISettings['ui.always_show_assessments'] ===
                '1'
            )
                return true;
            else {
                return (
                    this.haveSubscriptionDefinition && // Is a new subsription type, nevermind partner or standalone company that...
                    (this.hasLegacyAccess('assessmentQuestions') || // ...was once enabled through subscription
                        this.hasAccess('assessmentQuestions')) // ...is currently enabled through subscription
                );
            }
        }

        //for regular companies always show assements tab, and show upsell there.
        else return true;
    }

    get trainingEnabled() {
        if (!this.currentCompany) return false;
        if (this.havePartnerSubscription)
            return (
                this.haveSubscriptionDefinition && // Is a partner company that...
                (this.hasLegacyAccess('trainingSubjects') || // ...was once enabled through subscription
                    this.hasAccess('trainingSubjects')) // ...is currently enabled through subscription
            );
        //for regular companies always show trainings tab, and show upsell there.
        return true;
    }

    /** Is the assessment feature unavailable to this partner company? */
    get assessmentsUnavailable() {
        if (!this.currentCompany) return true;
        if (this.havePartnerSubscription && !this.haveValidPackage) return true; //Is partner company with invalid subscription
        return !(
            (
                this.haveSubscriptionDefinition && // Is a partner company that...
                (this.hasLegacyAccess('assessmentQuestions') || // ...was once enabled through subscription
                    this.hasAccess('assessmentQuestions'))
            ) // ...is currently enabled through subscription
        );
    }

    /** upgrade allowed only for standalone company && if not yet purchased */
    get assessmentsUpgradeAllowed() {
        return this.assessmentsUnavailable && !this.havePartnerSubscription;
    }

    /** upgrade allowed only for standalone company && if not yet purchased */
    get trainingUpgradeAllowed() {
        return (
            this.trainingProgramsUnavailable && !this.havePartnerSubscription
        );
    }

    /** Is the training program feature unavailable to this partner company? */
    get trainingProgramsUnavailable() {
        if (!this.currentCompany) return true;
        if (!this.haveValidPackage) return true; //Is partner company with invalid subscription
        return !(
            (
                this.haveSubscriptionDefinition && // Is a partner company that...
                (this.hasAccess('trainingSubjects') || // ...does not have access enabled through subscription
                    this.hasLegacyAccess('trainingSubjects'))
            ) // ...was once enabled through subscription
        );
    }

    hasAccess(arrayPropertyName) {
        return (
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_definition &&
            this.currentCompany.subscription.subscription_definition[
                arrayPropertyName
            ] &&
            this.currentCompany.subscription.subscription_definition[
                arrayPropertyName
            ].length > 0
        );
    }

    hasLegacyAccess(arrayPropertyName) {
        return (
            this.currentCompany.subscription.allowedItems &&
            this.currentCompany.subscription.allowedItems[arrayPropertyName] &&
            this.currentCompany.subscription.allowedItems[arrayPropertyName]
                .length > 0
        );
    }

    get slackAvailable() {
        return this.isIntegrationAvailable('slack');
    }

    get teamsAvailable() {
        return this.isIntegrationAvailable('teams');
    }

    isIntegrationAvailable(integrationName) {
        //Available for all non partner companies
        if (!this.havePartnerSubscription) return true;
        return (
            this.havePartnerSubscription &&
            this.currentCompany.subscription.subscription_definition &&
            (this.currentCompany.subscription.subscription_definition
                .integrations === 'all' ||
                this.currentCompany.subscription.subscription_definition.integrations.indexOf(
                    integrationName
                ) > -1)
        );
    }

    get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    get languages() {
        return this.commonStore.languages.filter(
            (x) =>
                this.currentCompany.languages &&
                this.currentCompany.languages.find((l) => l.code === x.code)
        );
    }

    get languagesCodes() {
        return this.languages.map((x) => x.code);
    }

    get daysWithEventsInSelectedMonth() {
        return this.calendarData
            .filter(
                (e) =>
                    new Date(e.date).getMonth() ===
                    new Date(this.deliveryCalendarMonth).getMonth()
            )
            .map((e) => moment(e.date).format('YYYY-MM-DD'));
    }

    get displayedCalendarEvents() {
        if (!this.loadingCalendarData && this.calendarData.length > 0) {
            let events = this.calendarData
                .map((e) => ({
                    ...e,
                    id: `${moment(e.date).format('YYYY-MM-DD')}`,
                    date: new Date(e.date),
                }))
                .filter(
                    (e) =>
                        e.date.getMonth() ===
                        new Date(this.deliveryCalendarMonth).getMonth()
                );

            // show an "empty" event if there's nothing scheduled on selected date
            if (
                !this.daysWithEventsInSelectedMonth.includes(
                    moment(this.deliveryCalendarDate).format('YYYY-MM-DD')
                )
            ) {
                events.push({
                    name: 'No events scheduled',
                    date: new Date(this.deliveryCalendarDate),
                    id: `${moment(this.deliveryCalendarDate).format(
                        'YYYY-MM-DD'
                    )}`,
                });
                return orderBy(events, 'date');
            }
            return events;
        } else {
            return [];
        }
    }

    get cmi5enabled() {
        return (
            this.currentCompany &&
            this.currentCompanyUISettings['ui.cmi5.enabled'] === '1'
        );
    }

    timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
    }

    async setAppcuesInfo(company, user) {
        // Set Appcues
        await this.timeout(15000);

        window.Appcues.identify(
            company.company_uid, // unique, required
            {
                // recommended (optional) properties

                createdAt: moment(company.created).valueOf(), // Unix timestamp of user signup date
                //purchasedAd: null, // Unix timestamp of account purchase date (leave null if empty)
                planTier: company.trial_ends ? 'Trial' : 'Standard', // Current user’s plan tier
                //role: 'Admin', // Current user’s role or permissions
                //accountId: '1234', // Current user's account ID
                firstName: user.name, // current user's first name

                // additional suggestions

                companyName: company.name, // Current user’s company name
                email: user.email, // Current user's email
                //location: '90210', // a zipcode, state, or country enables location-based targeting
                //version: '2.0', // users on different versions may need to see different content
                //language: 'spanish', // for multi-language applications
                //renewalDate: 1577880288, // to remind users to renew
            }
        );
    }
}

export default MainStore;
