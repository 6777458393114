import Auth from './AuthService';
import Users from './UsersService';
import Subjects from './SubjectsService';
import Companies from './CompaniesService';
import Categories from './CategoriesService';
import Templates from './TemplatesService';
import Questions from './QuestionsService';
import Automations from './AutomationsService';
import System from './SystemService';
import Packages from './PackagesService';
import Mails from './MailsService';
import Freemiums from './FreemiumsService';
import Resources from './ResourcesService';
import Branding from './BrandingService';
import Partners from './PartnersService';
import Notifications from './NotificationsService';
import Videos from './VideosService';
import Infosec from './InfosecService';
import LanguageService from './LanguageService';
import ACLService from './acl';
import AnalyticsService from './AnalyticsService';
import PreviewCertificateService from './PreviewCertificateService';
import ReportsService from './ReportsService';
import ThreatAreasService from './ThreatAreasService';

export default {
    Auth: new Auth(),
    Users: new Users(),
    Subjects: new Subjects(),
    Companies: new Companies(),
    Categories: new Categories(),
    Templates: new Templates(),
    Questions: new Questions(),
    Automations: new Automations(),
    Packages: new Packages(),
    System: new System(),
    Mails: new Mails(),
    Freemiums: new Freemiums(),
    Resources: new Resources(),
    Branding: new Branding(),
    Partners: new Partners(),
    Videos: new Videos(),
    Infosec: new Infosec(),
    Languages: new LanguageService(),
    Notifications: new Notifications(),
    ACL: new ACLService(),
    Analytics: new AnalyticsService(),
    PreviewCertificate: new PreviewCertificateService(),
    Reports: new ReportsService(),
    ThreatAreas: new ThreatAreasService(),
};
