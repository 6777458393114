import React, { useMemo, useEffect } from 'react';
import {
    Block,
    Heading3,
    palette,
    TextTiny,
} from '@awarego/awarego-components';
import SemiCircleProgress from '../../../../components/manager/assessment/semi-circle-progress';
import { Link } from 'react-router-dom';
import {
    replaceSpaceWithUnderscore,
    enforceSlash,
    joinPath,
} from '../../../../utils/helpers';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import TableClient from '../../../../components/table/TableClient';
import StyledLink from '../../../../components/StyledLink';

function ThreatAreasTable({ assessmentId, companyId, hasStarted }) {
    const { assessmentStore } = useStores();
    const { threatAreasList, loadingThreatAreasList } = assessmentStore;
    useEffect(() => {
        assessmentStore.loadThreatAreasList(companyId, assessmentId);
    }, [assessmentId, assessmentStore, companyId, threatAreasList]);



    const columns = useMemo(
        () => [
            {
                Header: 'Threat area',
                accessor: 'area',
                Cell: (x) => (
                    <StyledLink
                        to={enforceSlash(
                                replaceSpaceWithUnderscore(x.value)
                        )}
                        $hoverColor={palette.graphite.onyx}
                        $underlined={true}
                    >
                        {x.value}
                    </StyledLink>
                ),
            },
            {
                Header: '# Scenarios',
                accessor: 'questionsCount',
            },
            {
                Header: 'Weakest key behavior',
                accessor: 'weakestKeyBehaviour.name',
                Cell: (x) => (hasStarted ? x.value : 'N/A'),
            },
            {
                Header: 'Average score',
                accessor: 'averageScore',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        width={48}
                        top={12}
                        halfCircle
                        percentageText
                        showNA={!hasStarted}
                    />
                ),
            },
            {
                id: 'details',
                accessor: 'id',
                Cell: (x) => (
                    <TextTiny themed bold link>
                        <Link
                            to={replaceSpaceWithUnderscore(x.row.original.area)}
                        >
                            See details
                        </Link>
                    </TextTiny>
                ),
            },
        ],
        [hasStarted]
    );

    return (
        <Block gap={24}>
            <Heading3 ml={16}>
                Threat areas{' '}
                {threatAreasList.length > 0 && `(${threatAreasList.length})`}
            </Heading3>
            {loadingThreatAreasList ? (
                <ThemedSpinner />
            ) : (
                <TableClient
                    data={threatAreasList}
                    columns={columns}
                    hidePagination
                    defaultSortBy={hasStarted ? 'averageScore' : 'area'}
                />
            )}
        </Block>
    );
}

export default observer(ThreatAreasTable);
