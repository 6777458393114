import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';
import { AICC, Scorm12API } from 'scorm-again';

const errorString = {
    0: 'No error',
    101: 'General exception',
    201: 'Invalid argument error',
    202: 'Element cannot have children',
    203: 'Element not an array - cannot have count',
    301: 'Not initialized',
    401: 'Not implemented error',
    402: 'Invalid set value, element is a keyword',
    403: 'Element is read only',
    404: 'Element is write only',
    405: 'Incorrect data type',
};

class Scorm2004API {
    constructor(commitHandler, finishHandler) {
        this.cmi = {
            'cmi.learner_id': 'mogadanez@gmail.com',
            'cmi.learner_name': 'mogadanez@gmail.com',
        };
        // if (window.localStorage.cmi) {
        //     try {
        //         this.cmi = JSON.parse(window.localStorage.cmi);
        //         delete this.cmi['cmi.location'];
        //     } catch (e) {}
        // }

        if (!this.cmi)
            this.cmi = {
                'cmi.learner_id': 'mogadanez@gmail.com',
                'cmi.learner_name': 'mogadanez@gmail.com',
            };
        this.errorCode = '0';
        this.commitHandler = commitHandler;
        this.finishHandler = finishHandler;
    }

    Initialize() {
        console.log('LMSInitialize', arguments);
        this.errorCode = '0';
        return 'true';
    }

    Terminate() {
        console.log('LMSFinish', arguments);
        this.errorCode = '0';
        this.finishHandler();
        return 'true';
    }

    GetValue(element) {
        console.log('LMSGetValue', element);

        let value = this.cmi[element];

        return value ? value : '';
    }

    SetValue(element, value) {
        if (element === 'cmi.location') {
            console.log('LMSSetValue', element, value);
        }
        this.cmi[element] = value;
        return 'true';
    }

    Commit() {
        console.log('LMSCommit', arguments);
        this.commitHandler(this.cmi);
        return 'true';
    }

    GetLastError() {
        console.log('LMSGetLastError', arguments);
        return this.errorCode;
    }

    GetErrorString(param) {
        console.log('LMSGetErrorString');
        return param !== '' ? errorString[param] : '';
    }

    GetDiagnostic() {
        console.log('LMSGetDiagnostic', arguments);
        return '';
    }

    init(cmi) {
        console.log('init', arguments);
        this.cmi = cmi;
    }

    LMSInitialize() {
        return this.Initialize();
    }

    LMSFinish() {
        return this.Terminate();
    }

    LMSGetValue(element) {
        return this.GetValue(element);
    }

    LMSSetValue(element, value) {
        return this.SetValue(element, value);
    }

    LMSCommit() {
        return this.Commit();
    }

    LMSGetLastError() {
        return this.GetLastError();
    }

    LMSGetErrorString() {
        return this.LMSGetErrorString();
    }

    LMSGetDiagnostic() {
        return this.GetDiagnostic();
    }

    get LearnerId() {
        return this.cmi['cmi.learner_id'];
    }

    get LearnerName() {
        return this.cmi['cmi.learner_name'];
    }
}

function createAPI() {
    window.API_1484_11 = new Scorm2004API(
        (data) => {
            //window.localStorage.cmi = JSON.stringify(data);
            console.log('commit');
        },
        () => {
            console.log('finish');
        }
    );
    var settings = {};

    //    window.API = new Scorm12API(settings);
    window.API = window.API_1484_11;
}

function SCORMPreviewContainer({
    previewScormLocation,
    language,
    definition,
    subject,
    xapi,
}) {
    const { store, authStore } = useStores();
    const { scormIndex } = store;
    const { currentUser, token } = authStore;

    useEffect(() => {
        createAPI();
        return () => {
            delete window.API_1484_11;
            delete window.API;
        };
    }, []);

    const api = window.API_1484_11;
    const api12 = window.API;

    const loadData = useCallback(async () => {
        if (
            previewScormLocation &&
            (!scormIndex[previewScormLocation] ||
                !scormIndex[previewScormLocation].language !== language)
        ) {
            await store.scormInfo(previewScormLocation, language);
        }
    }, [previewScormLocation, language]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (api) {
            api.SetValue('cmi.learner_id', 'mogadanez@gmail.com');
            api.SetValue('cmi.learner_name', currentUser.name);
            api.SetValue('cmi.core.student_id', 'mogadanez@gmail.com');
            api.SetValue('cmi.core.student_name', currentUser.name);
        }
    }, [currentUser, api]);

    let scormData = scormIndex[previewScormLocation];

    let actor = {
        name: [currentUser.name || currentUser.email],
        account: [{ accountName: currentUser.email }],
        objectType: 'Agent',
    };

    let endpoint = `${import.meta.env.REACT_APP_API_URL}/xapi/preview`;
    let auth = `JWT ${token}`;
    const width = useMemo(() => {
        if (definition && definition.width) {
            return definition.width;
        }
        return 800;
    });
    const height = useMemo(() => {
        if (definition && definition.height) {
            return definition.height;
        }
        return 600;
    });

    let url =
        scormData &&
        (xapi
            ? `${scormData.url}?actor=${JSON.stringify(
                  actor
              )}&endpoint=${endpoint}&auth=${auth}&width=${width}&height=${height}`
            : scormData.url);

    return (
        <div>
            {scormData && (
                <iframe
                    frameBorder={0}
                    title={'SCORM'}
                    width={width}
                    height={height}
                    src={url}
                ></iframe>
            )}
        </div>
    );
}

export default observer(SCORMPreviewContainer);
