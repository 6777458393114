import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useStores } from '../../../hooks/use-stores';
import ErrorMessage from '../../../components/errormessage';
import { Helmet } from 'react-helmet';
import SingleListTable from './SingleListTable';
import SingleListHeader from './SingleListHeader';
import { Block } from '@awarego/awarego-components';
import UsersAddModal from '../modals/UsersAddModal';
import CreateEditListModal from '../modals/CreateEditListModal';

function SingleListContainer() {
    const { listStore, userStore, store } = useStores();
    const { currentCompany } = store;
    const { listId } = useParams();
    const [isAddEmployeesModalOpen, setIsAddEmployeesModalOpen] =
        useState(false);
    const [isEditListModalOpen, setIsEditListModalOpen] = useState(false);
    const rowsPerPage = 10;

    let createDisabled = !currentCompany || !currentCompany.canAddUsers;
    useEffect(() => {
        userStore.setQueryContext({
            n: rowsPerPage,
            p: 0,
            order: 'name asc',
            filter: '',
        });
    }, []);

    useEffect(() => {
        loadList();
    }, [listId, currentCompany]);

    useEffect(() => {
        loadUsers();
    }, [
        listId,
        currentCompany,
        userStore.pageIndex,
        userStore.pageSize,
        userStore.order,
    ]);

    const loadList = useCallback(async () => {
        await listStore.loadSingleList(currentCompany.company_id, listId);
    }, [listId, currentCompany]);

    const loadUsers = useCallback(async () => {
        await userStore.loadUsersByListId(
            currentCompany.company_id,
            listId,
            userStore.usersSearchedValue?.value_
        );
    }, [
        listId,
        currentCompany,
        userStore.pageIndex,
        userStore.pageSize,
        userStore.order,
    ]);

    useEffect(() => {
        loadUsers();
    }, [userStore.usersAdded, userStore.usersDeleted]);

    useEffect(() => {
        // Cleanup run on component unmount
        return () => {
            listStore.error = undefined;
            listStore.editingList = undefined;
            listStore.loadingList = false;
            listStore.list = null;
            userStore.usersFiltered = 0;
            userStore.usersByList = [];
            userStore.error = undefined;
            userStore.setQueryContext({});
            userStore.usersSearchedValue = null;
        };
    }, []);

    return (
        <Container className="manager-content">
            <Helmet>
                <title>List</title>
            </Helmet>
            <Link to={`/users`}>
                <div className={'navigation back-button'}>Back to lists</div>
            </Link>

            <Block gap={24} tablePadding>
                {listStore.error && <ErrorMessage error={listStore.error} />}
                <SingleListHeader
                    currentCompany={currentCompany}
                    createDisabled={createDisabled}
                    openAddEmployeesModal={setIsAddEmployeesModalOpen}
                    openEditListModal={setIsEditListModalOpen}
                    listId={listId}
                />
                <SingleListTable
                    currentCompany={currentCompany}
                    createDisabled={createDisabled}
                    listId={listId}
                    rowsPerPage={rowsPerPage}
                />
                {listStore.list && (
                    <UsersAddModal
                        isModalOpen={isAddEmployeesModalOpen}
                        list={listStore.list}
                        users={userStore.usersByList}
                        companyId={currentCompany.company_id}
                        onRequestClose={() => setIsAddEmployeesModalOpen(false)}
                        isImportingLists={false}
                    />
                )}
                {listStore.list && (
                    <CreateEditListModal
                        companyId={currentCompany.company_id}
                        list={listStore.list}
                        isModalOpen={isEditListModalOpen}
                        onRequestClose={() => setIsEditListModalOpen(false)}
                    />
                )}
            </Block>
        </Container>
    );
}

export default observer(SingleListContainer);
