import React from 'react';
import { FlexDiv, TextTiny, Heading3 } from '@awarego/awarego-components';
import { observer } from 'mobx-react';

function UserStatusText({
    preTitle,
    headingText,
    firstDetails,
    secondDetails,
}) {
    return (
        <>
            <FlexDiv>
                <TextTiny lighter bold>
                    {preTitle}
                </TextTiny>
            </FlexDiv>
            <FlexDiv>
                <Heading3>{headingText}</Heading3>
            </FlexDiv>
            {firstDetails ? (
                <FlexDiv>
                    <TextTiny lighter semiBold>
                        {firstDetails}
                    </TextTiny>
                </FlexDiv>
            ) : null}
            {secondDetails ? (
                <FlexDiv>
                    <TextTiny lighter semiBold>
                        {secondDetails}
                    </TextTiny>
                </FlexDiv>
            ) : null}
        </>
    );
}

export default observer(UserStatusText);
