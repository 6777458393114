import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import HeaderSection from '../../../components/header/HeaderSection';
import {
    TextLead,
    ThemedButton,
    CheckboxInput,
    FlexDiv,
    TextTiny,
} from '@awarego/awarego-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress } from '@mui/material';
import { useStores } from '../../../hooks/use-stores';
import SubHeaderComponentsList from '../../../components/header/SubHeaderComponentsList';
function EmployeesListsHeader({
    listsTotal,
    companyId,
    assessmentId,
    threatArea,
}) {
    const {
        assessmentEmployeesStore,
        assessmentEmployeeListsStore,
        assessmentStore,
    } = useStores();

    const createHeading = () => (
        <TextLead bold>
            Lists {listsTotal > 0 ? `(${listsTotal})` : '(0)'}
        </TextLead>
    );

    const createButtons = () => (
        <>
            {assessmentStore.editingAssessment.canRemind && (
                <ThemedButton
                    mode="secondary"
                    size="tiny"
                    $noTextTransform
                    disabled={
                        assessmentEmployeeListsStore
                            .clientSidePaginationWithSelect
                            .selectedCountOnAllPages === 0
                    }
                    onClick={() => {
                        assessmentEmployeesStore.showRemindersModal(
                            false,
                            'list',
                            assessmentEmployeeListsStore.clientSidePaginationWithSelect.selectedLists.map(
                                (x) => x.listId
                            )
                        );
                    }}
                    text="Send reminders"
                />
            )}
            <ThemedButton
                mode="secondary"
                size="tiny"
                $noTextTransform
                disabled={
                    assessmentEmployeeListsStore.clientSidePaginationWithSelect
                        .selectedCountOnAllPages === 0
                }
                onClick={handleDownloadCSV}
                text={
                    <>
                        {assessmentEmployeeListsStore.isDownloading && (
                            <CircularProgress size={10} />
                        )}{' '}
                        Download .csv
                        <GetAppIcon fontSize="small" />
                    </>
                }
            />
        </>
    );

    const handleDownloadCSV = () => {
        assessmentEmployeeListsStore.downloadCSV(companyId, assessmentId, {
            threatArea,
        });
    };

    const handleSelectAll = (event) => {
        assessmentEmployeeListsStore.selection.toggleCurrentPageSelected(
            event.target.checked
        );
    };

    const handleClearSelection = () => {
        assessmentEmployeeListsStore.selection.clearSelection();
    };

    const createSubheadings = () => {
        return (
            <SubHeaderComponentsList>
                <Fragment>
                    <CheckboxInput
                        label={checkboxInputLabel}
                        indeterminate={
                            assessmentEmployeeListsStore.selection
                                .currentPageSelected &&
                            assessmentEmployeeListsStore.selection
                                .haveDeselectedOnCurrentPage
                        }
                        checked={
                            (assessmentEmployeeListsStore.selection
                                .allSelected &&
                                !assessmentEmployeeListsStore.selection
                                    .currentPageDeSelected) ||
                            assessmentEmployeeListsStore.selection
                                .currentPageSelected
                        }
                        onChange={handleSelectAll}
                    />

                    {assessmentEmployeeListsStore.selection.allSelected && (
                        <FlexDiv column>
                            <TextTiny>
                                {assessmentEmployeeListsStore.selection
                                    .haveDeselectedUsers
                                    ? `${assessmentEmployeeListsStore.clientSidePaginationWithSelect.selectedCountOnAllPages} lists selected on all pages`
                                    : `All ${listsTotal} lists selected.`}
                            </TextTiny>
                            <TextTiny
                                themed
                                bold
                                link
                                onClick={handleClearSelection}
                            >
                                Clear selection
                            </TextTiny>
                        </FlexDiv>
                    )}
                </Fragment>
            </SubHeaderComponentsList>
        );
    };

    const checkboxInputLabel = useMemo(() => {
        if (
            assessmentEmployeeListsStore.clientSidePaginationWithSelect
                .selectedCountOnAllPages === 0 ||
            listsTotal ===
                assessmentEmployeeListsStore.clientSidePaginationWithSelect
                    .selectedCountOnAllPages
        ) {
            return `Select all (${listsTotal})`;
        } else {
            return `Selected (${assessmentEmployeeListsStore.clientSidePaginationWithSelect.selectedCountOnAllPages}/${listsTotal})`;
        }
    }, [
        listsTotal,
        assessmentEmployeeListsStore.clientSidePaginationWithSelect
            .selectedCountOnAllPagess,
        assessmentEmployeeListsStore.selection.effectiveSelectionOnCurrentPage,
    ]);

    return (
        <HeaderSection
            heading={createHeading()}
            searchPlaceholder={'Search lists'}
            searchValue={
                assessmentEmployeeListsStore.clientSidePaginationWithSelect
                    .searchValue
            }
            onSearch={(e) =>
                assessmentEmployeeListsStore.clientSidePaginationWithSelect.setSearchValue(
                    e.target.value
                )
            }
            onClearSearch={() =>
                assessmentEmployeeListsStore.clientSidePaginationWithSelect.setSearchValue(
                    ''
                )
            }
            subHeadings={createSubheadings()}
            excludeBlock
            marginLeft="16"
            buttons={createButtons()}
        />
    );
}

export default observer(EmployeesListsHeader);
