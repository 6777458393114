import { makeAutoObservable } from 'mobx';
import services from '../services';
import { generateLoadList } from '../utils/mobx';

class VideosStore {
    videos = [];
    loadingVideos = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setError(error) {
        this.error = error;
    }

    loadVideos = generateLoadList(
        'loadVideos',
        this,
        'loadingVideos',
        (tag) => {
            return services.Videos.fetch(tag);
        },
        'videos'
    );
}

export default VideosStore;
