import React, { useState } from 'react';
import {
    FlexDiv,
    Block,
    BigModal,
    ThemedButton,
    TextTiny,
    palette,
} from '@awarego/awarego-components';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStores } from '../../../hooks/use-stores';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
        },
    },
}));

function EditUserModal({
    isModalOpen,
    employee,
    companyId,
    modalStateSetter,
    employeeEditedSetter,
}) {
    const classes = useStyles();
    const { userStore } = useStores();
    const [newEmail, setNewEmail] = useState(null);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const onSave = async (event) => {
        event.preventDefault();
        try {
            const updatedName = event.target.elements[0].value;
            const updatedEmail = event.target.elements[2].value;

            let updatedEmployee = {};

            if (updatedName !== employee.name) {
                updatedEmployee.listsId = employee.listsId;
                updatedEmployee.name = updatedName;
            }

            if (
                updatedEmail !== employee.email &&
                updatedEmail != '' &&
                updatedEmail != null
            ) {
                updatedEmployee.email = updatedEmail;
            }
            await userStore.update(companyId, employee.email, updatedEmployee);
            employeeEditedSetter({
                type: 'success',
                data: { ...updatedEmployee, id: employee.id },
            });
        } catch (e) {
            let message = null;
            if (e.response && e.response.data && e.response.data.error) {
                message = e.response.data.error;
            }
            employeeEditedSetter({ type: 'error', data: message });
        }
    };

    return (
        <BigModal
            isOpen={isModalOpen}
            onRequestClose={() => modalStateSetter(false)}
            heading="Edit User"
            modalContent={
                <form onSubmit={onSave}>
                    <Block style={{ border: 'none', margin: 0, padding: 0 }}>
                        <TextField
                            id="employee-name"
                            label="Name"
                            variant="outlined"
                            margin="normal"
                            className={classes.root}
                            defaultValue={employee.name}
                        />
                        <TextField
                            id="employee-email"
                            label="Work Email Address"
                            variant="outlined"
                            margin="normal"
                            defaultValue={employee.email}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                        {newEmail && !emailPattern.test(newEmail) && (
                            <TextTiny
                                color={palette.vibrant.red}
                                ml={13}
                                mt={-13}
                                bold
                            >
                                Invalid email format
                            </TextTiny>
                        )}
                    </Block>
                    <FlexDiv
                        maxHeight={96}
                        dropShadowAbove
                        justifyEnd
                        alignCenter
                        gap={16}
                        px={48}
                        py={24}
                        ml={-48}
                        mr={-48}
                        mb={-32}
                        mt={32}
                        style={{ maxWidth: 'initial' }}
                    >
                        <ThemedButton
                            mode="secondary"
                            onClick={() => {
                                modalStateSetter(false);
                            }}
                            text="Cancel"
                        />{' '}
                        <ThemedButton
                            mode="primary"
                            text="Save"
                            type="submit"
                        />
                    </FlexDiv>
                </form>
            }
        />
    );
}

export default EditUserModal;
