import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './reminders.scss';
import SelectChannel from '../wizard/select-channel';
import {
    CheckboxInput,
    Heading3,
    TextLead,
    FlexDiv,
    TextDefault,
} from '@awarego/awarego-components';

// making sure week on calendar starts on Monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

export default observer(
    ({
        scheduledDate,
        dueDate,
        reminders_enabled,
        onChangeRemindersEnabled,
        reminders_steps,
        onChangeReminder,
        onAddReminderStep,
        onDeleteReminderStep,
        channel,
        slackAvailable,
        teamsAvailable,
        slackConnected,
        teamsConnected,
        onChangeChannel,
        adminTimeZone,
    }) => {
        const [remindersError, setRemindersError] = React.useState(null);
        const errorMessageReminders = React.useMemo(() => {
            switch (remindersError) {
                case 'maxDate': {
                    return `Reminder must be set before the due date (${moment(
                        dueDate
                    ).format(`L HH:mm [${adminTimeZone}]`)})`;
                }
                case 'minDate': {
                    return scheduledDate != null
                        ? `Reminder must be set after the delivery time (${moment(
                              scheduledDate
                          ).format(`L HH:mm [${adminTimeZone}]`)})`
                        : 'Reminder must be set to later than now.';
                }
                case 'disablePast': {
                    return 'Due date cannot be set in the past';
                }

                case 'invalidDate': {
                    return 'Your date is not valid';
                }

                default: {
                    return '';
                }
            }
        }, [remindersError, dueDate, scheduledDate, adminTimeZone]);

        return (
            <FlexDiv column mt={40}>
                <CheckboxInput
                    checked={reminders_enabled}
                    onChange={onChangeRemindersEnabled}
                    name="sendReminders"
                    label={<TextLead bold>Send reminders</TextLead>}
                    noYPadding
                />
                {reminders_enabled ? (
                    <Fragment>
                        {reminders_steps.length > 0 && (
                            <Heading3 displayBlock mt={16} mb={8}>
                                Remind on
                            </Heading3>
                        )}
                        {reminders_steps.map((x, i) => (
                            <div
                                className={'assessment-reminder-row'}
                                key={`reminder-option-${i}`}
                            >
                                <div className="reminder-row-count">
                                    {i + 1}.
                                </div>
                                {x.alreadySet &&
                                moment(x.send).isBefore(moment()) ? (
                                    <span className="reminder-row-count">
                                        {moment(x.send).format(
                                            `L HH:mm [${adminTimeZone}]`
                                        )}
                                    </span>
                                ) : (
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DateTimePicker
                                            hiddenLabel={true}
                                            value={
                                                x.send ? moment(x.send) : null
                                            }
                                            onChange={(date) => {
                                                onChangeReminder(date, i, x);
                                            }}
                                            ampm={false}
                                            dayOfWeekFormatter={(day) =>
                                                `${day}`
                                            }
                                            disablePast={true}
                                            format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                                            views={['day', 'hours', 'minutes']}
                                            onError={(newError) =>
                                                setRemindersError(newError)
                                            }
                                            slotProps={{
                                                textField: {
                                                    helperText:
                                                        errorMessageReminders,
                                                    placeholder:
                                                        'Select date & time',
                                                },
                                            }}
                                            minDate={
                                                scheduledDate !== null
                                                    ? moment(scheduledDate)
                                                    : undefined
                                            }
                                            maxDate={
                                                dueDate !== null
                                                    ? moment(dueDate)
                                                    : undefined
                                            }
                                        />
                                    </LocalizationProvider>
                                )}
                                {!(
                                    x.alreadySet &&
                                    moment(x.send).isBefore(moment())
                                ) && (
                                    <div>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(e) => {
                                                onDeleteReminderStep(x);
                                            }}
                                            color="secondary"
                                            size="large"
                                        >
                                            <DeleteForeverIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        ))}
                        {reminders_steps.length === 0 ? <br /> : ''}
                        {reminders_steps.length < 5 && (
                            <TextDefault
                                link
                                themed
                                bold
                                onClick={onAddReminderStep}
                            >
                                + Add new reminder
                            </TextDefault>
                        )}
                        <SelectChannel
                            channel={channel}
                            slackAvailable={slackAvailable}
                            teamsAvailable={teamsAvailable}
                            slackConnected={slackConnected}
                            teamsConnected={teamsConnected}
                            onChangeChannel={onChangeChannel}
                            isReminders={true}
                        />
                    </Fragment>
                ) : (
                    ''
                )}
            </FlexDiv>
        );
    }
);
