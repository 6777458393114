import { makeAutoObservable, observable, runInAction } from 'mobx';
import services from '../services';
import { extractErrorMessage, validateEmail } from '../utils/helpers';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import { generateLoadList } from '../utils/mobx';
import omit from 'lodash/omit';
import debounceFn from 'debounce-fn';

class UsersStore {
    campaignUserDetail = null;
    loadingCampaignUserDetail = null;
    users = observable([]);
    deletingItems = observable([]);
    loadingUsers = observable([]);
    savingUsers = false;
    processedRecords = undefined;
    accumulatedUsers = observable([]);
    loadingAutomationUserDetails = false;
    automationUserStatus = undefined;
    userDetails = null;
    loadingUserDetails = false;
    importError = null;
    error = null;
    usersList = observable([]);
    loadingUsersList = null;
    initiallyLoaded = false;
    usersTotal = 0;
    listType = 'all';
    queryContext = null;

    usersDeleted = observable(0);
    usersAdded = observable(0);
    usersSearchedValue = observable(null);

    usersFiltered = 0;

    // users list refactoring is in progress, once done some above properties of userStore might be deleted
    usersByList = observable([]);
    loadingUsersByList = false;

    /**
     * @param {CommonStore} commonStore
     * @param {HistoryStore} historyStore
     * @param {MainStore} mainStore
     */
    constructor(commonStore, historyStore, mainStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
        this.historyStore = historyStore;
        this.mainStore = mainStore;
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    async loadUserInfo(companyId, email) {
        if (this.loadingUserDetails) return;
        this.loadingUserDetails = true;
        try {
            this.userDetails = await services.Companies.usersService(
                companyId
            ).userDetails(email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingUserDetails = false;
        }
    }

    async loadUserDetails(companyId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus = await services.Companies.usersService(
                companyId
            ).userStatus(email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    async loadCampaignDetails(companyId, campaignUID, email) {
        if (this.loadingCampaignUserDetail === `${campaignUID}-${email}`)
            return;
        this.loadingCampaignUserDetail = `${campaignUID}-${email}`;
        this.campaignUserDetail = null;
        try {
            this.campaignUserDetail = await services.Companies.campaignsService(
                companyId
            )
                .usersService(campaignUID)
                .userDetails(email);
            // eslint-disable-next-line no-empty
        } catch (e) {
            // intentionally empty
        } finally {
            this.loadingCampaignUserDetail = false;
        }
    }

    async loadUsers(companyId, listId, accumulate) {
        if (this.loadingUsers.includes(listId)) return;
        this.loadingUsers.push(listId);
        if (!accumulate) this.users.clear();
        try {
            let result;
            if (listId)
                result = await services.Companies.listsService(companyId)
                    .usersService(listId)
                    .list();
            else throw new Error('List id is required');
            if (accumulate) {
                this.accumulatedUsers.push({ listId, users: result });
            } else {
                this.users.replace(result);
                this.usersFiltered = result.count;
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
            // intentionally empty
        } finally {
            this.loadingUsers.remove(listId);
        }
    }

    get accumulatedIndex() {
        return keyBy(this.accumulatedUsers, 'listId');
    }

    async delete(companyId, listId, email) {
        this.deletingItems.push(listId);
        try {
            if (Array.isArray(listId))
                await services.Companies.usersService(companyId).delete(email, {
                    lists: listId,
                });
            else
                await services.Companies.listsService(companyId)
                    .usersService(listId)
                    .delete(email);

            // @refactor users & usersLists might be a duplicity,
            let user = this.users.find((x) => x.email === email);
            if (user) {
                this.users.remove(user);
            }
            let userOfList = this.usersList.find((x) => x.email === email);
            if (userOfList) {
                this.usersList.remove(userOfList);
            }

            this.usersTotal -= 1;
            this.usersFiltered -= 1;
            this.usersDeleted++;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingItems.remove(listId);
        }
    }

    async update(companyId, email, valuesToUpdate) {
        let result = await services.Companies.usersService(companyId).update(
            email,
            valuesToUpdate
        );
        let userOfList = this.usersList.find((x) => x.email === email);
        if (userOfList) {
            runInAction(() => {
                userOfList.name = valuesToUpdate.name
                    ? valuesToUpdate.name
                    : userOfList.name;
                userOfList.email = valuesToUpdate.email
                    ? valuesToUpdate.email
                    : userOfList.email;
            });
        }

        return result;
    }

    parse(data) {
        let result = {};
        result.data = [];
        result.newUsers = [];
        result.updatedUsers = [];
        result.error = '';
        result.count_total = 0;
        result.count_invalid = 0;

        let lines = data.split('\n');
        if (lines) {
            if (lines.length > 0) {
                for (let i = 0; i < lines.length; i++) {
                    let line = lines[i];
                    if (line) {
                        line = line.replace(/["']/g, '');
                        line = line.trim();
                    }
                    // discard empty lines
                    if (line.length > 0) {
                        const lineArray = line.split(/[,;]/);
                        let lineObject = {};
                        lineObject.name = '';
                        lineObject.unit = '';
                        lineObject.metadata = {};
                        lineObject.original = line;
                        lineObject.lineNumber = i + 1;
                        lineObject.error = '';
                        result.count_total++;
                        if (lineArray.length > 0) {
                            lineObject.email = lineArray[0]
                                .trim()
                                .toLowerCase();
                            if (!validateEmail(lineObject.email)) {
                                lineObject.error = 'Email not valid';
                            }
                            if (lineObject.email.length > 100) {
                                if (lineObject.error.length === 0) {
                                    lineObject.error =
                                        'Email can not be more than 100 characters';
                                }
                            }
                            if (lineArray.length > 1) {
                                lineObject.name = lineArray[1].trim();
                                if (lineObject.name.length > 100) {
                                    if (lineObject.error.length === 0) {
                                        lineObject.error =
                                            'Name can not be more than 100 characters';
                                    }
                                }
                                if (lineArray.length > 2) {
                                    lineObject.unit = lineArray[2].trim();
                                    if (lineObject.unit.length > 50) {
                                        if (lineObject.error.length === 0) {
                                            lineObject.error =
                                                'Unit can not be more than 50 characters';
                                        }
                                    }
                                    if (lineArray.length > 3) {
                                        let tagsArray = lineArray.slice(3);
                                        lineObject.metadata.tags =
                                            tagsArray.map((tag) => tag.trim());
                                    }
                                }
                            }
                        } else {
                            lineObject.error = 'No data found in line';
                        }
                        if (lineObject.error.length > 0) {
                            result.count_invalid++;
                        }
                        result.data.push(lineObject);
                    }
                }
            } else {
                result.error = 'No rows found';
            }
        } else {
            result.error = 'No data entered';
        }
        return result;
    }

    async resetUploadData() {
        this.processedRecords = null;
    }

    async saveUsers(
        users,
        companyId,
        listId,
        clientOnly = false,
        preview = false,
        removeObsolete = false
    ) {
        this.savingUsers = true;
        let result,
            records = [];
        try {
            if (typeof users === 'string') result = this.parse(users);
            else {
                records = users;
            }
            if (
                result &&
                result.count_total /* && result.count_invalid != null && result.count_total > result.count_invalid*/
            ) {
                // create array of campaignSubjects to insert bulk
                for (let i = 0; result.data.length > i; i++) {
                    if (!result.data[i].error) {
                        records.push({
                            email: result.data[i].email,
                            name: result.data[i].name,
                            listName: result.data[i].unit,
                            metadata: result.data[i].metadata,
                        });
                    }
                }
            }
            if (clientOnly) {
                this.processedRecords = result || {};
                this.processedRecords.newUsers = records;
            } else {
                if (records.length > 0 || removeObsolete) {
                    const serverData = await services.Companies.listsService(
                        companyId
                    ).createBatch(listId, {
                        records,
                        preview,
                        removeObsolete,
                    });
                    if (result && preview) {
                        this.processedRecords = {
                            ...pick(result, [
                                'count_invalid',
                                'count_total',
                                'data',
                            ]),
                            ...serverData,
                            records,
                        };
                    } else this.processedRecords = null;
                    return serverData;
                } else {
                    this.processedRecords = result;
                }
            }
        } catch (e) {
            this.importError = extractErrorMessage(e);
        } finally {
            this.savingUsers = false;

            this.commonStore.saveAnalyticsEvent('employee', 'added', {
                sourcePage: this.historyStore.previousPath,
                totalEmployees: records.length,
                employeeListId: `${listId}`,
            });
        }
    }

    copyErrors() {
        if (
            this.processedRecords &&
            this.processedRecords.data &&
            this.processedRecords.data.length > 0
        ) {
            let errorList = '';
            for (let i = 0; i < this.processedRecords.data.length; i++) {
                if (
                    this.processedRecords.data[i].error &&
                    this.processedRecords.data[i].error.length > 0
                ) {
                    errorList +=
                        this.processedRecords.data[i].email +
                        ', ' +
                        this.processedRecords.data[i].name +
                        ', ' +
                        this.processedRecords.data[i].unit +
                        '\n';
                }
            }
            this.processedRecords = null;
            return errorList;
        } else {
            alert('No errors available to copy');
        }
    }

    cleanEditor() {
        this.processedRecords = null;
    }

    get pageIndex() {
        return this.queryContext && this.queryContext.p;
    }

    get pageSize() {
        return this.queryContext && this.queryContext.n;
    }

    get order() {
        return this.queryContext && this.queryContext.order;
    }

    setQueryContext(context = {}) {
        this.queryContext = context;
    }

    setPagingParams(pageIndex, pageSize) {
        if (this.queryContext) {
            this.queryContext.p = pageIndex;
            this.queryContext.n = pageSize;
        }
    }

    setSortBy(sortBy) {
        if (this.queryContext && sortBy && sortBy.length > 0) {
            this.queryContext.order = `${sortBy[0].id} ${
                sortBy[0].desc ? 'desc' : 'asc'
            }`;
        }
    }

    loadAllUsers = generateLoadList(
        'usersList',
        this,
        'loadingUsersList',
        async (companyId, searchValue = null) => {
            this.queryContext.filter = searchValue;
            const result = await services.Companies.usersService(
                companyId
            ).list({
                ...omit(this.queryContext),
            });
            result?.users.forEach(
                (user) => (user.link = `/users/user/${user.email}`)
            );
            runInAction(() => {
                if (!searchValue) {
                    this.usersTotal = result.count;
                }
                this.usersFiltered = result.count;
            });
            return result.users;
        },
        'usersList'
    );

    debouncedLoadAllUsers = debounceFn(this.loadAllUsers, { wait: 500 });

    loadUsersByListId = generateLoadList(
        'usersByList',
        this,
        'loadingUsersByList',
        async (companyId, listId, searchValue = null) => {
            if (searchValue != null) {
                this.queryContext.filter = searchValue;
            }
            const result = await services.Companies.listsService(companyId)
                .usersService(listId)
                .list({
                    ...omit(this.queryContext),
                });

            runInAction(() => {
                if (!searchValue) {
                    this.usersTotal = result.count;
                }
                this.usersFiltered = result.count;
            });
            return result.users;
        },
        'usersByList'
    );

    debouncedLoadUsersByListId = debounceFn(this.loadUsersByListId, {
        wait: 500,
    });

    buildDeleteUserDialog(
        userToDelete,
        description,
        onDeleteCallback,
        actionButtonText = 'Delete',
        title = 'Delete user'
    ) {
        this.commonStore.showConfirm(
            description,
            actionButtonText,
            title,
            async () => {
                await this.delete(
                    userToDelete.companyId,
                    userToDelete.listsId,
                    userToDelete.email
                );
                if (onDeleteCallback) onDeleteCallback();
            },
            true,
            true
        );
    }

    calculateUsersPageCount() {
        if (!this.usersFiltered || !this.pageSize) return 0;

        const pc = Math.ceil(this.usersFiltered / this.pageSize);
        return pc;
    }
}

export default UsersStore;
