import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import RadioButton from '../../../components/RadioButton';
import pluralize from 'pluralize';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';
import { Heading1, ThemedButton } from '@awarego/awarego-components';

function ModalReminders({
    onLoadRemindersCount,
    onSendReminders,
    target,
    onRequestClose,
    lastReminders,
    reminderCounter,
    loadingReminderCounter,
    remindOnlyUnfinished,
}) {
    const [reminderType, setReminderType] = useState(1);
    const [forceChecked, setForceChecked] = useState(false);

    const onChangeReminderType = (reminderType) => {
        setReminderType(reminderType);
        onLoadRemindersCount(reminderType);
    };

    useEffect(() => {
        onChangeReminderType(remindOnlyUnfinished ? 2 : 1);
    }, []);

    const onSend = () => {
        onSendReminders(reminderType);
    };

    if (target) {
        return (
            <div>
                <div className={'modalHeader'}>
                    <Heading1>Send Reminders</Heading1>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent form'}>
                    {!remindOnlyUnfinished && (
                        <div className={'training-schedule-options'}>
                            <RadioButton
                                id="typeSchedule1"
                                label="Low-performing Employees"
                                value={1}
                                groupValue={reminderType}
                                onClick={onChangeReminderType}
                            />
                            <RadioButton
                                id="typeSchedule2"
                                label="All not finished Employees"
                                value={2}
                                groupValue={reminderType}
                                onClick={onChangeReminderType}
                            />
                            <RadioButton
                                id="typeSchedule3"
                                label="All Employees"
                                value={3}
                                groupValue={reminderType}
                                onClick={onChangeReminderType}
                            />
                        </div>
                    )}
                    <div
                        className={
                            !remindOnlyUnfinished
                                ? 'training-schedule-feedback'
                                : ''
                        }
                    >
                        <div>
                            <span>
                                {loadingReminderCounter ? (
                                    <Spinner
                                        size="sm"
                                        style={{ verticalAlign: 'middle' }}
                                    />
                                ) : (
                                    <span>{reminderCounter} </span>
                                )}{' '}
                                {pluralize('employee', reminderCounter)} will be
                                reminded{' '}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="modalFooter justify-between">
                    {lastReminders.length > 0 && (
                        <div className={'container-checkbox small'}>
                            <label htmlFor={'additionalCheckbox'}>
                                There was a{' '}
                                {lastReminders.length > 1
                                    ? lastReminders.length
                                    : ''}{' '}
                                {pluralize('reminder', lastReminders.length)}{' '}
                                sent in the last 24h. Are you sure you want to
                                send it again?
                                <input
                                    type="checkbox"
                                    className="checkmark"
                                    autoComplete={false}
                                    id={'additionalCheckbox'}
                                    checked={forceChecked}
                                    onChange={() => {
                                        setForceChecked(!forceChecked);
                                    }}
                                />
                                <span
                                    className={classnames('checkmark', {
                                        checked: forceChecked,
                                    })}
                                ></span>
                            </label>
                        </div>
                    )}
                    <ThemedButton
                        mode="primary"
                        disabled={
                            loadingReminderCounter ||
                            reminderCounter === 0 ||
                            (lastReminders.length > 0 && !forceChecked)
                        }
                        onClick={onSend}
                        text="Send reminders"
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default observer(ModalReminders);
