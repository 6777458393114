import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Spinner } from 'reactstrap';
import intersection from 'lodash/intersection';
import { useStores } from '../../hooks/use-stores';
import {
    Heading2,
    ThemedButton,
    BigModal,
    FlexDiv,
    TextTiny,
    SpaceBetweenDiv,
    Heading3, DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import GroupsTable from './groups-table';
import { observer } from 'mobx-react';
import MoreIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import TableClient from '../table/TableClient';
import RequireRole from "../../containers/RequireRole";
import CircularProgress from "@mui/material/CircularProgress";


function IntegrationDetailExcludedUsers({ data, companyId, product }) {
    const { companyStore, azureGroupsStore, googleGroupsStore, commonStore } =
        useStores();
    let groupsStore;
    if (product === 'azure') groupsStore = azureGroupsStore;
    else groupsStore = googleGroupsStore;

    const {deletingUsersFromExcluded} = groupsStore;

    const handleRemoveUser = async (id) => {
        commonStore.showConfirm(
            'Are you sure you want to remove this user from excluded list ?',
            'Remove',
            'Remove user',
            async () => {
                let result = await groupsStore.removeExcludedUser(
                    companyId,
                    product,
                    id
                );
                if (result) {
                    commonStore.success('User removed.');
                    companyStore.removeUserFromExcludedList(companyId, product, id);
                }
            },
            true
        );

        //setIncludedRulesChanged(true);
    };

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={() => handleRemoveUser(row.id)}
                        row={row}
                        title="Remove User from excluded list"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
            </>
        );
    };


    const columns = useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'displayName',
            },
            {
                Header: 'Email',
                accessor: 'mail',
            },
            {
                Header: 'When Excluded',
                accessor: 'lastUpdated',
                Cell: (x) => {
                    if (x.value) {
                        return <>{moment(x.value).fromNow()}</>;
                    } else {
                        return <>Never</>;
                    }
                },
            },
            {
                Header: '',
                accessor: 'id',
                Cell: observer((x) => (
                    <FlexDiv>
                        {deletingUsersFromExcluded.includes(x.value) ? (
                            <CircularProgress />
                        ) : (
                            buildExtraActionsColumn(x.row.original)
                        )}
                    </FlexDiv>
                )),
                disableGlobalFilter: true,
            },
        ],
        []
    );

    return (
        <div className="integration-detail-block align-left">
            <FlexDiv fullWidth>
                <FlexDiv column flexGrow={1} mr={8}>
                    <Heading2 mb={16}>Excluded Users</Heading2>
                    <p>
                        Synced users removed from user lists excluded from the further sync. You can remove them from this list and include them in the sync again.
                    </p>
                </FlexDiv>
                <TableClient
                    columns={columns}
                    data={data.slice(0)}
                    NoDataComponent={
                        <div className="text-center">
                            <br />
                            <img
                                src="/static/img/icon-users.svg"
                                alt="No users"
                            />
                            <br />
                            <br />
                            <Heading3>No excluded user</Heading3>
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                }}
                            >
                            </p>
                        </div>
                    }
                />
            </FlexDiv>
        </div>
    );
}

export default observer(IntegrationDetailExcludedUsers);
