import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';
import { VideoPlayer } from '@awarego/awarego-components';
import keyBy from 'lodash/keyBy';
import ThemedSpinner from '../components/themed/ThemedSpinner';

export default observer(
    ({
        subject,
        logEvent,
        onChange,
        enableAssessmentFinish,
        onLastDecision,
        resetDecisions,
        language,
    }) => {
        const { store } = useStores();
        const [token, setToken] = useState(null);

        const { videoPlayerUrl, tokens } = store;

        const videoTokenRequested = async (predefinedSubjectId) => {
            const subjectId =
                predefinedSubjectId || subject.id || subject.subject_id;
            await store.videoToken(subjectId, language);
            let tokenArray = keyBy(tokens, 'id');
            setToken(tokenArray[subjectId]);
        };

        useEffect(() => {
            const loadVideoData = async () => {
                if (!store.videoPlayerUrl) await store.loadVideoPlayerUrl();
                const subjectId = subject.id || subject.subject_id;
                const tokenFromIndex = store.tokenIndex[subjectId];
                if (
                    subjectId &&
                    (!tokenFromIndex || tokenFromIndex.language !== language)
                ) {
                    await store.videoToken(subjectId, language);
                }
                let tokenArray = keyBy(tokens, 'id');
                setToken(tokenArray[subjectId]);
            };

            loadVideoData();
        }, [store, subject, tokens, language]);

        return store.loadingVideoUrl ? (
            <ThemedSpinner />
        ) : (
            token && (
                <VideoPlayer
                    videoTokenRequested={videoTokenRequested}
                    token={token && token.token}
                    videoPlayerUrl={videoPlayerUrl}
                    subject={subject}
                    logEvent={logEvent}
                    onChange={onChange}
                    enableAssessmentFinish={enableAssessmentFinish}
                    presetCaptionsLanguage={store.playerPresetCaptionsLanguage}
                    captionsSettings={store.playerCaptionsSettings}
                    onLastDecision={onLastDecision}
                    resetDecisions={() => {
                        resetDecisions();
                        subject.resetDecisions();
                    }}
                    onFinishedWatchingAnswer={() => {
                        subject.hasSeenAnswer();
                    }}
                />
            )
        );
    }
);
