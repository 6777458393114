import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Alert, Spinner } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import { formatDate } from '../../../utils/helpers';
import classnames from 'classnames';
import { ThemedLinkLink } from '../../../components/themed/ThemedComponents';
import {
    Block,
    FlexDiv,
    Heading4,
    SpaceBetweenDiv,
    TextDefault,
    ThemedButton,
    TooltipWrapper,
    ProgressWithNumber,
} from '@awarego/awarego-components';
import RequireRole from '../../../containers/RequireRole';
import { trackingDisabled } from '../../../utils/constants';

function UserCampaignDetails({
    userDetails,
    anonymousTracking = false,
    onSendReminder,
    email,
    showTrainings = false,
    canRemind,
    loadingCanRemind,
}) {
    const subjects = userDetails.subjects ? userDetails.subjects : [];
    const score = Math.round(userDetails.score) || 0;

    return (
        <Row>
            <Col md="12" xs="12" className="content">
                <Block>
                    <SpaceBetweenDiv alignStart>
                        <FlexDiv column>
                            <Heading4 mb={16}>{email}</Heading4>
                            {userDetails.started !== undefined && (
                                <Fragment>
                                    <TextDefault lighter mb={4}>
                                        Started on{' '}
                                        {formatDate(userDetails.started)}
                                    </TextDefault>
                                    <FlexDiv alignCenter>
                                        <ProgressWithNumber
                                            value={score}
                                            width={330}
                                            height={6}
                                        />
                                    </FlexDiv>
                                </Fragment>
                            )}
                        </FlexDiv>
                        <RequireRole>
                            {!showTrainings && (
                                <TooltipWrapper
                                    showTooltip={!canRemind}
                                    tooltipText={
                                        'You can send reminders only when a training is active'
                                    }
                                >
                                    <ThemedButton
                                        mode="primary"
                                        disabled={!canRemind}
                                        onClick={() => {
                                            onSendReminder();
                                        }}
                                        text={
                                            <>
                                                {loadingCanRemind ? (
                                                    <Spinner
                                                        as="span"
                                                        size="sm"
                                                    />
                                                ) : (
                                                    ' Send a reminder'
                                                )}
                                            </>
                                        }
                                    />
                                </TooltipWrapper>
                            )}
                        </RequireRole>
                    </SpaceBetweenDiv>

                    {/* Show Alert instead of table if company has company.anonymousTracking */}
                    {anonymousTracking ? (
                        <Alert color="warning">{trackingDisabled}</Alert>
                    ) : (
                        <Table
                            className="table"
                            id="table"
                            itemsPerPage={50}
                            pageButtonLimit={5}
                            sortable={[
                                {
                                    column: 'Lesson',
                                    sortFunction: function (a, b) {
                                        return a
                                            .toLowerCase()
                                            .localeCompare(b.toLowerCase());
                                    },
                                },
                                'Delivery',
                                'Views',
                            ]}
                            defaultSort="Delivery"
                            filterable={['Lesson']}
                            filterPlaceholder="Search Subjects"
                        >
                            <Thead>
                                <Th column="Lesson" className="">
                                    {' '}
                                    Lesson
                                </Th>
                                {showTrainings && (
                                    <Th column="Trainings" className="">
                                        Trainings
                                    </Th>
                                )}
                                <Th column="Delivery" className="center">
                                    Delivery date
                                </Th>
                                <Th
                                    column="Viewed"
                                    align="center"
                                    className="center"
                                >
                                    Viewed
                                </Th>
                                <Th column="Correctly" className="center">
                                    Correctly answered
                                </Th>
                                <Th column="Incorrectly" className="center">
                                    Incorrect count
                                </Th>
                            </Thead>
                            {subjects.length > 0 &&
                                subjects.map((subject, i) => (
                                    <Tr
                                        key={i}
                                        className={classnames('status-row', {
                                            disabled: subject.status === 0,
                                        })}
                                    >
                                        <Td
                                            column={'Lesson'}
                                            value={subject.title}
                                            className="list-name-column"
                                        >
                                            {subject.title}
                                        </Td>
                                        {showTrainings && (
                                            <Th column="Trainings" className="">
                                                <span className="d-flex flex-column">
                                                    {subject.training_ids.map(
                                                        (x) => (
                                                            <ThemedLinkLink
                                                                key={x}
                                                                to={`/trainings/${x}/view`}
                                                            >
                                                                {' '}
                                                                {
                                                                    userDetails
                                                                        .trainings[
                                                                        x
                                                                    ].name
                                                                }{' '}
                                                            </ThemedLinkLink>
                                                        )
                                                    )}
                                                </span>
                                            </Th>
                                        )}
                                        <Td
                                            column={'Delivery'}
                                            className="center"
                                            value={subject.delivered}
                                        >
                                            {subject.delivered
                                                ? formatDate(subject.delivered)
                                                : '- no data - '}
                                        </Td>
                                        <Td
                                            column={'Viewed'}
                                            className="center"
                                        >
                                            {subject.viewed > 0 ? (
                                                <img
                                                    className="check-mark"
                                                    alt="icon"
                                                    title="Viewed"
                                                    src="/static/img/icon-checked.svg"
                                                />
                                            ) : (
                                                <img
                                                    className="check-mark not-checked"
                                                    alt="icon"
                                                    title="Not viewed"
                                                    src="/static/img/icon-not-checked.svg"
                                                />
                                            )}
                                        </Td>
                                        <Td
                                            column={'Correctly'}
                                            className="center"
                                        >
                                            {subject.correct > 0 ? (
                                                <img
                                                    className="check-mark"
                                                    alt="icon"
                                                    title="Answered correctly"
                                                    src="/static/img/icon-checked.svg"
                                                />
                                            ) : (
                                                <img
                                                    className="check-mark not-checked"
                                                    alt="icon"
                                                    title="Not answered"
                                                    src="/static/img/icon-not-checked.svg"
                                                />
                                            )}
                                        </Td>
                                        <Td
                                            column={'Incorrectly'}
                                            className="center"
                                        >
                                            {subject.incorrect}
                                        </Td>
                                    </Tr>
                                ))}
                        </Table>
                    )}
                </Block>
            </Col>
        </Row>
    );
}

export default observer(UserCampaignDetails);
