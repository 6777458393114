import React from 'react';
import TextField from '../inputs/textField';
import { observer } from 'mobx-react';

export default observer(({ form, couponError }) => (
    <form>
        <div className="form-row">
            <div className="form-group col-md-6">
                <TextField field={form.$('firstName')} />
            </div>
            <div className="form-group col-md-6">
                <TextField field={form.$('lastName')} />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <TextField field={form.$('email')} />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="inputPassword">{form.$('country').label}</label>
                <select
                    id="country"
                    name="country"
                    className="form-control"
                    {...form.$('country').bind()}
                >
                    <option value=""></option>
                    {form.$('country').extra.map((x) => (
                        <option key={x.code} value={x.code}>
                            {x.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>

        <div className="form-group">
            <TextField field={form.$('line1')} />
        </div>
        <div className="form-group"></div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <TextField field={form.$('city')} />
            </div>
            <div className="form-group col-md-4">
                <TextField field={form.$('state')} />
            </div>
            <div className="form-group col-md-2">
                <TextField field={form.$('zip')} />
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-6">
                <TextField field={form.$('coupon')} error={couponError} />
            </div>
        </div>
    </form>
));
