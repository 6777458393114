import React from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import { observer, useLocalObservable } from 'mobx-react';

const ProgressChart = ({ chartData }) => {
    const localStore = useLocalObservable(() => ({
        get chartOptions() {
            return {
                chart: {
                    type: 'spline',
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift',
                    scrollablePlotArea: {
                        minWidth: 600,
                    },
                    backgroundColor: {
                        linearGradient: [0, 0, 0, 110],
                        stops: [
                            [0, 'rgb(240, 246, 246, 0.0)'],
                            [1, 'rgb(240, 246, 246, 0.2)'],
                        ],
                    },
                },
                colors: ['#82C9AB', '#F1D47A', '#FC814A'],

                title: {
                    text: 'Training progress',
                },

                plotOptions: {
                    series: {
                        animation: {
                            duration: 1000,
                        },
                        marker: {
                            enabled: true,
                        },
                        lineWidth: 1.5,
                    },
                },
                xAxis: {
                    categories: chartData.map((x) =>
                        moment(x.lastTimestamp).format('D MMM YY')
                    ),
                },
                tooltip: {
                    shared: true,
                },
                credits: {
                    enabled: false,
                },
                yAxis: [
                    {
                        title: {
                            text: 'Users',
                        },
                    },
                    {
                        title: {
                            text: '%',
                        },
                        opposite: true,
                    },
                ],

                series: [
                    {
                        type: 'area',
                        yAxis: 0,
                        name: 'Employees logged in ',
                        marker: {
                            symbol: 'url(https://awarego.com/icon-solid-green.png)',
                            height: 5,
                            width: 5,
                        },
                        fillColor: {
                            linearGradient: {
                                y1: 0,
                                y2: 0.1,
                            },
                            stops: [
                                [0, 'rgba(130, 201, 171, 0.02)'],
                                [1, 'rgba(130, 201, 171, 0)'],
                            ],
                        },
                        data: chartData.map((x) => x.loggedInUsers),
                        // data: [
                        //     {
                        //         y: 10,
                        //         marker: {
                        //             symbol: 'url(https://resources.awarego.com/img/icon-sent.png)',
                        //         },
                        //         accessibility: {
                        //             description:
                        //                 'Sunny symbol, this is the warmest point in the chart.',
                        //         },
                        //     },
                        //     20,
                        //     30,
                        //     {
                        //         y: 46,
                        //         marker: {
                        //             symbol: 'url(https://resources.awarego.com/img/icon-sent.png)',
                        //         },
                        //         accessibility: {
                        //             description:
                        //                 'Sunny symbol, this is the warmest point in the chart.',
                        //         },
                        //     },
                        //     56,
                        //     {
                        //         y: 60,
                        //         marker: {
                        //             symbol: 'url(https://resources.awarego.com/img/icon-reminder.png)',
                        //         },
                        //         accessibility: {
                        //             description:
                        //                 'Sunny symbol, this is the warmest point in the chart.',
                        //         },
                        //     },
                        //     70,
                        //     80,
                        //     90,
                        // ],
                    },
                    {
                        yAxis: 1,
                        type: 'area',
                        name: 'Current score',
                        tooltip: {
                            valueSuffix: ' %',
                        },
                        fillColor: {
                            linearGradient: {
                                y1: 0,
                                y2: 0.1,
                            },
                            stops: [
                                [0, 'rgba(241, 212, 122, 0.02)'],
                                [1, 'rgba(241, 212, 122, 0)'],
                            ],
                        },

                        data: chartData.map(
                            (x) => x.toDateSubjectsFinished_MAX
                        ),
                        marker: {
                            symbol: 'url(https://awarego.com/icon-solid-yellow.png)',
                            height: 5,
                            width: 5,
                        },
                    },
                    {
                        yAxis: 1,
                        type: 'area',
                        name: 'Overall score',
                        tooltip: {
                            valueSuffix: ' %',
                        },
                        fillColor: {
                            linearGradient: {
                                y1: 0,
                                y2: 0.1,
                            },
                            stops: [
                                [0, 'rgba(252, 129, 74, 0.02)'],
                                [1, 'rgba(252, 129, 74, 0)'],
                            ],
                        },
                        data: chartData.map((x) => x.allSubjectsFinished_MAX),
                        marker: {
                            symbol: 'url(https://awarego.com/icon-solid-orange.png)',
                            height: 5,
                            width: 5,
                        },
                    },
                ],
            };
        },
    }));

    // Data for the chart

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={localStore.chartOptions}
            />
        </div>
    );
};

export default observer(ProgressChart);
