import React from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ThemedButton, FlexDiv } from '@awarego/awarego-components';
import TableClient from '../../../components/table/TableClient';

function BouncedEmailsTable({ companyId, productId, store }) {
    const { columns, usersList, isDownloading, loadingUsersList } = store;

    const handleDownloadCSV = () => {
        store.downloadCSV(companyId, productId);
    };

    return (
        <FlexDiv>
            <FlexDiv my={10} flexGrow={1} justifyEnd>
                <ThemedButton
                    mode="secondary"
                    size="tiny"
                    $noTextTransform
                    onClick={handleDownloadCSV}
                    disabled={loadingUsersList}
                    text={
                        <>
                            {isDownloading && <CircularProgress size={10} />}{' '}
                            Download .csv
                            <GetAppIcon fontSize="small" />
                        </>
                    }
                />
            </FlexDiv>
            <TableClient
                columns={columns}
                data={usersList.slice()}
                defaultSortBy={'name'}
                loadingData={loadingUsersList}
            />
        </FlexDiv>
    );
}

export default observer(BouncedEmailsTable);
