import React from 'react';
import { observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { calculateColor, calculateScoreTitle } from '../../../utils/helpers';
import { palette } from '@awarego/awarego-components';

function ScoreDistributionChart({ componentData, onBarClick }) {
    const generateBarData = () => {
        const labels = componentData.map((value) => [
            calculateScoreTitle(value.from),
            value.from + ' - ' + value.to,
        ]);
        const data = componentData.map((value) => value.employeePercent);
        const backgroundColor = componentData.map((value) =>
            calculateColor(value.from)
        );

        const employeeCount = componentData.map((value) => value.value);
        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor,
                    hoverBackgroundColor: backgroundColor,
                    employeeCount,
                    borderRadius: '5',
                },
            ],
        };
    };

    const realData = generateBarData();

    return (
        <Bar
            data={realData}
            plugins={[ChartDataLabels]}
            options={{
                // show a pointer cursor when hovering over the bars
                onHover: function (e, chartEl) {
                    e.native.target.style.cursor = chartEl[0]
                        ? 'pointer'
                        : 'default';
                },
                onClick: (e, chartEl) => {
                    if (chartEl && chartEl[0] && onBarClick) {
                        onBarClick(chartEl[0].index);
                    }
                },
                interaction: {
                    intersect: true,
                    mode: 'index',
                },
                animation: {
                    duration: 0,
                },
                scales: {
                    y: {
                        position: 'right',
                        min: 0,
                        ticks: {
                            precision: 0,
                            stepSize: 25,
                            // Include a percentage sign in the ticks
                            callback: (value) => value + '%',
                        },
                        grid: {
                            drawBorder: false,
                        },
                    },
                    y1: {
                        position: 'left',
                        title: {
                            display: true,
                            text: '% of employees',
                        },
                        grid: {
                            drawBorder: false,
                        },
                        ticks: {
                            display: false,
                            precision: 0,
                        },
                        beginAtZero: true,
                    },
                    x: {
                        grid: { display: false },
                        ticks: {
                            fontSize: 10,
                            // always show all labels, even if its value is 0
                            autoSkip: false,
                            maxRotation: 0,
                        },
                    },
                },
                plugins: {
                    datalabels: {
                        color: (context) => {
                            const smallValue =
                                context.chart.scales.y._endValue * 0.2;
                            const value =
                                context.dataset.data[context.dataIndex];
                            return value < smallValue
                                ? context.dataset.backgroundColor
                                : 'white';
                        },
                        font: {
                            weight: 800,
                            size: 12,
                        },
                        padding: 6,
                        formatter: (value) => value + '%',
                        anchor: 'end',
                        align: 'start',
                        offset: (context) => {
                            const smallValue =
                                context.chart.scales.y._endValue * 0.2;
                            const value =
                                context.dataset.data[context.dataIndex];
                            return value < smallValue ? -24 : 4;
                        },
                    },
                    legend: { display: false },
                    tooltip: {
                        enabled: true,
                        backgroundColor: 'white',
                        bodyColor: palette.graphite.heavyMetal,
                        bodyFont: { size: 12, fontweight: 600 },
                        displayColors: false,
                        borderColor: palette.lightroom.heavyMetalLightUltra,
                        bodyAlign: 'center',
                        borderWidth: 1,
                        padding: 4,
                        caretSize: 0,
                        carretPadding: 0,
                        callbacks: {
                            title: function () {
                                return;
                            },
                            label: (context) => {
                                return `View employees (${
                                    context.dataset.employeeCount[
                                        [context.dataIndex]
                                    ]
                                })`;
                            },
                        },
                    },
                },
            }}
        />
    );
}

export default observer(ScoreDistributionChart);
