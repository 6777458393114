import React from 'react';
import { TextTiny } from '@awarego/awarego-components';

/** A clickable (link) element used in Headers' Sub-headings */
function SubHeaderLink({ text, color, onClick }) {
    return (
        <TextTiny
            lineHeight={'initial'}
            color={color}
            underline
            onClick={onClick}
            link
            bold
        >
            {text}
        </TextTiny>
    );
}

export default SubHeaderLink;
