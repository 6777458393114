import CommonStore from './commonStore';
import UserStore from './usersStore';
import SubscriptionsStore from './subscriptionsStore';
import ListStore from './listsStore';
import MainStore from './viewer';
import CompanyStore from './companyStore';
import AutomationStore from './automationStore';
import AssessmentStore from './assessmentStore';
import AssessmentEmployeesStoresFactory from './assessmentEmployeesStore';
import AssessmentEmployeeListsStore from './assessmentEmployeeListsStore';
import AutomationEmployeesStoresFactory from './automationEmployeesStore';
import AutomationEmployeeListsStore from './automationEmployeeListsStore';
import PartnerStore from './partnerStore';
import AuthStore from './authStore';
import CatalogStore from './catalogStore';
import ResourcesStore from './resourcesStore';
import MailStore from './mailStore';
import BrandingStore from './brandingStore';
import PPVStore from './ppvStore';
import VideosStore from './videosStore';
import QuestionStore from './questions';
import HistoryStore from './historyStore';
import Cmi5Store from './cmi5Store';
import AzureGroupsStore from './azureGroupsStore';
import GoogleGroupsStore from './googleGroupsStore';
import ReportsStore from './reportsStore';
import LanguageStore from './languageStore';
import VantaGroupsStore from './vantaGroupsStore';

const commonStore = new CommonStore();
const historyStore = new HistoryStore();

const store = new MainStore(commonStore);
const userStore = new UserStore(commonStore, historyStore, store);
const subscriptionStore = new SubscriptionsStore(commonStore);
const companyStore = new CompanyStore(store, subscriptionStore, commonStore);
const brandingStore = new BrandingStore(store, companyStore);
const listStore = new ListStore(userStore, commonStore);
const catalogStore = new CatalogStore();
const questionStore = new QuestionStore(companyStore, store);
const authStore = new AuthStore(commonStore, store, brandingStore);
const assessmentStore = new AssessmentStore(
    listStore,
    store,
    commonStore,
    companyStore,
    authStore,
    brandingStore
);
const automationStore = new AutomationStore(
    listStore,
    catalogStore,
    store,
    commonStore,
    companyStore,
    authStore,
    historyStore,
    brandingStore
);

const languageStore = new LanguageStore();
const stores = {
    authStore,
    store,
    brandingStore,
    commonStore,
    userStore,
    listStore,
    companyStore,
    partnerStore: new PartnerStore(),
    resourcesStore: new ResourcesStore(commonStore),
    subscriptionStore,
    catalogStore,
    mailStore: new MailStore(),
    ppvStore: new PPVStore(),
    automationStore,
    assessmentStore,
    assessmentEmployeesStoresFactory: new AssessmentEmployeesStoresFactory(
        assessmentStore,
        commonStore,
        store
    ),
    assessmentEmployeeListsStore: new AssessmentEmployeeListsStore(
        assessmentStore
    ),
    automationEmployeesStoresFactory: new AutomationEmployeesStoresFactory(
        automationStore,
        commonStore,
        store
    ),
    azureGroupsStore: new AzureGroupsStore(
        automationStore,
        commonStore,
        companyStore
    ),
    vantaGroupsStore: new VantaGroupsStore(
        automationStore,
        commonStore,
        companyStore
    ),
    googleGroupsStore: new GoogleGroupsStore(
        automationStore,
        commonStore,
        companyStore
    ),
    automationEmployeeListsStore: new AutomationEmployeeListsStore(
        automationStore
    ),
    videosStore: new VideosStore(),
    questionStore,
    historyStore,
    cmi5Store: new Cmi5Store(),
    reportsStore: new ReportsStore(),
    languageStore,
};

store.setAuthStore(stores.authStore);
store.setPartnerStore(stores.partnerStore);

export default stores;
