import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';
import { extractErrorMessage } from '../utils/helpers';

class Cmi5Store {
    loadingQuestions = null;
    error = null;
    questions = null;

    threatAreaDefinitions = [];
    loadingThreatAreaDefinitions = null;

    deletingPackages = [];
    packages = [];
    loadingPackages = null;

    creatingPackage = false;
    isZIPDownloading = false;

    scormWithTranslations = [];

    // loaders
    loadingScorm = null;
    uploadingLanguage = null;
    uploadingScorm = null;
    updatingLanguagePackage = null;
    deletingLanguagePackage = null;

    constructor() {
        makeAutoObservable(this);
    }

    setError = (error) => {
        this.error = error;
    };

    loadQuestions = generateLoadEntity(
        '',
        this,
        'loadingQuestions',
        (companyId) => {
            return services.Companies.questionsService(companyId).list();
        },
        'questions'
    );

    loadPackages = generateLoadList(
        'packages',
        this,
        'loadingPackages',
        (companyId) => {
            return services.Companies.cmi5Service(companyId).list();
        },
        'packages'
    );

    deletePackage = generateDeleteEntity(
        'deletePackage',
        this,
        'deletingPackages',
        async (companyId, id) => {
            await services.Companies.cmi5Service(companyId).delete({
                id,
            });
            runInAction(() => {
                this.packages = this.packages.filter((x) => x.id !== id);
            });
        }
    );

    loadThreatAreaDefinitions = generateLoadList(
        'threatAreaDefinitions',
        this,
        'loadingThreatAreaDefinitions',
        (companyId) => {
            return services.Companies.assessmentsService(
                companyId
            ).getThreatAreaDefinitions();
        },
        'threatAreaDefinitions',
        null,
        { shouldLoad: () => this.threatAreaDefinitions.length === 0 }
    );

    createPackage = generateCreateEntity(
        'createPackage',
        this,
        'creatingPackage',
        (companyId, data) => {
            return services.Companies.cmi5Service(companyId).create(data);
        }
    );

    setFormat(id, format) {
        const p = this.packages.find((x) => x.id === id);
        p.format = format;
    }

    async downloadZIP(companyId, packageId, uniqueId, type) {
        const format = type || p.format || 'scorm2004';
        const p = this.packages.find((x) => x.id === packageId);
        uniqueId = p ? p.uniqueId : uniqueId;
        if (this.isZIPDownloading) return true;
        this.isZIPDownloading = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response;

            response = await services.Companies.cmi5Service(
                companyId
            ).cmiZipPackage(packageId, options, format);

            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${uniqueId}-${format}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } finally {
            this.isZIPDownloading = false;
        }
    }

    async validateScormPackage(companyId, formData) {
        let response;
        try {
            response = await services.Companies.contentService(
                companyId
            ).validateScormZipPackage(formData);
            return response;
        } catch (error) {
            this.error = extractErrorMessage(error);
            throw error;
        }
    }

    uploadScormPackage = generateCreateEntity(
        'uploadScormPackage',
        this,
        'uploadingScorm',
        async (companyId, formData) => {
            const result = await services.Companies.contentService(
                companyId
            ).uploadScormZipPackage(formData);

            if (result) {
                this.scormWithTranslations.push(result);
            }

            return result;
        }
    );

    uploadLanguagePackage = generateCreateEntity(
        'uploadLanguage',
        this,
        'uploadingLanguage',
        async (companyId, formData, scormId) => {
            const result = await services.Companies.contentService(
                companyId
            ).uploadLanguagePackage(formData, scormId);

            if (result) {
                this.scormWithTranslations.push(result);
            }

            return result;
        }
    );

    editLanguagePackage = generateUpdateEntity(
        'editLanguagePackage',
        this,
        'updatingLanguagePackage',
        async (companyId, formData, scormId, languageCode) => {
            const result = await services.Companies.contentService(
                companyId
            ).editLanguagePackage(formData, scormId, languageCode);

            if (result && result.length > 0) {
                let scorms = [...this.scormWithTranslations];
                let scormsUpdated = scorms.map((pckg) => {
                    if (pckg.language === languageCode) {
                        return result[0];
                    }
                    return pckg;
                });
                this.scormWithTranslations = scormsUpdated;
                runInAction(() => {
                    this.scormWithTranslations = scormsUpdated;
                });
            }

            return result;
        }
    );
    deleteScormZipPackage = generateDeleteEntity(
        'deleteScormZipPackage',
        this,
        'deletingScormZipPackage',
        async (companyId, scormId) => {
            const result = await services.Companies.contentService(
                companyId
            ).deleteScormZipPackage(scormId);
            runInAction(() => {
                this.scormWithTranslations = [];
            });
            return result;
        }
    );

    deleteLanguagePackage = generateDeleteEntity(
        'deleteLanguagePackage',
        this,
        'deletingLanguagePackage',
        async (companyId, scormId, languageCode, isDefaultLanguage) => {
            const result = await services.Companies.contentService(
                companyId
            ).deleteLanguagePackage(scormId, languageCode, isDefaultLanguage);
            runInAction(() => {
                if (isDefaultLanguage) {
                    this.scormWithTranslations = [];
                } else {
                    this.scormWithTranslations =
                        this.scormWithTranslations.filter(
                            (x) => x.language !== languageCode
                        );
                }
            });
            return result;
        }
    );

    loadScormContent = generateLoadEntity(
        'loadScormContent',
        this,
        'loadingScorm',
        async (companyId, scormId) => {
            return services.Companies.subjectsService(
                companyId
            ).getAllScormTranslations(scormId);
        },
        'scormWithTranslations'
    );
}

export default Cmi5Store;
