import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { CheckboxInput, TextInput } from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';

function AddManager({ managerForm }) {
    const inputHasError = (input) =>
        managerForm.$(input).error && managerForm.$(input).error.length > 0;

    return (
        <Fragment>
            <TextInput
                error={inputHasError('name')}
                helperText={
                    inputHasError('name')
                        ? 'Please enter name of the admin'
                        : undefined
                }
                stretched
                {...managerForm.$('name').bind()}
            />
            <TextInput
                error={inputHasError('email')}
                helperText={
                    inputHasError('email')
                        ? 'Please enter a correct work email'
                        : undefined
                }
                stretched
                {...managerForm.$('email').bind()}
            />
            <TextInput
                select
                margin={'dense'}
                stretched
                variant={'outlined'}
                {...managerForm.$('role').bind()}
            >
                {managerForm.$('role').extra.map((t) => {
                    return (
                        <MenuItem key={t.value} value={t.value}>
                            {t.label}
                        </MenuItem>
                    );
                })}
            </TextInput>
            <CheckboxInput {...managerForm.$('sendWelcome').bind()} />
        </Fragment>
    );
}

export default observer(AddManager);
