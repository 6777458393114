import styled, { css } from 'styled-components';
import React from 'react';

const LockImg = styled.img`
    ${(props) =>
        props.small &&
        css`
            max-width: 32px;
            max-height: 32px;
        `};

    ${(props) =>
        props.topRight &&
        css`
            position: absolute;
            top: -8px;
            right: -8px;
            z-index: 1;
        `};
    ${(props) =>
        props.listItem &&
        css`
            margin: 0 12px 0 0;
        `};

    ${(props) =>
        props.hidden &&
        css`
            visibility: hidden;
        `};
`;

const LockOnCircle = (props) => {
    return props.outline ? (
        <LockImg {...props} src="/static/img/lock-on-circle-outline-icon.svg" />
    ) : (
        <LockImg {...props} src="/static/img/lock-on-circle-icon-small.svg" />
    );
};

export default LockOnCircle;
