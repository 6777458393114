import React, { Fragment, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
    FlexDiv,
    Heading2,
    TextDefault,
    TextLead,
    CopyToClipboardWrapper,
    ThemedButton,
    DropdownThemedButton,
} from '@awarego/awarego-components';
import ThemedSuccessSVG from '../../themed/ThemedSuccessSVG';
import { STATUS } from '../../../utils/constants';
import { formatDate } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/GetApp';
import CircularProgress from '@mui/material/CircularProgress';
import { useStores } from '../../../hooks/use-stores';


function StartScreen({
    createdAssessment,
    assessment,
    ongoing,
    myDomain,
    ssoEnabled,
    isScorm = false,
    onDownloadPackage,
    isPackageDownloading,
}) {
    const { commonStore } = useStores();
    const handleDownloadPackage = useCallback(
        (type) => {
            if (!createdAssessment) return;
            if (onDownloadPackage) {
                onDownloadPackage(
                    createdAssessment.id,
                    createdAssessment.uniqueId,
                    type
                );
            }
        },
        [createdAssessment, onDownloadPackage]
    );

    const actionBtns = useMemo(() => {
        if (!createdAssessment) return;
        const overviewBtn = (
            <ThemedButton
                as={Link}
                to={`/assessment/${isScorm ? 'p' : ''}${
                    createdAssessment.id
                }/view`}
                mode={isScorm ? 'secondary' : 'primary'}
                text="Go to assessment overview"
            />
        );
        if (!isScorm) return overviewBtn;
        return (
            <FlexDiv column gap={16} mt={16} alignCenter>
                <FlexDiv gap={24} alignCenter>
                    <DropdownThemedButton
                        text={
                            <FlexDiv gap={4} alignCenter>
                                {isPackageDownloading ? (
                                    <CircularProgress
                                        size={20}
                                        thickness={4}
                                        color="inherit"
                                    />
                                ) : (
                                    <DownloadIcon fontSize="small" />
                                )}
                                Download assessment
                            </FlexDiv>
                        }
                        btnProps={{
                            mode: 'primary',
                            $center: true,
                            style: { display: 'inline-flex!important' },
                        }}
                        items={[
                            {
                                text: 'SCORM 1.2',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('scorm12');
                                    },
                                },
                            },
                            {
                                text: 'SCORM 2004 3rd Edition',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('scorm2004');
                                    },
                                },
                            },
                            {
                                text: 'cmi5',
                                props: {
                                    onClick: () => {
                                        handleDownloadPackage('cmi5');
                                    },
                                },
                            },
                        ]}
                    />
                    {overviewBtn}
                </FlexDiv>
            </FlexDiv>
        );
    }, [
        createdAssessment,
        handleDownloadPackage,
        isPackageDownloading,
        isScorm,
    ]);

    return (
        <FlexDiv alignCenter justifyCenter column fullHeight gap={16}>
            <ThemedSuccessSVG />
            <Heading2 displayBlock>Success!</Heading2>
            <TextDefault lighter>
                {createdAssessment && createdAssessment.name} assessment{' '}
                {assessment.scheduleType !== -1 ? (
                    <Fragment>
                        has{' '}
                        {ongoing || assessment.status === STATUS.SCHEDULED
                            ? 'been successfully updated!'
                            : 'successfully started!'}
                        {assessment.scheduleType === 0 &&
                            !ongoing &&
                            ` Assessment invitation will be sent on ${
                                assessment.date && formatDate(assessment.date)
                            }`}
                    </Fragment>
                ) : (
                    'is ready and can be accessed at:'
                )}
            </TextDefault>
            {assessment.scheduleType === -1 && (
                <CopyToClipboardWrapper
                    copyText={`${myDomain}/assessments/${
                        createdAssessment.id
                    }?o=${assessment.companyId}${ssoEnabled ? '&sso=1' : ''}`}
                    onCopied={commonStore.copied}
                >
                    <TextLead bold>
                        {myDomain}/assessments/
                        {createdAssessment.id}
                        ?o=
                        {assessment.companyId}
                        {ssoEnabled ? '&sso=1' : ''}
                    </TextLead>
                </CopyToClipboardWrapper>
            )}
            {actionBtns}
        </FlexDiv>
    );
}

export default observer(StartScreen);
