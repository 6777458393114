import { makeAutoObservable } from 'mobx';

const STACK_MAX_LENGTH = 10;

class HistoryStore {
    historyStack = [];

    constructor() {
        makeAutoObservable(this);
    }

    savePath(path) {
        this.historyStack.push(path);

        if (this.historyStack.length > STACK_MAX_LENGTH) {
            this.historyStack.shift();
        }
    }

    /**
     * @returns {string}
     */
    get previousPath() {
        const index = this.historyStack.length - 1;
        return index < 1 ? '/' : this.historyStack[index];
    }
}

export default HistoryStore;
