import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    StatusBadge,
    SemiCircleProgress,
    Block,
} from '@awarego/awarego-components';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import DateCell from '../../../components/manager/DateCell';
import TableClient from '../../../components/table/TableClient';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import { LINKS } from '../../../utils/constants';
import {
    caseAndDiacriticsInsensitiveSort,
    statusAlphabeticalSort,
} from '../../../components/table/table-helpers';

function UserAssessmentsTable({ assessments }) {
    const maxVisibleLength = 30;
    const rowsPerPage = 5;

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    const areas = x.row.original._rawData?.definition.areas;
                    return (
                        <NameAndSublistCell
                            name={x.value}
                            maxVisibleNameLength={maxVisibleLength}
                            subListItems={areas}
                            maxVisibleSubListCount={2}
                            linkName={`/assessment/${x.row.original.id}`}
                        />
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo?.color}
                        text={x.row.original.statusInfo?.label}
                    />
                ),
                sortType: statusAlphabeticalSort,
            },
            {
                Header: 'Delivery date',
                accessor: 'deliveryDate',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Completion date',
                accessor: 'completionDate',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Due date',
                accessor: 'due',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Score',
                accessor: 'userScore',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        width={48}
                        top={14}
                        halfCircle
                        percentageText
                        showNA={!x.value}
                    />
                ),
            },
        ],
        []
    );

    return (
        <Block noBorder resetPadding>
            <TableClient
                columns={columns}
                data={assessments}
                hidePagination={
                    assessments && assessments.length <= rowsPerPage
                }
                defaultSortDescent={false}
                link={LINKS.assessment}
                NoDataComponent={
                    <NoItemsYetContent
                        img={'/static/img/icon-booklist.svg'}
                        imgAlt={'No assessments found'}
                        title={'No assessments found'}
                        text={'This employee has no assessments yet.'}
                    />
                }
            />
        </Block>
    );
}
export default observer(UserAssessmentsTable);
