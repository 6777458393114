import React, {
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    useCallback,
    Fragment,
} from 'react';
import {
    palette,
    FlexDiv,
    TextInput,
    TextLabel,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ScoreFilter({
    initialMinScore,
    initialMaxScore,
    scoreName,
    onScoreFilterApply,
}) {
    const [minScore, setMinScore] = useState(initialMinScore);
    const [maxScore, setMaxScore] = useState(initialMaxScore);
    const [tabIndex, setTabIndex] = useState(1);
    const scoreFromRef = useRef();
    const scoreToRef = useRef();
    const buttonRef = useRef();

    const scoreFilterRef = useRef();
    const [showScoreFilter, setShowScoreFilter] = useState(null);

    const handleApply = () => {
        if (minScore > maxScore) return;
        onScoreFilterApply(minScore, maxScore);
        closeScoreFilter();
    };

    const handleTab = useCallback(() => {
        let nextTab = tabIndex + 1;
        if (nextTab > 3) nextTab = 1;
        setTabIndex(nextTab);
    }, [tabIndex]);

    useImperativeHandle(
        scoreFilterRef,
        () => ({
            handleTab,
        }),
        [handleTab]
    );

    const onKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.stopPropagation();
            handleTab();
        }
        if (e.key === 'Enter') {
            handleApply();
        }
    };

    useEffect(() => {
        switch (tabIndex) {
            case 1:
                if (scoreFromRef && scoreFromRef.current)
                    scoreFromRef.current.select();
                break;
            case 2:
                if (scoreToRef && scoreToRef.current)
                    scoreToRef.current.select();
                break;
            case 3:
                if (buttonRef && buttonRef.current) buttonRef.current.focus();
                break;
        }
    }, [tabIndex]);

    const handleChangeMinScore = (e) => {
        if (e.target.value === '') return setMinScore(e.target.value); // support for empty string
        const num = Number(e.target.value);
        if (isNaN(num)) return; // ignore non digital input
        setMinScore(num);
    };

    const handleChangeMaxScore = (e) => {
        if (e.target.value === '') return setMaxScore(e.target.value);
        const num = Number(e.target.value);
        if (isNaN(num)) return; // ignore non digital input
        setMaxScore(num);
    };

    const openScoreFilter = (event) => {
        setShowScoreFilter(event.currentTarget);
    };

    const closeScoreFilter = () => {
        setShowScoreFilter(null);
    };

    return (
        <Fragment>
            <div className="assessment-filter-input" onClick={openScoreFilter}>
                <TextTiny bold>
                    {scoreName} ({minScore} - {maxScore})
                </TextTiny>
                <ArrowDropDownIcon fontSize="small" />
            </div>
            <Menu
                anchorEl={showScoreFilter}
                keepMounted
                open={Boolean(showScoreFilter)}
                onClose={closeScoreFilter}
                disableScrollLock={true}
            >
                {Boolean(showScoreFilter) && (
                    <FlexDiv
                        column
                        py={8}
                        px={16}
                        width={176}
                        onKeyDown={onKeyDown}
                    >
                        <TextTiny bold lighter mb={4}>
                            Select range
                        </TextTiny>
                        <FlexDiv $noWrap mb={8} alignCenter>
                            <TextInput
                                tabIndex={1}
                                inputRef={scoreFromRef}
                                value={minScore}
                                onChange={handleChangeMinScore}
                                heightSmall
                                inline
                                widthSmall
                            />
                            <TextTiny bold lighter mx={4}>
                                -
                            </TextTiny>
                            <TextInput
                                tabIndex={2}
                                inputRef={scoreToRef}
                                value={maxScore}
                                onChange={handleChangeMaxScore}
                                heightSmall
                                inline
                                widthSmall
                            />
                        </FlexDiv>
                        {minScore > maxScore && (
                            <TextLabel color={palette.vibrant.red} mb={8}>
                                Max value must be higher than min value.
                            </TextLabel>
                        )}
                        <ThemedButton
                            ref={buttonRef}
                            mode="primary"
                            size="small"
                            text="Show results"
                            $noTextTransform
                            disabled={minScore > maxScore}
                            onClick={handleApply}
                        />
                    </FlexDiv>
                )}
            </Menu>
        </Fragment>
    );
}

export default ScoreFilter;
