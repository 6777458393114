export async function handleSelectedFile(files, encoding, props) {
    let results = [];
    let acceptedFiles = [];
    const allowedExtensions = props.fileEndings
        ? props.fileEndings
        : props.fileType;

    if (typeof allowedExtensions === 'string') {
        for (let i = 0; i < files.length; i++) {
            let f = files[i];

            if (f.type && f.size > 0 && !f.name.endsWith(allowedExtensions)) {
                continue;
            }
            acceptedFiles.push(f);

            let text = await loadAsPromise(f, encoding);
            results.push(text);
        }
    } else if (
        Array.isArray(allowedExtensions) &&
        allowedExtensions.length > 0
    ) {
        for (let i = 0; i < files.length; i++) {
            let f = files[i];

            if (
                f.type &&
                f.size > 0 &&
                !allowedExtensions.includes(f.name.split('.').pop())
            ) {
                continue;
            }
            acceptedFiles.push(f);

            let text = await loadAsPromise(f, encoding);
            results.push(text);
        }
    } else {
        throw new Error('File extention is not allowed');
    }

    return { results: results, acceptedFiles: acceptedFiles };
}

function loadAsPromise(f, encoding) {
    return new Promise(async (resolve, reject) => {
        let reader = new FileReader();

        reader.onload = function (e) {
            let text = reader.result;
            resolve(text);
        };
        reader.onabort = function () {
            reject();
        };

        reader.readAsText(f, encoding);
    });
}
