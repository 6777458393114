import React, { useState } from 'react';
import { observer } from 'mobx-react';
import '../../containers/assessment/AssessmentsListView/AssessmentList.scss';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    Heading2,
    TextTiny,
    SearchInput,
} from '@awarego/awarego-components';
import { Dropdown, DropdownToggle } from 'reactstrap';
import ThemedSpinner from '../themed/ThemedSpinner';
import pluralize from 'pluralize';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterIcon from '@mui/icons-material/FilterList';
import RequireRole from '../../containers/RequireRole';

function HeaderSection({
    heading,
    subHeadings,

    /* Heading dropdown (caret) */
    headingDropdown,
    headingDropdownContentCount,
    /* Search */
    searchValue,
    searchPlaceholder,
    onSearch,
    onClearSearch,
    /* Filters */
    onShowFilter,
    filterCount,
    onClearFilters,

    buttons,
    contextMenu,
    alertSection,
    loadingData,
    excludeBlock = false, //Should this header be within another block instead of being in a stand-alone block?
    marginLeft = '0', // When using new tables, margin is set to 16px. Once table is replaced everywhere, we might consider removing it
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);

    const toggleExtraActionsDropdown = () => {
        setContextMenuOpen(!contextMenuOpen);
    };
    const toggleHeadingDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const content = (
        <FlexDiv mb={excludeBlock ? 16 : null}>
            <SpaceBetweenDiv $noWrap alignStart={subHeadings != null} gap={8}>
                <FlexDiv column gap={8} ml={marginLeft}>
                    <FlexDiv>
                        <Heading2>{heading}</Heading2>
                        {/** Caret dropdown after header */}
                        {headingDropdown ? (
                            <FlexDiv ml={4} mr={8} link>
                                {headingDropdownContentCount > 0 && (
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggleHeadingDropdown}
                                    >
                                        <DropdownToggle caret tag="span" />

                                        {headingDropdown}
                                    </Dropdown>
                                )}
                            </FlexDiv>
                        ) : null}
                    </FlexDiv>
                    {/** All the Sub heading lines */}
                    {subHeadings}
                </FlexDiv>
                <FlexDiv gap={16}>
                    {/** Search */}
                    {onSearch ? (
                        <SearchInput
                            searchValue={searchValue || ''}
                            placeholder={searchPlaceholder}
                            onChange={onSearch}
                            onClear={onClearSearch}
                        />
                    ) : null}
                    {/** Filter */}
                    {onShowFilter ? (
                        <div
                            className="assessment-filter-input"
                            onClick={onShowFilter}
                        >
                            <TextTiny bold lighter={filterCount === 0} link>
                                {filterCount > 0
                                    ? `${filterCount} ${pluralize(
                                          'Filter',
                                          filterCount
                                      )}`
                                    : 'Filters'}
                            </TextTiny>
                            <div>
                                {filterCount > 0 && (
                                    <CancelIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onClearFilters();
                                        }}
                                        fontSize="small"
                                        style={{
                                            cursor: 'pointer ',
                                        }}
                                    />
                                )}
                                <FilterIcon fontSize="small" />
                            </div>
                        </div>
                    ) : null}
                    {/** Buttons */}
                    <RequireRole>{buttons}</RequireRole>
                    {/** Context menu (three dots) */}
                    {contextMenu ? (
                        <Dropdown
                            isOpen={contextMenuOpen}
                            toggle={toggleExtraActionsDropdown}
                        >
                            <DropdownToggle
                                onClick={() => toggleExtraActionsDropdown}
                                className="btn btn-three-dots"
                            />
                            {contextMenu}
                        </Dropdown>
                    ) : null}
                </FlexDiv>
            </SpaceBetweenDiv>
            {alertSection ? alertSection : null}
        </FlexDiv>
    );

    // Render the content with or without the Block element based on the excludeBlock prop
    if (excludeBlock) {
        return loadingData ? <ThemedSpinner /> : content;
    } else {
        return <Block>{loadingData ? <ThemedSpinner /> : content}</Block>;
    }
}

export default observer(HeaderSection);
