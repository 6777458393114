import React, { useState } from 'react';
import { observer } from 'mobx-react';
import FiftyFiftyDiv from './fifty-fifty-div';
import {
    FlexDiv,
    Heading3,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';
import LanguageSelector from '../language-selector';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/GetApp';
import MaterialImage from './material-image';

function MaterialView({ material }) {
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    const selectLanguage = (languageKey) => {
        setSelectedLanguage(languageKey);
    };

    const downloadFile = () => {
        fetch(material.files[selectedLanguage].file).then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = material.files[selectedLanguage].originalName;
                alink.click();
            });
        });
    };

    const content = () => {
        return (
            <FlexDiv column justifyCenter gap={8}>
                <Heading3 displayBlock>{material.title}</Heading3>
                <TextDefault>{material.description}</TextDefault>
                <FlexDiv>
                    <LanguageSelector
                        selectedLanguage={selectedLanguage}
                        handleSelectLanguage={selectLanguage}
                        availableLanguages={material.languages}
                    />
                </FlexDiv>
                <FlexDiv>
                    <ThemedButton
                        mode="primary"
                        onClick={() => downloadFile()}
                        disabled={!selectedLanguage}
                        text={
                            <>
                                <FlexDiv gap={4} alignCenter>
                                    {material === 2 ? (
                                        <CircularProgress
                                            size={20}
                                            thickness={4}
                                            color="inherit"
                                        />
                                    ) : (
                                        <DownloadIcon fontSize="small" />
                                    )}
                                    Download
                                </FlexDiv>
                            </>
                        }
                    />
                </FlexDiv>
            </FlexDiv>
        );
    };

    return (
        <FiftyFiftyDiv
            leftHalf={<MaterialImage src={material.image} />}
            rightHalf={content()}
        />
    );
}

export default observer(MaterialView);
