import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
    FlexDiv,
    Heading2,
    TextDefault,
    ThemedButton,
    DropdownThemedButton,
} from '@awarego/awarego-components';
import ThemedSuccessSVG from '../../themed/ThemedSuccessSVG';
import { AUTOMATION_SCHEDULE_TYPE, STATUS } from '../../../utils/constants';
import { formatDate } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/GetApp';
import CircularProgress from '@mui/material/CircularProgress';

function StartScreen({
    createdTraining,
    training,
    signInLinksUrl,
    ongoing,
    isScorm = false,
    onDownloadPackage,
    isPackageDownloading,
}) {
    const handleDownloadPackage = useCallback(
        (type) => {
            if (!createdTraining) return;
            if (onDownloadPackage) {
                onDownloadPackage(
                    createdTraining.id,
                    createdTraining.uniqueId,
                    type
                );
            }
        },
        [createdTraining, onDownloadPackage]
    );

    const scormDownloadBtn = useMemo(() => {
        if (!createdTraining) return;
        return (
            <FlexDiv column gap={16} alignCenter>
                <DropdownThemedButton
                    text={
                        <FlexDiv gap={4} alignCenter>
                            {isPackageDownloading ? (
                                <CircularProgress
                                    size={20}
                                    thickness={4}
                                    color="inherit"
                                />
                            ) : (
                                <DownloadIcon fontSize="small" />
                            )}
                            Download training
                        </FlexDiv>
                    }
                    btnProps={{
                        mode: 'primary',
                        $center: true,
                        style: { display: 'inline-flex!important' },
                    }}
                    items={[
                        {
                            text: 'SCORM 1.2',
                            props: {
                                onClick: () => {
                                    handleDownloadPackage('scorm12');
                                },
                            },
                        },
                        {
                            text: 'SCORM 2004 3rd Edition',
                            props: {
                                onClick: () => {
                                    handleDownloadPackage('scorm2004');
                                },
                            },
                        },
                        {
                            text: 'cmi5',
                            props: {
                                onClick: () => {
                                    handleDownloadPackage('cmi5');
                                },
                            },
                        },
                    ]}
                />
            </FlexDiv>
        );
    }, [createdTraining, handleDownloadPackage, isPackageDownloading]);

    return (
        <FlexDiv alignCenter justifyCenter column fullHeight>
            <ThemedSuccessSVG />
            <Heading2 displayBlock my={16}>
                Success!
            </Heading2>
            <TextDefault lighter mb={16}>
                {createdTraining && createdTraining.name} training has{' '}
                {ongoing || training.status === STATUS.SCHEDULED
                    ? 'been successfully updated!'
                    : 'successfully started!'}
                {!training.isScorm &&
                    training.scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE &&
                    !ongoing &&
                    ` Training invitation will be sent on ${
                        training.deliverySchedule &&
                        training.deliverySchedule.length > 0 &&
                        formatDate(training.deliverySchedule[0].date)
                    }`}
            </TextDefault>
            {training.scheduleType ===
                AUTOMATION_SCHEDULE_TYPE.DOWNLOAD_LINKS && (
                <FlexDiv mb={16}>
                    <ThemedButton
                        mode="primary"
                        as="a"
                        href={signInLinksUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        text={
                            <>
                                Download sign in links{' '}
                                <DownloadIcon fontSize="small" />
                            </>
                        }
                    />
                </FlexDiv>
            )}
            {!isScorm ? (
                <Link
                    to={`/trainings/${isScorm ? 'p' : ''}${
                        createdTraining.id
                    }/view`}
                >
                    <ThemedButton
                        mode="primary"
                        text="Go to training overview"
                    />
                </Link>
            ) : (
                scormDownloadBtn
            )}
        </FlexDiv>
    );
}

export default observer(StartScreen);
