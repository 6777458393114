import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    CopyToClipboardWrapper,
    palette,
    StatusBadge,
    TextTiny,
} from '@awarego/awarego-components';
import Box from '@mui/material/Box';
import SubHeaderText from '../../../../components/header/SubHeaderText';
import pluralize from 'pluralize';
import SubHeaderLink from '../../../../components/header/SubHeaderLink';
import moment from 'moment/moment';
import { STATUS } from '../../../../utils/constants';
import SubHeaderSeparator from '../../../../components/header/SubHeaderSeparator';
import { useStores } from '../../../../hooks/use-stores';

function SubHeading({
    editingAutomation,
    templateStandards,
    bouncedEmails,
    showBouncedModal,
    automationId,
    companyId,
    ssoEnabled,
}) {
    const { brandingStore, commonStore } = useStores();
    console.log(editingAutomation.standards, templateStandards);
    if (!editingAutomation) return;
    return (
        <Fragment>
            <StatusBadge
                color={editingAutomation.statusInfo.color}
                text={editingAutomation.statusInfo.label}
            />
            {templateStandards &&
                (editingAutomation?.standards || []).length > 0 && (
                    <Box
                        display="flex"
                        style={{
                            gap: 4,
                        }}
                    >
                        {(editingAutomation?.standards || []).map((s) => {
                            return (
                                <StatusBadge
                                    key={s}
                                    color={palette.graphite.heavyMetal}
                                    text={s}
                                />
                            );
                        })}
                    </Box>
                )}
            <SubHeaderText
                text={
                    editingAutomation._rawData.totalUsers +
                    ' ' +
                    pluralize(
                        'employee',
                        editingAutomation._rawData.totalUsers
                    ) +
                    ' invited'
                }
            />
            {bouncedEmails > 0 && (
                <SubHeaderLink
                    text={
                        bouncedEmails +
                        ' ' +
                        pluralize('email', bouncedEmails) +
                        ' bounced'
                    }
                    color={palette.vibrant.red}
                    onClick={showBouncedModal}
                />
            )}
            <SubHeaderText text={editingAutomation.startDateText} separator />
            <SubHeaderText
                text={
                    'Last delivery on ' +
                    moment(editingAutomation.endDate).format('ll')
                }
                separator
            />
            {editingAutomation.finishDate && (
                <SubHeaderText
                    text={
                        'Due ' +
                        moment(editingAutomation.finishDate).format('ll[,] LT')
                    }
                    separator
                />
            )}
            {(editingAutomation.status === STATUS.ACTIVE ||
                editingAutomation.status === STATUS.COMPLETED) && (
                <>
                    <SubHeaderSeparator />
                    <CopyToClipboardWrapper
                        copyText={`${
                            brandingStore.myDomain
                        }/trainings/${automationId}?o=${companyId}${
                            ssoEnabled ? '&sso=1' : ''
                        }`}
                        onCopied={commonStore.copied}
                    >
                        <TextTiny lighter>Copy link</TextTiny>
                    </CopyToClipboardWrapper>
                </>
            )}
        </Fragment>
    );
}

export default observer(SubHeading);
