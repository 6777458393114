import React, { Fragment, useEffect, useState, useMemo } from 'react';
import AssessmentQuestionPreview from '../../../containers/assessment/AssessmentQuestionPreview';
import { useStores } from '../../../hooks/use-stores';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import './preview-question.scss';
import SemiCircleProgress from './semi-circle-progress';
import { calculateColor, calculateScoreTitle } from '../../../utils/helpers';
import CallToActionBanner from '../../CallToActionBanner';
import {
    Image,
    Heading2,
    FlexDiv,
    TextLead,
    TextDefault,
} from '@awarego/awarego-components';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import PreviewQuestionDetails from './preview-question-details';
import { useTranslation } from 'react-i18next';
import PreviewModalHeader from '../../../containers/previewModalHeader';
import Link from "@mui/material/Link";

function PreviewQuestion({
    questionId,
    onRequestClose,
    type,
    companyId,
    assessmentId,
    questionsSelected,
    hasMoreQuestions,
    questionsReportList,
    isWizard,
    redirectToUpgrade,
    isScorm,
    hideFooter,
    showLanguageSelector,
    defaultLanguage = 'en',
}) {
    const { questionStore, store, brandingStore } = useStores();
    const { question, loadingData } = questionStore;
    const { havePartnerSubscription } = store;
    const { supportEmail } = brandingStore;
    const [selectedQuestionId, setSelectedQuestionId] = useState(questionId);
    const [isMovingBack, setIsMovingBack] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { i18n } = useTranslation('assessment');

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    const handleQuestionDetailsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleQuestionDetailsClose = () => {
        setAnchorEl(null);
    };

    const questionDetailsOpen = Boolean(anchorEl);

    const chosenQuestionReport = useMemo(() => {
        if (!question || !questionsReportList || !questionsSelected) return;
        if (
            questionsSelected &&
            questionsSelected.length === questionsReportList.length
        )
            return questionsReportList[questionsSelected.indexOf(question.id)];
        return questionsReportList.find((q) => q.id === question.id);
    }, [question, questionsReportList, questionsSelected]);

    const averageQuestionScore = useMemo(() => {
        if (chosenQuestionReport) {
            return chosenQuestionReport.average_score_percentage;
        }
        return 0;
    }, [chosenQuestionReport]);

    /** The header component this component uses (PreviewModalHeader) has a property
     * to add elements to the left of its right aligned 'X' to close. This is the details
     * element we will add there for this assessment preview component. */
    const questionDetails = useMemo(() => {
        if (!question) return;
        return (
            <>
                <Link onClick={handleQuestionDetailsClick}>
                    <TextDefault
                        themed
                        bold
                        flex
                        gap={2}
                        link
                    >
                        <InfoIcon fontSize="small" />
                        Scenario details
                    </TextDefault>
                </Link>
                    {hasMoreQuestions && (
                        <TextLead>
                            {questionsSelected.indexOf(selectedQuestionId) + 1}/
                            {questionsSelected.length}{' '}
                            {pluralize('question', questionsSelected.length)}
                        </TextLead>
                    )}

            </>
        );
    }, [question]);

    useEffect(() => {
        setSelectedQuestionId(questionId);
        selectLanguage(defaultLanguage);
    }, [questionId]);

    useEffect(() => {
        if (type === 'questionReview')
            questionStore.loadQuestionReview(
                companyId,
                selectedQuestionId,
                `${isScorm ? 'p' : ''}${assessmentId}`
            );
        else questionStore.loadQuestionPreview(companyId, selectedQuestionId);
    }, [
        companyId,
        selectedQuestionId,
        type,
        assessmentId,
        questionStore,
        isScorm,
    ]);

    const selectLanguage = (languageKey) => {
        setSelectedLanguage(languageKey);
        i18n.changeLanguage(languageKey);
    };

    if (!question) return null;

    return (
        <Fragment>
            {type === 'questionReview' ? (
                <div className={'modalHeader'}>
                    <div className="close-btn-with-text">
                        <Image
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onRequestClose}
                            link
                        />
                        {hasMoreQuestions && (
                            <Fragment>
                                {questionsSelected.indexOf(selectedQuestionId) +
                                    1}
                                /{questionsSelected.length}{' '}
                                {pluralize(
                                    'question',
                                    questionsSelected.length
                                )}
                            </Fragment>
                        )}
                    </div>
                    {!loadingData && (
                        <FlexDiv gap={8}>
                            <Heading2>{question.name}</Heading2>-
                            <Heading2
                                bold
                                color={calculateColor(averageQuestionScore)}
                                mr={8}
                            >
                                {calculateScoreTitle(averageQuestionScore)}
                            </Heading2>
                            <SemiCircleProgress
                                percentage={averageQuestionScore}
                                width={48}
                                top={14}
                                halfCircle
                                mt={-4}
                                percentageText
                            />
                        </FlexDiv>
                    )}
                    {/* <div className="assessment-show-results-for">
                        <p>Show results for</p>
                        <FormControl variant="outlined">
                            <Select
                                name="platform"
                                color="primary"
                                value={platform}
                                onChange={(e) => {
                                    assessmentStore.setPlatform(e.target.value);
                                }}
                            >
                                <MenuItem value="all_platforms">
                                    All platforms
                                </MenuItem>
                                <MenuItem value="mobile">Mobile</MenuItem>
                                <MenuItem value="desktop">Desktop</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                </div>
            ) : (
                <div>
                    <PreviewModalHeader
                        titlePrefix={'Preview:'}
                        title={!loadingData && question.name}
                        showLanguageSelector={showLanguageSelector}
                        availableLanguages={question.languages}
                        onClose={onRequestClose}
                        onSelectLanguage={selectLanguage}
                        selectedLanguage={selectedLanguage}
                        details={questionDetails}
                    />
                </div>
            )}
            {question.disabled && (
                <CallToActionBanner
                    isPartnerCompany={havePartnerSubscription}
                    supportEmail={supportEmail}
                    contentType={'question'}
                    redirectToUpgrade={() => {
                        redirectToUpgrade();
                        onRequestClose();
                    }}
                />
            )}
            <AssessmentQuestionPreview
                questionsSelected={questionsSelected}
                selectedQuestionId={selectedQuestionId}
                setSelectedQuestionId={setSelectedQuestionId}
                hasMoreQuestions={hasMoreQuestions}
                isMovingBack={isMovingBack}
                setIsMovingBack={setIsMovingBack}
                onRequestClose={onRequestClose}
                question={question}
                companyId={companyId}
                isReview={type === 'questionReview'}
                loadingData={loadingData}
                isWizard={isWizard}
                hideFooter={hideFooter}
            />
            <Popover
                open={questionDetailsOpen}
                anchorEl={anchorEl}
                onClose={handleQuestionDetailsClose}
                elevation={1}
            >
                <PreviewQuestionDetails
                    handleQuestionDetailsClose={handleQuestionDetailsClose}
                    question={question}
                />
            </Popover>
        </Fragment>
    );
}

export default observer(PreviewQuestion);
