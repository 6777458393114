import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from '@mui/material';
import { TextDefault } from '@awarego/awarego-components';

/** Formatting of a name/title in a table cell */
function NameCell({ name, maxVisibleNameLength }) {
    return (
        <div>
            {(name || '').length > maxVisibleNameLength ? (
                <Tooltip title={name || ''}>
                    <span>
                        <TextDefault bold>
                            {(name || '').substring(0, maxVisibleNameLength)}
                            ...
                        </TextDefault>
                    </span>
                </Tooltip>
            ) : (
                <TextDefault bold>{name}</TextDefault>
            )}
        </div>
    );
}

export default observer(NameCell);
