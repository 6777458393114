import React, { useEffect, useState } from 'react';
import { Block } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import SubjectPreviewContainer from '../../../subjectPreview';
import RecommendationHeader from './RecommendationHeader';
import RecommendationSubheader from './RecommendationSubheader';
import RecommendationVideos from './RecommendationVideos';
import NoItemsYetContent from '../../../../components/manager/no-items-yet-content';

function RecommendationContainer({ companyId }) {
    const { assessmentStore, catalogStore, store } = useStores();
    const [previewLocation, setPreviewLocation] = useState(null);
    const [previewSubjectModal, setPreviewSubjectModal] = useState(false);
    const { cmi5enabled } = store;
    const [selectedListId, setSelectedListId] = useState('all');
    let [areas, setAreas] = useState(null);
    let [recommendations, setRecommendations] = useState(null);

    const { editingAssessment } = assessmentStore;
    useEffect(() => {
        doLoadData();
    }, []);

    useEffect(() => {
        refreshAreas();
        refreshRecommendations();
    }, [
        selectedListId,
        editingAssessment.areas,
        editingAssessment.recommendations,
    ]);

    const refreshAreas = () => {
        let sortedAreas;
        if (selectedListId == 'all') {
            sortedAreas = editingAssessment.areas
                .slice()
                .sort((a, b) => a.score - b.score);
        } else {
            if (editingAssessment._rawData.areasListReport) {
                const listsWithAreas =
                    editingAssessment._rawData.areasListReport;
                const list = listsWithAreas.find(
                    (list) => list.listId == selectedListId
                );
                sortedAreas = list.areas
                    .slice()
                    .sort((a, b) => a.score - b.score);
            }
        }
        setAreas(sortedAreas);
    };

    const refreshRecommendations = () => {
        let videoRecommendations;
        if (selectedListId == 'all') {
            videoRecommendations = editingAssessment.recommendations.videos;
        } else {
            if (editingAssessment._rawData.recommendationsByList) {
                const foundList =
                    editingAssessment._rawData.recommendationsByList[
                        selectedListId
                    ];
                if (foundList != undefined) {
                    videoRecommendations = foundList.videos;
                } else {
                    videoRecommendations = [];
                }
            }
        }
        setRecommendations(videoRecommendations);
    };

    const doLoadData = async () => {
        await catalogStore.loadCatalog(companyId);
        await catalogStore.loadThreatAreaCatalog(companyId);
    };

    const handleVideoClick = (subjectId) => {
        setPreviewLocation(subjectId);
        setPreviewSubjectModal(true);
    };

    const hidePreviewSubjectModal = () => {
        setPreviewSubjectModal(false);
    };

    const reloadVideos = (listId) => {
        setSelectedListId(listId);
    };

    return (
        <>
            <Block>
                {editingAssessment &&
                ((editingAssessment.isScorm && recommendations && areas) ||
                    (!editingAssessment.isScorm &&
                        editingAssessment._rawData.areasListReport &&
                        recommendations &&
                        areas)) ? (
                    <>
                        <RecommendationHeader
                            listId={selectedListId}
                            cmi5enabled={cmi5enabled}
                        ></RecommendationHeader>
                        <hr style={{ margin: '0px' }} />
                        <RecommendationSubheader
                            videos={recommendations}
                            lists={
                                editingAssessment.isScorm
                                    ? null
                                    : editingAssessment._rawData.areasListReport
                            }
                            reloadVideos={reloadVideos}
                        />

                        <RecommendationVideos
                            handleVideoClick={handleVideoClick}
                            sortedAreas={areas}
                            recommendedVideos={recommendations}
                        />
                    </>
                ) : (
                    <NoItemsYetContent
                        img={'/static/img/icon-booklist.svg'}
                        imgAlt={'No recommendations available'}
                        title={'No recommendations available'}
                    />
                )}
            </Block>

            {previewSubjectModal && (
                <SubjectPreviewContainer
                    isOpen={previewSubjectModal}
                    onClose={hidePreviewSubjectModal}
                    subjectId={previewLocation}
                    hideFooter={true}
                />
            )}
        </>
    );
}

export default observer(RecommendationContainer);
