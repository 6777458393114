/**
 * @param {number} scheduleType
 * @returns {string}
 */
export const translateTrainingScheduleType = (scheduleType) => {
    switch (scheduleType) {
        case 0:
            return 'Schedule delivery';
        case 1:
            return 'Send everything now';
        case 2:
            return 'Download sign in links';
        default:
            return 'Unknown schedule type';
    }
};

/**
 * @param {number} scheduleType
 * @returns {string}
 */
export const translateAssessmentScheduleType = (scheduleType) => {
    switch (scheduleType) {
        case 0:
            return 'Schedule';
        case 1:
            return 'Now';
        default:
            return 'Unknown schedule type';
    }
};
