import React, { Fragment, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../themed/ThemedSpinner';
import EyeIcon from '@mui/icons-material/Visibility';
import AddRemoveButton from '../../AddRemoveButton';
import {
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    TextTiny,
    Heading2,
    Heading3,
    LightTooltip,
} from '@awarego/awarego-components';
import LockOnCircle from '../../LockOnCircle';
import pluralize from 'pluralize';

function QuestionSelect({
    questions,
    onQuestionSelected,
    selection = [],
    showPreviewQuestionsModal,
    area = 'all',
    behaviour = 'all',
}) {
    const displayedQuestions = useMemo(
        () =>
            questions.filter((q) => {
                return (
                    (area === 'all' || q.area === area) &&
                    (behaviour === 'all' ||
                        q.behaviours.some((b) => b.code === behaviour))
                );
            }),
        [questions, area, behaviour]
    );

    const questionsByArea = useMemo(
        () => groupBy(displayedQuestions, 'area'),
        [displayedQuestions]
    );
    return (
        <Fragment>
            {!questionsByArea ? (
                <ThemedSpinner />
            ) : Object.keys(questionsByArea).length === 0 ? (
                <div className="no-assessments-found">
                    <img
                        src="/static/img/icon-booklist.svg"
                        alt="No scenarios found"
                    />
                    <div className="stats">
                        <Heading3 mb={8} displayBlock>
                            No scenarios found
                        </Heading3>
                        <p>
                            Try adjusting the filters to find what you were
                            looking for.
                        </p>
                    </div>
                </div>
            ) : (
                Object.keys(questionsByArea).map((a) => (
                    <Fragment key={a}>
                        <Heading2 my={14}>{a}</Heading2>
                        {questionsByArea[a].map((question, j) => (
                            <SpaceBetweenDiv py={16} key={j}>
                                {/* add/remove button */}
                                <FlexDiv alignCenter mr={8}>
                                    <AddRemoveButton
                                        selection={selection}
                                        itemId={question.id}
                                        onClick={() => {
                                            onQuestionSelected(question.id);
                                        }}
                                    />
                                </FlexDiv>
                                {/* question description */}
                                <FlexDiv
                                    column
                                    fullWidth
                                    maxWidth={500}
                                    mr={40}
                                    gap={4}
                                    link
                                    onClick={() => {
                                        onQuestionSelected(question.id);
                                    }}
                                >
                                    <TextDefault
                                        bold
                                        lighter={question.disabled}
                                        lineHeight={'initial'}
                                    >
                                        {question.name}
                                    </TextDefault>
                                    <TextDefault lineHeight={'initial'} t>
                                        {question.adminDescription}
                                    </TextDefault>
                                    <FlexDiv gap={2}>
                                        <TextTiny lighter>
                                            {question.difficulty
                                                .charAt(0)
                                                .toUpperCase() +
                                                question.difficulty.slice(
                                                    1
                                                )}{' '}
                                            ·
                                        </TextTiny>
                                        <TextTiny lighter>
                                            <LightTooltip
                                                title={question.behaviours.map(
                                                    (behaviour, i) => (
                                                        <div key={i}>
                                                            {behaviour.name}
                                                        </div>
                                                    )
                                                )}
                                            >
                                                <u>
                                                    {question.behaviours.length}{' '}
                                                    {pluralize(
                                                        'key behaviour',
                                                        question.behaviours
                                                            .length
                                                    )}
                                                </u>
                                            </LightTooltip>
                                        </TextTiny>
                                    </FlexDiv>
                                </FlexDiv>
                                <FlexDiv width={30}>
                                    <LockOnCircle hidden={!question.disabled} />
                                </FlexDiv>

                                {/* question preview */}
                                <TextDefault
                                    themed
                                    bold
                                    link
                                    onClick={() => {
                                        showPreviewQuestionsModal(
                                            question.id,
                                            false
                                        );
                                    }}
                                    mr={8}
                                >
                                    <EyeIcon
                                        alt="preview video"
                                        fontSize="small"
                                    />{' '}
                                    Preview
                                </TextDefault>
                            </SpaceBetweenDiv>
                        ))}
                    </Fragment>
                ))
            )}
        </Fragment>
    );
}

export default observer(QuestionSelect);
