import React from 'react';
import {
    Heading2,
    Block,
    FlexDiv,
    TextDefault,
} from '@awarego/awarego-components';
import styled from 'styled-components';

const ExpandImage = styled.div`
    margin: -32px;
    margin-left: 0;

    @media (max-width: 992px) {
        display: none !important;
    }
`;

function BlockWithRightAlignedImage({ heading, text, img, imgAlt }) {
    return (
        <Block>
            <FlexDiv $noWrap>
                <FlexDiv column>
                    <Heading2 mb={16}>{heading}</Heading2>
                    <TextDefault lighter>{text}</TextDefault>
                </FlexDiv>
                <ExpandImage>
                    <img src={img} alt={imgAlt} />
                </ExpandImage>
            </FlexDiv>
        </Block>
    );
}

export default BlockWithRightAlignedImage;
