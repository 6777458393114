import RestService from './RestService';

export default class BouncedEmails extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/bounced-emails`);
        } else {
            throw new Error(
                'BouncedEmails can only be used as a nested service'
            );
        }
    }
}
