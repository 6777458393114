import React, { Fragment, useState, useMemo } from 'react';
import { MenuItem, Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './AssessmentListTable.scss';
import SemiCircleProgress from '../../../components/manager/assessment/semi-circle-progress';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import DateCell from '../../../components/manager/DateCell';
import { LINKS, STATUS } from '../../../utils/constants';
import { calculateColor } from '../../../utils/helpers';
import {
    TextTiny,
    StatusBadge,
    LightTooltip,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import RequireRole from '../../RequireRole';
import TableClient from '../../../components/table/TableClient';
import {
    caseAndDiacriticsInsensitiveSort,
    statusAlphabeticalSort,
} from '../../../components/table/table-helpers';

function AssessmentListTable({
    assessments,
    onDelete,
    pauseAssessment,
    lists,
    defaultSortBy,
}) {
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [assessment, setOpenedAssessment] = useState(null);
    const rowsPerPage = 10;
    const maxVisibleLength = 30;

    const columns = useMemo(
        () => [
            {
                Header: 'Assessment',
                accessor: 'name',
                Cell: (x) => {
                    const areas = x.row.original._rawData.definition.areas;
                    return (
                        <NameAndSublistCell
                            name={x.value}
                            maxVisibleNameLength={maxVisibleLength}
                            subListItems={areas}
                            maxVisibleSubListCount={2}
                            linkName={`/assessment/${x.row.original.id}`}
                        />
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Status',
                accessor: '_rawData.status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo.color}
                        text={x.row.original.statusInfo.label}
                    />
                ),
                sortType: statusAlphabeticalSort,
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalEmployees',
                Cell: (x) => {
                    if (!x.row.original._rawData.definition.userLists)
                        return '';
                    const listNames = lists.filter((list) =>
                        x.row.original._rawData.definition.userLists.includes(
                            list.id
                        )
                    );
                    return listNames.length > 0 ? (
                        <LightTooltip
                            title={listNames.map((list) => (
                                <div key={list.id}>{list.name}</div>
                            ))}
                        >
                            <TextTiny underline lighter>
                                {x.value}
                            </TextTiny>
                        </LightTooltip>
                    ) : (
                        <TextTiny lighter>0</TextTiny>
                    );
                },
                sortDescFirst: true,
            },
            {
                Header: 'Delivery date',
                accessor: 'deliveryDate',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Due date',
                accessor: '_rawData.due',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Completion',
                accessor: 'attendance',
                Cell: (x) =>
                    x.row.original.status === STATUS.ACTIVE ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.usersFinished === 1 ? (
                        <Fragment>
                            <div>
                                <TextTiny bold color={calculateColor(x.value)}>
                                    {x.value}%
                                </TextTiny>
                            </div>
                            <TextTiny lighter>
                                {x.row.original._rawData.usersFinished}/
                                {x.row.original._rawData.totalEmployees}
                            </TextTiny>
                        </Fragment>
                    ) : (
                        ''
                    ),
                sortDescFirst: true,
            },
            {
                Header: 'Resilience score',
                accessor: 'resilienceScore',
                Cell: (x) => {
                    return x.row.original.status === STATUS.ACTIVE ||
                        x.row.original.status === STATUS.COMPLETED ||
                        x.row.original._rawData.usersFinished === 1 ? (
                        <SemiCircleProgress
                            percentage={x.value}
                            width={48}
                            top={14}
                            halfCircle
                            percentageText
                            showNA={!x.row.original._rawData.started}
                        />
                    ) : (
                        ''
                    );
                },
                tooltip:
                    'Average employee score across all assessed threat areas.',
                sortDescFirst: true,
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
            {
                Header: 'Last Updated',
                accessor: '_rawData.last_updated',
                disableSortBy: false,
                disableFilters: false,
                show: false,
            },
        ],
        [lists, caseAndDiacriticsInsensitiveSort]
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <DarkArrowTooltipIcon
                    customFunction={handleMenuOpen}
                    row={row}
                    title="More"
                    icon={'more'}
                ></DarkArrowTooltipIcon>
            </>
        );
    };

    const handleMenuOpen = (e, assessment) => {
        setOpenedAssessment(assessment);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedAssessment(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (
        <Fragment>
            <TableClient
                data={assessments}
                columns={columns}
                link={LINKS.assessment}
                defaultSortBy={defaultSortBy}
                hiddenColumns={['_rawData.last_updated']}
                initialPageSize={rowsPerPage}
                hidePagination={assessments.length <= rowsPerPage}
            />
            {assessment && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={assessment != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    <MenuItem
                        onClick={(e) => {
                            navigate(assessment.link);
                            handleMenuClose(e);
                        }}
                    >
                        Open
                    </MenuItem>
                    <RequireRole>
                        <MenuItem
                            onClick={(e) => {
                                navigate(
                                    `/assessment/create/copy/${assessment.id}`
                                );
                                handleMenuClose(e);
                            }}
                        >
                            Duplicate
                        </MenuItem>
                        {(assessment.status === STATUS.DRAFT ||
                            assessment.status === STATUS.SCHEDULED ||
                            assessment.status === STATUS.ACTIVE) && (
                            <MenuItem
                                onClick={(e) => {
                                    navigate(
                                        `/assessment/edit/${assessment.id}`
                                    );
                                    handleMenuClose(e);
                                }}
                            >
                                Edit
                            </MenuItem>
                        )}
                        {assessment.status === STATUS.SCHEDULED && (
                            <MenuItem
                                onClick={(e) => {
                                    pauseAssessment(assessment.id);
                                    handleMenuClose(e);
                                }}
                            >
                                Stop
                            </MenuItem>
                        )}
                        {(assessment.status === STATUS.DRAFT ||
                            assessment.status === STATUS.SCHEDULED) && (
                            <MenuItem
                                onClick={(e) => {
                                    onDelete(assessment.id, false);
                                    handleMenuClose(e);
                                }}
                            >
                                <span style={{ color: 'red' }}>Delete</span>
                            </MenuItem>
                        )}
                    </RequireRole>
                </Menu>
            )}
        </Fragment>
    );
}

export default AssessmentListTable;
