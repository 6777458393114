import React, { useMemo, useState } from 'react';
import { FlexDiv, Image } from '@awarego/awarego-components';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    thumb: {
        objectPosition: 'center center',
        maxHeight: 150,
        // [theme.breakpoints.down('sm')]: { maxHeight: 108 },
    },
}));

function FallbackImage({ src, fallback, fullWidthThumb }) {
    const [useFallback, setUseFallback] = useState(false);

    const classes = useStyles();

    const addDefaultSrc = (ev) => {
        setUseFallback(true);
        ev.target.src = fallback;
    };

    const effectiveSrc = useMemo(() => {
        if (useFallback) return fallback;
        if (!src) return fallback;
        return src;
    }, [src, fallback, useFallback]);

    return (
        <Image
            className={classes.thumb}
            height={fullWidthThumb ? 'auto' : 108}
            fullWidth={fullWidthThumb}
            alt="video thumbnail"
            borderRadiusTop={4}
            coverTop
            src={effectiveSrc}
            onError={addDefaultSrc}
        />
    );
}

function Img({ src, type, fullWidthThumb }) {
    const fallback = useMemo(() => {
        switch (type) {
            case 'assessment':
                return (
                    window.location.origin +
                    `/static/img/catview-thumb.png`
                );
            case 'training':
                return (
                    window.location.origin +
                    `/static/img/preview-thumb.png`
                );
            case 'material/book':
            case 'material/poster':
                return (
                    window.location.origin +
                    `/static/img/preview-thumb.png`
                );
            case 'material/sticker':
                return (
                    window.location.origin +
                    `/static/img/preview-thumb.png`
                );
            default:
                return (
                    window.location.origin +
                    `/static/img/preview-thumb.png`
                );
        }
    }, [type]);

    return (
        <FlexDiv alignCenter justifyCenter>
            <FallbackImage
                src={src}
                fallback={fallback}
                fullWidthThumb={fullWidthThumb}
            />
        </FlexDiv>
    );
}

export default Img;
