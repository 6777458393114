import RestService from "./RestService";

export default class ApiKeys extends RestService {
    constructor(parentService, companyId  ) {
        if( parentService && companyId )
            super(`${parentService.baseUrl}/${companyId }/apikeys`)
        else
            throw new Error("company is required")
    }
}
