import React from 'react';
import AutomationCreate from './AutomationCreate';
import { flatten } from 'lodash';
import { useParams } from 'react-router-dom';

function CreateAssessmentWrapper({
    companyId,
    parentPath,
    customBackUrl,
    editingAssessment,
    isScorm,
    recommendationMode,
}) {
    const { listId } = useParams();

    console.log(
        'CreateAssessmentWrapper:',
        companyId,
        parentPath,
        customBackUrl,
        editingAssessment,
        isScorm,
        recommendationMode,
        listId
    );

    const mapVideosToIds = (videos) =>
        flatten(Object.values(videos)).map((x) => x.id);
    const subjects = listId
        ? mapVideosToIds(
              editingAssessment._rawData.recommendationsByList[listId]
                  ?.videos || {}
          )
        : mapVideosToIds(editingAssessment.recommendations.videos);

    const lists = listId
        ? [{ id: parseInt(listId) }]
        : editingAssessment.selectedUserListsIds.map((x) => ({ id: x }));

    return (
        <AutomationCreate
            companyId={companyId}
            parentPath={parentPath}
            customBackUrl={customBackUrl}
            initialData={{
                subjects,
                lists,
            }}
            recommendationMode={recommendationMode}
            isScorm={isScorm}
        />
    );
}

export default CreateAssessmentWrapper;
