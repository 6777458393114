import React, { useEffect, useCallback, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import HeaderSection from '../../../components/header/HeaderSection';
import { TextTiny, palette, Block, FlexDiv } from '@awarego/awarego-components';
import { useStores } from '../../../hooks/use-stores';
import { DropdownMenu } from 'reactstrap';
import { ThemedDropdownItem } from '../../../components/themed/ThemedComponents';
import ReportTable from './ReportTable';
import SubHeaderText from '../../../components/header/SubHeaderText';
import SubHeaderComponentsList from '../../../components/header/SubHeaderComponentsList';
import ReportRecurringModal from '../modals/ReportRecurringModal';
import ReportRecurringFormDef from '../forms/recurring/ReportRecurringFormDef';
import { useNavigate, useParams } from 'react-router-dom';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import { LINKS } from '../../../utils/constants';

function ReportDetails() {
    const { store, reportsStore, authStore, listStore, commonStore } =
        useStores();
    const { companyId } = store;
    const { timeZone } = authStore;
    const { listsWithUsers: lists } = listStore;
    const { reportId } = useParams();
    const navigate = useNavigate();

    const [showingReportRecurringModal, setShowReportRecurringModal] =
        useState(false);

    useEffect(() => {
        loadData();
    }, [companyId, reportId]);

    const loadData = useCallback(async () => {
        const singleReport = await reportsStore.loadSingleReport(
            companyId,
            reportId
        );
        if (singleReport) {
            await listStore.loadCompanyLists(companyId);
        }
    }, [companyId, reportId]);

    const localStore = useLocalObservable(() => {
        let reportRecurringForm = new ReportRecurringFormDef(
            {},
            {
                onSuccess(form) {
                    doEditReport(this);
                },
                onError(form) {
                    console.log('Error in report form submit', form.errors());
                },
            }
        );

        return { reportRecurringForm };
    });

    const doEditReport = async (form) => {
        // UPDATING REPORT
        const result = await reportsStore.updateReport(
            companyId,
            reportId,
            form.values()
        );
        if (result) {
            try {
                await reportsStore.loadExecutionsByReport(companyId, reportId);
                commonStore.success('Report updated ', form);
            } catch (e) {
                commonStore.error('Report executions not loaded');
            }
        }

        hideReportRecurringModal();
    };

    const showEditReportRecurringModal = () => {
        setShowReportRecurringModal(true);
    };

    const hideReportRecurringModal = () => {
        setShowReportRecurringModal(false);
        localStore.reportRecurringForm.reset();
    };

    const deleteReport = () => {
        commonStore.showConfirm(
            'Are you sure you want to delete this report?',
            'Delete',
            'Delete report',
            async () => {
                const result = await reportsStore.delete(companyId, reportId);
                if (result) {
                    commonStore.success('Report deleted!');
                    navigate('/reports');
                }
            },
            true
        );
    };

    const createContextMenu = () => {
        return (
            <DropdownMenu>
                <ThemedDropdownItem
                    onClick={() => {
                        showEditReportRecurringModal();
                    }}
                >
                    Edit Report
                </ThemedDropdownItem>
                <ThemedDropdownItem
                    color={palette.vibrant.raspberry}
                    onClick={() => {
                        deleteReport();
                    }}
                >
                    <TextTiny color={palette.vibrant.red} bold>
                        Delete Report
                    </TextTiny>
                </ThemedDropdownItem>
            </DropdownMenu>
        );
    };

    const buildLinkedLists = (lists) => {
        return (
            <NameAndSublistCell
                subListItems={lists}
                maxVisibleSubListCount={7}
                link={LINKS.list}
            ></NameAndSublistCell>
        );
    };

    return reportsStore.error != null && !reportsStore.report ? (
        <Block>
            {' '}
            <FlexDiv justifyCenter>
                <NoItemsYetContent
                    img={'/static/img/recurring-reports-inside-frame.svg'}
                    imgAlt={'empty report execution view'}
                    title={'This report does not exist'}
                />
            </FlexDiv>
        </Block>
    ) : (
        <>
            <HeaderSection
                loadingData={reportsStore.loadingReport && !reportsStore.report}
                heading={reportsStore.report && reportsStore.report.name}
                subHeadings={
                    reportsStore.report && (
                        <>
                            <SubHeaderComponentsList>
                                <SubHeaderText
                                    isHeading={true}
                                    text={'Employee lists'}
                                />
                                {reportsStore.report &&
                                    reportsStore.report.lists &&
                                    reportsStore.report.lists.length > 0 && (
                                        <SubHeaderText
                                            isText={true}
                                            text={buildLinkedLists(
                                                reportsStore.report.lists
                                            )}
                                        />
                                    )}
                            </SubHeaderComponentsList>
                            <SubHeaderComponentsList>
                                <SubHeaderText
                                    isHeading={true}
                                    text={'Report frequency'}
                                />
                                <SubHeaderText
                                    isText={true}
                                    text={
                                        reportsStore.report &&
                                        reportsStore.report.frequencyText
                                    }
                                />
                            </SubHeaderComponentsList>
                        </>
                    )
                }
                contextMenu={store.isAdmin && createContextMenu()}
            />
            <ReportTable companyId={companyId} reportId={reportId} />

            <ReportRecurringModal
                isOpen={showingReportRecurringModal}
                onRequestClose={hideReportRecurringModal}
                lists={lists}
                reportsForm={localStore.reportRecurringForm}
                usersTimeZone={timeZone}
                companyId={companyId}
                editingReport={reportsStore.report}
            />
        </>
    );
}

export default observer(ReportDetails);
