import React from 'react';
import {
    Heading4,
    SpaceBetweenDiv,
    Block,
    FlexDiv,
    TextLead,
    ThemedButton,
} from '@awarego/awarego-components';
import styled from 'styled-components';
import LinkIfNeed from '../../LinkIfNeed';

const ExpandImage = styled.div`
    margin: -32px;
    margin-left: 0;
`;

function Upgrade({
    heading,
    text,
    img,
    imgAlt,
    upgradeLink,
    secondaryBtnText,
    secondaryBtnLink,
}) {
    return (
        <Block>
            <SpaceBetweenDiv $noWrap>
                <FlexDiv column>
                    <Heading4>{heading}</Heading4>
                    <TextLead bold mt={8} mb={16} wrap>
                        {text}
                    </TextLead>
                    <FlexDiv>
                        <LinkIfNeed link={upgradeLink}>
                            <ThemedButton
                                mode="primary"
                                text="Upgrade"
                                $noTextTransform
                                size="tiny"
                            />
                        </LinkIfNeed>
                        <LinkIfNeed link={secondaryBtnLink}>
                            <ThemedButton
                                mode="secondary"
                                $noTextTransform
                                $airy
                                size="tiny"
                                text={secondaryBtnText}
                            />
                        </LinkIfNeed>
                    </FlexDiv>
                </FlexDiv>
                <ExpandImage>
                    <img src={img} alt={imgAlt} />
                </ExpandImage>
            </SpaceBetweenDiv>
        </Block>
    );
}

export default Upgrade;
