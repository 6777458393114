import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { extractErrorMessage, formatDate } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SectionHeader from '../../components/SectionHeader';
import { Heading1, ThemedButton } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

const CompanyPPVLogView = ({ companyId }) => {
    const [loadedOrError, setLoadedOrError] = useState(null);

    const loadData = useCallback(async () => {
        try {
            await partnerStore.loadPPVLog(companyId);
            setLoadedOrError(true);
        } catch (e) {
            setLoadedOrError(extractErrorMessage(e));
        }
    }, [companyId]);
    useEffect(() => {
        if (companyId !== null) {
            loadData();
        }
    }, [companyId, loadData]);

    const eventType = (t) => {
        switch (t) {
            case 1:
                return 'Signature';
            case 2:
                return 'View';
            case 3:
                return 'Error';
            default:
                return 'Other';
        }
    };

    const { partnerStore, authStore } = useStores();
    const { loadingPPVLog, ppvLog } = partnerStore;
    const { token } = authStore;
    const csvUrl = `${
        import.meta.env.REACT_APP_API_URL
    }/companies/${companyId}/ppv/export/?token=${token}`;

    return loadingPPVLog ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={`/ppv`}>
                <div className={'navigation back-button'}>Back to PPV view</div>
            </Link>
            <div className="block">
                <SectionHeader>
                    <br />
                    <div className=" d-flex flex-row">
                        <div className={'section-header'}>
                            <div className={'left padded'}>
                                <Heading1>PPV views usage</Heading1>
                            </div>
                            <div className="pull-right">
                                <ThemedButton
                                    mode="secondary"
                                    as={'a'}
                                    href={csvUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    text="Export"
                                />
                            </div>
                        </div>
                    </div>
                </SectionHeader>

                <table className="simple-table">
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>EventType</th>
                            <th>Subject</th>
                            <th>Client Ip</th>
                            <th>JW Video ID</th>
                            <th>Location</th>
                            <th>Uid</th>
                            <th>ApiKey</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ppvLog.map((r) => (
                            <tr key={r.id}>
                                <td>{formatDate(r.timestamp)} </td>
                                <td>{eventType(r.event_type)}</td>
                                <td>{r.subject_title}</td>
                                <td>{r.client_ip}</td>
                                <td>{r.js_video_id}</td>
                                <td>{r.locaction}</td>
                                <td>{r.uid}</td>
                                <td>{r.api_key}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default observer(CompanyPPVLogView);
