import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MenuItem, FormControl, Select } from '@mui/material';
import { useStores } from '../hooks/use-stores';
import orderBy from 'lodash/orderBy';
import { InputLabel } from '@awarego/awarego-components';

function LanguageSelector({
    handleSelectLanguage,
    selectedLanguage,
    availableLanguages = ['en'],
}) {
    const { commonStore } = useStores();

    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        translateLanguageKeys();
    }, [availableLanguages]);

    /** Get the english, human-readable, name of languages */
    const translateLanguageKeys = () => {
        const readableLanguages = availableLanguages.map((x) => ({
            id: x,
            name: commonStore.languagesIndex[x]
                ? commonStore.languagesIndex[x].english_name
                : x,
        }));
        setLanguages(orderBy(readableLanguages, ['name'], ['asc']));
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel text={'Language'} />
            <Select
                defaultValue={selectedLanguage}
                label="Language"
                onChange={(e) => handleSelectLanguage(e.target.value)}
            >
                {languages.map((x) => {
                    return (
                        <MenuItem
                            value={x.id}
                            key={x.id}
                            selected={x.id === selectedLanguage}
                        >
                            {x.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default observer(LanguageSelector);
