class ScormClass {
    constructor(props) {
        this.entryPoint = props.entryPoint;
        this.language = props.language;
        this.name = props.name;
        this.version = props.version;
        this.description = props.description;
        this.thumbnail = props.thumbnail; // image file name
        this.filename = props.filename;
        this.zipFile = props.zipFile;
        this.imageFile = props.imageFile;
        this.xapi = props.xapi;
    }
}

export default ScormClass;
