/* eslint-disable no-undef,no-empty */
import { makeAutoObservable, runInAction } from 'mobx';
import ReactGA from 'react-ga';
import services from '../services';
import { backOffDelay } from '../utils/helpers';
import i18n from '../tools/i18n';
import keyBy from 'lodash/keyBy';
import { version } from '@awarego/awarego-components';

class CommonStore {
    message = null;
    appLoaded = false;
    messageType = null;
    lastPage = null;
    messageShown = false;
    i18n = null;
    confirmationRequired = false;
    confirmationDescription = '';
    confirmationAction = '';
    options = {};
    confirmationOnConfirmAction;
    confirmationShowCancel = true;
    confirmationTitle = '';
    isConfirmationDelete = false;
    additionalCheckbox = null;
    languagesLoaded = false;
    languages = [];

    messageTimeout;

    constructor() {
        makeAutoObservable(this);
        this.copied = this.copied.bind(this);
    }

    hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            clearTimeout(this.messageTimeout);
        } catch (e) {
            // intentionally empty
        }
        this.messageTimeout = null;
    }

    async setAppLoaded() {
        this.appLoaded = true;
    }

    copied() {
        this.success('Copied');
    }

    success(message) {
        this.showMessage(message, 'success');
    }

    warn(message) {
        this.showMessage(message, 'warning');
    }

    error(message) {
        this.showMessage(message, 'error');
    }

    showMessage(message, type = 'info', delay = 5000) {
        this.hideMessage();
        this.message = message;
        this.messageShown = true;
        this.messageType = type;
        this.messageTimeout = setTimeout(() => {
            this.hideMessage();
        }, delay);
    }

    getEntityName(entityType, entity) {
        switch (entityType) {
            case 'Request':
                return entity.location;
            case 'User':
                return entity.name || entity.email;
            case 'Customer':
                return entity.name;
            default:
                return entityType;
        }
    }

    showConfirm(
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationOnConfirmAction,
        isConfirmationDelete,
        confirmationShowCancel = true,
        additionalCheckbox,
        options = {}
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.options = options;
        this.confirmationTitle = confirmationTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationShowCancel = confirmationShowCancel;
        this.confirmationRequired = true;
        this.isConfirmationDelete = isConfirmationDelete;
        this.additionalCheckbox = additionalCheckbox;
    }

    cancelConfirm() {
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationRequired = false;
        this.additionalCheckbox = null;
        this.isConfirmationDelete = false;
        this.options = {};
    }

    doConfirm() {
        if (this.confirmationOnConfirmAction)
            this.confirmationOnConfirmAction();

        this.cancelConfirm();
    }

    async loadLanguages(counter = 0) {
        this.languagesLoaded = false;

        try {
            let result = await services.Languages.list();
            runInAction(() => {
                this.languages.replace(result);
                this.i18n = i18n.init(this);
                this.languagesLoaded = true;
            });
        } catch (e) {
            if (counter < 10)
                setTimeout(async () => {
                    await this.loadLanguages(counter + 1);
                }, backOffDelay(1500, counter));
        }
    }

    get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    analyticsEvent(category, action, label) {
        ReactGA.event(
            {
                category,
                action,
                label,
            },
            ['main']
        );
    }

    /**
     * @param {string} category
     * @param {string} action
     * @param {object} context
     * @returns {Promise<void>}
     */
    async saveAnalyticsEvent(category, action, context) {
        await services.Analytics.events.create({ category, action, context });
    }

    analyticsPageView(pagePath) {
        ReactGA.pageview(pagePath);
    }

    postAffTrackerClick() {
        window.PostAffTracker && window.PostAffTracker.track();
    }

    postAffTrackerSale(amount, subscriptionId, planId) {
        if (!window.PostAffTracker) return;
        let sale = PostAffTracker.createSale();
        sale.setTotalCost(amount);
        sale.setOrderID(subscriptionId);
        sale.setProductID(planId);

        PostAffTracker.register();
    }

    get componentsVersion() {
        return version;
    }
}

export default CommonStore;
