import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
    Tag,
    Block,
    palette,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../../components/errormessage';
import pluralize from 'pluralize';
import EditUserModal from './modals/EditUserModal';
import RequireRole from '../RequireRole';
import SubHeaderDetailsList from '../../components/header/SubHeaderDetailsList';
import HeaderSection from '../../components/header/HeaderSection';
import debounceFn from 'debounce-fn';
import { createNoUsersView } from './users-helpers';
import TableServer from '../../components/table/TableServer';
import StyledLink from '../../components/StyledLink';
import NameAndSublistCell from '../../components/manager/NameAndSublistCell';
import { LINKS } from '../../utils/constants';
import { validate as validateUUID } from 'uuid';
import { expandUserEmail, expandUserName } from '../../utils/helpers';

function UsersTable({ companyId, heading, createDisabled, currentCompany }) {
    const { userStore, commonStore } = useStores();
    const activePackage = currentCompany.subscription;
    const rowsPerPage = 10;
    const [searchValue, setSearchValue] = useState(null);
    const [isModalOpen, setModalIsOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState({
        id: null,
        name: '',
        email: '',
        listsId: '',
    });
    const [error, setError] = useState(null);
    const [manualInitialPageTrigger, setManualInitialPageTrigger] = useState(0);
    const [manualPaginationTrigger, setManualPaginationTrigger] =
        useState(null);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                id: 'name',
                accessor: (x) => {
                    // scorm name  is formatted as "id|[name]|[email]"
                    // so check if its  scorm name and parse
                    return expandUserName(x.name);
                },
                Cell: (x) => {
                    return (
                        <>
                            <StyledLink
                                to={`/users/user/${x.row.original.email}`}
                                $hoverColor={palette.graphite.onyx}
                                $underlined={true}
                            >
                                {x.value && x.value.length > 0 ? (
                                    x.value
                                ) : (
                                    <Tag text="No Name" />
                                )}{' '}
                            </StyledLink>
                        </>
                    );
                },
            },

            {
                Header: 'Email Address',
                id: 'email',
                accessor: (x) => {
                    // scorm name  is formatted as "id|[name]|[email]"
                    // so check if its  scorm name and parse
                    return expandUserEmail(x.name, x.email);
                },
            },

            {
                Header: 'List(s)',
                accessor: 'lists',
                id: 'lists',
                Cell: (x) => {
                    const handleCellClick = (e) => {
                        e.stopPropagation();
                    };

                    return (
                        <span onClick={handleCellClick}>
                            <NameAndSublistCell
                                subListItems={x.value}
                                maxVisibleSubListCount={1}
                                link={LINKS.list}
                            ></NameAndSublistCell>
                        </span>
                    );
                },

                disableSortBy: true,
                disableGlobalFilter: true,
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
            {
                Header: 'List Names',
                accessor: 'listName',
                id: 'listName',
                show: false,
            },
        ],
        []
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={onEditEmployee}
                        row={row}
                        title="Edit Employee"
                        icon={'edit'}
                    ></DarkArrowTooltipIcon>
                    <DarkArrowTooltipIcon
                        customFunction={onDeleteEmployee}
                        row={row}
                        title="Delete Employee"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
            </>
        );
    };

    useEffect(() => {
        // Cleanup run on component unmount
        return () => {
            userStore.usersFiltered = 0;
            userStore.setQueryContext({});
        };
    }, []);

    useEffect(() => {
        userStore.setQueryContext({ p: 0, n: rowsPerPage, order: 'name asc' });
    }, []);

    useEffect(() => {
        void userStore.loadAllUsers(companyId, searchValue);
    }, [
        companyId,
        userStore.pageIndex,
        userStore.pageSize,
        userStore.order,
        userStore.usersAdded,
    ]);

    useEffect(() => {
        if (userStore.usersDeleted > 0) {
            // if there is only one user displayed on page n, once he is deleted move to page n-1
            // page n != 0
            if (
                userStore.pageIndex ==
                userStore.usersFiltered / userStore.queryContext.n
            ) {
                const newPageIndex = Math.max(userStore.pageIndex - 1, 0);
                setManualPaginationTrigger(newPageIndex);
                userStore.setPagingParams(
                    newPageIndex,
                    userStore.queryContext.n
                );
                userStore.loadAllUsers(companyId, searchValue);
            }
            //if user is deleted from page 0, reload to move user from page 1 to its place
            else if (
                userStore.pageIndex == 0 &&
                !userStore.usersFiltered < userStore.queryContext.n
            ) {
                userStore.loadAllUsers(companyId, searchValue);
            }
        }
    }, [userStore.usersDeleted]);

    useEffect(() => {
        if (searchValue != null || typeof searchValue == 'undefined') {
            // called on typing in search bar
            loadDataWithFilterCallback();
        }
    }, [companyId, searchValue]);

    const loadDataWithFilterCallback = useCallback(async () => {
        void debouncedLoadFilteredUsers();
    }, [companyId, searchValue]);

    const debouncedLoadFilteredUsers = debounceFn(
        () => {
            // manually set page to 0 for searching, calls goToPage(0) method of SimpleTable
            setManualInitialPageTrigger(
                (manualInitialPageTrigger) => manualInitialPageTrigger + 1
            );
            void userStore.debouncedLoadAllUsers(companyId, searchValue);
        },
        { wait: 500 }
    );

    const createDetailsString = useCallback(() => {
        let details = [];
        let usersFiltered = userStore.usersFiltered || userStore.usersTotal;
        let usersLicensed =
            activePackage &&
            '(out of ' + activePackage.package_users + ' licensed)';

        if (userStore.usersFiltered !== userStore.usersTotal) {
            details.push(`${usersFiltered}/${userStore.usersTotal}`);
        } else {
            details.push(userStore.usersTotal);
        }
        details.push(pluralize('employee', userStore.usersTotal));
        details.push(usersLicensed);

        return details.join(' ');
    }, [userStore.usersTotal, userStore.usersFiltered, activePackage]);

    const employeeEditedCallback = (response) => {
        if (response.type == 'error') {
            setError(response.data ? response.data : 'Employee update failed');
        } else {
            commonStore.success('Employee updated successfully');
            setError(null);
        }

        setModalIsOpen(false);
    };

    const onEditEmployee = (e, user) => {
        e.stopPropagation();
        let listsId = user.lists.map((el) => {
            return el.id;
        });
        setModalIsOpen(true);
        setSelectedEmployee({
            id: user.userId,
            name: user.name,
            email: user.email,
            listsId: listsId,
        });
    };

    const employeeDeletedCallback = () => {
        commonStore.success('User deleted.');
    };

    const onDeleteEmployee = (e, user) => {
        e.stopPropagation();
        let listsId = user.lists.map((l) => l.id);
        let userToDelete = {
            companyId: companyId,
            listsId: listsId,
            email: user.email,
        };
        let description = `By doing this, user '${user.email}' will be removed from following lists: '${user.listName}' 
        and from all training programs associated with this user.`;
        userStore.buildDeleteUserDialog(
            userToDelete,
            description,
            employeeDeletedCallback
        );
    };

    const onChangePagingParams = (pageIndex, pageSize) => {
        if (
            userStore.pageIndex != pageIndex ||
            userStore.pageSize != pageSize
        ) {
            userStore.setPagingParams(pageIndex, pageSize);
        }
    };

    const onChangeSort = (sortBy) => {
        userStore.setSortBy(sortBy);
    };

    const pagesTotal = useMemo(() => {
        return userStore.calculateUsersPageCount();
    }, [userStore.pageSize, userStore.usersFiltered]);

    return (
        <Block gap={24} tablePadding>
            {error && <ErrorMessage error={error} />}
            <HeaderSection
                heading={heading}
                searchValue={searchValue}
                searchPlaceholder={'Search employees'}
                onSearch={(e) => setSearchValue(e.target.value || '')}
                onClearSearch={() => setSearchValue('')}
                subHeadings={
                    <SubHeaderDetailsList
                        key="details"
                        details={[createDetailsString()]}
                    />
                }
                excludeBlock
                marginLeft="16"
            />
            <EditUserModal
                isModalOpen={isModalOpen}
                employee={selectedEmployee}
                companyId={companyId}
                modalStateSetter={setModalIsOpen}
                employeeEditedSetter={employeeEditedCallback}
            ></EditUserModal>

            <TableServer
                columns={columns}
                data={userStore.usersList.slice()}
                hidePagination={
                    userStore.usersFiltered <= rowsPerPage ||
                    userStore.loadingUsersList
                }
                hiddenColumns={['listName']}
                link={LINKS.user}
                defaultSortBy={'name'}
                defaultSortDescent={false}
                rowsTotal={userStore.usersFiltered}
                pagesTotal={pagesTotal}
                onChangePagingParams={onChangePagingParams}
                onChangeSort={onChangeSort}
                loadingData={userStore.loadingUsersList}
                initialPageSize={rowsPerPage}
                NoDataComponent={createNoUsersView(
                    currentCompany,
                    createDisabled,
                    'users'
                )}
                manualInitialPageTrigger={manualInitialPageTrigger}
                manualPaginationTrigger={manualPaginationTrigger}
            />
        </Block>
    );
}

export default observer(UsersTable);
