import React from 'react';
import { palette, FlexDiv, StatusBadge } from '@awarego/awarego-components';

/** Sub list that consists only of tags */
function SubHeaderTagsList({ tags }) {
    return (
        <FlexDiv gap={8} alignCenter mr={32}>
            {tags
                ? tags.map((tag, index) => {
                      return (
                          <StatusBadge
                              key={index}
                              color={palette.graphite.heavyMetal}
                              text={tag}
                          />
                      );
                  })
                : null}
        </FlexDiv>
    );
}

export default SubHeaderTagsList;
