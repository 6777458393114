import React, { Fragment, useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import clsx from 'classnames';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import VantaFormDef from '../../forms/vanta';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedDropdownItem } from '../../components/themed/ThemedComponents';
import ErrorMessage from '../../components/errormessage';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import {
    SpaceBetweenDiv,
    TextTiny,
    FlexDiv,
    TextLead,
    Heading2,
    ThemedButton,
    palette,
} from '@awarego/awarego-components';
import io from 'socket.io-client';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Link as MuiLink,
} from '@mui/material';
import Box from '@mui/material/Box';

const integrationName = 'vantasync';

function CompanyVanta({ companyId }) {
    const { companyStore, commonStore, authStore, brandingStore } = useStores();

    const { loadingVantasyncDatas, syncRunning } = companyStore;
    const { account_name, brandingLoaded, integrationADUrl } = brandingStore;
    let data = companyStore.vantasyncDataIndex[companyId];

    const latestRunningSyncId = data?.latestRunningSyncId;
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [lastStatusesDropdown, setLastStatusesDropdown] = useState(false);

    const openDetailsDialog = () => {
        setDetailsDialogOpen(data.syncStatuses[0]);
    };
    const closeDetailsDialog = () => {
        setDetailsDialogOpen(false);
    };

    const toggleLastStatusesDropdown = () => {
        setLastStatusesDropdown(!lastStatusesDropdown);
    };

    const doLoadData = async () => {
        await companyStore.loadIntegrationData(companyId, integrationName);
    };

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    useEffect(() => {
        if (!latestRunningSyncId) return;
        console.log(latestRunningSyncId);
        const socket = io.connect(import.meta.env.REACT_APP_API_URL, {
            query: `token=${authStore.token}`,
            transports: ['websocket'],
        });

        socket.on('data', () => {
            console.log('data received');
        });

        socket.on('unauthorized', (error) => {
            if (
                error.data.type === 'UnauthorizedError' ||
                error.data.code === 'invalid_token'
            ) {
                console.log('error', error);
            }
        });

        socket.on('connect', async function () {
            console.log('connected');
            socket.emit(`integration`, {
                id: latestRunningSyncId,
            });
        });

        console.log(
            'connecting to',
            `integration:${latestRunningSyncId}:update_progress`
        );

        socket.on(
            `integration:${latestRunningSyncId}:update_progress`,
            function (data) {
                console.log('update_progress', data);
                companyStore.updateSyncdata(companyId, data);
                if (data.progress === 100) {
                    void doLoadData();
                }
            }
        );

        socket.on('disconnect', function () {
            console.log('disconnect');
        });

        socket.on('connect_error', function (e) {
            console.log(e);
        });

        return () => {
            socket.disconnect();
            socket.close();
        };
    }, [authStore.token, latestRunningSyncId]);

    const syncNow = async () => {
        try {
            let result = await companyStore.syncIntegration(
                companyId,
                integrationName
            );
            if (result && !result.error)
                commonStore.showMessage('Sync Started Successful');
            else
                commonStore.showMessage(
                    'Sync failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const handleUnlink = async () => {
        commonStore.showConfirm(
            'Employees that have already been imported with the Active Directory integration will stay, but new employees will not be synced. This will not remove already imported groups or stop current training programs or assessments.',
            'YES, UNLINK',
            'Unlink integration?',
            doHandleUnlink,
            true,
            true,
            false,
            { confirmationCancelAction: 'NO, CANCEL' }
        );
    };
    const doHandleUnlink = async () => {
        try {
            let result = await companyStore.unlinkIntegration(
                companyId,
                integrationName
            );
            await doLoadData();
            if (result) commonStore.showMessage('Sync Unlinked Successful');
        } catch (e) {
            commonStore.showMessage('Unlink failed: ' + e.message, 'error');
        }
    };

    return loadingVantasyncDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to=".." relative="path">
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block align-left">
                        <FlexDiv fullWidth>
                            <FlexDiv column flexGrow={5} mr={32}>
                                <Heading2 mb={16}>
                                    {(brandingLoaded && account_name) ||
                                        'AwareGO'}{' '}
                                    + Vanta
                                </Heading2>
                                <p
                                    className={clsx({
                                        'margin-bottom': data.enabled === '0',
                                    })}
                                >
                                    Sync Training statuses to Vanta App.
                                    {(brandingLoaded && account_name) ||
                                        'AwareGO'}{' '}
                                    portal.
                                </p>
                            </FlexDiv>
                            <FlexDiv column flexGrow={1}>
                                <img
                                    src={`/static/img/logo_vanta.jpeg`}
                                    alt="Vanta logo"
                                />
                            </FlexDiv>
                        </FlexDiv>

                        {!data.connected ? (
                            <FlexDiv column fullWidth>
                                <FlexDiv justifyEnd mt={16}>
                                    <a href={data.connectUrl}>
                                        <ThemedButton
                                            mode="primary"
                                            text="Connect Vanta Sync"
                                        />
                                    </a>
                                </FlexDiv>
                            </FlexDiv>
                        ) : (
                            <SpaceBetweenDiv alignEnd>
                                {!(
                                    syncRunning ||
                                    (data && data.latestRunningSyncId)
                                ) ? (
                                    <p>
                                        Last synced:{' '}
                                        {data && data.lastSync ? (
                                            <MuiLink
                                                onClick={openDetailsDialog}
                                            >
                                                <TextTiny
                                                    bold
                                                    link
                                                    color={
                                                        data.status === 3
                                                            ? palette.vibrant
                                                                  .raspberry
                                                            : palette.evergreen
                                                                  .aspargus
                                                    }
                                                >
                                                    {moment(
                                                        data.lastSync
                                                    ).fromNow()}
                                                </TextTiny>
                                            </MuiLink>
                                        ) : (
                                            'Never'
                                        )}
                                    </p>
                                ) : null}
                                {syncRunning ||
                                (data && data.latestRunningSyncId) ? (
                                    <Box
                                        display="flex"
                                        width={300}
                                        flexDirection="column"
                                    >
                                        <TextTiny
                                            bold
                                            color={palette.evergreen.aspargus}
                                        >
                                            {data.message || 'Initializing'}
                                        </TextTiny>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Box width="100%" mr={1}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={data.progress || 0}
                                                />
                                            </Box>
                                            <Box minWidth={35}>
                                                <TextTiny
                                                    bold
                                                    color={
                                                        palette.evergreen
                                                            .aspargus
                                                    }
                                                >{`${(
                                                    data.progress || 0
                                                ).toFixed(0)}%`}</TextTiny>
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <ThemedButton
                                        mode="secondary"
                                        onClick={syncNow}
                                        text="Sync now"
                                    />
                                )}
                            </SpaceBetweenDiv>
                        )}
                    </div>

                    {data.connected && (
                        <div className="integration-detail-block  align-left">
                            <Heading2 mb={16}>Unlink integration</Heading2>
                            <p
                                className={clsx({
                                    'margin-bottom': 1,
                                })}
                            >
                                By unlinking this integration you will not be
                                able to sync users from Active Directory. This
                                will affect all currently running training
                                programs.
                            </p>
                            <ThemedButton
                                mode="red"
                                size="fullWidth"
                                onClick={handleUnlink}
                                text="Unlink integration"
                            />
                        </div>
                    )}
                </Col>

                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href={integrationADUrl}
                        product="Vanta"
                    />
                </Col>
            </Row>
            {detailsDialogOpen && (
                <Dialog
                    open={detailsDialogOpen}
                    onClose={closeDetailsDialog}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle>Sync details</DialogTitle>
                    <DialogContent>
                        <FlexDiv pt={20} pb={20}>
                            <FlexDiv flexGrow={1}>
                                <TextLead bold lighter>
                                    Date
                                </TextLead>
                            </FlexDiv>
                            <FlexDiv column flexGrow={2} ml={32}>
                                <FlexDiv>
                                    <Heading2 mb={4}>
                                        <TextTiny bold>
                                            {moment(
                                                detailsDialogOpen.lastSync
                                            ).format('LLL')}
                                        </TextTiny>
                                    </Heading2>
                                    <FlexDiv ml={4} mr={8} link>
                                        <Dropdown
                                            isOpen={lastStatusesDropdown}
                                            toggle={toggleLastStatusesDropdown}
                                        >
                                            <DropdownToggle caret tag="span" />
                                            <DropdownMenu>
                                                {data &&
                                                    data.syncStatuses.map(
                                                        (x, i) => {
                                                            return (
                                                                <ThemedDropdownItem
                                                                    key={i}
                                                                    onClick={() => {
                                                                        setDetailsDialogOpen(
                                                                            x
                                                                        );
                                                                        toggleLastStatusesDropdown();
                                                                    }}
                                                                >
                                                                    <TextTiny
                                                                        bold={
                                                                            detailsDialogOpen.lastSyncId ===
                                                                            x.lastSyncId
                                                                        }
                                                                        color={
                                                                            x.status ===
                                                                            3
                                                                                ? palette
                                                                                      .vibrant
                                                                                      .raspberry
                                                                                : palette
                                                                                      .evergreen
                                                                                      .aspargus
                                                                        }
                                                                    >
                                                                        {moment(
                                                                            x.lastSync
                                                                        ).format(
                                                                            'LLL'
                                                                        )}
                                                                    </TextTiny>
                                                                </ThemedDropdownItem>
                                                            );
                                                        }
                                                    )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FlexDiv>
                                </FlexDiv>

                                {/*<TextTiny>{subtext}</TextTiny>*/}
                            </FlexDiv>
                        </FlexDiv>

                        <FlexDiv pt={20} pb={20}>
                            <FlexDiv flexGrow={1}>
                                <TextLead bold lighter>
                                    Status
                                </TextLead>
                            </FlexDiv>
                            <FlexDiv column flexGrow={2} ml={32}>
                                <TextTiny
                                    bold
                                    color={
                                        detailsDialogOpen.status === 3
                                            ? palette.vibrant.raspberry
                                            : palette.evergreen.aspargus
                                    }
                                >
                                    {detailsDialogOpen.status === 3
                                        ? 'Failed'
                                        : 'Success'}
                                </TextTiny>
                                {/*<TextTiny>{subtext}</TextTiny>*/}
                            </FlexDiv>
                        </FlexDiv>

                        {detailsDialogOpen.status === 3 && (
                            <FlexDiv pt={20} pb={20}>
                                <FlexDiv flexGrow={1}>
                                    <TextLead bold lighter>
                                        Error
                                    </TextLead>
                                </FlexDiv>
                                <FlexDiv column flexGrow={2} ml={32}>
                                    <TextTiny
                                        bold
                                        color={palette.vibrant.raspberry}
                                    >
                                        {detailsDialogOpen.errorDetails}
                                    </TextTiny>
                                    {/*<TextTiny>{subtext}</TextTiny>*/}
                                </FlexDiv>
                            </FlexDiv>
                        )}
                        {detailsDialogOpen.status === 2 && (
                            <>
                                <FlexDiv pt={20} pb={20}>
                                    <FlexDiv flexGrow={1}>
                                        <TextLead bold lighter>
                                            Sync Finished
                                        </TextLead>
                                    </FlexDiv>
                                </FlexDiv>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ThemedButton
                            mode="secondary"
                            text="Close"
                            onClick={closeDetailsDialog}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
}

export default observer(CompanyVanta);
