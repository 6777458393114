import { makeAutoObservable } from 'mobx';
import services from '../services';
import { generateLoadList } from '../utils/mobx';

class LanguageStore {
    languages = [];
    loadingLanguages = null;

    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    loadAllLanguages = generateLoadList(
        'loadLanguages',
        this,
        'loadingLanguages',
        async () => {
            const result = await services.Languages.all();
            return result;
        },
        'languages'
    );
}

export default LanguageStore;
