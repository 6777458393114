import React from 'react';
import { observer } from 'mobx-react';
import RadioButton from '../../RadioButton';
import SelectChannel from '../wizard/select-channel';
import {
    FlexDiv,
    Heading4,
    TextInput,
    TextDefault,
    TextWithTooltipIcon,
    TextTiny,
    LightTooltip,
    palette,
} from '@awarego/awarego-components';
import moment from 'moment';
import 'moment/locale/en-gb';
import MenuItem from '@mui/material/MenuItem';
import { AUTOMATION_SCHEDULE_TYPE } from '../../../utils/constants';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function Distribution({
    scheduleType,
    date,
    interval,
    subjectsPerInterval,
    channel,
    dueDate,
    dueEnabled,
    deliverySchedule,
    slackAvailable,
    slackConnected,
    teamsAvailable,
    teamsConnected,
    onChangeDate,
    onChangeScheduleType,
    onChangeChannel,
    onChangeSubjectsPerInterval,
    onChangeInterval,
    onChangeDueDate,
    adminTimeZone,
}) {
    const [dueError, setDueError] = React.useState(null);

    const errorMessageDueDate = React.useMemo(() => {
        switch (dueError) {
            case 'maxDate':
            case 'minDate': {
                return `Due date must be set after the delivery time (${moment(
                    date
                ).format(`L HH:mm [${adminTimeZone}]`)})`;
            }

            case 'disablePast': {
                return 'Due date cannot be set in the past';
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [dueError]);

    return (
        <FlexDiv mt={40} column>
            <Heading4 lighter mb={16}>
                Type of delivery
            </Heading4>
            <FlexDiv gap={32}>
                <RadioButton
                    id="typeSend0"
                    label="Schedule"
                    value={AUTOMATION_SCHEDULE_TYPE.FUTURE}
                    groupValue={scheduleType}
                    onClick={onChangeScheduleType}
                />
                <RadioButton
                    id="typeSend1"
                    label="Now"
                    value={AUTOMATION_SCHEDULE_TYPE.NOW}
                    groupValue={scheduleType}
                    onClick={onChangeScheduleType}
                />
                <RadioButton
                    id="typeSend2"
                    label={
                        <TextWithTooltipIcon
                            tooltipText="A .csv file with sign in links for all employees will be generated after you finalize this training."
                            textComponent="Download sign in links"
                        />
                    }
                    value={AUTOMATION_SCHEDULE_TYPE.DOWNLOAD_LINKS}
                    groupValue={scheduleType}
                    onClick={onChangeScheduleType}
                />
            </FlexDiv>
            {scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE && (
                <FlexDiv column mt={48} maxWidth={264}>
                    <Heading4 lighter mb={8}>
                        Start date & time
                    </Heading4>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            hiddenLabel={true}
                            value={date ? moment(date) : null}
                            onChange={onChangeDate}
                            ampm={false}
                            dayOfWeekFormatter={(day) => `${day}`}
                            disablePast={true}
                            format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                            views={['day', 'hours', 'minutes']}
                            slotProps={{
                                textField: {
                                    placeholder: 'Select date & time',
                                },
                            }}
                        />
                    </LocalizationProvider>

                    <Heading4 lighter mt={48} mb={16}>
                        Delivery schedule
                    </Heading4>
                    <FlexDiv gap={16} alignCenter>
                        <TextInput
                            value={subjectsPerInterval}
                            onChange={(e) => onChangeSubjectsPerInterval(e)}
                            inputProps={{ min: 1, max: 20 }}
                            type="number"
                            inline
                        />
                        <TextDefault bold>subjects</TextDefault>
                        <TextInput
                            select
                            inline
                            value={interval}
                            onChange={(e) => onChangeInterval(e)}
                        >
                            <MenuItem value="W">Weekly</MenuItem>
                            <MenuItem value="B">Biweekly</MenuItem>
                            <MenuItem value="M">Monthly</MenuItem>
                        </TextInput>
                    </FlexDiv>
                </FlexDiv>
            )}
            {(scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE ||
                scheduleType === AUTOMATION_SCHEDULE_TYPE.NOW) && (
                <SelectChannel
                    channel={channel}
                    slackAvailable={slackAvailable}
                    slackConnected={slackConnected}
                    teamsAvailable={teamsAvailable}
                    teamsConnected={teamsConnected}
                    onChangeChannel={onChangeChannel}
                />
            )}
            {dueEnabled && (
                <FlexDiv column maxWidth={264}>
                    <Heading4 lighter mt={24} displayBlock>
                        Due date (optional){' '}
                        <LightTooltip
                            title={
                                'When due date is reached, user lists will no longer sync to training. All users assigned can still attend and complete training. This date does not affect reminders sending.'
                            }
                            placement="bottom"
                        >
                            <InfoOutlinedIcon fontSize="small" color="action" />
                        </LightTooltip>
                    </Heading4>
                    <FlexDiv alignCenter>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                hiddenLabel={true}
                                value={dueDate ? moment(dueDate) : null}
                                onChange={onChangeDueDate}
                                ampm={false}
                                dayOfWeekFormatter={(day) => `${day}`}
                                disablePast={true}
                                format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                                views={['day', 'hours', 'minutes']}
                                onError={(newError) => setDueError(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessageDueDate,
                                        placeholder: 'Select date & time',
                                    },
                                }}
                                minDate={
                                    date !== null && scheduleType === 0
                                        ? deliverySchedule.length > 0
                                            ? moment(
                                                  deliverySchedule[
                                                      deliverySchedule.length -
                                                          1
                                                  ].date
                                              )
                                            : moment(date)
                                        : moment()
                                }
                            />
                        </LocalizationProvider>
                        {dueDate && (
                            <TextTiny
                                mx={8}
                                link
                                color={palette.evergreen.aspargus}
                                onClick={() => {
                                    onChangeDueDate(undefined);
                                }}
                            >
                                Clear
                            </TextTiny>
                        )}
                    </FlexDiv>
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

export default observer(Distribution);
