import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import AssignCredit from '../components/manager/dashboard/assign-credit';
import NewsFeed from '../components/manager/dashboard/newsfeed';
import NewSubjects from '../components/manager/dashboard/newsubjects';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from '../components/themed/ThemedSpinner';
import { Helmet } from 'react-helmet';
import { useStores } from '../hooks/use-stores';
import DashboardCalendar from '../components/manager/dashboard/calendar';
import AutomationDashboard from '../components/manager/dashboard/automation-dashboard';
import AssessmentDashboard from '../components/manager/dashboard/assessment-dashboard';
import { FlexDiv } from '@awarego/awarego-components';
import styled from 'styled-components';
import SubjectPreview from './subjectPreview';

const NarrowColumn = styled(Col)`
    max-width: 350px;
`;

function Home({ companyId }) {
    const { store, assessmentStore, automationStore, brandingStore } =
        useStores();

    const {
        serverData,
        currentCompany,
        loadingCalendarData,
        loadingDashboardData,
        dashboardData,
        daysWithEventsInSelectedMonth,
        trainingEnabled,
        assessmentEnabled,
        assessmentsUnavailable,
        assessmentsUpgradeAllowed,
        trainingUpgradeAllowed,
        trainingProgramsUnavailable,
        deliveryCalendarDate,
        displayedCalendarEvents,
        latestSubjects,
        loadingLatestSubjects,
        cmi5enabled,
    } = store;

    const [selectedSubject, setSelectedSubject] = useState(null);
    const [subjectModalIsOpen, setSubjectModalIsOpen] = useState(false);

    const doLoadDashboardData = useCallback(async () => {
        await store.loadDashboardData(companyId);
    }, [companyId, store]);

    const doLoadCalendarData = useCallback(async () => {
        await store.loadCalendarData(companyId);
    }, [store, companyId]);

    const doLoadLatestSubjects = useCallback(async () => {
        await store.loadLatestSubjects();
    }, [store]);

    const doLoadAutomations = useCallback(async () => {
        await automationStore.loadAutomations(companyId, false, 0, 3, true);
    }, [automationStore, companyId]);

    const doLoadAssessments = useCallback(async () => {
        await assessmentStore.loadAssessments(companyId, false, 0, 3);
    }, [assessmentStore, companyId]);

    useEffect(() => {
        doLoadCalendarData();
    }, [doLoadCalendarData]);

    useEffect(() => {
        doLoadDashboardData();
        doLoadLatestSubjects();
        doLoadAutomations();
        doLoadAssessments();
    }, [
        companyId,
        doLoadAssessments,
        doLoadAutomations,
        doLoadDashboardData,
        doLoadLatestSubjects,
    ]);

    const assessmentFirst =
        automationStore.dashboardAutomations.length === 0 &&
        assessmentStore.assessments.length > 0;

    const openSubjectModal = (subject) => {
        setSubjectModalIsOpen(true);
        setSelectedSubject(subject);
    };

    const closeSubjectModal = () => {
        setSubjectModalIsOpen(false);
    };

    return (
        <Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Row>
                <Col lg="8" xs="12">
                    {serverData &&
                        serverData.unusedPackages &&
                        serverData.unusedPackages.length > 0 && (
                            <div>
                                {serverData.unusedPackages.map((pack, i) => (
                                    <AssignCredit
                                        key={i}
                                        unusedPackage={pack}
                                        companies={serverData.companies}
                                    />
                                ))}
                            </div>
                        )}
                    {loadingDashboardData ? (
                        <ThemedSpinner />
                    ) : (
                        <FlexDiv
                            column={!assessmentFirst}
                            columnReverse={assessmentFirst}
                        >
                            {trainingEnabled && (
                                <AutomationDashboard
                                    dashboardData={dashboardData.trainings}
                                    trainings={[
                                        ...automationStore.dashboardAutomations,
                                    ]}
                                    trainingProgramsUnavailable={
                                        trainingProgramsUnavailable
                                    }
                                    upgradeAllowed={trainingUpgradeAllowed}
                                    supportEmail={brandingStore.supportEmail}
                                    demoLink={automationStore.demoTrainingURL}
                                    cmi5enabled={cmi5enabled}
                                />
                            )}

                            {assessmentEnabled && (
                                <AssessmentDashboard
                                    dashboardData={dashboardData.assessments}
                                    assessments={[
                                        ...assessmentStore.assessments,
                                    ]}
                                    assessmentsUnavailable={
                                        assessmentsUnavailable
                                    }
                                    upgradeAllowed={assessmentsUpgradeAllowed}
                                    supportEmail={brandingStore.supportEmail}
                                    demoLink={assessmentStore.demoAssessmentURL}
                                    cmi5enabled={cmi5enabled}
                                    isScormOnly={assessmentStore.isScormOnly}
                                />
                            )}
                        </FlexDiv>
                    )}
                </Col>
                <NarrowColumn>
                    <DashboardCalendar
                        displayedEvents={displayedCalendarEvents}
                        onDayChange={store.setDeliveryCalendarDate}
                        setSelectedMonth={store.setDeliveryCalendarMonth}
                        selectedDate={deliveryCalendarDate}
                        daysWithDot={daysWithEventsInSelectedMonth}
                        isLoading={loadingCalendarData}
                    />
                    {trainingEnabled &&
                        (loadingLatestSubjects ? (
                            <ThemedSpinner />
                        ) : (
                            latestSubjects.length > 0 && (
                                <NewSubjects
                                    latestSubjects={latestSubjects}
                                    handleClick={openSubjectModal}
                                />
                            )
                        ))}
                    {!!currentCompany &&
                        currentCompany.awarego_company === 1 && <NewsFeed />}
                </NarrowColumn>
            </Row>

            {/* Subject modal*/}
            {selectedSubject && (
                <>
                    <SubjectPreview
                        subjectId={selectedSubject.subject_id}
                        isOpen={subjectModalIsOpen}
                        onClose={closeSubjectModal}
                        hideFooter={true}
                        showLanguageSelector={true}
                        availableLanguages={
                            selectedSubject.languages ||
                            currentCompany.defaultLanguage ||
                            []
                        }
                        defaultLanguage={currentCompany.defaultLanguage || 'en'}
                        subjectTitle={selectedSubject.title}
                    />
                </>
            )}
        </Fragment>
    );
}

export default observer(Home);
