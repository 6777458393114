import React from 'react';
import { observer } from 'mobx-react';
import ErrorIcon from '@mui/icons-material/Error';
import {ThemedStep} from "../../themed/ThemedComponents";
import './sidebar.scss';

function Sidebar({
    steps,
    activeStep,
    allowedSteps,
    onStepChange,
    warningSteps,
}) {
    return (
        <div className={'assessment-wizard-steps'}>
            {steps &&
                steps.map((s, i) => (
                    <ThemedStep
                        key={`step-${s.number}`}
                        {...{
                            active: activeStep === s.number,
                            enabled: allowedSteps.includes(s.number),
                        }}
                        onClick={() => {
                            if (allowedSteps.includes(s.number)) {
                                onStepChange(s.number);
                            }
                        }}
                    >
                        {i + 1}. {s.title}
                        {warningSteps.includes(i + 1) ? (
                            <ErrorIcon
                                color={'error'}
                                fontSize={'inherit'}
                                style={{
                                    marginLeft: '10px',
                                    marginBottom: '4px',
                                }}
                            />
                        ) : null}
                    </ThemedStep>
                ))}
            {/* {extraInfo
                && <div className="sidebar-extra-info">
                    <InfoOutlinedIcon fontSize="small"/> Questions cannot be changed as some employees have already received the assessment.
                </div>
            } */}
        </div>
    );
}

export default observer(Sidebar);
