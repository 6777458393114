import React from 'react';
import { Alert, Container } from 'reactstrap';

function ErrorMessage({
    error = '',
    errorType = 'warning',
    children,
    marginBottom = false,
}) {
    /* ************************************************************
  More info on available alert types:
    https://reactstrap.github.io/components/alerts/
************************************************************  */

    if ((!error || typeof error !== 'string') && !children) {
        return null;
    } else {
        // Display error
        return (
            <Alert
                color={errorType}
                className={`header ${marginBottom ? 'margin-bottom' : ''}`}
            >
                <Container>{children || error}</Container>
            </Alert>
        );
    }
}

export default ErrorMessage;
