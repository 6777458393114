import { observer } from 'mobx-react';
import { useStores } from './hooks/use-stores';
import React, { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { querystring } from './utils/helpers';
import PrivateRoute from './hocs/PrivateRoute';
import Home from './containers/Home';
import AuthRoute from './hocs/AuthRoute';
import SignUp from './containers/auth/SignUp';
import PPVHome from './containers/PPVHome';
import CompanyPPVLogView from './containers/super/CompanyPPVLogView';
import PPVEmbed from './containers/PPVEmbed';
import Settings from './containers/settings';
import Partner from './containers/partner';
import Embed from './containers/Embed';
import Users from './containers/users/Users';
import Subscriptions from './containers/subscriptions';
import Automations from './containers/automations';
import Assessment from './containers/assessment';
import Content from './containers/content';
import Reports from './containers/reports';
import SignIn from './containers/auth/SignIn';
import CheckEmail from './containers/auth/CheckEmail';
import EmailSignInError from './containers/auth/EmailSignInError';
import NotFound from './components/NotFound';
import { Container } from 'reactstrap';
import Waiting from './components/waiting';
import Layout from './layouts/layout';
import EmailSignIn from './containers/auth/EmailSignIn';
import TokenSignIn from './containers/auth/TokenSignIn';

function MainRoutes({
    appLoaded,
    brandingLoaded,
    loadingData,
    handleSignout,
    isAuthenticated,
    trialMessageBar,
    currentCompany,
    trainingEnabled,
    assessmentEnabled,
}) {
    const { commonStore, store } = useStores();

    return (
        <Routes>
            {/*<Route //TODO: Simmi RR, what to do here?
                exact
                strict
                path="/:url*"
                render={(props) => {
                    let message = querystring('message');
                    let messageType = querystring('messageType');
                    if (message)
                        commonStore.showMessage(message, messageType, 7000);

                    return (
                        <Redirect
                            to={`${props.location.pathname}${
                                props.location.search.indexOf('utm_term') !== -1
                                    ? '/' + props.location.search
                                    : '/'
                            }`}
                        />
                    );
                }}
            />*/}
            <Route
                path="/"
                element={
                    appLoaded && brandingLoaded && !loadingData ? (
                        <Layout
                            handleSignout={handleSignout}
                            isAuthenticated={isAuthenticated}
                            trialMessageBar={isAuthenticated && trialMessageBar}
                        />
                    ) : (
                        <Container className="main-content">
                            <Waiting waitingActive={appLoaded} />
                        </Container>
                    )
                }
            >
                <Route
                    index
                    element={
                        <PrivateRoute>
                            <Home
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PrivateRoute>
                    }
                />

                {/*<Route
                    path="old_home"
                    element={
                        <PrivateRoute>
                            <Home
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PrivateRoute>
                    }
                />


                <Route path="ppv-trial" element={<TrialPPV />} />
*/}
                <Route
                    path="auth/signup"
                    element={
                        <AuthRoute>
                            <SignUp />
                        </AuthRoute>
                    }
                />
                <Route
                    path="trial"
                    element={<Navigate to={'/auth/signup'} replace={true} />}
                />
                <Route
                    path="dummies"
                    element={<Navigate to={'/auth/signup'} replace={true} />}
                />

                {/* //This is probably obsolete, replaced by Resources/Content
                <Route
                    path="catalog/*"
                    element={
                        trainingEnabled ? (
                            <PrivateRoute>
                                <Catalog
                                    companyId={
                                        currentCompany &&
                                        currentCompany.company_id
                                    }
                                />
                            </PrivateRoute>
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />*/}

                <Route
                    path="embed"
                    element={
                        <PrivateRoute>
                            <Embed />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="subscriptions/*"
                    element={
                        <PrivateRoute>
                            <Subscriptions
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="partner/*"
                    element={
                        <PrivateRoute>
                            <Partner />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="ppv"
                    element={
                        <PrivateRoute>
                            <PPVHome
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="ppv/usage"
                    element={
                        <CompanyPPVLogView
                            companyId={
                                currentCompany && currentCompany.company_id
                            }
                        >
                            <Home
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </CompanyPPVLogView>
                    }
                />
                <Route
                    path="ppv/embed/:subjectId"
                    element={
                        <PPVEmbed>
                            <Home
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PPVEmbed>
                    }
                />
                <Route
                    path="settings/*"
                    element={
                        <PrivateRoute>
                            {' '}
                            <Settings
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            >
                                <Home
                                    companyId={
                                        currentCompany &&
                                        currentCompany.company_id
                                    }
                                />
                            </Settings>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="automations/*"
                    element={
                        trainingEnabled ? (
                            <PrivateRoute>
                                <Automations
                                    companyId={
                                        currentCompany &&
                                        currentCompany.company_id
                                    }
                                />
                            </PrivateRoute>
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />
                <Route
                    path="trainings/*"
                    element={
                        trainingEnabled ? (
                            <PrivateRoute>
                                <Automations
                                    companyId={
                                        currentCompany &&
                                        currentCompany.company_id
                                    }
                                />
                            </PrivateRoute>
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />
                <Route
                    path="assessment/*"
                    element={
                        assessmentEnabled ? (
                            <PrivateRoute>
                                <Assessment
                                    companyId={
                                        currentCompany &&
                                        currentCompany.company_id
                                    }
                                />
                            </PrivateRoute>
                        ) : (
                            <PrivateRoute>
                                <Navigate to="/" replace={true} />
                            </PrivateRoute>
                        )
                    }
                />
                <Route
                    path="users/*"
                    element={
                        <PrivateRoute>
                            <Users
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="catalog/*"
                    element={
                        <PrivateRoute>
                            <Content />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="content/*"
                    element={
                        <PrivateRoute>
                            <Content />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="reports/*"
                    element={
                        <PrivateRoute>
                            <Reports />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="auth/signin"
                    element={
                        <AuthRoute>
                            <SignIn />
                        </AuthRoute>
                    }
                />
                <Route
                    path="auth/check-email"
                    element={
                        <AuthRoute>
                            <CheckEmail />
                        </AuthRoute>
                    }
                />
                <Route
                    path="auth/email/signin/:token"
                    element={<EmailSignIn />}
                />
                <Route
                    path="auth/jwt/signin/:token"
                    element={<TokenSignIn />}
                />

                <Route path="auth/error/email" element={<EmailSignInError />} />

                {/* Finally, catch all unmatched routes */}
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default observer(MainRoutes);
