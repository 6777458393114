import React, { useState } from 'react';
import {
    Block,
    TextDefault,
    SpaceBetweenDiv,
    Heading3,
    Heading4,
} from '@awarego/awarego-components';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ThemedDropdownItem } from '../../../../components/themed/ThemedComponents';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';

function CompletedAssessment({
    HugeHeading,
    editingAssessment,
    hasStarted,
    isScorm = false,
}) {
    const { assessmentEmployeesStoresFactory } = useStores();
    const [employeesDropdown, setEmployeesDropdown] = useState(false);
    const toggleEmployeesDropdown = () => {
        setEmployeesDropdown(!employeesDropdown);
    };

    const showFinishedEmployees = () => {
        assessmentEmployeesStoresFactory.showEmployeesModal('users', {
            isFinished: true,
            subheading: 'Employees who completed the assessment',
            scoreName: 'Resilience score',
        });
    };

    const showNotStartedEmployees = () => {
        assessmentEmployeesStoresFactory.showEmployeesModal('users', {
            isStarted: false,
            subheading: "Employees who didn't start the assessment",
            scoreName: 'Resilience score',
        });
    };

    const showNotFinishedEmployees = () => {
        assessmentEmployeesStoresFactory.showEmployeesModal('users', {
            isStarted: true,
            isFinished: false,
            subheading: "Employees who didn't complete the assessment",
            scoreName: 'Resilience score',
        });
    };

    return (
        <Block resetMargin gap={16}>
            <Heading3>
                {isScorm
                    ? 'Employees who completed the assessment'
                    : 'Completed the assessment'}
            </Heading3>
            <HugeHeading lighter={!hasStarted}>
                {isScorm
                    ? editingAssessment._rawData.usersFinished
                    : `${editingAssessment.attendance}%`}
            </HugeHeading>
            {hasStarted ? (
                <SpaceBetweenDiv>
                    {!isScorm && (
                        <TextDefault bold lighter>
                            {editingAssessment._rawData.usersFinished}/
                            {editingAssessment._rawData.usersTotal} employees
                        </TextDefault>
                    )}

                    <Dropdown
                        isOpen={employeesDropdown}
                        toggle={toggleEmployeesDropdown}
                    >
                        <TextDefault
                            bold
                            themed
                            flex
                            link
                            onClick={() =>
                                isScorm ? showFinishedEmployees() : undefined
                            }
                        >
                            {isScorm ? (
                                'View employees'
                            ) : (
                                <DropdownToggle
                                    caret
                                    tag="span"
                                    onClick={() => toggleEmployeesDropdown}
                                >
                                    View employees{' '}
                                </DropdownToggle>
                            )}
                        </TextDefault>
                        {!isScorm && (
                            <DropdownMenu>
                                <ThemedDropdownItem
                                    onClick={showFinishedEmployees}
                                >
                                    Employees who completed the assessment (
                                    {editingAssessment._rawData.usersFinished})
                                </ThemedDropdownItem>
                                <ThemedDropdownItem
                                    onClick={showNotStartedEmployees}
                                >
                                    Employees who didn't start the assessment (
                                    {editingAssessment._rawData.usersTotal -
                                        editingAssessment._rawData.usersStarted}
                                    )
                                </ThemedDropdownItem>
                                <ThemedDropdownItem
                                    onClick={showNotFinishedEmployees}
                                >
                                    Employees who didn't complete the assessment
                                    (
                                    {editingAssessment._rawData.usersStarted -
                                        editingAssessment._rawData
                                            .usersFinished}
                                    )
                                </ThemedDropdownItem>
                            </DropdownMenu>
                        )}
                    </Dropdown>
                </SpaceBetweenDiv>
            ) : (
                <Heading4 center lighter>
                    No completion rate yet
                </Heading4>
            )}
        </Block>
    );
}

export default observer(CompletedAssessment);
