import React, {
    Fragment,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    DropdownMenu,
    TabContent,
    TabPane,
    Button,
    ButtonGroup,
} from 'reactstrap';
import ErrorMessage from '../../../components/errormessage';
import moment from 'moment';
import pluralize from 'pluralize';
import SubjectsProgress from './SubjectsProgress';
import ListsProgress from './ListsProgress';
import {
    defaultZero,
    calculateColor,
    trainingName,
} from '../../../utils/helpers';
import Modal from 'react-modal';
import ModalReminders from '../modals/ModalReminders';
import ModalRename from '../../ModalRename';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import {
    ThemedDropdownItem,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../../components/themed/ThemedComponents';
import { useStores } from '../../../hooks/use-stores';
import {
    Heading2,
    Heading3,
    Heading4,
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    TextTiny,
    StatusBadge,
    InlineProgressBar,
    palette,
    TextWithTooltipIcon,
    TextDefault,
    CopyToClipboardWrapper,
} from '@awarego/awarego-components';
import styled from 'styled-components';
import EmployeesModal from '../../users/components/EmployeesModal';
import RequireRole from '../../RequireRole';
import SubHeaderComponentsList from '../../../components/header/SubHeaderComponentsList';
import HeaderSection from '../../../components/header/HeaderSection';
import SubHeaderText from '../../../components/header/SubHeaderText';
import SubHeaderLink from '../../../components/header/SubHeaderLink';
import TrainingUsersContainer from '../../users/training-users/TrainingUsersContainer';
import { STATUS } from '../../../utils/constants';
import SubHeaderSeparator from '../../../components/header/SubHeaderSeparator';
import ProgressChart from './ProgressChart';
import LessonsDeliveredDonut from './LessonsDeliveredDonut';
import io from 'socket.io-client';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import PreviewSyncLists from './PreviewSyncLists';
import Box from '@mui/material/Box';
import SubHeading from './controls/SubHeading';

const HugeHeading = styled.h1`
    font-size: 56px;
    font-weight: 700;
    text-align: center;
`;

function AutomationView({ companyId }) {
    const {
        automationStore,
        brandingStore,
        userStore,
        authStore,
        store,
        commonStore,
        automationEmployeesStoresFactory,
    } = useStores();

    const {
        editingAutomation,
        loadingAutomation,
        lastReminders,
        reminderCounter,
        loadingReminderCounter,
        latestTenAutomations,
        chartMetrics,
        chartMetricsLoading,
        chartMetricsMigrating,
        error,
    } = automationStore;

    const { token, anonymousTracking } = authStore;
    const { templateStandards } = brandingStore;

    const [extraActionsDropdown, setExtraActionsDropdown] = useState(false);
    const [showingLatestTrainingsDropdown, showLatestTrainingsDropdown] =
        useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [remindersModal, setRemindersModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [statSent, setStatSent] = useState('sent');
    const [manualSyncListsModal, setManualSyncListsModal] = useState(false);

    const statSentChange = (value) => {
        setStatSent(value);
    };

    let navigate = useNavigate();
    const { id: automationId } = useParams();

    const chartsEnabled =
        store.currentCompany &&
        store.currentCompanyUISettings['ui.trainings.charts'];

    const doLoadData = useCallback(async () => {
        const companyChanged = await automationStore.loadAutomation(
            companyId,
            automationId
        );
        if (chartsEnabled)
            void automationStore.loadAutomationChartMetrics(
                companyId,
                automationId
            );
        await loadLatestTrainings();
        if (companyChanged) {
            return navigate('/trainings');
        }
    }, [automationId, automationStore, companyId]);

    useEffect(() => {
        doLoadData();
    }, [companyId, automationId, doLoadData]);

    useEffect(() => {
        // Cleanup run on component unmount
        return () => {
            automationStore.perUserDetailsLoaded = false;
        };
    }, []);

    const doDelete = async () => {
        if (await automationStore.delete(companyId, automationId))
            return navigate('/trainings');
    };

    const onDelete = () => {
        commonStore.showConfirm(
            'Are you sure you want to delete this training?',
            'Delete',
            'Delete Training',
            async () => {
                await doDelete();
            },
            true
        );
    };

    useEffect(() => {
        const socket = io.connect(import.meta.env.REACT_APP_API_URL, {
            query: `token=${authStore.token}`,
            transports: ['websocket'],
        });

        socket.on('unauthorized', (error) => {
            if (
                error.data.type === 'UnauthorizedError' ||
                error.data.code === 'invalid_token'
            ) {
                console.log('error', error);
            }
        });

        socket.on('connect', async function () {
            socket.emit(`training-metrics`, {
                trainingId: automationId,
            });
        });

        socket.on(`training:${automationId}:update_metrics`, function (data) {
            automationStore.insertChartMetric(data);
        });

        socket.on(`training:${automationId}:init_metrics`, function (data) {
            console.log('init_metrics', data);
            automationStore.setChartMetric(data);
        });

        socket.on('disconnect', function () {
            //   console.log('disconnect');
        });

        return () => {
            socket.disconnect();
            socket.close();
        };
    }, [authStore.token, companyId, automationId]);

    const onDeleteEmployee = (user) => {
        let lists = Array.isArray(user.lists_ids)
            ? user.lists_ids
            : user.lists_ids.split(',').map((x) => x.trim());
        let listNames = user.lists_names.map((o) => o.name);

        let userToDelete = {
            companyId: companyId,
            listsId: lists,
            email: user.email,
        };
        let description = `By doing this, user '${user.email}' will be removed from following lists: '${listNames}' 
        and from all training programs associated with this list(s).`;
        userStore.buildDeleteUserDialog(userToDelete, description, () => {
            editingAutomation.removeUser(user.email);
        });
    };

    const pauseAutomation = async () => {
        commonStore.showConfirm(
            'Are you sure you want to stop this training?',
            'Stop',
            'Stop training',
            async () => {
                const result = await automationStore.pauseAutomation(
                    companyId,
                    automationId
                );
                if (result) commonStore.success('Training stopped!');
            },
            true
        );
    };

    const toggleExtraActionsDropdown = () => {
        setExtraActionsDropdown(!extraActionsDropdown);
    };

    const loadLatestTrainings = async () => {
        await automationStore.loadLatestTenAutomations(companyId);
    };

    /** length of the latestTenAutomations array, excluding the training currently being viewed */
    const latestTenAutomationsCount = () => {
        return latestTenAutomations.filter(
            (a) => a.id !== parseInt(automationId)
        ).length;
    };

    const toggleLatestTrainings = () => {
        !showingLatestTrainingsDropdown &&
            latestTenAutomationsCount() === 0 &&
            loadLatestTrainings();
        showLatestTrainingsDropdown(!showingLatestTrainingsDropdown);
    };

    const tabsChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const showRemindersModal = () => {
        setRemindersModal(true);
    };

    const hideRemindersModal = () => {
        setRemindersModal(false);
    };

    const loadRemindersCount = async (reminderOption) => {
        automationStore.loadRemindersCounter(
            companyId,
            automationId,
            reminderOption
        );
    };

    const handleManualSyncLists = async () => {
        automationStore.previewUsersToRemoveSelection.clearSelection();
        automationStore.previewUsersToAddSelection.clearSelection();
        setManualSyncListsModal(true);
    };
    const handleSyncLists = async () => {
        await automationStore.syncUsers(companyId, automationId);
        setManualSyncListsModal(false);
    };

    const handleSwitchSyncLists = async () => {
        const action = editingAutomation.disableSyncLists
            ? 'Enable'
            : 'Disable';

        commonStore.showConfirm(
            <>
                {editingAutomation.disableSyncLists
                    ? `Would you like to activate automatic synchronization of the user list? Enabling this feature ensures that any modifications to the user lists are immediately updated in this training session.`
                    : `Would you like to disable automatic synchronization of the user list? If you disable this feature, changes to the user lists will not be automatically updated in this training. However, you can still synchronize the lists manually.`}
                {editingAutomation.disableSyncLists && (
                    <FlexDiv pt={16}>
                        <PreviewSyncLists
                            automationId={automationId}
                            companyId={companyId}
                        />
                    </FlexDiv>
                )}
            </>,
            `${action}`,
            `${action} user list sync`,
            async () => {
                const result = await automationStore.switchSyncLists(
                    companyId,
                    automationId,
                    !editingAutomation.disableSyncLists
                );
                if (result) {
                    commonStore.success('User list sync switched!');
                }
            }
        );
    };
    const sendReminders = async (reminderOption) => {
        let result = await automationStore.sendReminders(
            companyId,
            automationId,
            reminderOption
        );
        if (result) {
            commonStore.success('Reminders sent!');
        }
        hideRemindersModal();
    };

    let attendance,
        score,
        reportUrl,
        reportUsersUrl,
        signInLinksUrl,
        usersOverviewReportUrl,
        detailedUsersReportUrl;

    if (editingAutomation) {
        attendance =
            editingAutomation &&
            (100 * editingAutomation._rawData.opened || 0) /
                editingAutomation._rawData.totalUsers;
        score =
            editingAutomation &&
            (statSent === 'sent'
                ? editingAutomation.awarenessScoreSent
                : editingAutomation.awarenessScore);

        reportUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            editingAutomation.id
        }/report?token=${token}`;
        reportUsersUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            editingAutomation.id
        }/report?users_details=1&token=${token}`;
        usersOverviewReportUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            editingAutomation.id
        }/users_report/csv?token=${token}`;
        detailedUsersReportUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            editingAutomation.id
        }/users_detailed_report/csv?token=${token}`;
        signInLinksUrl = `${
            import.meta.env.REACT_APP_API_URL
        }/companies/${companyId}/trainings/${
            editingAutomation.id
        }/users/csv?token=${token}`;
    }

    const ssoEnabled =
        store.currentCompany &&
        store.currentCompanyUISettings['ui.sso.configured'];

    const displayPdfReport =
        editingAutomation && editingAutomation._rawData.totalUsers < 1000;

    const bouncedEmails = useMemo(
        () => editingAutomation && editingAutomation._rawData.bouncedEmails,
        [editingAutomation]
    );

    const showBouncedModal = () => {
        automationEmployeesStoresFactory.showEmployeesModal('bounced', {
            subheading: 'Emails bounced',
        });
    };

    const createContextMenu = useMemo(() => {
        if (!editingAutomation) return;
        return (
            <DropdownMenu>
                <RequireRole>
                    {editingAutomation.canRemind && (
                        <ThemedDropdownItem onClick={showRemindersModal}>
                            Send reminders
                        </ThemedDropdownItem>
                    )}
                </RequireRole>
                <ThemedDropdownItem>
                    <a
                        href={reportUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Report (.pdf)
                    </a>
                </ThemedDropdownItem>

                {!anonymousTracking && (
                    <>
                        {displayPdfReport && (
                            <ThemedDropdownItem>
                                <a
                                    href={reportUsersUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Employee report (.pdf)
                                </a>
                            </ThemedDropdownItem>
                        )}
                        <ThemedDropdownItem>
                            <a
                                href={usersOverviewReportUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Employee report (.csv)
                            </a>
                        </ThemedDropdownItem>
                        <ThemedDropdownItem>
                            <a
                                href={detailedUsersReportUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Detailed employee report (.csv)
                            </a>
                        </ThemedDropdownItem>
                        {false && (
                            <RequireRole>
                                <ThemedDropdownItem>
                                    <a
                                        href={signInLinksUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Sign in links (.csv)
                                    </a>
                                </ThemedDropdownItem>
                                <ThemedDropdownItem
                                    onClick={handleSwitchSyncLists}
                                >
                                    User lists sync:{' '}
                                    {editingAutomation.disableSyncLists ? (
                                        <TextTiny
                                            color={palette.vibrant.red}
                                            bold
                                        >
                                            Off
                                        </TextTiny>
                                    ) : (
                                        <TextTiny
                                            color={palette.evergreen.aspargus}
                                            bold
                                        >
                                            On
                                        </TextTiny>
                                    )}
                                </ThemedDropdownItem>
                                {editingAutomation.disableSyncLists && (
                                    <ThemedDropdownItem
                                        onClick={handleManualSyncLists}
                                    >
                                        Sync user lists now
                                    </ThemedDropdownItem>
                                )}
                            </RequireRole>
                        )}
                    </>
                )}

                <RequireRole>
                    {editingAutomation.canRename && (
                        <ThemedDropdownItem
                            onClick={() => setRenameModal(true)}
                        >
                            Rename training
                        </ThemedDropdownItem>
                    )}
                    {editingAutomation.canCopy && (
                        <ThemedDropdownItem>
                            <Link
                                to={`/trainings/create/copy/${editingAutomation.id}`}
                            >
                                Copy training
                            </Link>
                        </ThemedDropdownItem>
                    )}
                    {editingAutomation.canStop && (
                        <ThemedDropdownItem onClick={pauseAutomation}>
                            Stop training
                        </ThemedDropdownItem>
                    )}
                    {editingAutomation.canDelete && (
                        <ThemedDropdownItem onClick={onDelete}>
                            <TextTiny color={palette.vibrant.red} bold>
                                Delete training
                            </TextTiny>
                        </ThemedDropdownItem>
                    )}
                </RequireRole>
            </DropdownMenu>
        );
    }, [editingAutomation, displayPdfReport, pauseAutomation, onDelete]);

    const createHeadingDropdown = useMemo(() => {
        if (!editingAutomation) return;
        return (
            <DropdownMenu>
                {automationStore.loadingAutomations ? (
                    <ThemedSpinner />
                ) : (
                    latestTenAutomations.map((x, i) => {
                        if (x.id !== editingAutomation.id)
                            return (
                                <ThemedDropdownItem key={`${i}-${x.id}`}>
                                    <Link to={`/trainings/${x.id}/view`}>
                                        {x.name}
                                    </Link>
                                </ThemedDropdownItem>
                            );
                    })
                )}
            </DropdownMenu>
        );
    }, [
        editingAutomation,
        latestTenAutomations,
        automationStore.loadingAutomations,
    ]);

    return (
        <Fragment>
            <Link to={`/trainings/`}>
                <div className={'navigation back-button'}>
                    Back to training overview
                </div>
            </Link>
            <ErrorMessage error={error} />
            {loadingAutomation ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {editingAutomation ? (
                        <Fragment>
                            <HeaderSection
                                heading={trainingName(
                                    editingAutomation.name,
                                    editingAutomation._rawData.created
                                )}
                                headingDropdown={createHeadingDropdown}
                                headingDropdownContentCount={latestTenAutomationsCount()}
                                subHeadings={
                                    <SubHeaderComponentsList>
                                        <SubHeading
                                            editingAutomation={
                                                editingAutomation
                                            }
                                            companyId={companyId}
                                            bouncedEmails={bouncedEmails}
                                            automationId={automationId}
                                            ssoEnabled={ssoEnabled}
                                            showBouncedModal={showBouncedModal}
                                            templateStandards={
                                                templateStandards
                                            }
                                        />
                                    </SubHeaderComponentsList>
                                }
                                contextMenu={createContextMenu}
                            />
                            {chartsEnabled && (
                                <>
                                    {chartMetricsLoading && <ThemedSpinner />}

                                    {!chartMetricsLoading && (
                                        <Row>
                                            <Col
                                                md="3"
                                                xs="12"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Block>
                                                    <Row>
                                                        <Col md="12" xs="12">
                                                            <TextWithTooltipIcon
                                                                textComponent={
                                                                    <Heading2>
                                                                        Lessons
                                                                        delivered
                                                                    </Heading2>
                                                                }
                                                                tooltipText={
                                                                    'Lessons that have already been sent.'
                                                                }
                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12">
                                                            <LessonsDeliveredDonut
                                                                lessonsDelivered={
                                                                    editingAutomation.lessonsSent
                                                                }
                                                                lessonsTotal={
                                                                    editingAutomation.lessonsTotal
                                                                }
                                                                chartData={
                                                                    chartMetrics
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Block>
                                                <Block gap={16}>
                                                    <Heading3>
                                                        Invited employees
                                                    </Heading3>
                                                    <HugeHeading>
                                                        {
                                                            editingAutomation.totalUsers
                                                        }
                                                    </HugeHeading>
                                                </Block>
                                                <Block gap={16} flexGrow={1}>
                                                    <TextWithTooltipIcon
                                                        textComponent={
                                                            <Heading3>
                                                                Employee list
                                                                sync
                                                            </Heading3>
                                                        }
                                                        tooltipText={
                                                            editingAutomation.disableSyncLists
                                                                ? 'User sync is inactive. Changes to the employee lists will not be automatically updated in this training.'
                                                                : 'User sync is active. Users added to user lists in this training are automatically added to this training.'
                                                        }
                                                    />
                                                    <SpaceBetweenDiv>
                                                        <Heading2
                                                            style={{
                                                                borderBottom:
                                                                    '1px dotted ' +
                                                                    (editingAutomation.disableSyncLists
                                                                        ? palette
                                                                              .vibrant
                                                                              .orange
                                                                        : palette
                                                                              .evergreen
                                                                              .aspargus),
                                                            }}
                                                            link
                                                            onClick={
                                                                handleSwitchSyncLists
                                                            }
                                                            color={
                                                                editingAutomation.disableSyncLists
                                                                    ? palette
                                                                          .vibrant
                                                                          .orange
                                                                    : palette
                                                                          .evergreen
                                                                          .aspargus
                                                            }
                                                        >
                                                            {editingAutomation.disableSyncLists
                                                                ? 'Inactive'
                                                                : 'Active'}
                                                        </Heading2>
                                                        {/*<SubHeaderSeparator />*/}
                                                        {/*<TextTiny*/}
                                                        {/*    link*/}
                                                        {/*    onClick={*/}
                                                        {/*        handleSwitchSyncLists*/}
                                                        {/*    }*/}
                                                        {/*>*/}
                                                        {/*    Change*/}
                                                        {/*</TextTiny>*/}
                                                        {editingAutomation.disableSyncLists && (
                                                            <>
                                                                <SubHeaderSeparator />
                                                                <TextTiny
                                                                    link
                                                                    onClick={
                                                                        handleManualSyncLists
                                                                    }
                                                                >
                                                                    Sync users
                                                                </TextTiny>
                                                            </>
                                                        )}
                                                    </SpaceBetweenDiv>
                                                </Block>
                                            </Col>
                                            <Col md="9" xs="12">
                                                <Row>
                                                    <Col md="6" xs="12">
                                                        <Block>
                                                            <SpaceBetweenDiv
                                                                gap={8}
                                                                rowGap={4}
                                                            >
                                                                <TextWithTooltipIcon
                                                                    textComponent={
                                                                        <Heading2>
                                                                            Completion
                                                                            rate
                                                                        </Heading2>
                                                                    }
                                                                    tooltipText={
                                                                        'The score improves once your employees watch the assigned training videos and successfully complete the training quizzes.'
                                                                    }
                                                                />
                                                                {editingAutomation.canRemind && (
                                                                    <TextDefault
                                                                        themed
                                                                        link
                                                                        bold
                                                                        onClick={
                                                                            showRemindersModal
                                                                        }
                                                                    >
                                                                        Send
                                                                        reminders
                                                                    </TextDefault>
                                                                )}
                                                            </SpaceBetweenDiv>
                                                            <ThemedNav training>
                                                                <ThemedNavItem
                                                                    active={
                                                                        statSent ===
                                                                        'sent'
                                                                    }
                                                                >
                                                                    <ThemedNavLink
                                                                        onClick={() => {
                                                                            statSentChange(
                                                                                'sent'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Sent
                                                                        Lessons
                                                                    </ThemedNavLink>
                                                                </ThemedNavItem>
                                                                <ThemedNavItem
                                                                    active={
                                                                        statSent ===
                                                                        'all'
                                                                    }
                                                                >
                                                                    <ThemedNavLink
                                                                        onClick={() => {
                                                                            statSentChange(
                                                                                'all'
                                                                            );
                                                                        }}
                                                                    >
                                                                        All
                                                                        lessons
                                                                    </ThemedNavLink>
                                                                </ThemedNavItem>
                                                            </ThemedNav>

                                                            <p className="mtb20">
                                                                <strong>
                                                                    {defaultZero(
                                                                        score
                                                                    ).toFixed(
                                                                        0
                                                                    )}
                                                                    % of
                                                                    {statSent ===
                                                                    'sent'
                                                                        ? ' sent '
                                                                        : ' assigned '}
                                                                    training has
                                                                    been
                                                                    completed
                                                                </strong>
                                                            </p>
                                                            <FlexDiv $noWrap>
                                                                <FlexDiv
                                                                    fullWidth
                                                                    alignCenter
                                                                >
                                                                    <InlineProgressBar
                                                                        customColor={calculateColor(
                                                                            score
                                                                        )}
                                                                        value={
                                                                            score
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </FlexDiv>

                                                                <FlexDiv
                                                                    flexGrow={1}
                                                                >
                                                                    <Heading2
                                                                        color={calculateColor(
                                                                            score
                                                                        )}
                                                                    >
                                                                        {defaultZero(
                                                                            score
                                                                        ).toFixed(
                                                                            0
                                                                        )}
                                                                        /100
                                                                    </Heading2>
                                                                </FlexDiv>
                                                            </FlexDiv>
                                                        </Block>
                                                    </Col>
                                                    <Col md="6" xs="12">
                                                        <Block>
                                                            <TextWithTooltipIcon
                                                                textComponent={
                                                                    <Heading2>
                                                                        Attendance
                                                                    </Heading2>
                                                                }
                                                                tooltipText={
                                                                    ' This is the number of everyone who has logged in, regardless if they finished a lesson.'
                                                                }
                                                            />

                                                            <Box
                                                                height={45}
                                                            ></Box>
                                                            <p className="mtb20">
                                                                <strong>
                                                                    {editingAutomation
                                                                        ._rawData
                                                                        .opened ||
                                                                        '0'}{' '}
                                                                    employees
                                                                    logged in
                                                                </strong>{' '}
                                                                out of{' '}
                                                                {
                                                                    editingAutomation
                                                                        ._rawData
                                                                        .totalUsers
                                                                }
                                                            </p>
                                                            <FlexDiv $noWrap>
                                                                <FlexDiv
                                                                    fullWidth
                                                                    alignCenter
                                                                >
                                                                    <InlineProgressBar
                                                                        customColor={calculateColor(
                                                                            attendance
                                                                        )}
                                                                        value={
                                                                            attendance
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </FlexDiv>

                                                                <FlexDiv
                                                                    flexGrow={1}
                                                                >
                                                                    <Heading2
                                                                        color={calculateColor(
                                                                            attendance
                                                                        )}
                                                                    >
                                                                        {editingAutomation
                                                                            ._rawData
                                                                            .opened ||
                                                                            '0'}
                                                                        /
                                                                        {
                                                                            editingAutomation
                                                                                ._rawData
                                                                                .totalUsers
                                                                        }
                                                                    </Heading2>
                                                                </FlexDiv>
                                                            </FlexDiv>
                                                        </Block>
                                                    </Col>
                                                </Row>
                                                <Block>
                                                    {chartMetricsMigrating ? (
                                                        <Heading2>
                                                            Preparing data
                                                        </Heading2>
                                                    ) : (
                                                        <ProgressChart
                                                            chartData={
                                                                chartMetrics
                                                            }
                                                        />
                                                    )}
                                                </Block>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}

                            <div className="block">
                                <ThemedNav training>
                                    <ThemedNavItem active={activeTab === '1'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('1');
                                            }}
                                        >
                                            Lessons
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                    <ThemedNavItem active={activeTab === '2'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('2');
                                            }}
                                        >
                                            Lists
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                    <ThemedNavItem active={activeTab === '3'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('3');
                                            }}
                                        >
                                            Employees
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                </ThemedNav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <SubjectsProgress
                                            training={
                                                editingAutomation._rawData
                                            }
                                            subjects={
                                                editingAutomation._rawData
                                                    .subjects
                                            }
                                            reminders={
                                                editingAutomation.remindersList
                                            }
                                            userCount={
                                                editingAutomation._rawData
                                                    .totalUsers
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <ListsProgress
                                            lists={
                                                editingAutomation._rawData.lists
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TrainingUsersContainer
                                            ssoEnabled={ssoEnabled}
                                            anonymousTracking={
                                                anonymousTracking
                                            }
                                            users={editingAutomation.users}
                                            lists={
                                                editingAutomation._rawData.lists
                                            }
                                            training={editingAutomation}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                            <Modal
                                isOpen={remindersModal}
                                onRequestClose={hideRemindersModal}
                                className="Modal schedule"
                            >
                                <ModalReminders
                                    target={editingAutomation}
                                    lastReminders={lastReminders}
                                    reminderCounter={reminderCounter}
                                    loadingReminderCounter={
                                        loadingReminderCounter
                                    }
                                    onLoadRemindersCount={loadRemindersCount}
                                    onRequestClose={hideRemindersModal}
                                    onSendReminders={sendReminders}
                                />
                            </Modal>
                            <Modal
                                isOpen={renameModal}
                                onRequestClose={() => setRenameModal(false)}
                                className="Modal"
                            >
                                <ModalRename
                                    onRequestClose={() => setRenameModal(false)}
                                    item={editingAutomation}
                                    itemStore={automationStore}
                                    companyId={companyId}
                                />
                            </Modal>
                            {/* employees modal */}
                            <EmployeesModal
                                companyId={companyId}
                                productId={automationId}
                                productEmployeesStoresFactory={
                                    automationEmployeesStoresFactory
                                }
                                product={automationStore.editingAutomation}
                            />

                            <Dialog
                                maxWidth={'md'}
                                fullWidth
                                open={manualSyncListsModal}
                                onClose={() => {
                                    setManualSyncListsModal(false);
                                }}
                            >
                                <DialogTitle>Sync Users</DialogTitle>
                                <DialogContent>
                                    <PreviewSyncLists
                                        automationId={automationId}
                                        companyId={companyId}
                                        selectable={true}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <SpaceBetweenDiv
                                        gap={24}
                                        alignCenter
                                        px={16}
                                        py={8}
                                    >
                                        <FlexDiv gap={8} alignCenter>
                                            {(automationStore.previewUsersToAdd
                                                .length > 0 ||
                                                automationStore
                                                    .previewUsersToRemove
                                                    .length > 0) && (
                                                <>
                                                    <TextTiny
                                                        bold
                                                        color={
                                                            palette.evergreen
                                                                .aspargus
                                                        }
                                                    >
                                                        {
                                                            automationStore
                                                                .previewUsersToAddSelection
                                                                .totalSelectionCountOnAllPages
                                                        }{' '}
                                                        {pluralize(
                                                            'user',
                                                            automationStore
                                                                .previewUsersToAddSelection
                                                                .totalSelectionCountOnAllPages
                                                        )}{' '}
                                                        will be added.
                                                    </TextTiny>{' '}
                                                    <TextTiny
                                                        bold
                                                        color={
                                                            palette.vibrant
                                                                .orange
                                                        }
                                                    >
                                                        {
                                                            automationStore
                                                                .previewUsersToRemoveSelection
                                                                .totalSelectionCountOnAllPages
                                                        }{' '}
                                                        {pluralize(
                                                            'user',
                                                            automationStore
                                                                .previewUsersToRemoveSelection
                                                                .totalSelectionCountOnAllPages
                                                        )}{' '}
                                                        will be removed.
                                                    </TextTiny>
                                                </>
                                            )}
                                        </FlexDiv>
                                        <FlexDiv gap={8} alignCenter>
                                            <TextDefault
                                                uppercase
                                                extraBold
                                                link
                                                onClick={() => {
                                                    setManualSyncListsModal(
                                                        false
                                                    );
                                                }}
                                            >
                                                Close
                                            </TextDefault>
                                            {(automationStore.previewUsersToAdd
                                                .length > 0 ||
                                                automationStore
                                                    .previewUsersToRemove
                                                    .length > 0) && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSyncLists}
                                                >
                                                    Sync Now
                                                </Button>
                                            )}
                                        </FlexDiv>
                                    </SpaceBetweenDiv>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    ) : (
                        <Block>Training program not found :(</Block>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AutomationView);
