import React from 'react';
import { FlexDiv } from '@awarego/awarego-components';

function UserModalTooltipText() {
    return (
        <FlexDiv column gap={16}>
            Here are some ideas on how you can organize your employees into
            lists:
            <ul
                style={{
                    paddingInlineStart: '16px',
                }}
            >
                <li>
                    <b>Company org chart</b> (Finance, Marketing, IT department,
                    etc.)
                </li>
                <li>
                    <b>Seniority</b> (C-level, entry level, mid-level, etc.)
                </li>
                <li>
                    <b>Employee maturity</b> (e.g., new hires)
                </li>
                <li>
                    <b>Exposure</b> (e.g., privileged users, critical data
                    users)
                </li>
                <li>
                    <b>Based on where employees work</b> (e.g., on-premise,
                    remote, frequent travelers)
                </li>
                <li>
                    <b>Risk areas</b> (physical vs cybersecurity)
                </li>
            </ul>
        </FlexDiv>
    );
}

export default UserModalTooltipText;
