import React from 'react';
import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import { FlexDiv, InputLabel } from '@awarego/awarego-components';

function ContentFilter({ filterName, filterValue, setFilter, filterOptions }) {
    return (
        <FlexDiv flexGrow={1} width={240}>
            <FormControl
                variant="outlined"
                color="primary"
                style={{ width: '100%' }}
            >
                <InputLabel text={filterName} />
                <Select
                    label={filterName}
                    multiple
                    value={filterValue}
                    onChange={(e) => setFilter(e.target.value)}
                    renderValue={(selected) =>
                        filterOptions
                            .filter((list) => selected.includes(list.id))
                            .map((list, i, arr) => (
                                <span key={list.id}>
                                    {/* don't put comma after last list  */}
                                    {list.name}
                                    {i !== arr.length - 1 ? ', ' : ''}
                                </span>
                            ))
                    }
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        variant: 'menu',
                    }}
                >
                    {filterOptions
                        .sort((a, b) => a.name - b.name)
                        .map((list) => (
                            <MenuItem
                                disableRipple
                                key={list.name}
                                value={list.id}
                                style={{
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                }}
                            >
                                <Checkbox
                                    checked={filterValue.indexOf(list.id) > -1}
                                    color="primary"
                                />
                                {list.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </FlexDiv>
    );
}

export default observer(ContentFilter);
