import React, { Fragment } from 'react';
import ThreatAreasTable from './ThreatAreasTable';
import KeyBehavioursTable from './KeyBehavioursTable';
import EmployeeListScoresPerThreat from './employee-list-scores-per-threat';
import { observer } from 'mobx-react';

function ThreatAreas({
    threatAreas,
    editingAssessment,
    assessmentId,
    companyId,
    hasStarted,
    showLists = true,
}) {
    return (
        <Fragment>
            <ThreatAreasTable
                editingAssessment={editingAssessment}
                assessmentId={assessmentId}
                companyId={companyId}
                hasStarted={hasStarted}
            />
            <KeyBehavioursTable
                threatAreas={threatAreas}
                needsFilter
                assessmentId={assessmentId}
                companyId={companyId}
                hasStarted={hasStarted}
            />
            {showLists &&
                editingAssessment &&
                editingAssessment.selectedUserListsIds.length > 1 && (
                    <EmployeeListScoresPerThreat
                        hasStarted={hasStarted}
                        assessmentId={assessmentId}
                        companyId={companyId}
                    />
                )}
        </Fragment>
    );
}

export default observer(ThreatAreas);
