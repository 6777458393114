import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import ErrorMessage from '../../errormessage';
import ThemedEmailSentSVG from '../../themed/ThemedEmailSentSVG';
import { Heading1, Heading3, ThemedButton } from '@awarego/awarego-components';

function PreviewEmail({ ownEmail, onRequestClose, sendPreview, sendError }) {
    const [targetEmail, settargetEmail] = useState(ownEmail);
    const [previewSent, setpreviewSent] = useState(false);

    // should be deleted once backend works better..
    const previewType = 0;
    const selectedEvents = [];

    const onPreviewSend = () => {
        sendPreview(targetEmail, previewType, selectedEvents);
        setpreviewSent(true);
    };

    return (
        <div className="modalWindow simple">
            <div className={'modalHeader plain'}>
                {!previewSent && <Heading1>Send a preview of emails</Heading1>}
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <ErrorMessage error={sendError} />
            {!previewSent ? (
                <Fragment>
                    <div
                        className={'modalContent'}
                        style={{ minHeight: '120px' }}
                    >
                        <div className="form-holder">
                            <TextField
                                type="email"
                                name="recipientsEmail"
                                required
                                className={'bigInput'}
                                value={targetEmail}
                                label="Recipient's email address"
                                onChange={(e) => {
                                    settargetEmail(e.target.value);
                                }}
                                helperText="Use commas(,) to add recipients"
                            />
                        </div>
                    </div>
                    <div className={'modalFooter'}>
                        <ThemedButton
                            mode="primary"
                            text="Send Preview"
                            onClick={onPreviewSend}
                            disabled={!(targetEmail !== '')}
                        />
                    </div>
                </Fragment>
            ) : (
                <div className={'modalContent'}>
                    <div className="no-assessments-found">
                        <ThemedEmailSentSVG />
                        <div className="stats">
                            <Heading3 mt={16} mb={8} displayBlock>
                                Preview sent successfully
                            </Heading3>
                            <p>
                                The recipients you’ve entered received the
                                preview of this assessment
                            </p>
                        </div>
                        <ThemedButton
                            mode="primary"
                            text="Ok"
                            onClick={onRequestClose}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default observer(PreviewEmail);
