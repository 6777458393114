import { makeAutoObservable } from 'mobx';
import services from '../services';

class QuestionsStore {
    loadingData = false;
    error = null;
    question = null;
    questionAnswers = null;

    constructor(companyStore, mainStore) {
        makeAutoObservable(this);
        this.companyStore = companyStore;
        this.mainStore = mainStore;
    }

    setError = (error) => {
        this.error = error;
    };

    async loadQuestionPreview(companyId, id, background) {
        if (!background) {
            if (this.loadingData === id) return;
            this.loadingData = id;
        }
        this.setError(null);
        try {
            this.question = await services.Companies.questionsService(
                companyId
            ).fetchPreview(id);
            if (
                !this.mainStore.assessmentEnabled ||
                (this.mainStore.currentCompany.subscription
                    .subscription_definition.assessmentQuestions !== 'all' &&
                    this.mainStore.currentCompany.subscription.subscription_definition.assessmentQuestions.indexOf(
                        id
                    ) < 0)
            ) {
                this.question.disabled = true;
            }
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    async loadQuestionReview(companyId, id, assessmentId, background) {
        if (!background) {
            if (this.loadingData === id) return;
            this.loadingData = id;
        }
        this.setError(null);
        try {
            this.question = await services.Companies.assessmentsService(
                companyId
            )
                .questionsService(assessmentId)
                .fetch(id);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    async loadQuestionAnswer(companyId, assessmentId, questionId, background) {
        if (!background) {
            if (this.loadingData === questionId) return;
            this.loadingData = questionId;
        }
        this.setError(null);
        try {
            this.questionAnswers = await services.Companies.questionsService(
                companyId
            ).getAnswers(assessmentId, questionId);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }
}

export default QuestionsStore;
