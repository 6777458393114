import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { ThemedLink } from '../themed/ThemedComponents';
import HelpIcon from '@mui/icons-material/Help';
import { LightTooltip } from '@awarego/awarego-components';
import { handleSelectedFile } from './file-helpers';
import { extractErrorMessage } from '../../utils/helpers';
import ErrorMessage from '../errormessage';

function DragAndDropImport({ ...props }) {
    const fileRef = React.createRef();
    const [error, setError] = useState(null);

    const handleFilesSelect = async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        let encoding = 'utf-8';

        try {
            let files = evt.target.files || evt.dataTransfer.files;

            let properties = {
                fileEndings: props.fileEndings,
                fileType: props.fileType,
            };

            let handledFiles = await handleSelectedFile(
                files,
                encoding,
                properties
            );

            if (props.onImportFiles) {
                props.onImportFiles(handledFiles.results);
            }

            if (props.onSetAcceptedFiles) {
                props.onSetAcceptedFiles(handledFiles.acceptedFiles);
            }
        } catch (e) {
            const error = extractErrorMessage(e);
            setError(error);
        }
    };

    const handleDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    const chooseFile = (e) => {
        fileRef.current.click();
    };

    return (
        <>
            {error && <ErrorMessage error={error} />}
            <div
                className="d-flex flex-center flex-fill flex-column"
                onDragOver={handleDragOver}
                onDrop={handleFilesSelect}
            >
                <div className="company-logo-drop-zone">
                    <img
                        src="/static/img/drop-icon.svg"
                        alt="drop files icon"
                    />
                    <div className="drop_zone_title">Drag and drop</div>
                    <div className="drop_zone_description">
                        or&nbsp;
                        <ThemedLink
                            style={{ textDecoration: 'underline' }}
                            onClick={chooseFile}
                        >
                            browse
                        </ThemedLink>
                        &nbsp;for a .{props.fileType} file
                        {props.tooltip ? (
                            <LightTooltip
                                title={props.tooltip}
                                placement="bottom-start"
                                className="drop_zone_tooltip"
                            >
                                <HelpIcon />
                            </LightTooltip>
                        ) : null}
                    </div>
                    <input
                        className="hidden"
                        type="file"
                        multiple={false}
                        accept={props.acceptedFormats}
                        onChange={handleFilesSelect}
                        ref={fileRef}
                    />
                </div>
            </div>
        </>
    );
}

export default observer(DragAndDropImport);
