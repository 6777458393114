import RestService from './RestService';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/categories`);
        } else super('/categories');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetSubjects(id) {
        return this.get(`${this.baseUrl}/all/${id}/subjects`);
    }
}
