import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = {
    name: {
        name: 'itemName',
        key: 'name',
        label: 'Name',
        rules: 'string|required',
    },
};

class RenameForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default RenameForm;
