import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExclamationPointIcon from '@mui/icons-material/Error';
import uniq from 'lodash/uniq';
import './key-behaviour-coverage.scss';
import { LinearProgress } from '@mui/material';
import { defaultZero } from '../../../utils/helpers';
import {
    FlexDiv,
    Heading2,
    Heading3,
    LightTooltip,
} from '@awarego/awarego-components';

function KeyBehaviourCoverage({
    behaviours,
    questionsSelected,
    maxByAreaAndBehaviourForSelectedQuestions,
    visibility,
}) {
    const areaNames = uniq(
        questionsSelected.map((q) => {
            return q.area;
        })
    );
    const selectedAreas = behaviours.filter((area) =>
        areaNames.includes(area.name)
    );

    return (
        <div
            className={'key-behaviour-coverage'}
            style={{ opacity: visibility ? 1 : 0 }}
        >
            <Heading2 mb={8} displayBlock noWrap>
                Key behaviour coverage
            </Heading2>
            <p>
                This is how key behaviours are covered based on a given set of
                answers.
            </p>
            {selectedAreas.map((area, i) => {
                return (
                    <FlexDiv column key={i}>
                        <Heading3 extraBold mt={16}>
                            {area.name}
                        </Heading3>
                        <div className="progress-list">
                            {area.behaviours.map((b, j) => {
                                let selectedQuestionsSum =
                                    maxByAreaAndBehaviourForSelectedQuestions[
                                        `${area.name}-${b.code}`
                                    ];
                                // let allQuestionsMax = maxByAreaAndBehaviourForAllQuestions[`${area.name}-${b.code}`]
                                return (
                                    <div className={'item'} key={j}>
                                        <div className={'title'}>{b.name}</div>
                                        <div className={'graphics'}>
                                            <Fragment>
                                                {selectedQuestionsSum ===
                                                undefined ? (
                                                    <LightTooltip
                                                        title="This key behaviour is not covered. Please add more questions that cover this behaviour."
                                                        placement="bottom-start"
                                                    >
                                                        <p>Not covered</p>
                                                    </LightTooltip>
                                                ) : (
                                                    <Fragment>
                                                        <LinearProgress
                                                            value={defaultZero(
                                                                (selectedQuestionsSum /
                                                                    b.green) *
                                                                    100 >
                                                                    100
                                                                    ? 100
                                                                    : (selectedQuestionsSum /
                                                                          b.green) *
                                                                          100
                                                            )}
                                                            color={
                                                                selectedQuestionsSum >=
                                                                b.green
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            }
                                                            variant="determinate"
                                                            fullWidth
                                                            className={
                                                                'progress'
                                                            }
                                                            style={{
                                                                height: '4px',
                                                            }}
                                                        />
                                                        <div className={'icon'}>
                                                            <LightTooltip
                                                                title={
                                                                    selectedQuestionsSum >=
                                                                    b.green
                                                                        ? `This key behaviour is 100% covered within a given set of questions.`
                                                                        : 'Please add more questions that cover this key behaviour for more accurate results.'
                                                                }
                                                                placement="bottom-start"
                                                            >
                                                                {selectedQuestionsSum >=
                                                                b.green ? (
                                                                    <CheckCircleIcon color="primary" />
                                                                ) : (
                                                                    <ExclamationPointIcon color="secondary" />
                                                                )}
                                                            </LightTooltip>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </FlexDiv>
                );
            })}
        </div>
    );
}

export default observer(KeyBehaviourCoverage);
