import RestService from './RestService';

export default class Reports extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/reports`);
        } else super('/reports');
    }

    // Download either an overview report for training, or a detailed one if isDetailedReport == true
    trainingEmployeeReport(params, isDetailedReport) {
        return this.get(
            `/companies/${params.companyId}/trainings/${
                params.trainingId
            }/report${isDetailedReport ? '?users_details=1' : ''}`,
            params
        );
    }

    companyOverviewReport(params) {
        return this.get(
            `/companies/${params.companyId}/overview/report?token=${params.token}`,
            params
        );
    }
    all() {
        return this.get(`${this.baseUrl}`);
    }

    executionsByReportId(reportId, params) {
        return this.get(
            `${this.baseUrl}/${reportId}/execution?p=${params.p}&n=${params.n}&order=${params.order}`
        );
    }

    singleReport(reportId) {
        return this.get(`${this.baseUrl}/${reportId}`);
    }

    executionPDF(reportId, executionId, options) {
        return this.get(
            `${this.baseUrl}/${reportId}/execution/${executionId}/report.pdf`,
            null,
            options
        );
    }

    executionCSV(reportId, executionId, options) {
        return this.get(
            `${this.baseUrl}/${reportId}/execution/${executionId}/report.csv`,
            null,
            options
        );
    }
}
