import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import MaterialView from './material-view';
import PreviewModalHeader from '../previewModalHeader';

function MaterialModal({ material, onRequestClose }) {
    return (
        <Fragment>
            <PreviewModalHeader
                titlePrefix={'Download:'}
                title={material.title}
                onClose={onRequestClose}
            />

            <MaterialView material={material} />
        </Fragment>
    );
}

export default observer(MaterialModal);
