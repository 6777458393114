import React, { useMemo, useEffect } from 'react';
import {
    Block,
    Heading3,
    SpaceBetweenDiv,
    FlexDiv,
    TextTiny,
    StatusBadge,
    ThemedButton,
    palette,
} from '@awarego/awarego-components';
import TableClient from '../../../../components/table/TableClient';
import moment from 'moment';
import { observer } from 'mobx-react';
import { resourcePath } from '../../../../utils/helpers';
import { useStores } from '../../../../hooks/use-stores';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import { Spinner } from 'reactstrap';
import { STATUS } from '../../../../utils/constants';

function Reminders({ companyId, assessmentId }) {
    const { assessmentStore, assessmentEmployeesStoresFactory, authStore } =
        useStores();
    const rowsPerPage = 10;
    useEffect(() => {
        assessmentStore.loadRemindersOverview(companyId, assessmentId);
    }, [assessmentId, assessmentStore, companyId]);

    const { employeesOverviewStore: assessmentEmployeesStore } =
        assessmentEmployeesStoresFactory;

    const { remindersOverview, loadingRemindersOverview, editingAssessment } =
        assessmentStore;

    const { loadingEmployeesToBeSentReminders } = assessmentEmployeesStore;
    const { timeZone } = authStore;

    const columns = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (x) =>
                    x.value === 0 ? (
                        <StatusBadge
                            color={palette.vibrant.blue}
                            text={'Scheduled'}
                        />
                    ) : (
                        <StatusBadge
                            color={palette.evergreen.aspargus}
                            text={'Sent'}
                        />
                    ),
            },
            {
                Header: 'Date',
                accessor: 'send',
                Cell: (x) =>
                    x.value
                        ? moment(x.value).format(`ll LT [${timeZone}]`)
                        : '-',
            },
            {
                Header: editingAssessment.isCompleted ? 'Sent to' : 'Send to',
                accessor: 'sendTo',
            },
        ],
        [editingAssessment.isCompleted]
    );

    const showReminders = useMemo(() => {
        if (!remindersOverview.length > 0 || loadingRemindersOverview)
            return false;

        // if assessment hasn't started yet, show reminders only if there are scheduled reminders in the future
        if (editingAssessment.status === STATUS.SCHEDULED)
            return remindersOverview.some((r) => {
                return r.status === 0;
            });

        return true;
    }, [remindersOverview, loadingRemindersOverview, editingAssessment.status]);

    const sendReminderTinyButton = useMemo(() => {
        if (!editingAssessment.canRemind) return;
        if (loadingEmployeesToBeSentReminders) return <Spinner />;
        return (
            <ThemedButton
                mode="primary"
                tiny
                $noTextTransform
                onClick={() =>
                    assessmentEmployeesStore.showRemindersModal(
                        false,
                        null,
                        null,
                        null,
                        true
                    )
                }
                text="Send reminders"
            />
        );
    }, [
        assessmentEmployeesStore,
        editingAssessment.canRemind,
        loadingEmployeesToBeSentReminders,
    ]);

    const content = useMemo(() => {
        if (loadingRemindersOverview) return <ThemedSpinner />;
        if (showReminders)
            return (
                <TableClient
                    columns={columns}
                    data={remindersOverview}
                    defaultSortBy="send"
                    hidePagination={
                        remindersOverview &&
                        remindersOverview.length <= rowsPerPage
                    }
                />
            );
        return (
            <FlexDiv gap={24} column alignCenter justifyCenter>
                <img
                    src={resourcePath('/static/img/reminder-icon.svg')}
                    alt={'bell icon'}
                />
                <Heading3>
                    No reminders sent {!editingAssessment.isCompleted && 'yet'}
                </Heading3>
                <TextTiny lighter mt={-8}>
                    {!editingAssessment.isCompleted
                        ? 'Remind users to start the assessment after they receive it'
                        : 'No reminders were sent for this assessment.'}
                </TextTiny>
                {sendReminderTinyButton}
            </FlexDiv>
        );
    }, [
        columns,
        editingAssessment.isCompleted,
        loadingRemindersOverview,
        remindersOverview,
        sendReminderTinyButton,
        showReminders,
    ]);

    return (
        <Block gap={24}>
            <SpaceBetweenDiv alignCenter>
                <Heading3>Reminders</Heading3>
                {!loadingRemindersOverview &&
                    remindersOverview.length > 0 &&
                    editingAssessment.canRemind && (
                        <FlexDiv gap={16}>
                            {loadingEmployeesToBeSentReminders ? (
                                <Spinner />
                            ) : (
                                <ThemedButton
                                    mode="primary"
                                    $noTextTransform
                                    onClick={() =>
                                        assessmentEmployeesStore.showRemindersModal(
                                            false,
                                            null,
                                            null,
                                            null,
                                            true
                                        )
                                    }
                                    text="Send reminders"
                                />
                            )}
                        </FlexDiv>
                    )}
            </SpaceBetweenDiv>
            {content}
        </Block>
    );
}

export default observer(Reminders);
