import React from 'react';
import { observer } from 'mobx-react';
import {
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    TextWithTooltipIcon,
} from '@awarego/awarego-components';
import pluralize from 'pluralize';

function WizardSummary({
    summaryOutput,
    assessment,
    ongoing,
    onChangeDuration,
    overrideDurationInMinutes,
}) {
    return (
        <FlexDiv column gap={24} mt={40}>
            {summaryOutput.map((x, i) => (
                <SpaceBetweenDiv key={i} alignStart gap={16}>
                    <FlexDiv flexGrow={1}>
                        <TextDefault bold>{x.name}</TextDefault>
                    </FlexDiv>
                    <FlexDiv flexGrow={5}>
                        <TextDefault>{x.detail}</TextDefault>
                    </FlexDiv>
                </SpaceBetweenDiv>
            ))}
            {/* Duration */}
            {assessment && (
                <SpaceBetweenDiv alignStart gap={16}>
                    <FlexDiv flexGrow={1}>
                        <TextDefault bold>
                            <TextWithTooltipIcon
                                tooltipText={
                                    'This is the time that users have to finish the assessment. We recommend two minutes per question, which is more than enough. If you have many scenarios you might want to change the duration.'
                                }
                                textComponent={'Duration'}
                            />
                        </TextDefault>
                    </FlexDiv>
                    <FlexDiv flexGrow={5} gap={8}>
                        <TextDefault>
                            {overrideDurationInMinutes ||
                                assessment.selectedQuestionsDuration}{' '}
                            {pluralize(
                                'minute',
                                overrideDurationInMinutes ||
                                    assessment.selectedQuestionsDuration
                            )}
                        </TextDefault>
                        {!ongoing && (
                            <>
                                <TextDefault>|</TextDefault>
                                <TextDefault
                                    onClick={onChangeDuration}
                                    themed
                                    link
                                    bold
                                >
                                    Change time
                                </TextDefault>
                            </>
                        )}
                    </FlexDiv>
                </SpaceBetweenDiv>
            )}
        </FlexDiv>
    );
}

export default observer(WizardSummary);
