import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';
import { Link } from 'react-router-dom';
import { palette } from '@awarego/awarego-components';
import defaultValidatorFn from '@emotion/is-prop-valid';

export const ThemedLink = styled.span`
    color: ${(props) => props.color || props.theme.primary};
    cursor: pointer;

    ${(props) =>
        props.bold &&
        css`
            font-weight: 700;
        `}
`;

export const ThemedLinkLink = styled(Link)`
    &&:hover {
        color: ${(props) => props.theme.primary};
    }
`;

export const ThemedStep = styled.span`
    white-space: nowrap;
    font-size: 14px;
    color: ${rgba(palette.graphite.heavyMetal, 0.2)};
    font-weight: 800;
    text-transform: uppercase;
    border-left: 4px solid transparent;
    width: fit-content;
    margin-bottom: 8px;
    padding: 8px 20px 8px 48px;
    line-height: 19px;

    ${(props) =>
        props.active &&
        css`
            cursor: pointer;
            color: ${(props) => props.color || props.theme.primary};
            border-left: 4px solid
                ${(props) => props.color || props.theme.primary};
        `};

    ${(props) =>
        props.enabled &&
        !props.active &&
        css`
            cursor: pointer;
            color: ${palette.graphite.heavyMetal};
            border-left: 4px solid transparent;

            &:hover {
                color: ${(props) => props.color || props.theme.primary};
            }
        `};
`;

export const ThemedInputLabel = styled.span`
    ${(props) =>
        props.checked &&
        css`
            &&& {
                color: ${props.theme.primary};
                font-weight: bold;
            }
        `}
`;

export const ThemedRadiobutton = styled.span`
    position: absolute;
    top: 1px;
    left: 0px;
    height: 22px;
    width: 22px;
    border: 2px solid ${(props) => props.color || props.theme.primary};
    border-radius: 50%;

    &:hover {
        background-color: ${(props) =>
            rgba(props.color || props.theme.primary, 0.05)};
    }

    &:after {
        left: 2px;
        top: 2px;
        width: 14px;
        height: 14px;
        content: '';
        position: absolute;
        border-radius: 50%;

        ${(props) =>
            props.checked &&
            css`
                display: block;
                background-color: ${(props) =>
                    props.color || props.theme.primary};
            `}
    }
`;

export const ThemedCheckmarkInputContainer = styled.div`

  &&& {
    input[type=checkbox] {
      ~ .checkmark {
        border: 1px solid ${(props) => rgba(props.theme.primary, 0.5)};
      }
    }

    input[type=checkbox]:checked {
      ~ span {
        color: ${(props) => props.theme.primary};
      }

      ~ .checkmark {
        background: ${(props) => props.theme.primary};

        &:after {
          display: block;
          border: solid white;
          border-width: 0 3px 3px 0 !important;
        }
      }
    }
`;

export const ThemedNav = styled.ul.withConfig({
    shouldForwardProp: (prop) => defaultValidatorFn(prop),
})`
    display: flex;
    border-bottom: 1px solid #f5f3f3;
    align-items: center;
    padding-left: 40px;
    font-size: 16px;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;

    ${(props) =>
        props.dashboard &&
        css`
            margin-left: -40px;
            margin-right: -40px;
            border-top: 1px solid #f5f3f3;
            background: #faf9f9;
        `}
    ${(props) =>
        props.training &&
        css`
            margin-left: -30px;
            margin-right: -30px;
        `}
  ${(props) =>
        props.clean &&
        css`
            border-bottom: 0;
            padding: 0;
        `}
  ${(props) =>
        props.table &&
        css`
            margin-left: 0;
            padding-left: 0;
        `}

  ${(props) =>
        props.$mobileMenu &&
        css`
            flex-direction: column;
            width: 200px;
        `}
`;

export const ThemedNavItem = styled.li.withConfig({
    shouldForwardProp: (prop) => defaultValidatorFn(prop),
})`
    -webkit-transition: ease 0.2s all;
    transition: ease 0.2s all;
    border-bottom: 4px solid transparent;
    margin-right: 16px;
    cursor: pointer;
    list-style: none;

    &:hover,
    &:focus {
        color: ${(props) => lighten(0.1, props.theme.secondary)};
        border-bottom: 4px solid
            ${(props) => lighten(0.4, props.theme.secondary)};
    }

    ${(props) =>
        props.active &&
        css`
            & {
                color: ${props.theme.secondary};
                border-bottom: 4px solid ${props.theme.secondary};

                a {
                    color: ${props.theme.secondary};
                }

                &:hover {
                    color: ${props.theme.secondary};
                    border-bottom: 4px solid ${(props) => props.theme.secondary};
                }
            }
        `}

    ${(props) =>
        props.$mobileMenu &&
        css`
            width: 100%;
            margin: 0;
            border-bottom: 1px solid lightgray;
        `}
  ${(props) =>
        props.strongerMargin &&
        css`
            margin-right: 24px;
        `}
`;

export const ThemedNavLink = styled.a.withConfig({
    shouldForwardProp: (prop) => defaultValidatorFn(prop),
})`
    display: block;
    padding: 0.5rem 1rem;
    font-size: 16px;
    color: ${palette.graphite.heavyMetal};
    font-weight: 700;

    ${(props) =>
        props.top &&
        css`
            font-weight: 700;
            padding: 22px 0px;
            font-size: 14px;
        `}

    ${(props) =>
        props.table &&
        css`
            font-size: 20px;
            font-weight: 800;
        `}

  ${(props) =>
        props.$mobileMenu &&
        css`
            margin-left: 16px;
            padding: 16px;
        `}
  ${(props) =>
        props.$mildPadding &&
        css`
            padding: 16px 0;
        `}
`;

export const ThemedDropdownItem = styled.button`
    background: none;
    display: block;
    width: 100%;
    padding: 8px 0;
    clear: both;
    font-weight: normal;
    color: black;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px;

    &:hover {
        a {
            color: ${(props) => props.theme.primary};
        }

        color: ${(props) => props.theme.primary};
    }

    selected-item {
        color: ${(props) => props.theme.primary};
    }

    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.theme.primary};
        `}
`;
