import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Heading2, Heading3 } from '@awarego/awarego-components';
import { SUBJECT_TYPE } from '../../../utils/constants';

function SubjectQuiz({ subject }) {
    return (
        <Fragment>
            {subject.type === SUBJECT_TYPE.VIDEO && (
                <>
                    <Heading2 my={16} displayBlock>
                        Quiz
                    </Heading2>
                    <div>
                        {(subject.questions || []).map((x, i) => {
                            return (
                                <div key={i}>
                                    <Heading3 displayBlock mt={16} mb={8}>
                                        Question {i + 1}/
                                        {subject.questions.length}
                                    </Heading3>
                                    <p>{x.question}</p>
                                    <ul>
                                        <li>{x.correct_answer} (correct)</li>
                                        <li>{x.wrong_answer1} </li>
                                        <li>{x.wrong_answer2} </li>
                                        <li>{x.wrong_answer3} </li>
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default observer(SubjectQuiz);
