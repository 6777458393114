import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '@awarego/awarego-components';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';

function ReportFormFooter({
    reportsForm,
    submitText = 'Save',
    creatingReport,
    onCancel,
    onSubmit,
}) {
    return (
        <Fragment>
            {creatingReport ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <ThemedButton
                        text={'Cancel'}
                        mode={'secondary'}
                        onClick={onCancel}
                    />
                    <ThemedButton
                        text={submitText}
                        mode={'primary'}
                        disabled={!reportsForm.isValid}
                        onClick={onSubmit}
                    />
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(ReportFormFooter);
