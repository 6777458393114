import React from 'react';
import { observer } from 'mobx-react';
import { SpaceBetweenDiv } from '@awarego/awarego-components';
import AddRemoveButton from '../../AddRemoveButton';
import VideoDescription from './video-description';

function SubjectRow({
    subject,
    previewVideo,
    onVideoSelected,
    selection,
    isTrial,
}) {
    return (
        <SpaceBetweenDiv gap={24} py={16} px={24} $noWrap>
            <VideoDescription
                previewVideo={previewVideo}
                item={subject}
                isTrial={isTrial}
            />
            <AddRemoveButton
                selection={selection}
                itemId={subject.id}
                onClick={() => {
                    onVideoSelected(subject.id);
                }}
                disabled={
                    subject.disabled || (isTrial && !subject.availableForBeta)
                }
            />
        </SpaceBetweenDiv>
    );
}

export default observer(SubjectRow);
