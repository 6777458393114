import RestService from './RestService';

export default class ThreatAreas extends RestService {
    constructor() {
        super('/threatAreas');
    }

    all() {
        return this.get(`${this.baseUrl}/`, null, null);
    }
}
