import React from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
} from '@mui/material';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import pluralize from 'pluralize';
import './questions-order.scss';
import ThemedEyeSVG from '../../themed/ThemedEyeSVG';
import NumberChip from '../../NumberChip';
import LockOnCircle from '../../LockOnCircle';
import { Heading3, LightTooltip } from '@awarego/awarego-components';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    borderRadius: 10,
    width: '100%',
    padding: 0,

    // change background colour if dragging
    background: isDragging ? '#fff' : '#fff',
    boxShadow: isDragging
        ? '0px 4px 14px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.18)'
        : '',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#FFF' : '#FFF',
    padding: grid,
    width: '100%',
});

function QuestionsOrder({
    questions,
    ongoing,
    showPreviewQuestionsModal,
    onReorderList,
    onRemoveQuestion,
}) {
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        onReorderList(result.source.index, result.destination.index);
    };

    const count = questions.length || 0;

    return (
        <div className="dragZone questions questions-order">
            <Table>
                <TableHead>
                    {count > 0 && (
                        <TableRow>
                            <TableCell />
                            <TableCell>Question</TableCell>
                            <TableCell>Threat area</TableCell>
                            <TableCell># Key behaviours</TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    )}
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <TableBody
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {questions.map((item, index) => {
                                    return (
                                        <Draggable
                                            component
                                            className="draggable"
                                            key={item.id + '-' + index}
                                            draggableId={item.id + '-' + index}
                                            index={index}
                                            isDragDisabled={ongoing}
                                        >
                                            {(provided, snapshot) => (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="dragItem table"
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps
                                                            .style
                                                    )}
                                                >
                                                    <TableCell className="dragCell">
                                                        <div
                                                            className="dragLeft assessment-drag"
                                                            style={{
                                                                display: ongoing
                                                                    ? 'none'
                                                                    : 'inherit',
                                                            }}
                                                        >
                                                            <img
                                                                alt="dragholder"
                                                                src="/static/img/drag.svg"
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.disabled ? (
                                                            <LockOnCircle
                                                                listItem
                                                                small
                                                            />
                                                        ) : (
                                                            <NumberChip
                                                                size="small"
                                                                label={
                                                                    index + 1
                                                                }
                                                            />
                                                        )}

                                                        {item.name.length >
                                                        40 ? (
                                                            <Tooltip
                                                                title={
                                                                    item.name
                                                                }
                                                            >
                                                                <span className="subject-name">
                                                                    {item.name.substring(
                                                                        0,
                                                                        40
                                                                    )}
                                                                    ...
                                                                </span>
                                                            </Tooltip>
                                                        ) : (
                                                            <span className="subject-name">
                                                                {item.name}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <p>{item.area}</p>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LightTooltip
                                                            title={item.behaviours.map(
                                                                (b, j) => (
                                                                    <div
                                                                        key={j}
                                                                    >
                                                                        {b.name}
                                                                    </div>
                                                                )
                                                            )}
                                                        >
                                                            <p className="underline">
                                                                {
                                                                    item
                                                                        .behaviours
                                                                        .length
                                                                }{' '}
                                                                {pluralize(
                                                                    'Behaviours',
                                                                    item
                                                                        .behaviours
                                                                        .length
                                                                )}
                                                            </p>
                                                        </LightTooltip>
                                                    </TableCell>
                                                    <TableCell
                                                        className="action"
                                                        onClick={() => {
                                                            showPreviewQuestionsModal(
                                                                item.id,
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <ThemedEyeSVG />
                                                    </TableCell>
                                                    {!ongoing && (
                                                        <TableCell className="action">
                                                            <img
                                                                alt="remove question"
                                                                src="/static/img/icon-close.png"
                                                                onClick={() => {
                                                                    onRemoveQuestion(
                                                                        item.id
                                                                    );
                                                                }}
                                                            />
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                                {count === 0 && (
                                    <div className="training-wizard-content center">
                                        <img
                                            src="/static/img/icon-user-list.svg"
                                            alt="No scenarios selected yet"
                                        />
                                        <div className="stats">
                                            <Heading3
                                                displayBlock
                                                mt={16}
                                                mb={8}
                                            >
                                                No scenarios selected yet
                                            </Heading3>
                                            <p>
                                                Select scenarios you want to
                                                send to your employees.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
            </Table>
        </div>
    );
}

export default observer(QuestionsOrder);
