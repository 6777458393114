import { makeAutoObservable } from 'mobx';
import services from '../services';
import { generateLoadList } from '../utils/mobx';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';

class ResourcesStore {
    content = [];
    loadingContent = false;

    error = null;

    typeFilter = [];
    threatAreaFilter = [];
    languageFilter = [];

    constructor(commonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    setError(error) {
        this.error = error;
    }

    loadAllContent = generateLoadList(
        'loadContent',
        this,
        'loadingContent',
        (companyId) => {
            return services.Resources.all(companyId);
        },
        'content'
    );

    filterContent(type) {
        return orderBy(this.content, ['order'], ['asc']).filter((x) => {
            //Type filtering
            if (x.type !== type) return false;
            if (this.typeFilter.length > 0 && this.typeFilter.indexOf(type) < 0)
                return false;

            //Language filtering
            if (
                this.languageFilter.length > 0 &&
                intersection(x.languages, this.languageFilter).length === 0
            ) {
                return false;
            }

            //Threat area filtering
            if (
                this.threatAreaFilter.length > 0 &&
                (!x.tags || x.tags.length <= 0)
            ) {
                return false;
            }
            if (
                this.threatAreaFilter.length > 0 &&
                intersection(x.tags, this.threatAreaFilter).length === 0
            ) {
                return false;
            }

            return true;
        });
    }

    get availableThreatAreas() {
        const unOrderedTags = uniq(
            flatten(this.content.map((x) => x.tags))
        ).map((x) => ({
            id: x,
            name: x,
        }));
        return orderBy(unOrderedTags, ['name'], ['asc']);
    }

    get availableLanguages() {
        if (!this.commonStore.languagesLoaded) return [];
        const availableLanguages = uniq(
            flatten(this.content.map((x) => x.languages))
        ).map((x) => ({
            id: x,
            name: this.commonStore.languagesIndex[x]
                ? this.commonStore.languagesIndex[x].english_name
                : x,
            img:
                this.commonStore.languagesIndex[x] &&
                this.commonStore.languagesIndex[x].flag_image,
        }));
        return orderBy(availableLanguages, ['name'], ['asc']);
    }

    get trainingContent() {
        return this.filterContent('training');
    }

    get assessmentContent() {
        return this.filterContent('assessment');
    }

    get materialContent() {
        return this.filterContent('material');
    }

    setTypeFilter(typeFilter) {
        this.typeFilter = typeFilter;
    }

    setThreatAreaFilter(threatAreaFilter) {
        this.threatAreaFilter = threatAreaFilter;
    }

    setLanguageFilter(languageFilter) {
        this.languageFilter = languageFilter;
    }

    /** Deprecated functionality for the old resources page (should be removed in the future) : */
    posters = [];
    stickers = [];
    loadingPosters = false;
    loadingStickers = false;

    loadPosters = generateLoadList(
        'loadPosters',
        this,
        'loadingPosters',
        () => {
            return services.Resources.posters();
        },
        'posters',
        'posters',
        { throwError: true }
    );

    loadStickers = generateLoadList(
        'loadStickers',
        this,
        'loadingStickers',
        () => {
            return services.Resources.stickers();
        },
        'stickers',
        'stickers',
        { throwError: true }
    );
}

export default ResourcesStore;
