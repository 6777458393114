import React from 'react';
import { observer } from 'mobx-react';
import { TextTiny } from '@awarego/awarego-components';
import moment from 'moment';

/** Formatting of date in a table cell */
function DateCell({ date }) {
    return (
        <div>
            {date ? (
                <>
                    <div>
                        <TextTiny bold lighter>
                            {moment(date).format('ll')}
                        </TextTiny>
                    </div>
                    <TextTiny lighter>{moment(date).format('LT')}</TextTiny>
                </>
            ) : (
                '-'
            )}
        </div>
    );
}

export default observer(DateCell);
