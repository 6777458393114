import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Heading3, TextInput } from '@awarego/awarego-components';

function ReportRecipientsFormFragment({
    reportsForm,
    editingReport,
    inputHasError,
}) {
    useEffect(() => {
        if (editingReport) {
            reportsForm.$('recipientsEmails').set(editingReport.recipients);
            reportsForm
                .$('customEmailSubject')
                .set(editingReport.customEmailSubject);
            reportsForm.validate();
        }
    }, []);

    return (
        <>
            <Heading3 mb={24} displayBlock>
                Send to email (optional)
            </Heading3>
            <TextInput
                error={inputHasError('recipientsEmails')}
                margin={'dense'}
                stretched
                variant={'outlined'}
                type={'email'}
                helperText={
                    inputHasError('recipientsEmails')
                        ? 'Please enter valid email address(es)'
                        : undefined
                }
                {...reportsForm.$('recipientsEmails').bind()}
            />
            <TextInput
                margin={'dense'}
                stretched
                variant={'outlined'}
                {...reportsForm.$('customEmailSubject').bind()}
            />
        </>
    );
}

export default observer(ReportRecipientsFormFragment);
