import RestService from './RestService';
import Managers from './ManagersService';
import Settings from './SettingsService';
import Companies from './CompaniesService';
import Subscriptions from './SubscriptionsService';

export default class Partners extends RestService {
    constructor(asSuper = false) {
        super('/partners');
    }

    companiesService(partnerId) {
        return new Companies(false, this, partnerId);
    }

    subscriptionsService(partnerId) {
        return new Subscriptions(this, partnerId);
    }

    managersService(partnerId) {
        return new Managers(this, partnerId);
    }

    settingsService(partnerId) {
        return new Settings(this, partnerId);
    }

    openManageSession(partnerId) {
        return this.post(`${this.baseUrl}/${partnerId}/create_portal_session`);
    }

    togglePartner(partnerId, enabled) {
        return this.post(`${this.baseUrl}/${partnerId}/toggle`, { enabled });
    }
}
