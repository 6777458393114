import styled, { css } from 'styled-components';
import React from 'react';
import { rgba } from 'polished';
import { palette } from '@awarego/awarego-components';

const ButtonDiv = styled.div`
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${rgba(palette.evergreen.aspargus, 0.1)};
    border-radius: 4px;
    height: 48px;
    transition: 0.2s ease all;
    font-size: 150%;
    transition: ease 0.2s background-color;
    color: ${palette.evergreen.aspargus};
    &:hover {
        background-color: ${rgba(palette.evergreen.aspargus, 0.3)};
        cursor: pointer;
    }

    ${(props) =>
        props.selected &&
        css`
            background-color: ${rgba(palette.graphite.heavyMetal, 0.05)};
            color: ${palette.graphite.heavyMetal};

            &:hover {
                background: ${rgba(palette.vibrant.red, 0.3)};
                cursor: pointer;
                color: ${palette.vibrant.red};
            }
        `};
`;

const AddRemoveButton = ({ selection = [], itemId, onClick }) => {
    return (
        <ButtonDiv selected={selection.includes(itemId)} onClick={onClick}>
            {selection.includes(itemId) ? '-' : '+'}
        </ButtonDiv>
    );
};

export default AddRemoveButton;
