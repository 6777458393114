import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { withTheme } from 'styled-components';

class ThemedLessonsCompleted extends React.Component {
    render() {
        return (
            <SvgLoader
                width="16"
                height="16"
                path="/static/img/arrow-forward.svg"
            >
                <SvgProxy
                    selector="path"
                    fill={this.props.color || this.props.theme.primary}
                />
            </SvgLoader>
        );
    }
}

export default withTheme(ThemedLessonsCompleted);
