import React, {useEffect, useMemo} from 'react';
import { FlexDiv, TextDefault } from '@awarego/awarego-components';
import { observer } from 'mobx-react';

import CalendarWithDots from '../../components/manager/wizard/delivery-calendar/calendar-with-dots';
import ScheduleHeading from '../../components/manager/wizard/delivery-calendar/schedule-heading';
import ScheduledEvents from '../../components/manager/wizard/delivery-calendar/scheduled-events';
import { AUTOMATION_SCHEDULE_TYPE } from '../../utils/constants';

function DeliveryCalendar({
    displayedEvents,
    daysWithDot,
    setSelectedMonth,
    selectedDate,
    setSelectedDate,
    totalEvents,
    updateEventDate,
    readOnly,
    scheduleType,
    date,
    adminTimeZone,
}) {

    const scheduleContent = useMemo(() => {
        if (scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE && date === null)
            return (
                <TextDefault lighter center mx={16} my={40}>
                    Nothing scheduled yet. Please select start date & time.
                </TextDefault>
            );
        if (daysWithDot.length > 0)
            return (
                <ScheduledEvents
                    displayedEvents={displayedEvents}
                    readOnly={
                        readOnly ||
                        scheduleType === AUTOMATION_SCHEDULE_TYPE.NOW
                    }
                    selectedDate={selectedDate}
                    totalEvents={totalEvents}
                    updateEventDate={updateEventDate}
                    adminTimeZone={adminTimeZone}
                    startDate={date}
                />
            );
        return (
            <TextDefault lighter center mx={16} mb={32}>
                You have nothing scheduled this month
            </TextDefault>
        );
    }, [
        scheduleType,
        date,
        daysWithDot.length,
        displayedEvents,
        readOnly,
        selectedDate,
        totalEvents,
        updateEventDate,
        adminTimeZone,
    ]);



    return (
        <FlexDiv column border maxWidth={328} alignCenter>
            <CalendarWithDots
                //selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedMonth={setSelectedMonth}
                daysWithDot={daysWithDot}
            />
            {!readOnly && daysWithDot.length > 0 && date !== null && (
                <ScheduleHeading />
            )}
            {scheduleContent}
        </FlexDiv>
    );
}

export default observer(DeliveryCalendar);
