import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReportsList from './ReportsList';
import ReportDetailsContainer from './single-report/ReportDetailsContainer';

export default () => {
    return (
        <Routes>
            <Route path="/" element={<ReportsList />} />
            <Route path=":reportId" element={<ReportDetailsContainer />} />
        </Routes>
    );
};
