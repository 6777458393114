import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Content from './Content';
import ScormContentView from './scorm-content-view/ScormContentView';

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Content />} />
            <Route path=":scormId" element={<ScormContentView />} />
        </Routes>
    );
};
