import React from 'react';
import { observer} from 'mobx-react';
import {Table} from 'reactstrap';
import {formatDate} from '../../utils/helpers';
import {TextDefault, TextLead} from '@awarego/awarego-components';

function MailLog({records}) {

    return (
        <>
            <Table className="mail-log">
                <tbody>
                {records.map((record) => {
                    return (
                        <tr key={record.id}>
                            <tr>
                                <br></br>
                                <TextLead>
                                    Mail Logs -{' '}
                                    <span className="green">
                                                {formatDate(record.created)}
                                            </span>
                                </TextLead>
                            </tr>
                            <tr>
                                <td>
                                    <TextDefault>Action</TextDefault>
                                    <pre>
                                                {JSON.stringify(
                                                    record.actions,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextDefault>Message</TextDefault>
                                    <pre>
                                                {JSON.stringify(
                                                    record.message,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextDefault>Status</TextDefault>
                                    <pre>
                                                {JSON.stringify(
                                                    record.delivery_status,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                </td>
                            </tr>
                        </tr>
                    );
                })}
                </tbody>
            </Table>

        </>
    );

}

export default observer(MailLog);
