import React, { useEffect, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { useInfiniteQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import services from '../../services';
import {
    Checkbox,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import Box from '@mui/material/Box';
import { SearchInput, FlexDiv } from '@awarego/awarego-components';

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

function GroupsTable({ companyId, included, onSelectGroup, product }) {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const fetchProjects = async ({ pageParam }) => {
        const fetchedData = await services.Companies.integrationsService(
            companyId
        ).integrationGroups(product, {
            top: 10,
            skip: pageParam,
            filter: debouncedSearchTerm ? debouncedSearchTerm : null,
        });

        return fetchedData;
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        status,
    } = useInfiniteQuery({
        queryKey: ['groups', debouncedSearchTerm],
        queryFn: fetchProjects,
        getNextPageParam: (lastPage) => lastPage.skip,
    });

    const allRows = data ? data.pages.flatMap((d) => d.rows) : [];

    const parentRef = React.useRef();

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? allRows.length + 1 : allRows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 50,
        overscan: 5,
    });

    React.useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem) {
            return;
        }

        if (
            lastItem.index >= allRows.length - 1 &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage();
        }
    }, [
        hasNextPage,
        fetchNextPage,
        allRows.length,
        isFetchingNextPage,
        rowVirtualizer.getVirtualItems(),
        debouncedSearchTerm,
    ]);

    const virtualRows = rowVirtualizer.getVirtualItems();
    const totalSize = rowVirtualizer.getTotalSize();

    const paddingTop =
        virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0;

    return (
        <div>
            <>
                {' '}
                <Box mb={1} flex={1}>
                    <SearchInput
                        fullWidth
                        searchValue={searchTerm || ''}
                        placeholder="Search"
                        onChange={(e) =>
                            setSearchTerm(e.target.value || undefined)
                        }
                        onClear={() => setSearchTerm('')}
                    />
                </Box>
                <div
                    ref={parentRef}
                    className="List"
                    style={{
                        height: `500px`,
                        width: `100%`,
                        overflow: 'auto',
                    }}
                >
                    <List>
                        {paddingTop > 0 && (
                            <Box style={{ height: `${paddingTop}px` }} />
                        )}
                        <ListItem>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText
                                primary={'Name'}
                                style={{ flex: '2' }}
                            ></ListItemText>
                            <ListItemText
                                style={{ flex: '1' }}
                                primary={'# Employees'}
                            ></ListItemText>
                        </ListItem>
                        {virtualRows.map((virtualRow) => {
                            const row = allRows[virtualRow.index];
                            if (!row) return null;
                            return (
                                <ListItem key={virtualRow.index}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={included[row.id]}
                                            tabIndex={-1}
                                            onChange={(e) => onSelectGroup(row)}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        style={{ flex: '2' }}
                                        primary={row.displayName}
                                    ></ListItemText>
                                    <ListItemText
                                        style={{ flex: '1' }}
                                        primary={row.usersCount}
                                    ></ListItemText>
                                </ListItem>
                            );
                        })}
                        {(isLoading || isFetching || isFetchingNextPage) && (
                            <FlexDiv justifyCenter alignCenter>
                                <CircularProgress />
                            </FlexDiv>
                        )}
                        {paddingBottom > 0 && (
                            <Box style={{ height: `${paddingBottom}px` }} />
                        )}
                    </List>
                </div>
            </>
        </div>
    );
}

export default observer(GroupsTable);
