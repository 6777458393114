import React, { Fragment, useState, useEffect } from 'react';
import RichTextEditor from 'react-rte';
import { observer } from 'mobx-react';

const component = observer(
    ({ mrkdownField, field, showLabel, error, initialValue, ...rest }) => {
        const [value, setValue] = useState(
            RichTextEditor.createValueFromString(field.value || '', 'html') ||
                RichTextEditor.createEmptyValue()
        );
        useEffect(() => {
            setValue(
                RichTextEditor.createValueFromString(field.value || '', 'html')
            );
        }, [field]);

        useEffect(() => {
            if (initialValue && field && field.key) {
                setValue(
                    RichTextEditor.createValueFromString(
                        initialValue[field.key],
                        'html'
                    )
                );
            }
        }, [initialValue]);

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: [
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                'LINK_BUTTONS',
                'IMAGE_BUTTON',
                'BLOCK_TYPE_DROPDOWN',
                'HISTORY_BUTTONS',
            ],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Strikethrough', style: 'STRIKETHROUGH' },
                { label: 'Monospace', style: 'CODE' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' },
                { label: 'Code Block', style: 'code-block' },
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' },
                { label: 'Blockquote', style: 'blockquote' },
            ],
        };

        const onChange = (value) => {
            setValue(value);
            if (field.onChange) {
                // Send the changes up to the parent component as an HTML string.
                // This is here to demonstrate using `.toString()` but in a real app it
                // would be better to avoid generating a string on each change.
                let html = value.toString('html');
                let markdown = value.toString('markdown');
                field.onChange(html);
                mrkdownField.onChange(markdown);
            }
        };

        return (
            <Fragment>
                {showLabel ? (
                    <label htmlFor={field.id}>
                        {field.label}
                        {field.rules.indexOf('required') > -1 && (
                            <span className="red">*</span>
                        )}
                    </label>
                ) : (
                    ''
                )}
                <RichTextEditor
                    value={value}
                    id={field.id}
                    onChange={onChange}
                    toolbarConfig={toolbarConfig}
                    // if there were any more properties specified when calling component htmlField and wanna use them here, we don't have to specify them here one by one
                    {...rest}
                />
                <input type="hidden" id={mrkdownField.id} />
                <div className="error">{field.error || error}</div>
            </Fragment>
        );
    }
);
export default component;
