import React from 'react';
import {
    ThemedButton,
    palette,
    TextDefault,
    TextLead,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import SubHeaderComponentsList from '../../../../components/header/SubHeaderComponentsList';
import SubHeaderText from '../../../../components/header/SubHeaderText';
import pluralize from 'pluralize';
import Banner from '../../../../components/Banner';
import {
    getSubjectsCount,
    calculateTrainingDuration,
} from './recommendation-helpers';
function RecommendationBanner({ videos, onViewTraining }) {
    const createHeading = () => (
        <TextLead bold>Recommended training plan</TextLead>
    );

    const subjectsCount = getSubjectsCount(videos);
    const weeksCount = calculateTrainingDuration(subjectsCount, 2);

    const createSubheading = () => {
        return (
            <SubHeaderComponentsList>
                <SubHeaderText
                    text={
                        <TextDefault color={palette.graphite.heavyMetal}>
                            To improve resilience we recommend tailor-made
                            training of{' '}
                            <TextDefault bold color={palette.graphite.onyx}>
                                {subjectsCount} training{' '}
                                {pluralize('subject', subjectsCount)}
                            </TextDefault>{' '}
                            delivered through{' '}
                            <TextDefault bold color={palette.graphite.onyx}>
                                {weeksCount + ' '}
                                {pluralize('week', weeksCount)}
                            </TextDefault>
                        </TextDefault>
                    }
                />
            </SubHeaderComponentsList>
        );
    };

    const customIconStyles = {
        backgroundColor: palette.graphite.gray,
        borderRadius: '32px',
        padding: '8px',
    };

    return (
        <Banner
            backgroundColor={palette.graphite.white}
            heading={createHeading()}
            descriptionSection={createSubheading()}
            actionSection={
                <ThemedButton
                    $noTextTransform
                    mode="secondary"
                    onClick={() => onViewTraining()}
                    text={'View training'}
                />
            }
            iconPath={'/static/img/icon-create-magic.svg'}
            iconAlt={'Recommended training plan'}
            iconStyles={customIconStyles}
        ></Banner>
    );
}

export default observer(RecommendationBanner);
