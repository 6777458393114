import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Heading1, ThemedButton } from '@awarego/awarego-components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useStores } from '../hooks/use-stores';
import ErrorMessage from '../components/errormessage';

function ModalRename({ onRequestClose, item, itemStore, companyId }) {
    const { authStore } = useStores();
    const { timeZone } = authStore;
    const [dueDate, setDueDate] = React.useState(null);
    const [dueError, setDueError] = React.useState(null);
    const [dateChanged, setDateChanged] = React.useState(false);
    const { dueError: error } = itemStore;

    const scheduledDate = item.date;
    const scheduleType = item.scheduleType;

    const errorMessageDueDate = React.useMemo(() => {
        switch (dueError) {
            case 'maxDate':
            case 'minDate': {
                return `Due date must be set after the delivery time (${moment(
                    scheduledDate
                ).format(`L HH:mm [${timeZone}]`)})`;
            }

            case 'disablePast': {
                return 'Due date cannot be set in the past';
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [dueError]);

    const onChangeDueDate = (value) => {
        setDueDate(value);
        if (value !== item.due) {
            setDateChanged(true);
        }
    };

    useEffect(() => {
        if (item) {
            setDueDate(item.due);
            itemStore.setError(null, 'updateDue');
        }
    }, [item]);

    const onSubmit = async () => {
        if (dateChanged) {
            const result = await itemStore.updateDue(
                companyId,
                item.id,
                dueDate
            );
            if (result) {
                item.setDueDate(dueDate);
                onRequestClose();
            }
        } else {
            onRequestClose();
        }
    };

    if (item) {
        return (
            <div>
                <div className={'modalHeader'}>
                    <Heading1>Update Due Date</Heading1>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent form'}>
                    <div className={'training-schedule-options'}>
                        <ErrorMessage error={error} />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                hiddenLabel={true}
                                value={dueDate ? moment(dueDate) : null}
                                onChange={onChangeDueDate}
                                ampm={false}
                                dayOfWeekFormatter={(day) => `${day}`}
                                disablePast={true}
                                format={`DD/MM/YYYY HH:mm[${timeZone}]`}
                                views={['day', 'hours', 'minutes']}
                                onError={(newError) => setDueError(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessageDueDate,
                                        placeholder: 'Select date & time',
                                    },
                                }}
                                minDate={
                                    scheduledDate !== null && scheduleType === 0
                                        ? moment(scheduledDate)
                                        : undefined
                                }
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="modalFooter justifyEnd">
                    <ThemedButton
                        mode="primary"
                        onClick={onSubmit}
                        text="Update"
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default observer(ModalRename);
