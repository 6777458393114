import React from 'react';
import { FlexDiv } from '@awarego/awarego-components';
import SubHeaderText from './SubHeaderText';

/** Sub list that consists only of strings, seperated by pipes ('|') */
function SubHeaderDetailsList({ details }) {
    return (
        <FlexDiv alignCenter gap={8} mr={32}>
            {details
                ? details.map((detail, index) => (
                      <SubHeaderText
                          text={detail}
                          key={index}
                          separator={index !== 0}
                      />
                  ))
                : null}
        </FlexDiv>
    );
}

export default SubHeaderDetailsList;
