import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Heading1, ThemedButton, TextInput } from '@awarego/awarego-components';
import RenameForm from '../forms/rename';

function ModalRename({ onRequestClose, item, itemStore, companyId }) {
    const localStore = useLocalObservable(() => {
        let renameForm = new RenameForm(
            {},
            {
                hooks: {
                    onSuccess() {
                        doRename();
                    },
                },
            }
        );
        renameForm.update(item ? { name: item.name } : {});
        return { renameForm };
    });

    const doRename = async () => {
        try {
            await itemStore.rename(companyId, `${item && item.isScorm ? 'p' : ''}${item && item.id}` , {
                ...localStore.renameForm.values(),
            });
            // reset form
            localStore.renameForm.update({ name: '' });
        } catch (e) {
            console.log(e);
        } finally {
            onRequestClose();
        }
    };

    if (item) {
        return (
            <div>
                <div className={'modalHeader'}>
                    <Heading1>Rename</Heading1>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent form'}>
                    <div className={'training-schedule-options'}>
                        <TextInput
                            stretched
                            {...localStore.renameForm.$('name').bind()}
                        />
                    </div>
                </div>
                <div className="modalFooter justifyEnd">
                    <ThemedButton
                        mode="primary"
                        disabled={
                            !localStore.renameForm.values().name.length > 0
                        }
                        onClick={localStore.renameForm.onSubmit}
                        text="Update"
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default observer(ModalRename);
