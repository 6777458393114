import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';

function EmailSignInError() {
    const { authStore } = useStores();
    const { isAuthenticated, currentUser } = authStore;

    let navigate = useNavigate();

    const handleSignout = () => {
        authStore.logout();
        navigate('/');
    };
    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            {isAuthenticated ? (
                <Fragment>
                    <Heading1 mb={16}>
                        You are signed in as {currentUser.email}
                    </Heading1>
                    <Link to="/">
                        <ThemedButton mode="primary" text="Go to homepage" />
                    </Link>
                    <TextDefault
                        onClick={() => handleSignout()}
                        link
                        lighter
                        underline
                    >
                        Sign in as another user
                    </TextDefault>
                </Fragment>
            ) : (
                <Fragment>
                    <Heading1 mb={16}>Unable to sign in</Heading1>
                    <TextDefault mb={16}>
                        The link you tried to sign in with is not valid.
                    </TextDefault>
                    <Link to="/auth/signin">
                        <ThemedButton
                            mode="secondary"
                            text="Request a new link"
                        />
                    </Link>
                </Fragment>
            )}
        </FlexDiv>
    );
}

export default observer(EmailSignInError);
