import intersection from 'lodash/intersection';
import { makeAutoObservable } from 'mobx';

class ClientSidePaginationWithSelect {
    data = null;
    searchValue = '';
    parent = null;

    constructor(data, parent, idAccessor, searchProp, selection) {
        makeAutoObservable(this);
        this.data = data;
        this.parent = parent;
        if (idAccessor) this.idAccessor = idAccessor;
        this.searchProp = searchProp;
        this.selection = selection;
    }

    setSearchValue(value) {
        this.searchValue = value;
    }

    get filteredData() {
        return this.data.filter(
            (x) =>
                !(
                    this.searchValue.length > 0 &&
                    x[this.searchProp] !== null &&
                    !x[this.searchProp]
                        .toLowerCase()
                        .includes(this.searchValue.toLowerCase())
                )
        );
    }

    get pagedData() {
        return this.filteredData.slice(
            this.parent.pageIndex * this.parent.pageSize,
            this.parent.pageIndex * this.parent.pageSize + this.parent.pageSize
        );
    }

    get pageCount() {
        return Math.ceil(this.dataTotal / this.parent.pageSize);
    }

    get dataTotal() {
        return this.filteredData.length;
    }

    get selectedCountOnCurrentPage() {
        const selection = Object.keys(
            this.selection.effectiveSelectionOnCurrentPage
        )
            .filter((x) => x)
            .map((x) => Number(x));
        const selectedIds = this.selectedData.map((x) => x.listId);
        return intersection(selection, selectedIds).length;
    }

    get selectedCountOnAllPages() {
        return this.selectedData.length;
    }

    get selectedData() {
        return this.filteredData.filter((item, i) => {
            if (
                this.selection.unChecked &&
                this.selection.unChecked[this.idAccessor(item)]
            )
                return false;
            if (
                this.selection.checked &&
                this.selection.checked[this.idAccessor(item)]
            )
                return true;
            let page = Math.ceil(++i / this.parent.pageSize) - 1;
            if (
                this.selection.pagesSelected &&
                this.selection.pagesSelected[page]
            )
                return true;
            if (
                this.selection.pagesSelected &&
                this.selection.pagesSelected[page] === false
            )
                return false;

            //default action:
            return this.selection.allSelected;
        });
    }
}
export default ClientSidePaginationWithSelect;
