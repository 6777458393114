import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SubjectPreview from '../../../containers/subjectPreview';
import { SpaceBetweenDiv } from '@awarego/awarego-components';
import { useCallback, Fragment } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import VideoDescription from './video-description';

const grid = 8;

const boxShadow =
    '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(29, 30, 29, 0.04)';

const StyledSpaceBetweenDiv = styled(SpaceBetweenDiv)`
    transition: 0.4s ease-out;

    &:hover > .opacity0 {
        opacity: 1;
    }

    &:hover {
        box-shadow: ${boxShadow};
    }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    borderRadius: 4,
    width: '100%',
    padding: 0,

    // change background colour if dragging
    boxShadow: isDragging ? boxShadow : '',

    // styles we need to apply on draggables
    ...draggableStyle,
});

function SubjectsOrder({ videos, onReorderList, onRemoveSubject, isTrial }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [previewVideoLocation, setPreviewVideoLocation] = useState(null);
    const count = videos.length || 0;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        onReorderList(result.source.index, result.destination.index);
    };

    const previewVideo = (video_id) => {
        setModalIsOpen(true);
        setPreviewVideoLocation(video_id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const getSubjects = useCallback(
        (provided) => {
            return (
                <div ref={provided.innerRef}>
                    {videos.map((item, index) => {
                        return (
                            <Draggable
                                key={item.id + '-' + index}
                                draggableId={item.id + '-' + index}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <StyledSpaceBetweenDiv
                                            gap={24}
                                            py={16}
                                            px={24}
                                            $noWrap
                                            borderRadius={4}
                                        >
                                            {/* drag & drop icon */}
                                            <img
                                                alt="drag handle"
                                                src="/static/img/drag.svg"
                                                className="opacity0"
                                            />
                                            {/* video thumbnail & text*/}
                                            <VideoDescription
                                                previewVideo={previewVideo}
                                                isTrial={isTrial}
                                                item={item}
                                            />

                                            {/* delete icon */}
                                            <ClearIcon
                                                color="error"
                                                className="opacity0"
                                                onClick={() => {
                                                    onRemoveSubject(item.id);
                                                }}
                                            />
                                        </StyledSpaceBetweenDiv>
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                    {count === 0 && (
                        <Row className="right-banner-placeholder">
                            <div className="loading-group-automation"></div>
                            <div className="loading-group-automation"></div>
                            <div className="loading-group-automation"></div>
                        </Row>
                    )}
                </div>
            );
        },
        [count, isTrial, onRemoveSubject, videos]
    );

    return (
        <Fragment>
            {previewVideoLocation && (
                <SubjectPreview
                    subjectId={previewVideoLocation}
                    isOpen={modalIsOpen}
                    onClose={closeModal}
                />
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => getSubjects(provided, snapshot)}
                </Droppable>
            </DragDropContext>
        </Fragment>
    );
}

export default observer(SubjectsOrder);
