import React from 'react';
import GenericInsight from './GenericInsight';

function WeakestListInsight({ insight, onAction }) {
    return (
        <GenericInsight
            heading={`"${insight.context.name}" struggled the most with the assessment`}
            text={`${insight.context.employeesPercentage}% of employees in the list are identified as Risky or Very Risky`}
            actionName=" View employees"
            insight={insight}
            onAction={onAction}
        />
    );
}

export default WeakestListInsight;
