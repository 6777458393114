import React, { useState, useEffect, Fragment } from 'react';
import {
    replaceUnderscoreWithSpace,
    replaceSpaceWithUnderscore,
} from '../../../../utils/helpers';
import { Link, useParams } from 'react-router-dom';
import {
    FlexDiv,
    Block,
    Heading2,
    TextTiny,
    SpaceBetweenDiv,
} from '@awarego/awarego-components';
import { useStores } from '../../../../hooks/use-stores';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ThemedDropdownItem } from '../../../../components/themed/ThemedComponents';
import ResilienceScoreDistribution from '../overview/resilience-score-distribution';
import ResilienceScore from '../overview/resilience-score';
import styled from 'styled-components';
import KeyBehavioursTable from './KeyBehavioursTable';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import Questions from '../questions';
import EmployeesList from '../../../users/EmployeesList';
import PreviewQuestion from '../../../../components/manager/assessment/preview-question';
import Dialog from '@mui/material/Dialog';
import ErrorMessage from '../../../../components/errormessage';
import EmployeesModal from '../../../../containers/users/components/EmployeesModal';
import EmployeesListsContainer from '../../../users/assessment-users/EmployeesListsContainer';

const HugeHeading = styled.h1`
    font-size: 56px;
    font-weight: 700;
    text-align: center;
`;

function ThreatAreaView({ companyId }) {
    let { threatAreaCode, id: assessmentId } = useParams();

    const { assessmentStore, brandingStore, assessmentEmployeesStoresFactory } =
        useStores();
    const {
        threatAreaDetail,
        loadingThreatAreaDetail,
        threatAreasList,
        editingAssessment,
        questionsReportList,
        error,
    } = assessmentStore;

    const { employeesOverviewStore: assessmentEmployeesStore } =
        assessmentEmployeesStoresFactory;

    const threatArea = replaceUnderscoreWithSpace(threatAreaCode);

    const [threatAreasDropdown, setThreatAreasDropdown] = useState(false);
    const [reviewQuestionsModal, setReviewQuestionsModal] = useState(false);
    const [reviewedQuestion, setReviewedQuestion] = useState(null);
    const [isPreviewQuestion, setIsPreviewQuestion] = useState(false);

    const showReviewQuestionsModal = (questionId, isPreview) => {
        setReviewedQuestion(questionId);
        setIsPreviewQuestion(isPreview);
        setReviewQuestionsModal(true);
    };

    const hideReviewQuestionsModal = () => {
        setReviewQuestionsModal(false);
        setReviewedQuestion(null);
    };

    const toggleThreatAreas = () => {
        !threatAreasDropdown &&
            threatAreasList.length === 0 &&
            loadOtherThreatAreas();
        setThreatAreasDropdown(!threatAreasDropdown);
    };

    useEffect(() => {
        assessmentStore.loadThreatAreaDetail(
            companyId,
            assessmentId,
            threatArea
        );
        !assessmentStore.editingAssessment &&
            assessmentStore.loadAssessment(companyId, assessmentId);
    }, [assessmentId, assessmentStore, companyId, threatArea]);

    const loadOtherThreatAreas = async () => {
        assessmentStore.loadThreatAreasList(companyId, assessmentId);
    };

    const onBarClick = (index) => {
        const def = brandingStore.getScoreDefinitionByIndex(index);
        assessmentEmployeesStoresFactory.showEmployeesModal('users', {
            isFinished: true,
            minScore: def && def.from_score,
            maxScore: def && def.to_score,
            threatArea: threatArea,
            scoreName: 'Threat area score',
            subheading: `Threat area: ${threatArea}`,
        });
    };

    return loadingThreatAreaDetail || !editingAssessment ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={`/assessment/${assessmentId}/view/threatAreas`}>
                <div className={'navigation back-button'}>
                    Back to {editingAssessment.name}
                </div>
            </Link>
            <ErrorMessage error={error} />

            {/* dashboard */}
            <Block>
                <FlexDiv column>
                    <FlexDiv>
                        <Heading2 mb={4}>{threatArea}</Heading2>
                        {editingAssessment._rawData.definition.areas.length >
                            1 && (
                            <FlexDiv ml={4} mr={8} link>
                                <Dropdown
                                    isOpen={threatAreasDropdown}
                                    toggle={toggleThreatAreas}
                                >
                                    <DropdownToggle caret tag="span" />
                                    <DropdownMenu>
                                        {assessmentStore.loadingThreatAreasList ? (
                                            <ThemedSpinner />
                                        ) : (
                                            threatAreasList.map((area) => {
                                                if (area.area !== threatArea)
                                                    return (
                                                        <ThemedDropdownItem
                                                            key={area.area}
                                                        >
                                                            <Link
                                                                to={`/assessment/${assessmentId}/view/threatAreas/${replaceSpaceWithUnderscore(
                                                                    area.area
                                                                )}`}
                                                            >
                                                                {area.area}
                                                            </Link>
                                                        </ThemedDropdownItem>
                                                    );
                                            })
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </FlexDiv>
                        )}
                    </FlexDiv>

                    <FlexDiv gap={8} alignCenter>
                        <TextTiny lighter>
                            {threatAreaDetail.assessmentKeyBehavioursCount}/
                            {threatAreaDetail.totalKeyBehavioursCount} key
                            behaviours | {threatAreaDetail.questionsCount}{' '}
                            {pluralize(
                                'question',
                                threatAreaDetail.questionsCount
                            )}
                        </TextTiny>
                    </FlexDiv>
                </FlexDiv>
            </Block>

            {/* average score */}
            <SpaceBetweenDiv mt={20} gap={8} alignStretch>
                <ResilienceScore
                    score={threatAreaDetail.averageScore}
                    HugeHeading={HugeHeading}
                    headingText={'Average score'}
                    howIsThisCalculatedTooltip={
                        'This number shows the average score for a specific Threat area. This score only applies to employees who completed the assessment.'
                    }
                    scoreTooltip={
                        'To better understand the meaning of scores that come from the assessment, these are descriptive grades for different score ranges:'
                    }
                    hasStarted={editingAssessment.hasStarted}
                />
                <ResilienceScoreDistribution
                    headingText={'Average score distribution'}
                    data={assessmentStore.threatAreaDetailChartValues}
                    hasStarted={editingAssessment.hasStarted}
                    onBarClick={onBarClick}
                />
            </SpaceBetweenDiv>

            {/* key behaviors */}
            <KeyBehavioursTable
                companyId={companyId}
                assessmentId={assessmentId}
                specifiedThreatAreaByDefault={threatArea}
                hasStarted={editingAssessment.hasStarted}
            />

            {/* questions */}
            <Questions
                companyId={companyId}
                assessmentId={assessmentId}
                specifiedThreatAreaByDefault={threatArea}
                hasStarted={editingAssessment.hasStarted}
                showReviewQuestionsModal={showReviewQuestionsModal}
            />

            {/* lists */}
            {!editingAssessment.isScorm && (
                <EmployeesListsContainer
                    assessmentId={assessmentId}
                    companyId={companyId}
                    threatArea={threatArea}
                />
            )}

            {/* employees */}
            <EmployeesList
                assessmentId={assessmentId}
                companyId={companyId}
                threatArea={threatArea}
                assessmentEmployeesStore={assessmentEmployeesStore}
                product={assessmentStore.editingAssessment}
            />

            {/* question preview */}
            <Dialog
                fullScreen
                open={reviewQuestionsModal}
                onClose={hideReviewQuestionsModal}
                className="review-questions-modal"
            >
                <PreviewQuestion
                    questionId={reviewedQuestion}
                    onRequestClose={hideReviewQuestionsModal}
                    type={!isPreviewQuestion && 'questionReview'}
                    companyId={companyId}
                    hasMoreQuestions={questionsReportList.length > 1}
                    assessmentId={editingAssessment.id}
                    questionsSelected={editingAssessment.questions}
                    questionsReportList={questionsReportList}
                />
            </Dialog>

            {/* employees modal */}
            <EmployeesModal
                companyId={companyId}
                productId={assessmentId}
                productEmployeesStoresFactory={assessmentEmployeesStoresFactory}
                product={assessmentStore.editingAssessment}
            />
        </Fragment>
    );
}

export default observer(ThreatAreaView);
