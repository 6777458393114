import { Link } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';

function LinkIfNeed({ link, children }) {
    if (link) {
        if (link.startsWith('http://') || link.startsWith('https://')) {
            return (
                <a href={link} target="_blank" rel="noreferrer">
                    {children}
                </a>
            );
        } else {
            return <Link to={link}>{children}</Link>;
        }
    }

    return children;
}

export default observer(LinkIfNeed);
