import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import UserDetailsContainer from './single-user/UserDetailsContainer';
import { Helmet } from 'react-helmet';
import UsersTable from './UsersTable';
import UsersListsTable from './UsersListsTable';
import { useStores } from '../../hooks/use-stores';
import SingleListContainer from './single-list-of-users/SingleListContainer';

function Users({ companyId }) {
    const { store } = useStores();
    const createDisabled =
        !store.currentCompany || !store.currentCompany.canAddUsers;
    const currentCompany = store.currentCompany;

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Employees</title>
            </Helmet>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <UsersListsTable
                                companyId={companyId}
                                heading={'Employee lists'}
                                createDisabled={createDisabled}
                                currentCompany={currentCompany}
                            />
                            <UsersTable
                                companyId={companyId}
                                heading={'All employees'}
                                createDisabled={createDisabled}
                                currentCompany={currentCompany}
                            />
                        </>
                    }
                />
                <Route
                    path="list/:listId/user/:email/*"
                    element={<UserDetailsContainer companyId={companyId} />}
                />

                <Route path="user/:email" element={<UserDetailsContainer />} />

                <Route
                    path="list/:listId/*"
                    element={<SingleListContainer companyId={companyId} />}
                />
            </Routes>
        </Container>
    );
}

export default observer(Users);
