import React from 'react';
import { FlexDiv } from '@awarego/awarego-components';

/** A Sub list that is a bit more flexible than others. Takes in an array of components to display */
function SubHeaderComponentsList({ children, customWidth = 'initial' }) {
    return (
        <FlexDiv alignCenter gap={8} mr={32} style={{ width: customWidth }}>
            {children}
        </FlexDiv>
    );
}

export default SubHeaderComponentsList;
