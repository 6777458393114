import React, { useMemo } from 'react';
import WeakestBehaviourInsight from './weakest-behaviour';
import LowestPerformingQuestionInsight from './lowest-performing-question';
import WeakestListInsight from './weakest-list';
import LowestPerformingThreatAreaInsight from './lowest-performing-threat-area';
import SingleThreatAreaInsight from './single-threat-area';

function RiskInsight({ insight, onAction, threatAreaCount }) {
    const Component = useMemo(() => {
        switch (insight.type) {
            case 'KEY_BEHAVIOUR':
                return WeakestBehaviourInsight;
            case 'THREAT_AREA':
                return threatAreaCount > 1
                    ? LowestPerformingThreatAreaInsight
                    : SingleThreatAreaInsight;
            case 'EMPLOYEE_LIST':
                return WeakestListInsight;
            case 'QUESTIONS':
                return LowestPerformingQuestionInsight;
        }
    }, [insight.type, threatAreaCount]);

    return Component ? (
        <Component insight={insight} onAction={onAction} />
    ) : null;
}

export default RiskInsight;
