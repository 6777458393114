import React from 'react';
import {
    FlexDiv,
    Block,
    Heading2,
    TextDefault,
    TextTiny,
    SpaceBetweenDiv,
    Heading3,
    Legend,
    TextWithTooltipIcon,
    LightTooltip,
} from '@awarego/awarego-components';
import { defaultZero, calculateScoreTitle } from '../../../../utils/helpers';
import SemiCircleProgress from '../../../../components/manager/assessment/semi-circle-progress';
import { observer } from 'mobx-react';

function ResilienceScore({
    hasStarted,
    HugeHeading,
    score,
    headingText,
    howIsThisCalculatedTooltip,
    scoreTooltip,
}) {
    return (
        <Block resetMargin flexGrow={1}>
            <SpaceBetweenDiv alignCenter mb={16}>
                <Heading3>{headingText}</Heading3>
                <LightTooltip title={howIsThisCalculatedTooltip}>
                    <TextTiny lighter link>
                        How is this calculated?
                    </TextTiny>
                </LightTooltip>
            </SpaceBetweenDiv>
            <FlexDiv column flexGrow={1} alignCenter justifyCenter>
                <SemiCircleProgress
                    percentage={score}
                    width={176}
                    top={54}
                    text={<HugeHeading>{defaultZero(score)}</HugeHeading>}
                    showNA={!hasStarted}
                />
                <TextDefault bold lighter mt={16} mb={hasStarted && 8}>
                    {hasStarted ? 'Average employee score is' : 'No score yet'}
                </TextDefault>
                {hasStarted && (
                    <TextWithTooltipIcon
                        tooltipText={
                            <FlexDiv column>
                                <TextTiny mb={8}>{scoreTooltip}</TextTiny>
                                <Legend
                                    stepCount={5}
                                    fullWidth
                                    vertical
                                    dotSize={16}
                                    fontSize={13}
                                />
                            </FlexDiv>
                        }
                        textComponent={
                            <Heading2>{calculateScoreTitle(score)}</Heading2>
                        }
                    />
                )}
            </FlexDiv>
        </Block>
    );
}

export default observer(ResilienceScore);
