import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
// import {Table, Tr, Td, Th, Thead} from 'reactable';
import {
    FormControlLabel,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Collapse,
    CircularProgress,
} from '@mui/material';
import { ThemedLink } from '../../../components/themed/ThemedComponents';

function SelectUsersTable({
    lists,
    selectedLists,
    onUserListSelected,
    searchValue,
    ongoing,
    previousEmployees,
    requestUsersForList,
    loadingUsers,
}) {
    let searchedList = lists
        .filter((list) =>
            list.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort(
            (listA, listB) => new Date(listB.created) - new Date(listA.created)
        );

    const [recipientsShown, setRecipientsShown] = useState([]);

    const toggleRecipients = (id, e) => {
        let recipients = recipientsShown.slice(0);

        if (recipientsShown.includes(id)) {
            recipients = recipients.filter((x) => x !== id);
        } else {
            recipients.push(id);
            requestUsersForList(id);
        }
        setRecipientsShown(recipients);
        e.stopPropagation();
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Lists ({searchedList.length})</TableCell>
                    <TableCell># Employees</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {lists &&
                    lists.length > 0 &&
                    searchedList.map((list) => (
                        <Fragment key={list.id}>
                            <TableRow
                                onClick={() => {
                                    onUserListSelected(list.id);
                                }}
                                style={{ borderBottom: 0 }}
                            >
                                <TableCell>
                                    <Fragment>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedLists.includes(
                                                        list.id
                                                    )}
                                                    name={'checkbox_' + list.id}
                                                    color="primary"
                                                    id={'checkbox_' + list.id}
                                                    disabled={
                                                        ongoing &&
                                                        previousEmployees.includes(
                                                            list.id
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                        {list.name}
                                    </Fragment>
                                </TableCell>
                                <TableCell>{list.usersCount}</TableCell>
                                {list.usersCount > 0 && (
                                    <TableCell>
                                        <ThemedLink
                                            className="show-recipients-link"
                                            onClick={(e) =>
                                                toggleRecipients(list.id, e)
                                            }
                                        >
                                            {recipientsShown.includes(list.id)
                                                ? 'Hide'
                                                : 'Show'}{' '}
                                            recipients
                                        </ThemedLink>
                                    </TableCell>
                                )}
                            </TableRow>
                            {recipientsShown.includes(list.id) && (
                                <Collapse
                                    in={recipientsShown.includes(list.id)}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <div className="show-recipients">
                                        {loadingUsers.includes(list.id) ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            list.users &&
                                            list.users.users &&
                                            list.users.users.map((u) => (
                                                <div key={u.email}>
                                                    {u.email}
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </Collapse>
                            )}
                        </Fragment>
                    ))}
            </TableBody>
        </Table>
    );
}

export default observer(SelectUsersTable);
