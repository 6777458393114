import React, { useState } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import HeaderSection from '../../../components/header/HeaderSection';
import SubHeaderDetailsList from '../../../components/header/SubHeaderDetailsList';

function TrainingUsersHeader({ onSearchValue, users }) {
    const [searchEmployeeValue, setSearchEmployeeValue] = useState(null);
    const [filteredUsersCount, setFilteredUsersCount] = useState(null);

    function handleInput(value) {
        let filteredUsers = null;
        if (value != undefined && value != '' && value != null) {
            filteredUsers = users.filter((u) => {
                return u.name?.toLowerCase().includes(value.toLowerCase());
            });
        }
        setFilteredUsersCount(
            filteredUsers ? filteredUsers.length : users.length
        );
        setSearchEmployeeValue(value);
        onSearchValue(value);
    }

    function getUsersCountString() {
        const count = filteredUsersCount ? filteredUsersCount : users.length;
        const value = count + ' ' + pluralize('employee', count);
        return value;
    }

    return (
        <HeaderSection
            heading={'Users progress'}
            searchPlaceholder={'Search Employees'}
            searchValue={searchEmployeeValue}
            onSearch={(e) => handleInput(e.target.value)}
            onClearSearch={() => handleInput('')}
            subHeadings={
                <SubHeaderDetailsList details={[getUsersCountString()]} />
            }
            excludeBlock
            marginLeft="16"
        />
    );
}

export default observer(TrainingUsersHeader);
