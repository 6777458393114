import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Spinner } from 'reactstrap';
import intersection from 'lodash/intersection';
import { useStores } from '../../hooks/use-stores';
import {
    Heading2,
    ThemedButton,
    BigModal,
    FlexDiv,
    TextTiny,
    SpaceBetweenDiv,
    Heading3,
} from '@awarego/awarego-components';
import GroupsTable from './groups-table';
import { observer } from 'mobx-react';
import MoreIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import TableClient from '../table/TableClient';

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 12,
        '& > span': {
            marginRight: 10,
            fontSize: 12,
        },
    },
    listOption: {
        fontSize: 12,
        minHeight: 48,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        paddingTop: 6,
        boxSizing: 'border-box',
        outline: '0',
        WebkitTapHighlightColor: 'transparent',
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up('sm')]: {
            minHeight: 'auto',
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.action.selected,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:active': {
            backgroundColor: theme.palette.action.selected,
        },
        '&[aria-disabled="true"]': {
            opacity: theme.palette.action.disabledOpacity,
            pointerEvents: 'none',
        },
    },
    /* Styles applied to the group's label elements. */
    groupLabel: {
        backgroundColor: theme.palette.background.paper,
        top: -8,
    },
    /* Styles applied to the group's ul elements. */
    groupUl: {
        padding: 0,
        '& $option': {
            paddingLeft: 24,
        },
    },

    input: {
        '& input': {
            fontSize: 12,
        },
        '& .Mui-focused  fieldset': {
            borderColor: 'rgba(52, 53, 52, 0.2) !important',
        },
    },
    list: {
        minWidth: 200,
    },
}));

function IntegrationDetailGroupSettings({ companyId, product, savingData }) {
    const classes = useStyles();
    const {
        companyStore,
        azureGroupsStore,
        googleGroupsStore,
        vantaGroupsStore,
        commonStore,
    } = useStores();
    let groupsStore;
    if (product === 'azure') groupsStore = azureGroupsStore;
    else if (product === 'vanta') groupsStore = vantaGroupsStore;
    else groupsStore = googleGroupsStore;
    const [includedRulesChanged, setIncludedRulesChanged] = useState(false);
    const [showGroupsModal, setShowGroupsModal] = useState(false);
    const [initiallyLoaded, setInitiallyLoaded] = useState(false);
    const [includedCopy, setIncludedCopy] = useState({});
    const [totalEmployees, setTotalEmployees] = useState(0);

    const { selectedGroups, included, loadingGroups } = groupsStore;

    useEffect(() => {
        (async () => {
            await groupsStore.loadSelectedGroups(companyId);
            setInitiallyLoaded(true);
        })();
    }, [companyId]);

    const handleRemoveGroup = async (id) => {
        commonStore.showConfirm(
            'Are you sure you want to remove this group from sync?',
            'Delete',
            'Delete group',
            async () => {
                let result = await groupsStore.removeGroup(
                    companyId,
                    product,
                    id
                );
                if (result) {
                    commonStore.success('Group deleted.');
                }
            },
            true
        );

        //setIncludedRulesChanged(true);
    };

    const handleSelectGroup = (group) => {
        const existing = includedCopy[group.id];
        if (existing) delete includedCopy[group.id];
        else
            includedCopy[group.id] = {
                id: group.id,
                displayName: group.displayName,
                usersCount: group.usersCount,
            };
        updateTotalEmployees(includedCopy);
        const i1 = intersection(
            Object.keys(included),
            Object.keys(includedCopy)
        ).length;
        const i2 = intersection(
            Object.keys(includedCopy),
            Object.keys(included)
        ).length;

        setIncludedRulesChanged(
            i1 !== Object.keys(included).length ||
                i2 !== Object.keys(includedCopy).length
        );
    };

    const updateTotalEmployees = (groups) => {
        const res = groups
            ? Object.values(groups).reduce((acc, g) => acc + g.usersCount, 0)
            : 0;
        setTotalEmployees(res);
    };

    const handleOpenGroupsDialog = () => {
        setIncludedCopy({ ...included });
        updateTotalEmployees(included);
        setShowGroupsModal(true);
    };

    const handleImportGroups = async () => {
        let result = await companyStore.saveIntegrationRules(
            companyId,
            product,
            1,
            {
                included: Object.values(includedCopy),
            }
        );
        if (result) {
            groupsStore.selectedGroups.replace(Object.values(includedCopy));
            setIncludedRulesChanged(false);
            setShowGroupsModal(false);
            setTotalEmployees(0);
            groupsStore.loadSelectedGroups(companyId);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [groupMenu, setGroupMenu] = useState(null);
    const handleMenuOpen = (e, assessment) => {
        setGroupMenu(assessment);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setGroupMenu(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    const moreActionsMenu = (group) => (
        <IconButton
            color="primary"
            className="show-on-hover"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
                handleMenuOpen(e, group);
                e.preventDefault();
            }}
            id={group.id}
            size="large"
        >
            <MoreIcon />
        </IconButton>
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Included Groups',
                accessor: 'displayName',
            },
            {
                Header: '# Employees',
                accessor: 'usersCount',
            },
            {
                Header: 'Last Sync',
                accessor: 'lastSynced',
                Cell: (x) => {
                    if (x.value) {
                        return <>{moment(x.value).fromNow()}</>;
                    } else {
                        return <>Never</>;
                    }
                },
            },
        ],
        []
    );

    const handleCloseGroupsDialog = () => {
        if (includedRulesChanged) {
            commonStore.showConfirm(
                'There are unsaved changes in group selection. Are you sure you want to close the window?',
                'Yes, Close',
                'Are you sure? Changes will be lost',
                () => {
                    setShowGroupsModal(false);
                },
                true,
                true,
                false,
                { confirmationCancelAction: 'No, Cancel' }
            );
        } else setShowGroupsModal(false);
    };

    return (
        <div className="integration-detail-block align-left">
            <FlexDiv fullWidth>
                <FlexDiv column flexGrow={1} mr={8}>
                    <Heading2 mb={16}>User groups</Heading2>
                    <p>
                        Any changes to the {product} user groups will be
                        automatically applied to the groups below.
                    </p>
                </FlexDiv>
                {!loadingGroups &&
                    initiallyLoaded &&
                    selectedGroups.length > 0 && (
                        <>
                            <FlexDiv alignCenter>
                                <ThemedButton
                                    mode="secondary"
                                    onClick={handleOpenGroupsDialog}
                                    disabled={loadingGroups}
                                    text="Select groups"
                                />
                            </FlexDiv>
                        </>
                    )}
            </FlexDiv>

            {loadingGroups && !initiallyLoaded && <Spinner />}

            {!loadingGroups && initiallyLoaded && (
                <Fragment>
                    <TableClient
                        columns={columns}
                        data={selectedGroups.slice(0)}
                        NoDataComponent={
                            <div className="text-center">
                                <br />
                                <img
                                    src="/static/img/icon-users.svg"
                                    alt="No users"
                                />
                                <br />
                                <br />
                                <Heading3>No user groups imported yet</Heading3>
                                <p
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Select the groups you want to import to our
                                    portal
                                </p>
                            </div>
                        }
                    />
                    {groupMenu && (
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={groupMenu != null}
                            onClose={handleMenuClose}
                            elevation={1}
                        >
                            <MenuItem
                                onClick={(e) => {
                                    handleMenuClose(e);
                                }}
                            >
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    handleRemoveGroup(groupMenu.id);
                                    handleMenuClose(e);
                                }}
                            >
                                <span style={{ color: 'red' }}>Delete</span>
                            </MenuItem>
                        </Menu>
                    )}
                </Fragment>
            )}

            {!loadingGroups &&
                initiallyLoaded &&
                selectedGroups.length === 0 && (
                    <FlexDiv alignCenter fullWidth>
                        <ThemedButton
                            size="fullWidth"
                            mode="secondary"
                            text="Select groups"
                            onClick={handleOpenGroupsDialog}
                            disabled={loadingGroups}
                        />
                    </FlexDiv>
                )}

            <BigModal
                isOpen={showGroupsModal}
                onRequestClose={handleCloseGroupsDialog}
                heading={
                    product === 'azure'
                        ? 'Import from Azure Active Directory'
                        : product === 'vanta'
                        ? 'Import from Vanta'
                        : 'Import from Google Suite'
                }
                width={1016}
                filterContent={<></>}
                modalContent={
                    <GroupsTable
                        groupsStore={groupsStore}
                        companyId={companyId}
                        included={includedCopy}
                        onSelectGroup={handleSelectGroup}
                        product={product}
                    />
                }
                modalFooterContent={
                    <SpaceBetweenDiv>
                        <TextTiny>
                            {Object.keys(includedCopy).length} groups selected{' '}
                            {totalEmployees > 0
                                ? `(${totalEmployees} Employees in total )`
                                : ''}
                        </TextTiny>
                        {savingData.includes(companyId) ? (
                            <Spinner />
                        ) : (
                            <FlexDiv gap={8}>
                                <ThemedButton
                                    onClick={handleCloseGroupsDialog}
                                    text="Close"
                                />

                                <ThemedButton
                                    mode="primary"
                                    text="Apply"
                                    onClick={handleImportGroups}
                                    disabled={!includedRulesChanged}
                                />
                            </FlexDiv>
                        )}
                    </SpaceBetweenDiv>
                }
            />
        </div>
    );
}

export default observer(IntegrationDetailGroupSettings);
