import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    CalendarInput,
    TextInput,
    TextTiny,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import { Spinner } from 'reactstrap';
import { formatCurrency, isSubscriptionPlanFree } from '../../utils/helpers';
import moment from "moment";

function PackageForm({
    companyForm,
    isEdit,
    subscription,
    subscriptionPlans,
    loadingSubscriptionsPlans,
    partnerEstimate,
    estimationLoading,
}) {
    const inputHasError = (input) =>
        companyForm.$(input).error && companyForm.$(input).error.length > 0;

    const companyFormSeats = parseInt(companyForm.values().seats);

    const companyFormSubscription = companyForm.values().subscription;

    const isFree = isSubscriptionPlanFree(
        subscriptionPlans,
        companyFormSubscription
    );

    const seatsChanged =
        isEdit &&
        subscription &&
        companyFormSeats !== subscription.package_users;

    const subscriptionChanged =
        isEdit &&
        subscription &&
        companyFormSubscription !== subscription.subscription_code;

    const moreCompaniesWithSubscription =
        partnerEstimate &&
        partnerEstimate.newPartnerSubscriptionSeats > companyFormSeats;

    const isInactive = !subscription || subscription.package_valid === 0;

    const seatsIncreased =
        subscription && companyFormSeats > subscription.package_users;

    const isFreeInitially =
        subscription &&
        isSubscriptionPlanFree(
            subscriptionPlans,
            subscription.subscription_code
        );

    const validOptions = useMemo(
        () => [
            {
                value: '',
                name: 'Select timespan',
                disabled: true,
            },
            {
                value: '1',
                name: 'Month',
            },
            {
                value: '3',
                name: '3 months',
            },
            {
                value: '6',
                name: '6 months',
            },
            {
                value: '12',
                name: 'Year',
            },
            {
                value: '18',
                name: '18 months',
            },
            {
                value: '24',
                name: '2 years',
            },
            {
                value: '60',
                name: '5 years',
            },
            {
                value: '120',
                name: '10 years',
            },
            {
                value: '1200',
                name: 'Eternal',
            },
            {
                value: '-1',
                name: 'Custom',
            },
        ],
        []
    );
    return (
        <Fragment>
            {loadingSubscriptionsPlans ? (
                <Spinner />
            ) : (
                <Fragment>
                    <TextInput
                        error={inputHasError('subscription')}
                        helperText={
                            inputHasError('subscription')
                                ? 'Please choose a tier'
                                : undefined
                        }
                        stretched
                        {...companyForm.$('subscription').bind()}
                        select
                    >
                        <MenuItem value={''} disabled={true}>
                            Choose tier
                        </MenuItem>
                        {subscriptionPlans.map((p) => (
                            <MenuItem key={p.code} value={p.code}>
                                {p.name}
                            </MenuItem>
                        ))}
                    </TextInput>
                </Fragment>
            )}
            <TextInput
                error={inputHasError('seats')}
                helperText={
                    inputHasError('seats')
                        ? 'Please enter number of seats'
                        : !estimationLoading
                        ? (seatsChanged || isInactive || subscriptionChanged) &&
                          !isFree &&
                          partnerEstimate &&
                          moreCompaniesWithSubscription && (
                              <TextTiny themed bold>
                                  The new,{' '}
                                  {!isEdit || seatsIncreased
                                      ? 'decreased'
                                      : 'increased'}{' '}
                                  price{' '}
                                  {formatCurrency(
                                      partnerEstimate.newPartnerTotalForPlan /
                                          partnerEstimate.newPartnerSubscriptionSeats,
                                      partnerEstimate.currencyCode
                                  )}{' '}
                                  per seat will be applied to all active
                                  companies in the{' '}
                                  {subscriptionPlans &&
                                      subscriptionPlans.filter(
                                          (p) =>
                                              p.code === companyFormSubscription
                                      )[0].name}{' '}
                                  subscription
                              </TextTiny>
                          )
                        : null
                }
                stretched
                {...companyForm.$('seats').bind()}
                disabled={isFree}
            />
            {!isFree && (
                <Fragment>
                    {(!isEdit || (isEdit && isInactive) || isFreeInitially) && (
                        <TextInput
                            error={inputHasError('valid')}
                            helperText={
                                inputHasError('valid')
                                    ? 'Please select a timespan'
                                    : undefined
                            }
                            stretched
                            select
                            {...companyForm.$('valid').bind()}
                        >
                            {validOptions.map((p) => (
                                <MenuItem
                                    key={p.value}
                                    value={p.value}
                                    disabled={p.disabled}
                                >
                                    {p.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    )}
                    {(companyForm.$('valid').value === '-1' ||
                        (isEdit && !isInactive && !isFreeInitially)) && (
                        <CalendarInput
                            {...companyForm.$('validTill').bind()}
                            maxDate={moment('2200-01-02')}
                            datePickerOnly
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(PackageForm);
