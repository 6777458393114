import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { LightTooltip, TextTiny, palette } from '@awarego/awarego-components';
import NameCell from './NameCell';
import StyledLink from '../StyledLink';
import compact from 'lodash/compact';

function getItemText(item) {
    if (typeof item === 'string') return item;

    if (typeof item === 'object') return item.title || item.name;
}

function NameAndSublistCell({
    name,
    maxVisibleNameLength,
    subListItems,
    maxVisibleSubListCount,
    link = null, // link for subListItems
    linkName = null, // link for Name
}) {
    subListItems = compact(subListItems);
    return (
        <Fragment>
            {name && (
                <>
                    {linkName != null ? (
                        <StyledLink
                            to={linkName}
                            $hoverColor={palette.graphite.onyx}
                            $underlined={true}
                        >
                            <NameCell
                                name={name}
                                maxVisibleNameLength={maxVisibleNameLength}
                            />
                        </StyledLink>
                    ) : (
                        <NameCell
                            name={name}
                            maxVisibleNameLength={maxVisibleNameLength}
                        />
                    )}
                </>
            )}
            {subListItems && subListItems.length > 0 ? (
                <TextTiny {...(name ? { lighter: true } : {})}>
                    {subListItems
                        .slice(0, maxVisibleSubListCount)
                        .map((item, i, arr) => {
                            if (link) {
                                return (
                                    <StyledLink
                                        to={`${link.url}${item.id}`}
                                        key={i}
                                        $hoverColor={palette.graphite.onyx}
                                        $underlined={true}
                                    >
                                        {i !== 0 && ', '}
                                        {getItemText(item)}
                                    </StyledLink>
                                );
                            } else {
                                return (
                                    <span key={i}>
                                        {/* don't put comma after last item  */}
                                        {getItemText(item)}
                                        {i !== arr.length - 1 ? ', ' : ''}
                                    </span>
                                );
                            }
                        })}
                    {subListItems.length > maxVisibleSubListCount && (
                        <LightTooltip
                            onClick={(e) => e.preventDefault()}
                            title={subListItems
                                .slice(
                                    maxVisibleSubListCount,
                                    subListItems.length
                                )
                                .map((item, i) => {
                                    if (link) {
                                        return (
                                            <StyledLink
                                                key={item.id}
                                                to={`${link.url}${item.id}`}
                                                $hoverColor={
                                                    palette.graphite.onyx
                                                }
                                                $underlined={true}
                                            >
                                                <span>{getItemText(item)}</span>
                                                <br />
                                            </StyledLink>
                                        );
                                    } else {
                                        return (
                                            <div key={i}>
                                                {getItemText(item)}
                                            </div>
                                        );
                                    }
                                })}
                        >
                            <TextTiny
                                underline
                                ml={5}
                                lighter
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                +
                                {
                                    subListItems.slice(
                                        maxVisibleSubListCount,
                                        subListItems.length
                                    ).length
                                }{' '}
                                more
                            </TextTiny>
                        </LightTooltip>
                    )}
                </TextTiny>
            ) : (
                ''
            )}
        </Fragment>
    );
}

export default observer(NameAndSublistCell);
