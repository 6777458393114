import React, { useState } from 'react';
import { observer } from 'mobx-react';
import capitalize from 'lodash/capitalize';
import { Heading1, TextInput, ThemedButton } from '@awarego/awarego-components';

function ChangeName({
    onRequestClose,
    name,
    nextStep,
    onChangeName,
    saveChanges,
    isDraft,
    redirectLink,
    itemString,
    isNameUntitled,
}) {
    const handleOnRequestClosed = () => {
        setIsSavingInProgress(false);
        onRequestClose();
    };

    const [isSavingInProgress, setIsSavingInProgress] = useState(false);

    return (
        <div className="modalWindow simple">
            <div className={'modalHeader plain'}>
                <Heading1>Name this {itemString}</Heading1>
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className={'modalContent'} style={{ minHeight: '100px' }}>
                <div className="form-holder">
                    <TextInput
                        type="text"
                        name="name"
                        value={name}
                        stretched
                        label={`${capitalize(itemString)} name`}
                        onChange={onChangeName}
                        autoFocus
                        placeholder={`e.g. ${capitalize(itemString)} 1`}
                    />
                </div>
            </div>
            <div className={'modalFooter'}>
                <ThemedButton
                    mode="primary"
                    onClick={() => {
                        if (isDraft) {
                            setIsSavingInProgress(true);
                            return saveChanges(
                                redirectLink || undefined
                            ).finally(() => handleOnRequestClosed());
                        } else {
                            handleOnRequestClosed();
                            return nextStep();
                        }
                    }}
                    disabled={isNameUntitled || isSavingInProgress}
                    text={
                        isSavingInProgress
                            ? 'Saving'
                            : isDraft
                            ? 'Save draft'
                            : 'Save and complete'
                    }
                    $center={true}
                    $saving={isSavingInProgress}
                />
            </div>
        </div>
    );
}

export default observer(ChangeName);
