import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import TableClient from '../../../components/table/TableClient';
import {
    DarkArrowTooltipIcon,
    ThemedButton,
    FlexDiv,
    Image,
    TextDefault,
    palette,
} from '@awarego/awarego-components';
import { caseAndDiacriticsInsensitiveSort } from '../../../components/table/table-helpers';
import RequireRole from '../../RequireRole';
import styled from 'styled-components';
import { useStores } from '../../../hooks/use-stores';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import ErrorMessage from '../../../components/errormessage';

function ScormLanguagesTable({
    onEditLanguage,
    onDeleteLanguage,
    onAddLanguage,
    onPreviewLanguage,
    mainScorm,
}) {
    const rowsPerPage = 5;
    const { cmi5Store, languageStore } = useStores();

    const RightAlignedCell = styled.div`
        text-align: right;
        padding-right: 8px;
    `;

    useEffect(() => {
        (async () => {
            await languageStore.loadAllLanguages();
        })();
        return () => {
            cmi5Store.error = null;
        };
    }, []);

    const getRowWithEnglishName = (row) => {
        const filteredLanguages = languageStore.languages.find(
            (lang) => lang.code === row.language
        );

        const isMainScorm = mainScorm && mainScorm.id == row.id;

        if (filteredLanguages) {
            row.english_name = filteredLanguages.english_name;
        }

        row.isMainLanguage = isMainScorm ? true : false;
        return row;
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    const isMainScorm =
                        mainScorm && mainScorm.id == x.row.original.id;
                    return (
                        <TextDefault>
                            {x.value}{' '}
                            {isMainScorm ? (
                                <i
                                    style={{
                                        color: palette.graphite.charcoal,
                                    }}
                                >
                                    [Default Language]
                                </i>
                            ) : null}
                        </TextDefault>
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Language',
                accessor: 'language',
                Cell: (x) => {
                    const passedLanguages = languageStore.languages.filter(
                        (l) => {
                            return l.code === x.value;
                        }
                    );

                    if (passedLanguages && passedLanguages.length > 0) {
                        return (
                            <FlexDiv alignCenter>
                                <Image
                                    height={24}
                                    src={passedLanguages[0].flag_image}
                                    alt="language flag"
                                ></Image>
                                <TextDefault ml={8}>
                                    {passedLanguages[0].english_name}
                                </TextDefault>
                            </FlexDiv>
                        );
                    } else {
                        return 'Language Not Found';
                    }
                },
            },
            {
                Header: ' ',
                id: 'previewColumn',
                Cell: (x) => {
                    return (
                        <RightAlignedCell>
                            <ThemedButton
                                mode="secondary"
                                text={'Preview'}
                                $noTextTransform
                                onClick={() =>
                                    onPreviewLanguage(x.row.original)
                                }
                            />
                        </RightAlignedCell>
                    );
                },
            },
            {
                Header: ' ',
                id: 'actionsColumn',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
        ],
        [caseAndDiacriticsInsensitiveSort, languageStore.languages, mainScorm]
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={() =>
                            onEditLanguage(getRowWithEnglishName(row))
                        }
                        row={row}
                        title="Edit Language"
                        icon={'edit'}
                    ></DarkArrowTooltipIcon>
                    <DarkArrowTooltipIcon
                        customFunction={onDeleteLanguage}
                        row={row}
                        title="Delete Language"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
            </>
        );
    };

    return (
        <>
            {cmi5Store.error && (
                <ErrorMessage error={cmi5Store.error} marginBottom />
            )}
            {languageStore.loadingLanguages || cmi5Store.loadingScorm ? (
                <ThemedSpinner />
            ) : (
                <>
                    <TableClient
                        columns={columns}
                        data={cmi5Store.scormWithTranslations.slice()}
                        hidePagination={
                            cmi5Store.scormWithTranslations &&
                            cmi5Store.scormWithTranslations.length <=
                                rowsPerPage
                        }
                        defaultSortBy={'name'}
                        defaultSortDescent={false}
                        loadingData={
                            cmi5Store.deleteLanguagePackage ||
                            cmi5Store.loadingScorm
                        }
                    />

                    <RequireRole>
                        <FlexDiv my={32} ml={18}>
                            <ThemedButton
                                mode="secondary"
                                text="+ Add language"
                                $noTextTransform={true}
                                $noWrap
                                onClick={onAddLanguage}
                            ></ThemedButton>
                        </FlexDiv>
                    </RequireRole>
                </>
            )}
        </>
    );
}

export default observer(ScormLanguagesTable);
