import React, { useMemo, Fragment, useState } from 'react';
import Setting from './Setting';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useStores } from '../../hooks/use-stores';
import {
    FlexDiv,
    FullscreenModal,
    Heading2,
    TextTiny,
} from '@awarego/awarego-components';
import Upgrade from '../subscriptions/Upgrade';
import Logo from '../../components/logo';

export default observer(() => {
    const { store, companyStore, brandingStore, subscriptionStore } =
        useStores();
    const {
        currentCompany,
        isTrial,
        subscriptionBillingPeriod,
        havePartnerSubscription,
    } = store;

    const { supportEmail } = brandingStore;

    const activePackage = currentCompany.subscription;
    const isChargeBeeEnabled = currentCompany.chargebeeEnabled;

    const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);

    const openUpgradeModal = () => {
        setUpgradeModalOpened(true);
    };

    const closeUpgradeModal = () => {
        subscriptionStore.billingInfo = null;
        setUpgradeModalOpened(false);
    };

    const openCBportal = async () => {
        let chargebeeInstance = window.Chargebee.getInstance();

        let portalSession = await companyStore.openManageSession(
            currentCompany.company_id
        );

        chargebeeInstance.setPortalSession(portalSession);

        let cbPortal = chargebeeInstance.createChargebeePortal();
        cbPortal.open({
            close() {
                chargebeeInstance.logout();
            },
        });
    };

    const packageRenewal = useMemo(
        () => (
            <Fragment>
                {' '}
                · {subscriptionBillingPeriod} billing{' '}
                {activePackage &&
                    ` (Renews on ${moment(
                        activePackage.package_valid_to
                    ).format('LL')})`}
            </Fragment>
        ),
        [activePackage, subscriptionBillingPeriod]
    );

    const packageRenewalPartner = useMemo(
        () => (
            <Fragment>
                {' '}
                · Valid until:{' '}
                {activePackage &&
                    ` ${moment(activePackage.package_valid_to).format('LL')}`}
            </Fragment>
        ),
        [activePackage, subscriptionBillingPeriod]
    );

    const description = useMemo(
        () => (
            <Fragment>
                {activePackage
                    ? `${currentCompany.users_total}/${activePackage.package_users} seats used`
                    : ''}
                {isTrial
                    ? ` · Expires on (${moment(
                          currentCompany.trial_ends
                      ).format('LL')})`
                    : havePartnerSubscription
                    ? packageRenewalPartner
                    : packageRenewal}
                {havePartnerSubscription && (
                    <FlexDiv mt={8}>
                        Please contact
                        <TextTiny mx={4} bold themed>
                            <a href={`mailto:${supportEmail}`}>
                                {supportEmail}
                            </a>
                        </TextTiny>
                        to change your subscription.
                    </FlexDiv>
                )}
            </Fragment>
        ),
        [
            activePackage,
            currentCompany.trial_ends,
            currentCompany.users_total,
            havePartnerSubscription,
            isTrial,
            packageRenewal,
            supportEmail,
        ]
    );

    const nonTrialActionText = !havePartnerSubscription ? 'Manage' : undefined;

    const showChargebee = useMemo(
        () => isChargeBeeEnabled && !havePartnerSubscription,
        [havePartnerSubscription, isChargeBeeEnabled]
    );

    return (
        <div className="top-guide">
            <div className="block new-settings-block">
                <div className="row">
                    <div className="col-md-8 ">
                        <Heading2 displayBlock mb={8}>
                            Subscription
                        </Heading2>
                        <p className="text-thin">
                            Manage subscription plans and payment options
                        </p>
                    </div>
                </div>

                {activePackage ? (
                    <Setting
                        name="Your plan"
                        value={
                            activePackage.package_trial
                                ? 'Trial'
                                : activePackage.package_name
                        }
                        subtext={description}
                        actionName={isTrial ? 'Upgrade' : nonTrialActionText}
                        action={openUpgradeModal}
                        isLast={!showChargebee}
                    />
                ) : (
                    <Setting
                        name="Your plan"
                        value={`Expired`}
                        subtext={`To reactivate your account please select a plan`}
                        actionName="Select plan"
                        action={openUpgradeModal}
                        isLast={!showChargebee}
                    />
                )}
                {showChargebee && (
                    <Setting
                        name="Payment settings"
                        actionName="Manage payment settings"
                        action={openCBportal}
                        isLast={true}
                    />
                )}
            </div>
            <FullscreenModal
                isOpen={upgradeModalOpened}
                onRequestClose={closeUpgradeModal}
                modalContent={<Upgrade companyId={currentCompany.company_id} />}
                heading={'Upgrade'}
                logo={<Logo />}
            />
        </div>
    );
});
