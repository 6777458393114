import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import Managers from '../../components/company/managers';

function AdminsSettings() {
    const { store, authStore } = useStores();

    const { currentCompany, addingManager, addManagerError } = store;

    const addManager = async (form) => {
        const addedManager = await store.addManagerToCurrentCompany(
            form.values()
        );
        return addedManager;
    };

    const removeManager = async (companyId, email) => {
        return await store.removeManagerFromCurrentCompany(email);
    };

    const sendLoginInfo = async (managerEmail) => {
        await authStore.signin(
            managerEmail,
            currentCompany.company_id,
            null,
            null,
            '/',
            'login-info'
        );
    };

    return (
        <Managers
            company={currentCompany}
            addingManager={addingManager}
            addManagerError={addManagerError}
            addManager={addManager}
            removeManager={removeManager}
            sendLoginInfo={sendLoginInfo}
        />
    );
}

export default observer(AdminsSettings);
