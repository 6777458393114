import RestService from './RestService';
import Users from './UsersService';

export default class Lists extends RestService {
    constructor(parentService, companyId) {
        if (parentService && companyId)
            super(`${parentService.baseUrl}/${companyId}/lists`);
        else super('/lists');
    }

    createBatch(listId, data) {
        if (listId)
            return this.post(
                `${this.baseUrl}/${listId}/createUsersBatch`,
                data
            );
        else return this.post(`${this.baseUrl}/createUsersBatch`, data);
    }

    usersService(listId) {
        return new Users(this, listId);
    }
}
