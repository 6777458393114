import React, { useState } from 'react';
import {
    palette,
    TextDefault,
    FlexDiv,
    TextInput,
    SpaceBetweenDiv,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import {
    getSubjectsCount,
    calculateTrainingDuration,
} from './recommendation-helpers';
import { MenuItem } from '@mui/material';

function RecommendationSubheader({ videos, lists, reloadVideos }) {
    const subjectsCount = getSubjectsCount(videos);
    const weeksCount = calculateTrainingDuration(subjectsCount, 2);
    const [selectedList, setSelectedList] = useState('all');

    const onChangeList = (e) => {
        setSelectedList(e.target.value);
        reloadVideos(e.target.value);
    };

    return (
        <FlexDiv mt={32}>
            <SpaceBetweenDiv $noWrap alignStart gap={8}>
                <FlexDiv flexGrow={1}>
                    <TextInput
                        select
                        inline
                        value={selectedList}
                        onChange={(e) => onChangeList(e)}
                        style={{
                            backgroundColor: palette.graphite.cloud,
                            width: '100%',
                        }}
                    >
                        <MenuItem key="all" value="all">
                            {lists
                                ? `All lists [${lists.length}]`
                                : 'All Employees'}
                        </MenuItem>
                        {lists &&
                            lists.map((list) => (
                                <MenuItem key={list.listId} value={list.listId}>
                                    {list.listName}
                                </MenuItem>
                            ))}
                    </TextInput>
                </FlexDiv>
                <FlexDiv flexGrow={4}>
                    <TextDefault
                        lighter
                        semiBold
                        mt={8}
                        color={palette.graphite.heavyMetal}
                    >
                        This{' '}
                        <TextDefault bold color={palette.graphite.onyx}>
                            {weeksCount}-week{' '}
                        </TextDefault>{' '}
                        training program contains{' '}
                        <TextDefault bold color={palette.graphite.onyx}>
                            {subjectsCount}{' '}
                            {pluralize('subject', subjectsCount)}
                        </TextDefault>
                        , with a focus on those threat areas where your
                        employees scored lower in this assessment.
                    </TextDefault>
                </FlexDiv>
            </SpaceBetweenDiv>
        </FlexDiv>
    );
}

export default observer(RecommendationSubheader);
