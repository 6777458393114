import React, {Fragment, useState, useMemo, useEffect} from 'react';
import {observer} from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    FlexDiv,
    Block,
    SpaceBetweenDiv,
    BigModal,
    TextTiny,
    TextLead,
    TextDefault,
    Heading2,
    StatusBadge,
    ThemedButton,
    palette,
} from '@awarego/awarego-components';
import {useStores} from '../../hooks/use-stores';
import PackageForm from '../../components/partner/PackageForm';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import RemoveSubscriptionConfirm from '../../components/partner/RemoveSubscriptionConfirm';
import StopCampaignsConfirm from '../../components/partner/StopCampaignsConfirm';
import EditSubscriptionConfirm from '../../components/partner/EditSubscriptionConfirm';
import {formatCurrency, isSubscriptionPlanFree} from '../../utils/helpers';
import ReduceEmployeesConfirm from '../../components/partner/ReduceEmployeesConfirm';
import TableClient from '../../components/table/TableClient';

function CompanySubscription({company, partnerId, loadCompany}) {
    const {partnerStore, commonStore, subscriptionStore} = useStores();

    const [showEditPackageModal, setShowEditPackageModal] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);

    const {addingPackage} = partnerStore;

    const {
        subscriptionPlans,
        loadingSubscriptionsPlans,
        companyForm,
        partnerEstimate,
        estimationLoading,
    } = subscriptionStore;

    useEffect(() => {
        subscriptionStore.loadSubscriptionPlans(partnerId);
    }, [partnerId, subscriptionStore]);

    const isInactive =
        company.subscription && company.subscription.package_valid === 0;

    const isFree =
        companyForm &&
        subscriptionPlans &&
        isSubscriptionPlanFree(
            subscriptionPlans,
            company.subscription?.subscription_code
        );

    const initiateEditForm = async () => {
        subscriptionStore.initCompanyForm(
            partnerId,
            handleSubmitEditPackage,
            company,
            true,
            isInactive
        );
        setShowEditPackageModal(true);
    };

    const subscriptionName = (plan) =>
        subscriptionPlans.filter((p) => p.code === plan)[0].name;

    // when you click on Confirm btn in Confirm subscription changes modal
    const confirmAndExecuteEditSubscription = (form) => {
        setShowEditPackageModal(false);
        commonStore.showConfirm(
            <EditSubscriptionConfirm
                isInactive={isInactive}
                companyForm={form}
                partnerEstimate={subscriptionStore.partnerEstimate}
                validTo={company?.subscription?.package_valid_to}
                seats={company?.subscription?.package_users}
                subscription={company?.subscription?.subscription_code}
                newSubscriptionName={subscriptionName(
                    form.values().subscription
                )}
            />,
            'Confirm',
            'Confirm subscription changes',
            async () => {
                const params = {
                    companyName: form.values().companyName,
                    subscriptionCode: form.values().subscription,
                    seats: form.values().seats,
                };
                if (form.values().valid) params.validTo = form.values().valid;
                if (form.values().validTill)
                    params.validTill = form.values().validTill;

                let result = await subscriptionStore.updateCompanySubscription(
                    partnerId,
                    company.company_id,
                    params
                );
                if (result) {
                    loadCompany();
                    commonStore.success('Subscription updated.');
                } else {
                    if (subscriptionStore.error) {
                        commonStore.error(subscriptionStore.error);
                    }
                }
            },
            false
        );
    };

    // when you click on Review and confirm in Edit form
    const handleSubmitEditPackage = async (form) => {
        await subscriptionStore.loadCanEditSubscription({
            partnerId,
            companyId: company.company_id,
            subscriptionCode: form.values().subscription,
            newSeats: parseInt(form.values().seats),
        });

        const result = subscriptionStore.canEditSubscription;

        const subscriptionChanged =
            company.subscription?.subscription_code !==
            form.values().subscription;

        if (result) {
            // if there are no problems
            if (result.canBeEdited) {
                confirmAndExecuteEditSubscription(form);
            }
            // if there are running trainings or assessments
            else if (result.activeAssessments || result.activeTrainings) {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <StopCampaignsConfirm
                        action={`change subscription plan to ${subscriptionName(
                            form.values().subscription
                        )}`}
                        result={result}
                    />,
                    'Ok',
                    'Unable to change subscription plan',
                    () => {
                    },
                    false,
                    false
                );
            }
            // if you have more employees imported than seats you selected
            else if (result.removeEmployees > 0) {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <ReduceEmployeesConfirm
                        canEditSubscription={result}
                        subscriptionChanged={subscriptionChanged}
                    />,
                    'Got it',
                    subscriptionChanged
                        ? 'Unable to change your subscription plan'
                        : 'Unable to change number of seats',
                    () => {
                    },
                    false,
                    false
                );
            }
            // if you chose more seats than subscription plan allows - probably won't happen as for free subscription plan it's always 10 and the input is disabled
            else if (result.removeSeats > 0) {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <TextLead>Remove {result.removeSeats} seats.</TextLead>,
                    'Got it',
                    'Unable to make changes',
                    () => {
                    },
                    false,
                    false
                );
            }
        } else {
            if (subscriptionStore.canEditSubscriptionError) {
                commonStore.error(subscriptionStore.canEditSubscriptionError);
            }
        }
    };

    const onDeactivate = async () => {
        setIsDeactivating(true);
        let canBeRemovedResult = await subscriptionStore.canBeRemoved(
            partnerId,
            company.company_id
        );

        // if there are no problems with deactivating company
        if (canBeRemovedResult && canBeRemovedResult.canBeRemoved) {
            let estimationResult = await subscriptionStore.loadEstimation(
                partnerId,
                company.subscription.subscription_code,
                0,
                company.company_id
            );
            if (estimationResult) {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <RemoveSubscriptionConfirm
                        company={company}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        verb={'deactivate'}
                        subject={'subscription for'}
                        action={'deactivating'}
                        isFree={isFree}
                        subscriptionName={
                            company.subscription.subscription_name
                        }
                    />,
                    'Yes, deactivate',
                    'Deactivate subscription',
                    async () => {
                        let deactivationResult =
                            await subscriptionStore.deactivateCompanySubscription(
                                partnerId,
                                company.company_id
                            );
                        if (deactivationResult) {
                            loadCompany();
                            commonStore.success('Subscription deactivated.');
                        } else {
                            if (subscriptionStore.deactivatingError) {
                                commonStore.error(
                                    subscriptionStore.deactivatingError
                                );
                            }
                        }
                    },
                    true
                );
            } else {
                if (subscriptionStore.estimationError) {
                    commonStore.error(subscriptionStore.estimationError);
                }
            }
        }
        // if there are running trainings or assessments
        else {
            setShowEditPackageModal(false);
            commonStore.showConfirm(
                <StopCampaignsConfirm
                    action={'deactivate this subscription'}
                    result={canBeRemovedResult}
                />,
                'Ok',
                'Unable to deactivate subscription',
                () => {
                },
                false,
                false
            );
        }
        setIsDeactivating(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Plan',
                accessor: 'subscription_name',
                disableSortBy: true,
            },
            {
                Header: 'Seats',
                accessor: 'package_users',
                disableSortBy: true,
            },
            {
                Header: 'Status',
                accessor: 'package_valid',
                Cell: (x) => {
                    return x.value ? (
                        <StatusBadge
                            color={palette.evergreen.aspargus}
                            text={'Active'}
                        />
                    ) : (
                        <StatusBadge
                            color={palette.vibrant.red}
                            text={'Inactive'}
                        />
                    );
                },
                disableSortBy: true,
            },
            {
                Header: 'Active from',
                accessor: 'package_valid_from',
                Cell: (x) => moment(x.value).format('ll'),
                disableSortBy: true,
            },
            {
                Header: 'Active until',
                accessor: 'package_valid_to',
                Cell: (x) => moment(x.value).add(-1, 'd').format('ll'), //Displaying date of day before subscription runs out to eliminate confusions
                disableSortBy: true,
            },
            {
                Header: 'Monthly cost',
                accessor: 'price',
                Cell: (x) =>
                    `${formatCurrency(x.value, x.row.original.currencyCode)}`,
                tooltip:
                    'Your monthly cost will vary depending on the total number of assigned seats across all companies.',
                disableSortBy: true,
            },
        ],
        []
    );

    const priceComponent = (price = 0, currency) => (
        <FlexDiv>
            <TextLead bold>{formatCurrency(price, currency)}</TextLead>
            <TextLead lighter bold>
                /month
            </TextLead>
        </FlexDiv>
    );

    return !company ? (
        <ThemedSpinner/>
    ) : (
        <Fragment>
            <Block>
                <SpaceBetweenDiv ml={16} pr={32}>
                    <Heading2>Subscription</Heading2>
                    <ThemedButton
                        mode="secondary"
                        onClick={initiateEditForm}
                        text={isInactive ? 'Reactivate' : 'Edit subscription'}
                    />
                </SpaceBetweenDiv>
                <FlexDiv mt={32} justifyCenter>
                    {subscriptionStore.updatingCompanySubscription ||
                    subscriptionStore.deactivatingCompanySubscription ? (
                        <ThemedSpinner/>
                    ) : (
                        company.subscription && (
                            <TableClient
                                data={[company.subscription]}
                                columns={columns}
                                hidePagination
                            />
                        )
                    )}
                </FlexDiv>
            </Block>
            <BigModal
                isOpen={showEditPackageModal}
                onRequestClose={() => setShowEditPackageModal(false)}
                heading={
                    isInactive ? 'Reactivate subscription' : 'Edit subscription'
                }
                extraHeaderContent={
                    <Fragment>
                        {isInactive ? (
                            <FlexDiv pt={8}>
                                <TextDefault>
                                    {company.company_name} was previously on the
                                </TextDefault>
                                {company.subscription && (
                                    <TextDefault bold ml={4}>
                                        {company.subscription.subscription_name}{' '}
                                        plan
                                    </TextDefault>
                                )}
                            </FlexDiv>
                        ) : (
                            <FlexDiv pt={8} alignCenter>
                                <TextDefault>
                                    {company.subscription && (
                                        <>
                                            {company.company_name} is currently
                                            on{' '}
                                            {
                                                company.subscription
                                                    .subscription_name
                                            }{' '}
                                            plan (
                                            {company.subscription.package_users}{' '}
                                            seats) ∙
                                            {formatCurrency(
                                                company.subscription.price,
                                                company.subscription
                                                    .currencyCode
                                            )}
                                            /month
                                        </>
                                    )}
                                    {!company.subscription && (
                                        <>
                                            {company.company_name} is currently
                                            not have a subscription{' '}
                                        </>
                                    )}
                                </TextDefault>
                                {company.subscription && !isInactive && (
                                    <FlexDiv ml={12}>
                                        {isDeactivating ? (
                                            <Spinner/>
                                        ) : (
                                            <TextDefault
                                                color={palette.vibrant.red}
                                                bold
                                                onClick={() => onDeactivate()}
                                                link
                                            >
                                                Deactivate
                                            </TextDefault>
                                        )}
                                    </FlexDiv>
                                )}
                            </FlexDiv>
                        )}
                    </Fragment>
                }
                modalContent={
                    <PackageForm
                        companyForm={companyForm}
                        isEdit
                        subscription={company.subscription}
                        subscriptionPlans={subscriptionPlans}
                        loadingSubscriptionsPlans={loadingSubscriptionsPlans}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        estimationLoading={estimationLoading && !isDeactivating}
                    />
                }
                modalFooterContent={
                    addingPackage ? (
                        <ThemedSpinner size={45}/>
                    ) : (
                        <SpaceBetweenDiv gap={16}>
                            <FlexDiv column>
                                <TextTiny lighter bold>
                                    Total
                                </TextTiny>
                                <FlexDiv>
                                    {((!estimationLoading &&
                                                !partnerEstimate) ||
                                            isDeactivating) &&
                                        priceComponent(
                                            company.subscription?.price,
                                            company.subscription?.currencyCode
                                        )}
                                    {estimationLoading && !isDeactivating ? (
                                        <Spinner/>
                                    ) : (
                                        partnerEstimate &&
                                        priceComponent(
                                            partnerEstimate.newCompanyTotal,
                                            partnerEstimate.currencyCode
                                        )
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                            <ThemedButton
                                mode="primary"
                                text="Review and confirm"
                                onClick={() => {
                                    companyForm.submit();
                                }}
                                disabled={
                                    (companyForm && !companyForm.isValid) ||
                                    estimationLoading ||
                                    isDeactivating
                                }
                            />
                        </SpaceBetweenDiv>
                    )
                }
            />
        </Fragment>
    );
}

export default observer(CompanySubscription);
