import React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import CompanyDetailsContainer from './CompanyDetails';
import CompaniesList from './CompaniesList';
import PartnerResourcesContainer from './PartnerResources';
import { Heading1 } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

const Partner = () => {
    const { authStore } = useStores();
    const { partnerId } = useParams();
    let partner = authStore.currentUser.superForAccounts[0];

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Navigate to={partner.id + '/companies'} replace={true} />
                }
            />
            <Route
                path=":partnerId"
                element={<Navigate to={'companies'} replace={true} />}
            />

            <Route
                path=":partnerId/companies/:companyId"
                element={
                    <Container className="manager-content content wide user-table campaigns">
                        <CompanyDetailsContainer />
                    </Container>
                }
            />

            <Route
                path=":partnerId/companies/*"
                element={
                    <Container className="wide campaigns">
                        <CompaniesList />
                    </Container>
                }
            />

            <Route
                path=":partnerId/resources/*"
                element={
                    <Container className="manager-content campaigns wide">
                        <PartnerResourcesContainer />
                    </Container>
                }
            />

            <Route
                path=":partnerId/payments"
                element={
                    <Container className="manager-content list-table">
                        <Heading1 displayBlock mt={24}>
                            Payments
                        </Heading1>
                    </Container>
                }
            />
        </Routes>
    );
};

export default observer(Partner);
