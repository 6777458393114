import React, { useMemo } from 'react';
import { TextTiny, FlexDiv, CheckboxInput } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';

import SemiCircleProgress from '../../../components/manager/assessment/semi-circle-progress';
import { CircularProgress } from '@mui/material';
import TableClient from '../../../components/table/TableClient';

function EmployeesListsTable({
    employeesLists,
    filteredEmployeeLists,
    loadingData,
}) {
    const {
        assessmentStore,
        assessmentEmployeeListsStore,
        assessmentEmployeesStoresFactory,
    } = useStores();

    const { employeesOverviewStore: assessmentEmployeesStore } =
        assessmentEmployeesStoresFactory;
    const rowsPerPage = 5;

    const columns = useMemo(
        () => [
            {
                Header: 'List',
                accessor: 'listName',
                Cell: observer((x) => {
                    return (
                        <CheckboxInput
                            label={x.value}
                            checked={
                                assessmentEmployeeListsStore.selection
                                    .effectiveSelectionOnCurrentPage[
                                    x.row.original.listId
                                ]
                            }
                            noYPadding={true} //Removes Y padding and margin, making it fit better into table row
                            onChange={(e) => {
                                assessmentEmployeeListsStore.selection.toggleRow(
                                    x.row.original,
                                    e.target.checked
                                );
                            }}
                        />
                    );
                }),
            },
            {
                Header: '# Employees',
                accessor: 'employeesCount',
            },
            {
                Header: 'Completion rate',
                accessor: 'completionRate',
                Cell: (x) => x.value + '%',
            },
            {
                Header: 'Average score',
                accessor: 'resilienceScore',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        width={48}
                        top={12}
                        halfCircle
                        percentageText
                        showNA={!x.row.original.started}
                    />
                ),
            },
            {
                Header: ' ',
                accessor: 'listId',
                Cell: (x) => {
                    if (!assessmentStore.editingAssessment.canRemind) return '';
                    return (
                        <FlexDiv alignCenter className="show-on-hover" gap={4}>
                            {assessmentEmployeesStore.loadingEmployeesToBeSentReminders && (
                                <CircularProgress size={20} />
                            )}
                            <TextTiny
                                themed
                                bold
                                link
                                onClick={() =>
                                    assessmentEmployeesStore.showRemindersModal(
                                        false,
                                        'list',
                                        x.value,
                                        x.row.original.listName
                                    )
                                }
                            >
                                Send reminders
                            </TextTiny>
                        </FlexDiv>
                    );
                },
            },
        ],
        [
            assessmentEmployeesStore,
            assessmentStore.editingAssessment.canRemind,
            assessmentEmployeeListsStore.selection,
        ]
    );

    return (
        <TableClient
            columns={columns}
            data={employeesLists}
            defaultSortBy="resilienceScore"
            initialPageSize={rowsPerPage}
            hidePagination={filteredEmployeeLists.length <= rowsPerPage}
            loadingData={loadingData}
        />
    );
}

export default observer(EmployeesListsTable);
