import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
    Block,
    Heading3,
    Heading4,
    SpaceBetweenDiv,
    TextInput,
    palette,
    TextTiny,
    InlineProgressBar,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import { useStores } from '../../../../hooks/use-stores';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import { observer } from 'mobx-react';
import TableClient from '../../../../components/table/TableClient';

function KeyBehavioursTable({
    threatAreas,
    needsFilter,
    companyId,
    assessmentId,
    specifiedThreatAreaByDefault,
    hasStarted,
}) {
    const { assessmentStore, assessmentEmployeesStoresFactory, authStore } =
        useStores();
    const { anonymousTracking } = authStore;

    const { keyBehavioursList, loadingKeyBehavioursList } = assessmentStore;

    const [threatAreaFilter, setThreatAreaFilter] = useState('all');
    const rowsPerPage = 5;
    const threatAreaQueryFilter = useMemo(() => {
        if (specifiedThreatAreaByDefault) return specifiedThreatAreaByDefault;
        if (threatAreaFilter === 'all') return null;
        return threatAreaFilter;
    }, [specifiedThreatAreaByDefault, threatAreaFilter]);
    useEffect(() => {
        assessmentStore.loadKeyBehavioursList(
            companyId,
            assessmentId,
            threatAreaQueryFilter
        );
    }, [companyId, assessmentId, assessmentStore, threatAreaQueryFilter]);

    const showEmployeeScores = useCallback(
        (data) =>
            assessmentEmployeesStoresFactory.showEmployeesModal('users', {
                threatArea: data.threatArea,
                keyBehaviour: data.keyBehaviourCode,
                scoreName: 'Key behaviour score',
                subheading: `Key behaviour: ${data.keyBehaviourName}`,
            }),
        [assessmentEmployeesStoresFactory]
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Key behaviour',
                accessor: 'keyBehaviourName',
                Cell: (x) => <Heading4 bold>{x.value}</Heading4>,
            },
            {
                Header: 'Threat area',
                accessor: 'threatArea',
            },
            {
                Header: 'Coverage',
                accessor: 'coverage',
                Cell: (x) => (!x.value ? 'Not covered' : x.value + '%'),
                tooltip:
                    'Less than 100% coverage means that a Key behavior lacks sufficient number of questions to be considered fully assessed. When the coverage is less than 100%, the behavior score shown may not be accurate.',
            },
            {
                Header: 'Average score',
                accessor: 'averageScore',
                Cell: (x) => {
                    if (!x.row.original.coverage) return '';
                    if (hasStarted) return x.value + '/100';
                    return 'N/A';
                },
            },
            {
                Header: '% of employees at risk',
                accessor: 'usersAtRiskPercentage',
                tooltip: `Showing percentage of employees who completed the assessment and got a score of 50% or below (Risky or Very Risky)`,
                Cell: (x) => {
                    if (!x.row.original.coverage || !x.value) return '';
                    return (
                        <>
                            <InlineProgressBar
                                customColor={palette.vibrant.red}
                                value={x.value}
                                height={6}
                            />
                            <TextTiny bold color={palette.vibrant.red}>
                                {x.value}%
                            </TextTiny>
                        </>
                    );
                },
            },
            {
                Header: ' ',
                Cell: (x) => {
                    return hasStarted ? (
                        <TextTiny
                            themed
                            bold
                            link
                            ml={8}
                            onClick={() => showEmployeeScores(x.row.original)}
                            className="show-on-hover"
                        >
                            View employees scores
                        </TextTiny>
                    ) : null;
                },
                showOnHover: true,
            },
        ],
        [hasStarted, showEmployeeScores]
    );

    return (
        <Block gap={24}>
            <SpaceBetweenDiv>
                <Heading3 ml={16}>
                    Key behaviors{' '}
                    {keyBehavioursList.length > 0
                        ? `(${keyBehavioursList.length})`
                        : ''}
                </Heading3>
                {needsFilter ? (
                    <TextInput
                        select
                        value={threatAreaFilter || 'all'}
                        onChange={(e) => {
                            setThreatAreaFilter(e.target.value);
                        }}
                        inline
                    >
                        <MenuItem value="all">
                            All threat areas ({threatAreas.length})
                        </MenuItem>
                        {threatAreas.map((area, i) => (
                            <MenuItem value={area.name} key={i}>
                                {area.name}
                            </MenuItem>
                        ))}
                    </TextInput>
                ) : (
                    ''
                )}
            </SpaceBetweenDiv>
            {loadingKeyBehavioursList ? (
                <ThemedSpinner />
            ) : (
                <TableClient
                    data={keyBehavioursList}
                    columns={columns}
                    hidePagination={
                        keyBehavioursList &&
                        keyBehavioursList.length < rowsPerPage
                    }
                    defaultSortBy={
                        hasStarted ? 'usersAtRiskPercentage' : 'coverage'
                    }
                    getRowProps={(row) => ({
                        style: {
                            opacity: !row.original.coverage ? 0.4 : 1,
                        },
                    })}
                    initialPageSize={rowsPerPage}
                />
            )}
        </Block>
    );
}

export default observer(KeyBehavioursTable);
