import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import ListsOverviewWithCheck from './select-users-table';
import pluralize from 'pluralize';
import { Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './select-users-step.scss';
import ListEditorModal from '../../../containers/users/modals/ListEditorModal';
import Modal from 'react-modal';
import isEqual from 'lodash/isEqual';
import EmployeesLimitReachedAction from '../../../components/manager/employees-limit-reached-action';
import { Heading1, ThemedButton } from '@awarego/awarego-components';

function SelectUsers({
    hideAddEmployeeModal,
    lists,
    currentCompany,
    item,
    itemString,
    recipientsModal,
    error,
    hideRecipientsModal,
    createRecipientsList,
    disabled,
    showRecipientsModal,
    loadingUsers,
    requestUsersForList,
    selectedLists,
    selectedUserCount,
    onUserListSelected,
    ongoing,
    redirectToUpgrade,
}) {
    // const [activeTab, setActiveTab] = useState("0")
    const [searchValue, setSearchValue] = useState('');

    // const tabsChange = (tab) => {
    // 	if (activeTab !== tab) {
    // 		setActiveTab(tab)
    // 	}
    // }

    const previousEmployees =
        ongoing && itemString === 'assessment'
            ? item._rawData && item._rawData.definition.userLists
            : undefined;

    return (
        <Fragment>
            <Modal
                isOpen={recipientsModal}
                onRequestClose={hideRecipientsModal}
                className="Modal schedule"
            >
                <ListEditorModal
                    result={item}
                    error={error}
                    onRequestClose={hideRecipientsModal}
                    createRecipientsList={createRecipientsList}
                />
            </Modal>
            <div className={'modalHeader'}>
                <Heading1>Select employees</Heading1>
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={hideAddEmployeeModal}
                />
            </div>
            <div className={'modalContent do-not-shrink'}>
                <div className="modalSearch">
                    <TextField
                        label="Search by list"
                        color="primary"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className="action">
                        <span className="alignRight">
                            <ThemedButton
                                disabled={disabled}
                                onClick={showRecipientsModal}
                                mode="secondary"
                                text="Create new list"
                            />
                            <EmployeesLimitReachedAction
                                disabled={disabled}
                                currentCompany={currentCompany}
                                redirectToUpgrade={() => {
                                    redirectToUpgrade();
                                    hideRecipientsModal();
                                }}
                            />
                        </span>
                    </div>
                </div>

                <div className="select-users">
                    <Row>
                        <Col md="12" xs="12" className="content">
                            <ListsOverviewWithCheck
                                lists={lists}
                                loadingUsers={loadingUsers}
                                requestUsersForList={requestUsersForList}
                                selectedLists={selectedLists}
                                selectedUserCount={selectedUserCount}
                                onUserListSelected={onUserListSelected}
                                searchValue={searchValue}
                                ongoing={ongoing}
                                assessment={item}
                                previousEmployees={previousEmployees}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={'modalFooter'}>
                <div className="d-flex flex-fill flex-column">
                    <Typography>
                        {selectedLists.length > 0
                            ? `${selectedLists.length} ${pluralize(
                                  'list',
                                  selectedLists.length
                              )} selected`
                            : ''}
                    </Typography>
                    <Typography
                        color={selectedUserCount > 0 ? 'primary' : undefined}
                    >
                        {selectedUserCount}{' '}
                        {pluralize('employee', selectedUserCount)} selected
                    </Typography>
                </div>
                <ThemedButton
                    mode="primary"
                    disabled={
                        ongoing &&
                        isEqual(
                            previousEmployees.slice().sort(),
                            item.selectedUserListsIds.slice().sort()
                        )
                    }
                    onClick={hideAddEmployeeModal}
                    text="Confirm"
                />
            </div>
        </Fragment>
    );
}

export default observer(SelectUsers);
