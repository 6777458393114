import React, { useEffect, Fragment, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import AssessmentListTable from './AssessmentListTable';
import ScormAssessmentListTable from './ScormAssessmentListTable';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import { useStores } from '../../../hooks/use-stores';
import Modal from 'react-modal';
import FilterModal from '../../../components/manager/filter-modal';
import { Link } from 'react-router-dom';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../../components/themed/ThemedComponents';
import { MenuItem } from '@mui/material';
import './AssessmentList.scss';
import Snackbar from '../../../components/Snackbar';
import mapValues from 'lodash/mapValues';
import ErrorMessage from '../../../components/errormessage';
import NoItemsYet from '../../../components/manager/no-items-yet';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    TextInput,
    Heading3,
    ThemedButton,
    DropdownThemedButton,
    palette,
    TextTiny,
} from '@awarego/awarego-components';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import RequireRole from '../../RequireRole';
import HeaderSection from '../../../components/header/HeaderSection';

function AssessmentList({ companyId, tab = 'all' }) {
    const { assessmentStore, store, listStore, commonStore, cmi5Store } =
        useStores();
    const [filterModal, setFilterModal] = useState(false);

    const {
        loadingAssessments,
        assessments,
        searchValue,
        areaFilter,
        employeeFilter,
        sortBy,
        filteredAssessments,
        isBothAssessmentTypes,
        isScormOnly,
        scormAssessments,
        filteredScormAssessments,
        startDueDateFilter,
        endDueDateFilter,
        startDeliveryDateFilter,
        endDeliveryDateFilter,
        error,
        threatAreaDefinitions,
        demoAssessmentURL,
    } = assessmentStore;
    const { cmi5enabled } = store;
    const { listsWithUsers: lists } = listStore;

    const [message, setMesage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);

    useEffect(() => {
        assessmentStore.loadAssessments(companyId);
        listStore.loadCompanyLists(companyId);
    }, [assessmentStore, companyId, listStore]);

    const filterCount = [
        areaFilter.length ? 1 : 0,
        employeeFilter.length ? 1 : 0,
        startDueDateFilter !== null && endDueDateFilter !== null ? 1 : 0,
        startDeliveryDateFilter !== null && endDeliveryDateFilter !== null
            ? 1
            : 0,
    ].reduce((a, b) => a + b);

    const clearFilters = () => {
        assessmentStore.setAreaFilter([]);
        assessmentStore.setEmployeeFilter([]);
        assessmentStore.setStartDueDateFilter(null);
        assessmentStore.setEndDueDateFilter(null);
        assessmentStore.setStartDeliveryDateFilter(null);
        assessmentStore.setEndDeliveryDateFilter(null);
    };

    const hideFilterModal = () => {
        setFilterModal(false);
    };

    const showFilterModal = () => {
        setFilterModal(true);
    };

    const onDelete = (id, isScorm) => {
        commonStore.showConfirm(
            'Assessment will be deleted forever.',
            'Yes, delete',
            'Delete this assessment?',
            async () => {
                const result = await assessmentStore.delete(
                    companyId,
                    isScorm ? `p${id}` : id
                );
                if (result) showSnackBar('Assessment deleted!');
            },
            true
        );
    };

    const onDownloadPackage = (id, uniqueId, type) => {
        cmi5Store.downloadZIP(companyId, id, uniqueId, type);
    };

    const onPause = (id) => {
        commonStore.showConfirm(
            'Are you sure you want to stop this assessment?',
            'Stop',
            'Stop assessment',
            async () => {
                await pauseAssessment(id);
                showSnackBar('Assessment paused!');
            },
            false
        );
    };

    const pauseAssessment = async (id) => {
        await assessmentStore.pauseAssessment(companyId, id);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMesage(message);
        setMessageType(messageType);
        setMessageShown(true);
    };

    const closeSnackbar = () => {
        setMessageShown(false);
        setMesage(null);
        setMessageType(null);
    };

    const tabs = useMemo(() => {
        return [
            {
                code: 'all',
                title: 'All',
            },
            {
                code: 'active',
                title: 'Active',
            },
            {
                code: 'completed',
                title: 'Completed',
            },
            {
                code: 'draft',
                title: 'Draft',
                visible: store.isAdmin,
            },
        ];
    }, [store.isAdmin]);

    const visibleTabs = useMemo(() => {
        return tabs.filter((tab) => tab.visible !== false);
    }, [tabs]);

    const sortOptions = [
        {
            value: '_rawData.last_updated',
            text: 'Last updated',
        },
        {
            value: 'deliveryDate',
            text: 'Delivery date',
        },
        {
            value: '_rawData.due',
            text: 'Due date',
        },
    ];

    const addHeaderSection = () => {
        return (
            <HeaderSection
                heading={'Assessments'}
                searchPlaceholder={'Search by name'}
                searchValue={searchValue || ''}
                onSearch={(e) => assessmentStore.setSearchValue(e.target.value)}
                onClearSearch={() => assessmentStore.setSearchValue('')}
                onShowFilter={showFilterModal}
                filterCount={filterCount}
                onClearFilters={() => {
                    clearFilters();
                }}
                buttons={CTAbtn}
            />
        );
    };

    const CTAbtn = useMemo(() => {
        if (!cmi5enabled)
            return (
                <ThemedButton
                    mode="primary"
                    text="Create new"
                    as={Link}
                    to="/assessment/create"
                    $center
                />
            );
        return (
            <DropdownThemedButton
                text="Create new"
                btnProps={{ mode: 'primary', $center: true }}
                items={[
                    {
                        text: 'Assessment',
                        props: {
                            component: Link,
                            to: '/assessment/create',
                        },
                    },
                    {
                        text: 'Assessment (SCORM, cmi5)',
                        props: {
                            component: Link,
                            to: '/assessment/create_scorm',
                        },
                    },
                ]}
            />
        );
    }, [cmi5enabled]);

    const getTabCount = (t) => {
        if (t.code === 'all' && isBothAssessmentTypes) return;
        const count = assessmentStore[`${t.code}Tab`].length;
        const badge = (
            <TextTiny
                bold
                padding={4}
                backgroundColor={palette.graphite.gray}
                color={palette.graphite.charcoal}
                ml={8}
            >
                {count}
            </TextTiny>
        );

        return badge;
    };

    return loadingAssessments ? (
        <>
            {addHeaderSection()}
            <ThemedSpinner />
        </>
    ) : (
        <Fragment>
            <ErrorMessage error={error} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                variant={messageType}
                message={message || ''}
                open={messageShown}
                onClose={closeSnackbar}
            />
            {/* We should move this to specific component and use <Outlet/> here */}
            {assessments.length > 0 && (
                <Fragment>
                    {addHeaderSection()}
                    {!isScormOnly && (
                        <Block gap={32} tablePadding>
                            {isBothAssessmentTypes && (
                                <Heading3 ml={16}>
                                    Regular assessments (
                                    {filteredAssessments.length})
                                </Heading3>
                            )}

                            <SpaceBetweenDiv positionRelative ml={16} pr={32}>
                                <ThemedNav clean>
                                    {visibleTabs.map((t) => (
                                        <ThemedNavItem
                                            active={tab === t.code}
                                            key={t.code}
                                        >
                                            <ThemedNavLink
                                                to={`/assessment/${t.code}`}
                                                as={Link}
                                            >
                                                <FlexDiv alignCenter>
                                                    {t.title} {getTabCount(t)}
                                                </FlexDiv>
                                            </ThemedNavLink>
                                        </ThemedNavItem>
                                    ))}
                                </ThemedNav>
                                <FlexDiv alignCenter gap={8}>
                                    <TextDefault lighter>Sort by:</TextDefault>
                                    <TextInput
                                        select
                                        inline
                                        value={sortBy}
                                        onChange={(e) => {
                                            assessmentStore.setSortBy(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {sortOptions.map((p) => (
                                            <MenuItem
                                                key={p.value}
                                                value={p.value}
                                            >
                                                {p.text}
                                            </MenuItem>
                                        ))}
                                    </TextInput>
                                </FlexDiv>
                            </SpaceBetweenDiv>
                            {assessmentStore[`${tab}Tab`].length > 0 && (
                                <AssessmentListTable
                                    assessments={[
                                        ...assessmentStore[`${tab}Tab`],
                                    ]}
                                    onDelete={onDelete}
                                    pauseAssessment={onPause}
                                    lists={lists}
                                    defaultSortBy={sortBy}
                                />
                            )}
                            {assessmentStore[`${tab}Tab`].length === 0 && (
                                <NoItemsYetContent
                                    img={'/static/img/icon-booklist.svg'}
                                    imgAlt={'No assessments found'}
                                    title={'No assessments found'}
                                    text={
                                        'Try adjusting your search or filter to find what you were looking for.'
                                    }
                                />
                            )}
                        </Block>
                    )}
                </Fragment>
            )}
            {scormAssessments.length > 0 && (
                <ScormAssessmentListTable
                    assessments={filteredScormAssessments}
                    onDelete={onDelete}
                    onDownloadPackage={onDownloadPackage}
                    defaultSortBy={sortBy}
                    isPackageDownloading={cmi5Store.isZIPDownloading}
                    isBothAssessmentTypes={isBothAssessmentTypes}
                />
            )}
            {assessments.length === 0 && (
                <NoItemsYet
                    img={'/static/img/assessment-empty.svg'}
                    imgAlt={'sneak peak into assessment'}
                    title={'No assessments yet'}
                    text={
                        'Create a new assessment to measure and track employees’ cybersecurity knowledge and behavior.'
                    }
                    heading={'Assessments'}
                    buttons={
                        <FlexDiv gap={24}>
                            <RequireRole>{CTAbtn}</RequireRole>
                            <ThemedButton
                                as="a"
                                href={demoAssessmentURL}
                                target="_blank"
                                rel="noreferrer"
                                mode="secondary"
                                text="Try demo"
                            />
                        </FlexDiv>
                    }
                />
            )}
            <Modal
                isOpen={filterModal}
                onRequestClose={hideFilterModal}
                className="filter-modal"
                ariaHideApp={false}
            >
                <FilterModal
                    onRequestClose={hideFilterModal}
                    resultCount={
                        assessmentStore[`${tab}Tab`].length +
                        filteredScormAssessments.length
                    }
                    nameFilter1={'Threat areas'}
                    array1={Object.values(
                        mapValues(threatAreaDefinitions, 'name')
                    )}
                    filter1={areaFilter.slice(0)}
                    setFilter1={(value) => {
                        assessmentStore.setAreaFilter(value);
                    }}
                    isAreaFilter1={true}
                    nameFilter2={'Employee lists'}
                    array2={lists}
                    filter2={employeeFilter.slice(0)}
                    setFilter2={(value) => {
                        assessmentStore.setEmployeeFilter(value);
                    }}
                    nameDateFilter1={'Delivery date'}
                    startDateFilter1={startDeliveryDateFilter}
                    setStartDateFilter1={(value) => {
                        let deliveryDateFromTimeFixed = value?.set({
                            h: 0,
                            m: 0,
                        });
                        assessmentStore.setStartDeliveryDateFilter(
                            deliveryDateFromTimeFixed
                                ? deliveryDateFromTimeFixed
                                : value
                        );
                    }}
                    endDateFilter1={endDeliveryDateFilter}
                    setEndDateFilter1={(value) => {
                        let deliveryDateToTimeFixed = value?.set({
                            h: 23,
                            m: 59,
                        });
                        assessmentStore.setEndDeliveryDateFilter(
                            deliveryDateToTimeFixed
                                ? deliveryDateToTimeFixed
                                : value
                        );
                    }}
                    nameDateFilter2={'Due date'}
                    startDateFilter2={startDueDateFilter}
                    setStartDateFilter2={(value) => {
                        // expl: date picker does not have time setter, current time is set by default
                        // which filters out assessments before that time. To fix it, time is set manually
                        let dueDateFromTimeFixed = value?.set({ h: 0, m: 0 });
                        assessmentStore.setStartDueDateFilter(
                            dueDateFromTimeFixed ? dueDateFromTimeFixed : value
                        );
                    }}
                    endDateFilter2={endDueDateFilter}
                    setEndDateFilter2={(value) => {
                        let dueDateToTimeFixed = value?.set({ h: 23, m: 59 });
                        assessmentStore.setEndDueDateFilter(
                            dueDateToTimeFixed ? dueDateToTimeFixed : value
                        );
                    }}
                    filterCount={filterCount}
                    clearFilters={clearFilters}
                    hideDateFilters={isScormOnly}
                />
            </Modal>
        </Fragment>
    );
}

export default observer(AssessmentList);
