import React, { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../themed/ThemedSpinner';
import { FlexDiv, Heading2, Heading3 } from '@awarego/awarego-components';
import SubjectRow from './subject-row';

function SubjectSelect({
    catalog,
    onVideoSelected,
    selection = [],
    showPreviewSubjectModal,
    category,
    isTrial,
}) {
    const previewVideo = (subjectId) => {
        showPreviewSubjectModal(subjectId);
    };

    const catalogWithoutEmptyCategories = useMemo(
        () => catalog.filter((c) => c.subjects.length > 0),
        [catalog]
    );

    const displayedSubjects = useMemo(() => {
        if (category && category !== 'all') {
            return [catalog.find((c) => c.name === category)];
        } else {
            return catalogWithoutEmptyCategories;
        }
    }, [category, catalog]);

    const subjectRow = (subject) => {
        return (
            <SubjectRow
                onVideoSelected={onVideoSelected}
                selection={selection}
                subject={subject}
                previewVideo={previewVideo}
                key={subject.id}
                isTrial={isTrial}
            />
        );
    };

    return (
        <Fragment>
            {!catalog ? (
                <ThemedSpinner />
            ) : catalog.length === 0 ||
              catalogWithoutEmptyCategories.length === 0 ? (
                <div className="no-assessments-found">
                    <img
                        src="/static/img/icon-booklist.svg"
                        alt="No subjects found"
                    />
                    <div className="stats">
                        <Heading3 displayBlock mt={16} mb={8}>
                            No subjects found
                        </Heading3>
                        <p>
                            Try adjusting the filters to find what you were
                            looking for.
                        </p>
                    </div>
                </div>
            ) : displayedSubjects ? (
                displayedSubjects.map((c) => (
                    <FlexDiv column key={c.id} mb={24}>
                        <Heading2 mb={16}>
                            {c.name || c.threatAreaName}
                        </Heading2>
                        {c &&
                            c.subjects &&
                            c.subjects.map((subject) => subjectRow(subject))}
                    </FlexDiv>
                ))
            ) : null}
        </Fragment>
    );
}

export default observer(SubjectSelect);
