import React from 'react';
import { observer } from 'mobx-react';
import { CleanHR } from '@awarego/awarego-components';
import ReportSetupFormFragment from '../ReportSetupFormFragment';
import ReportRecipientsFormFragment from '../ReportRecipientsFormFragment';

function ReportSnapshotForm({ reportsForm, lists }) {
    const inputHasError = (input) =>
        reportsForm.$(input).error && reportsForm.$(input).error.length > 0;

    return (
        <>
            <ReportSetupFormFragment
                reportsForm={reportsForm}
                lists={lists}
                inputHasError={inputHasError}
            />
            <CleanHR my={32} />
            <ReportRecipientsFormFragment
                reportsForm={reportsForm}
                inputHasError={inputHasError}
            />
        </>
    );
}

export default observer(ReportSnapshotForm);
