import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import {
    FlexDiv,
    Heading1,
    SpaceBetweenDiv,
    TextLead,
} from '@awarego/awarego-components';
import DoneIcon from '@mui/icons-material/Done';

const ResponsiveColumns = styled.div`
    column-count: 3;
    width: 100%;
    @media (max-width: 992px) {
        column-count: 2;
    }
    @media (max-width: 576px) {
        column-count: 1;
    }
`;

const allPlansDefinition = [
    'Dashboards and reports',
    'User groups',
    'User progress tracking',
    'Scheduled delivery',
    'Reminders',
    'Email support',
    'Support for <b>18 languages</b>',
    'Cross-platform support',
];

function AllPlansInclude() {
    return (
        <Fragment>
            <Heading1 mb={32} displayBlock>
                All plans include:
            </Heading1>
            <SpaceBetweenDiv>
                <ResponsiveColumns>
                    {allPlansDefinition.map((text, i) => (
                        <FlexDiv alignCenter gap={8} key={i} mb={24} $noWrap>
                            <DoneIcon fontSize="small" />
                            <TextLead>
                                <Trans components={{ b: <b /> }}>{text}</Trans>
                            </TextLead>
                        </FlexDiv>
                    ))}
                </ResponsiveColumns>
            </SpaceBetweenDiv>
        </Fragment>
    );
}

export default AllPlansInclude;
