import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Heading1, TextDefault, FlexDiv } from '@awarego/awarego-components';
import styles from './summary.module.scss';

function Summary({
    mainNumberText,
    mainNumber,
    secondaryNumberText,
    secondaryNumber,
    iconUrl,
    iconAlt,
    item,
}) {
    const tab = mainNumberText.toLowerCase();
    return (
        <Link
            to={`/${item}/${tab}`}
            className={styles['summary']}
            style={{ marginRight: '16px', marginLeft: '16px' }}
        >
            <div
                className={classnames(
                    styles['summary-section'],
                    styles['summary-section-border-bottom']
                )}
            >
                <FlexDiv column>
                    <TextDefault>{mainNumberText}</TextDefault>
                    <Heading1>{mainNumber}</Heading1>
                </FlexDiv>
                <img src={iconUrl} alt={iconAlt} />
            </div>
            <div className={styles['summary-section']}>
                <TextDefault lighter>{secondaryNumberText}</TextDefault>
                <div className={styles['summary-section-line']}>
                    <img src="/static/img/icon-person.svg" alt="person" />
                    <TextDefault bold>{secondaryNumber}</TextDefault>
                </div>
            </div>
        </Link>
    );
}

export default Summary;
