import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import UserDetails from './UserDetails';
import { useStores } from '../../../hooks/use-stores';

function UserDetailsContainer() {
    const { email } = useParams();
    const { authStore } = useStores();
    const { anonymousTracking } = authStore;

    return (
        <Fragment>
            <Link to={`/users`}>
                <div className={'navigation back-button'}>
                    Back to employees
                </div>
            </Link>
            <UserDetails email={email} anonymousTracking={anonymousTracking} />
        </Fragment>
    );
}

export default observer(UserDetailsContainer);
