import React, { Fragment } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ProfileSettingsContainer from './ProfileSettings';
import IntegrationsSettingsContainer from './IntegrationsSettings';
import CompanySettingsContainer from './CompanySettings';
import AdminsSettingsContainer from './AdminsSettings';
import EmailSettingsContainer from './EmailSettings';
import SubscriptionsSettingsContainer from './SubscriptionSettings';
import ThemedLinkStep from '../../components/themed/ThemedLinkStep';
import CompanySlack from '../companies/CompanySlack';
import CompanyAzureAd from '../companies/CompanyAzureAd';
import CompanyAzureSSO from '../companies/CompanyAzureSSO';
import CompanyGoogle from '../companies/CompanyGoogle';
import { Helmet } from 'react-helmet';
import classes from 'classnames';
import CompanyTeams from '../companies/CompanyTeams';
import { useStores } from '../../hooks/use-stores';
import APIKeys from './APIKeys';
import { Heading2 } from '@awarego/awarego-components';
import RoleRoute from '../../hocs/RoleRoute';
import RequireRole from '../RequireRole';
import CompanyVanta from '../companies/CompanyVanta';
import CompanyVantaSync from '../companies/CompanyVantaSync';

export default ({ companyId }) => {
    const location = useLocation();
    const { store } = useStores();
    const { currentCompany, currentCompanyUISettings } = store;
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);

    const showLessonMenu = () => {
        setShowMobileMenu(true);
    };
    const hideLessonMenu = () => {
        setShowMobileMenu(false);
    };

    const currentCompanyHasSetting = (key) => {
        return (
            currentCompanyUISettings[key] &&
            currentCompanyUISettings[key] === '1'
        );
    };

    return (
        <Fragment>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <div className="d-flex">
                <div
                    className={classes('sidebar-mobile-menu ', {
                        show: !showMobileMenu,
                    })}
                    onClick={() => showLessonMenu()}
                ></div>
                <div
                    className={classes('sidebar-mobile-menu close', {
                        show: showMobileMenu,
                    })}
                    onClick={() => hideLessonMenu()}
                ></div>

                <div
                    className={classes(
                        'block sidebar-steps sidebar sidebar-links ',
                        { open: showMobileMenu }
                    )}
                    onClick={() => hideLessonMenu()}
                >
                    <div className="sidebar-inner">
                        <Heading2
                            ml={34}
                            mb={16}
                            mt={showMobileMenu ? 16 : 8}
                            displayBlock
                        >
                            Settings
                        </Heading2>
                        <ThemedLinkStep
                            active={
                                location.pathname === '/settings' ||
                                location.pathname === '/settings/profile'
                                    ? 'active'
                                    : 0
                            }
                            to="/settings"
                            text="My profile"
                        />
                        <RequireRole>
                            <hr />
                            <ThemedLinkStep
                                active={
                                    location.pathname === '/settings/company'
                                        ? 'active'
                                        : 0
                                }
                                to="company"
                                text="Company settings"
                            />
                            <ThemedLinkStep
                                active={
                                    location.pathname ===
                                    '/settings/subscriptions'
                                        ? 'active'
                                        : 0
                                }
                                to="subscriptions"
                                text="Subscription"
                            />
                            <ThemedLinkStep
                                active={
                                    location.pathname === '/settings/admins'
                                        ? 'active'
                                        : 0
                                }
                                to="admins"
                                text="Admins"
                            />
                            <ThemedLinkStep
                                active={
                                    location.pathname.startsWith(
                                        '/settings/email'
                                    )
                                        ? 'active'
                                        : 0
                                }
                                to="email"
                                text="Email settings"
                            />
                            <ThemedLinkStep
                                active={
                                    location.pathname.startsWith(
                                        '/settings/integrations'
                                    )
                                        ? 'active'
                                        : 0
                                }
                                to="integrations"
                                text="Integrations"
                            />

                            {currentCompany &&
                                !currentCompanyUISettings[
                                    'ui.api_keys_hidden'
                                ] && (
                                    <ThemedLinkStep
                                        active={
                                            location.pathname.startsWith(
                                                '/settings/apikeys'
                                            )
                                                ? 'active'
                                                : 0
                                        }
                                        to="apikeys"
                                        text="API Keys"
                                    />
                                )}
                        </RequireRole>
                    </div>
                </div>
                <div className="d-flex flex-column fullwidth">
                    <div>
                        <Row>
                            <Col md="12" xs="12">
                                <Routes>
                                    <Route path="/">
                                        <Route
                                            index
                                            element={
                                                <ProfileSettingsContainer />
                                            }
                                        />
                                        <Route
                                            path="company"
                                            element={
                                                <RoleRoute>
                                                    <CompanySettingsContainer />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="subscriptions"
                                            element={
                                                <RoleRoute>
                                                    <SubscriptionsSettingsContainer />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="admins"
                                            element={
                                                <RoleRoute>
                                                    <AdminsSettingsContainer />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="email"
                                            element={
                                                <RoleRoute>
                                                    <EmailSettingsContainer
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        {currentCompany &&
                                            !currentCompanyHasSetting(
                                                'ui.api_keys_hidden'
                                            ) && (
                                                <Route
                                                    path="apikeys"
                                                    element={
                                                        <RoleRoute>
                                                            <APIKeys />
                                                        </RoleRoute>
                                                    }
                                                />
                                            )}
                                        <Route
                                            path="integrations"
                                            exact
                                            element={
                                                <RoleRoute>
                                                    <IntegrationsSettingsContainer
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/slack"
                                            element={
                                                <RoleRoute>
                                                    <CompanySlack
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/teams"
                                            element={
                                                <RoleRoute>
                                                    <CompanyTeams
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/google"
                                            element={
                                                <RoleRoute>
                                                    <CompanyGoogle
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/azure"
                                            element={
                                                <RoleRoute>
                                                    <CompanyAzureAd
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/vanta"
                                            element={
                                                <RoleRoute>
                                                    <CompanyVanta
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/vantasync"
                                            element={
                                                <RoleRoute>
                                                    <CompanyVantaSync
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                        <Route
                                            path="integrations/azuresso"
                                            element={
                                                <RoleRoute>
                                                    <CompanyAzureSSO
                                                        companyId={companyId}
                                                    />
                                                </RoleRoute>
                                            }
                                        />
                                    </Route>
                                </Routes>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
