import RestService from './RestService';

export default class Packages extends RestService {
    constructor() {
        super('/freemiums');
    }

    updateVideos(freemiumCode, videos) {
        return this.post(`${this.baseUrl}/${freemiumCode}/videos`, { videos });
    }

    resetCounter(freemiumCode) {
        return this.post(`${this.baseUrl}/${freemiumCode}/resetCounter`);
    }
}
