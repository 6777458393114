import React, { useState } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import ErrorMessage from '../../errormessage';
import { ThemedRadiobutton } from '../../themed/ThemedComponents';
import {
    CheckboxInput,
    Heading1,
    TextInput,
    ThemedButton,
} from '@awarego/awarego-components';
import { AUTOMATION_SCHEDULE_TYPE } from '../../../utils/constants';
import { useStores } from '../../../hooks/use-stores';

function PreviewEmail({
    ownEmail,
    scheduleType,
    result,
    interval,
    sendError,
    onPreviewSend,
    onRequestClose,
}) {
    const [targetEmail, setTargetEmail] = useState(ownEmail);
    const [sendingPreview, setSendingPreview] = useState(false);
    const [previewType, setPreviewType] = useState(
        scheduleType === AUTOMATION_SCHEDULE_TYPE.NOW ? 1 : 0
    );
    const [selectedEvents, setSelectedEvents] = useState([]);
    const { commonStore } = useStores();

    const onChangeEmail = (e) => {
        setTargetEmail(e.target.value);
    };

    const onChangePreviewType = (value) => {
        setPreviewType(value);
    };

    const toggleEvent = (value) => {
        var events = selectedEvents.slice(0);
        if (events.includes(value)) events = events.filter((x) => x !== value);
        else events.push(value);

        setSelectedEvents(events);
    };

    const handleOnPreviewSend = () => {
        setSendingPreview(true);
        onPreviewSend(targetEmail, previewType, selectedEvents)
            .then(() => {
                setSendingPreview(false);
            })
            .catch((error) => {
                commonStore.error('There was an error with sending previews');
                setSendingPreview(false);
            });
    };

    let period = interval === 'M' ? 'Month' : 'Week';

    if (result) {
        return (
            <div className="modalWindow simple">
                <div className={'modalHeader plain'}>
                    <Heading1>Send a preview</Heading1>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent'}>
                    <div className={'d-flex bd-highlight mb-3 '}>
                        <div>
                            <label
                                htmlFor="email"
                                className={
                                    previewType === 1
                                        ? 'container-radiobutton checked'
                                        : 'container-radiobutton'
                                }
                                onClick={() => {
                                    onChangePreviewType(1);
                                }}
                            >
                                All training
                                <input
                                    type="radio"
                                    name="active"
                                    id="typeNow"
                                    className="styled-radiobutton"
                                    value={1}
                                    checked={previewType === 1}
                                    onChange={() => {
                                        onChangePreviewType(1);
                                    }}
                                />
                                <ThemedRadiobutton
                                    {...{
                                        checked: previewType === 1,
                                    }}
                                />
                            </label>
                        </div>
                        {scheduleType !== 1 && (
                            <div>
                                <label
                                    htmlFor="email"
                                    className={
                                        previewType === 2
                                            ? 'ml20 container-radiobutton checked'
                                            : ' ml20 container-radiobutton'
                                    }
                                    onClick={() => {
                                        onChangePreviewType(2);
                                    }}
                                >
                                    Specific training
                                    <input
                                        type="radio"
                                        disabled={
                                            scheduleType ===
                                            AUTOMATION_SCHEDULE_TYPE.NOW
                                        }
                                        name="active"
                                        id="typeSchedule"
                                        className="styled-radiobutton"
                                        value={2}
                                        checked={previewType === 2}
                                        onChange={() => {
                                            onChangePreviewType(2);
                                        }}
                                    />
                                    <ThemedRadiobutton
                                        {...{
                                            checked: previewType === 2,
                                        }}
                                    />
                                </label>
                            </div>
                        )}
                    </div>
                    {previewType === 2 && (
                        <table className="simple-clear-table">
                            <tbody>
                                {result.map((event) => (
                                    <tr key={event.tempId}>
                                        <td>
                                            <CheckboxInput
                                                checked={selectedEvents.includes(
                                                    event.tempId
                                                )}
                                                noYPadding
                                                onChange={() => {
                                                    toggleEvent(event.tempId);
                                                }}
                                                label={`${period} ${
                                                    event.tempId === 0
                                                        ? event.tempId + 1
                                                        : interval === 'B'
                                                        ? 2 * event.tempId + 1
                                                        : event.tempId + 1
                                                }`}
                                            />
                                        </td>
                                        <td>
                                            {event.subjects.length}{' '}
                                            {pluralize(
                                                'video',
                                                event.subjects.length
                                            )}
                                        </td>
                                        <td className="last-column">
                                            {event.subjects
                                                .map((s) => s.title)
                                                .join(', ')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <ErrorMessage error={sendError} />
                    <br />
                    <TextInput
                        type="email"
                        label="Recipients email address"
                        required
                        stretched
                        value={targetEmail}
                        onChange={onChangeEmail}
                        helperText="Use commas to add recipients"
                    />
                </div>
                <div className={'modalFooter'}>
                    {sendingPreview ? (
                        <ThemedButton
                            mode="primary"
                            type="button"
                            disabled
                            $saving={true}
                            text="Sending"
                            $center={true}
                        />
                    ) : (
                        <ThemedButton
                            mode="primary"
                            text="Send"
                            onClick={handleOnPreviewSend}
                            disabled={
                                !(
                                    targetEmail !== '' &&
                                    (previewType === 1 ||
                                        previewType === 3 ||
                                        selectedEvents.length > 0)
                                )
                            }
                        />
                    )}
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default observer(PreviewEmail);
