import withStyles from '@mui/styles/withStyles';
import { Chip } from '@mui/material';
import { palette } from '@awarego/awarego-components';

const NumberChip = withStyles(() => ({
    root: {
        margin: '0 12px 0 0',
    },
    sizeSmall: {
        backgroundColor: `rgba(32, 162, 107, 0.1)`,
        width: '24px',
        height: '24px',
    },
    label: {
        fontSize: '12px',
        fontWeight: 800,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: `${palette.evergreen.aspargus}!important`,
    },
}))(Chip);

export default NumberChip;
