import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
    FlexDiv,
    palette,
    SpaceBetweenDiv,
    StatusBadge,
    TextLead,
    TextTiny,
} from '@awarego/awarego-components';
import { TextField, FormControl, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ThemedSpinner from '../../themed/ThemedSpinner';
function WizardHeader({
    selectedTemplate,
    isStartStep,
    openSaveChangesModal,
    item,
    onChangeName,
    itemString,
    initiateSavingProgress,
    isNameUntitled,
    draftAllowed = true,
}) {
    const navigate = useNavigate();
    const saveButtonText = item.id != null ? 'Save and update' : 'Save draft';
    const isSaveButtonEnabled = selectedTemplate && selectedTemplate.length > 0;
    const [savingInProgress, setSavingInProgress] = useState(false);

    // TextField with fit-content input width
    const FONT_SIZE = 9;
    const DEFAULT_INPUT_WIDTH = 200;
    const [textValue, setTextValue] = useState('');
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

    const useStyles = makeStyles(() => ({
        textField: {
            margin: '0px',
        },
        cssOutlinedInput: {
            padding: '0px',
            '&$cssFocused $notchedOutline': {
                borderColor: `${palette.evergreen.aspargus} !important`,
            },
        },

        cssFocused: {},

        notchedOutline: {
            borderWidth: '1px',
            borderColor: 'white !important',
        },

        resize: {
            fontSize: '17px!important',
            paddingBottom: '0px!important',
            paddingLeft: '2px!important',
            paddingRight: '0px!important',
            paddingTop: '0px!important',
        },
    }));

    const classes = useStyles();

    // TextField with fit-content input width
    useEffect(() => {
        if (textValue.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
            setInputWidth((textValue.length + 3) * FONT_SIZE);
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH);
        }
    }, [textValue]);

    const handleSaveButtonClick = () => {
        if (isNameUntitled && isSaveButtonEnabled) {
            initiateSavingProgress();
            return;
        }

        if (!isNameUntitled && isSaveButtonEnabled) {
            setSavingInProgress(true);
            initiateSavingProgress().finally(() => {
                setSavingInProgress(false);
            });
        }
    };

    return (
        <div className={'training-wizard-header'}>
            <img
                src="/static/img/close.svg"
                alt="Close"
                className={'training-wizard-close'}
                onClick={() => {
                    if (
                        selectedTemplate &&
                        selectedTemplate.length > 0 &&
                        !isStartStep
                    ) {
                        openSaveChangesModal();
                    } else {
                        navigate(
                            itemString === 'training'
                                ? '/trainings'
                                : '/assessment'
                        );
                    }
                }}
            />
            <div className="assessment-wizard-header">
                <FlexDiv>
                    <FormControl>
                        <SpaceBetweenDiv>
                            <TextField
                                id="create-training-name"
                                variant="outlined"
                                margin="normal"
                                className={classes.textField}
                                disabled={isStartStep}
                                defaultValue={item.name}
                                onChange={(e) => {
                                    setTextValue(e.target.value);
                                    onChangeName(e);
                                }}
                                InputProps={{
                                    style: {
                                        fontWeight: 800,
                                        color: !isNameUntitled
                                            ? palette.graphite.heavyMetal
                                            : palette.lightroom.heavyMetalLight,
                                        textAlign: 'center',
                                        backgroundColor: 'white',
                                        marginBottom: 0,
                                        width: `${inputWidth}px`,
                                    },
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.resize,
                                    },
                                    startAdornment: (
                                        <Fragment>
                                            {!isStartStep && (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ paddingLeft: '2px' }}
                                                >
                                                    <EditIcon
                                                        fontSize="small"
                                                        style={{
                                                            cursor: 'default',
                                                        }}
                                                        className="edit-icon"
                                                    />
                                                </InputAdornment>
                                            )}
                                        </Fragment>
                                    ),
                                }}
                            />
                        </SpaceBetweenDiv>
                    </FormControl>
                </FlexDiv>
                {item.id != null ? (
                    <StatusBadge
                        color={item.statusInfo.color}
                        text={item.statusInfo.label}
                    />
                ) : (
                    <TextTiny color={palette.graphite.charcoal}>
                        Creating new {itemString}
                    </TextTiny>
                )}
            </div>
            {/* Creating draft for Scorm is not allowed  */}
            {(draftAllowed || (!isStartStep && draftAllowed)) && (
                <FlexDiv className="right">
                    {!savingInProgress ? (
                        <TextLead
                            themed
                            bold
                            link={isSaveButtonEnabled}
                            lighter={!isSaveButtonEnabled}
                            onClick={handleSaveButtonClick}
                        >
                            {saveButtonText}
                        </TextLead>
                    ) : (
                        <ThemedSpinner size={35} />
                    )}
                </FlexDiv>
            )}
        </div>
    );
}

export default WizardHeader;
