import { makeAutoObservable } from 'mobx';
import services from '../services';

class MailStore {
    mailDetails = undefined;
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async loadMailLog(storeUrl) {
        const result = await services.Mails.loadMailDetails(storeUrl);
        this.mailDetails = result;
    }
}

export default MailStore;
