import React, { useEffect } from 'react';
import {
    Block,
    SpaceBetweenDiv,
    Heading3,
    FlexDiv,
    Heading4,
} from '@awarego/awarego-components';
import { useStores } from '../../../../hooks/use-stores';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import { replaceSpaceWithUnderscore } from '../../../../utils/helpers';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import RiskInsight from '../../../../components/manager/assessment/riskInsight';

function RiskInsights({
    showReviewQuestionsModal,
    companyId,
    assessmentId,
    threatAreaCount,
    hasStarted,
}) {
    const { assessmentStore, assessmentEmployeesStoresFactory } = useStores();

    let navigate = useNavigate();

    useEffect(() => {
        hasStarted && assessmentStore.loadRiskInsights(companyId, assessmentId);
    }, [hasStarted, companyId, assessmentId, assessmentStore]);

    const { riskInsightsSorted, loadingRiskInsights } = assessmentStore;

    const onAction = (insight) => {
        switch (insight.type) {
            case 'KEY_BEHAVIOUR':
                assessmentEmployeesStoresFactory.showEmployeesModal('users', {
                    threatArea: insight.context.threatArea,
                    keyBehaviour: insight.context.keyBehaviour,
                    minScore: 0,
                    maxScore: 49,
                    scoreName: 'Key behaviour score',
                    subheading: `Key behaviour: ${insight.context.name}`,
                });
                break;
            case 'THREAT_AREA':
                navigate(
                    `threatAreas/${replaceSpaceWithUnderscore(
                        insight.context.name
                    )}`
                );
                break;
            case 'EMPLOYEE_LIST':
                assessmentEmployeesStoresFactory.modalEmployeesStore.clearSelectedListsInFilter();
                assessmentEmployeesStoresFactory.modalEmployeesStore.toggleListSelected(
                    insight.context.listId
                );
                assessmentEmployeesStoresFactory.showEmployeesModal('users', {
                    listId: [insight.context.listId],
                    minScore: 0,
                    maxScore: 49,
                    scoreName: 'Key behaviour score',
                });
                break;
            case 'QUESTIONS':
                assessmentStore.loadAssessmentQuestionsReport(
                    companyId,
                    assessmentId
                );
                showReviewQuestionsModal(insight.context.id, false);
                break;
        }
    };

    const returnContent = () => {
        if (!hasStarted)
            return (
                <FlexDiv alignCenter justifyCenter column>
                    <img src="/static/img/info-magnifier.svg" />
                    <Heading4 lighter mt={24}>
                        No insights yet
                    </Heading4>
                </FlexDiv>
            );
        if (loadingRiskInsights) return <ThemedSpinner />;
        if (riskInsightsSorted)
            return riskInsightsSorted.map((insight, i, arr) => (
                <SpaceBetweenDiv
                    // don't put bottom border after last risk insight
                    borderBottom={i !== arr.length - 1}
                    py={16}
                    key={i}
                    $noWrap
                    gap={16}
                >
                    <RiskInsight
                        insight={insight}
                        onAction={onAction}
                        threatAreaCount={threatAreaCount}
                    />
                </SpaceBetweenDiv>
            ));
    };

    return (
        <Block gap={16}>
            <Heading3 mb borderBottom>
                Risk insights
            </Heading3>
            {returnContent()}
        </Block>
    );
}

export default observer(RiskInsights);
