import { makeAutoObservable } from 'mobx';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import services from '../services';
import { extractErrorMessage, isSubscriptionPlanFree } from '../utils/helpers';
import {
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';
import AddCompanyDef from '../forms/add-company';
import debounceFn from 'debounce-fn';
import { maxBy } from 'lodash';

class SubscriptionsStore {
    legacyKeyFacts = [
        'Full access to all training material',
        'New videos monthly to cover new risks',
        'Certificate at completion',
        'Track user progress',
        'Employee reminders',
        'Only {user_price} per user per month',
    ];
    basePlans = [];
    subscriptionPlans = [];
    loadingPlans = false;
    estimationLoadingId = 0;
    estimationLoading = false;
    loadingSubscriptionsPlans = false;
    deactivatingCompanySubscription = false;
    updatingCompanySubscription = false;
    payInProcess = false;
    error = null;
    estimationError = null;
    deactivatingError = null;
    subscriptionPlansError = null;
    partnerEstimate = null;
    couponError = null;
    selectedPlan = null;
    selectedSeats = null;
    planPeriod = null;
    my = null;
    coupon = null;
    estimateResult = null;
    estimating = null;
    billingInfo = null;
    companyForm = null;
    canReduceSeatsLoading = false;
    canBeRemovedLoading = false;
    canEditSubscriptionLoading = false;
    canEditSubscriptionError = null;

    constructor(commonStore) {
        makeAutoObservable(this);
        this.enterprise_units =
            Number(import.meta.env.REACT_APP_ENTERPRISE_UNITS) || 500;
        this.commonStore = commonStore;
        this.estimateDebounced = debounceFn(this.estimate, { wait: 500 });
        this.estimatePartner = debounceFn(this.loadEstimation, { wait: 500 });
    }

    setError(error, type = 'generic') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.error = type === 'generic' ? error : null;
        this.deactivatingError =
            type === 'deactivateCompanySubscription' ? error : null;
        this.subscriptionPlansError =
            type === 'subscriptionPlans' ? error : null;
        this.canEditSubscriptionError =
            type === 'canEditSubscription' ? error : null;
    }

    initCompanyForm(partnerId, onSuccess, initials, isEdit, isInactive) {
        this.partnerEstimate = null;
        let companyForm = new AddCompanyDef(initials, {
            onError() {
                console.log('form error');
            },
            onSuccess() {
                onSuccess(companyForm, initials);
            },
        });

        const handleEstimation = () => {
            const subscription = companyForm.$('subscription').value;
            const seats = companyForm.$('seats').value;
            if (subscription && seats)
                this.estimatePartner(
                    partnerId,
                    subscription,
                    seats,
                    companyForm.$('companyId').value
                );
        };

        /* -------------- HELPER METHODS ---------- */

        const eternal = '1200';

        // set seats to 10 & make plan eternal
        const setFreeSubscriptionFormValues = () => {
            companyForm.$('seats').value = 10;
            companyForm.$('valid').value = eternal;
        };

        const resetSubscriptionValidityTo = (length) => {
            companyForm.$('valid').value = length;

            // validTill is only regarded when valid = '-1', but the date needs to be in the future so the form is valid
            companyForm.$('validTill').value = moment().add(1, 'years');
        };

        const resetForm = () => {
            companyForm.$('seats').value = '';
            resetSubscriptionValidityTo('');
        };

        const isInitialValueFree =
            initials &&
            isSubscriptionPlanFree(
                this.subscriptionPlans,
                initials.subscription?.subscription_code
            );

        /* ---------------------------------------- */

        // subscription is inactive
        if (initials && initials.subscription?.package_valid === 0) {
            // subscription is free
            if (isInitialValueFree) {
                resetSubscriptionValidityTo(eternal);
            }
            // any paid subscription
            else {
                resetSubscriptionValidityTo('');
            }
            handleEstimation();
        }

        // observe subscription value changes in the form
        companyForm.observe({
            path: 'subscription',
            key: 'value',
            call: (form) => {
                const isOldValueFree = isSubscriptionPlanFree(
                    this.subscriptionPlans,
                    form.change.oldValue
                );

                const isNewValueFree = isSubscriptionPlanFree(
                    this.subscriptionPlans,
                    form.change.newValue
                );

                // if you switch to Free plan, set seats to 10 & make plan eternal
                if (isNewValueFree) {
                    setFreeSubscriptionFormValues();
                }
                // if you switch away from the Free plan
                else if (isOldValueFree) {
                    // if editing an existing subscription
                    if (initials && isEdit) {
                        // set seats to original
                        companyForm.$('seats').value =
                            initials.subscription.package_users;

                        // inactive subscription
                        if (isInactive) {
                            resetSubscriptionValidityTo('');
                        }
                        // if active subscription
                        else {
                            if (isInitialValueFree) {
                                resetForm();
                            }
                            // if initially not free, set validity to original
                            else {
                                companyForm.$('valid').value = '-1';
                                companyForm.$('validTill').value =
                                    initials.subscription.package_valid_to;
                            }
                        }
                    }
                    // if creating a new company, reset validity & seats
                    else if (!isEdit) {
                        resetForm();
                    }
                }
                handleEstimation();
            },
        });

        companyForm.observe({
            path: 'seats',
            key: 'value',
            call: () => {
                handleEstimation();
            },
        });
        this.companyForm = companyForm;
    }

    selectBillingFrequency(yearly) {
        this.basePlans.map((plan) => {
            plan.selected = yearly ? plan.yearly : plan.monthly;
            this.selectSeats(plan.baseId, plan.selectedSeats);
        });
    }

    selectSeats(baseId, seats) {
        const item = this.basePlans.find((item) => item.baseId === baseId);
        if (item) {
            item.selectedSeats = seats;
            if (item.selected) {
                item.selectedTier = item.selected.tiers.find(
                    (x) => x.ending_unit === seats
                );
                if (!item.selectedTier)
                    item.selectedTier = item.selected.tiers.find(
                        (x) => x.starting_unit <= seats
                    );

                item.perUserPrice = (
                    item.selectedTier.price /
                    (item.selected.period_unit === 'year' ? 12 : 1) /
                    item.selectedTier.ending_unit /
                    100
                ).toFixed(2);
            }
        }
    }

    setPlan(plan) {
        this.selectedPlan = plan;
        this.planPeriod = this.selectedPlan && this.selectedPlan.period_unit;
    }

    setTier(seats) {
        this.selectedSeats = seats;
    }

    get selectedTier() {
        if (!this.selectedPlan || !this.selectedPlan.tiers) return null;

        return this.selectedPlan.tiers.find((tier) => {
            return tier.ending_unit === this.selectedSeats;
        });
    }

    async loadToken(companyId) {
        let result = await services.Companies.subscriptionsService(
            companyId
        ).braintreetoken();
        return result;
    }

    setBillingInfo(billingInfo) {
        this.billingInfo = billingInfo;
    }

    setCoupon(coupon) {
        this.coupon = coupon;
        this.estimateResult = null;
    }

    async loadPlans(companyId, activePackageSeats) {
        if (this.loadingPlans) return;
        this.loadingPlans = true;
        this.error = null;
        try {
            let result = await services.Companies.subscriptionsService(
                companyId
            ).plans();
            result.forEach((x) => {
                x.yearly = x.id.endsWith('-yearly');
                x.monthly = x.id.endsWith('-monthly');
                x.baseId = x.id.replace(/-yearly|-monthly/, '');
            });
            const groupedByBase = groupBy(result, 'baseId');
            const basePlans = [];
            Object.keys(groupedByBase).forEach((baseId) => {
                const base = {
                    baseId,
                    name:
                        groupedByBase[baseId][0] &&
                        groupedByBase[baseId][0].name,
                    yearly: groupedByBase[baseId].find((x) => x.yearly),
                    monthly: groupedByBase[baseId].find((x) => x.monthly),
                };
                if (!base.yearly && !base.monthly) {
                    base.yearly = groupedByBase[baseId][0];
                    base.monthly = groupedByBase[baseId][0];
                }
                base.selected = base.yearly || base.monthly || base;
                if (
                    base.selected &&
                    (!base.selected.tiers || base.selected.tiers.length === 0)
                ) {
                    if (
                        base.selected.definition &&
                        base.selected.definition.tiers
                    )
                        base.selected.tiers = base.selected.definition.tiers;
                }

                //get max seats for all tiers
                const maxOfAllTiers =
                    maxBy(base.selected.tiers, 'ending_unit') ||
                    base.selected.tiers[0];

                // default to 300 or maxOf All tiers seats if active package seats not offered in this plan
                const seats =
                    base.selected &&
                    base.selected.tiers &&
                    base.selected.tiers.find(
                        (t) =>
                            activePackageSeats &&
                            t.ending_unit === activePackageSeats
                    )
                        ? activePackageSeats
                        : Math.min(maxOfAllTiers.ending_unit, 300);

                this.selectSeats(base.baseId, seats);
                base.selectedSeats = seats;

                base.selectedTier =
                    base.selected &&
                    base.selected.tiers.find((t) => t.ending_unit === seats);

                base.perUserPrice = (
                    base.selectedTier.price /
                    (base.selected && base.selected.period_unit === 'year'
                        ? 12
                        : 1) /
                    base.selectedSeats /
                    100
                ).toFixed(2);
                basePlans.push(base);
            });
            this.basePlans.replace(basePlans);

            let my = await services.Companies.subscriptionsService(
                companyId
            ).list();
            if (!my || my.status !== 'active') {
                this.my = null;
            }
            if (my && my.status === 'active') {
                this.my = { planId: my.plan_id, seats: my.plan_quantity };
            }
        } catch (e) {
            console.error(e);
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPlans = false;
        }
    }

    async cancelSubscription(companyId) {
        try {
            this.estimateResult = await services.Companies.subscriptionsService(
                companyId
            ).cancel();
            return 'ok';
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async estimate(companyId, planId, seats) {
        let key = `${companyId}-${planId}-${seats}`;
        this.couponError = null;
        if (this.estimating === key) return;
        this.estimateResult = null;
        this.estimating = key;
        try {
            this.estimateResult = await services.Companies.subscriptionsService(
                companyId
            ).create({
                planId,
                coupon: this.coupon,
                seats,
                estimate: true,
            });
        } catch (e) {
            let error = extractErrorMessage(e);
            if (error.startsWith('coupon'))
                this.couponError = 'Invalid coupon code';
            else this.error = error;
        } finally {
            if (this.estimating === key) this.estimating = null;
        }
    }

    async create(companyId, planId, seats, token) {
        if (this.payInProcess) return;
        this.payInProcess = true;
        this.error = null;
        try {
            let result = await services.Companies.subscriptionsService(
                companyId
            ).create({
                planId,
                coupon: this.coupon,
                seats,
                token,
                ...this.billingInfo,
            });
            this.commonStore.postAffTrackerSale(
                this.price,
                result.subscription.id,
                planId
            );
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.payInProcess = false;
        }
    }

    get futureCredit() {
        if (
            this.estimateResult &&
            this.estimateResult.estimate &&
            this.estimateResult.estimate.credit_note_estimates
        ) {
            return (
                sumBy(
                    this.estimateResult.estimate.credit_note_estimates,
                    'amount_available'
                ) || 0
            );
        }

        return 0;
    }

    get nextBilling() {
        if (this.estimateResult && this.estimateResult.estimate)
            return moment
                .unix(
                    this.estimateResult.estimate.subscription_estimate
                        .next_billing_at
                )
                .format('L');

        return '';
    }

    get price() {
        if (!this.selectedTier) return 0;
        if (this.estimateResult && this.estimateResult.estimate) {
            if (this.estimateResult.estimate.invoice_estimate) {
                let total = this.estimateResult.estimate.invoice_estimate.total;
                let credit =
                    this.estimateResult.estimate.invoice_estimate
                        .credits_applied || 0;

                return ((total - credit) / 100).toFixed(0);
            }
            if (
                this.estimateResult.estimate &&
                this.estimateResult.estimate.next_invoice_estimate
            )
                return (
                    this.estimateResult.estimate.next_invoice_estimate.total /
                    100
                ).toFixed(0);
        }

        return (this.selectedTier.price / 100).toFixed(0);
    }

    get nextPlanPrice() {
        if (!this.selectedTier) return 0;
        return (
            Math.max(0, this.selectedTier.price - this.futureCredit) / 100
        ).toFixed(0);
    }

    planCode(ending_unit) {
        if (!this.selectedTier || !this.selectedPlan) return '';

        return `${this.selectedPlan.id}-${
            ending_unit || this.enterprise_units
        }`;
    }

    // new subscriptions plans code:

    loadSubscriptionPlans = generateLoadList(
        'subscriptionPlans',
        this,
        'loadingSubscriptionsPlans',
        async (partnerId) => {
            return services.Partners.subscriptionsService(partnerId).plans();
        },
        'subscriptionPlans'
    );

    deactivateCompanySubscription = generateUpdateEntity(
        'deactivateCompanySubscription',
        this,
        'deactivatingCompanySubscription',
        async (partnerId, companyId) => {
            return services.Partners.companiesService(partnerId).deactivate(
                companyId
            );
        }
    );

    updateCompanySubscription = generateUpdateEntity(
        'updateCompanySubscription',
        this,
        'updatingCompanySubscription',
        async (partnerId, companyId, body) => {
            return services.Partners.companiesService(
                partnerId
            ).updateSubscription(companyId, body);
        }
    );

    setPartnerEstimate(estimate) {
        this.partnerEstimate = estimate;
    }

    loadCanEditSubscription = generateLoadEntity(
        'canEditSubscription',
        this,
        'canEditSubscriptionLoading',
        async (params) => {
            return services.Partners.companiesService(
                params.partnerId
            ).canEditSubscription(params.companyId, {
                subscriptionCode: params.subscriptionCode,
                newSeats: params.newSeats,
            });
        },
        'canEditSubscription'
    );

    async loadEstimation(partnerId, subscription, seats, companyId) {
        this.estimationLoadingId++;
        const fetchId = this.estimationLoadingId;
        this.estimationError = null;
        this.estimationLoading = true;
        try {
            let service = companyId
                ? await services.Partners.companiesService(
                      partnerId
                  ).subscriptionsService(companyId)
                : services.Partners.subscriptionsService(partnerId);
            let result = await service.estimate({ subscription, seats });
            this.setPartnerEstimate(result);
            return true;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.estimationError = error;
        } finally {
            if (this.estimationLoadingId === fetchId)
                this.estimationLoading = false;
        }
    }

    async canBeRemoved(partnerId, companyId) {
        this.canBeRemovedLoading = true;
        try {
            let result = await services.Partners.companiesService(
                partnerId
            ).canBeRemoved(companyId);
            return result;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.error = error;
        } finally {
            this.canBeRemovedLoading = false;
        }
    }

    async canReduceSeats(partnerId, companyId, seats) {
        this.canReduceSeatsLoading = true;
        try {
            let result = await services.Partners.companiesService(
                partnerId
            ).canReduceSeats(companyId, { newSeats: seats });
            return result;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.error = error;
        } finally {
            this.canReduceSeatsLoading = false;
        }
    }
}

export default SubscriptionsStore;
