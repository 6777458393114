import styled, { css } from 'styled-components';
import React from 'react';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { FlexDiv, TextDefault } from '@awarego/awarego-components';
import LogoutIcon from '@mui/icons-material/ExitToApp';

const CheckIcon = styled(Check)`
    opacity: 0;

    li:hover && {
        opacity: 0.4;
    }

    ${(props) =>
        props.selected &&
        css`
            opacity: 1;
        `}
`;

const ThemedDropdownNavItem = ({
    text,
    selected,
    isSettings,
    isSignOut,
    isFeedback,
    ...props
}) => (
    <FlexDiv py={2} {...props} alignCenter>
        <FlexDiv width={24}>
            {isFeedback ? (
                <FeedbackIcon color={'primary'} fontSize="small" />
            ) : isSettings ? (
                <SettingsIcon
                    color={selected ? 'primary' : undefined}
                    fontSize="small"
                />
            ) : isSignOut ? (
                <TextDefault error>
                    <LogoutIcon fontSize="small" />
                </TextDefault>
            ) : (
                <CheckIcon color="primary" selected={selected} />
            )}
        </FlexDiv>
        <TextDefault bold ml={8}>
            {text}
        </TextDefault>
    </FlexDiv>
);

export default ThemedDropdownNavItem;
