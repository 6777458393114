import React from 'react';
import {
    FlexDiv,
    TextLead,
    TextDefault,
    TextInput,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { MenuItem } from '@mui/material';

export default observer(
    ({
        name,
        options,
        action,
        defaultValue,
        isLast,
        isPartnerCompanyDetails,
    }) => {
        const [savedMark, setSavedMark] = React.useState(false);

        const onChange = async (event) => {
            const target = event.target;
            setSavedMark(false);
            await action(target.value);
            setSavedMark(true);
        };

        return (
            <FlexDiv
                py={isPartnerCompanyDetails ? 0 : 20}
                bottomBorder={!isLast}
            >
                <FlexDiv flexGrow={1} alignCenter>
                    {isPartnerCompanyDetails ? (
                        <TextDefault lighter>{name}</TextDefault>
                    ) : (
                        <TextLead bold lighter>
                            {name}
                        </TextLead>
                    )}
                </FlexDiv>
                <FlexDiv flexGrow={2} leftIndent />
                <FlexDiv flexGrow={1} alignCenter>
                    <TextInput
                        placeholder="Language"
                        name="companySize"
                        value={defaultValue}
                        onChange={(e) => onChange(e)}
                        select
                    >
                        {options.map((x) => (
                            <MenuItem key={x.key} value={x.value}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </TextInput>
                    {savedMark && (
                        <TextDefault themed bold ml={16}>
                            Saved!
                        </TextDefault>
                    )}
                </FlexDiv>
            </FlexDiv>
        );
    }
);
