import React from 'react';
import {
    FlexDiv,
    Heading1,
    TextDefault,
    TextLead,
    Heading2,
    TextInput,
    ThemedButton,
    CleanHR,
    TextTiny,
    palette,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import { substituteWithComponent } from '../../../utils/helpers';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import DoneIcon from '@mui/icons-material/Done';
import CrossIcon from '@mui/icons-material/Clear';

const GreenBorder = styled.div`
    background-color: ${palette.evergreen.aspargus};
    border-radius: 16px;
    padding: 0;
    box-shadow: 0 0 0 4px ${palette.evergreen.aspargus};
`;

function PlanCard({
    plan,
    onSeatsChange,
    disableButton,
    enterprise_units,
    openPaymentModal,
    getPrimaryButtonText,
    billedYearly,
    legacyKeyFacts,
    recommended,
}) {
    const getKeyFactIcon = (fact) => {
        if (fact.excluded) return <CrossIcon color="error" />;
        return <DoneIcon color="primary" />;
    };

    const getKeyFactText = (fact) => {
        if (typeof fact === 'string') return fact;
        return fact.text;
    };

    const planCard = (
        <FlexDiv
            width={368}
            backgroundColor="white"
            padding={32}
            border={!recommended}
            borderRadius={16}
            column
            mt={!recommended ? 26 : undefined}
            key={plan.baseId}
            $noWrap
        >
            <Heading1 mb={8}>{plan.name}</Heading1>
            <TextLead mb={16}>{plan.selected.definition.description}</TextLead>
            <TextInput
                select
                margin={'dense'}
                stretched
                variant={'outlined'}
                value={plan.selectedSeats}
                onChange={(event) => {
                    onSeatsChange(event, plan.baseId);
                }}
            >
                {plan.selected.tiers
                    .filter((x) => x.ending_unit)
                    .map((t) => {
                        return (
                            <MenuItem
                                key={`${t.ending_unit}:${t.starting_unit}`}
                                value={t.ending_unit || enterprise_units}
                            >
                                {t.ending_unit || `${t.starting_unit}+`} seats
                            </MenuItem>
                        );
                    })}
            </TextInput>
            <ThemedButton
                mode="primary"
                disabled={disableButton}
                text={getPrimaryButtonText(plan)}
                onClick={() => {
                    openPaymentModal(plan);
                }}
            />

            <FlexDiv mt={32} alignCenter>
                <Heading2 mr={4}>
                    $
                    {(
                        plan.selectedTier && plan.selectedTier.price / 100
                    ).toFixed(2)}
                </Heading2>
                <TextDefault>
                    {billedYearly ? 'annually' : ' monthly'} (
                    {plan.selectedSeats} seats)
                </TextDefault>
            </FlexDiv>
            <TextDefault mt={4} lighter>
                ${plan.perUserPrice} per seat per month
            </TextDefault>
            <CleanHR my={32} />
            {(plan.selected.definition.keyFacts || legacyKeyFacts).map(
                (fact, i) => (
                    <FlexDiv alignCenter gap={8} key={i} mb={16} $noWrap>
                        {getKeyFactIcon(fact)}
                        <TextLead>
                            {substituteWithComponent(
                                getKeyFactText(fact),
                                '{user_price}',
                                <TextLead themed>${plan.perUserPrice}</TextLead>
                            )}
                        </TextLead>
                    </FlexDiv>
                )
            )}
        </FlexDiv>
    );

    return recommended ? (
        <GreenBorder column>
            <TextTiny ml={32} color="white" uppercase bold>
                Recommended
            </TextTiny>
            {planCard}
        </GreenBorder>
    ) : (
        planCard
    );
}

export default observer(PlanCard);
