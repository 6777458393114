import React from 'react';
import { observer } from 'mobx-react';
import HtmlField from '../../inputs/htmlField';
import { FlexDiv, TextDefault, TextTiny } from '@awarego/awarego-components';

function IntroductoryForm({ form }) {
    if (!form) return;
    return (
        <FlexDiv column>
            <TextDefault lighter bold mb={8}>
                Message
            </TextDefault>
            <TextTiny lighter mb={16}>
                This text will replace our standard first paragraph in the first
                delivery to employees. A login link will be included in the
                delivery. No password is needed.
            </TextTiny>
            <HtmlField
                field={form.$('message')}
                mrkdownField={form.$('mrkdwn')}
            />
            <div className="error">{form.$('message').error}</div>
        </FlexDiv>
    );
}

export default observer(IntroductoryForm);
