import React, {
    useEffect,
    useState,
    Fragment,
    useCallback,
    useRef,
} from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Alert } from 'reactstrap';
import pluralize from 'pluralize';
import UsersFormDef from '../../../forms/list-users';
import ErrorMessage from '../../../components/errormessage';
import AddOrImport from '../AddOrImport';
import range from 'lodash/range';
import {
    BigModal,
    SpaceBetweenDiv,
    ThemedButton,
} from '@awarego/awarego-components';
import { useStores } from '../../../hooks/use-stores';
import { handleSelectedFile } from '../../../components/file-handlers/file-helpers';

function UsersAddModal({
    companyId,
    list,
    isModalOpen,
    onRequestClose,
    isImportingLists,
}) {
    const { userStore } = useStores();
    const existingUsersSelectedRef = useRef([]);
    const [batchMode, setBatchMode] = useState([]);
    const [deleteObsolete, setDeleteObsolete] = useState(false);
    const [encoding, setEncoding] = useState('utf-8');
    const [files, setFiles] = useState([]);
    const [importError, setImportError] = useState(null);
    const [showEncodingSelect, setShowEncodingSelect] = useState(false);
    const [savingUsers, setSavingUsers] = useState(false);

    const onSuccess = useCallback(async () => {
        setSavingUsers(true);
        let formUsers = localStore._usersForm
            .values()
            .users.filter((x) => x.email != '');
        let result = await userStore.saveUsers(
            [
                ...formUsers,
                ...existingUsersSelectedRef.current.map((x) => {
                    return {
                        name: x.name,
                        email: x.email,
                    };
                }),
            ],
            companyId,
            list && list.id,
            false
        );
        setSavingUsers(false);
        if (result) {
            userStore.usersAdded++;
            onRequestClose();
        }
    }, [existingUsersSelectedRef]);

    useEffect(() => {
        userStore.cleanEditor();
    }, []);
    useEffect(() => {
        userStore.cleanEditor();
    }, [companyId]);

    const localStore = useLocalObservable(() => {
        let _usersForm = new UsersFormDef(
            {},
            {
                hooks: {
                    onSuccess: onSuccess,
                    onError: (e) => {
                        console.error(e);
                    },
                },
            }
        );
        if (list) {
            _usersForm.update({ name: list.name });
            range(1).forEach(() => {
                _usersForm.$('users').onAdd({
                    preventDefault: () => {},
                });
            });
        }

        return { _usersForm };
    });

    const showEncodingOptions = () => {
        setShowEncodingSelect(true);
    };

    const changeEncoding = (e) => {
        setEncoding(e.target.value);
        const properties = { fileEndings: ['txt', 'csv'] };
        handleSelectedFile(files, e.target.value, properties);
    };

    const setAcceptedFiles = (files) => {
        setFiles(files);
    };

    const switchMode = () => {
        userStore.resetUploadData();
        setBatchMode(!batchMode);
    };

    const toggleDeleteObsolete = () => {
        setDeleteObsolete(!deleteObsolete);
    };

    const importUsers = () => {
        localStore._usersForm.submit();
    };

    const reUpload = async () => {
        userStore.resetUploadData();
    };

    const onSelectExistingUsers = (values) => {
        existingUsersSelectedRef.current = values;
    };

    const confirmImportFiles = async () => {
        let result = await userStore.saveUsers(
            userStore.processedRecords && userStore.processedRecords.records,
            companyId,
            list && list.id,
            false,
            false,
            deleteObsolete
        );

        if (result) {
            userStore.usersAdded++;
            onRequestClose();
        }
    };

    const importFiles = (files) => {
        if (files) {
            files.forEach(async (fileContent) => {
                await userStore.saveUsers(
                    fileContent,
                    companyId,
                    list && list.id,
                    false,
                    true
                );
            });
        } else {
            let importErrorMessage = 'There was a problem importing file';
            setImportError(importErrorMessage);
        }
    };

    const { processedRecords } = userStore;

    return (
        <BigModal
            isOpen={isModalOpen}
            width={800}
            onRequestClose={onRequestClose}
            heading={
                isImportingLists ? 'Import employee lists' : 'Add employees'
            }
            modalContent={
                <div className={'modalContent d-flex flex-column'}>
                    {batchMode &&
                        processedRecords &&
                        processedRecords.newLists &&
                        processedRecords.newLists.length > 0 && (
                            <>
                                <Alert
                                    color="success"
                                    className="justify-content-center"
                                >
                                    {processedRecords.newLists.length} new{' '}
                                    {pluralize(
                                        'list',
                                        processedRecords.newLists.length
                                    )}{' '}
                                    will be created from .csv file
                                </Alert>
                                <div className="users-to-add">
                                    {processedRecords.newLists.map((x, i) => {
                                        return (
                                            <div
                                                className={'user-record'}
                                                key={i}
                                            >
                                                {' '}
                                                {x}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    {batchMode &&
                        processedRecords &&
                        processedRecords.newUsers &&
                        processedRecords.newUsers.length > 0 && (
                            <Alert
                                color="success"
                                className="justify-content-center"
                            >
                                New: {processedRecords.newUsers.length} new{' '}
                                {pluralize(
                                    'employee',
                                    processedRecords.newUsers.length
                                )}{' '}
                                found in .csv file
                            </Alert>
                        )}
                    {batchMode &&
                        processedRecords &&
                        processedRecords.updated > 0 && (
                            <Alert
                                color="success"
                                className="justify-content-center"
                            >
                                Updates: {processedRecords.updated}{' '}
                                {pluralize(
                                    'employee',
                                    processedRecords.updated
                                )}{' '}
                                with changed data found in .csv file
                            </Alert>
                        )}
                    {batchMode &&
                        processedRecords &&
                        processedRecords.obsolete > 0 && (
                            <div className={'container-checkbox small'}>
                                <label htmlFor={'addToCampaignsEmail'}>
                                    <input
                                        type="checkbox"
                                        className="checkmark"
                                        id={'addToCampaignsEmail'}
                                        checked={deleteObsolete}
                                        onChange={toggleDeleteObsolete}
                                    />
                                    Delete missing employees (
                                    {processedRecords.obsolete}{' '}
                                    {pluralize(
                                        'employee',
                                        processedRecords.obsolete
                                    )}{' '}
                                    no longer in the list )
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        )}
                    <AddOrImport
                        onSelectExistingUsers={onSelectExistingUsers}
                        companyId={companyId}
                        batchMode={batchMode}
                        processedRecords={processedRecords}
                        deleteObsolete={deleteObsolete}
                        showEncodingSelect={showEncodingSelect}
                        showEncodingOptions={showEncodingOptions}
                        encoding={encoding}
                        changeEncoding={changeEncoding}
                        importFiles={importFiles}
                        usersForm={localStore._usersForm}
                        isImportingLists={isImportingLists}
                        setAcceptedFiles={(files) => setAcceptedFiles(files)}
                    />
                    <ErrorMessage
                        error={importError || userStore.importError}
                    />
                </div>
            }
            modalFooterContent={
                <SpaceBetweenDiv gap={16}>
                    {list && (
                        <ThemedButton
                            mode="secondary"
                            $noWrap
                            onClick={switchMode}
                            text={
                                batchMode
                                    ? 'Switch to manual mode'
                                    : 'Switch to import mode'
                            }
                        />
                    )}

                    {!batchMode && (
                        <ThemedButton
                            mode="primary"
                            text={savingUsers ? 'Saving' : 'Done'}
                            onClick={importUsers}
                            $saving={savingUsers}
                            $center={true}
                            disabled={savingUsers}
                        />
                    )}
                    {batchMode && processedRecords && (
                        <Fragment>
                            <ThemedButton
                                mode="primary"
                                text="Re-Upload"
                                onClick={reUpload}
                            />
                            <ThemedButton
                                mode="primary"
                                onClick={confirmImportFiles}
                                disabled={
                                    !(
                                        processedRecords.newUsers.length > 0 ||
                                        processedRecords.updated > 0 ||
                                        (deleteObsolete &&
                                            processedRecords.obsolete > 0)
                                    )
                                }
                                text="Confirm"
                            />
                        </Fragment>
                    )}
                </SpaceBetweenDiv>
            }
        />
    );
}

UsersAddModal.defaultProps = {
    isModalOpen: true,
};

export default observer(UsersAddModal);
