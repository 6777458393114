import React from 'react';
import { TextTiny } from '@awarego/awarego-components';
import SubHeaderSeparator from './SubHeaderSeparator';

/** A text element used in Headers' Sub-headings */
function SubHeaderText({
    text,
    separator = false,
    isText = false, //Uses narrow text, since our default is semi-bold
    isHeading = false, //Uses bold text
}) {
    const separatorValue = separator === true ? '|' : separator;
    return (
        <>
            {separator && <SubHeaderSeparator separator={separatorValue} />}
            <TextTiny
                lineHeight={'initial'}
                lighter
                semiBold={!isHeading && !isText}
                bold={isHeading}
            >
                {text}
            </TextTiny>
        </>
    );
}

export default SubHeaderText;
