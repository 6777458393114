import React, { Fragment, useState, useMemo, useEffect } from 'react';
import './AssessmentListTable.scss';
import SemiCircleProgress from '../../../components/manager/assessment/semi-circle-progress';
import { MenuItem, Menu } from '@mui/material';
import { LINKS, STATUS } from '../../../utils/constants';
import {
    NestedMenuItem,
    Block,
    Heading3,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import pluralize from 'pluralize';
import RequireRole from '../../RequireRole';
import DateCell from '../../../components/manager/DateCell';
import { caseAndDiacriticsInsensitiveSort } from '../../../components/table/table-helpers';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import TableClient from '../../../components/table/TableClient';
import { useNavigate } from 'react-router-dom';

function ScormAssessmentListTable({
    assessments,
    onDelete,
    onDownloadPackage,
    defaultSortBy,
    isPackageDownloading,
    isBothAssessmentTypes,
}) {
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [assessment, setOpenedAssessment] = useState(null);
    const rowsPerPage = 10;

    const handleDownloadPackage = (type) => {
        if (onDownloadPackage) {
            onDownloadPackage(
                assessment.id,
                assessment._rawData.uniqueId,
                type
            );
        }
    };

    useEffect(() => {
        if (isPackageDownloading == false) {
            handleMenuClose();
        }
    }, [isPackageDownloading]);

    const maxVisibleLength = 30;

    const columns = useMemo(
        () => [
            {
                Header: 'Assessment',
                accessor: 'name',
                Cell: (x) => {
                    const areas = x.row.original._rawData.definition.areas;
                    return (
                        <NameAndSublistCell
                            name={x.value}
                            maxVisibleNameLength={maxVisibleLength}
                            subListItems={areas}
                            maxVisibleSubListCount={2}
                            linkName={`/assessment/p${x.row.original.id}/view`}
                        />
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Created',
                accessor: 'deliveryDate',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
            },
            {
                Header: 'Completion',
                accessor: '_rawData.usersFinished',
                Cell: (x) => `${x.value} ${pluralize('employee', x.value)}`,
            },
            {
                Header: 'Score',
                accessor: 'resilienceScore',
                Cell: (x) => {
                    return x.row.original.status === STATUS.ACTIVE ||
                        x.row.original.status === STATUS.COMPLETED ||
                        x.row.original._rawData.usersFinished === 1 ? (
                        <SemiCircleProgress
                            percentage={x.value}
                            width={48}
                            top={14}
                            halfCircle
                            percentageText
                            showNA={x.row.original._rawData.usersFinished == 0}
                        />
                    ) : (
                        ''
                    );
                },
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
        ],
        [caseAndDiacriticsInsensitiveSort]
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <DarkArrowTooltipIcon
                    customFunction={handleMenuOpen}
                    row={row}
                    title="More"
                    icon={'more'}
                ></DarkArrowTooltipIcon>
            </>
        );
    };

    const handleMenuOpen = (e, assessment) => {
        setOpenedAssessment(assessment);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedAssessment(null);
        setAnchorEl(null);
        if (e) {
            e.stopPropagation();
        }
    };

    return (
        <Block gap={32} tablePadding>
            {isBothAssessmentTypes && (
                <Heading3 ml={16}>
                    Scorm assessments ({assessments.length})
                </Heading3>
            )}
            {assessments.length > 0 ? (
                <Fragment>
                    <TableClient
                        data={assessments}
                        columns={columns}
                        link={LINKS.assessment}
                        defaultSortBy={defaultSortBy}
                        hidePagination={assessments.length <= rowsPerPage}
                        initialPageSize={rowsPerPage}
                    />
                    {assessment && (
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={assessment != null}
                            onClose={handleMenuClose}
                            elevation={1}
                        >
                            <MenuItem
                                onClick={(e) => {
                                    navigate(assessment.link);
                                    handleMenuClose(e);
                                }}
                            >
                                Open
                            </MenuItem>
                            <NestedMenuItem
                                text="Download assessment"
                                loading={isPackageDownloading}
                                items={[
                                    {
                                        text: 'SCORM 1.2',
                                        props: {
                                            onClick: () => {
                                                handleDownloadPackage(
                                                    'scorm12'
                                                );
                                            },
                                        },
                                    },
                                    {
                                        text: 'SCORM 2004 3rd Edition',
                                        props: {
                                            onClick: () => {
                                                handleDownloadPackage(
                                                    'scorm2004'
                                                );
                                            },
                                        },
                                    },
                                    {
                                        text: 'cmi5',
                                        props: {
                                            onClick: () => {
                                                handleDownloadPackage('cmi5');
                                            },
                                        },
                                    },
                                ]}
                            />
                            <RequireRole>
                                {(assessment.status === STATUS.DRAFT ||
                                    assessment.status === STATUS.SCHEDULED) && (
                                    <MenuItem
                                        onClick={(e) => {
                                            onDelete(assessment.id, true);
                                            handleMenuClose(e);
                                        }}
                                    >
                                        <span style={{ color: 'red' }}>
                                            Delete
                                        </span>
                                    </MenuItem>
                                )}
                            </RequireRole>
                        </Menu>
                    )}
                </Fragment>
            ) : (
                <NoItemsYetContent
                    img={'/static/img/icon-booklist.svg'}
                    imgAlt={'No assessments found'}
                    title={'No Scorm assessments found'}
                    text={
                        'Try adjusting your search or filter to find what you were looking for.'
                    }
                />
            )}
        </Block>
    );
}

export default ScormAssessmentListTable;
