import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import VideoPlayer from './video-player';
import { EVENTS } from '../utils/constants';
import { useStores } from '../hooks/use-stores';

function VideoPreview({ isOpen, videoPlayerUrl, token, subject, language }) {
    const { store } = useStores();
    const onDecisionMade = async (decision) => {
        const context = {
            decision: decision.key,
            result: !!decision.correct,
            next: decision.playlistIndex,
        };
        subject.setLogDecision(context);

        if (!subject.video_decisions[decision.playlistIndex].questionText) {
            const { numberOfRightAnswers, numberOfPossibleDecisions } = subject;

            if (numberOfRightAnswers / numberOfPossibleDecisions === 1) {
                subject.setPreviewFinishedState(EVENTS.ANSWARE_CORRECT);
            } else {
                subject.setPreviewFinishedState(EVENTS.ANSWARE_INCORRECT);
            }
        }
    };

    const subjectId = subject && subject.id;

    const loadData = useCallback(async () => {
        if (!subject) return;
        if (!store.videoPlayerUrl) await store.loadVideoPlayerUrl();
        if (subjectId && !store.tokenIndex[subjectId])
            await store.videoToken(subjectId, language);
    }, [subjectId, store, subject]);

    useEffect(() => {
        loadData();
    }, [loadData, subjectId]);

    return isOpen && videoPlayerUrl && token ? (
        <VideoPlayer
            training={null}
            logEvent={() => {}}
            isAssessment={false}
            subject={subject}
            language={language}
            onChange={onDecisionMade}
        />
    ) : (
        <div />
    );
}

export default observer(VideoPreview);
