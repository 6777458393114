import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import { Row, Col } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { FormControlLabel, Switch } from '@mui/material';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import { Heading1, Heading3, ThemedButton } from '@awarego/awarego-components';

function CompanySlack({ companyId }) {
    const { companyStore, commonStore, brandingStore } = useStores();

    useEffect(() => {
        companyStore.loadIntegrationData(companyId, 'slack');
    }, [companyId]);

    const onToggleWelcomeMessage = async () => {
        if (companyStore.slackDataIndex[companyId].welcomeMessage === '1') {
            await companyStore.saveIntegrationData(companyId, 'slack', {
                welcomeMessage: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'slack', {
                welcomeMessage: '1',
            });
        }
    };

    const testIntegration = async () => {
        try {
            let result = await companyStore.testIntegration(companyId, 'slack');
            if (result.success)
                commonStore.showMessage('Connection successful');
            else
                commonStore.showMessage(
                    'Connection failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Connection failed: ' + e.message, 'error');
        }
    };

    const { slackError, testingSlackData, loadingSlackDatas } = companyStore;
    const { account_name, brandingLoaded, integrationSlackUrl } = brandingStore;
    let data = companyStore.slackDataIndex[companyId];

    return loadingSlackDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to=".." relative="path">
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block">
                        {slackError && (
                            <ErrorMessage
                                error={slackError}
                                marginBottom={true}
                            />
                        )}
                        <img
                            src={`/static/img/logo-slack.svg`}
                            alt="Slack logo"
                        />
                        <Heading1 mb={8} mt={16}>
                            {(brandingLoaded && account_name) || 'AwareGO'} +
                            Slack
                        </Heading1>
                        <p className={!data.connected ? 'margin-bottom' : ''}>
                            {(brandingLoaded && account_name) || 'AwareGO'}{' '}
                            Slack app will send Slack messages with lessons
                            directly to your employees; it will remind them to
                            take their lessons on time and improve their overall
                            completion rate.
                        </p>
                        {!data.connected ? (
                            <a href={data.connectUrl}>
                                <ThemedButton
                                    mode="primary"
                                    text="Connect Slack"
                                />
                            </a>
                        ) : (
                            ''
                        )}
                    </div>
                    {data.connected ? (
                        <div className="integration-detail-block align-left">
                            <Heading3 my={8}>Manage connection</Heading3>
                            <div className="button-with-description">
                                <p>
                                    Click on the following button to test the
                                    connection with Slack. This action will not
                                    send out any messages.
                                </p>
                                {testingSlackData.includes(companyId) ? (
                                    <Spinner />
                                ) : (
                                    <ThemedButton
                                        mode="secondary"
                                        size="small"
                                        onClick={testIntegration}
                                        text="Test Connection"
                                    />
                                )}
                            </div>
                            <Heading3 my={8}>Welcome message</Heading3>
                            <div className="button-with-description">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                Number(data.welcomeMessage) ===
                                                1
                                            }
                                            onChange={onToggleWelcomeMessage}
                                            name="welcomeEnabled"
                                            color="primary"
                                        />
                                    }
                                    label="New users in your Slack will receive a friendly welcome message with basic usage notes."
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </Col>
                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href={integrationSlackUrl}
                        product="Slack"
                    />
                </Col>
            </Row>
        </Fragment>
    );
}

export default observer(CompanySlack);
