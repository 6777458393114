import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { defaultZero, calculateColor } from '../../../utils/helpers';
import Summary from './summary';
import {
    Heading2,
    Heading3,
    FlexDiv,
    TextDefault,
    Block,
    StatusBadge,
    SpaceBetweenDiv,
    ThemedButton,
    DropdownThemedButton,
    Tag,
    palette,
} from '@awarego/awarego-components';
import SemiCircleProgress from '../assessment/semi-circle-progress';
import { Link } from 'react-router-dom';
import NoItemsYet from '../no-items-yet';
import AssessmentUpgrade from '../assessment/AssessmentUpgrade';
import FullWidthLink from '../../FullWidthLink';
import RequireRole from '../../../containers/RequireRole';
import TableClient from '../../../components/table/TableClient';
import { LINKS } from '../../../utils/constants';
import StyledLink from '../../../components/StyledLink';

function AssessmentDashboard({
    dashboardData,
    assessments,
    assessmentsUnavailable,
    upgradeAllowed,
    supportEmail,
    demoLink,
    cmi5enabled,
    isScormOnly,
}) {
    const columns = useMemo(
        () => [
            {
                Header: 'Assessment',
                accessor: 'name',
                Cell: (x) => (
                    <>
                        <StyledLink
                            to={`/assessment/${
                                x.row.original.isScorm ? `p` : ``
                            }${x.row.original.id}/view`}
                            $hoverColor={palette.graphite.onyx}
                            $underlined={true}
                        >
                            {x.value && x.value.length > 0 ? (
                                x.value.length > 35 ? (
                                    <Tooltip title={x.value}>
                                        <span>
                                            <TextDefault bold>
                                                {x.value.substring(0, 35)}
                                                ...
                                            </TextDefault>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <TextDefault bold>{x.value}</TextDefault>
                                )
                            ) : (
                                <Tag text="No Name" />
                            )}{' '}
                        </StyledLink>
                        <StatusBadge
                            color={x.row.original.statusInfo.color}
                            text={x.row.original.statusInfo.label}
                            transparent={x.row.original.statusInfo.transparent}
                        />
                    </>
                ),
                disableSortBy: true,
            },
            {
                Header: '# Employees',
                accessor: (x) => {
                    if (x.isScorm) return x._rawData.usersFinished;
                    return x._rawData.totalEmployees;
                },
                Cell: (x) => <TextDefault>{x.value}</TextDefault>,
                disableSortBy: true,
            },
            {
                Header: 'Completion',
                accessor: 'attendance',
                Cell: (x) => {
                    if (x.row.original.isScorm) return 'N/A';
                    return (
                        <TextDefault bold color={calculateColor(x.value)}>
                            {defaultZero(x.value)}%
                        </TextDefault>
                    );
                },
                disableSortBy: true,
            },
            {
                Header: 'Resilience score',
                accessor: 'resilienceScore',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        width={48}
                        top={12}
                        halfCircle
                        percentageText
                        showNA={
                            (x.row.original.isScorm &&
                                x.row.original._rawData.usersFinished == 0) ||
                            (!x.row.original.isScorm &&
                                !x.row.original._rawData.started)
                        }
                    />
                ),
                tooltip:
                    'Average employee score across all assessed threat areas.',
                disableSortBy: true,
            },
        ],
        []
    );

    if (assessmentsUnavailable) {
        return (
            <RequireRole>
                <AssessmentUpgrade
                    upgradeAllowed={upgradeAllowed}
                    supportEmail={supportEmail}
                    demoLink={demoLink}
                />
            </RequireRole>
        );
    }

    return assessments.length > 0 ? (
        <Block tablePadding>
            <Heading2 mb={16} ml={16}>
                Assessments
            </Heading2>
            <FlexDiv alignCenter $noWrap>
                {!isScormOnly && dashboardData && (
                    <Summary
                        mainNumberText={'Active'}
                        mainNumber={dashboardData.active.value}
                        secondaryNumberText={'Currently assessing'}
                        secondaryNumber={dashboardData.active.users}
                        iconUrl={'/static/img/icon-dashboard-repeat.svg'}
                        iconAlt={'arrow in circle'}
                        item="assessment"
                    />
                )}
                {dashboardData && (
                    <Summary
                        mainNumberText={'Completed'}
                        mainNumber={dashboardData.completed.value}
                        secondaryNumberText={'Completed assessment'}
                        secondaryNumber={dashboardData.completed.users}
                        iconUrl={'/static/img/icon-dashboard-completed.svg'}
                        iconAlt={'circle with tick inside'}
                        item="assessment"
                    />
                )}
            </FlexDiv>
            <Heading3 my={24} ml={16}>
                Assessment performance
            </Heading3>
            <Block noBorder resetPadding resetMargin>
                <TableClient
                    columns={columns}
                    data={assessments}
                    hidePagination
                    link={LINKS.assessment}
                    disableSort
                    hiddenColumns={isScormOnly ? ['attendance'] : []}
                />
            </Block>
            <FullWidthLink to={`/assessment`}>
                <ThemedButton
                    mode="secondary"
                    size="fullWidth"
                    text="All assessments"
                />
            </FullWidthLink>
        </Block>
    ) : (
        <NoItemsYet
            img={'/static/img/assessment-empty.svg'}
            imgAlt={'sneak peak into assessment'}
            title={'No assessments yet'}
            text={
                'Create a new assessment to measure and track employees’ cybersecurity knowledge and behavior.'
            }
            heading={'Assessments'}
            buttons={
                <RequireRole>
                    <SpaceBetweenDiv gap={24}>
                        {cmi5enabled ? (
                            <DropdownThemedButton
                                text="Create assessment"
                                wrapperProps={{ flexGrow: 1 }}
                                btnProps={{ mode: 'primary' }}
                                items={[
                                    {
                                        text: 'Assessment',
                                        props: {
                                            component: Link,
                                            to: '/assessment/create',
                                        },
                                    },
                                    {
                                        text: 'Assessment (SCORM, cmi5)',
                                        props: {
                                            component: Link,
                                            to: '/assessment/create_scorm',
                                        },
                                    },
                                ]}
                            />
                        ) : (
                            <ThemedButton
                                mode="primary"
                                text="Create new"
                                as={Link}
                                flexGrow={1}
                                $center
                                to={`/assessment/create`}
                            />
                        )}
                        <ThemedButton
                            as="a"
                            href={demoLink}
                            target="_blank"
                            rel="noreferrer"
                            mode="secondary"
                            text="Try demo"
                            flexBasisPercent={cmi5enabled ? 50 : undefined}
                            flexGrow={!cmi5enabled ? 1 : undefined}
                            $center
                        />
                    </SpaceBetweenDiv>
                </RequireRole>
            }
        />
    );
}

export default AssessmentDashboard;
