import RestService from './RestService';
import BaseService from './BaseService';

export default class ContentService extends RestService {
    constructor(parentService, companyId) {
        if (parentService && companyId)
            super(`${parentService.baseUrl}/${companyId}/content`);
        else throw new Error('company is required');
    }

    validateScormZipPackage(formData) {
        const request = this.post(`${this.baseUrl}/validate`, formData, {
            headers: {
                ...BaseService.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return request;
    }

    uploadScormZipPackage(formData) {
        const request = this.post(`${this.baseUrl}`, formData, {
            headers: {
                ...BaseService.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return request;
    }

    uploadLanguagePackage(formData, scormId) {
        const request = this.post(
            `${this.baseUrl}/${scormId}/translation`,
            formData,
            {
                headers: {
                    ...BaseService.commonHeaders().headers,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return request;
    }

    editLanguagePackage(formData, scormId, languageCode) {
        const request = this.put(
            `${this.baseUrl}/${scormId}/translation/${languageCode}`,
            formData,
            {
                headers: {
                    ...BaseService.commonHeaders().headers,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return request;
    }

    deleteScormZipPackage(scormId) {
        const request = this.delete(scormId);
        return request;
    }

    deleteLanguagePackage(scormId, languageCode, isDefaultLanguage) {
        const url = `${this.baseUrl}/${scormId}/translation/${languageCode}`;
        const request = this.del(url, { defaultLanguage: isDefaultLanguage });
        return request;
    }
}
