import { BigModal } from '@awarego/awarego-components';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ReportFormFooter from '../forms/ReportFormFooter';
import ReportRecurringForm from '../forms/recurring/ReportRecurringForm';
import { useStores } from '../../../hooks/use-stores';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';

function ReportRecurringModal({
    isOpen,
    onRequestClose,
    reportsForm,
    lists,
    usersTimeZone,
    companyId,
    reportId, //If supplied, we fetch a report with that ID to use to pre-fill the form
    editingReport, //If supplied, used to pre-fill the form
}) {
    const { reportsStore } = useStores();
    const isEditing = !!(editingReport || reportId);

    useEffect(() => {
        // define an async function that fetches the data
        const fetchData = async () => {
            await reportsStore.loadSingleReport(companyId, reportId);
        };

        // call the function
        if (reportId) {
            fetchData()
                // make sure to catch any error
                .catch(console.error);
        }
    }, [companyId, reportId, reportsStore]);

    return (
        <BigModal
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            heading={'Schedule report' + (isEditing ? ' (edit)' : '')}
            modalContent={
                reportId && reportsStore.loadingReport ? (
                    <ThemedSpinner />
                ) : (
                    <ReportRecurringForm
                        reportsForm={reportsForm}
                        lists={lists}
                        usersTimeZone={usersTimeZone}
                        editingReport={
                            editingReport || (reportId && reportsStore.report)
                        }
                    />
                )
            }
            modalFooterContent={
                <ReportFormFooter
                    reportsForm={reportsForm}
                    submitText={'Save'}
                    onCancel={() => {
                        onRequestClose();
                    }}
                    onSubmit={() => {
                        reportsForm.submit(isEditing);
                    }}
                />
            }
        />
    );
}

export default observer(ReportRecurringModal);
