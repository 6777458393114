import React from 'react';
import {
    FlexDiv,
    Heading1,
    SpaceBetweenDiv,
    TextDefault,
    TextLead,
    ThemedButton,
} from '@awarego/awarego-components';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';

const CleanUL = styled.ul`
    padding: 0;
    margin: 0;
`;

const CustomLI = styled.li`
    margin-bottom: 4px;
    ${(props) =>
        props.biggerSpacing &&
        css`
            margin-bottom: 16px;
        `}
`;

const enterprisePlanDefinition = [
    '<b>1000+ seats</strong>',
    'Single sign-on (SSO)',
    'Custom content',
    'Content branding',
    'Content available as SCORM',
    '<b>Priority support</strong>',
    'Access to customer success team',
    'Expert consulting',
];

function EnterprisePlan() {
    return (
        <SpaceBetweenDiv
            backgroundColor="white"
            padding={32}
            border
            borderRadius={16}
            alignStart
            gap={32}
            mt={64}
        >
            <FlexDiv column maxWidth={312} alignStart>
                <Heading1 mb={8}>Enterprise</Heading1>
                <TextLead mb={32}>
                    For large businesses looking for the best support and
                    greatest flexibility
                </TextLead>
                <ThemedButton
                    mode="primary"
                    text={
                        <a
                            href="https://awarego.com/contact-2/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Contact us
                        </a>
                    }
                />
            </FlexDiv>
            <TextDefault desktopOnly>
                <CleanUL>
                    {enterprisePlanDefinition.slice(0, 5).map((text, i) => (
                        <CustomLI key={i} biggerSpacing>
                            <Trans
                                components={{
                                    b: <b />,
                                }}
                            >
                                {text}
                            </Trans>
                        </CustomLI>
                    ))}
                </CleanUL>
            </TextDefault>
            <TextDefault desktopOnly>
                <CleanUL>
                    {enterprisePlanDefinition.slice(5, 8).map((text, i) => (
                        <CustomLI key={i} biggerSpacing>
                            <Trans
                                components={{
                                    b: <b />,
                                }}
                            >
                                {text}
                            </Trans>
                        </CustomLI>
                    ))}
                </CleanUL>
            </TextDefault>
            <TextDefault mobileOnly ml={16}>
                <CleanUL>
                    {enterprisePlanDefinition.map((text, i) => (
                        <CustomLI key={i} biggerSpacing>
                            <Trans
                                components={{
                                    b: <b />,
                                }}
                            >
                                {text}
                            </Trans>
                        </CustomLI>
                    ))}
                </CleanUL>
            </TextDefault>
        </SpaceBetweenDiv>
    );
}

export default EnterprisePlan;
