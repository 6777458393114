const CHANNELS = {
    EMAIL: 1,
    SLACK: 2,
    TEAMS: 4,
};

/**
 * @param {number} channel
 * @returns {string[]}
 */
export const getDeliveryChannels = (channel) => {
    const result = [];
    if (!Number.isInteger(channel)) return result;

    for (const [key, val] of Object.entries(CHANNELS)) {
        if ((channel & val) === val) {
            result.push(key);
        }
    }

    return result;
};
