import React, { useEffect } from 'react';
import {
    Block,
    Heading3,
    SpaceBetweenDiv,
    SearchInput,
    FlexDiv,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import EmployeesCheckboxTable from './components/EmployeesCheckboxTable';
import ScoreFilter from './components/scoreFilter';
import { useStores } from '../../hooks/use-stores';

function EmployeesList({
    companyId,
    assessmentId,
    threatArea = '',
    assessmentEmployeesStore,
    product,
    canRemind,
}) {
    const {
        usersTotal,
        userListSearchValue,
        order,
        pageIndex,
        pageSize,
        loadingUsersList,
        minScore,
        maxScore,
        scoreName,
    } = assessmentEmployeesStore;

    const { authStore } = useStores();
    const { anonymousTracking } = authStore;

    useEffect(() => {
        assessmentEmployeesStore.setThreatArea(
            threatArea !== '' ? threatArea : null
        );
        if (!anonymousTracking) {
            assessmentEmployeesStore.loadUsers(companyId, assessmentId);
        }
    }, [
        assessmentId,
        companyId,
        threatArea,
        pageIndex,
        pageSize,
        order,
        minScore,
        maxScore,
        assessmentEmployeesStore,
        anonymousTracking,
    ]);

    useEffect(() => {
        if (!anonymousTracking) {
            assessmentEmployeesStore.debouncedLoadUsers(
                companyId,
                assessmentId
            );
        }
    }, [
        assessmentEmployeesStore,
        assessmentId,
        companyId,
        userListSearchValue,
        anonymousTracking,
    ]);

    const onScoreFilterApply = (minScore, maxScore) => {
        assessmentEmployeesStore.setScores(minScore, maxScore);
    };

    return (
        <Block gap={24}>
            <SpaceBetweenDiv>
                <Heading3>
                    Employees {!loadingUsersList && `(${usersTotal})`}
                </Heading3>
                {!anonymousTracking && (
                    <FlexDiv gap={16} alignCenter $noWrap>
                        <ScoreFilter
                            scoreName={scoreName}
                            initialMinScore={minScore}
                            initialMaxScore={maxScore}
                            onScoreFilterApply={onScoreFilterApply}
                        />
                        <SearchInput
                            searchValue={userListSearchValue}
                            placeholder="Search employees"
                            onChange={(e) =>
                                assessmentEmployeesStore.setUserListSearchValue(
                                    e.target.value
                                )
                            }
                            onClear={() =>
                                assessmentEmployeesStore.setUserListSearchValue(
                                    ''
                                )
                            }
                        />
                    </FlexDiv>
                )}
            </SpaceBetweenDiv>
            <EmployeesCheckboxTable
                companyId={companyId}
                productId={assessmentId}
                product={product}
                store={assessmentEmployeesStore}
                isEmployeesList
                canRemind={canRemind}
                anonymousTracking={anonymousTracking}
            />
        </Block>
    );
}

export default observer(EmployeesList);
