import RestService from './RestService';

export default class TemplatesService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/questions`);
        } else super('/questions');
    }

    fetchPreview(entityId) {
        return this.get(`${this.baseUrl}/${entityId}`, {
            preview: true,
        });
    }
}
