import React, { Fragment, useMemo } from 'react';
import { isEnumSet } from './../../../utils/helpers';
import { TRANSPORTS } from './../../../utils/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    FlexDiv,
    Heading4,
    CheckboxInput,
    TextLead,
    LightTooltip,
} from '@awarego/awarego-components';

function SelectChannel({
    channel,
    slackAvailable,
    slackConnected,
    teamsAvailable,
    teamsConnected,
    onChangeChannel,
    isReminders,
    removeTopMargin,
}) {
    const channels = useMemo(
        () => [
            {
                label: 'Email',
                available: true,
                connected: true,
                value: TRANSPORTS.EMAIL,
                checked: isEnumSet(channel, TRANSPORTS.EMAIL),
            },
            {
                label: 'Slack',
                available: slackAvailable,
                connected: slackConnected,
                value: TRANSPORTS.SLACK,
                checked:
                    isEnumSet(channel, TRANSPORTS.SLACK) &&
                    slackAvailable &&
                    slackConnected,
            },
            {
                label: 'MS Teams',
                available: teamsAvailable,
                connected: teamsConnected,
                value: TRANSPORTS.TEAMS,
                checked:
                    isEnumSet(channel, TRANSPORTS.TEAMS) &&
                    teamsAvailable &&
                    teamsConnected,
            },
        ],
        [
            channel,
            slackAvailable,
            slackConnected,
            teamsAvailable,
            teamsConnected,
        ]
    );
    return (
        <Fragment>
            <Heading4
                lighter
                mt={!removeTopMargin ? 48 : undefined}
                mb={16}
                displayBlock
            >
                Choose delivery channels{isReminders ? ' for reminders' : ''}
            </Heading4>
            <FlexDiv gap={36}>
                {channels.map((channel) => (
                    <CheckboxInput
                        checked={channel.checked}
                        noYPadding
                        value={channel.value}
                        onChange={onChangeChannel}
                        label={
                            <FlexDiv gap={4} alignCenter>
                                <TextLead
                                    lighter={
                                        !channel.available || !channel.connected
                                    }
                                >
                                    {channel.label}
                                </TextLead>
                                {(!channel.available || !channel.connected) && (
                                    <>
                                        <LightTooltip
                                            title={
                                                !channel.available
                                                    ? `Upgrade is needed for ${channel.label} integration.`
                                                    : `Please configure ${channel.label} under Settings/Integrations.`
                                            }
                                            placement="bottom"
                                        >
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="action"
                                            />
                                        </LightTooltip>
                                        <span>{channel.available}</span>
                                    </>
                                )}
                            </FlexDiv>
                        }
                        disabled={!channel.available || !channel.connected}
                        key={channel.label}
                    />
                ))}
            </FlexDiv>
        </Fragment>
    );
}

export default SelectChannel;
