import React from 'react';
import {
    TextInput,
    FlexDiv,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';
import { CircularProgress } from '@mui/material';
import Popover from '@mui/material/Popover';

function AddToListModal({
    showAddToListModal,
    setShowAddToListModal,
    showNewListInput,
    setShowNewListInput,
    newListInput,
    setNewListInput,
    handleCreateNewList,
    savingList,
    savingUsers,
    lists,
    handleAddEmployeesToList,
}) {
    return (
        <Popover
            anchorEl={showAddToListModal}
            keepMounted
            open={Boolean(showAddToListModal)}
            onClose={() => setShowAddToListModal(null)}
            disableScrollLock={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <FlexDiv column py={16} px={24} width={272}>
                <FlexDiv borderBottom={!showNewListInput} pb={8}>
                    <TextTiny
                        bold
                        link
                        onClick={() => setShowNewListInput(true)}
                    >
                        Create new list
                    </TextTiny>
                </FlexDiv>
                {showNewListInput && (
                    <FlexDiv column alignEnd pb={8} borderBottom gap={8}>
                        <TextInput
                            value={newListInput}
                            onChange={(e) => setNewListInput(e.target.value)}
                            placeholder="Enter list name"
                            inline
                            stretched
                        />
                        <ThemedButton
                            mode="primary"
                            disabled={newListInput === ''}
                            size="small"
                            $noTextTransform
                            onClick={handleCreateNewList}
                            text={
                                <>
                                    <FlexDiv alignCenter>
                                        {savingList ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            'Create2'
                                        )}
                                    </FlexDiv>
                                </>
                            }
                        />
                    </FlexDiv>
                )}
                <FlexDiv column mt={8}>
                    {savingUsers ? (
                        <CircularProgress size={20} />
                    ) : (
                        lists.map((list, i, arr) => (
                            <TextTiny
                                bold
                                key={list.id}
                                link
                                mt={8}
                                //  don't put margin bottom after last list
                                mb={i !== arr.length - 1 && 8}
                                onClick={() =>
                                    handleAddEmployeesToList(list.id, list.name)
                                }
                            >
                                {list.name}
                            </TextTiny>
                        ))
                    )}
                </FlexDiv>
            </FlexDiv>
        </Popover>
    );
}

export default AddToListModal;
