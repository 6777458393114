import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useStores } from '../../hooks/use-stores';
import { observer, useLocalObservable } from 'mobx-react';
import { Block, ThemedButton } from '@awarego/awarego-components';
import HeaderSection from '../../components/header/HeaderSection';
import ReportsTable from './ReportsTable';
import SubHeaderComponentsList from '../../components/header/SubHeaderComponentsList';
import SubHeaderText from '../../components/header/SubHeaderText';
import ReportSnapshotModal from './modals/ReportSnapshotModal';
import ReportSnapshotFormDef from './forms/snapshot/ReportSnapshotFormDef.js';
import ReportRecurringModal from './modals/ReportRecurringModal';
import ReportRecurringFormDef from './forms/recurring/ReportRecurringFormDef';

function ReportsList() {
    const { authStore, store, listStore, commonStore, reportsStore } =
        useStores();
    const { timeZone } = authStore;
    const { currentCompany } = store;

    const { listsWithUsers: lists } = listStore;

    const [showingReportSnapshotModal, setShowReportSnapshotModal] =
        useState(false);
    const [showingReportRecurringModal, setShowReportRecurringModal] =
        useState(false);
    const [editingReportId, setEditingReportId] = useState(null);

    const localStore = useLocalObservable(() => {
        let reportSnapshotForm = new ReportSnapshotFormDef(
            {},
            {
                onSuccess(e) {
                    doAddOrEditReport(this, true);
                },
                onError(e) {
                    console.error('error', e);
                },
            }
        );

        let reportRecurringForm = new ReportRecurringFormDef(
            {},
            {
                onSuccess(form) {
                    doAddOrEditReport(this, false);
                },
                onError(form) {
                    console.error('error', form.errors());
                },
            }
        );

        return { reportSnapshotForm, reportRecurringForm };
    });

    const doAddOrEditReport = async (form, isSnapshot = false) => {
        const reportId = form.values().id;

        if (reportId && reportId !== '') {
            // UPDATING REPORT
            const result = await reportsStore.updateReport(
                currentCompany.company_id,
                editingReportId,
                form.values()
            );
            if (result) {
                commonStore.success('Report updated ', form);
            }
        } else {
            // ADDING REPORT
            const result = await reportsStore.addReport(
                currentCompany.company_id,
                form.values(),
                isSnapshot
            );
            if (result) {
                if (isSnapshot) {
                    commonStore.success('Report generated', form);
                } else {
                    commonStore.success('Report added', form);
                }
            }
        }
        hideReportSnapshotModal();
        hideReportRecurringModal();
    };

    useEffect(() => {
        listStore.loadCompanyLists(currentCompany.company_id);
    }, [listStore]);

    const showReportSnapshotModal = () => {
        setShowReportSnapshotModal(true);
    };

    const hideReportSnapshotModal = () => {
        setShowReportSnapshotModal(false);
        localStore.reportSnapshotForm.reset();
    };

    const showReportRecurringModal = () => {
        setShowReportRecurringModal(true);
    };

    const showEditReportRecurringModal = (e, report) => {
        e.stopPropagation();
        setEditingReportId(report.id);
        setShowReportRecurringModal(true);
    };

    const hideReportRecurringModal = () => {
        setShowReportRecurringModal(false);
        setEditingReportId(null);
        localStore.reportRecurringForm.reset();
    };

    const deleteReport = async (e, report) => {
        e.stopPropagation();
        commonStore.showConfirm(
            'Are you sure you want to delete this report?',
            'Delete',
            'Delete report',
            async () => {
                const result = await reportsStore.delete(
                    currentCompany.company_id,
                    report.id
                );
                if (result) {
                    commonStore.success('Report deleted!');
                }
            },
            true
        );
    };

    const snapshotSubHeadings = () => (
        <SubHeaderComponentsList>
            <SubHeaderText text="Generate a real-time snapshot or send a report to assess the performance of designated employee lists. Please note that this report will not be saved in the system." />
        </SubHeaderComponentsList>
    );

    const snapshotButton = () => (
        <ThemedButton
            text={'Generate snapshot'}
            mode={'primary'}
            onClick={() => {
                showReportSnapshotModal();
            }}
            $noWrap
        />
    );

    const recurringSubHeadings = () => (
        <SubHeaderComponentsList>
            <SubHeaderText text="Create report lists that focus on specific employee groups, and share them with your colleagues at a custom schedule. Create and set up a recurring report just once and get an easy overview of task completion over time." />
        </SubHeaderComponentsList>
    );

    const recurringButton = () => (
        <ThemedButton
            text={'+ add'}
            mode={'primary'}
            onClick={() => {
                showReportRecurringModal();
            }}
            $noWrap
        />
    );

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Reports</title>
            </Helmet>

            <HeaderSection
                heading={'Snapshot report'}
                subHeadings={snapshotSubHeadings()}
                buttons={snapshotButton()}
            />

            <Block tablePadding>
                <HeaderSection
                    heading={'Recurring reports'}
                    subHeadings={recurringSubHeadings()}
                    buttons={recurringButton()}
                    excludeBlock
                    marginLeft="16"
                />

                <ReportsTable
                    companyId={currentCompany.company_id}
                    onEditReport={showEditReportRecurringModal}
                    onDeleteReport={deleteReport}
                ></ReportsTable>
            </Block>

            <ReportSnapshotModal
                isOpen={showingReportSnapshotModal}
                onRequestClose={hideReportSnapshotModal}
                lists={lists}
                reportsForm={localStore.reportSnapshotForm}
            />

            <ReportRecurringModal
                isOpen={showingReportRecurringModal}
                onRequestClose={hideReportRecurringModal}
                lists={lists}
                reportsForm={localStore.reportRecurringForm}
                usersTimeZone={timeZone}
                companyId={currentCompany.company_id}
                reportId={editingReportId}
            />
        </Container>
    );
}
export default observer(ReportsList);
