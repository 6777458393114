import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import SubjectListEmbed from '../components/manager/embed/subject-list';
import services from '../services';

const Embed = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setCategories([]);
            const result = await services.Categories.list();
            setCategories(result);
        } catch (e) {
            // Handle error
        } finally {
            // Perform cleanup or final actions
            //this.loadingCategories = false
        }
    };

    return (
        <Container className="manager-content">
            <Routes>
                <Route
                    path="/"
                    element={<SubjectListEmbed categories={categories} />}
                />
            </Routes>
        </Container>
    );
};

export default inject('store')(observer(Embed));
