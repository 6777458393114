import React from 'react';
import {
    DropdownThemedButton,
    ThemedButton,
} from '@awarego/awarego-components';
import { Link } from 'react-router-dom';

function CreateAssessmentButton({
    cmi5enabled,
    buttonParams,
    buttonStyle,
    links = {
        regularLink: '/trainings/create',
        scormLink: '/trainings/create_scorm',
    },
    texts = {
        singleButton: 'Create new',
        dropdownButtonRegular: 'Training',
        dropdownButtonScorm: 'Training (SCORM, cmi5)',
    },
}) {
    if (!cmi5enabled)
        return (
            <ThemedButton
                mode="primary"
                text={texts.singleButton}
                as={Link}
                to={links.regularLink}
                $center
                {...buttonParams}
                {...buttonStyle}
            />
        );
    return (
        <DropdownThemedButton
            text={texts.singleButton}
            btnProps={{ mode: 'primary', $center: true, ...buttonParams }}
            items={[
                {
                    text: texts.dropdownButtonRegular,
                    props: {
                        component: Link,
                        to: links.regularLink,
                    },
                },
                {
                    text: texts.dropdownButtonScorm,
                    props: {
                        component: Link,
                        to: links.scormLink,
                    },
                },
            ]}
        />
    );
}

export default CreateAssessmentButton;
