import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Menu, MenuItem } from '@mui/material';
import moment from 'moment';
import { DarkArrowTooltipIcon } from '@awarego/awarego-components';
import { userRoleName } from '../../utils/helpers';
import TableClient from '../table/TableClient';
import RequireRole from '../../containers/RequireRole';

function ManagersTable({ sendLoginInfo, company, removeManager }) {
    const rowsPerPage = 10;
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetAdmin, setTargetAdmin] = useState(null);
    const columns = useMemo(
        () => [
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => moment(x.value).format('ll'),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: (x) => userRoleName(x.value),
            },

            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
        ],
        []
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={() => {
                            removeManager(
                                company.company_id,
                                row.name,
                                row.email
                            );
                        }}
                        row={row}
                        title="Delete Admin"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
                <DarkArrowTooltipIcon
                    customFunction={handleMenuOpen}
                    row={row}
                    title="More"
                    icon={'more'}
                ></DarkArrowTooltipIcon>
            </>
        );
    };
    const handleMenuOpen = (e, admin) => {
        setTargetAdmin(admin);
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e) => {
        setTargetAdmin(null);
        setAnchorEl(null);
    };

    return (
        <>
            <TableClient
                columns={columns}
                data={company.managers.slice() || []}
                hidePagination={
                    company.managers && company.managers.length < rowsPerPage
                }
                initialPageSize={rowsPerPage}
            />
            {targetAdmin && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={targetAdmin != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    <MenuItem
                        onClick={(e) => {
                            sendLoginInfo(
                                targetAdmin.email,
                                company.company_id
                            );
                            handleMenuClose(e);
                        }}
                    >
                        Send login link
                    </MenuItem>
                </Menu>
            )}
        </>
    );
}

export default observer(ManagersTable);
