import React, { Fragment, useEffect, useState, useMemo } from 'react';
import {
    BigModal,
    FlexDiv,
    CheckboxInput,
    TextTiny,
    SearchInput,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import ScoreFilter from './scoreFilter';
import isEqual from 'lodash/isEqual';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import EmployeesCheckboxTable from './EmployeesCheckboxTable';
import BouncedEmailsTable from './BouncedEmailsTable';
import { useStores } from '../../../hooks/use-stores';

function EmployeesModal({
    companyId,
    productId,
    productEmployeesStoresFactory,
    product,
}) {
    const { modalEmployeesStore: productEmployeesStore } =
        productEmployeesStoresFactory;

    const {
        listType,
        listIdHash,
        order,
        pageIndex,
        pageSize,
        subheading,
        minScore,
        maxScore,
        scoreName,
        selectedListsInFilter,
        userListsSelectedDescription,
    } = productEmployeesStore;

    const [showListsFilter, setShowListsFilter] = useState(null);
    const { authStore } = useStores();
    const { anonymousTracking } = authStore;

    const openListsFilter = (event) => {
        setShowListsFilter(event.currentTarget);
    };

    const hideListsFilter = () => {
        setShowListsFilter(null);
    };

    const hideEmployeesModal = () => {
        productEmployeesStoresFactory.hideEmployeesModal();
    };

    const listCheckboxInputLabel = useMemo(() => {
        if (
            selectedListsInFilter.length === 0 ||
            selectedListsInFilter.length === product.selectedUserListsIds.length
        )
            return `Select all (${product.selectedUserListsIds.length})`;
        return `Selected (${selectedListsInFilter.length}/${product.selectedUserListsIds.length})`;
    }, [product.selectedUserListsIds.length, selectedListsInFilter.length]);

    useEffect(() => {
        if (listType) {
            if (
                !anonymousTracking ||
                (anonymousTracking && listType == 'bounced')
            ) {
                productEmployeesStore.loadEmployeesList(companyId, productId);
            }
        }
    }, [
        companyId,
        productId,
        listType,
        listIdHash,
        pageIndex,
        pageSize,
        order,
        productEmployeesStore,
        minScore,
        maxScore,
        product.selectedUserListsIds,
    ]);

    const onScoreFilterApply = (minScore, maxScore) => {
        productEmployeesStore.setScores(minScore, maxScore);
    };

    const modalContent = useMemo(() => {
        if (!listType) return;
        if (listType === 'bounced')
            return (
                <BouncedEmailsTable
                    store={productEmployeesStore}
                    companyId={companyId}
                    productId={productId}
                />
            );
        return (
            <EmployeesCheckboxTable
                store={productEmployeesStore}
                companyId={companyId}
                productId={productId}
                product={product}
                anonymousTracking={anonymousTracking}
            />
        );
    }, [listType, productEmployeesStore, companyId, productId, product]);

    return (
        <Fragment>
            <BigModal
                isOpen={listType != null}
                onRequestClose={hideEmployeesModal}
                heading="View Employees"
                width={1016}
                extraHeaderContent={<TextTiny lighter>{subheading}</TextTiny>}
                filterContent={
                    product && (
                        <FlexDiv gap={16} alignCenter $noWrap>
                            {product.userListsSelected.length > 1 && (
                                <div
                                    className="assessment-filter-input"
                                    onClick={openListsFilter}
                                >
                                    {userListsSelectedDescription}
                                    <ArrowDropDownIcon fontSize="small" />
                                </div>
                            )}
                            {scoreName !== '' && (
                                <ScoreFilter
                                    scoreName={scoreName}
                                    initialMinScore={minScore}
                                    initialMaxScore={maxScore}
                                    onScoreFilterApply={onScoreFilterApply}
                                />
                            )}
                        </FlexDiv>
                    )
                }
                modalContent={modalContent}
            />

            <Menu
                anchorEl={showListsFilter}
                keepMounted
                open={Boolean(showListsFilter)}
                onClose={hideListsFilter}
                disableScrollLock={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <FlexDiv column px={16} pt={8} width={240}>
                    <SearchInput
                        searchValue={product.userListSearchValue}
                        placeholder="Search"
                        stretched="true"
                        onChange={(e) => {
                            product.setUserListSearchValue(e.target.value);
                        }}
                        onClear={() => {
                            product.setUserListSearchValue('');
                        }}
                    />
                    <CheckboxInput
                        label={listCheckboxInputLabel}
                        checked={isEqual(
                            product.selectedUserListsIds
                                .slice()
                                .sort((a, b) => a - b),
                            selectedListsInFilter.slice().sort((a, b) => a - b)
                        )}
                        color="primary"
                        onClick={() => {
                            productEmployeesStore.toggleAllListsSelected(
                                product.selectedUserListsIds
                            );
                        }}
                    />
                    {product.filteredUserListsSelected
                        .sort((a, b) => a.name - b.name)
                        .map((list) => (
                            <CheckboxInput
                                checked={selectedListsInFilter.includes(
                                    list.id
                                )}
                                label={list.name}
                                key={list.id}
                                noYPadding={true} //Removes Y padding and margin, making it fit better into table row
                                onChange={() =>
                                    productEmployeesStore.toggleListSelected(
                                        list.id
                                    )
                                }
                            />
                        ))}
                </FlexDiv>
            </Menu>
        </Fragment>
    );
}

export default observer(EmployeesModal);
