import React, {
    useCallback,
    useEffect,
    Fragment,
    useState,
    useMemo,
} from 'react';
import {
    FlexDiv,
    Heading1,
    SpaceBetweenDiv,
    palette,
    TextDefault,
    TextLead,
    RadioTabInput,
} from '@awarego/awarego-components';
import Modal from 'react-modal';
import { useStores } from '../../hooks/use-stores';
import { Navigate, useNavigate } from 'react-router-dom';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import PaymentForm from './PaymentForm';
import BillingForm from './BillingForm';
import PaymentConfirm from './PaymentConfirm';
import PlanCard from '../../components/manager/company/planCard';
import EnterprisePlan from '../../components/manager/company/enterprise-plan';
import AllPlansInclude from '../../components/manager/company/all-plans-include';
import FAQ from '../../components/manager/company/faq';

function Upgrade({ companyId }) {
    let navigate = useNavigate();

    const { subscriptionStore, store, commonStore } = useStores();

    const { currentCompany, havePartnerSubscription } = store;

    const { loadingPlans, enterprise_units, basePlans, error, legacyKeyFacts } =
        subscriptionStore;

    const [paymentModalMode, setPaymentModalMode] = useState(0);
    const [billedYearly, setBilledYearly] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const activePackage = currentCompany.subscription;

    const basePlansEffective = useMemo(() => {
        return basePlans.filter((plan) => {
            if (
                plan.baseId.startsWith('free') &&
                activePackage &&
                !activePackage?.externalId?.startsWith('free')
            )
                return false;

            return true;
        });
    });

    useEffect(() => {
        // record subscription_upgrade_view event
        commonStore.saveAnalyticsEvent(
            'account',
            'subscription_upgrade_view ',
            {}
        );
    }, []);

    const doLoadData = useCallback(async () => {
        await subscriptionStore.loadPlans(
            companyId,
            activePackage && activePackage.package_users
        );
    }, [activePackage, companyId, subscriptionStore]);

    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const activePackageBaseId = useMemo(() => {
        if (
            !activePackage ||
            activePackage.package_trial ||
            activePackage.externalId == null
        )
            return;
        else {
            return activePackage.externalId.replace(/-yearly|-monthly/, '');
        }
    }, [activePackage]);

    const seatsChanged = useCallback(
        (plan) => {
            if (!activePackage) return false;
            return activePackage.package_users !== plan.selectedSeats;
        },
        [activePackage]
    );
    const billingFrequencyChanged = useCallback(
        (plan) => {
            if (!activePackage) return false;
            return activePackage.billingPeriod !== plan.selected.period_unit;
        },
        [activePackage]
    );

    const getPrimaryButtonText = useCallback(
        (plan) => {
            if (
                activePackage &&
                plan.selected.baseId === activePackageBaseId &&
                !activePackage.package_trial
            ) {
                let text = [];
                if (seatsChanged(plan)) text.push('Change seats');
                if (billingFrequencyChanged(plan))
                    text.push(`Bill ${billedYearly ? 'annually' : 'monthly'}`);
                if (text.length > 0) return text.join(' & ');
                return 'Current plan';
            }

            return `Choose ${plan.name}`;
        },
        [
            activePackage,
            activePackageBaseId,
            billedYearly,
            billingFrequencyChanged,
            seatsChanged,
        ]
    );

    const goToDashboard = async () => {
        await store.loadServerData();
        navigate('/');
    };

    const openPaymentModal = useCallback(
        (plan) => {
            if (plan) {
                setSelectedPlan(plan);
                setSubscriptionStorePlanDetails(plan);
            }
            setPaymentModalMode(1);
        },
        [companyId, subscriptionStore]
    );

    const onPreviousStep = () => {
        setSubscriptionStorePlanDetails(selectedPlan);
        setPaymentModalMode(1);
    };

    const setSubscriptionStorePlanDetails = (plan) => {
        subscriptionStore.setPlan(plan.selected);
        subscriptionStore.setTier(plan.selectedSeats);
        subscriptionStore.estimate(
            companyId,
            subscriptionStore.selectedPlan.id,
            subscriptionStore.selectedSeats
        );
    };

    const openPaymentDetails = (billingInfo) => {
        subscriptionStore.setBillingInfo(billingInfo);
        setPaymentModalMode(2);
    };

    const onCouponEntered = (coupon) => {
        subscriptionStore.setCoupon(coupon);
        subscriptionStore.estimateDebounced(
            companyId,
            subscriptionStore.selectedPlan.id,
            subscriptionStore.selectedSeats
        );
    };

    const doPayment = async (paymentToken) => {
        let result = await subscriptionStore.create(
            companyId,
            subscriptionStore.selectedPlan.id,
            subscriptionStore.selectedSeats,
            paymentToken
        );
        if (result) {
            setPaymentModalMode(3);
        }
    };

    const onBillingFrequencyChange = (yearly) => {
        if (billedYearly && yearly) return;
        if (!billedYearly && !yearly) return;
        setBilledYearly(yearly);
        subscriptionStore.selectBillingFrequency(yearly);
    };

    const onSeatsChange = useCallback(
        (event, baseId) => {
            subscriptionStore.selectSeats(baseId, event.target.value);
        },
        [subscriptionStore]
    );

    const closePaymentModal = () => {
        subscriptionStore.setCoupon(null);
        setPaymentModalMode(0);
    };

    if (havePartnerSubscription)
        return <Navigate to="/settings/subscriptions" replace={true} />;

    return (
        <Fragment>
            <ErrorMessage error={error} />
            {activePackage && (
                <FlexDiv
                    alignCenter
                    justifyCenter
                    backgroundColor={palette.graphite.heavyMetalLight}
                    height={48}
                >
                    <TextDefault>
                        Your current subscription is{' '}
                        {activePackage.package_trial
                            ? 'Trial'
                            : activePackage.package_name}{' '}
                        ({activePackage.package_users} seats)
                    </TextDefault>
                </FlexDiv>
            )}

            <FlexDiv
                backgroundColor={palette.lightroom.heavyMetalLightUltra}
                pb={88}
            >
                <div className="container">
                    <FlexDiv column alignCenter>
                        <SpaceBetweenDiv mt={48} mb={96} gap={16}>
                            <Heading1>Choose plan</Heading1>

                            {/* monthly/annually btns */}
                            <RadioTabInput
                                boolean={billedYearly}
                                buttonText1={
                                    <Fragment>
                                        <TextLead bold mr={2}>
                                            Bill annually -{' '}
                                        </TextLead>
                                        <TextLead bold themed>
                                            2 mo. free
                                        </TextLead>
                                    </Fragment>
                                }
                                buttonText2={
                                    <TextLead bold>Bill monthly</TextLead>
                                }
                                onChange={onBillingFrequencyChange}
                            />
                        </SpaceBetweenDiv>

                        {/* subscription plans */}
                        <FlexDiv gap={32} fullWidth justifyCenter>
                            {loadingPlans ? (
                                <ThemedSpinner />
                            ) : (
                                <Fragment>
                                    {basePlansEffective.map((plan) => (
                                        <PlanCard
                                            plan={plan}
                                            key={plan.baseId}
                                            onSeatsChange={onSeatsChange}
                                            disableButton={
                                                activePackage &&
                                                plan.selected.baseId ===
                                                    activePackageBaseId &&
                                                !seatsChanged(plan) &&
                                                !billingFrequencyChanged(plan)
                                            }
                                            enterprise_units={enterprise_units}
                                            openPaymentModal={openPaymentModal}
                                            getPrimaryButtonText={
                                                getPrimaryButtonText
                                            }
                                            billedYearly={billedYearly}
                                            legacyKeyFacts={legacyKeyFacts}
                                            recommended={
                                                plan.selected.definition
                                                    .recommended
                                            }
                                        />
                                    ))}

                                    <EnterprisePlan />
                                </Fragment>
                            )}
                        </FlexDiv>
                    </FlexDiv>
                </div>
            </FlexDiv>

            {!loadingPlans && (
                <FlexDiv
                    backgroundColor={palette.lightroom.heavyMetalLightHigh}
                    pt={96}
                    pb={120}
                    column
                >
                    <div className="container">
                        <AllPlansInclude />
                        <FAQ />
                    </div>
                </FlexDiv>
            )}
            <Modal fade={false} isOpen={paymentModalMode === 1}>
                <BillingForm
                    onComplete={openPaymentDetails}
                    onCancel={closePaymentModal}
                    onCouponEntered={onCouponEntered}
                />
            </Modal>

            <Modal fade={false} isOpen={paymentModalMode === 2}>
                <PaymentForm
                    companyId={companyId}
                    onComplete={doPayment}
                    prevStep={onPreviousStep}
                    onCancel={closePaymentModal}
                />
            </Modal>
            <Modal fade={false} isOpen={paymentModalMode === 3}>
                <PaymentConfirm onComplete={goToDashboard} />
            </Modal>
        </Fragment>
    );
}

export default observer(Upgrade);
