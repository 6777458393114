import React, { useState } from 'react';
import { Block } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../../components/themed/ThemedComponents';
import { TabContent, TabPane } from 'reactstrap';
import UserAssessmentsTable from './UserAssessmentsTable';
import UserTrainingsTable from './UserTrainingsTable';

function UserActivityTable({
    trainings,
    loadingTrainings,
    assessments,
    loadingAssessments,
}) {
    const [activeTab, setActiveTab] = useState('1');

    const tabsChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <Block>
            <ThemedNav clean>
                <ThemedNavItem active={activeTab === '1'}>
                    <ThemedNavLink
                        onClick={() => {
                            tabsChange('1');
                        }}
                    >
                        Training programs
                    </ThemedNavLink>
                </ThemedNavItem>
                <ThemedNavItem active={activeTab === '2'}>
                    <ThemedNavLink
                        onClick={() => {
                            tabsChange('2');
                        }}
                    >
                        Assessments
                    </ThemedNavLink>
                </ThemedNavItem>
            </ThemedNav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    {!loadingTrainings ? (
                        <UserTrainingsTable trainings={trainings} />
                    ) : (
                        <ThemedSpinner />
                    )}
                </TabPane>
                <TabPane tabId="2">
                    {!loadingAssessments ? (
                        <UserAssessmentsTable assessments={assessments} />
                    ) : (
                        <ThemedSpinner />
                    )}
                </TabPane>
            </TabContent>
        </Block>
    );
}

export default observer(UserActivityTable);
