import React from 'react';
import { observer } from 'mobx-react';
import { Image } from '@awarego/awarego-components';

function MaterialImage({ src }) {
    return (
        <Image
            maxWidth={300}
            contain
            dropShadow
            src={src}
            alt="Preview image of this material"
        />
    );
}

export default observer(MaterialImage);
