import { formatDateTimeAndTimezone } from '../../../utils/helpers';
import {
    SCHEDULE_FREQUENCY,
    SCHEDULE_DAYS,
    SCHEDULE_HOURS,
    SCHEDULE_DAY_POS,
} from '../../../utils/constants';
import { scheduleFrequencyName, getRRule } from '../reports-helpers';
//Used to initialize the 'Next generated at' label
const initRRule = getRRule({
    frequency: 'WEEKLY',
    weekDay: 'MO',
    hourOfDay: 7,
    weekdayPosition: '1',
});


export const  fieldDefinitions= {
        id: {
            name: 'id',
            type: 'hidden',
            value: null,
        },

        name: {
            name: 'name',
            label: 'Report name',
            rules: 'required|string',
            placeholder: 'Enter name of report',
            hooks: {
                onChange(field) {
                    field.state.form
                        .$('customEmailSubject')
                        .set('placeholder', field.value);
                    field.state.form.validate();
                },
            },
        },

        employeeLists: {
            name: 'employeeLists',
            label: 'Select employee lists that will be included in the report',
            placeholder: 'Select from existing lists',
            rules: 'array',
            value: [],
            disabled: false,
            hooks: {
                onChange(field) {
                    field.state.form.validate();
                },
            },
        },

        allEmployees: {
            name: 'allEmployees',
            label: 'Select all employee lists',
            rules: 'boolean|requiredIfEmptyArray:employeeLists',
            type: 'checkbox',
            value: false,
            hooks: {
                onChange(field) {
                    /** Before validating I need to make sure it isn't disabled */
                    field.state.form.$('employeeLists').set('disabled', false);
                    field.state.form.$('employeeLists').validate();

                    /**Disable employeeLists field if 'allEmployees' is checked */
                    if (field.value) {
                        field.state.form
                            .$('employeeLists')
                            .set('disabled', true);
                    }
                    field.state.form.validate();
                },
            },
        },

        scheduleFrequency: {
            name: 'scheduleFrequency',
            label: 'Frequency',
            rules: 'string',
            value: 'WEEKLY',
            extra: Object.values(SCHEDULE_FREQUENCY).map((x) => ({
                label: scheduleFrequencyName(x),
                value: x,
            })),
            hooks: {
                onChange(field) {
                    if (field.value === 'MONTHLY') {
                        field.state.form
                            .$('scheduleWeekday')
                            .set('label', null);
                        field.state.form
                            .$('scheduleWeekdayPosition')
                            .set(
                                'label',
                                field.value.charAt(0) +
                                    field.value.slice(1).toLowerCase() +
                                    ' on'
                            );
                    } else {
                        field.state.form
                            .$('scheduleWeekday')
                            .set(
                                'label',
                                field.value.charAt(0) +
                                    field.value.slice(1).toLowerCase() +
                                    ' on'
                            );
                    }
                    module.exports.updateNextRunAt(field.state.form);
                },
            },
        },

        scheduleWeekday: {
            name: 'scheduleWeekday',
            label: 'Weekly on',
            rules: 'string',
            value: 'MO',
            extra: SCHEDULE_DAYS.map((x) => ({
                label: x.name,
                value: x.id,
            })),
            hooks: {
                onChange(field) {
                    module.exports.updateNextRunAt(field.state.form);
                },
            },
        },

        scheduleHour: {
            name: 'scheduleHour',
            rules: 'integer',
            value: 7,
            extra: SCHEDULE_HOURS.map((x) => ({
                label: x.name,
                value: x.id,
            })),
            hooks: {
                onChange(field) {
                    module.exports.updateNextRunAt(field.state.form);
                },
            },
        },

        scheduleWeekdayPosition: {
            name: 'scheduleWeekdayPosition',
            rules: 'integer',
            label: 'Monthly on',
            value: 1,
            extra: SCHEDULE_DAY_POS.map((x) => ({
                label: x.name,
                value: x.id,
            })),
            hooks: {
                onChange(field) {
                    module.exports.updateNextRunAt(field.state.form);
                },
            },
        },

        nextReportSentAtText: {
            name: 'nextReportSentAtText',
            type: 'hidden',
            value:
                'Next report will be generated on ' +
                formatDateTimeAndTimezone(initRRule.after(new Date(), true)),
        },

        recipientsEmails: {
            name: 'recipientsEmails',
            label: 'Enter Emails, seperated by comma(,)',
            rules: 'multipleEmails',
            multiple: true,
            value: '',
        },

        customEmailSubject: {
            name: 'customEmailSubject',
            label: 'Add custom email subject',
            placeholder: '',
            rules: 'string',
            value: '',
        },
    }

export const    ruleDefinitions= {
        multipleEmails: {
            function: (value) =>
                value.match(
                    /^\s*[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}\s*(?:[,;]\s*[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}\s*)*$/
                ),
            message:
                'One of the :attribute email addresses is not valid, or they are not seperated with comma(,) or semi-comma(;).',
        },

        /** This is basically a copy of the required_unless rule from the validatorjs package but knows how to handle boolean values */
        requiredUnless: {
            function(val, req, attribute) {
                req = this.getParameters();

                if (req[1] === 'true') {
                    req[1] = true;
                } else if (req[1] === 'false') {
                    req[1] = false;
                }

                if (
                    this.validator._objectPath(this.validator.input, req[0]) !==
                    req[1]
                ) {
                    return this.validator.getRule('required').validate(val);
                }

                return true;
            },
        },

        requiredIfEmptyArray: {
            /** Rule passes if this is true or the depending array is not empty (employeeLists)*/
            function(val, req) {
                req = this.getParameters();

                const arrayValue = this.validator._objectPath(
                    this.validator.input,
                    req[0]
                );

                const arrayIsNotEmpty =
                    Array.isArray(arrayValue) && arrayValue.length > 0;

                return val || arrayIsNotEmpty;
            },
            message:
                'Either all employees or at least one employee list must be selected.',
        },
    }

export function  updateNextRunAt(form) {
        const rruleObject = getRRule({
            frequency: form.$('scheduleFrequency').get('value'),
            weekDay: form.$('scheduleWeekday').get('value'),
            hourOfDay: form.$('scheduleHour').get('value'),
            weekdayPosition: form.$('scheduleWeekdayPosition').get('value'),
        });

        form.$('nextReportSentAtText').set(
            'value',
            'Next report will be generated on ' +
                formatDateTimeAndTimezone(rruleObject.after(new Date(), true))
        );
    }

