import React from 'react';
import { observer } from 'mobx-react';
import {
    FlexDiv,
    SpaceBetweenDiv,
    Heading2,
    Image,
} from '@awarego/awarego-components';
import LanguageSelector from './language-selector';
function PreviewModalHeader({
    titlePrefix,
    title,
    details,
    onClose,
    showLanguageSelector,
    availableLanguages,
    selectedLanguage,
    onSelectLanguage,
}) {
    return (
        <SpaceBetweenDiv
            minHeight={showLanguageSelector ? 100 : 80}
            px={48}
            dropShadowBelow
            positionRelative
        >
            <FlexDiv gap={8}>
                <Heading2 regular>{titlePrefix}</Heading2>
                <Heading2>{title}</Heading2>
            </FlexDiv>

            <FlexDiv gap={24} alignCenter>
                {details}

                <FlexDiv $noWrap gap={16}>
                    {showLanguageSelector && (
                        <LanguageSelector
                            selectedLanguage={selectedLanguage}
                            handleSelectLanguage={onSelectLanguage}
                            availableLanguages={availableLanguages}
                        />
                    )}
                    <Image
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onClose}
                        link
                    />
                </FlexDiv>
            </FlexDiv>
        </SpaceBetweenDiv>
    );
}

export default observer(PreviewModalHeader);
