import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    Fragment,
} from 'react';
import { observer } from 'mobx-react';
import StyledLink from '../../../components/StyledLink';
import {
    ProgressWithNumber,
    palette,
    DarkArrowTooltipIcon,
    CopyToClipboardWrapper,
    FlexDiv,
    TextTiny,
    Tag,
} from '@awarego/awarego-components';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import RequireRole from '../../RequireRole';
import { Menu, MenuItem, Switch } from '@mui/material';
import { useStores } from '../../../hooks/use-stores';
import TableClient from '../../../components/table/TableClient';
import { createNoUsersView } from '../users-helpers';
import { LINKS } from '../../../utils/constants';
function TrainingUsersTable({
    currentCompany,
    users,
    training,
    ssoEnabled,
    anonymousTracking,
    searchValue,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetTraining, setTargetTraining] = useState(null);
    const { commonStore, automationStore, userStore } = useStores();
    const [showSubjectsDetails, setShowSubjectsDetails] = useState(false);
    const { perUserDetailsIndex } = automationStore;
    const companyId = currentCompany.company_id;
    const trainingUserDetailLink = {
        url: `/trainings/${training.id}/user/`,
        suffix: 'email',
    };
    const rowsPerPage = 5;

    const columns = useMemo(() => {
        const staticColumns = [];

        if (!anonymousTracking) {
            staticColumns.push({
                Header: (
                    <span
                        style={{
                            whiteSpace: 'nowrap!important',
                        }}
                    >
                        Employee
                    </span>
                ),
                id: 'name',
                accessor: 'name',
                Cell: (x) => {
                    return (
                        <Fragment>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <StyledLink
                                    to={`${trainingUserDetailLink.url}${x.row.original.email}`}
                                    $hoverColor={palette.graphite.onyx}
                                    $underlined={true}
                                >
                                    {x.value && x.value.length > 0 ? (
                                        x.value
                                    ) : (
                                        <Tag text="No Name" />
                                    )}
                                </StyledLink>
                            </span>
                        </Fragment>
                    );
                },
            });

            staticColumns.push({
                Header: 'Email',
                id: 'email',
                accessor: 'email',
                Cell: (x) => x.value,
            });
        } else {
            staticColumns.push({
                Header: 'Employee',
                id: 'name',
                accessor: 'name',
                Cell: (x) => x.value,
            });
        }

        if (!showSubjectsDetails && !anonymousTracking) {
            staticColumns.push({
                Header: 'List(s)',
                accessor: 'lists',
                id: 'lists',
                Cell: (x) => {
                    const handleCellClick = (e) => {
                        e.stopPropagation();
                    };

                    return (
                        <span onClick={handleCellClick}>
                            <NameAndSublistCell
                                subListItems={x.value}
                                maxVisibleSubListCount={1}
                                link={LINKS.list}
                            ></NameAndSublistCell>
                        </span>
                    );
                },
                disableSortBy: true,
                disableGlobalFilter: true,
            });
        }

        // Dynamic columns with subject details:
        let dynamicColumns = [];
        if (showSubjectsDetails && !anonymousTracking) {
            dynamicColumns = training._rawData.subjects.map((s) => {
                return {
                    Header: s.name,
                    accessor: s.name,
                    id: s.name,
                    Cell: (x) => {
                        return buildSubjectIcons(s, x.row.original.email);
                    },
                    disableSortBy: true,
                    disableGlobalFilter: true,
                };
            });
        }

        const remainingStaticColumns = [
            {
                Header: 'Completion Rate',
                accessor: 'score',
                id: 'score',
                Cell: (x) => {
                    return (
                        <span style={{ textWrap: 'nowrap' }}>
                            <ProgressWithNumber
                                value={Math.round(
                                    (100 * x.row.original.score) /
                                        x.row.original.total
                                )}
                                medium="true"
                                width={150}
                                height={6}
                            />
                        </span>
                    );
                },
                disableGlobalFilter: true,
            },
        ];

        if (!anonymousTracking) {
            remainingStaticColumns.push({
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            });
        }

        return [...staticColumns, ...dynamicColumns, ...remainingStaticColumns];
    }, [showSubjectsDetails, training._rawData.subjects, ssoEnabled]);

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <span style={{ textWrap: 'nowrap' }}>
                        {!ssoEnabled && (
                            <DarkArrowTooltipIcon
                                customFunction={onDeleteEmployee}
                                row={row}
                                title="Delete Employee"
                                icon={'delete'}
                            ></DarkArrowTooltipIcon>
                        )}
                        <DarkArrowTooltipIcon
                            customFunction={handleMenuOpen}
                            row={row}
                            title="More"
                            icon={'more'}
                        ></DarkArrowTooltipIcon>
                    </span>
                </RequireRole>
            </>
        );
    };

    useEffect(() => {
        loadUsersDetails();
    }, []);

    const handleMenuOpen = (e, training) => {
        setTargetTraining(training);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setTargetTraining(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    const loadUsersDetails = useCallback(async () => {
        if (!automationStore.perUserDetailsLoaded) {
            await automationStore.loadUsersDetails(companyId, training.id);
        }
    }, [training.id, companyId]);

    const onDeleteEmployee = (e, user) => {
        e.stopPropagation();
        let lists = Array.isArray(user.lists_ids)
            ? user.lists_ids
            : user.lists_ids.split(',').map((x) => x.trim());
        let listNames = user.lists.map((o) => o.name);

        let userToDelete = {
            companyId: companyId,
            listsId: lists,
            email: user.email,
        };
        let description = `By doing this, user '${user.email}' will be removed from following lists: '${listNames}' 
        and from all training programs associated with this list(s).`;
        userStore.buildDeleteUserDialog(userToDelete, description, () => {
            training.removeUser(user.email);
            commonStore.success('Employee deleted');
        });
    };

    const buildSubjectIcons = (s, email) => {
        let userData = perUserDetailsIndex[email];
        let viewed =
            userData &&
            userData.viewed_subject_ids &&
            userData.viewed_subject_ids.indexOf(s.subject_id) > -1;
        let answered =
            userData &&
            userData.answered_subject_ids &&
            userData.answered_subject_ids.indexOf(s.subject_id) > -1;

        switch (true) {
            case viewed && !answered:
                return (
                    <img
                        className="view-mark"
                        title={'Viewed'}
                        alt="icon"
                        src="/static/img/icon-eye.svg"
                    />
                );
            case viewed && answered:
                return (
                    <img
                        className="check-mark"
                        alt="icon"
                        title={'Viewed and Answered correctly'}
                        src="/static/img/icon-checked.svg"
                    />
                );
            case !viewed && answered:
                return (
                    <img
                        className="check-mark"
                        alt="icon"
                        title={'Answered correctly'}
                        src="/static/img/icon-checked-yellow.svg"
                    />
                );
            case !viewed && !answered:
                return (
                    <img
                        className="check-mark not-checked"
                        alt="icon"
                        src="/static/img/icon-not-checked.svg"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {users != undefined && (
                <>
                    {!anonymousTracking && users && users.length > 0 && (
                        <FlexDiv alignCenter ml={16} mb={-22}>
                            <TextTiny bold>
                                {showSubjectsDetails
                                    ? 'Hide details'
                                    : 'Show details'}
                            </TextTiny>
                            <Switch
                                checked={showSubjectsDetails}
                                onChange={(event) => {
                                    setShowSubjectsDetails(
                                        !showSubjectsDetails
                                    );
                                }}
                                color="primary"
                            />
                        </FlexDiv>
                    )}
                    <TableClient
                        columns={columns}
                        data={users}
                        hidePagination={users && users.length <= rowsPerPage}
                        defaultSortBy={'name'}
                        defaultSortDescent={false}
                        link={
                            !anonymousTracking ? trainingUserDetailLink : null
                        }
                        NoDataComponent={createNoUsersView(
                            currentCompany,
                            false,
                            'users',
                            false
                        )}
                        filterValue={searchValue}
                    />
                </>
            )}
            {targetTraining && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={targetTraining != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    <MenuItem
                        onClick={(e) => {
                            handleMenuClose(e);
                        }}
                    >
                        <CopyToClipboardWrapper
                            copyText={targetTraining.access_link}
                            onCopied={commonStore.copied}
                        >
                            Copy sign in link &nbsp;
                        </CopyToClipboardWrapper>
                    </MenuItem>
                </Menu>
            )}
        </>
    );
}

export default observer(TrainingUsersTable);
