import React, { Fragment } from 'react';
import {
    FlexDiv,
    Heading1,
    SpaceBetweenDiv,
    TextLead,
    Heading3,
    CleanHR,
} from '@awarego/awarego-components';

const FAQdefinition = [
    {
        question: 'How are seats calculated?',
        answer: 'Seats are calculated as the total number of unique users who are subscribed to any list in any given month',
    },
    {
        question: 'What happens if I exceed the user count?',
        answer: 'If you exceed the maximum number of users allowed in your plan, we’ll adjust your fee from that day',
    },
    {
        question: 'Do I have to pay for content updates?',
        answer: 'Our clients get full access to all training and assessment content which is updated regularly at no extra fee',
    },
    {
        question: 'Is there a discount for number of seats?',
        answer: 'Yes. Choose the number of seats to see the discounted price',
    },
];

function FAQ() {
    return (
        <Fragment>
            <Heading1 mt={96} mb={32} displayBlock>
                FAQ
            </Heading1>
            <SpaceBetweenDiv desktopOnly $noWrap gap={32}>
                {FAQdefinition.slice(0, 3).map((q, i) => (
                    <FlexDiv column key={i}>
                        <Heading3 mb={8}>{q.question}</Heading3>
                        <TextLead lighter>{q.answer}</TextLead>
                    </FlexDiv>
                ))}
            </SpaceBetweenDiv>
            <CleanHR desktopOnly my={32} />
            <FlexDiv column desktopOnly>
                <Heading3 mb={8}>{FAQdefinition[3].question}</Heading3>
                <TextLead lighter>{FAQdefinition[3].answer}</TextLead>
            </FlexDiv>
            <FlexDiv column mobileOnly gap={32}>
                {FAQdefinition.map((q, i) => (
                    <FlexDiv column key={i}>
                        <Heading3 mb={8}>{q.question}</Heading3>
                        <TextLead lighter>{q.answer}</TextLead>
                    </FlexDiv>
                ))}
            </FlexDiv>
        </Fragment>
    );
}

export default FAQ;
