import React, { useState, useEffect, useCallback } from 'react';
import FormDef from '../../../forms/list';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import {
    TextWithTooltipIcon,
    TextLead,
    ThemedButton,
    BigModal,
    TextInput,
} from '@awarego/awarego-components';
import UserModalTooltipText from './ModalTooltipText';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../../hooks/use-stores';

const CreateEditListModal = ({
    companyId,
    list,
    errorSetter,
    onRequestClose: modalStateSetter,
    isModalOpen,
}) => {
    const navigate = useNavigate();
    const { listStore } = useStores();
    const { loadingList, savingList } = listStore;

    const navigateToList = (listId) => {
        navigate(`/users/list/${listId}`);
    };

    const onSuccess = useCallback(async () => {
        let listId;
        try {
            listId = await listStore.save(companyId, list && list.id, {
                active: 1,
                ...form.values(),
            });
            form.update({ name: '' });
        } catch (e) {
            errorSetter('Error: List could not been saved.');
            console.log(e);
        } finally {
            modalStateSetter(false);
            if (!list) {
                navigateToList(listId);
            }
        }
    }, [
        listStore,
        navigateToList,
        companyId,
        list,
        errorSetter,
        modalStateSetter,
    ]);

    const [form, setForm] = useState(
        new FormDef(
            {},
            {
                hooks: {
                    onSuccess: onSuccess,
                },
            }
        )
    );

    useEffect(() => {
        form.update(list ? { name: list.name } : {});
    }, [list, form]);

    const isNew = !list;

    return (
        <BigModal
            isOpen={isModalOpen}
            onRequestClose={() => modalStateSetter(false)}
            heading={isNew ? 'Create Employee list' : 'Rename list'}
            extraHeaderContent={
                <TextLead lighter mt={4}>
                    <TextWithTooltipIcon
                        tooltipText={<UserModalTooltipText />}
                        textComponent={
                            'Organize employees into lists based on your goals.'
                        }
                    />
                </TextLead>
            }
            modalContent={
                !loadingList && (
                    <div className="create-campaign">
                        <TextInput stretched {...form.$('name').bind()} />
                    </div>
                )
            }
            modalFooterContent={
                savingList ? (
                    <ThemedSpinner size={50} />
                ) : (
                    <>
                        <ThemedButton
                            mode="secondary"
                            onClick={() => {
                                modalStateSetter(false);
                            }}
                            text="Cancel"
                        />{' '}
                        <ThemedButton
                            mode="primary"
                            onClick={form.onSubmit}
                            disabled={!form.values().name.length > 0}
                            text={isNew ? 'Create' : 'Update'}
                        />
                    </>
                )
            }
        />
    );
};

export default observer(CreateEditListModal);
