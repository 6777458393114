import { FlexDiv, palette } from '@awarego/awarego-components';
import React from 'react';
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import styled from 'styled-components';
import { calculateColor, defaultZero } from '../../../utils/helpers';
import { rgba } from 'polished';
import { observer } from 'mobx-react';

// TO USE THIS COMPONENT, SPECIFY:
// - percentage

// OPTIONAL PROPS:

// halfCircle/fullCircle
// - by default, the component creates a circle cut in 3/4
// - use halfCircle prop if you want to use a circle cut in half
// - use fullCircle prop if you want to use a full circle

// width
// - by default, the width of this component is 200px
// - if custom width in px needed, specify it as width={80}

// top
// - this prop determines positioning of text within the circle
// - if custom position of text in px needed (which it most probably will), specify is as top={20}

// mt (= margin-top)
// - default margin top is 0
// - use this if circle needs better vertical positioning within its surroundings
// - if custom margin top in px needed, specify it as mt={8}

// CONTENT WITHIN THE CIRCLE

// - text
// -- use text prop to insert any kind of text within the circle - it gives flexibility in terms of what the text should be and its styling
// -- example use:  <SemiCircleProgress {...} text={<TextLead>45 ouf of 100</TextLead>}/>

// - percentageText
// -- use percentageText if you want to display the percentage only
// -- example use: <SemiCircleProgress {...} percentageText/>
// -- you can also choose to not have percentageText colored by using prop percentageTextNotColored

// - showNA
// -- if showNA is true, the component will have text N/A and will be grey

function SemiCircleProgress({
    percentage = 100,
    halfCircle = false,
    fullCircle = false,
    width = 200,
    top = 100,
    text = '',
    mt = 0,
    trailColorGray = false,
    percentageText,
    percentageTextNotColored,
    showNA,
    children,
}) {
    const setHeight = () => {
        if (halfCircle) return width / 1.84;
        if (fullCircle) return width;
        return width / 1.36;
    };

    const setCircleRatio = () => {
        if (halfCircle) return 0.48;
        if (fullCircle) return 1;
        return 0.62;
    };
    const setRotation = () => {
        if (halfCircle) return 0.76;
        if (fullCircle) return 0.5;
        return 0.69;
    };

    const Wrapper = styled.div`
        width: ${width}px;
        height: ${setHeight()}px;
        position: relative;
        margin-top: ${mt}px;
    `;
    const PercentageText = styled.span`
        font-size: ${width / 3.16}px;
        font-weight: 800;
        color: ${(props) => props.color};
    `;

    const NAText = styled.span`
        font-size: ${width / 4}px;
        font-weight: 800;
        color: ${rgba(palette.graphite.heavyMetal, 0.6)};
    `;

    const value = defaultZero(percentage);

    const returnContent = () => {
        if (showNA) return <NAText>N/A</NAText>;
        if (percentageText)
            return (
                <PercentageText
                    color={
                        percentageTextNotColored
                            ? palette.graphite.heavyMetal
                            : calculateColor(value)
                    }
                >
                    {value}
                </PercentageText>
            );
        return text;
    };

    return (
        <Wrapper>
            <CircularProgressbarWithChildren
                value={value}
                circleRatio={setCircleRatio()}
                strokeWidth={14}
                styles={buildStyles({
                    rotation: setRotation(),
                    pathColor: calculateColor(value),
                    trailColor:
                        trailColorGray || showNA
                            ? '#EBEEF1'
                            : rgba(calculateColor(value), 0.1),
                    pathTransitionDuration: 1,
                    strokeLinecap: 'round',
                })}
            >
                {children}
            </CircularProgressbarWithChildren>
            <FlexDiv
                marginAuto
                positionAbsolute
                width={width}
                justifyCenter
                top={top}
                column
                alignCenter
            >
                {returnContent()}
            </FlexDiv>
        </Wrapper>
    );
}

export default observer(SemiCircleProgress);
