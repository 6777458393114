import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = {
    managerEmail: {
        name: 'managerEmail',
        label: 'Work Email Address',
        rules: 'required|email|string',
        placeholder: 'name@company.com',
        type: 'email',
    },
};
class Auth extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default Auth;
