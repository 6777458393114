import React from 'react';
import { TextDefault, FlexDiv, Image } from '@awarego/awarego-components';

const Banner = ({
    backgroundColor,
    roundedCorners = false,
    iconPath,
    iconAltText,
    iconStyles = null,
    heading,
    descriptionSection,
    actionSection,
}) => {
    return (
        <FlexDiv
            py={32}
            px={32}
            backgroundColor={backgroundColor}
            alignCenter
            fullWidth
            gap={16}
            borderRadius={roundedCorners ? 2 : null}
        >
            <Image src={iconPath} alt={iconAltText} style={iconStyles} />
            <FlexDiv column flexGrow={1}>
                <TextDefault bold>{heading}</TextDefault>
                {descriptionSection ? descriptionSection : null}
            </FlexDiv>
            {actionSection ? actionSection : null}
        </FlexDiv>
    );
};

export default Banner;
