import React, { useMemo, Fragment } from 'react';
import {
    Heading2,
    Heading4,
    Block,
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    palette,
    ThemedButton,
    Heading3,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import moment from 'moment';
import { observer } from 'mobx-react';
import { formatCurrency } from '../../utils/helpers';
import TableClient from '../../components/table/TableClient';

function SubscriptionOverview({
    partnerId,
    partnerSubscriptionsOverview,
    loadingPartnerSubscriptionsOverview,
}) {
    const { partnerStore } = useStores();
    let totalRow = null;
    let rowsWithTotal = [];

    if (
        partnerSubscriptionsOverview &&
        partnerSubscriptionsOverview.rows &&
        partnerSubscriptionsOverview.rows.length > 0
    ) {
        totalRow = {
            planName: '',
            totalCompanies: null,
            totalSeats: 'Total',
            totalMonthlyCost: formatCurrency(
                partnerSubscriptionsOverview.sumMonthlyCost,
                partnerSubscriptionsOverview.currencyCode
            ),
            customRow: true,
        };

        rowsWithTotal = [totalRow, ...partnerSubscriptionsOverview.rows];
    }

    const payNow = async () => {
        await partnerStore.collectPartnerPayment(partnerId);
    };

    const openCBportal = async () => {
        if (!partnerSubscriptionsOverview) return;

        window.Chargebee.init({
            site: partnerSubscriptionsOverview.chargebeeSite,
            publishableKey: partnerSubscriptionsOverview.chargebeeClientKey,
        });

        var chargebeeInstance = Chargebee.getInstance();

        let portalSession = await partnerStore.openManagePartnerSession(
            partnerId
        );

        chargebeeInstance.setPortalSession(portalSession);

        let cbPortal = chargebeeInstance.createChargebeePortal();
        cbPortal.open({
            close() {
                chargebeeInstance.logout();
            },
        });
    };

    const overviewColumns = useMemo(
        () => [
            {
                Header: 'Subscription',
                accessor: 'planName',
                Cell: (x) => {
                    return <Heading4>{x.value}</Heading4>;
                },
            },
            {
                Header: 'Total companies',
                accessor: 'totalCompanies',
            },
            {
                Header: 'Total seats',
                accessor: 'totalSeats',
                Cell: (x) => {
                    if (x.row.original.customRow && x.row.index === 0) {
                        return <Heading3 bold>{x.value}</Heading3>;
                    }

                    return x.value;
                },
            },
            {
                Header: 'Monthly cost',
                accessor: 'totalMonthlyCost',
                Cell: observer((x) => {
                    if (x.row.original.customRow && x.row.index === 0) {
                        return <Heading3>{x.value}</Heading3>;
                    } else {
                        return formatCurrency(
                            x.value,
                            partnerSubscriptionsOverview &&
                                partnerSubscriptionsOverview.currencyCode
                        );
                    }
                }),
                tooltip:
                    'Your monthly cost will vary depending on the total number of assigned seats across all companies.',
            },
        ],
        [partnerSubscriptionsOverview]
    );

    return (
        <Block>
            {partnerSubscriptionsOverview &&
            partnerSubscriptionsOverview.totalDues &&
            !partnerSubscriptionsOverview.collected ? (
                <Fragment>
                    {partnerStore.collectingPayment ? (
                        <ThemedSpinner />
                    ) : (
                        <FlexDiv ignoreParentPadding={32}>
                            <SpaceBetweenDiv
                                backgroundColor={palette.vibrant.red}
                                fullWidth
                                px={32}
                                py={16}
                                mb={54}
                            >
                                <FlexDiv column>
                                    <Heading4 bold color={'white'}>
                                        Payment{' '}
                                        {partnerSubscriptionsOverview.hasValidCard
                                            ? 'due'
                                            : 'failed'}
                                    </Heading4>
                                    <TextDefault color={'white'}>
                                        You have due amount of{' '}
                                        {partnerSubscriptionsOverview.totalDues.toFixed(
                                            2
                                        )}{' '}
                                        {
                                            partnerSubscriptionsOverview.currencyCode
                                        }{' '}
                                        since{' '}
                                        {moment
                                            .unix(
                                                partnerSubscriptionsOverview.dueSince
                                            )
                                            .format('LL')}
                                        .{' '}
                                    </TextDefault>
                                </FlexDiv>
                                {partnerSubscriptionsOverview.hasValidCard ? (
                                    <ThemedButton
                                        mode="black"
                                        onClick={() => {
                                            payNow();
                                        }}
                                        $noTextTransform
                                        text="Pay now"
                                    />
                                ) : (
                                    <ThemedButton
                                        mode="black"
                                        onClick={() => {
                                            openCBportal();
                                        }}
                                        $noTextTransform
                                        text="Update your payment method"
                                    />
                                )}
                            </SpaceBetweenDiv>
                        </FlexDiv>
                    )}
                </Fragment>
            ) : null}
            <SpaceBetweenDiv ml={16} pr={32}>
                <FlexDiv column>
                    <Heading2>Account overview</Heading2>
                </FlexDiv>
                <FlexDiv alignRight>
                    <ThemedButton
                        mode="secondary"
                        onClick={openCBportal}
                        text="Manage account"
                    />
                </FlexDiv>
            </SpaceBetweenDiv>
            {loadingPartnerSubscriptionsOverview ? (
                <ThemedSpinner />
            ) : (
                <FlexDiv mt={32}>
                    {partnerSubscriptionsOverview && (
                        <TableClient
                            data={
                                rowsWithTotal.length > 0
                                    ? rowsWithTotal
                                    : partnerSubscriptionsOverview.rows
                            }
                            columns={overviewColumns}
                            hidePagination={true}
                        />
                    )}
                </FlexDiv>
            )}
        </Block>
    );
}

export default observer(SubscriptionOverview);
