import RestService from './RestService';

export default class SystemService extends RestService {
    constructor() {
        super('/system');
    }

    listHooks() {
        return this.get(`${this.baseUrl}/hooks`);
    }

    createHooks() {
        return this.post(`${this.baseUrl}/hooks`);
    }

    impersonate(email) {
        return this.get(`${this.baseUrl}/impersonate/${email}`);
    }
}
