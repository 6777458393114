import { RRule } from 'rrule';
import { SCHEDULE_DAYS, SCHEDULE_FREQUENCY } from '../../utils/constants';
import React from 'react';
import { FlexDiv } from '@awarego/awarego-components';
import NoItemsYetContent from '../../components/manager/no-items-yet-content';

export function getRRule({ frequency, weekDay, hourOfDay, weekdayPosition }) {
    const utcWeekDay = convertLocalWeekDayToUTC(weekDay, hourOfDay);
    const utcHour = convertLocalHourToUTCHour(hourOfDay);

    // By using both the corrected and uncorrected weekday, we can ensure that the report is generated as close to user's requested time as possible
    const weekdayStart = [RRule[utcWeekDay], RRule[weekDay]]; //Monday, Tuesday, Wednesday etc.
    let weekdayPositionStart = weekdayPosition ? weekdayPosition : null; //First, Second, Third, Last Monday e.g.
    let interval = 1;
    let rruleFrequency = RRule.WEEKLY;

    if (frequency === 'BIWEEKLY') {
        interval = 2;
        weekdayPositionStart = null;
    } else if (frequency === 'WEEKLY') {
        weekdayPositionStart = null;
    } else if (frequency === 'MONTHLY') {
        rruleFrequency = RRule.MONTHLY;
    }

    const rrule = {
        freq: rruleFrequency,
        interval: interval,
        byweekday: weekdayStart,
        bysetpos: weekdayPositionStart,
        byhour: utcHour,
        byminute: 0,
        bysecond: 0,
    };

    return new RRule(rrule);
}

export function scheduleFrequencyName(frequencyId) {
    const frequency = Object.keys(SCHEDULE_FREQUENCY).find(
        (x) => SCHEDULE_FREQUENCY[x] === frequencyId
    );
    if (!frequency) return 'Unknown';
    return frequency.charAt(0).toUpperCase() + frequency.slice(1).toLowerCase();
}

/** Given
 * - the id of the weekday user selected ('FR' for example),
 * - the hour selected (23 for example)
 * - the timezone offset, from UTC, for the source date (4 for EDT for example)
 * - the timezone offset, from UTC, for the target date (0 for UTC in this example)
 * - whether we are translating from UTC to local time (or vice-versa)
 * will return the id of the 'corrected weekday'
 * (in this example 'SA' because 23:00 on a Friday in GMT-4 timezone is a Saturday at 03:00 in UTC)*/
export function getCorrectWeekDayId(
    dayOfWeekId,
    hour,
    sourceOffset,
    targetOffset,
    isConvertingFromUTCToLocal = false
) {
    //Make sure we use 0 instead of 24 for hour
    hour = hour === 24 ? 0 : hour;

    const dayOfWeek = getWeekdayIndexFromId(dayOfWeekId);
    // Calculate the hour difference between the source and UTC time zones
    const hourDiff = targetOffset - sourceOffset;

    let targetDayIndex = null;
    if (isConvertingFromUTCToLocal) {
        //This seems to be the magic to make it work to translate from rrule to frequencyText
        targetDayIndex =
            (dayOfWeek - Math.floor((hour + hourDiff) / 24) + 7) % 7;
    } else {
        targetDayIndex = targetDayIndex =
            (dayOfWeek + Math.floor((hour + hourDiff) / 24) + 7) % 7;
    }

    return SCHEDULE_DAYS[targetDayIndex].id;
}

export function getWeekdayIndexFromId(id) {
    return SCHEDULE_DAYS.findIndex((x) => x.id === id);
}

export function convertLocalWeekDayToUTC(localWeekDayId, localHour) {
    const localDate = new Date(); // Create a Date object with the current UTC time
    localDate.setHours(localHour);

    return getCorrectWeekDayId(
        localWeekDayId,
        localHour,
        -localDate.getTimezoneOffset() / 60,
        0,
        false
    );
}

export function convertUTCWeekDayToLocal(utcWeekDayId, utcHour, localHour) {
    const utcDate = new Date(); // Create a Date object with the current UTC time
    utcDate.setUTCHours(utcHour);
    utcDate.setUTCMinutes(0);
    utcDate.setUTCSeconds(0);

    return getCorrectWeekDayId(
        utcWeekDayId,
        localHour,
        0,
        utcDate.getTimezoneOffset() / 60,
        true
    );
}

export function convertUTCHourToLocalHour(utcHour) {
    const utcDate = new Date(); // Create a Date object with the current UTC time
    utcDate.setUTCHours(utcHour);
    utcDate.setUTCMinutes(0);
    utcDate.setUTCSeconds(0);

    return utcDate.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: false,
        timeZoneName: 'short',
    });
}

export function convertLocalHourToUTCHour(localHour) {
    const localDate = new Date(); // Create a Date object with the current UTC time
    localDate.setHours(localHour);

    return localDate.getUTCHours();
}

export function createNoReportsView(title) {
    return (
        <FlexDiv justifyCenter>
            <NoItemsYetContent
                img={'/static/img/recurring-reports-inside-frame.svg'}
                imgAlt={'empty report view'}
                title={title}
            />
        </FlexDiv>
    );
}
