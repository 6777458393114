import React from 'react';
import { FlexDiv, Heading3 } from '@awarego/awarego-components';
import styled from 'styled-components';

const DivWithBoxShadow = styled(FlexDiv)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

function ScheduleHeading() {
    return (
        <DivWithBoxShadow fullWidth>
            <Heading3 ml={24} my={16}>
                Schedule
            </Heading3>
        </DivWithBoxShadow>
    );
}

export default ScheduleHeading;
