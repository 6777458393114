import React from 'react';
import {
    Heading2,
    SpaceBetweenDiv,
    FlexDiv,
    GridDiv,
    ThemedButton,
} from '@awarego/awarego-components';
import ContentCard from './content-card';

function ContentResultSection({
    heading,
    handleClick,
    items,
    totalItemCount, //Not the same as items.length because we might be displaying only the first 8
    handleShowAll,
    fullWidthThumb = false,
    buttons = null,
}) {
    const getActualId = (contentId) => {
        return contentId.slice(1);
    };

    return (
        <FlexDiv>
            <SpaceBetweenDiv column alignStart $noWrap mt={24}>
                <SpaceBetweenDiv my={16}>
                    <Heading2 displayBlock>{`${heading} (${
                        totalItemCount || 0
                    })`}</Heading2>
                    {buttons}
                </SpaceBetweenDiv>
                <GridDiv gap={8} minItemWidth={240}>
                    {items &&
                        items.map((item) => (
                            <ContentCard
                                id={getActualId(item.id)}
                                item={item}
                                title={item.title || item.default_title}
                                text={
                                    item.description ||
                                    item.default_description ||
                                    item.adminDescription
                                }
                                thumbnail={
                                    item.thumbnail || item.default_thumbnail
                                }
                                tags={item.tags}
                                type={`${item.type}${
                                    item.subType ? `/${item.subType}` : ''
                                }`}
                                key={item.id}
                                fullWidthThumb={fullWidthThumb}
                                handleClick={handleClick}
                            />
                        ))}
                </GridDiv>
            </SpaceBetweenDiv>
            {totalItemCount > items.length && (
                <FlexDiv fullWidth justifyCenter margin={16}>
                    <ThemedButton
                        $noTextTransform
                        mode="secondary"
                        onClick={() => handleShowAll()}
                        text="See all"
                    />
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

export default ContentResultSection;
