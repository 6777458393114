import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import Upgrade from './Upgrade';
import { FullscreenModal } from '@awarego/awarego-components';
import Logo from '../../components/logo';

function Subscriptions({ companyId }) {
    const navigate = useNavigate();
    return (
        <Routes>
            <Route
                path="upgrade*"
                element={
                    <FullscreenModal
                        isOpen={true}
                        onRequestClose={() =>
                            navigate('/settings/subscriptions')
                        }
                        modalContent={<Upgrade companyId={companyId} />}
                        heading={'Upgrade'}
                        logo={<Logo />}
                    />
                }
            />
        </Routes>
    );
}

export default observer(Subscriptions);
