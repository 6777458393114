import RestService from './RestService';
import NotificationTargetsService from './NotificationTargetsService';

export default class NotificationsService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/notifications`);
        } else super('/notifications');
    }

    targetsService(reasonId) {
        return new NotificationTargetsService(this, reasonId);
    }
}
