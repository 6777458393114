import React from 'react';
import { observer } from 'mobx-react';
import LogoUpload from './logo-upload';
import CompanyAppearanceLogoDisplay from './CompanyAppearanceLogoDisplay';

function EditLogo({ company, importLogo, removeLogo }) {
    return company.logo_name ? (
        <CompanyAppearanceLogoDisplay
            logo={company.logo_name}
            onRemoveLogo={removeLogo}
        />
    ) : (
        <LogoUpload onLogoSelected={importLogo} />
    );
}

export default observer(EditLogo);