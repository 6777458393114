import Form from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {
    dvr: dvr(validatorjs),
};

const fields = {
    companyName: {
        name: 'companyName',
        key: 'companyName',
        label: 'Company name',
        rules: 'string|required|between:3,150',
        placeholder: 'Your company name',
    },
    companyDomain: {
        name: 'companyDomain',
        key: 'companyDomain',
        label: 'Your Company domain',
        rules: 'string|required|between:4,150',
        placeholder: 'E.g. company.com',
    },
    emailHR: {
        name: 'emailHR',
        key: 'emailHR',
        label: 'HR department email',
        rules: 'required|email|string',
        placeholder: 'E.g. hr@company.com',
    },
};

class PersonalizeForm extends Form {
    constructor(initials, personalizedKeys, hooks) {
        let f = {};

        // initiate form with fields, which are present in personalizedKeys array only
        personalizedKeys.map((pk) => {
            if (fields[pk]) {
                f[pk] = { ...fields[pk] };
            }
        });

        // propagate values to fields, if there are some initial values like saved in local storage or when editing a draft with previously saved data
        if (initials) {
            Object.keys(initials).forEach((k) => {
                if (f[k]) f[k].value = initials[k];
            });
        }

        super({ fields: f }, { plugins, hooks });
    }

    updateFields(initials, personalizedKeys) {
        // add new fields to form
        personalizedKeys.map((pk) => {
            if (!this.has(pk)) {
                const field = { ...fields[pk] };
                if (initials[pk]) field.value = initials[pk];
                this.add(field);
            }
        });

        //remove fields from form, which are not present in personalizedKeys array
        Object.keys(fields).forEach((k) => {
            if (this.has(k) && !personalizedKeys.includes(k)) {
                this.del(k);
            }
        });
    }
}

export default PersonalizeForm;
