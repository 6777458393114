import BaseService from './BaseService';

class RestService extends BaseService {
    constructor(baseUrl) {
        super();
        this.baseUrl = baseUrl;
    }

    getId(entity) {
        if (typeof entity === 'string') return entity;
        if (typeof entity === 'number') return entity;
        if (!entity) return undefined;
        const id = entity.id;
        const _id = entity._id;
        return id || id === 0 ? id : _id;
    }

    create(entity, options) {
        return this.post(this.baseUrl, entity, options);
    }

    fetch(entityId) {
        return this.get(`${this.baseUrl}/${entityId}`);
    }

    list(params, options) {
        return this.get(`${this.baseUrl}`, params, options);
    }

    update(entity, id) {
        // id = 0 would evaluate to falsy state
        const entityId = id || id === 0 ? id : this.getId(entity);
        return this.put(
            `${this.baseUrl}/${encodeURIComponent(entityId)}`,
            entity
        );
    }

    delete(entity, body) {
        return this.del(
            `${this.baseUrl}/${encodeURIComponent(this.getId(entity))}`,
            body
        );
    }
}

export default RestService;
