import React from 'react';
import {
    generateCreateEntity,
    generateLoadList,
    generateLoadEntity,
} from '../utils/mobx';
import services from '../services';
import { makeAutoObservable, runInAction } from 'mobx';
import {
    TextTiny,
    CheckboxInput,
    TextDefault,
    SemiCircleProgress,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import moment from 'moment';
import omit from 'lodash/omit';
import SelectionController from './SelectionController';
import isEqual from 'lodash/isEqual';
import debounceFn  from 'debounce-fn';
import { extractErrorMessage } from '../utils/helpers';
import { caseAndDiacriticsInsensitiveSort } from '../components/table/table-helpers';
import NameAndSublistCell from '../components/manager/NameAndSublistCell';

CheckboxInput.propTypes = {};

class AutomationEmployeesStore {
    usersList = [];
    loadingUsersList = null;
    addingUsersToList = null;
    initiallyLoaded = false;
    isDownloading = false;
    error = null;
    queryContext = null;
    selectedListsInFilter = [];
    usersTotal = 0;
    listType = null;
    employeesToBeSentReminders = 0;
    loadingEmployeesToBeSentReminders = false;
    remindersSentRecently = [];

    constructor(automationStore, commonStore, mainStore) {
        makeAutoObservable(this);
        this.automationStore = automationStore;
        this.commonStore = commonStore;
        this.mainStore = mainStore;
        this.selection = new SelectionController(
            this.usersList,
            this,
            (x) => x.email
        );

        this.automationStore.editingAutomation &&
            this.toggleAllListsSelected(
                this.automationStore.editingAutomation.selectedUserListsIds
            );
        this.setMinScoreDebounced = debounceFn(this.setMinScore, { wait: 500 });
        this.setMaxScoreDebounced = debounceFn(this.setMaxScore, { wait: 500 });
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    setUserListSearchValue(value) {
        if (this.queryContext) this.queryContext.filter = value;
    }

    get userListSearchValue() {
        return (this.queryContext && this.queryContext.filter) || '';
    }

    get userListsSelectedDescription() {
        if (!this.automationStore.editingAutomation) return '';
        const selectedLists =
            this.automationStore.editingAutomation.userListsSelected.filter(
                (list) => this.selectedListsInFilter.includes(list.id)
            );

        if (selectedLists.length === 1) return selectedLists[0].name;
        if (
            this.selectedListsInFilter.length ===
            this.automationStore.editingAutomation.userListsSelected.length
        )
            return `All lists (${this.automationStore.editingAutomation.userListsSelected.length})`;

        return `${this.selectedListsInFilter.length} lists`;
    }

    setListId(value) {
        if (this.queryContext) this.queryContext.listId = value;
    }

    setMinScore(value) {
        if (this.queryContext) this.queryContext.minScore = value;
    }

    setMaxScore(value) {
        if (this.queryContext) this.queryContext.maxScore = value;
    }

    setScores(minScore, maxScore) {
        this.setMinScore(minScore);
        this.setMaxScore(maxScore);
    }

    setPagingParams(pageIndex, pageSize) {
        if (this.queryContext) {
            this.queryContext.p = pageIndex;
            this.queryContext.n = pageSize;
        }
    }

    setScoreName(value) {
        if (this.queryContext) this.queryContext.scoreName = value;
    }

    setQueryContext(context = {}) {
        this.queryContext = context;
    }

    setSortBy(sortBy) {
        if (this.queryContext && sortBy && sortBy.length > 0) {
            this.queryContext.order = `${sortBy[0].id} ${
                sortBy[0].desc ? 'desc' : 'asc'
            }`;
        }
    }

    setThreatArea(value) {
        if (this.queryContext) this.queryContext.threatArea = value;
    }

    async showRemindersModal(
        useSelection,
        type,
        item
        // itemName,
        // reloadAfterSending = false
    ) {
        // there are five cases how this can be used
        // 1. remind all users
        // 2. remind one list exactly
        // 3. remind selected lists
        // 4. remind selected users
        // 5. remind one user exactly

        let userLists;
        if (
            type === 'list' &&
            (Array.isArray(item) || Number.isInteger(item))
        ) {
            userLists = Array.isArray(item) ? item : [item];
        }
        await this.loadEmployeesToBeSentReminders(
            useSelection,
            userLists ? { userLists: userLists } : {}
        );

        if (!this.loadingEmployeesToBeSentReminders) {
            if (this.employeesToBeSentReminders === 0) {
                this.commonStore.showConfirm(
                    'There are no employees to be reminded.',
                    'Close',
                    'Send reminders',
                    null,
                    false,
                    false
                );
            } else {
                if (type === 'user') {
                    // this.commonStore.showConfirm(
                    //     <EmployeeRemindersModal
                    //         employeeCount={
                    //             useSelection
                    //                 ? this.employeesToBeSentReminders
                    //                 : 1
                    //         }
                    //         employeeName={itemName}
                    //     />,
                    //     'Send reminders',
                    //     'Send reminders',
                    //     async () => {
                    //         await this.sendReminders(
                    //             useSelection,
                    //             item ? { emails: [item] } : {}
                    //         );
                    //     }
                    // );
                } else {
                    // this.commonStore.showConfirm(
                    //     <ListRemindersModal
                    //         employeesToBeSentReminders={
                    //             this.employeesToBeSentReminders
                    //         }
                    //         remindersSentRecently={this.remindersSentRecently}
                    //         listName={itemName}
                    //         lists={userLists}
                    //     />,
                    //     'Send reminders',
                    //     'Send reminders',
                    //     async () => {
                    //         await this.sendReminders(
                    //             useSelection,
                    //             userLists ? { userLists: userLists } : {},
                    //             reloadAfterSending
                    //         );
                    //     }
                    // );
                }
            }
        }
    }

    async sendReminders(useSelection, body, reloadAfterSending) {
        try {
            const companyId = this.mainStore.currentCompany.company_id;
            const automationId = this.automationStore.editingAutomation.id;
            let params;
            if (useSelection) {
                params = {
                    ...omit(this.queryContext, ['scoreName', 'subheading']),
                    ...this.selection.selectionContext,
                };
            }
            const result = await services.Companies.automationsService(
                companyId
            ).sendReminders(automationId, params, {
                ...body,
                reminderOption: 2,
            });
            if (result) {
                if (result.success) {
                    this.commonStore.success('Reminders sent!');
                } else {
                    this.commonStore.warn(
                        result.reason
                            ? result.reason
                            : 'Reminders were not sent!'
                    );
                }
            }
            if (result && result.success && reloadAfterSending)
                this.automationStore.loadRemindersOverview(
                    companyId,
                    automationId
                );
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.selection.clearSelection();
            });
        }
    }

    get pagingParams() {
        if (this.queryContext) {
            return {
                pageIndex: this.queryContext.p,
                pageSize: this.queryContext.n,
            };
        }
        return {};
    }

    get listId() {
        return this.queryContext && this.queryContext.listId;
    }

    get listIdHash() {
        return (
            this.queryContext &&
            this.queryContext.listId &&
            this.queryContext.listId.join('-')
        );
    }

    get minScore() {
        return this.queryContext && this.queryContext.minScore
            ? this.queryContext.minScore
            : 0;
    }

    get maxScore() {
        return this.queryContext && this.queryContext.maxScore
            ? this.queryContext.maxScore
            : 100;
    }

    get subheading() {
        return this.queryContext ? this.queryContext.subheading : '';
    }

    get scoreName() {
        return this.queryContext && (this.queryContext.scoreName || '');
    }

    get pageIndex() {
        return this.queryContext && this.queryContext.p;
    }

    get pageSize() {
        return this.queryContext && this.queryContext.n;
    }

    get order() {
        return this.queryContext && this.queryContext.order;
    }

    toggleListSelected = (listId) => {
        if (this.selectedListsInFilter.includes(listId))
            this.selectedListsInFilter.remove(listId);
        else this.selectedListsInFilter.push(listId);
        this.setListId(this.selectedListsInFilter);
    };

    toggleAllListsSelected = (array) => {
        if (
            isEqual(
                array.slice().sort((a, b) => a - b),
                this.selectedListsInFilter.slice().sort((a, b) => a - b)
            )
        ) {
            this.clearSelectedListsInFilter();
        } else {
            this.selectedListsInFilter.replace(array);
        }
        if (this.listId !== null) this.setListId(null);
    };

    clearSelectedListsInFilter = () => {
        this.selectedListsInFilter.replace([]);
    };

    async downloadCSV(companyId, automationId) {
        if (this.isDownloading) return true;
        this.isDownloading = true;

        // setTimeout(async () => {
        try {
            const options = {
                responseType: 'blob',
                // onDownloadProgress: (progressEvent) => {
                //     const { loaded, total } = progressEvent;
                //
                //     // setDownloadInfo({
                //     //     progress: Math.floor((loaded * 100) / total),
                //     //     loaded,
                //     //     total,
                //     //     completed: false,
                //     // });
                // },
            };

            let response;

            switch (this.listType) {
                case 'bounced':
                    response = await services.Companies.automationsService(
                        companyId
                    )
                        .bouncedEmailsService(automationId)
                        .list(
                            {
                                csv: true,
                                listIds: this.queryContext.listId,
                            },
                            options
                        );
                    break;
                default:
                    response = await services.Companies.automationsService(
                        companyId
                    )
                        .usersService(automationId)
                        .list(
                            {
                                csv: true,
                                ...omit(this.queryContext, [
                                    'scoreName',
                                    'subheading',
                                ]),
                                ...this.selection.selectionContext,
                            },
                            options
                        );
                    break;
            }
            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } finally {
            this.isDownloading = false;
            this.selection.clearSelection();
        }
        //  }, 5000);
    }

    get columns() {
        switch (this.listType) {
            case 'bounced':
                return [
                    {
                        Header: 'Name',
                        id: 'name',
                        accessor: (x) => {
                            return x.name || x.email;
                        },
                        Cell: (x) => <TextDefault bold>{x.value}</TextDefault>,
                        sortType: caseAndDiacriticsInsensitiveSort,
                    },
                    {
                        Header: 'List',
                        accessor: 'lists_names',
                        Cell: (x) => {
                            if (x.value && x.value.length > 0) {
                                return (
                                    <NameAndSublistCell
                                        subListItems={x.value}
                                        maxVisibleSubListCount={1}
                                    ></NameAndSublistCell>
                                );
                            } else {
                                return (
                                    <TextDefault lighter>
                                        List unavailable
                                    </TextDefault>
                                );
                            }
                        },
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                    },
                ];
            default:
                return [
                    {
                        Header: 'Name',
                        id: 'name',
                        accessor: (x) => {
                            return x.name || x.email;
                        },
                        sortType: caseAndDiacriticsInsensitiveSort,
                        Cell: observer((x) => {
                            return (
                                <CheckboxInput
                                    label={x.value}
                                    checked={
                                        this.selection
                                            .effectiveSelectionOnCurrentPage[
                                            x.row.original.email
                                        ]
                                    }
                                    noYPadding={true} //Removes Y padding and margin, making it fit better into table row
                                    onChange={(e) => {
                                        this.selection.toggleRow(
                                            x.row.original,
                                            e.target.checked
                                        );
                                    }}
                                />
                            );
                        }),
                    },
                    {
                        Header: 'Completed date',
                        accessor: 'completedDate',
                        Cell: (x) =>
                            x.value
                                ? moment(x.value).format('MMM DD[, ]YYYY')
                                : '-',
                    },
                    {
                        Header: 'List',
                        accessor: 'listName',
                        // show spaces between listNames separated by a comma
                        Cell: (x) =>
                            x.value ? (
                                x.value.replace(/,(?=[^\s])/g, ', ')
                            ) : (
                                <TextDefault lighter>
                                    List unavailable
                                </TextDefault>
                            ),
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                    },
                    {
                        Header: this.scoreName,
                        accessor: 'scorePercent',
                        Cell: (x) => (
                            <SemiCircleProgress
                                percentage={x.value}
                                width={48}
                                top={12}
                                halfCircle
                                percentageText
                                showNA={!x.row.original.started}
                            />
                        ),
                    },
                    {
                        Header: ' ',
                        Cell: (x) => {
                            if (
                                x.row.original.completedDate ||
                                x.row.original.started ||
                                !this.automationStore.editingAutomation
                                    .canRemind
                            )
                                return '';
                            return (
                                <TextTiny
                                    themed
                                    bold
                                    link
                                    onClick={() =>
                                        this.showRemindersModal(
                                            false,
                                            'user',
                                            x.row.original.email,
                                            x.row.original.name ||
                                                x.row.original.email
                                        )
                                    }
                                    className="show-on-hover"
                                >
                                    Send reminders
                                </TextTiny>
                            );
                        },
                    },
                ];
        }
    }

    loadEmployeesToBeSentReminders = generateLoadEntity(
        'employeesToBeSentReminders',
        this,
        'loadingEmployeesToBeSentReminders',
        async (useSelection, body = {}) => {
            const companyId = this.mainStore.currentCompany.company_id;
            const automationId = this.automationStore.editingAutomation.id;
            let params;
            if (useSelection) {
                params = {
                    ...omit(this.queryContext, ['scoreName', 'subheading']),
                    ...this.selection.selectionContext,
                };
            }
            const result = await services.Companies.automationsService(
                companyId
            ).countReminders(automationId, params, {
                ...body,
                reminderOption: 2,
            });
            runInAction(() => {
                this.remindersSentRecently.replace(result.lastReminders);
            });
            return result.remindersCount;
        },
        'employeesToBeSentReminders'
    );

    loadBouncedEmails = generateLoadList(
        'usersList',
        this,
        'loadingUsersList',
        async (companyId, automationId) => {
            const result = await services.Companies.automationsService(
                companyId
            )
                .bouncedEmailsService(automationId)
                .list({
                    ...omit(this.queryContext, ['scoreName', 'subheading']),
                    listIds: this.queryContext.listId,
                });
            return result.map((x) =>
                typeof x === 'string' ? { email: x } : x
            );
        },
        'usersList'
    );

    loadUsers = generateLoadList(
        'usersList',
        this,
        'loadingUsersList',
        async (companyId, automationId) => {
            const result = await services.Companies.automationsService(
                companyId
            )
                .usersService(automationId)
                .list({
                    ...omit(this.queryContext, ['scoreName', 'subheading']),
                });
            runInAction(() => {
                this.usersTotal = result.count;
            });
            return result.users;
        },
        'usersList'
    );

    addUsersToList = generateCreateEntity(
        'addUsersToList',
        this,
        'addingUsersToList',
        async (companyId, automationId, targetListId, targetListName) => {
            const params = {
                ...omit(this.queryContext, ['scoreName', 'subheading']),
                ...this.selection.selectionContext,
            };
            if (targetListId) params.targetListId = targetListId;
            if (targetListName) params.targetListName = targetListName;
            return await services.Companies.automationsService(companyId)
                .usersService(automationId)
                .addUsersToList(params);
        }
    );

    debouncedLoadUsers = debounceFn(this.loadUsers, { wait: 500 });

    async loadEmployeesList(companyId, automationId) {
        try {
            switch (this.listType) {
                case 'bounced':
                    return this.loadBouncedEmails(companyId, automationId);
                default:
                    return this.loadUsers(companyId, automationId);
            }
        } finally {
            this.initiallyLoaded = true;
        }
    }
}

class AutomationEmployeesStoresFactory {
    employeesStores = {};

    constructor(automationStore, commonStore, mainStore) {
        makeAutoObservable(this);
        this.automationStore = automationStore;
        this.commonStore = commonStore;
        this.mainStore = mainStore;
    }

    get modalEmployeesStore() {
        if (!this.automationStore) return null;
        if (!this.employeesStores['modal'])
            this.employeesStores['modal'] = new AutomationEmployeesStore(
                this.automationStore,
                this.commonStore,
                this.mainStore
            );

        return this.employeesStores['modal'];
    }

    get employeesOverviewStore() {
        if (!this.automationStore) return null;
        if (!this.employeesStores['overview']) {
            this.employeesStores['overview'] = new AutomationEmployeesStore(
                this.automationStore,
                this.commonStore,
                this.mainStore
            );
            this.employeesStores['overview'].listType = 'users';
            this.employeesStores['overview'].queryContext = {};
            this.employeesStores['overview'].setScoreName('Threat area score');
        }

        return this.employeesStores['overview'];
    }

    hideEmployeesModal() {
        // just recreate instead cleanup props
        this.employeesStores['modal'] = new AutomationEmployeesStore(
            this.automationStore,
            this.commonStore,
            this.mainStore
        );
    }

    showEmployeesModal(type, context = {}) {
        this.modalEmployeesStore.listType = type;
        this.modalEmployeesStore.queryContext = context;
        this.modalEmployeesStore.initiallyLoaded = false;
    }
}

export default AutomationEmployeesStoresFactory;
