import { CheckboxInput, FlexDiv, TextTiny } from '@awarego/awarego-components';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

function SelectAll({ selection, loadingUsersList, usersTotal, pageSize }) {
    const {
        currentPageSelected,
        currentPageDeSelected,
        allSelected,
        haveDeselectedOnCurrentPage,
        haveDeselectedUsers,
        selectionCountOnCurrentPage,
        selectionCountOnAllPages,
        totalSelectionCountOnAllPages,
    } = selection;

    const handleSelectAll = (event) => {
        selection.toggleCurrentPageSelected(event.target.checked);
    };

    const handleSelectAllOnAllPages = () => {
        selection.setAllPagesSelected();
    };

    const handleClearSelection = () => {
        selection.clearSelection();
    };

    const checkboxInputLabel = useMemo(() => {
        if (
            totalSelectionCountOnAllPages === 0 ||
            usersTotal === totalSelectionCountOnAllPages
        )
            return `Select all${!loadingUsersList ? ` (${usersTotal})` : ''}`;
        return `Selected (${totalSelectionCountOnAllPages}/${usersTotal})`;
    }, [usersTotal, totalSelectionCountOnAllPages, loadingUsersList]);

    return (
        <FlexDiv>
            <CheckboxInput
                label={checkboxInputLabel}
                indeterminate={
                    currentPageSelected && haveDeselectedOnCurrentPage
                }
                checked={
                    (allSelected && !currentPageDeSelected) ||
                    currentPageSelected
                }
                onChange={handleSelectAll}
            />
            {allSelected && (
                <FlexDiv column ml={16}>
                    <TextTiny>
                        {haveDeselectedUsers
                            ? `${selectionCountOnAllPages} users selected on all pages`
                            : `All ${usersTotal} employees selected.`}
                    </TextTiny>
                    <TextTiny themed bold link onClick={handleClearSelection}>
                        Clear selection
                    </TextTiny>
                </FlexDiv>
            )}
            {usersTotal > pageSize && currentPageSelected && !allSelected && (
                <FlexDiv column ml={16}>
                    <TextTiny>
                        {` ${
                            haveDeselectedOnCurrentPage ? '' : 'All'
                        } ${selectionCountOnCurrentPage}
                                                employees on this page are
                                                selected.`}
                    </TextTiny>
                    <TextTiny
                        themed
                        bold
                        link
                        onClick={handleSelectAllOnAllPages}
                    >
                        Select all employees on all pages
                    </TextTiny>
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

export default observer(SelectAll);
