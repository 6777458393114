import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Image,
    SpaceBetweenDiv,
    FlexDiv,
    TextDefault,
    Heading3,
    TextTiny,
    TextWithTooltipIcon,
} from '@awarego/awarego-components';

function PreviewQuestionDetails({ handleQuestionDetailsClose, question }) {
    const content = [
        { name: 'Threat area', text: question.area },
        {
            name: 'Difficulty level',
            text:
                question.difficulty.charAt(0).toUpperCase() +
                question.difficulty.slice(1),
            tooltipText: (
                <TextTiny lineHeight={'initial'}>
                    Difficulty level indicates how difficult or challenging it
                    is to correctly answer the scenario.
                    <br />
                    <br />
                    <b>Basic.</b> The scenario covers the basics of a given
                    threat area.
                    <br />
                    <br />
                    <b>Medium.</b> Requires a certain level of knowledge of the
                    threat area.
                    <br /> <br />
                    <b>Advanced.</b> Usually reserved for specific roles where a
                    certain threat area presents a significant risk and a high
                    level of knowledge on the matter is expected.
                </TextTiny>
            ),
        },
        {
            name: 'Key behaviours',
            text: question.behaviours.map((behaviour, i) => (
                <div key={i}>{behaviour.name}</div>
            )),
            tooltipText:
                'Key behaviours which are measured with this scenario.',
        },
    ];
    return (
        <FlexDiv column padding={24} gap={24} minWidth={480} maxWidth={560}>
            <SpaceBetweenDiv gap={4}>
                <Heading3>Scenario details</Heading3>
                <Image
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={handleQuestionDetailsClose}
                    link
                />
            </SpaceBetweenDiv>
            <TextDefault>{question.adminDescription}</TextDefault>
            <FlexDiv column>
                {content.map((x, i) => (
                    <FlexDiv key={i} gap={8}>
                        <FlexDiv flexGrow={2}>
                            <TextTiny lighter>
                                {x.tooltipText ? (
                                    <TextWithTooltipIcon
                                        tooltipText={x.tooltipText}
                                        textComponent={x.name}
                                        icon={<HelpOutlineIcon />}
                                    />
                                ) : (
                                    x.name
                                )}
                            </TextTiny>
                        </FlexDiv>
                        <FlexDiv flexGrow={5}>
                            <TextTiny bold>{x.text}</TextTiny>
                        </FlexDiv>
                    </FlexDiv>
                ))}
            </FlexDiv>
        </FlexDiv>
    );
}

export default PreviewQuestionDetails;
