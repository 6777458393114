import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    name: {
        label: 'Company name',
        rules: 'string|required|between:4,150',
        placeholder: 'Company name',
    },
    theme: {
        label: 'Theme',
        rules: 'required',
        value: '1',
        placeholder: 'Company theme',
        extra: [
            { value: '1', label: 'Dark' },
            { value: '2', label: 'Light' },
        ],
    },
};

class CompanyForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default CompanyForm;
