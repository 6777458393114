import React from 'react';
import GenericInsight from './GenericInsight';

function WeakestBehaviourInsight({ insight, onAction }) {
    return (
        <GenericInsight
            heading={`Weakest behavior is "${insight.context.name}"`}
            text={`${insight.context.employeesPercentage}% of employees are identified as Risky or Very Risky in this key behavior`}
            actionName="View employees"
            insight={insight}
            onAction={onAction}
        />
    );
}

export default WeakestBehaviourInsight;
