import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { USER_ROLE } from '../utils/constants';
import { useStores } from '../hooks/use-stores';

function RequireRole({
    children,
    requiredRole = USER_ROLE.ADMIN,
    fallback = null,
}) {
    const { store } = useStores();
    const { currentRole } = store;

    const shouldShow = useMemo(() => {
        const rolesMap = {
            [USER_ROLE.ADMIN]: [USER_ROLE.ADMIN],
            [USER_ROLE.VIEWER]: [USER_ROLE.ADMIN, USER_ROLE.VIEWER],
        };
        return rolesMap[requiredRole].includes(currentRole);
    }, [currentRole]);

    return shouldShow ? <>{children}</> : fallback;
}

export default observer(RequireRole);
