import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import ThemedBackArrow from '../../components/themed/ThemedBackArrow';
import ThemedForwardArrow from '../../components/themed/ThemedForwardArrow';
import {
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';

function AssessmentFooter({
    hasMoreQuestions,
    onRequestClose,
    questionIndex,
    questionCount,
    hasPreviousBlock,
    hasNextBlock,
    onPreviousLesson,
    onNextLesson,
    onPrevBlock,
    onNextBlock,
    isWizard,
}) {
    return (
        <div className={'modalFooter'}>
            <SpaceBetweenDiv alignCenter justifyCenter>
                {!hasMoreQuestions && isWizard && (
                    <ThemedButton
                        mode="primary"
                        text="Back to add scenarios"
                        onClick={onRequestClose}
                    />
                )}
                {hasMoreQuestions && (
                    <Fragment>
                        <FlexDiv
                            alignCenter
                            visibilityHidden={questionIndex === 0}
                            ml={32}
                        >
                            <ThemedBackArrow />
                            <TextDefault
                                color="black"
                                onClick={onPreviousLesson}
                                link
                                bold
                                uppercase
                                flex
                                ml={4}
                            >
                                Previous question
                            </TextDefault>
                        </FlexDiv>
                        <FlexDiv>
                            <ThemedButton
                                mode="secondary"
                                disabled={
                                    questionIndex === 0 && !hasPreviousBlock
                                }
                                onClick={onPrevBlock}
                                text="Back"
                            />
                            {questionIndex === questionCount - 1 &&
                            !hasNextBlock ? (
                                <ThemedButton
                                    mode="primary"
                                    continue
                                    $airy
                                    onClick={onRequestClose}
                                    text="Close"
                                />
                            ) : (
                                <ThemedButton
                                    mode="primary"
                                    $airy
                                    onClick={onNextBlock}
                                    text="Next"
                                />
                            )}
                        </FlexDiv>
                        <FlexDiv
                            alignCenter
                            visibilityHidden={
                                questionIndex === questionCount - 1
                            }
                            mr={32}
                        >
                            <TextDefault
                                color="black"
                                onClick={onNextLesson}
                                bold
                                link
                                uppercase
                                flex
                                mr={4}
                            >
                                Skip to next question
                            </TextDefault>
                            <ThemedForwardArrow />
                        </FlexDiv>
                    </Fragment>
                )}
            </SpaceBetweenDiv>
        </div>
    );
}

export default observer(AssessmentFooter);
