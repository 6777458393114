import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ConfirmationModal from './containers/ConfirmationModal';
import Snackbar from './components/Snackbar';
import { ThemeProvider } from 'styled-components';
import {
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import { useStores } from './hooks/use-stores';
import MainRoutes from './MainRoutes';
import lang from 'validatorjs/src/lang';
import en from 'validatorjs/src/lang/en';

lang._set('en', en);

function App() {
    const { store, authStore, commonStore, brandingStore } = useStores();

    useEffect(() => {
        (async () => {
            if (authStore.token) {
                try {
                    await Promise.all([
                        authStore.pullUser(),
                        commonStore.loadLanguages(),
                    ]);
                } finally {
                    await commonStore.setAppLoaded();
                }
            } else {
                await commonStore.loadLanguages();
                await brandingStore.loadBranding();
                await commonStore.setAppLoaded();
            }
        })();
    }, []);

    const closeSnackbar = () => {
        commonStore.hideMessage();
    };

    const handleSignout = () => {
        authStore.logout();
    };

    const { theme, muiTheme, brandingLoaded } = brandingStore;
    const { appLoaded, messageShown, languagesLoaded, i18n } = commonStore;

    const { isAuthenticated } = authStore;
    const {
        trialMessageBar,
        currentCompany,
        loadingData,
        assessmentDashboardEnabled,
        assessmentEnabled,
        trainingEnabled,
    } = store;

    const primaryColor = theme.primary;

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    {languagesLoaded && (
                        <I18nextProvider i18n={i18n}>
                            {/*{brandingLoaded && (
                                    <Route
                                        path="testing2/*"
                                        element={<TestPage />}
                                    />
                                )}
                                <Route path="infosec/*" element={<Infosec />} />

                                <Route
                                    path="auth/jwt/signin/:token"
                                    element={<TokenSignIn />}
                                />
                                <Route path=".well-known/security.txt" />*/}
                            <MainRoutes
                                {...{
                                    appLoaded,
                                    brandingLoaded,
                                    primaryColor,
                                    loadingData,
                                    handleSignout,
                                    isAuthenticated,
                                    trialMessageBar,
                                    assessmentDashboardEnabled,
                                    currentCompany,
                                    trainingEnabled,
                                    assessmentEnabled,
                                }}
                            />
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                autoHideDuration={6000}
                                variant={commonStore.messageType}
                                message={commonStore.message || ''}
                                open={messageShown}
                                onClose={closeSnackbar}
                            />
                            <ConfirmationModal />

                            {/*<Fragment>
                                <Routes>
                                    <Route
                                        path="/"
                                        render={(props) => {
                                            let normalizedPath = enforceSlash(
                                                props.location.pathname.replace(
                                                    /\/\//g,
                                                    '/'
                                                )
                                            );
                                            if (
                                                commonStore.lastPage !==
                                                normalizedPath
                                            ) {
                                                commonStore.analyticsPageView(
                                                    `${normalizedPath}`
                                                );
                                            }
                                            commonStore.lastPage = normalizedPath;
                                            return null;
                                        }}
                                    />
                                </Routes>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    autoHideDuration={6000}
                                    variant={commonStore.messageType}
                                    message={commonStore.message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                ></Snackbar>
                                <ConfirmationModal />
                            </Fragment>*/}
                        </I18nextProvider>
                    )}
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
}

export default observer(App);
