import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

/* do not move this component to awarego-components lib. 
React-router-dom mismatch between LMS and components lib*/
const StyledLink = styled(Link)`
    ${(props) => {
        return css`
            cursor: pointer;
            &:hover {
                text-decoration: ${props.$underlined ? 'underline' : 'none'};
                color: ${props.$hovercolor};
            }
        `;
    }}
`;

export default StyledLink;
