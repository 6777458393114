import React, { Fragment } from 'react';
import {
    FlexDiv,
    Block,
    TextTiny,
    Heading3,
    Heading4,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';

import ScoreDistributionChart from '../../../../components/manager/assessment/score-distribution-chart';
import { resourcePath } from '../../../../utils/helpers';

function ResilienceScoreDistribution({
    flexGrow = 1,
    headingText,
    data,
    hasStarted,
    onBarClick,
}) {
    return (
        <Block flexGrow={flexGrow} resetMargin $noWrap>
            <Heading3 mb={8}>{headingText}</Heading3>
            {hasStarted ? (
                <Fragment>
                    <TextTiny lighter>
                        {data.length > 0 &&
                            (+data[0].employeePercent || 0) +
                                (+data[1].employeePercent || 0)}
                        % of assessed employees are likely to represent a risk
                    </TextTiny>
                    <FlexDiv flexGrow={3} mt={16} alignCenter>
                        <ScoreDistributionChart
                            componentData={data}
                            onBarClick={onBarClick}
                        />
                    </FlexDiv>
                </Fragment>
            ) : (
                <Fragment>
                    <img
                        src={resourcePath('/static/img/empty-chart.png')}
                        alt={'empty chart'}
                    />
                    <Heading4 center mt={24} lighter>
                        No score distribution yet
                    </Heading4>
                </Fragment>
            )}
        </Block>
    );
}

export default observer(ResilienceScoreDistribution);
