import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import {
    Block,
    FlexDiv,
    Heading1,
    Heading3,
    TextDefault,
    TextInput,
    ThemedButton,
} from '@awarego/awarego-components';
import ErrorMessage from '../../components/errormessage';
import SignUpFormDef from '../../forms/auth';
import TermsOfService from '../../components/TermsOfService';
import styled, { css } from 'styled-components';
import Recaptcha from 'react-recaptcha';

const { REACT_APP_RECAPTCHA_KEY } = import.meta.env;

const NumberChip = styled.div`
    font-weight: 800;
    font-size: 13px;
    color: ${(props) => props.theme.primary};
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 2px solid ${(props) => props.theme.primary};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    ${(props) =>
        props.lineAfter &&
        css`
            ::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 16px;
                left: 10px;
                top: 26px;
                background: ${(props) => props.theme.primary};
                opacity: 0.2;
            }
        `}
`;

function SignUp() {
    const { authStore, brandingStore, commonStore } = useStores();
    const recaptchaRef = useRef();

    const { account_name, termsUrl } = brandingStore;

    const { trialError, creatingTrial } = authStore;

    let navigate = useNavigate();

    const localStore = useLocalObservable(() => {
        let signInForm = new SignUpFormDef(
            {},
            {
                hooks: {
                    onSuccess() {
                        continueToNextStep();
                    },
                },
            }
        );
        signInForm.observe({
            path: 'managerEmail',
            key: 'value',
            call: ({ form }) => {
                form.validate();
            },
        });

        return { signInForm };
    });

    useEffect(() => {
        // google tracker
        ReactGA.pageview('/trial/');
        // facebook pixel
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
        };
        ReactPixel.init('742074739469756', advancedMatching, options);
        ReactPixel.pageView();
        commonStore.postAffTrackerClick();
        return () => {};
    }, [commonStore]);

    useEffect(() => {
        // record signup_form_view event
        commonStore.saveAnalyticsEvent('account', 'signup_form_view', {});
    }, []);

    const verifyCallback = (recaptchaToken) => {
        register(recaptchaToken);
    };

    const continueToNextStep = () => {
        recaptchaRef.current.execute();
    };

    // const callCapterra = () => {
    //     var capterra_vkey = 'fdcc0cfdacfc5e2c58304433a7cf696d',
    //         capterra_vid = '2137634',
    //         capterra_prefix =
    //             'https:' === document.location.protocol
    //                 ? 'https://ct.capterra.com'
    //                 : 'http://ct.capterra.com';
    //     var ct = document.createElement('script');
    //     ct.type = 'text/javascript';
    //     ct.async = true;
    //     ct.src =
    //         capterra_prefix +
    //         '/capterra_tracker.js?vid=' +
    //         capterra_vid +
    //         '&vkey=' +
    //         capterra_vkey;
    //     var s = document.getElementsByTagName('script')[0];
    //     s.parentNode.insertBefore(ct, s);
    // };

    const register = async (recaptchaToken) => {
        const data = {};
        data.recaptchaToken = recaptchaToken;
        data.managerEmail = localStore.signInForm.$('managerEmail').value;
        let response = await authStore.registerTrial(data);

        // if (response === 'signedin') {
        //     // user already signed in
        //     ReactPixel.trackCustom('trialSignup', { signup: true });
        //     ReactGA.set({ page: '/trial/thank-you/' });
        //     // ReactGA.event({category: 'User',action: 'trial-signup-page-was-automatically-signed-in',label: data.managerEmail});
        //     ReactGA.pageview('/trial/signedin/');
        //     ReactGA.event({
        //         category: 'User',
        //         action: 'trialpage-signedin',
        //         label: 'email:' + data.managerEmail.replace('@', '-'),
        //     });

        //     callCapterra();
        //     history.push('/');

        authStore.setIsSignUp(true);
        authStore.setEmail(localStore.signInForm.$('managerEmail').value);

        if (response === 'email') {
            // user already signed up with this email before
            ReactGA.pageview('/free/login/');
            authStore.setExtraMessage('You have already signed up');
            navigate('/auth/check-email?utm_term=login');
            //ReactGA.event('trial','trial-signup-was-sent-loggin-mail',managerEmail);
            ReactGA.event({
                category: 'User',
                action: 'free-signup-was-sent-login-emails',
                label: 'email:' + data.managerEmail.replace('@', '-'),
            });
        } else if (response) {
            ReactPixel.trackCustom('freeSignup', { signup: true });
            ReactGA.set({ page: '/free-signup/thank-you/' });
            ReactGA.pageview('/free-signup/thank-you/');
            authStore.setExtraMessage('Please verify your email.');
            navigate('/auth/check-email?utm_term=signup');
            ReactGA.event({
                category: 'User',
                action: 'free-signup',
                label: 'email:' + data.managerEmail.replace('@', '-'),
            });
        } else {
            recaptchaRef.current.reset();
        }
    };

    const inputHasError =
        localStore.signInForm.$('managerEmail').error &&
        localStore.signInForm.$('managerEmail').error.length > 0;

    const text = [
        'Create assessment or training from a template',
        'Share it with your employees',
        'Measure and track progress',
    ];

    return (
        <FlexDiv justifyCenter alignCenter wrapper column gap={16} pt={32}>
            {trialError && trialError.length > 0 && (
                <ErrorMessage error={trialError} />
            )}
            {creatingTrial ? (
                <ThemedSpinner />
            ) : (
                <FlexDiv
                    gap={120}
                    rowGap={48}
                    rowReverse
                    justifyCenter
                    alignCenter
                >
                    <FlexDiv width={400} column gap={24}>
                        <Heading1 extraBold>
                            It only takes minutes to start your cybersecurity
                            awareness journey
                        </Heading1>
                        <FlexDiv column gap={24}>
                            {text.map((x, i) => (
                                <FlexDiv gap={16} $noWrap alignCenter key={i}>
                                    <NumberChip lineAfter={i === 0 || i === 1}>
                                        {i + 1}
                                    </NumberChip>
                                    <Heading3 bold>{x}</Heading3>
                                </FlexDiv>
                            ))}
                        </FlexDiv>
                    </FlexDiv>
                    <Block width={568} resetMargin>
                        <Heading1>First, enter your work email</Heading1>
                        <FlexDiv mb={24}>
                            <TextDefault lighter mr={4}>
                                Already have an account?
                            </TextDefault>
                            <TextDefault themed bold>
                                <Link to={`/auth/signin`}>Sign in</Link>
                            </TextDefault>
                        </FlexDiv>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                localStore.signInForm.submit();
                            }}
                        >
                            <FlexDiv column gap={8}>
                                <TextInput
                                    error={inputHasError}
                                    helperText={
                                        inputHasError
                                            ? 'Please enter your work email'
                                            : undefined
                                    }
                                    stretched
                                    {...localStore.signInForm
                                        .$('managerEmail')
                                        .bind()}
                                />
                                <ThemedButton
                                    mode="primary"
                                    size="fullWidth"
                                    text="Continue"
                                    type="submit"
                                />
                                <Recaptcha
                                    ref={recaptchaRef}
                                    sitekey={REACT_APP_RECAPTCHA_KEY}
                                    size="invisible"
                                    verifyCallback={verifyCallback}
                                />
                                <TermsOfService
                                    accountName={account_name}
                                    termsUrl={termsUrl}
                                />
                            </FlexDiv>
                        </form>
                    </Block>
                </FlexDiv>
            )}
        </FlexDiv>
    );
}
export default observer(SignUp);
