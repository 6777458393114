import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Waiting from '../../components/waiting';
import { querystring } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { useParams, useNavigate } from 'react-router-dom';

const SignIn = () => {
    const { authStore } = useStores();
    const navigate = useNavigate();
    let { token } = useParams();

    useEffect(() => {
        authStore.signinFromEmail(token).then((result) => {
            let redirect = querystring('redirect') || '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            navigate(result ? redirect : '/auth/error/email');
        });
    }, [token]);

    return <Waiting waitingActive={true} />;
};

export default observer(SignIn);
