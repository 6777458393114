import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
    Link,
    matchPath,
    Route,
    Routes,
    useLocation,
    useNavigate,
    Outlet,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert, Container } from 'reactstrap';
import { observer } from 'mobx-react';
import '../css/index.scss';
import { useStores } from '../hooks/use-stores';
import { formatDate } from '../utils/helpers';
import classnames from 'classnames';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../components/themed/ThemedComponents';
import ThemedDropdownNavItem from '../components/themed/ThemedDropdownNavItem';
import {
    palette,
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';
import {
    Avatar,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    ButtonBase,
} from '@mui/material';
import CaretIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { rgba } from 'polished';
import makeStyles from '@mui/styles/makeStyles';
import Logo from '../components/logo';

const useStyles = makeStyles(() => ({
    styledAvatar: {
        height: 32,
        width: 32,
        fontSize: 12,
        fontWeight: 800,
        color: rgba(palette.graphite.heavyMetal, 0.6),
        backgroundColor: palette.graphite.heavyMetalLight,
    },
    showOnSmallScreen: {
        display: 'none',
        ['@media screen and (max-width: 1200px)']: {
            display: 'flex',
        },
    },
    hideOnSmallScreen: {
        display: 'flex',
        ['@media screen and (max-width: 1200px)']: {
            display: 'none',
        },
    },
    header: {
        padding: '0 48px',
        ['@media screen and (max-width: 1200px)']: {
            padding: '0 8px',
        },
    },
    hideOnMobile: {
        display: 'flex',
        ['@media screen and (max-width: 440px)']: {
            display: 'none',
        },
    },
    softShadow: {
        '& .MuiPaper-elevation8': {
            boxShadow:
                '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08)',
        },
    },
}));

function Layout({ children, handleSignout }) {
    const { store, authStore, brandingStore } = useStores();

    const {
        favicon,
        account_name,
        name,
        helpUrl,
        privacyUrl,
        resourcesUrl,
        contentUrl,
        supportEmail,
        brandingLoaded,
        myDomain,
    } = brandingStore;
    const { isAuthenticated, currentUser, isPartnerAdmin } = authStore;
    const {
        serverData,
        currentCompany,
        isTrial,
        haveValidPackage,
        havePartnerSubscription,
        assessmentEnabled,
        trainingEnabled,
    } = store;

    const [tooManyUsersBannerVisible, setTooManyUsersBannerVisible] =
        useState(true);
    const [notification, setNotification] = useState(false);
    const [notificationType, setNotificationType] = useState('warning');
    const [notificationMessage, setNotificationMessage] =
        useState('All is good');

    let navigate = useNavigate();
    let location = useLocation();
    const pathname = location.pathname;

    const classes = useStyles();
    const partnerViewSelected = !!matchPath(pathname, '/partner');

    const loadResources = () => {
        fetch('https://resources.awarego.com/notification-lms.json')
            .then((response) => response.json())
            .then((jsonData) => {
                setNotification(jsonData.status);
                setNotificationType(jsonData.type);
                setNotificationMessage(jsonData.message);
            })
            .catch((error) => {
                console.log('loaded notification failed');
                console.error(error);
            });
    };

    const onChangeCompany = async (id) => {
        handleUserMenuClose();
        await store.setCurrentCompany(id);

        //Route to '/' if in partner view
        if (partnerViewSelected) {
            navigate(`/`);
        }
    };

    const updateFavicon = (icon) => {
        const favicon = document.getElementById('favicon');
        favicon.href = icon;
        return true;
    };

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        return name.split(' ').map((n) => n[0]);
    };

    useEffect(() => {
        loadResources();
    }, []);

    const selectedSection = useMemo(() => {
        if (pathname.toLowerCase().startsWith('/users')) return 'users';
        else if (pathname.toLowerCase().startsWith('/trainings'))
            return 'trainings';
        else if (pathname.toLowerCase().startsWith('/catalog'))
            return 'catalog';
        else if (pathname.toLowerCase().startsWith('/settings'))
            // don't select any tab when on settings page as its not on the main menu
            return 5;
        else if (pathname.toLowerCase().startsWith('/resources'))
            return 'content';
        else if (pathname.toLowerCase().startsWith('/content'))
            return 'content';
        else if (pathname.toLowerCase().startsWith('/assessment'))
            return 'assessment';
        else if (pathname.toLowerCase().startsWith('/reports'))
            return 'reports';
        return '';
    }, [pathname]);

    const partnerSelectedSection = useMemo(() => {
        if (pathname.toLowerCase().endsWith('/resources')) return 'resources';
        return 'companies';
    }, [pathname]);

    const partnerIdSelected = useMemo(() => {
        let split = pathname.split('/');
        const partnerIndex = split.indexOf('partner');
        return partnerIndex > 0 ? split[partnerIndex + 1] : null;
    }, [pathname]);

    const currentUserInitials = currentUser
        ? getInitials(currentUser.name)
        : '';
    const contentVerticallyCentered = !isAuthenticated;
    let date = new Date();

    const defaultTabs = useMemo(() => {
        const tabs = [
            {
                code: '',
                title: 'Dashboard',
                enabled: true,
            },
            {
                code: 'trainings',
                title: 'Training programs',
                enabled: trainingEnabled,
            },
            {
                code: 'assessment',
                title: 'Assessments',
                enabled: assessmentEnabled,
            },
            {
                code: 'users',
                title: 'Employees',
                enabled: true,
            },
            {
                code: 'reports',
                title: 'Reports',
                enabled: true,
            },
            {
                code: 'content',
                title: 'Content',
                enabled: true,
                customLink: contentUrl,
            },
            {
                code: 'help',
                title: 'Help',
                enabled: true,
                customLink: helpUrl,
            },
        ];
        tabs.forEach((x) => {
            if (
                currentCompany &&
                currentCompany.settings[`ui.menu_hide_${x.code}_tab`]
            )
                x.enabled = false;
        });
        return tabs;
    }, [
        currentCompany,
        trainingEnabled,
        assessmentEnabled,
        resourcesUrl,
        helpUrl,
    ]);

    const partnerTabs = [
        {
            code: 'companies',
            title: 'Companies',
            to: '/partner/' + partnerIdSelected + '/companies',
        },
        {
            code: 'resources',
            title: 'Resources',
            to: '/partner/' + partnerIdSelected + '/resources',
        },
    ];

    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const handleUserMenuToggle = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const menuContainer = (isMobileMenu) =>
        isAuthenticated && (
            <Routes>
                <Route
                    path="ppv/*"
                    element={
                        <ThemedNav clean $mobileMenu={isMobileMenu}>
                            <ThemedNavItem active $mobileMenu={isMobileMenu}>
                                <ThemedNavLink
                                    top="top"
                                    as={Link}
                                    to="ppv"
                                    $mobileMenu={isMobileMenu}
                                >
                                    Embed
                                </ThemedNavLink>
                            </ThemedNavItem>
                        </ThemedNav>
                    }
                />
                <Route
                    path="partner/*"
                    element={
                        <ThemedNav clean $mobileMenu={isMobileMenu}>
                            {partnerTabs.map((t) => (
                                <ThemedNavItem
                                    active={partnerSelectedSection === t.code}
                                    key={t.code}
                                    $mobileMenu={isMobileMenu}
                                >
                                    <ThemedNavLink
                                        to={t.to}
                                        top="top"
                                        as={Link}
                                        $mobileMenu={isMobileMenu}
                                    >
                                        {t.title}
                                    </ThemedNavLink>
                                </ThemedNavItem>
                            ))}
                        </ThemedNav>
                    }
                />
                <Route
                    path="*"
                    element={
                        <ThemedNav clean $mobileMenu={isMobileMenu}>
                            {defaultTabs.map((t) =>
                                t.enabled ? (
                                    t.customLink === `/${t.code}` ||
                                    !t.customLink ? (
                                        <ThemedNavItem
                                            active={selectedSection === t.code}
                                            key={t.code}
                                            $mobileMenu={isMobileMenu}
                                        >
                                            <ThemedNavLink
                                                to={`${t.code}`}
                                                top="top"
                                                as={Link}
                                                $mobileMenu={isMobileMenu}
                                            >
                                                {t.title}
                                            </ThemedNavLink>
                                        </ThemedNavItem>
                                    ) : (
                                        <ThemedNavItem
                                            key={t.code}
                                            $mobileMenu={isMobileMenu}
                                        >
                                            <ThemedNavLink
                                                href={t.customLink}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                top="top"
                                                $mobileMenu={isMobileMenu}
                                            >
                                                {t.title}
                                            </ThemedNavLink>
                                        </ThemedNavItem>
                                    )
                                ) : null
                            )}
                        </ThemedNav>
                    }
                />
            </Routes>
        );

    return (
        <Fragment>
            {brandingLoaded && updateFavicon(favicon) && (
                <Helmet
                    titleTemplate={'%s | ' + account_name + ' LMS'}
                    defaultTitle={`${account_name} LMS`}
                />
            )}
            <div className="layout_wrapper">
                {!isAuthenticated ? (
                    <SpaceBetweenDiv padding={24}>
                        <Logo />
                    </SpaceBetweenDiv>
                ) : (
                    <SpaceBetweenDiv
                        backgroundColor={'white'}
                        minHeight={70}
                        className={classes.header}
                    >
                        <FlexDiv alignCenter>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleMobileMenuToggle}
                                className={classes.showOnSmallScreen}
                                size="large"
                            >
                                <MenuIcon color="secondary" />
                            </IconButton>
                            <Logo />
                            {isPartnerAdmin &&
                                !currentCompany &&
                                !partnerViewSelected && (
                                    /*<Navigate to={'partner'} replace={true} />*/
                                    <div>
                                        {isPartnerAdmin} {currentCompany}{' '}
                                        {partnerViewSelected}
                                    </div>
                                )}
                            <Drawer
                                variant="temporary"
                                open={mobileMenuOpen}
                                onClose={handleMobileMenuToggle}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                                className={classes.showOnSmallScreen}
                            >
                                {menuContainer(true)}
                            </Drawer>
                            <FlexDiv className={classes.hideOnSmallScreen}>
                                {menuContainer(false)}
                            </FlexDiv>
                        </FlexDiv>
                        {currentCompany && (
                            <Fragment>
                                <ButtonBase
                                    onClick={handleUserMenuToggle}
                                    disableRipple={true}
                                >
                                    <FlexDiv
                                        column
                                        ml={16}
                                        className={classes.hideOnMobile}
                                        maxWidth={250}
                                        noWrap
                                        style={{ textAlign: 'start' }}
                                    >
                                        <TextDefault bold cutLongText>
                                            {currentUser.name ||
                                                currentUser.email}
                                        </TextDefault>
                                        <TextTiny
                                            light
                                            cutLongText
                                            maxWidth={250}
                                        >
                                            {currentCompany.company_name}
                                        </TextTiny>
                                    </FlexDiv>
                                    <FlexDiv ml={8} mr={4}>
                                        <Avatar
                                            className={classes.styledAvatar}
                                            alt="current user initials"
                                        >
                                            {currentUserInitials}
                                        </Avatar>
                                    </FlexDiv>

                                    <CaretIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </ButtonBase>
                                <Menu
                                    anchorEl={userMenuAnchor}
                                    open={Boolean(userMenuAnchor)}
                                    onClick={handleUserMenuClose}
                                    className={classes.softShadow}
                                >
                                    {serverData &&
                                        serverData.map((comp) => (
                                            <MenuItem
                                                key={comp.company_id}
                                                onClick={() =>
                                                    onChangeCompany(
                                                        comp.company_id
                                                    )
                                                }
                                                selected={
                                                    !partnerViewSelected &&
                                                    currentCompany.company_id ===
                                                        comp.company_id
                                                }
                                            >
                                                <ThemedDropdownNavItem
                                                    selected={
                                                        !partnerViewSelected &&
                                                        currentCompany.company_id ===
                                                            comp.company_id
                                                    }
                                                    href={myDomain}
                                                    text={comp.company_name}
                                                />
                                            </MenuItem>
                                        ))}
                                    <Divider />
                                    {isPartnerAdmin &&
                                        currentUser.superForAccounts.map(
                                            (x) => (
                                                <MenuItem
                                                    key={x.id}
                                                    selected={
                                                        x.id ==
                                                        partnerIdSelected
                                                    }
                                                    component={Link}
                                                    to={`/partner/${x.id}`}
                                                >
                                                    <ThemedDropdownNavItem
                                                        selected={
                                                            x.id ==
                                                            partnerIdSelected
                                                        }
                                                        onClick={() => {
                                                            handleUserMenuClose();
                                                        }}
                                                        text={`${x.name} Companies`}
                                                    />
                                                </MenuItem>
                                            )
                                        )}
                                    {isPartnerAdmin && <Divider />}
                                    <MenuItem component={Link} to="/">
                                        <ThemedDropdownNavItem
                                            text="Admin view"
                                            selected={
                                                !pathname.includes('/ppv')
                                            }
                                        />
                                    </MenuItem>
                                    <MenuItem component="a" href={myDomain}>
                                        <ThemedDropdownNavItem text="Employee view" />
                                    </MenuItem>
                                    <MenuItem component={Link} to="/ppv">
                                        <ThemedDropdownNavItem
                                            text="Embed content"
                                            selected={pathname.includes('/ppv')}
                                        />
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem component={Link} to="/settings">
                                        <ThemedDropdownNavItem
                                            text="Settings"
                                            selected={pathname.includes(
                                                '/settings'
                                            )}
                                            isSettings
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to="/"
                                        onClick={() => handleSignout()}
                                    >
                                        <ThemedDropdownNavItem
                                            text={
                                                <TextDefault error bold>
                                                    Sign out
                                                </TextDefault>
                                            }
                                            isSignOut
                                        />
                                    </MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                    </SpaceBetweenDiv>
                )}
                {isAuthenticated && (
                    <Routes>
                        {/*Not show upgrade in ppv & partner routes*/}
                        <Route path="ppv/*" element={<Fragment />} />
                        <Route path="partner/*" element={<Fragment />} />
                        <Route
                            path="*"
                            element={
                                <Fragment>
                                    {!haveValidPackage && (
                                        <FlexDiv
                                            justifyCenter
                                            alignCenter
                                            backgroundColor={
                                                palette.vibrant.yellow
                                            }
                                            padding={8}
                                        >
                                            {!havePartnerSubscription ? (
                                                <>
                                                    <TextDefault bold>
                                                        Your free trial has
                                                        expired.{' '}
                                                        {currentCompany &&
                                                            currentCompany.chargebeeEnabled && (
                                                                <Fragment>
                                                                    Please{' '}
                                                                    <Link to="/subscriptions/upgrade">
                                                                        upgrade{' '}
                                                                    </Link>{' '}
                                                                    your {name}{' '}
                                                                    subscription.
                                                                </Fragment>
                                                            )}{' '}
                                                        Need more time?
                                                        Contact&nbsp;
                                                        <TextDefault
                                                            bold
                                                            underline
                                                        >
                                                            <a
                                                                href={`mailto:${supportEmail}`}
                                                            >
                                                                {supportEmail}
                                                            </a>
                                                        </TextDefault>
                                                    </TextDefault>
                                                </>
                                            ) : (
                                                <TextDefault bold>
                                                    Your subscription has
                                                    expired. Please
                                                    contact&nbsp;
                                                    <TextDefault bold underline>
                                                        <a
                                                            href={`mailto:${supportEmail}`}
                                                        >
                                                            {supportEmail}
                                                        </a>
                                                    </TextDefault>
                                                    &nbsp;to upgrade.
                                                </TextDefault>
                                            )}
                                        </FlexDiv>
                                    )}
                                    {currentCompany &&
                                        currentCompany.usersLeft < 0 && (
                                            <Alert
                                                color="warning"
                                                isOpen={
                                                    tooManyUsersBannerVisible
                                                }
                                                toggle={() =>
                                                    setTooManyUsersBannerVisible(
                                                        false
                                                    )
                                                }
                                            >
                                                <TextDefault bold>
                                                    You have registered more
                                                    employees (
                                                    {currentCompany.users_total}
                                                    ) than your subscription
                                                    package allows (
                                                    {
                                                        currentCompany.users_available
                                                    }
                                                    ).
                                                </TextDefault>
                                                <br />
                                                <TextDefault bold>
                                                    {currentCompany.chargebeeEnabled ? (
                                                        <Fragment>
                                                            <Link to="/settings/subscriptions">
                                                                Change
                                                                subscription
                                                            </Link>{' '}
                                                            or{' '}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            You can contact your
                                                            provider at{' '}
                                                            <a
                                                                href={`mailto:${supportEmail}`}
                                                            >
                                                                {supportEmail}
                                                            </a>{' '}
                                                            or
                                                        </Fragment>
                                                    )}{' '}
                                                    <Link to="/users">
                                                        {' '}
                                                        Edit employee lists
                                                    </Link>
                                                </TextDefault>
                                            </Alert>
                                        )}
                                </Fragment>
                            }
                        />
                    </Routes>
                )}
                {isAuthenticated && isTrial && currentCompany && (
                    <Routes>
                        {/*Don't show upgrade message in partner routes*/}
                        <Route path="partner/*" element={<Fragment />} />
                        <Route
                            path="subscriptions/upgrade/*"
                            element={<Fragment />}
                        />
                        <Route
                            path="*"
                            element={
                                <FlexDiv
                                    justifyCenter
                                    alignCenter
                                    backgroundColor={palette.vibrant.yellow}
                                    padding={6}
                                >
                                    <TextDefault bold>
                                        Trial expires on{' '}
                                        {formatDate(currentCompany.trial_ends)}.
                                    </TextDefault>
                                    {currentCompany.chargebeeEnabled && (
                                        <ThemedButton
                                            mode="black"
                                            size="tiny"
                                            noTextTransform
                                            airy
                                            as={Link}
                                            to={'/subscriptions/upgrade'}
                                            text="Upgrade now"
                                        />
                                    )}
                                </FlexDiv>
                            }
                        />
                        )
                    </Routes>
                )}
                {notification && (
                    <Alert color={notificationType}>
                        {notificationMessage}
                    </Alert>
                )}
                <Container
                    className={classnames('', {
                        'd-flex': contentVerticallyCentered,
                    })}
                >
                    <Outlet />
                </Container>
                <footer>
                    <FlexDiv justifyCenter my={24} alignCenter>
                        <TextTiny lighter>
                            <a
                                href={privacyUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>{' '}
                            &nbsp;&#183; © {date.getFullYear()}{' '}
                            {(brandingLoaded && account_name) || 'AwareGO'}
                        </TextTiny>
                    </FlexDiv>
                </footer>
            </div>
        </Fragment>
    );
}

export default observer(Layout);
