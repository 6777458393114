import React, { createRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert } from 'reactstrap';
import {
    FlexDiv,
    Image,
    TextDefault,
    TextLead,
    Heading2,
} from '@awarego/awarego-components';
import {ThemedLink} from "../themed/ThemedComponents";

function LogoUpload({ onLogoSelected, uploadingLogo }) {
    const [error, setError] = useState(null);

    const fileRef = createRef();

    const handleFileSelect = async (e) => {
        let tempError = null;

        e.stopPropagation();
        e.preventDefault();

        const files = e.target.files || e.dataTransfer.files;
        const image = files[0];

        // Error handling
        if (image.type !== 'image/png') {
            tempError = `'${image.type}' is not a supported format`;
        } else if (image.size > 80000) {
            tempError = `'${image.name}' is too large, please pick a smaller file`;
        }

        const formData = new FormData();
        formData.append('image', image);

        if (tempError) {
            setError(tempError);
        } else {
            setError(null);
            onLogoSelected(formData);
        }
    };

    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    const chooseFile = () => {
        fileRef.current.click();
    };

    return (
        <FlexDiv column alignCenter>
            {error && (
                <FlexDiv mb={16}>
                    <Alert color="danger">{error}</Alert>
                </FlexDiv>
            )}
            <FlexDiv
                onDragOver={handleDragOver}
                onDrop={handleFileSelect}
                column
                padding={80}
                alignCenter
                mb={16}
                border
            >
                <Image
                    src="/static/img/drop-icon.svg"
                    alt="drop your files here"
                    width={50}
                    marginAuto
                />
                {uploadingLogo ? (
                    <Heading2>Uploading...</Heading2>
                ) : (
                    <FlexDiv column alignCenter>
                        <Heading2>Drag and drop</Heading2>
                        <TextLead>
                            or{' '}
                            <ThemedLink onClick={chooseFile}>
                                browse
                            </ThemedLink>{' '}
                            for an image file
                        </TextLead>
                        <input
                            className="hidden"
                            type="file"
                            multiple={false}
                            accept="image/png"
                            onChange={handleFileSelect}
                            ref={fileRef}
                        />
                    </FlexDiv>
                )}
            </FlexDiv>
            <TextDefault>
                The image file should be in PNG, up to 640 x 264 pixels and no
                bigger than 80KB
            </TextDefault>
        </FlexDiv>
    );
}

export default observer(LogoUpload);
