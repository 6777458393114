import React, { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import Alert from '../../components/Alert';
import DragAndDropImport from '../../components/file-handlers/DragAndDropImport';
import EditableUsersTable from './components/EditableUsersTable';
import MenuItem from '@mui/material/MenuItem';
import {
    Heading3,
    FlexDiv,
    TextDefault,
    TextInput,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

function AddOrImport({
    batchMode,
    processedRecords,
    hideListName = false,
    deleteObsolete,
    showEncodingSelect,
    showEncodingOptions,
    encoding,
    changeEncoding,
    importFiles,
    usersForm,
    showHeader,
    companyId,
    onSelectExistingUsers,
    isImportingLists,
    setAcceptedFiles,
}) {
    const { userStore } = useStores();
    useEffect(() => {
        return () => {
            userStore.importError = null;
        };
    }, []);

    const batchModeContent = useMemo(() => {
        if (!processedRecords)
            return (
                <DragAndDropImport
                    onImportFiles={importFiles}
                    onSetAcceptedFiles={(files) => {
                        setAcceptedFiles(files);
                    }}
                    isImportingLists={isImportingLists}
                    fileType="csv"
                    tooltip="Expected .csv format is following [email], [full name (optional)], [department (optional)]."
                    acceptedFormats=".txt, .csv, text/csv, text/plain, text.csv, text.plain"
                    fileEndings={['txt', 'csv']}
                />
            );
        return (
            <Fragment>
                {processedRecords.newUsers.length > 0 && (
                    <Fragment>
                        <Alert severity="success">
                            {processedRecords.newUsers.length} new employees
                            added from .csv file
                        </Alert>
                        <div className="users-to-add">
                            {processedRecords.newUsers.map((x) => (
                                <div key={x.email} className={'user-record'}>
                                    {'New: '}
                                    {x.email}, {x.name}{' '}
                                    {!hideListName &&
                                        x.listName &&
                                        `, ${x.listName}`}
                                </div>
                            ))}
                        </div>
                    </Fragment>
                )}
                {processedRecords.updatedUsers &&
                    processedRecords.updatedUsers.length > 0 && (
                        <Fragment>
                            <Alert severity="success">
                                {processedRecords.updatedUsers.length} employees
                                updated from .csv file
                            </Alert>
                            <div className="users-to-add">
                                {processedRecords.updatedUsers.map((x) => (
                                    <div
                                        key={x.email}
                                        className={'user-record'}
                                    >
                                        {'Updated: '}
                                        {x.email}, {x.name}{' '}
                                        {!hideListName &&
                                            x.listName &&
                                            `, ${x.listName}`}
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    )}
                {processedRecords.newUsers.length === 0 &&
                    processedRecords.obsolete > 0 && (
                        <div className="d-flex flex-center flex-fill flex-column">
                            No new user found,
                            {deleteObsolete
                                ? `${processedRecords.obsolete} ${pluralize(
                                      'employee',
                                      processedRecords.obsolete
                                  )} will be deleted`
                                : `Tick checkbox above to remove obsolete users`}
                        </div>
                    )}
                {processedRecords.newUsers.length === 0 &&
                    processedRecords.obsolete === 0 && (
                        <div className="d-flex flex-center flex-fill flex-column">
                            No changes found
                        </div>
                    )}
                {processedRecords.count_invalid > 0 && (
                    <div className="users-to-add">
                        <Heading3 displayBlock mb={8}>
                            Found errors:
                        </Heading3>
                        {processedRecords.data
                            .filter((x) => x.error)
                            .map((x) => (
                                <div key={x.lineNumber} className="user-record">
                                    {x.lineNumber}: {x.original}
                                    <div className={'user-record-error'}>
                                        {x.error}
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                <FlexDiv justifyEnd>
                    {!showEncodingSelect && (
                        <TextDefault link onClick={showEncodingOptions}>
                            Select file encoding
                        </TextDefault>
                    )}
                    {showEncodingSelect && (
                        <TextInput
                            select
                            value={encoding}
                            onChange={changeEncoding}
                            name="select-encoding"
                            label="File type"
                        >
                            <MenuItem value="utf-8">UTF-8</MenuItem>
                            <MenuItem value="iso-8859-1">
                                ISO 8859-1 (Icelandic)
                            </MenuItem>
                        </TextInput>
                    )}
                </FlexDiv>
            </Fragment>
        );
    }, [
        changeEncoding,
        deleteObsolete,
        encoding,
        hideListName,
        importFiles,
        isImportingLists,
        processedRecords,
        showEncodingOptions,
        showEncodingSelect,
        setAcceptedFiles,
    ]);

    return batchMode ? (
        batchModeContent
    ) : (
        <EditableUsersTable
            onSelectExistingUsers={onSelectExistingUsers}
            form={usersForm}
            showHeader={showHeader}
            companyId={companyId}
        />
    );
}

export default observer(AddOrImport);
