import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import { makeAutoObservable } from 'mobx';

class PartnerStore {
    error = null;
    editingCompany = null;
    partnerSubscriptionsOverview = null;
    collectingPayment = false;
    loadingPartnerCompanies = false;
    loadingCompany = false;
    loadingCompanies = false;
    savingCompany = false;
    savingPPV = false;
    savingPPVApiKey = false;
    addingManager = false;
    loadingPPVLog = false;
    loadingPPVTransactions = false;
    loadingPartnerSubscriptionsOverview = false;
    uploadingLogoAsPartner = false;
    companySearchValue = '';
    companies = [];
    ppvLog = [];
    ppvTransactions = [];
    removingCompanies = [];

    constructor() {
        makeAutoObservable(this);
    }

    setError(error, name) {
        switch (name) {
            default:
                this.error = error;
        }
    }

    setCompanySearchValue(companySearchValue) {
        this.companySearchValue = companySearchValue;
    }

    async update(companyId, values) {
        this.savingCompany = true;
        this.error = null;
        try {
            if (companyId) {
                let result = await services.Companies.update({
                    id: companyId,
                    ...values,
                });
                Object.assign(this.editingCompany, result, values);
            } else {
                await services.Companies.super().create({ ...values }, true);
                await this.loadCompanies();
                return true;
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    clearEditingCompany() {
        this.editingCompany = null;
    }

    async loadCompany(companyId, partnerId) {
        if (this.loadingCompany) return;
        this.error = null;
        if (this.editingCompany != null) {
            if (this.editingCompany.id === companyId) return;
            else this.editingCompany = null;
        }
        try {
            this.loadingCompany = true;

            this.editingCompany = partnerId
                ? await services.Partners.companiesService(partnerId).fetch(
                      companyId
                  )
                : await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompany = false;
        }
    }

    async addManager(companyId, data) {
        this.addingManager = true;
        this.error = null;
        try {
            await services.Companies.managersService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    async removeManager(companyId, email) {
        this.error = null;
        try {
            await services.Companies.managersService(companyId).delete(email);
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async createPPV(companyId) {
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.createPPV(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    async addViews(companyId, views, notes) {
        if (!views) return;
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.addViews(companyId, {
                views,
                notes,
            });
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    async loadPPVLog(companyId) {
        this.loadingPPVLog = true;
        this.error = null;
        try {
            let ppvLog = await services.Companies.ppvLog(companyId);
            this.ppvLog.replace(ppvLog);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVLog = false;
        }
    }

    async loadPPVTransactions(companyId) {
        this.loadingPPVTransactions = true;
        this.error = null;
        try {
            let ppvTransactions = await services.Companies.ppvTransactions(
                companyId
            );
            this.ppvTransactions.replace(ppvTransactions);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVTransactions = false;
        }
    }

    async newPPVApiKey(companyId) {
        this.savingPPVApiKey = true;
        this.error = null;
        try {
            let ppv = await services.Companies.newPPVApiKey(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPVApiKey = false;
        }
    }

    async uploadLogoAsPartnerAdmin(companyId, formData) {
        this.uploadingLogoAsPartner = true;
        try {
            let result = await services.Resources.addLogo(companyId, formData);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, {
                    logo_name: result.logo_name,
                });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogoAsPartner = false;
        }
    }

    async removeLogoAsPartnerAdmin(companyId) {
        try {
            let result = await services.Resources.removeLogo(companyId);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, { logo_name: null });
            }
            //await services.Resources.removeLogo(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async loadCompanies() {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Companies.list();
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    async deleteCompany(partnerId, companyId) {
        this.removingCompanies.push(companyId);
        this.error = null;
        try {
            await services.Partners.companiesService(partnerId).delete(
                companyId
            );
            let existing = this.companies.find((x) => x.id === companyId);
            if (existing) this.companies.remove(existing);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingCompanies.remove(companyId);
        }
    }

    async loadPartnerCompanies(partnerId) {
        this.loadingPartnerCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Partners.companiesService(
                partnerId
            ).list();
            result.forEach(
                (c) => (c.link = `/partner/${partnerId}/companies/${c.id}`)
            );
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartnerCompanies = false;
        }
    }

    get filteredPartnerCompanies() {
        return this.companies
            .filter(
                (x) =>
                    !(
                        this.companySearchValue.length > 0 &&
                        x.name !== null &&
                        !x.name
                            .toLowerCase()
                            .includes(this.companySearchValue.toLowerCase())
                    )
            )
            .sort(
                (companyA, companyB) =>
                    new Date(companyB.created) - new Date(companyA.created)
            );
    }

    async loadPartnerSubscriptionsOverview(partnerId) {
        this.loadingPartnerSubscriptionsOverview = true;
        this.error = null;

        try {
            this.partnerSubscriptionsOverview =
                await services.Partners.subscriptionsService(
                    partnerId
                ).overview();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartnerSubscriptionsOverview = false;
        }
    }

    async collectPartnerPayment(partnerId) {
        this.collectingPayment = true;
        this.error = null;
        try {
            await services.Partners.subscriptionsService(partnerId).collect();
            if (this.partnerSubscriptionsOverview)
                this.partnerSubscriptionsOverview.collected = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.collectingPayment = false;
        }
    }

    async openManagePartnerSession(partnerId) {
        return services.Partners.openManageSession(partnerId);
    }

    isCompanyValid(company) {
        const validTo = company.valid_to;
        return validTo && new Date(validTo) > new Date();
    }

    isCompanyInvalid(company) {
        return !this.isCompanyValid(company);
    }

    get activeCompaniesCount() {
        return this.companies.filter((x) => this.isCompanyValid(x)).length;
    }

    get inactiveCompaniesCount() {
        return this.companies.filter((x) => this.isCompanyInvalid(x)).length;
    }
}

export default PartnerStore;
