import React from 'react';
import {
    FlexDiv,
    Heading1,
    Heading3,
    TextTiny,
} from '@awarego/awarego-components';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    section: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function NotAuthorized() {
    const classes = useStyles();
    return (
        <FlexDiv justifyCenter mt={24} pb={24} className={classes.root}>
            <FlexDiv
                justifyCenter
                mt={24}
                pb={24}
                column
                className={classes.section}
            >
                <Heading3>Authorized personal only</Heading3>
                <Heading1>Access denied</Heading1>
                <TextTiny>
                    You do not have permission to access the requested page.
                </TextTiny>
            </FlexDiv>
            <img src="/static/img/403.svg" className={classes.section} />
        </FlexDiv>
    );
}

export default NotAuthorized;
