import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import TrainingUsersTable from './TrainingUsersTable';
import TrainingUsersHeader from './TrainingUsersHeader';
import { Block } from '@awarego/awarego-components';

function TrainingUsersContainer({
    ssoEnabled = false,
    anonymousTracking = false,
    users,
    training,
}) {
    const { userStore, store } = useStores();
    const { currentCompany } = store;

    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        userStore.setQueryContext({
            n: 5,
            p: 0,
            order: 'name asc',
            filter: '',
        });
    }, []);

    return (
        <Block gap={24} tablePadding noBorder resetMargin>
            <TrainingUsersHeader onSearchValue={setSearchValue} users={users} />
            {users && training && (
                <TrainingUsersTable
                    currentCompany={currentCompany}
                    users={users}
                    training={training}
                    ssoEnabled={ssoEnabled}
                    anonymousTracking={anonymousTracking}
                    searchValue={searchValue}
                />
            )}
        </Block>
    );
}

export default observer(TrainingUsersContainer);
