import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { generateLoadEntity, generateLoadList } from '../utils/mobx';
import { extractErrorMessage } from '../utils/helpers';
import Report from './models/report';
import omit from 'lodash/omit';
import { downloadFile } from '../utils/fileDownload';
import { FILE_TYPES } from '../utils/constants';
import contentDisposition from 'content-disposition';

class ReportsStore {
    reports = [];
    report = null;
    reportExecutions = [];
    reportExecutionsCount = 0;

    loadingReports = false;
    loadingReport = false;
    loadingExecutions = false;
    deletingReport = false;
    addingReport = false;
    error = null;

    editingReport = null;
    queryContext = null;

    constructor() {
        makeAutoObservable(this);
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    // used for server-side pagination and sorting
    setQueryContext(context = {}) {
        this.queryContext = context;
    }

    get pageIndex() {
        return this.queryContext && this.queryContext.p;
    }

    get pageSize() {
        return this.queryContext && this.queryContext.n;
    }

    get order() {
        return this.queryContext && this.queryContext.order;
    }

    setPagingParams(pageIndex, pageSize) {
        if (this.queryContext) {
            this.queryContext.p = pageIndex;
            this.queryContext.n = pageSize;
        }
    }

    setSortBy(sortBy) {
        if (this.queryContext && sortBy && sortBy.length > 0) {
            // 'generatedAt' is not table column. Order is the same as 'id', therefore column 'id' is used.
            const sortingColumn =
                sortBy[0].id === 'generatedAt' ? 'id' : sortBy[0].id;
            this.queryContext.order = `${sortingColumn} ${
                sortBy[0].desc ? 'desc' : 'asc'
            }`;
        }
    }

    loadAllReports = generateLoadList(
        'loadReports',
        this,
        'loadingReports',
        async (companyId) => {
            const result = await services.Companies.reportsService(
                companyId
            ).all();
            return result.map((report) => {
                return new Report(report, companyId);
            });
        },
        'reports'
    );

    loadExecutionsByReport = generateLoadList(
        'loadExecutionsByReport',
        this,
        'loadingExecutions',
        async (companyId, reportId) => {
            const result = await services.Companies.reportsService(
                companyId
            ).executionsByReportId(reportId, { ...omit(this.queryContext) });

            runInAction(() => {
                this.reportExecutionsCount = result.count;
            });

            return result?.rows;
        },
        'reportExecutions'
    );

    loadSingleReport = generateLoadEntity(
        'loadReport',
        this,
        'loadingReport',
        async (companyId, reportId) => {
            const result = await services.Companies.reportsService(
                companyId
            ).singleReport(reportId);
            return new Report(result, companyId);
        },
        'report'
    );

    async addReport(companyId, data, isSnapshot = false) {
        this.addingReport = true;
        this.error = null;
        try {
            this.report = new Report(data, companyId);
            const options = isSnapshot
                ? {
                      responseType: 'blob',
                  }
                : null;

            const response = await services.Companies.reportsService(
                companyId
            ).create(this.report.toDTO(), options);

            if (!isSnapshot) {
                const report = new Report(response, companyId);
                runInAction(() => {
                    this.reports.push(report);
                });
            } else {
                const disposition = response.headers['content-disposition'];
                let fileName = 'Snapshot Report';

                if (disposition) {
                    const dispositionParams =
                        contentDisposition.parse(disposition).parameters;
                    fileName = dispositionParams.filename
                        ? dispositionParams.filename
                        : 'Snapshot Report';
                }

                downloadFile(response.data, fileName);
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingReport = false;
        }
    }

    async updateReport(companyId, reportId, data) {
        this.addingReport = true;
        this.error = null;
        try {
            this.report = new Report(data, companyId);

            const response = await services.Companies.reportsService(
                companyId
            ).update(this.report.toDTO(), reportId);

            runInAction(() => {
                const updatedReport = new Report(response, companyId);
                this.report = updatedReport;

                const newArray = this.reports.map((obj) =>
                    obj.id === updatedReport.id ? updatedReport : obj
                );
                this.reports.replace(newArray);
            });
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingReport = false;
        }
    }

    async delete(companyId, reportId) {
        if (this.deletingReport) return;
        this.deletingReport = true;
        try {
            await services.Companies.reportsService(companyId).delete(reportId);
            this.editingReport = null;
            this.reports.replace(this.reports.filter((x) => x.id !== reportId));
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingReport = false;
        }
    }

    // supports PDF/CSV
    async generateFile(
        companyId,
        reportId,
        executionId,
        type = FILE_TYPES.PDF
    ) {
        this.error = null;
        const options = {
            responseType: 'blob',
        };
        let response;

        try {
            if (type === FILE_TYPES.CSV) {
                response = await services.Companies.reportsService(
                    companyId
                ).executionCSV(reportId, executionId, options);
            } else if (type === FILE_TYPES.PDF) {
                response = await services.Companies.reportsService(
                    companyId
                ).executionPDF(reportId, executionId, options);
            } else {
                return;
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'Company Overview Report';
            if (disposition) {
                const dispositionParams =
                    contentDisposition.parse(disposition).parameters;
                fileName = dispositionParams.filename
                    ? dispositionParams.filename
                    : 'Company Overview Report';
            }
            downloadFile(response.data, fileName);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }
}

export default ReportsStore;
