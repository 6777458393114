import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { FlexDiv, TextDefault } from '@awarego/awarego-components';

export default observer(
    ({ disabled, currentCompany, redirectToUpgrade, centered }) => {
        const { brandingStore } = useStores();
        const { supportEmail } = brandingStore;

        const upgradeText = useMemo(() => {
            if (currentCompany.chargebeeEnabled) {
                if (redirectToUpgrade)
                    return (
                        <TextDefault
                            link
                            bold
                            themed
                            onClick={redirectToUpgrade}
                        >
                            Add more
                        </TextDefault>
                    );
                return (
                    <Link to={`/subscriptions/upgrade/`}>
                        <TextDefault bold themed>
                            Add more
                        </TextDefault>
                    </Link>
                );
            }
            return (
                <TextDefault lighter>
                    Please contact{' '}
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                </TextDefault>
            );
        }, [currentCompany.chargebeeEnabled, redirectToUpgrade, supportEmail]);

        return (
            disabled && (
                <FlexDiv
                    column
                    mt={centered ? 24 : 10}
                    alignEnd={!centered}
                    alignCenter={centered}
                >
                    <TextDefault lighter>Employees limit reached.</TextDefault>
                    {upgradeText}
                </FlexDiv>
            )
        );
    }
);
