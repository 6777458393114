import React from 'react';
import {
    Heading1,
    TextLead,
    FlexDiv,
    SpaceBetweenDiv,
    ThemedButton,
} from '@awarego/awarego-components';

function WizardStepHeader({
    title,
    subtitle,
    summary,
    buttonText,
    onButtonClick,
}) {
    return (
        <>
            <SpaceBetweenDiv alignStart gap={16} mt={40}>
                <FlexDiv column>
                    <Heading1 displayBlock mb={4}>
                        {title}
                    </Heading1>
                    {subtitle && (
                        <TextLead lighter mb={16}>
                            {subtitle}
                        </TextLead>
                    )}
                    {summary && (
                        <TextLead bold themed>
                            {summary}
                        </TextLead>
                    )}
                </FlexDiv>
                {buttonText && (
                    <ThemedButton
                        mode="secondary"
                        onClick={onButtonClick}
                        text={buttonText}
                    />
                )}
            </SpaceBetweenDiv>
        </>
    );
}

export default WizardStepHeader;
