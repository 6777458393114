import React from 'react';
import { FlexDiv, TextTiny, Heading3 } from '@awarego/awarego-components';

function NoItemsYetContent({
    img,
    imgAlt,
    title,
    text,
    buttons,
    extraContent,
}) {
    return (
        <FlexDiv justifyCenter alignCenter column>
            <img src={img} alt={imgAlt} />
            <Heading3 mt={24} mb={text ? 8 : 24}>
                {title}
            </Heading3>
            {text && (
                <TextTiny lighter mb={buttons || extraContent ? 40 : undefined}>
                    {text}
                </TextTiny>
            )}
            {buttons}
            {extraContent}
        </FlexDiv>
    );
}

export default NoItemsYetContent;
