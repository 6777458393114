import React, { Fragment } from 'react';
import pluralize from 'pluralize';
import EmployeeListsOverview from './employee-lists-overview';
import SelectUsers from '../../../containers/users/components/select-users-step';
import Modal from 'react-modal';
import {
    Heading3,
    TextDefault,
    FlexDiv,
    ThemedButton,
} from '@awarego/awarego-components';
import WizardStepHeader from './WizardStepHeader';
import { Link } from 'react-router-dom';

function SelectEmployees({
    itemString,
    item,
    lists,
    toggleUserListSelected,
    ongoing,
    addEmployeeModal,
    showAddEmployeeModal,
    hideAddEmployeeModal,
    recipientsModal,
    showRecipientsModal,
    hideRecipientsModal,
    error,
    loadingUsers,
    requestUsersForList,
    selectedLists,
    selectedUserCount,
    disabled,
    createRecipientsList,
    currentCompany,
    redirectToUpgrade,
}) {
    return (
        <Fragment>
            <WizardStepHeader
                title={'Select employees or create a new list'}
                summary={`${item.selectedUserCount} ${pluralize(
                    'employee',
                    item.selectedUserCount
                )} selected`}
                buttonText={item.selectedUserCount > 0 && 'Select employees'}
                onButtonClick={showAddEmployeeModal}
            />
            <div className="table">
                {lists.length > 0 ? (
                    item.selectedUserCount > 0 ? (
                        <EmployeeListsOverview
                            lists={item.userListsSelected}
                            onRemoveList={toggleUserListSelected}
                            item={item}
                            ongoing={ongoing}
                            itemString={itemString}
                            currentCompany={currentCompany}
                        />
                    ) : (
                        <FlexDiv pt={48} alignCenter column justifyCenter>
                            <FlexDiv mb={16}>
                                <img
                                    src="/static/img/employee-list-empty.svg"
                                    alt="No employees selected yet"
                                />
                            </FlexDiv>
                            <Heading3 mt={16} mb={8} displayBlock>
                                No employees selected yet
                            </Heading3>
                            <TextDefault lighter>
                                Choose which employee lists will receive this{' '}
                                {itemString}
                            </TextDefault>
                            <FlexDiv mt={24} mb={32}>
                                <ThemedButton
                                    onClick={showAddEmployeeModal}
                                    mode="secondary"
                                    text="Select employees"
                                />
                            </FlexDiv>
                        </FlexDiv>
                    )
                ) : (
                    <FlexDiv alignCenter column justifyCenter gap={16} pt={48}>
                        <img
                            src="/static/img/employee-list-empty.svg"
                            alt="a list of employee departments"
                        />
                        <Heading3 my={16}>No employee lists yet</Heading3>
                        <ThemedButton
                            onClick={showRecipientsModal}
                            mode="secondary"
                            text="Create new list"
                        />
                        <TextDefault mt={8}>
                            Or{' '}
                            <TextDefault bold themed>
                                <Link to="/settings/integrations">
                                    set up integrations
                                </Link>
                            </TextDefault>{' '}
                            to sync your employees automatically
                        </TextDefault>
                    </FlexDiv>
                )}
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={addEmployeeModal}
                onRequestClose={hideAddEmployeeModal}
                className="Modal schedule"
            >
                <SelectUsers
                    lists={lists}
                    showRecipientsModal={showRecipientsModal}
                    recipientsModal={recipientsModal}
                    hideRecipientsModal={hideRecipientsModal}
                    createRecipientsList={createRecipientsList}
                    item={item}
                    error={error}
                    loadingUsers={loadingUsers}
                    requestUsersForList={requestUsersForList}
                    selectedLists={selectedLists}
                    selectedUserCount={selectedUserCount}
                    onUserListSelected={toggleUserListSelected}
                    hideAddEmployeeModal={hideAddEmployeeModal}
                    disabled={disabled}
                    ongoing={ongoing}
                    currentCompany={currentCompany}
                    redirectToUpgrade={redirectToUpgrade}
                />
            </Modal>
        </Fragment>
    );
}

export default SelectEmployees;
