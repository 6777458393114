import React from 'react';
import { observer } from 'mobx-react';
import ReportSetupFormFragment from '../ReportSetupFormFragment';
import ReportFrequencyFormFragment from '../ReportFrequencyFormFragment';
import ReportRecipientsFormFragment from '../ReportRecipientsFormFragment';
import { CleanHR } from '@awarego/awarego-components';

function ReportRecurringForm({
    reportsForm,
    lists,
    usersTimeZone,
    editingReport,
}) {
    const inputHasError = (input) =>
        reportsForm.$(input).error && reportsForm.$(input).error.length > 0;

    return (
        <>
            <ReportSetupFormFragment
                reportsForm={reportsForm}
                lists={lists}
                inputHasError={inputHasError}
                editingReport={editingReport}
            />
            <ReportFrequencyFormFragment
                reportsForm={reportsForm}
                inputHasError={inputHasError}
                usersTimeZone={usersTimeZone}
                editingReport={editingReport}
            />
            <CleanHR my={32} />
            <ReportRecipientsFormFragment
                inputHasError={inputHasError}
                reportsForm={reportsForm}
                editingReport={editingReport}
            />
        </>
    );
}

export default observer(ReportRecurringForm);
