import React from 'react';
import {
    Heading2,
    Heading4,
    SpaceBetweenDiv,
    TextLabel,
} from '@awarego/awarego-components';

function NewSubjects({ latestSubjects, handleClick }) {
    return (
        <div className="block">
            <Heading2 mt={8}>New subjects</Heading2>
            {latestSubjects.map((subject, i) => {
                return (
                    <div
                        key={i}
                        className="item video"
                        onClick={() => handleClick(subject)}
                        style={{ cursor: 'pointer' }}
                    >
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url(${subject.thumb})`,
                            }}
                        />
                        <SpaceBetweenDiv>
                            <Heading4 displayBlock my={8}>
                                {subject.title}
                            </Heading4>

                            <TextLabel lighter noWrap>
                                {Math.floor(subject.duration / 60)}.
                                {subject.duration % 60} min
                            </TextLabel>
                        </SpaceBetweenDiv>
                        <p>{subject.description}</p>
                        {subject !==
                        latestSubjects[latestSubjects.length - 1] ? (
                            <hr />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
}

export default NewSubjects;
