import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './IntegrationElement.scss';
import { Heading3, ThemedButton } from '@awarego/awarego-components';

function IntegrationElement({ integration, supportEmail }) {
    return (
        <Col sm={12} md={12} lg={6} key={integration.id}>
            <div className="block block-shadow">
                {integration.beta && (
                    <div className="ribbon ribbon-top-left">
                        <span>Beta</span>
                    </div>
                )}
                <div className="integration-card-header">
                    <Heading3>{integration.name}</Heading3>
                    <img
                        src={`/static/img/${integration.logo}`}
                        alt={`${integration.name} integration`}
                    />
                </div>
                <p>{integration.description}</p>
                <div className="action-bar">
                    <div className="pull-right">
                        {integration.needsUpgrade ? (
                            <a href={`mailto:${supportEmail}`}>
                                <ThemedButton
                                    size="small"
                                    mode="primary"
                                    text="Contact to upgrade"
                                />
                            </a>
                        ) : integration.id === 'teams' ||
                          integration.id === 'azuresso' ||
                          integration.id === 'google' ? (
                            integration.enabled === '0' ? (
                                <Link
                                    to={`/settings/integrations/${integration.id}`}
                                >
                                    <ThemedButton
                                        size="small"
                                        mode="primary"
                                        text="Connect"
                                    />
                                </Link>
                            ) : (
                                <Link
                                    to={`/settings/integrations/${integration.id}`}
                                >
                                    <ThemedButton
                                        size="small"
                                        mode="secondary"
                                        text="Manage"
                                    />
                                </Link>
                            )
                        ) : integration.connected ? (
                            <Link
                                to={`/settings/integrations/${integration.id}`}
                            >
                                <ThemedButton
                                    size="small"
                                    mode="secondary"
                                    text="Manage"
                                />
                            </Link>
                        ) : (
                            <Link
                                to={`/settings/integrations/${integration.id}`}
                            >
                                <ThemedButton
                                    size="small"
                                    mode="primary"
                                    text="Connect"
                                />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default IntegrationElement;
