import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import Waiting from '../../components/waiting';
import { querystring } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';

function SignIn() {
    const { authStore } = useStores();
    const navigate = useNavigate();
    const { token } = useParams();

    //http://localhost:3002/auth/jwt/signin/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzYyLCJlbWFpbCI6ImFsZWtzYW5kci5wb3BvdkBhd2FyZWdvLmNvbSIsInNjb3BlIjoibG1zIiwidmVyc2lvbiI6IjIuMTAuMTIiLCJpYXQiOjE2NzI3NTQ1NjksImV4cCI6MTY3Mzk2NDE2OX0.G_WnjGsJ6xXXAQJEGOLUm-_O-ZEtfXuf7deczpQrVjw

    useEffect(() => {
        if (token) {
            authStore.setToken(token);
            let redirect = querystring('redirect') || '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            navigate(redirect);
        }
    }, [token]);

    return <Waiting waitingActive={true} from={'TokenSignIn'} />;
}

export default observer(SignIn);
