import React from 'react';
import { FlexDiv, palette } from '@awarego/awarego-components';

function FiftyFiftyDiv({ leftHalf, rightHalf }) {
    return (
        <FlexDiv flexGrow={2}>
            <FlexDiv
                flexGrow={1}
                padding={50}
                backgroundColor={palette.graphite.white}
                justifyCenter
            >
                {leftHalf}
            </FlexDiv>
            <FlexDiv
                //widthPercentage={50}
                flexGrow={1}
                padding={50}
                backgroundColor={palette.graphite.cloud}
            >
                {rightHalf}
            </FlexDiv>
        </FlexDiv>
    );
}

export default FiftyFiftyDiv;
