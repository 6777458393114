import React, { useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import { useStores } from '../../../../hooks/use-stores';
import pluralize from 'pluralize';
import SemiCircleProgress from '../../../../components/manager/assessment/semi-circle-progress';
import {
    FlexDiv,
    Heading3,
    Heading4,
    SpaceBetweenDiv,
    Block,
    TextInput,
    TextTiny,
    ThemedButton,
    LightTooltip,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import TableClient from '../../../../components/table/TableClient';

function Questions({
    threatAreas,
    showReviewQuestionsModal,
    needsFilter,
    specifiedThreatAreaByDefault,
    companyId,
    assessmentId,
    hasStarted,
}) {
    const { assessmentStore } = useStores();
    const { loadingQuestionsReportList, questionsReportList } = assessmentStore;
    const rowsPerPage = 5;

    const [threatAreaFilter, setThreatAreaFilter] = useState('all');

    const threatAreaQueryFilter = useMemo(() => {
        if (specifiedThreatAreaByDefault) return specifiedThreatAreaByDefault;
        if (threatAreaFilter === 'all') return null;
        return threatAreaFilter;
    }, [specifiedThreatAreaByDefault, threatAreaFilter]);

    useEffect(() => {
        assessmentStore.loadAssessmentQuestionsReport(
            companyId,
            assessmentId,
            threatAreaQueryFilter && {
                threatArea: threatAreaQueryFilter,
            }
        );
    }, [companyId, assessmentId, assessmentStore, threatAreaQueryFilter]);

    const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'number',
                Cell: (x) => <Heading4 bold>{x.value}</Heading4>,
            },
            {
                Header: 'Scenario',
                accessor: 'name',
                Cell: (x) => (
                    <Heading4
                        bold
                        link
                        onClick={() =>
                            showReviewQuestionsModal(
                                x.row.original.id,
                                !hasStarted
                            )
                        }
                    >
                        {x.value}
                    </Heading4>
                ),
            },
            {
                Header: 'Threat area',
                accessor: 'area',
            },
            {
                Header: '# Key behaviours',
                accessor: 'key_behaviour_count',
                Cell: (x) => (
                    <LightTooltip
                        title={x.row.original.component_names.map((b) => (
                            <div key={b}>{b}</div>
                        ))}
                    >
                        <u>
                            {x.value} {pluralize('Behaviours', x.value)}
                        </u>
                    </LightTooltip>
                ),
            },
            {
                Header: 'Average score',
                accessor: 'average_score_percentage',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        width={48}
                        top={14}
                        halfCircle
                        percentageText
                        showNA={!hasStarted}
                    />
                ),
            },
            {
                id: 'review_answers',
                Cell: (x) => (
                    <TextTiny
                        bold
                        link
                        themed
                        onClick={() =>
                            showReviewQuestionsModal(
                                x.row.original.id,
                                !hasStarted
                            )
                        }
                    >
                        {hasStarted ? 'Review answers' : 'Preview question'}
                    </TextTiny>
                ),
                showOnHover: true,
            },
        ],
        [hasStarted, showReviewQuestionsModal]
    );

    return (
        <Block gap={24}>
            <SpaceBetweenDiv alignCenter>
                <Heading3>
                    Scenarios{' '}
                    {questionsReportList.length > 0
                        ? `(${questionsReportList.length})`
                        : ''}
                </Heading3>
                <FlexDiv alignCenter gap={16}>
                    {needsFilter ? (
                        <TextInput
                            select
                            value={threatAreaFilter || 'all'}
                            onChange={(e) => {
                                setThreatAreaFilter(e.target.value);
                            }}
                            inline
                        >
                            <MenuItem value="all">
                                All threat areas ({threatAreas.length})
                            </MenuItem>
                            {threatAreas.map((area, i) => (
                                <MenuItem value={area.name} key={i}>
                                    {area.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    ) : (
                        ''
                    )}
                    <ThemedButton
                        mode="secondary"
                        $noTextTransform
                        onClick={() =>
                            showReviewQuestionsModal(
                                questionsReportList[0].id,
                                !hasStarted
                            )
                        }
                        text={
                            hasStarted
                                ? 'Review all answers'
                                : 'Preview all scenarios'
                        }
                    />
                </FlexDiv>
            </SpaceBetweenDiv>
            {loadingQuestionsReportList ? (
                <ThemedSpinner />
            ) : (
                <TableClient
                    columns={columns}
                    data={questionsReportList}
                    defaultSortBy={'number'}
                    defaultSortDescent={false}
                    hidePagination={
                        questionsReportList &&
                        questionsReportList.length <= rowsPerPage
                    }
                />
            )}
        </Block>
    );
}

export default observer(Questions);
