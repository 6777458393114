/* eslint-disable no-empty */
import { makeAutoObservable, observable } from 'mobx';
import keyBy from 'lodash/keyBy';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import { generateLoadEntity } from '../utils/mobx';

class ListsStore {
    lists = observable([]);
    list = null;
    deletingItems = [];

    loadingLists = false;
    loadingList = false;
    savingList = false;
    editingList = undefined;
    error = undefined;

    constructor(userStore, commonStore) {
        makeAutoObservable(this);
        this.userStore = userStore;
        this.commonStore = commonStore;
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }
    clearError() {
        this.error = null;
    }

    addOrUpdateList(list) {
        list.link = `/users/list/${list.id}`;
        let index = this.lists.findIndex((l) => l.id === list.id);
        if (index > -1) Object.assign(this.lists[index], list);
        else this.lists.push(list);
    }

    async loadCompanyLists(companyId) {
        if (this.loadingLists) return;
        this.loadingLists = true;
        this.lists.clear();
        try {
            const result = await services.Companies.listsService(
                companyId
            ).list();
            result.forEach((list) => (list.link = `/users/list/${list.id}`));
            this.lists.replace(result);
        } catch (e) {
        } finally {
            this.loadingLists = false;
        }
    }

    async loadList(companyId, listId) {
        if (this.loadingList) return;
        this.loadingList = true;
        this.list = undefined;
        try {
            this.editingList = await services.Companies.listsService(
                companyId
            ).fetch(listId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingList = false;
        }
    }

    loadSingleList = generateLoadEntity(
        'loadList',
        this,
        'loadingList',
        async (companyId, listId) => {
            const result = await services.Companies.listsService(
                companyId
            ).fetch(listId);
            return result;
        },
        'list'
    );

    async delete(companyId, listId) {
        this.deletingItems.push(listId);
        try {
            const result = await services.Companies.listsService(
                companyId
            ).delete(listId);
            let list = this.lists.find((x) => x.id === listId);
            if (list) this.lists.remove(list);
            return result;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingItems.remove(listId);
        }
    }

    async save(companyId, listId, values) {
        this.savingList = true;
        try {
            if (this.editingList && listId) {
                await services.Companies.listsService(companyId).update({
                    id: this.editingList.id,
                    ...values,
                });
                this.commonStore.analyticsEvent(
                    'lists',
                    'update',
                    `company-${companyId}`
                );
            } else {
                let result = await services.Companies.listsService(
                    companyId
                ).create({ active: 1, ...values });
                this.commonStore.analyticsEvent(
                    'lists',
                    'create',
                    `company-${companyId}`
                );
                listId = result.insertId;
            }

            this.addOrUpdateList(
                await services.Companies.listsService(companyId).fetch(listId)
            );
            this.editingList = null;
            return listId;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingList = false;
        }
    }

    get allListsIndex() {
        return keyBy(this.lists, 'id');
    }

    get listsWithUsers() {
        let result = [];
        this.lists.forEach((list) => {
            let usersDetails = this.userStore.accumulatedIndex[list.id];
            result.push({
                ...list,
                users: usersDetails ? usersDetails.users : [],
            });
        });
        return result;
    }
}

export default ListsStore;
