import RestService from './RestService';

export default class TemplatesService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/templates`);
        } else super('/templates');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetSubjects(id) {
        return this.get(`${this.baseUrl}/all/${id}/subjects`);
    }
}
