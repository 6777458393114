import React, { useMemo, useState, Fragment, useEffect } from 'react';
import {
    SpaceBetweenDiv,
    CheckboxInput,
    FlexDiv,
    TextTiny,
    ThemedButton,
    CopyToClipboardWrapper,
    palette,
    TextLead,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import CaretIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddToListModal from '../../../components/manager/assessment/add-to-list-modal';
import pluralize from 'pluralize';
import ErrorMessage from '../../../components/errormessage';
import RequireRole from '../../RequireRole';
import { STATUS } from '../../../utils/constants';
import TableServer from '../../../components/table/TableServer';
import { trackingDisabled } from '../../../utils/constants';
import SelectAll from './SelectAll';
import EmployeesCheckboxTableImpl from './EmployeesCheckboxTableImpl';

function EmployeesCheckboxTable({
    companyId,
    productId,
    store,
    product,
    isEmployeesList = false,
    canRemind,
    anonymousTracking,
}) {
    const { listStore, commonStore, userStore } = useStores();
    const [showAddToListModal, setShowAddToListModal] = useState(null);
    const [showNewListInput, setShowNewListInput] = useState(false);
    const [newListInput, setNewListInput] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const rowsPerPage = 10;

    const {
        loadingUsersList,
        listType,
        usersTotal,
        columns,
        hiddenColumns,
        usersList,
        pageSize,
        selection,
        isDownloading,
        error,
        detailsMenu,
    } = store;

    const { selectionCountOnAllPages } = selection;

    useEffect(() => {
        if (detailsMenu) setAnchorEl(detailsMenu.anchorEl);
        else setAnchorEl(null);
    }, [detailsMenu]);

    const handleCreateNewList = async () => {
        let result = await store.addUsersToList(
            companyId,
            productId,
            null,
            newListInput
        );
        if (result) {
            commonStore.success(
                `${result.added} ${pluralize(
                    'employee',
                    result.added
                )} added to "${newListInput}"`
            );
            hideAddToListModal();
            listStore.loadCompanyLists(companyId);
            store.selection.clearSelection();
        }
    };

    const handleAddEmployeesToList = async (listId, listName) => {
        let result = await store.addUsersToList(
            companyId,
            productId,
            listId,
            null
        );
        if (result) {
            if (result.added > 0) {
                commonStore.success(
                    `${result.added} ${pluralize(
                        'employee',
                        result.added
                    )} added to "${listName}"${
                        result.duplicates > 0
                            ? `. ${result.duplicates} ${pluralize(
                                  'employee',
                                  result.duplicates
                              )} already exist(s) in the list`
                            : ''
                    }`
                );
            }
            if (!result.added && result.duplicates > 0)
                commonStore.warn(
                    `${result.duplicates} ${pluralize(
                        'employee',
                        result.duplicates
                    )} already exist(s) in the list`
                );
            hideAddToListModal();
            store.selection.clearSelection();
        }
    };

    const hideAddToListModal = () => {
        setShowAddToListModal(null);
        setNewListInput('');
        setShowNewListInput(false);
    };

    const onChangePagingParams = (pageIndex, pageSize) => {
        store.setPagingParams(pageIndex, pageSize);
    };

    const onChangeSort = (sortBy) => {
        store.setSortBy(sortBy);
    };

    const handleDownloadCSV = () => {
        store.downloadCSV(companyId, productId);
    };

    const onReset = (employeeEmail) => {
        commonStore.showConfirm(
            'Progress/result of this employee will be cleared. Are you sure?',
            'Reset',
            'Reset assessment progress',
            () => {
                store.restartAssessment(employeeEmail);
            },
            false,
            true
        );
    };

    const onDeleteEmployeeFromScormAssessment = (sessionId) => {
        commonStore.showConfirm(
            'This employee will be deleted from the assessment. Are you sure?',
            'Delete',
            'Delete employee from assessment',
            () => {
                store.deleteEmployeeFromScormAssessment(companyId, sessionId);
            },
            false,
            true
        );
    };

    const hiddenColumnsWithoutAccessLink = useMemo(() => {
        let temp = [...hiddenColumns];
        const index = temp.indexOf('access_link');
        if (index > -1) {
            temp.splice(index, 1);
        }
        return temp;
    }, [hiddenColumns]);

    return (
        <FlexDiv column gap={8}>
            {error && <ErrorMessage error={error} />}
            <SpaceBetweenDiv>
                {(anonymousTracking && listType == 'bounced') ||
                !anonymousTracking ? (
                    <SelectAll
                        selection={store.selection}
                        usersTotal={usersTotal}
                        pageSize={pageSize}
                        loadingUsersList={loadingUsersList}
                    />
                ) : null}
                <FlexDiv gap={16}>
                    {listType !== 'bounced' && !anonymousTracking && (
                        <Fragment>
                            {product.canRemind && (
                                <ThemedButton
                                    mode="secondary"
                                    size="tiny"
                                    $noTextTransform
                                    disabled={selectionCountOnAllPages === 0}
                                    onClick={() => {
                                        store.showRemindersModal(true, 'user');
                                    }}
                                    text="Send reminders"
                                />
                            )}
                            <RequireRole>
                                {!product.isScorm && (
                                    <ThemedButton
                                        mode="secondary"
                                        size="tiny"
                                        $noTextTransform
                                        disabled={
                                            selectionCountOnAllPages === 0
                                        }
                                        onClick={(e) => {
                                            setShowAddToListModal(
                                                e.currentTarget
                                            );
                                        }}
                                        text={
                                            <FlexDiv alignCenter>
                                                Add to list
                                                <CaretIcon fontSize="small" />
                                            </FlexDiv>
                                        }
                                    />
                                )}
                            </RequireRole>
                        </Fragment>
                    )}
                    {(anonymousTracking && listType == 'bounced') ||
                    !anonymousTracking ? (
                        <ThemedButton
                            mode="secondary"
                            size="tiny"
                            $noTextTransform
                            disabled={selectionCountOnAllPages === 0}
                            onClick={handleDownloadCSV}
                            text={
                                <>
                                    {isDownloading && (
                                        <CircularProgress size={10} />
                                    )}{' '}
                                    Download .csv
                                    <GetAppIcon fontSize="small" />
                                </>
                            }
                        />
                    ) : null}
                </FlexDiv>
            </SpaceBetweenDiv>

            {(anonymousTracking && listType == 'bounced') ||
            !anonymousTracking ? (
                <EmployeesCheckboxTableImpl
                    columns={columns}
                    usersList={usersList}
                    usersTotal={usersTotal}
                    pageSize={pageSize}
                    loadingUsersList={loadingUsersList}
                    onChangePagingParams={onChangePagingParams}
                    onChangeSort={onChangeSort}
                    rowsPerPage={rowsPerPage}
                    hiddenColumns={
                        isEmployeesList
                            ? hiddenColumnsWithoutAccessLink
                            : hiddenColumns
                    }
                />
            ) : (
                <TextLead center>{trackingDisabled}</TextLead>
            )}

            <AddToListModal
                showAddToListModal={showAddToListModal}
                setShowAddToListModal={setShowAddToListModal}
                showNewListInput={showNewListInput}
                setShowNewListInput={setShowNewListInput}
                newListInput={newListInput}
                setNewListInput={setNewListInput}
                handleCreateNewList={handleCreateNewList}
                savingList={listStore.savingList}
                savingUsers={userStore.savingUsers}
                lists={listStore.lists}
                handleAddEmployeesToList={handleAddEmployeesToList}
            />
            {anchorEl && detailsMenu && !anonymousTracking && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={anchorEl != null}
                    onClose={() => {
                        store.handleMenuClose();
                    }}
                    elevation={1}
                >
                    <RequireRole>
                        {detailsMenu.employee.started &&
                            product.status == STATUS.ACTIVE && (
                                <>
                                    {product.isScorm ? (
                                        <MenuItem
                                            onClick={(e) => {
                                                const sessionId =
                                                    detailsMenu.employee.email;
                                                onDeleteEmployeeFromScormAssessment(
                                                    sessionId
                                                );
                                                store.handleMenuClose(e);
                                            }}
                                        >
                                            <TextTiny
                                                color={palette.vibrant.red}
                                                bold
                                            >
                                                Delete employee
                                            </TextTiny>
                                        </MenuItem>
                                    ) : (
                                        <MenuItem
                                            onClick={(e) => {
                                                onReset(
                                                    detailsMenu.employee.email
                                                );
                                                store.handleMenuClose(e);
                                            }}
                                        >
                                            <TextTiny
                                                color={palette.vibrant.red}
                                                bold
                                            >
                                                Reset assessment
                                            </TextTiny>
                                        </MenuItem>
                                    )}
                                </>
                            )}

                        {!(
                            detailsMenu.employee.completedDate ||
                            detailsMenu.employee.started ||
                            !canRemind
                        ) && (
                            <MenuItem>
                                <TextTiny
                                    themed
                                    bold
                                    link
                                    onClick={() =>
                                        store.showRemindersModal(
                                            false,
                                            'user',
                                            detailsMenu.employee.email,
                                            detailsMenu.employee.name ||
                                                detailsMenu.employee.email
                                        )
                                    }
                                >
                                    Send reminders
                                </TextTiny>
                            </MenuItem>
                        )}
                        {detailsMenu?.employee?.access_link && (
                            <MenuItem>
                                <CopyToClipboardWrapper
                                    copyText={detailsMenu.employee.access_link}
                                    onCopied={(e) => {
                                        commonStore.copied();
                                        store.handleMenuClose(e);
                                    }}
                                >
                                    Copy sign in link
                                </CopyToClipboardWrapper>
                            </MenuItem>
                        )}
                    </RequireRole>
                </Menu>
            )}
        </FlexDiv>
    );
}

export default observer(EmployeesCheckboxTable);
