import React, { Fragment, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import sanitizeHtml from 'sanitize-html';
import {
    getDeliveryFrequency,
    getReminderDelayType,
} from '../../utils/helpers';
import pluralize from 'pluralize';
import {
    BigModal,
    FlexDiv,
    SpaceBetweenDiv,
    TextDefault,
    LightTooltip,
} from '@awarego/awarego-components';
import WizardSummary from '../../components/manager/wizard/WizardSummary';
import CalendarWithDots from '../../components/manager/wizard/delivery-calendar/calendar-with-dots';
import ScheduledEvents from '../../components/manager/wizard/delivery-calendar/scheduled-events';
import moment from 'moment-timezone';
import { AUTOMATION_SCHEDULE_TYPE } from '../../utils/constants';
import { useCallback } from 'react';

function SummaryOutput({
    automation,
    introductoryForm,
    actionButtonText,
    adminTimeZone,
    isScorm,
}) {
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    const openScheduleModal = useCallback(() => {
        automation.moveCalendarViewToDate();
        setShowScheduleModal(true);
    }, [automation]);

    const deliverySummary = useMemo(() => {
        if (!automation) return;
        if (automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.NOW)
            return `All subjects will be sent immediately when you click on "${actionButtonText}" below`;
        if (automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.DOWNLOAD_LINKS)
            return `.csv file will be generated when you click on "${actionButtonText}" below`;
        return (
            <Fragment>
                {automation.subjectsPerInterval}{' '}
                {pluralize('subject', automation.subjectsPerInterval)} every{' '}
                {getDeliveryFrequency(automation.interval)} starting from{' '}
                {automation.date &&
                    moment(automation.date).format(
                        'ddd, LL (HH:mm [' + adminTimeZone + '])'
                    )}
                .{' '}
                <TextDefault underline link onClick={openScheduleModal}>
                    See full schedule
                </TextDefault>
            </Fragment>
        );
    }, [actionButtonText, adminTimeZone, automation, openScheduleModal]);

    const summaryOutput = useMemo(() => {
        if (!automation) return;
        return [
            {
                name: 'Name',
                detail: automation.name,
            },
            {
                name: 'Subjects',
                detail: (
                    <div>
                        {[...new Set(automation.videosSelected)].map(
                            (subject, j, arr) => (
                                <span key={j}>
                                    {subject.english_title}
                                    {j !== arr.length - 1 ? (
                                        j >= 4 && j % 4 === 0 ? (
                                            <Fragment>
                                                , <br />
                                            </Fragment>
                                        ) : (
                                            ', '
                                        )
                                    ) : (
                                        ''
                                    )}
                                </span>
                            )
                        )}
                    </div>
                ),
            },
            ...(isScorm
                ? []
                : [
                      {
                          name: 'Delivery',
                          detail: deliverySummary,
                      },
                      {
                          name: 'Reminders',
                          detail: automation.reminders_enabled ? (
                              <Fragment>
                                  Sending reminders{' '}
                                  <LightTooltip
                                      title={automation.reminders_steps.map(
                                          (x, i) => (
                                              <div key={i}>
                                                  {x.delay}{' '}
                                                  {getReminderDelayType(
                                                      x.delayType,
                                                      x.delay
                                                  )}{' '}
                                                  after delivery
                                              </div>
                                          )
                                      )}
                                  >
                                      <u>
                                          {automation.reminders_steps.length}{' '}
                                          {pluralize(
                                              'time',
                                              automation.reminders_steps.length
                                          )}
                                      </u>
                                  </LightTooltip>{' '}
                                  after{' '}
                                  {automation.scheduleType === 0 && 'each '}
                                  delivery
                              </Fragment>
                          ) : (
                              '—'
                          ),
                      },
                      {
                          name: `Employees (${automation.selectedUserCount})`,
                          detail: (
                              <div>
                                  {automation.userListsSelected &&
                                      automation.userListsSelected.length > 0 &&
                                      automation.userListsSelected[0] !==
                                          undefined &&
                                      automation.userListsSelected.map(
                                          (list, j, arr) => (
                                              <span key={j}>
                                                  {/* don't put comma after last list name */}
                                                  {list.name}
                                                  {j !== arr.length - 1 ? (
                                                      j >= 4 && j % 4 === 0 ? (
                                                          <Fragment>
                                                              , <br />
                                                          </Fragment>
                                                      ) : (
                                                          ', '
                                                      )
                                                  ) : (
                                                      ''
                                                  )}
                                              </span>
                                          )
                                      )}
                              </div>
                          ),
                      },
                      {
                          name: 'Intro message',
                          detail: !automation.introMessageEnabled ? (
                              '—'
                          ) : (
                              <div
                                  dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(
                                          introductoryForm.$('message').value
                                      ),
                                  }}
                              />
                          ),
                      },
                      {
                          name: 'Due date',
                          detail: !automation.dueDate ? (
                              '—'
                          ) : (
                              <div>
                                  <div>
                                      {moment(automation.dueDate).format(
                                          'ddd, LL (HH:mm [' +
                                              adminTimeZone +
                                              '])'
                                      )}{' '}
                                  </div>
                                  <div>
                                      Due date does not affect reminder or
                                      disable user access to training.
                                  </div>
                              </div>
                          ),
                      },
                  ]),
        ];
    }, [automation, deliverySummary, introductoryForm]);

    return (
        <Fragment>
            <WizardSummary summaryOutput={summaryOutput} />
            <BigModal
                isOpen={showScheduleModal}
                onRequestClose={() => setShowScheduleModal(false)}
                heading="Delivery schedule"
                modalContent={
                    <SpaceBetweenDiv $noWrap alignStart>
                        <FlexDiv flexGrow={1} borderRight justifyCenter>
                            <CalendarWithDots
                                selectedDate={automation.deliveryCalendarDate}
                                setSelectedDate={
                                    automation.setDeliveryCalendarDate
                                }
                                setSelectedMonth={
                                    automation.setDeliveryCalendarMonth
                                }
                                daysWithDot={
                                    automation.daysWithEventsInSelectedMonth
                                }
                            />
                        </FlexDiv>
                        <FlexDiv flexGrow={1} justifyCenter>
                            {automation.daysWithEventsInSelectedMonth.length >
                            0 ? (
                                <ScheduledEvents
                                    displayedEvents={
                                        automation.displayedCalendarEvents
                                    }
                                    readOnly
                                    selectedDate={
                                        automation.deliveryCalendarDate
                                    }
                                    totalEvents={
                                        automation.deliverySchedule.length
                                    }
                                    maxNameCharacterLength={32}
                                    hideScrollbar={false}
                                    maxHeight={304}
                                    adminTimeZone={adminTimeZone}
                                />
                            ) : (
                                <TextDefault lighter>
                                    You have nothing scheduled this month
                                </TextDefault>
                            )}
                        </FlexDiv>
                    </SpaceBetweenDiv>
                }
            />
        </Fragment>
    );
}

export default observer(SummaryOutput);
