import { generateLoadList } from '../utils/mobx';
import services from '../services';
import { makeAutoObservable } from 'mobx';
import SelectionController from './SelectionController';
import ClientSidePaginationWithSelect from './ClientSidePaginationWithSelect';

class AutomationEmployeeListsStore {
    employeeLists = [];
    loadingEmployeeLists = false;
    error = null;
    pageIndex = 0;
    pageSize = 10;
    isDownloading = false;

    constructor(automationStore) {
        makeAutoObservable(this);
        this.automationStore = automationStore;

        this.selection = new SelectionController(
            this.employeeLists,
            this,
            (x) => x.listId
        );

        this.clientSidePaginationWithSelect =
            new ClientSidePaginationWithSelect(
                this.employeeLists,
                this,
                (x) => x.listId,
                'listName',
                this.selection
            );
    }

    setPagingParams(pageIndex, pageSize) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    loadEmployeeLists = generateLoadList(
        'employeeLists',
        this,
        'loadingEmployeeLists',
        (companyId, automationId, filter) => {
            return services.Companies.automationsService(
                companyId
            ).employeeLists(automationId, filter);
        },
        'employeeLists'
    );

    async downloadCSV(companyId, automationId, filter) {
        if (this.isDownloading) return true;
        this.isDownloading = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response = await services.Companies.automationsService(
                companyId
            ).employeeLists(
                automationId,
                {
                    ...filter,
                    listIds:
                        this.clientSidePaginationWithSelect.selectedData.map(
                            (x) => x.listId
                        ),
                },
                true,
                options
            );

            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'lists.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } finally {
            this.isDownloading = false;
        }
    }
}
export default AutomationEmployeeListsStore;
