import RestService from './RestService';
import buildURL from 'axios/lib/helpers/buildURL';

export default class Users extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/users`);
        } else super('/users');
    }

    userDetails(email) {
        return this.get(`${this.baseUrl}/user/${encodeURIComponent(email)}`);
    }

    userStatus(email) {
        //This is basically get trainings status for the given email
        return this.get(`${this.baseUrl}/status/${encodeURIComponent(email)}`);
    }

    addUsersToList(params) {
        return this.post(buildURL(`${this.baseUrl}/addUsersToList`, params));
    }
    update(email, body) {
        const url = buildURL(`${this.baseUrl}/${email}`);
        return this.put(url, body);
    }
}
