import React from 'react';
import { Block, Heading2 } from '@awarego/awarego-components';
import NoItemsYetContent from './no-items-yet-content';

function NoItemsYet({
    heading,
    img,
    imgAlt,
    title,
    text,
    buttons,
    extraContent,
}) {
    return (
        <Block gap={32}>
            <Heading2>{heading}</Heading2>
            <NoItemsYetContent
                img={img}
                imgAlt={imgAlt}
                title={title}
                text={text}
                buttons={buttons}
                extraContent={extraContent}
            />
        </Block>
    );
}

export default NoItemsYet;
