import { observer } from 'mobx-react';
import { FlexDiv, TextLead } from '@awarego/awarego-components';
import React from 'react';

function RemoveSubscriptionConfirm({
    company,
    partnerEstimate,
    verb,
    subject,
    action,
    isFree,
    subscriptionName,
    isInactive,
}) {
    return isFree || isInactive ? (
        <TextLead>
            Are you sure you want to {verb} {subject} {company.company_name}?
        </TextLead>
    ) : (
        <FlexDiv column>
            <TextLead mb={16}>
                By {action} {subject} the {company.company_name}, your total
                cost across all companies will now be $
                {partnerEstimate.newPartnerTotal} per month.
            </TextLead>
            {partnerEstimate.newPartnerSubscriptionSeats > 0 && (
                <TextLead>
                    The new, increased price $
                    {(
                        partnerEstimate.newPartnerTotalForPlan /
                        partnerEstimate.newPartnerSubscriptionSeats
                    ).toFixed(2)}{' '}
                    per seat will be applied to all active companies with the{' '}
                    {subscriptionName} subscription.
                </TextLead>
            )}
        </FlexDiv>
    );
}

export default observer(RemoveSubscriptionConfirm);
