import React from 'react';
import { Alert } from 'reactstrap';

function variant2color(variant) {
    if (variant === 'error') return 'danger';
    return variant;
}

export default ({
    anchorOrigin = { vertical: 'top', horizontal: 'center' },
    autoHideDuration,
    variant = 'success',
    message,
    open,
    onClose,
}) => (
    <div
        className={`snackbar snackbar-${anchorOrigin.horizontal}-${anchorOrigin.vertical}`}
    >
        <Alert
            className={`snackbar-content`}
            color={variant2color(variant)}
            isOpen={open}
            toggle={onClose}
        >
            {message}
        </Alert>
    </div>
);
