import RestService from './RestService';

export default class Users extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/mails`);
        } else super('/mails');
    }

    loadMailDetails(storeUrl) {
        return this.post(`${this.baseUrl}/mailDetails`, { storeUrl });
    }
}
