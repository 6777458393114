import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { FlexDiv, TextLead } from '@awarego/awarego-components';
import moment from 'moment';

function EditSubscriptionConfirm({
    partnerEstimate,
    companyForm,
    validTo,
    seats,
    isInactive,
    subscription,
    newSubscriptionName,
}) {
    const validityChanged = !moment(validTo).isSame(
        companyForm.values().validTill,
        'day'
    );
    const seatsChanged = seats !== companyForm.values().seats;

    const subscriptionChanged =
        subscription !== companyForm.values().subscription;

    const updateSubscriptionText = subscriptionChanged
        ? `By changing your subscription to the ${newSubscriptionName} plan`
        : 'By updating this subscription';

    return (
        <FlexDiv column>
            {(seatsChanged || isInactive || subscriptionChanged) && (
                <Fragment>
                    <TextLead mb={16}>
                        You will be charged $
                        {partnerEstimate && partnerEstimate.newCompanyTotal} per
                        month for a total of{' '}
                        {companyForm && companyForm.values().seats} seats for{' '}
                        {companyForm && companyForm.values().companyName}.
                    </TextLead>
                    <TextLead>
                        {updateSubscriptionText}, your total cost including all
                        companies will be updated to $
                        {partnerEstimate &&
                            partnerEstimate.newPartnerTotal &&
                            partnerEstimate.newPartnerTotal}
                        .
                    </TextLead>
                </Fragment>
            )}
            {validityChanged && !isInactive && (
                <TextLead mt={(seatsChanged || subscriptionChanged) && 16}>
                    The subscription will be newly valid until{' '}
                    {moment(companyForm.values().validTill).format('LL')}.
                </TextLead>
            )}
        </FlexDiv>
    );
}

export default observer(EditSubscriptionConfirm);
