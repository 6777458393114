import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import services from '../../../services';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import { ThemedButton } from '@awarego/awarego-components';
import Typography from '@mui/material/Typography';

const LISTBOX_PADDING = 8; // px
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index]; // [0]props, [1]option, [2]state - index, inputValue, selected
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    return (
        <>
            <Typography
                component="li"
                {...dataSet[0]}
                noWrap
                style={inlineStyle}
            >
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={dataSet[2].selected}
                />
                {`${dataSet[1].email} - ${dataSet[1].name}`}
            </Typography>
        </>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default observer(
    ({ form, companyId, showHeader = false, onSelectExistingUsers }) => {
        const [open, setOpen] = React.useState(false);
        const [lastLoaded, setLastLoaded] = React.useState(null);
        const [options, setOptions] = React.useState([]);
        const [inputValue, setInputValue] = React.useState('');
        const [running, setRunning] = React.useState(false);
        const loading = open && running;

        React.useEffect(() => {
            if (inputValue === '') {
                setOptions([]);
                return undefined;
            }

            (async () => {
                setRunning(true);
                const response = await services.Companies.usersService(
                    companyId
                ).list({ filter: inputValue });

                const mappedResponse = response.users.map(({ email, name }) => {
                    return { email, name };
                });
                setOptions(mappedResponse);
                setLastLoaded(new Date());
                setRunning(false);
            })();
        }, [inputValue, companyId]);

        React.useEffect(() => {
            //
            if (
                !open &&
                (!lastLoaded || new Date() - lastLoaded > 5 * 60 * 1000)
            ) {
                setOptions([]);
            }
        }, [open]);

        const handleSelectUser = (e, value) => {
            onSelectExistingUsers(value);
        };

        //We filter by any string match, in either email or username
        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
                return option.name + option.email;
            },
        });

        return (
            <Fragment>
                {onSelectExistingUsers && (
                    <>
                        <div className="d-flex justify-content-end">
                            <Autocomplete
                                disableCloseOnSelect
                                onChange={handleSelectUser}
                                onInputChange={(
                                    event,
                                    newInputValue,
                                    reason
                                ) => {
                                    /* keep the input value (= the searched string) after user selects option from dropdown.
                                    // if not used, value is cleared on selection as well as options and 'No match' will appear in dropdown. 
                                    This would prevent user to select multiple options after search */
                                    if (reason != 'reset') {
                                        setInputValue(newInputValue);
                                    }
                                }}
                                open={open}
                                multiple
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                isOptionEqualToValue={(option, value) => {
                                    return (
                                        option &&
                                        value &&
                                        option.email === value.email
                                    );
                                }}
                                getOptionLabel={(option) => {
                                    //What we display after selection, the 'chip'
                                    return `${option.email}`;
                                }}
                                filterOptions={filterOptions}
                                ListboxComponent={ListboxComponent}
                                disableListWrap
                                options={options}
                                loading={loading}
                                noOptionsText="No match"
                                size="medium"
                                limitTags={1}
                                id="multiple-limit-tags"
                                style={{ width: '350px', marginRight: '24px' }}
                                renderOption={(props, option, state) => [
                                    props,
                                    option,
                                    state,
                                ]}
                                renderInput={(params) => (
                                    <TextField
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </React.Fragment>
                                            ),
                                        }}
                                        {...params}
                                        label="Search existing users"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <hr style={{ marginBottom: '10px' }} />
                    </>
                )}
                {showHeader && (
                    <div className={'d-flex flex-row'}>
                        <div className="form-holder">
                            <p>Email address</p>
                        </div>
                        <div className="form-holder">
                            <p>Name (optional)</p>
                        </div>
                    </div>
                )}
                {form.$('users').map((user) => {
                    return (
                        <div key={user.key}>
                            <div className="form-holder">
                                <div className={'d-flex flex-row'}>
                                    <div className="form-holder">
                                        <input
                                            type="email"
                                            name="recipientEmail"
                                            className={
                                                user.$('email').isValid
                                                    ? 'bigInput dark'
                                                    : 'bigInput '
                                            }
                                            {...user.$('email').bind()}
                                        />
                                        <div className="error">
                                            {user.$('email').error}
                                        </div>
                                    </div>
                                    <div className="form-holder">
                                        <input
                                            type="text"
                                            name="recipientName"
                                            className={
                                                user.$('name').isValid
                                                    ? 'bigInput dark'
                                                    : 'bigInput '
                                            }
                                            {...user.$('name').bind()}
                                        />
                                    </div>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={user.onDel}
                                        color="secondary"
                                        size="large"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div>
                    <ThemedButton
                        mode="secondary"
                        className="mt20 plus"
                        onClick={form.$('users').onAdd}
                        text="+"
                    />
                </div>
            </Fragment>
        );
    }
);
