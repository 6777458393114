import React from 'react';
import { observer } from 'mobx-react';
import { Image, VideoThumbnailWrapper } from '@awarego/awarego-components';
import LockOnCircle from '../../LockOnCircle';

function VideoThumbnail({ previewVideo, isTrial, item }) {
    return (
        <VideoThumbnailWrapper onClick={() => previewVideo(item.id, item.type)}>
            <Image
                height={96}
                minWidth={180}
                src={item.thumb}
                alt="video thumbnail"
                borderRadius={4}
                cover
            />
            {(item.disabled || (!item.availableForBeta && isTrial)) && (
                <LockOnCircle outline small topRight />
            )}
        </VideoThumbnailWrapper>
    );
}

export default observer(VideoThumbnail);
