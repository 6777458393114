import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HeaderSection from '../../../components/header/HeaderSection';
import moment from 'moment';
import {
    palette,
    FlexDiv,
    SpaceBetweenDiv,
    TextTiny,
} from '@awarego/awarego-components';
import UserTrainingStatus from './UserTrainingStatus';
import UserAssessmentStatus from './UserAssessmentStatus';
import UserActivityTable from './UserActivityTable';
import { useStores } from '../../../hooks/use-stores';
import { ThemedDropdownItem } from '../../../components/themed/ThemedComponents';
import { DropdownMenu } from 'reactstrap';
import Banner from '../../../components/Banner';
import NotFound from '../../../components/NotFound';
import SubHeaderDetailsList from '../../../components/header/SubHeaderDetailsList';
import SubHeaderTagsList from '../../../components/header/SubHeaderTagsList';
import EditUserModal from '../modals/EditUserModal';
import ErrorMessage from '../../../components/errormessage';
import { useNavigate } from 'react-router-dom';
import { trackingDisabled, USER_ROLE } from '../../../utils/constants';

function UserDetails({ email, anonymousTracking = false }) {
    const navigate = useNavigate();
    const { automationStore, assessmentStore, userStore, store, commonStore } =
        useStores();
    const { currentCompany, currentRole } = store;
    const { userDetails, loadingUserDetails } = userStore;
    const { userAutomations, usersLatestTraining, loadingUserAutomations } =
        automationStore;
    const { userAssessments, usersLatestAssessment, loadingUserAssessments } =
        assessmentStore;

    const [isModalOpen, setModalIsOpen] = useState(false);
    const [error, setError] = useState(null);

    const doLoadData = useCallback(async () => {
        if (email) {
            userStore.loadUserInfo(currentCompany.company_id, email);
            automationStore.loadUserAutomations(
                currentCompany.company_id,
                email
            );
            assessmentStore.loadUserAssessments(
                currentCompany.company_id,
                email
            );
        }
    }, [email, currentCompany]);

    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const createDetailsArray = () => {
        let details = [];
        details.push(userDetails.email);
        if (!anonymousTracking) {
            if (userDetails.lists) {
                userDetails.lists.map((list) => {
                    details.push(list.name);
                });
            }
            if (userDetails.lastActive) {
                details.push(
                    'Last active ' +
                        moment(userDetails.lastActive).format('ll HH:mm')
                );
            }
        }

        return details;
    };

    const onEditEmployee = (user) => {
        let listsId = user.lists.map((el) => {
            return el.id;
        });
        userDetails.listsId = listsId;
        setModalIsOpen(true);
    };

    const onDeleteEmployee = (user) => {
        let listsId = user.lists.map((l) => l.id);
        let listNames = user.lists.map((l) => l.name);
        const lastIndex = listNames.length - 1;
        const nameString =
            listNames.slice(0, lastIndex).join(', ') +
            ' and ' +
            listNames[lastIndex];

        let userToDelete = {
            companyId: currentCompany.company_id,
            listsId: listsId,
            email: user.email,
        };
        let description = `By doing this, user '${user.email}' will be removed from following lists: '${nameString}' 
        and from all training programs associated with this user.`;
        userStore.buildDeleteUserDialog(
            userToDelete,
            description,
            employeeDeletedCallback
        );
    };

    const employeeEditedCallback = (response) => {
        if (response.type == 'error') {
            setError(response.data ? response.data : 'Employee update failed');
        } else {
            if (response.data.name) {
                userDetails.name = response.data.name;
            }

            if (response.data.email) {
                userDetails.email = response.data.email;
            }
            commonStore.success('Employee updated successfully');
            setError(null);
            if (response.data.email) {
                navigate(`/users/user/${response.data.email}/`);
            }
        }

        setModalIsOpen(false);
    };

    const employeeDeletedCallback = () => {
        navigate('/users');
        commonStore.success('Employee deleted.');
    };

    const isAdmin = () => {
        if (currentRole == USER_ROLE.ADMIN) {
            return true;
        }

        return false;
    };

    const createContextMenu = () => {
        return (
            <DropdownMenu>
                <ThemedDropdownItem
                    onClick={() => {
                        onEditEmployee(userDetails);
                    }}
                >
                    Edit employee
                </ThemedDropdownItem>
                <ThemedDropdownItem
                    color={palette.vibrant.raspberry}
                    onClick={() => {
                        onDeleteEmployee(userDetails);
                    }}
                >
                    <TextTiny color={palette.vibrant.red} bold>
                        Delete employee
                    </TextTiny>
                </ThemedDropdownItem>
            </DropdownMenu>
        );
    };

    const createTrackingDisabledAlert = () => {
        return (
            <>
                <Banner
                    heading={trackingDisabled}
                    backgroundColor={palette.vibrant.redLight}
                    roundedCorners
                    iconPath={'/static/img/warning-on-circle-icon.svg'}
                    iconAltText={'Disabled'}
                />
            </>
        );
    };

    if (!loadingUserDetails && !userDetails) {
        return <NotFound />;
    }

    const subHeadings = [
        <SubHeaderDetailsList
            key="details"
            details={!loadingUserDetails && userDetails && createDetailsArray()}
        />,
        <SubHeaderTagsList
            key="tags"
            tags={
                !anonymousTracking
                    ? !loadingUserDetails &&
                      userDetails &&
                      userDetails.combinedTags
                    : null
            }
        />,
    ];

    return (
        <>
            {error && <ErrorMessage error={error} />}
            <HeaderSection
                heading={
                    !loadingUserDetails &&
                    userDetails &&
                    (userDetails.name != null && userDetails.name != ''
                        ? userDetails.name
                        : userDetails.email)
                }
                subHeadings={subHeadings}
                contextMenu={
                    !loadingUserDetails &&
                    userDetails &&
                    isAdmin() &&
                    createContextMenu()
                }
                alertSection={
                    !anonymousTracking ? null : createTrackingDisabledAlert()
                }
                loadingData={!userDetails && loadingUserDetails}
            />
            {!anonymousTracking ? (
                <>
                    <SpaceBetweenDiv mt={8} gap={8} alignStretch>
                        <FlexDiv flexGrow={2} gap={8}>
                            <UserTrainingStatus
                                training={usersLatestTraining}
                                loading={loadingUserAutomations}
                            />
                            <UserAssessmentStatus
                                assessment={usersLatestAssessment}
                                loading={loadingUserAssessments}
                            />
                        </FlexDiv>
                    </SpaceBetweenDiv>
                    <UserActivityTable
                        trainings={userAutomations}
                        loadingTrainings={loadingUserAutomations}
                        assessments={userAssessments}
                        loadingAssessments={loadingUserAssessments}
                    />
                </>
            ) : null}
            {userDetails ? (
                <EditUserModal
                    isModalOpen={isModalOpen}
                    employee={userDetails}
                    companyId={currentCompany.company_id}
                    modalStateSetter={setModalIsOpen}
                    employeeEditedSetter={employeeEditedCallback}
                ></EditUserModal>
            ) : null}
        </>
    );
}

export default observer(UserDetails);
