import React, { useEffect, useCallback } from 'react';
import { Block } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/use-stores';
import EmployeesListsHeader from './EmployeesListsHeader';
import EmployeesListsTable from './EmployeesListsTable';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';

function EmployeesListsContainer({
    companyId,
    assessmentId,
    threatArea = null,
}) {
    const { assessmentEmployeeListsStore } = useStores();

    useEffect(() => {
        loadEmployeesLists();
    }, []);

    const loadEmployeesLists = useCallback(async () => {
        await assessmentEmployeeListsStore.loadEmployeeLists(
            companyId,
            assessmentId,
            {
                threatArea,
            }
        );
    }, [assessmentEmployeeListsStore, assessmentId, companyId, threatArea]);

    const { clientSidePaginationWithSelect, loadingEmployeeLists } =
        assessmentEmployeeListsStore;

    const {
        filteredData: filteredEmployeeLists,
        pagedData: pagedEmployeeLists,
        dataTotal: listsTotal,
    } = clientSidePaginationWithSelect;

    return (
        <Block gap={24} tablePadding noBorder>
            {!loadingEmployeeLists ? (
                <>
                    <EmployeesListsHeader
                        listsTotal={listsTotal}
                        companyId={companyId}
                        assessmentId={assessmentId}
                        threatArea={threatArea}
                    />
                    <EmployeesListsTable
                        filteredEmployeeLists={filteredEmployeeLists}
                        employeesLists={pagedEmployeeLists}
                        loadingData={loadingEmployeeLists}
                    />
                </>
            ) : (
                <ThemedSpinner />
            )}
        </Block>
    );
}

export default observer(EmployeesListsContainer);
