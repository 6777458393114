import React from 'react';
import { useStores } from '../hooks/use-stores';
import { FlexDiv, palette } from '@awarego/awarego-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
    img {
        max-height: 40px;
        max-width: 130px;
    }
    position: relative;
    margin-right: 32px;
    :before {
        position: absolute;
        content: 'Admin';
        top: 28px;
        padding: 0px;
        border-radius: 2px;
        background: rgba(${palette.graphite.white}, 0.1);
        right: 0px;
        font-weight: 700;
        font-size: 12px;
        color: ${palette.graphite.heavyMetal};
        cursor: pointer;
    }
`;

function Logo() {
    const { brandingStore, authStore } = useStores();
    const { logo, account_name, brandingLoaded } = brandingStore;
    const { isAuthenticated } = authStore;
    return (
        <Wrapper>
            <FlexDiv column alignEnd>
                {(brandingLoaded && account_name !== 'AwareGo') ||
                isAuthenticated ? (
                    <Link to="/">
                        <img src={logo} alt={'My ' + account_name} />
                    </Link>
                ) : (
                    <a href="https://www.awarego.com">
                        <img src={logo} alt={account_name} />
                    </a>
                )}
            </FlexDiv>
        </Wrapper>
    );
}

export default Logo;
