import {generateDeleteEntity, generateLoadList} from '../utils/mobx';
import services from '../services';
import { makeAutoObservable } from 'mobx';
import { CheckboxInput } from '@awarego/awarego-components';
import keyBy from 'lodash/keyBy';

CheckboxInput.propTypes = {};

class GoogleGroupsStore {
    selectedGroups = [];
    loadingGroups = false;
    loadingGroupsList = null;
    groupsTotal = 0;
    initiallyLoaded = false;
    error = null;
    queryContext = {};
    skip = null;

    deletingUsersFromExcluded=[]

    constructor(automationStore, commonStore, companyStore) {
        makeAutoObservable(this);
        this.automationStore = automationStore;
        this.commonStore = commonStore;
        this.companyStore = companyStore;
    }

    setError(error, type) {
        switch (type) {
            default:
                this.error = error;
                break;
        }
    }

    async removeGroup(companyId, product, id) {
        const existing = this.selectedGroups.find((x) => x.id === id);
        if (existing) this.selectedGroups.remove(existing);

        let result = await this.companyStore.saveIntegrationRules(
            companyId,
            product,
            1,
            {
                included: this.selectedGroups,
            }
        );
        return result;
    }

    toggleGroup(group) {
        const existing = this.selectedGroups.find((x) => x.id === group.id);
        if (existing) this.selectedGroups.remove(existing);
        else
            this.selectedGroups.push({
                id: group.id,
                displayName: group.displayName,
            });
    }

    get included() {
        return keyBy(this.selectedGroups, 'id');
    }

    loadSelectedGroups = generateLoadList(
        'googleGroups',
        this,
        'loadingGroups',
        async (companyId) => {
            return await services.Companies.integrationsService(
                companyId
            ).integrationSelectedGroups('google');
        },
        'selectedGroups'
    );

    removeExcludedUser= generateDeleteEntity("removeExcludedUser",  this,"deletingUsersFromExcluded",
        async (companyId, product, id) => {
            return await services.Companies.integrationsService(companyId).removeExcluded(product, id);
        })


}

export default GoogleGroupsStore;
