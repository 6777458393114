import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { useStores } from '../hooks/use-stores';

import {
    SpaceBetweenDiv,
    FlexDiv,
    Heading1,
    TextLead,
    TextDefault,
    ThemedButton,
} from '@awarego/awarego-components';
import styled from 'styled-components';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const ImageAsLink = styled.img`
    cursor: pointer;
`;

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: 672,
        padding: 24,
    },
}));

function ConfirmationModal() {
    const { commonStore } = useStores();
    const classes = useStyles();

    const [additionalCheckboxChecked, setAdditionalCheckboxChecked] =
        useState(false);

    const {
        confirmationRequired,
        confirmationDescription,
        confirmationAction,
        options,
        confirmationTitle,
        confirmationShowCancel,
        additionalCheckbox,
        isConfirmationDelete,
    } = commonStore;

    const { confirmationCancelAction = 'Cancel' } = options;

    const handleClose = () => {
        commonStore.cancelConfirm();
    };

    const onConfirm = async (arg) => {
        if (!commonStore.additionalCheckbox || additionalCheckboxChecked)
            return commonStore.doConfirm(arg);
    };

    const renderAction = (confirmationAction) => {
        if (Array.isArray(confirmationAction)) {
            return confirmationAction.map((action) => {
                return (
                    <ThemedButton
                        mode={isConfirmationDelete ? 'red' : 'primary'}
                        key={action.key}
                        onClick={() => onConfirm(action.key)}
                        text={action.value}
                    />
                );
            });
        } else {
            return (
                <ThemedButton
                    mode={isConfirmationDelete ? 'red' : 'primary'}
                    onClick={onConfirm}
                    text={confirmationAction}
                />
            );
        }
    };

    const toggleAdditionalCheckboxChecked = () => {
        setAdditionalCheckboxChecked(!additionalCheckboxChecked);
    };

    return confirmationRequired ? (
        <Dialog
            open={true}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                <SpaceBetweenDiv>
                    <Heading1>{confirmationTitle}</Heading1>
                    <ImageAsLink
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={handleClose}
                    />
                </SpaceBetweenDiv>
            </DialogTitle>
            <DialogContent>
                <TextLead>{confirmationDescription}</TextLead>
                {additionalCheckbox && (
                    <label htmlFor={'additionalCheckbox'}>
                        <input
                            type="checkbox"
                            className="checkmark"
                            autoComplete={false}
                            id={'additionalCheckbox'}
                            checked={additionalCheckboxChecked}
                            onChange={toggleAdditionalCheckboxChecked}
                        />
                        {additionalCheckbox}
                        <span className="checkmark"></span>
                    </label>
                )}
            </DialogContent>
            <DialogActions>
                {confirmationShowCancel && (
                    <FlexDiv pr={40}>
                        <TextDefault
                            uppercase
                            extraBold
                            link
                            onClick={handleClose}
                        >
                            {confirmationCancelAction}
                        </TextDefault>
                    </FlexDiv>
                )}
                {renderAction(confirmationAction)}{' '}
            </DialogActions>
        </Dialog>
    ) : null;
}

export default observer(ConfirmationModal);
