import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import PackageForm from './PackageForm';
import CompanyForm from './CompanyForm';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../errormessage';

function CompanyAndPackageEditor({
    companyForm,
    partnerEstimate,
    estimationLoading,
}) {
    const { subscriptionStore } = useStores();

    const { subscriptionPlans, loadingSubscriptionsPlans, estimationError } =
        subscriptionStore;

    return (
        <Fragment>
            <ErrorMessage error={estimationError} />
            <CompanyForm companyForm={companyForm} />
            <PackageForm
                companyForm={companyForm}
                subscriptionPlans={subscriptionPlans}
                loadingSubscriptionsPlans={loadingSubscriptionsPlans}
                partnerEstimate={partnerEstimate}
                estimationLoading={estimationLoading}
            />
        </Fragment>
    );
}

export default observer(CompanyAndPackageEditor);
