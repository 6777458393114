import RestService from './RestService';

export default class PPVService extends RestService {
    constructor(parentService, companyId) {
        if (parentService && companyId)
            super(`${parentService.baseUrl}/${companyId}/ppv`);
        else super('/ppv');
    }

    status() {
        return this.get(`${this.baseUrl}/status`);
    }

    toggle(subjectId, enabled) {
        return this.post(`${this.baseUrl}/${subjectId}/toggle`, { enabled });
    }
}
