import { createTheme } from '@mui/material/styles';
import { palette } from '@awarego/awarego-components';

export const AwareGOTheme = createTheme({
    palette: {
        primary: { main: palette.evergreen.aspargus },
        secondary: { main: palette.vibrant.raspberry },
        aspargus: { main: palette.evergreen.aspargus },
        heavymetal: { main: '#343534' },
    },
    typography: {
        fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    padding: '6px 24px',
                    '&:first-of-type': {
                        marginTop: '4px',
                    },
                    '&:last-child': {
                        marginBottom: '4px',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    minWidth: '100px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontWeight: '600',
                    letterSpacing: '0.04em',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    marginTop: '4px',
                    '& .MuiOutlinedInput-input': {
                        margin: 0,
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        lineHeight: '18px',
                        paddingTop: '10px',
                        paddingBottom: '8px',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#c4c4c4',
                    '&.Mui-error': {
                        color: '#BE2024',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#c4c4c4',
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                daySelected: {
                    backgroundColor: `${palette.evergreen.aspargus} !important`,
                    color: palette.graphite.white,
                },
                current: {
                    backgroundColor: `rgba(32, 162, 107, 0.1) !important`,
                    color: palette.evergreen.aspargus,
                },
            },
        },
    },
});
