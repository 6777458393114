import React, { useMemo } from 'react';
import {
    SpaceBetweenDiv,
    Block,
    FlexDiv,
    ThemedButton,
} from '@awarego/awarego-components';
import ContentFilter from './content-filter';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';

function ContentFilters() {
    const { brandingStore, resourcesStore } = useStores();

    const {
        typeFilter,
        threatAreaFilter,
        languageFilter,
        availableThreatAreas,
        availableLanguages,
    } = resourcesStore;

    const { materialsVisible } = brandingStore;

    const types = useMemo(() => {
        return [
            { id: 'training', name: 'Training subjects' },
            { id: 'assessment', name: 'Risk assessment scenarios' },
            ...(materialsVisible
                ? [{ id: 'material', name: 'Reinforcement material' }]
                : []),
        ];
    }, [materialsVisible]);

    const areas = availableThreatAreas;

    const languages = availableLanguages;

    const clearAllFilters = () => {
        resourcesStore.setTypeFilter([]);
        resourcesStore.setThreatAreaFilter([]);
        resourcesStore.setLanguageFilter([]);
    };

    return (
        <Block>
            <SpaceBetweenDiv gap={16}>
                <FlexDiv flexGrow={1}>
                    <FlexDiv wrap gap={16} fullWidth justifyEnd>
                        <ContentFilter
                            filterName={'Type'}
                            filterOptions={types}
                            filterValue={typeFilter.slice(0)}
                            setFilter={(value) => {
                                resourcesStore.setTypeFilter(value);
                            }}
                        />
                        <ContentFilter
                            filterName={'Category'}
                            filterOptions={areas}
                            filterValue={threatAreaFilter.slice(0)}
                            setFilter={(value) => {
                                resourcesStore.setThreatAreaFilter(value);
                            }}
                        />
                        <ContentFilter
                            filterName={'Available in language'}
                            filterOptions={languages}
                            filterValue={languageFilter.slice(0)}
                            setFilter={(value) => {
                                resourcesStore.setLanguageFilter(value);
                            }}
                        />
                        <FlexDiv flexGrow={'0 0 120px'} mb={6} alignCenter>
                            <ThemedButton
                                height={44}
                                $noTextTransform
                                mode="secondary"
                                onClick={() => clearAllFilters()}
                                text="Clear"
                            />
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
            </SpaceBetweenDiv>
        </Block>
    );
}

export default observer(ContentFilters);
