import React, { useEffect } from 'react';
import {
    FlexDiv,
    WaitingAnimation as Animation,
} from '@awarego/awarego-components';

function WaitingAnimation({ waitingActive = false, from }) {
    useEffect(() => {}, [from]);

    if (!waitingActive) {
        return null;
    } else {
        return (
            <div className="loading-fullscreen">
                <FlexDiv maxWidth={224} maxHeight={224}>
                    <Animation />
                </FlexDiv>
            </div>
        );
    }
}

export default WaitingAnimation;
