import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Helmet } from 'react-helmet';
import { useStores } from '../../hooks/use-stores';
import BlockWithRightAlignedImage from './block-with-right-aligned-image';
import ContentFilters from './content-filters';
import ContentResultSection from './content-result-section';
import SubjectPreview from '../subjectPreview';
import PreviewQuestion from '../../components/manager/assessment/preview-question';
import Modal from 'react-modal';
import MaterialModal from './material-modal';
import { useTranslation } from 'react-i18next';
import { ThemedButton } from '@awarego/awarego-components';
import ScormContentModal from './ScormContentModal';

function Content() {
    const { brandingStore, resourcesStore, store, catalogStore, cmi5Store } =
        useStores();
    const { currentCompany } = store;
    const {
        loadingContent,
        trainingContent,
        assessmentContent,
        materialContent,
    } = resourcesStore;
    const { materialsVisible } = brandingStore;
    const { i18n } = useTranslation('assessment');
    const navigate = useNavigate();
    /** How many items are shown initially, before 'Show all' is selected */
    const initialItemCount = 8;

    const [subjectModalIsOpen, setSubjectModalIsOpen] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [showingAllSubjects, showAllSubjects] = useState(false);

    const [questionModalIsOpen, setQuestionModalIsOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showingAllQuestions, showAllQuestions] = useState(false);

    const [materialModalIsOpen, setMaterialModalIsOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [showingAllMaterial, showAllMaterial] = useState(false);

    const [isAddContentModalOpen, setAddContentModalOpen] = useState(false);

    const redirectToUpgrade = () => {
        navigate('subscriptions/upgrade');
    };

    useEffect(() => {
        doLoadData();
    }, []);

    const doLoadData = async () => {
        await resourcesStore.loadAllContent(currentCompany.company_id);
        await catalogStore.loadCatalog(currentCompany.company_id);
        await catalogStore.loadThreatAreaCatalog(currentCompany.company_id);
    };

    const doShowAllSubjects = () => {
        showAllSubjects(true);
    };
    const doShowAllQuestions = () => {
        showAllQuestions(true);
    };
    const doShowAllMaterial = () => {
        showAllMaterial(true);
    };

    /** Get the items that should be displayed, either all or only the first X*/
    const getDisplayItems = (showingFlag, items) => {
        return showingFlag ? items : items.slice(0, initialItemCount);
    };

    /** When user closes Question preview (after maybe viewing the question in non-english, we reset the system language to company's default language */
    const resetSelectedLanguage = () => {
        i18n.changeLanguage(currentCompany.defaultLanguage || 'en');
    };

    const openSubjectModal = (subjectId, subject) => {
        // if subject is uploaded scorm, Custom Content means only scorm?
        if (subject.tags.includes('Custom Content')) {
            navigate(`/content/${subjectId}`);
        } else {
            setSubjectModalIsOpen(true);
            setSelectedSubject({ id: subjectId, item: subject });
        }
    };

    const closeSubjectModal = () => {
        setSubjectModalIsOpen(false);
    };

    const openQuestionModal = (questionId) => {
        setQuestionModalIsOpen(true);
        setSelectedQuestion(questionId);
    };

    const closeQuestionModal = () => {
        setQuestionModalIsOpen(false);
        resetSelectedLanguage();
    };

    const openMaterialModal = (materialId, material) => {
        setMaterialModalIsOpen(true);
        setSelectedMaterial(material);
    };

    const closeMaterialModal = () => {
        setMaterialModalIsOpen(false);
    };

    const createAddContentButton = () => (
        <ThemedButton
            text={'Import Scorm'}
            mode={'secondary'}
            onClick={() => {
                setAddContentModalOpen(true);
            }}
            $noWrap
            $noTextTransform
        />
    );

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Content</title>
            </Helmet>
            <BlockWithRightAlignedImage
                heading={
                    'Subjects, scenarios' +
                    (materialsVisible ? 'and material' : '')
                }
                text={
                    `Here you can browse all current training subjects and assessment scenarios in all available languages.` +
                    (materialsVisible
                        ? `You can also find shareable and printable materials to support your security awareness programs. These include posters, stickers for social media, e-books and whitepapers to help reinforce good cybersecurity habits at your workplace.`
                        : '')
                }
                img={'/static/img/resources-top-img-LowRes-2.jpg'}
                imgAlt={'our content'}
            />

            <ContentFilters />

            {loadingContent ? (
                <ThemedSpinner />
            ) : (
                <>
                    <ContentResultSection
                        heading={'Training subjects'}
                        items={getDisplayItems(
                            showingAllSubjects,
                            trainingContent
                        )}
                        totalItemCount={trainingContent.length}
                        fullWidthThumb
                        handleClick={openSubjectModal}
                        handleShowAll={doShowAllSubjects}
                        buttons={
                            store.isAdmin ? createAddContentButton() : null
                        }
                    />
                    <ContentResultSection
                        heading={'Risk assessment scenarios'}
                        items={getDisplayItems(
                            showingAllQuestions,
                            assessmentContent
                        )}
                        totalItemCount={assessmentContent.length}
                        fullWidthThumb
                        handleClick={openQuestionModal}
                        handleShowAll={doShowAllQuestions}
                    />
                    {materialsVisible && (
                        <ContentResultSection
                            heading={'Reinforcement material'}
                            items={getDisplayItems(
                                showingAllMaterial,
                                materialContent
                            )}
                            totalItemCount={materialContent.length}
                            fullWidthThumb
                            handleClick={openMaterialModal}
                            handleShowAll={doShowAllMaterial}
                        />
                    )}
                </>
            )}

            {/* Subject modal*/}
            {selectedSubject && (
                <SubjectPreview
                    subjectId={selectedSubject.id}
                    isOpen={subjectModalIsOpen}
                    onClose={closeSubjectModal}
                    hideFooter={true}
                    showLanguageSelector={true}
                    availableLanguages={selectedSubject.item.languages || []}
                    defaultLanguage={currentCompany.defaultLanguage || 'en'}
                    redirectToUpgrade={redirectToUpgrade}
                />
            )}

            {/* Questions modal*/}
            <Modal
                isOpen={questionModalIsOpen}
                onRequestClose={closeQuestionModal}
                className="preview-questions-modal"
            >
                <PreviewQuestion
                    questionId={selectedQuestion}
                    onRequestClose={closeQuestionModal}
                    hasMoreQuestions={false}
                    companyId={currentCompany.company_id}
                    questionsSelected={[selectedQuestion]}
                    isWizard
                    hideFooter={true}
                    showLanguageSelector={true}
                    defaultLanguage={currentCompany.defaultLanguage || 'en'}
                />
            </Modal>

            {/* Material modal*/}
            <Modal
                isOpen={materialModalIsOpen}
                onRequestClose={closeMaterialModal}
                className="preview-questions-modal"
            >
                <MaterialModal
                    material={selectedMaterial}
                    onRequestClose={closeMaterialModal}
                />
            </Modal>

            <ScormContentModal
                isModalOpen={isAddContentModalOpen}
                onRequestCloseModal={() => {
                    cmi5Store.error = null;
                    setAddContentModalOpen(false);
                }}
                companyId={currentCompany.company_id}
            />
        </Container>
    );
}

export default observer(Content);
