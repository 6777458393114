import Form from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import * as validatorjs from 'validatorjs';
import { USER_ROLE } from '../utils/constants';
import { userRoleName } from '../utils/helpers';

const plugins = { dvr: validatorjs };

const fields = {
    name: {
        name: 'name',
        label: 'Name',
        rules: 'required|string',
        placeholder: 'Enter name of admin',
    },
    email: {
        name: 'email',
        label: 'Work Email Address',
        rules: 'required|email|string',
        placeholder: 'name@company.com',
        type: 'email',
    },
    role: {
        name: 'role',
        label: 'User Role',
        rules: 'required|numeric',
        extra: Object.values(USER_ROLE).map((x) => ({
            label: userRoleName(x),
            value: x,
        })),
    },
    sendWelcome: {
        name: 'sendWelcome',
        label: 'Send a welcome email',
        rules: 'boolean',
        type: 'checkbox',
        value: false,
    },
};

const options = {
    showErrorsOnReset: false,
};

class AddManager extends Form {
    constructor(setup, hooks) {
        super({ fields, ...setup }, { plugins, hooks, options });
    }
}

export default AddManager;
