import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { DropdownMenu } from 'reactstrap';
import { ThemedDropdownItem } from '../../../components/themed/ThemedComponents';
import pluralize from 'pluralize';
import EmployeesLimitReachedAction from '../../../components/manager/employees-limit-reached-action';
import { useStores } from '../../../hooks/use-stores';
import HeaderSection from '../../../components/header/HeaderSection';
import SubHeaderDetailsList from '../../../components/header/SubHeaderDetailsList';
import { useNavigate } from 'react-router-dom';
import { palette, ThemedButton } from '@awarego/awarego-components';
import { runInAction } from 'mobx';

function SingleListHeader({
    currentCompany,
    createDisabled,
    openAddEmployeesModal,
    listId,
    openEditListModal,
}) {
    const { listStore, userStore, commonStore, store } = useStores();
    const navigate = useNavigate();
    const companyId = currentCompany.company_id;
    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        if (searchValue != null || typeof searchValue == 'undefined') {
            // called on typing in search bar
            runInAction(() => {
                userStore.usersSearchedValue = searchValue;
            });
        }
    }, [companyId, searchValue]);

    const createContextMenu = useMemo(() => {
        return (
            <DropdownMenu>
                <ThemedDropdownItem
                    onClick={() => {
                        listStore.editingList = listStore.list;
                        openEditListModal(true);
                    }}
                >
                    Rename list
                </ThemedDropdownItem>
                <ThemedDropdownItem
                    onClick={() => onDeleteList()}
                    style={{ color: palette.vibrant.red }}
                >
                    Delete list
                </ThemedDropdownItem>
            </DropdownMenu>
        );
    }, []);

    const createButtons = useMemo(() => {
        return (
            <>
                <ThemedButton
                    disabled={createDisabled}
                    onClick={() => {
                        openAddEmployeesModal(true);
                    }}
                    mode="secondary"
                    $airy
                    text="Add employees"
                ></ThemedButton>
                <EmployeesLimitReachedAction
                    disabled={createDisabled}
                    currentCompany={currentCompany}
                />
            </>
        );
    }, [createDisabled, currentCompany]);

    const onDeleteList = () => {
        commonStore.showConfirm(
            'Are you sure you want to remove this list?  The list will also be removed from assigned training programs and assessments',
            'Delete',
            'Delete list',
            async () => {
                const success = await listStore.delete(
                    currentCompany.company_id,
                    listId
                );
                if (success) {
                    navigate('/users');
                }
            },
            true
        );
    };

    return (
        <HeaderSection
            heading={listStore.list && listStore.list.name}
            searchPlaceholder={'Search Employees'}
            searchValue={searchValue}
            onSearch={(e) => setSearchValue(e.target.value)}
            onClearSearch={() => setSearchValue('')}
            subHeadings={
                <SubHeaderDetailsList
                    details={[
                        userStore.usersFiltered +
                            ' ' +
                            pluralize('employee', userStore.usersFiltered),
                    ]}
                />
            }
            buttons={store.isAdmin && createButtons}
            contextMenu={store.isAdmin && createContextMenu}
            excludeBlock
            marginLeft="16"
            loadingData={listStore.loadingList}
        />
    );
}

export default observer(SingleListHeader);
