import React from 'react';
import {
    FlexDiv,
    Heading4,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';

function GenericInsight({ heading, text, actionName, insight, onAction }) {
    return (
        <>
            <FlexDiv $noWrap gap={16}>
                <img src="/static/img/info-magnifier.svg" />
                <FlexDiv column>
                    <Heading4 bold>{heading}</Heading4>
                    <TextTiny lighter>{text}</TextTiny>
                </FlexDiv>
            </FlexDiv>
            <ThemedButton
                mode="secondary"
                size="small"
                text={actionName}
                $noTextTransform
                onClick={() => onAction(insight)}
            />
        </>
    );
}

export default GenericInsight;
