import RestService from './RestService';

export default class Managers extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/managers`);
        } else super('/managers');
    }

    setBillingManager(data) {
        return this.post(`${this.baseUrl}/set_billing_manager`, data);
    }
}
