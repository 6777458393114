import RestService from './RestService';
import NotesService from './NotesService';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/subjects`);
        } else super('/subjects');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetLanguages(id) {
        return this.get(`${this.baseUrl}/all/${id}/languages`);
    }

    updateSubjectLanguageContent(id, lang, content) {
        return this.put(`${this.baseUrl}/all/${id}/languages/content/${lang}`, {
            content: content,
        });
    }

    updateSubjectLanguageTranslation(id, lang, title, description) {
        return this.put(
            `${this.baseUrl}/all/${id}/languages/translation/${lang}`,
            {
                title: title,
                description: description,
            }
        );
    }

    updateSubjectLanguageQuestion(
        id,
        lang,
        question,
        correct,
        wrong1,
        wrong2,
        wrong3
    ) {
        return this.put(
            `${this.baseUrl}/all/${id}/languages/question/${lang}`,
            {
                question: question,
                correct: correct,
                wrong1: wrong1,
                wrong2: wrong2,
                wrong3: wrong3,
            }
        );
    }

    notesService(subjectId) {
        return new NotesService(this, subjectId);
    }

    getVideoPlayerUrl() {
        return this.post(`${this.baseUrl}/videoPlayerUrl`);
    }

    getVideoToken(videoId, lang) {
        return this.post(`${this.baseUrl}/videoToken`, { videoId, lang });
    }

    getScormInfo(subjectId, language) {
        return this.post(`${this.baseUrl}/scormInfo`, {
            subjectId,
            lang: language,
            preview: true,
        });
    }

    getLatestSubjects() {
        return this.get(`${this.baseUrl}/latestSubjects`);
    }

    getSubject(subjectId) {
        return this.get(`${this.baseUrl}/${subjectId}`);
    }

    getAllScormTranslations(subjectId) {
        return this.get(`${this.baseUrl}/scorm/${subjectId}`);
    }
}
