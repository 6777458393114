import React from 'react';
import {
    FlexDiv,
    Heading4,
    SpaceBetweenDiv,
    TextDefault,
    TextLabel,
    ThemedButton,
    LightTooltip,
} from '@awarego/awarego-components';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react';

function EventDescription({
    e,
    totalEvents,
    handleEditDateClick,
    index,
    readOnly,
    currentEventHovered,
    maxNameCharacterLength = 16,
    adminTimeZone,
}) {
    if (e.name !== 'No event scheduled')
        return (
            <SpaceBetweenDiv pl={8} gap={8} $noWrap>
                <FlexDiv column $noWrap>
                    <Heading4 bold mb={4} displayBlock noWrap>
                        {e.name.length > maxNameCharacterLength ? (
                            <LightTooltip title={e.name}>
                                <span>
                                    {e.name.substring(
                                        0,
                                        maxNameCharacterLength
                                    )}
                                    ...
                                </span>
                            </LightTooltip>
                        ) : (
                            e.name
                        )}
                    </Heading4>
                    <TextLabel lighter noWrap>
                        {moment(e.date).format(`HH:mm [${adminTimeZone}]`)}{' '}
                        Delivery ({e.tempId + 1}/{totalEvents})
                    </TextLabel>
                </FlexDiv>
                {!readOnly && (
                    <ThemedButton
                        mode="secondary"
                        size="small"
                        $noTextTransform
                        $noWrap
                        hidden={!isEqual(currentEventHovered, e)}
                        onClick={() => {
                            handleEditDateClick(e, index);
                        }}
                        text="Edit date"
                    />
                )}
            </SpaceBetweenDiv>
        );

    return (
        <TextDefault ml={8} lighter>
            {e.name}
        </TextDefault>
    );
}

export default observer(EventDescription);
