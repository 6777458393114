import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Heading1, Heading3 } from '@awarego/awarego-components';
import {useStores} from "../../hooks/use-stores";

function PaymentConfirm ({onComplete}) {

       const {subscriptionStore} =  useStores()
        const { price, billingInfo } =subscriptionStore;

        return (
            <div className={'wizard-fullscreen'}>
                <div className={'wizard-fullscreen-header'}>
                    <Heading1>Payment successful</Heading1>
                </div>
                <div className="wizard-fullscreen-body align-items-center justify-content-center flex-column">
                    <img src="/static/img/receipt.svg" alt="Email sent" />
                    <Heading3 displayBlock mt={16} mb={8}>
                        Thank you!
                    </Heading3>
                    <p>
                        Your account was charged{' '}
                        <strong className="green">${price}</strong>. A
                        confirmation and receipt was also sent to{' '}
                        <strong className="green">{billingInfo.email}</strong>
                    </p>
                </div>

                <div className={'wizard-fullscreen-footer'}>
                    <div
                        className={'actions center d-flex justify-content-end'}
                    >
                        <Button
                            color={'primary'}
                            onClick={onComplete}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        );

}

export default observer(PaymentConfirm);
