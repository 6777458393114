import React from 'react';
import { observer } from 'mobx-react';
import VideoPreview from '../components/videoPreview';
import { useStores } from '../hooks/use-stores';

function VideoPreviewContainer({ previewVideoLocation, subject, language }) {
    const { store } = useStores();
    const { videoPlayerUrl, tokenIndex } = store;
    let token = tokenIndex[previewVideoLocation];

    return (
        <VideoPreview
            token={token && token.token}
            videoPlayerUrl={videoPlayerUrl}
            subject={subject}
            isOpen
            language={language}
        />
    );
}

export default observer(VideoPreviewContainer);
