import React, { Fragment } from 'react';
import EmployeesListsTable from '../../../users/assessment-users/EmployeesListsTable';
import EmployeesList from '../../../users/EmployeesList';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import EmployeesListsContainer from '../../../users/assessment-users/EmployeesListsContainer';

function Employees({ companyId, assessmentId, showLists = true }) {
    const { assessmentEmployeesStoresFactory, assessmentStore } = useStores();

    const { employeesOverviewStore: assessmentEmployeesStore } =
        assessmentEmployeesStoresFactory;
    return (
        <Fragment>
            {showLists && (
                <EmployeesListsContainer
                    companyId={companyId}
                    assessmentId={assessmentId}
                />
            )}
            <EmployeesList
                companyId={companyId}
                assessmentId={assessmentId}
                assessmentEmployeesStore={assessmentEmployeesStore}
                product={assessmentStore.editingAssessment}
                canRemind={assessmentStore.editingAssessment.canRemind}
            />
        </Fragment>
    );
}

export default observer(Employees);
