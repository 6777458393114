import React from 'react';
import { ThemedRadiobutton } from './themed/ThemedComponents';
import { TextLead } from '@awarego/awarego-components';
import { observer } from 'mobx-react';

export default observer(({ id, label, value, groupValue, onClick }) => (
    <label
        htmlFor={id}
        className={
            groupValue === value
                ? 'container-radiobutton checked'
                : 'container-radiobutton'
        }
        onClick={() => {
            onClick(value);
        }}
    >
        <TextLead bold>{label}</TextLead>
        <input
            type="radio"
            name="active"
            id={id}
            className="styled-radiobutton"
            value={value}
            checked={groupValue === 3}
            onChange={() => {
                onClick(value);
            }}
        />
        <ThemedRadiobutton {...{ checked: groupValue === value }} />
    </label>
));
