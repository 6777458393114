import React from 'react';
import { calculateScoreTitle } from '../../../../utils/helpers';
import GenericInsight from './GenericInsight';

function LowestPerformingQuestionInsight({ insight, onAction }) {
    return (
        <GenericInsight
            heading={`Lowest performing question is "${insight.context.name}"`}
            text={`Average employee score on this question is ${
                insight.context.averageScore
            }, which is considered ${calculateScoreTitle(
                insight.context.averageScore
            )}`}
            actionName="View answers"
            insight={insight}
            onAction={onAction}
        />
    );
}

export default LowestPerformingQuestionInsight;
