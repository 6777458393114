import React, { useState, useRef, Fragment } from 'react';
import { observer } from 'mobx-react';
import makeStyles from '@mui/styles/makeStyles';
import {
    Block,
    FlexDiv,
    palette,
    TextDefault,
    TextTiny,
    ThemedButton,
    TextWithTooltipIcon,
} from '@awarego/awarego-components';
import {
    MenuItem,
    FormControl,
    Checkbox,
    TextField,
    FormControlLabel,
} from '@mui/material';
import ErrorMessage from '../../components/errormessage';
import { useStores } from '../../hooks/use-stores';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
        },
    },
}));

function ScormContentForm({
    scorm,
    availableLanguages,
    acceptedFormats,
    onUploadScorm,
    showReupload,
    onReuploadScorm,
    isAddLanguageContent = false,
    isEditLanguageContent = false,
}) {
    const classes = useStyles();
    const { cmi5Store } = useStores();

    const fileRef = React.createRef();
    const filenameRef = useRef(null);
    const languageRef = useRef(null);
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const entryPointRef = useRef(null);
    const xapiRef = useRef(null);

    const [error, setError] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [checked, setChecked] = useState(scorm.xapi == 1 ? true : false);

    const isLanguageForm = isAddLanguageContent || isEditLanguageContent;
    const editedLanguage = isEditLanguageContent ? scorm.language : undefined;

    const handleXapiChange = (event) => {
        setChecked(event.target.checked);
    };

    const onSave = (event) => {
        event.preventDefault();
        if (cmi5Store.error) {
            cmi5Store.error = null;
        }
        const scormData = new FormData();

        // Append individual properties from the form object
        if (!isLanguageForm) {
            if (!thumbnail) {
                cmi5Store.error = 'Image file is required';
                return;
            } else {
                scormData.append('thumbnail', thumbnail.file);
            }
        }
        if (!isEditLanguageContent) {
            scormData.append('filename', filenameRef.current.value);
            scormData.append('package', scorm.zipFile);
        }

        scormData.append('language', languageRef.current.value);
        scormData.append('name', nameRef.current.value);
        scormData.append('description', descriptionRef.current.value);
        scormData.append('entryPoint', entryPointRef.current.value);
        scormData.append('xapi', xapiRef.current.checked);

        onUploadScorm(scormData, editedLanguage);
    };

    const chooseFile = () => {
        if (cmi5Store.error) {
            cmi5Store.error = null;
        }
        fileRef.current.click();
    };

    const handleImageSelect = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const files = e.target.files || e.dataTransfer.files;
        const image = files[0];

        if (!acceptedFormats.split(', ').includes(image.type)) {
            const errorMessage = `'${image.type}' is not a supported format`;
            setError(errorMessage);
        } else if (image.size > 3 * 1024 * 1024) {
            const errorMessage = `'${image.name}' is too large, maximum size is 3MB`;
            setError(errorMessage);
        } else {
            const imageData = {
                file: image,
                name: image.name,
            };
            setError(null);
            setThumbnail(imageData);
        }
    };

    return (
        <>
            <form onSubmit={onSave}>
                <Block style={{ border: 'none', margin: 0, padding: 0 }}>
                    {error && <ErrorMessage error={error} />}
                    <FormControl margin="dense" required>
                        <TextField
                            name="name"
                            required
                            id="scorm-name"
                            label="Name"
                            variant="outlined"
                            className={classes.root}
                            defaultValue={scorm.name}
                            inputRef={nameRef}
                        />
                    </FormControl>
                    <FormControl margin="dense" required>
                        <TextField
                            required
                            id="scorm-language"
                            select
                            variant="outlined"
                            label="Default Language"
                            className={classes.root}
                            defaultValue={scorm.language}
                            name="language"
                            inputRef={languageRef}
                        >
                            {availableLanguages.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl margin="dense" required>
                        <TextField
                            required
                            id="scorm-description"
                            label="Description"
                            multiline
                            minRows={4}
                            variant="outlined"
                            className={classes.root}
                            defaultValue={scorm.description}
                            inputProps={{ maxLength: 300 }}
                            name="description"
                            inputRef={descriptionRef}
                        />
                    </FormControl>
                    {!isLanguageForm ? (
                        <Fragment>
                            <FlexDiv alignCenter>
                                <ThemedButton
                                    type="button"
                                    text="Choose File"
                                    mode="gray"
                                    $noTextTransform={true}
                                    style={{
                                        paddingTop: '12px',
                                        paddingBottom: '12px',
                                        borderRadius: 0,
                                        marginRight: '8px',
                                        border: '1px solid lightgray',
                                    }}
                                    onClick={() => chooseFile()}
                                />
                                <input
                                    className="hidden"
                                    type="file"
                                    multiple={false}
                                    accept={acceptedFormats}
                                    onChange={handleImageSelect}
                                    ref={fileRef}
                                />
                                <FormControl margin="dense" required>
                                    <TextField
                                        required
                                        disabled
                                        id="scorm-image"
                                        label="Image"
                                        variant="outlined"
                                        className={classes.root}
                                        value={
                                            thumbnail
                                                ? thumbnail.name
                                                : undefined
                                        }
                                        name="thumbnailName"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </FlexDiv>
                            <TextTiny
                                color={palette.graphite.ash}
                                mb={12}
                                mt={-8}
                            >
                                Upload image in .png, .jpeg with maximum size
                                3MB. Landscape ratio (4:3) is recommended.
                            </TextTiny>
                        </Fragment>
                    ) : null}
                    {!isEditLanguageContent && (
                        <FormControl margin="dense" required>
                            <TextField
                                required
                                disabled
                                id="scorm-file"
                                label="SCORM file"
                                variant="outlined"
                                className={classes.root}
                                defaultValue={scorm.filename}
                                name="filename"
                                inputRef={filenameRef}
                            />
                        </FormControl>
                    )}
                    <FlexDiv>
                        <FormControl
                            required
                            margin="dense"
                            style={{ marginRight: '40px' }}
                        >
                            <TextField
                                required
                                id="scorm-entryPoint"
                                label="Entry point"
                                variant="outlined"
                                className={classes.root}
                                defaultValue={scorm.entryPoint}
                                name="entryPoint"
                                inputRef={entryPointRef}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleXapiChange}
                                    name="xapi"
                                    color="primary"
                                    inputRef={xapiRef}
                                />
                            }
                            label={
                                <TextWithTooltipIcon
                                    textComponent={
                                        <TextDefault>XAPI</TextDefault>
                                    }
                                    tooltipText={
                                        'Please check this box only if you are familiar with its purpose'
                                    }
                                />
                            }
                        />
                    </FlexDiv>
                </Block>
                <FlexDiv
                    maxHeight={96}
                    dropShadowAbove
                    justifyEnd
                    alignCenter
                    gap={16}
                    px={48}
                    py={18}
                    ml={-48}
                    mr={-48}
                    mb={-32}
                    mt={12}
                    style={{ maxWidth: 'initial' }}
                >
                    {showReupload ? (
                        <ThemedButton
                            mode="secondary"
                            text="Back to drag and drop"
                            type="button"
                            $noTextTransform={true}
                            onClick={() => {
                                onReuploadScorm();
                            }}
                        />
                    ) : null}

                    {cmi5Store.uploadingLanguage ||
                    cmi5Store.updatingLanguagePackage ||
                    cmi5Store.uploadingScorm ? (
                        <ThemedButton
                            mode="primary"
                            type="button"
                            disabled
                            $saving={true}
                            text="Saving"
                            $center={true}
                        />
                    ) : (
                        <ThemedButton
                            mode="primary"
                            text="Save"
                            type="submit"
                        />
                    )}
                </FlexDiv>
            </form>
        </>
    );
}

export default observer(ScormContentForm);
