import React from 'react';
import {
    FlexDiv,
    Block,
    ProgressWithNumber,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import UserStatusText from './UserStatusText';
import moment from 'moment';

function UserTrainingStatus({ training, loading }) {
    return (
        <Block resetMargin flexGrow={1}>
            {!loading ? (
                <FlexDiv column fullHeight>
                    <UserStatusText
                        preTitle={'Training status'}
                        headingText={training?.name || 'N/A - not applicable'}
                        firstDetails={
                            training?._rawData.firstBatch &&
                            'Started ' +
                                moment(training?._rawData.firstBatch).format(
                                    'll'
                                )
                        }
                        secondDetails={
                            training?.lessonsDelivered &&
                            'Lessons delivered ' + training?.lessonsDelivered
                        }
                    />

                    <FlexDiv $noWrap flexGrow={1}>
                        {/** Making sure the progress bar is sticking to bottom of the component*/}
                        <FlexDiv fullWidth style={{ alignSelf: 'end' }}>
                            <ProgressWithNumber
                                value={training && training.singleUserScore}
                                plainNumber
                                fullWidth
                                height={16}
                                fontSize={20}
                                showNA={!training}
                            />
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
            ) : (
                <ThemedSpinner /> //Replace with ghost/skeleton/empty?
            )}
        </Block>
    );
}

export default observer(UserTrainingStatus);
