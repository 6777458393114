import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import stores from './stores';
import includes from 'array-includes';
import 'whatwg-fetch';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { Provider as HttpProvider } from 'use-http';
import BaseService from './services/BaseService';
import { version } from '../package.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

configure({ isolateGlobalState: true });

//const history = createBrowserHistory();

window.__stores = stores;

(async function init() {
    if (import.meta.env.REACT_APP_SENTRY_DSN) {
        try {
            await Sentry.close();
        } catch (e) {}
        Sentry.init({
            dsn: import.meta.env.REACT_APP_SENTRY_DSN,
            release: 'awarego-lms@' + version,
            autoSessionTracking: true,
            beforeSend(event) {
                // Check if it is an exception, and if so, show the report dialog

                if (
                    event.exception &&
                    window.__stores?.store?.currentCompany?.settings[
                        'ui.feedback_enabled'
                    ] === '1'
                ) {
                    Sentry.showReportDialog({ eventId: event.event_id });
                }
                return event;
            },
            integrations: [
                new Integrations.BrowserTracing({
                    // Can also use reactRouterV4Instrumentation
                    routingInstrumentation:
                        Sentry.reactRouterV5Instrumentation(history),
                }),
            ],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 0.0,
        });
    }
})();

includes.shim();
ReactGA.initialize('UA-6397241-1');

// eslint-disable-next-line no-undef

try {
    // eslint-disable-next-line no-undef
    window.PostAffTracker &&
        window.PostAffTracker.setAccountId &&
        window.PostAffTracker.setAccountId('default1');
} catch (e) {
    // Intentionally empty
}

//to window only for debug

/*history.listen((location, action) => {
    if (action === 'PUSH') {
        stores.historyStore.savePath(location.pathname);
    }
});*/

const queryClient = new QueryClient();

const options = {
    interceptors: {
        // every time we make an http request, this will run 1st before the request is made
        // url, path and route are supplied to the interceptor
        // request options can be modified and must be returned
        request: async ({ options }) => {
            const { headers: commonHeaders } = BaseService.commonHeaders();
            Object.keys(commonHeaders).forEach((k) => {
                options.headers[k] = commonHeaders[k];
            });
            //options.headers.Authorization = `Bearer ${stores.authStore.token}`;
            return options;
        },
    },
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
        <Provider {...stores}>
            <QueryClientProvider client={queryClient}>
                <HttpProvider
                    url={import.meta.env.REACT_APP_API_URL}
                    options={options}
                >
                    <BrowserRouter>
                        {/*import.meta.env.NODE_ENV === 'development' && (
                            <Route path="/testing" component={Testing} />
                        )*/}
                        <App />
                    </BrowserRouter>
                </HttpProvider>
            </QueryClientProvider>
        </Provider>
    </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
