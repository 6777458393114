import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Setting from './Setting';
import { useStores } from '../../hooks/use-stores';
import { Heading2, TextDefault } from '@awarego/awarego-components';

export default observer(() => {
    const { authStore } = useStores();
    const { currentUser } = authStore;

    return (
        <div className="top-guide">
            <div className="block new-settings-block">
                <Heading2 displayBlock>My profile</Heading2>
                <TextDefault lighter>
                    Joined on {moment(currentUser.created).format('LL')}
                </TextDefault>
                <Setting name="Name" value={currentUser.name} />
                <Setting name="Email" value={currentUser.email} isLast={true} />
            </div>
        </div>
    );
});
