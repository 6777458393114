import { reaction, makeAutoObservable, runInAction } from 'mobx';
import { backOffDelay, extractErrorMessage } from '../utils/helpers';
import * as Sentry from '@sentry/react';
import services from '../services';
import moment from 'moment';

class AuthStore {
    constructor(commonStore, viewerStore, brandingStore) {
        makeAutoObservable(this);
        this.viewerStore = viewerStore;
        this.commonStore = commonStore;
        this.brandingStore = brandingStore;
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                    this.pullUser();
                } else {
                    window.localStorage.removeItem('jwt');
                    this.viewerStore.logout();
                }
            }
        );

        reaction(
            () => this.currentUser,
            (user) => {
                if (user) {
                    Sentry.setUser(user);
                    Sentry.addBreadcrumb({
                        category: 'auth',
                        message: 'Authenticated user ' + user.email,
                        level: 'info',
                    });
                    this.viewerStore.loadServerData();
                } else {
                    this.viewerStore.logout();
                    this.brandingStore.loadBranding();
                }
            }
        );
    }

    token = window.localStorage.getItem('jwt');
    inProgress = false;
    creatingTrial = false;
    currentUser = null;

    loginError = null;
    emailLoginError = null;
    trialError = null;
    signupError = null;
    profileError = null;
    companyError = null;
    company_id = null;
    campaign_id = null;
    subject_id = null;
    email = null;
    suggestTrial = false;
    extraMessage = false;
    isSignUp = false;

    get anonymousTracking() {
        return this.viewerStore.currentCompany.anonymousTracking === 1;
    }

    setError(error, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.loginError = type === 'login' ? error : null;
        this.emailLoginError = type === 'emailLogin' ? error : null;
        this.trialError = type === 'trial' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
    }

    async logout() {
        this.inProgress = false;
        this.forgetUser();
        this.setToken(null);
    }

    setToken(token) {
        this.token = token;
    }

    setEmail(email) {
        this.email = email;
    }

    get displayName() {
        if (!this.currentUser) return '';
        return this.currentUser.name || this.currentUser.email;
    }

    get timeZone() {
        return moment.tz.guess() && moment.tz(moment.tz.guess())
            ? moment.tz(moment.tz.guess()).format(' z')
            : '';
    }

    get isAuthenticated() {
        return this.currentUser != null;
    }

    get isPartnerAdmin() {
        return (
            this.currentUser &&
            this.currentUser.superForAccounts &&
            this.currentUser.superForAccounts.length > 0
        );
    }

    /* isPartnerAdminOfCompany(companyId) {
    this.currentUser.superForAccounts;
}*/

    get isTeamManager() {
        if (!this.currentUser) return false;
        return this.currentUser.permissions.indexOf('manageUsers') > -1;
    }

    setUser(user) {
        this.currentUser = user;
    }

    setExtraMessage(message) {
        this.extraMessage = message;
    }

    setIsSignUp(boolean) {
        this.isSignUp = boolean;
    }

    forgetUser() {
        this.currentUser = undefined;
    }

    handleSignInResult(response) {
        if (response.url) {
            window.location = response.url;
            return 'redirect';
        }
        if (response.status) return true;
        else {
            this.suggestTrial = true;
        }
    }

    async signin(email, company_id, campaign_id, subject_id, redirect, scope) {
        this.suggestTrial = false;
        this.setIsSignUp(false);
        this.setError(null);
        try {
            let response = await services.Auth.signin(
                email,
                company_id,
                campaign_id,
                subject_id,
                redirect,
                scope
            );
            return this.handleSignInResult(response);
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async resendEmail(email, isSignUp) {
        let result = await this.signin(email);
        if (result) this.commonStore.success('Email was re-sent');
        if (isSignUp) this.setIsSignUp(true);
    }

    async signinFromEmail(token) {
        this.setError(null);
        try {
            let jwtToken = await services.Auth.emailsignin(token);
            this.setToken(jwtToken);
            return true;
        } catch (e) {
            this.setError(e, 'emailLogin');
        }
    }

    async registerTrial(data, simple) {
        this.creatingTrial = true;
        try {
            let result = await services.Auth.registerTrial(data, simple);
            // if (result && result.success && result.token) {
            //     this.setToken(result.token);
            //     this.pullUser();
            //     return 'signedin';
            // }
            if (result.login) return 'email';
            return 'registered';
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            runInAction(() => {
                this.creatingTrial = false;
            });
        }
    }

    async registerGdrp(data) {
        this.creatingTrial = true;
        try {
            await services.Auth.registerFreemium(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            runInAction(() => {
                this.creatingTrial = false;
            });
        }
    }

    async registerPPVTrial(data) {
        this.creatingTrial = true;
        data.ppv = true;
        try {
            await services.Auth.registerTrial(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            runInAction(() => {
                this.creatingTrial = false;
            });
        }
    }

    async profileUpdate(values) {
        this.setError(null, 'profile');
        try {
            let user = await services.Users.update({
                _id: this.currentUser._id,
                ...values,
            });
            runInAction(() => {
                Object.assign(this.currentUser, user);
            });
            this.commonStore.success('Your profile successful updated');
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message,
                'profile'
            );
        }
    }

    async pullUser(counter = 0) {
        this.inProgress = true;
        try {
            if (this.token) {
                let user = await services.Auth.current();
                if (user) {
                    this.setUser(user);
                    this.commonStore.saveAnalyticsEvent(
                        'account',
                        'opened_lms',
                        {}
                    );
                }
            } else {
                this.logout();
            }
        } catch (e) {
            runInAction(() => {
                this.inProgress = false;
                if (
                    counter < 10 &&
                    e &&
                    (!e.response ||
                        e.response.status !== 403 ||
                        e.response.status !== 401)
                ) {
                    setTimeout(async () => {
                        await this.pullUser(counter + 1);
                    }, backOffDelay(1500, counter));
                } else {
                    if (!import.meta.env.REACT_APP_KEEP_JWT)
                        return this.logout();
                }
            });
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }
}

export default AuthStore;
