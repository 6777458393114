import RestService from './RestService';

export default class LanguageService extends RestService {
    constructor() {
        super('/languages');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }
}
