import React from 'react';
import { TextLead } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import HeaderSection from '../../../../components/header/HeaderSection';
import CreateAssessmentButton from '../../../../components/CreateAssessmentButton';

function RecommendationHeader({ cmi5enabled, listId = 'all' }) {
    const createHeading = () => (
        <TextLead bold>Recommended training plan</TextLead>
    );

    const constructUrl = () => {
        let url = 'create-training';

        if (listId !== 'all') {
            url = `create-training/${listId}`;
        }

        return url;
    };

    return (
        <HeaderSection
            style={{ marginBottom: '32px' }}
            excludeBlock
            heading={createHeading()}
            buttons={
                <CreateAssessmentButton
                    cmi5enabled={cmi5enabled}
                    buttonParams={{
                        $noTextTransform: true,
                        $center: true,
                        $noWrap: true,
                    }}
                    buttonStyle={{ marginLeft: '-100px', marginTop: '15px' }}
                    texts={{
                        singleButton: 'Create training…',
                        dropdownButtonRegular: 'Training…',
                        dropdownButtonScorm: 'Training (SCORM, cmi5)…',
                    }}
                    links={{
                        regularLink: constructUrl(),
                        scormLink: 'create-scorm',
                    }}
                />
            }
        />
    );
}

export default observer(RecommendationHeader);
