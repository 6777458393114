import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import RadioButton from '../../../components/RadioButton';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import './distribution.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectChannel from '../wizard/select-channel';
import {
    Heading2,
    Heading3,
    Heading4,
    FlexDiv,
} from '@awarego/awarego-components';

// making sure week on calendar starts on Monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

export default observer(
    ({
        scheduleType,
        scheduledDate,
        dueDate,
        channel,
        slackAvailable,
        slackConnected,
        teamsAvailable,
        teamsConnected,
        onChangeScheduleType,
        onChangeChannel,
        onChangeDate,
        onChangeDueDate,
        ongoing,
        manualEnabled,
        adminTimeZone,
    }) => {
        const [error, setError] = React.useState(null);
        const [dueError, setDueError] = React.useState(null);

        const errorMessageDueDate = React.useMemo(() => {
            switch (dueError) {
                case 'maxDate':
                case 'minDate': {
                    return `Due date must be set after the delivery time (${moment(
                        scheduledDate
                    ).format(`L HH:mm [${adminTimeZone}]`)})`;
                }

                case 'disablePast': {
                    return 'Due date cannot be set in the past';
                }

                case 'invalidDate': {
                    return 'Your date is not valid';
                }

                default: {
                    return '';
                }
            }
        }, [dueError]);

        const errorMessageScheduled = React.useMemo(() => {
            switch (error) {
                case 'disablePast': {
                    return 'Assessment can not be scheduled in the past';
                }

                case 'invalidDate': {
                    return 'Your date is not valid';
                }

                default: {
                    return '';
                }
            }
        }, [error]);

        return (
            <Fragment>
                <div className={'training-schedule'}>
                    <br />
                    <Heading3 mb={16} mt={8} displayBlock>
                        Set delivery
                    </Heading3>
                    <Heading4 lighter mb={16}>
                        Type of delivery
                    </Heading4>
                    <div className={'training-schedule-options'}>
                        {!ongoing ? (
                            <Fragment>
                                <RadioButton
                                    id="typeSend1"
                                    label="Now"
                                    value={1}
                                    groupValue={scheduleType}
                                    onClick={onChangeScheduleType}
                                />
                                <RadioButton
                                    id="typeSend0"
                                    label="Schedule"
                                    value={0}
                                    groupValue={scheduleType}
                                    onClick={onChangeScheduleType}
                                />
                                {manualEnabled && (
                                    <RadioButton
                                        id="typeSend-1"
                                        label="Manual"
                                        value={-1}
                                        groupValue={scheduleType}
                                        onClick={onChangeScheduleType}
                                    />
                                )}
                            </Fragment>
                        ) : scheduleType === 1 ? (
                            <span className="ongoing-text">Now</span>
                        ) : (
                            <div className="flex flex-column">
                                <span className="ongoing-text">
                                    Scheduled - Sent on{' '}
                                    {moment(scheduledDate).format(
                                        `L (LT) [${adminTimeZone}]`
                                    )}
                                </span>
                                <div className="assessment-wizard-info-message extra-message">
                                    <InfoOutlinedIcon fontSize="small" />
                                    <span style={{ maxWidth: '90%' }}>
                                        In case you're adding employees to this
                                        assessment, they will receive this
                                        assessment immediately after you confirm
                                        your edits.
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {scheduleType === 0 && !ongoing && (
                        <FlexDiv mb={24} column maxWidth={264}>
                            <Heading4 lighter mt={8}>
                                Date & time
                            </Heading4>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    hiddenLabel={true}
                                    value={
                                        scheduledDate
                                            ? moment(scheduledDate)
                                            : null
                                    }
                                    onChange={onChangeDate}
                                    ampm={false}
                                    dayOfWeekFormatter={(day) => `${day}`}
                                    disablePast={true}
                                    format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                                    views={['day', 'hours', 'minutes']}
                                    onError={(newError) => setError(newError)}
                                    minDate={moment()}
                                    maxDate={
                                        dueDate !== null && scheduleType === 0
                                            ? moment(dueDate)
                                            : undefined
                                    }
                                    slotProps={{
                                        textField: {
                                            helperText: errorMessageScheduled,
                                            placeholder: 'Select date & time',
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FlexDiv>
                    )}
                    {(scheduleType === 0 || scheduleType === 1) && (
                        <SelectChannel
                            channel={channel}
                            slackAvailable={slackAvailable}
                            slackConnected={slackConnected}
                            teamsAvailable={teamsAvailable}
                            teamsConnected={teamsConnected}
                            onChangeChannel={onChangeChannel}
                            removeTopMargin={scheduleType === 0}
                        />
                    )}
                    <FlexDiv column maxWidth={264}>
                        <Heading2 mb={16} mt={40} $noWrap displayBlock>
                            Set due date*
                        </Heading2>
                        <Heading4 lighter mt={8} displayBlock>
                            Date & time
                        </Heading4>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                hiddenLabel={true}
                                value={dueDate ? moment(dueDate) : null}
                                onChange={onChangeDueDate}
                                ampm={false}
                                dayOfWeekFormatter={(day) => `${day}`}
                                disablePast={true}
                                format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                                views={['day', 'hours', 'minutes']}
                                onError={(newError) => setDueError(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessageDueDate,
                                        placeholder: 'Select date & time',
                                    },
                                }}
                                minDate={
                                    scheduledDate !== null && scheduleType === 0
                                        ? moment(scheduledDate)
                                        : undefined
                                }
                            />
                        </LocalizationProvider>
                    </FlexDiv>
                </div>
            </Fragment>
        );
    }
);
