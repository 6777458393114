import React from 'react';
import {
    FlexDiv,
    Block,
    SemiCircleProgress,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import UserStatusText from './UserStatusText';
import moment from 'moment';

function UserAssessmentStatus({ assessment, loading }) {
    return (
        <Block resetMargin flexGrow={1}>
            {!loading ? (
                <FlexDiv gap={32}>
                    <FlexDiv column justifyCenter>
                        <SemiCircleProgress
                            percentage={assessment && assessment.userScore}
                            percentageText
                            width={176}
                            top={54}
                            showNA={!assessment || !assessment.userScore}
                        />
                    </FlexDiv>
                    <FlexDiv column>
                        <UserStatusText
                            preTitle={'Last assessment'}
                            headingText={
                                assessment?.name || 'N/A - not applicable'
                            }
                            firstDetails={
                                assessment?.completionDate &&
                                'Completed ' +
                                    moment(assessment.completionDate).format(
                                        'll'
                                    )
                            }
                        />
                    </FlexDiv>
                </FlexDiv>
            ) : (
                <ThemedSpinner /> //Replace with ghost/skeleton/empty?
            )}
        </Block>
    );
}

export default observer(UserAssessmentStatus);
