import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FlexDiv, TextInput, InputLabel } from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import { updateNextRunAt } from './ReportFormFieldDefs';

function ReportFrequencyFormFragment({
    reportsForm,
    usersTimeZone,
    editingReport,
}) {
    useEffect(() => {
        if (editingReport) {
            reportsForm.$('scheduleFrequency').set(editingReport.frequency);
            reportsForm
                .$('scheduleWeekdayPosition')
                .set(editingReport.weekdayPosition);
            reportsForm.$('scheduleWeekday').set(editingReport.localWeekDay);
            reportsForm.$('scheduleHour').set(editingReport.localHourOfDay);
            updateNextRunAt(reportsForm);
            reportsForm.validate();
        }
    }, []);

    return (
        <>
            <TextInput
                select
                //stretched
                {...reportsForm.$('scheduleFrequency').bind()}
            >
                {reportsForm.$('scheduleFrequency').extra.map((t) => {
                    return (
                        <MenuItem key={t.value} value={t.value}>
                            {t.label}
                        </MenuItem>
                    );
                })}
            </TextInput>
            <FlexDiv gap={16} alignEnd>
                <TextInput
                    select
                    hidden={
                        reportsForm.$('scheduleFrequency').value !== 'MONTHLY'
                    }
                    {...reportsForm.$('scheduleWeekdayPosition').bind()}
                >
                    {reportsForm.$('scheduleWeekdayPosition').extra.map((t) => {
                        return (
                            <MenuItem key={t.value} value={t.value}>
                                {t.label}
                            </MenuItem>
                        );
                    })}
                </TextInput>
                <TextInput select {...reportsForm.$('scheduleWeekday').bind()}>
                    {reportsForm.$('scheduleWeekday').extra.map((t) => {
                        return (
                            <MenuItem key={t.value} value={t.value}>
                                {t.label}
                            </MenuItem>
                        );
                    })}
                </TextInput>
                <TextInput select {...reportsForm.$('scheduleHour').bind()}>
                    {reportsForm.$('scheduleHour').extra.map((t) => {
                        return (
                            <MenuItem key={t.value} value={t.value}>
                                {t.label} {usersTimeZone}
                            </MenuItem>
                        );
                    })}
                </TextInput>
            </FlexDiv>
            <InputLabel text={reportsForm.$('nextReportSentAtText').value} />
        </>
    );
}

export default observer(ReportFrequencyFormFragment);
