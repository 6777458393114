import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { palette } from '@awarego/awarego-components';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

const useStyles = makeStyles((theme) => ({
    dayWithDotContainer: {
        position: 'relative',
    },
    dayWithDot: {
        position: 'absolute',
        height: 0,
        width: 0,
        border: '2px solid',
        borderRadius: 4,
        borderColor: palette.graphite.heavyMetal,
        right: '47%',
        transform: 'translateX(1px)',
        top: '73%',
    },
    dayWithSelectedDot: {
        borderColor: 'white',
    },
    dayWithTodayDot: {
        borderColor: theme.palette.primary.main,
    },
    dayWithPastDot: {
        opacity: 0.5,
    },
}));

function CalendarWithDots({
    selectedDate,
    setSelectedDate,
    setSelectedMonth,
    daysWithDot,
}) {
    const classes = useStyles();

    function ServerDay(props) {
        const { day, outsideCurrentMonth, ...other } = props;

        const isSelected =
            daysWithDot && daysWithDot.includes(props.day.format('YYYY-MM-DD'));

        return isSelected ? (
            <div className={classes.dayWithDotContainer}>
                <PickersDay
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                    {...other}
                />
                <div
                    className={classnames(classes.dayWithDot, {
                        [classes.dayWithPastDot]: moment(day).isBefore(
                            moment(),
                            'day'
                        ),
                        [classes.dayWithSelectedDot]: moment(day).isSame(
                            moment(selectedDate),
                            'day'
                        ),
                        [classes.dayWithTodayDot]:
                            !moment(day).isSame(moment(selectedDate), 'day') &&
                            moment(day).isSame(moment(), 'day'),
                    })}
                />
            </div>
        ) : (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
            />
        );
    }

    return (
        <>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                dateLibInstance={moment}
            >
                <DateCalendar
                    views={['day']}
                    value={selectedDate && moment(selectedDate)}
                    onChange={(date) => {
                        setSelectedDate(date);
                    }}
                    onMonthChange={(date) => {
                        setSelectedMonth(date);
                    }}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                        day: {},
                    }}
                    disablePast={true}
                />
            </LocalizationProvider>
        </>
    );
}

export default observer(CalendarWithDots);
