export function downloadPDF(response, fileName) {
    const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data)], {
            type: 'application/pdf',
        })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

// Supported types: PDF, CSV
export function downloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(
        new Blob([blob], {
            type: blob.type,
        })
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
