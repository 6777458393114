import React, { Fragment, useMemo, useRef, useEffect, useState } from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from 'react-table';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
import './table.scss';
import { observer } from 'mobx-react';
import ThemedSpinner from '../themed/ThemedSpinner';
import {
    palette,
    TextDefault,
    TextTiny,
    TextWithTooltipIcon,
    Heading3,
    FlexDiv,
} from '@awarego/awarego-components';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';

const ScrollableGrid = styled(Grid)`
    overflow-x: auto;
    padding: 6px;

    ${(props) =>
        props.$overflowInitial &&
        css`
            overflow-x: initial !important;
        `}
`;
const StickyTableHead = styled(TableHead)`
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
`;

const StickyTableFooter = styled(TableFooter)`
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
`;

const TableBodyRow = styled(TableRow)`
    border: 1px solid transparent;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        top: -1px;
        bottom: 0px;
        left: -10px;
        right: -10px;
        transform: scale(1);
        border-radius: 10px;
    }

    &:hover {
        z-index: 2;
        background: white;

        &:after {
            content: '';
            transform: scale(1.02, 1);
            position: absolute;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
                0px 0px 14px rgba(29, 30, 29, 0.08);
        }

        &:last-child {
            border-bottom: 1px solid transparent !important;
        }

        ${(props) =>
            props.$link &&
            css`
                cursor: pointer;
            `}
    }

    &:last-child {
        border-bottom: 1px solid rgba(52, 53, 52, 0.05) !important;
    }
`;

function TableClient({
    columns, // accessor of a column needs to match a property in data object
    hiddenColumns = [], // accessors of columns not appearing in the table, used for e.g. sorting/filter
    data, // data to be displayed in the table
    hidePagination, // hide pagination toolbar if less data than rows per page
    initialPageSize = 5, // how many rows displayed by default
    getRowId, // optional, returns unique identifier for each table row
    defaultSortBy, // use column accessor for default table sorting
    defaultSortDescent = true, // sets sorting direction, depends on defaultSortBy column,
    loadingData = false, // conditional loader, is the same as mobx function loadFlag
    NoDataComponent, // optional component to display if data prop is empty
    link = null, // pass link object {url: 'x', suffix: 'email/id'}, sets onClick event to row, typically to redirect to row value detail (e.g. ReportDetail)
    filterValue = null, // value from search input to apply filtering
    overflowInitial = false, // if set true, scroller on x axis is removed
    getRowProps = () => {},
}) {
    const gridContainerRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(1027);
    let navigate = useNavigate();
    const isTableEmpty = !data || (data && data.length == 0);
    const initialSortBy = useMemo(
        () => [
            {
                id: defaultSortBy,
                desc: defaultSortDescent,
            },
        ],
        [defaultSortBy, defaultSortDescent]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        rows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: initialPageSize ? initialPageSize : 5,
                sortBy: initialSortBy,
                hiddenColumns: hiddenColumns,
            },
            getRowId,
            link,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // calculate width of columns for empty view
    useEffect(() => {
        if (data === null || data.length === 0) {
            const tableContainerElement = gridContainerRef.current;
            if (tableContainerElement) {
                const width = tableContainerElement.offsetWidth;
                setTableWidth(width);
            }
        }
    }, [data]);

    // apply filters
    useEffect(() => {
        if (filterValue || filterValue === '') {
            setGlobalFilter(filterValue || undefined);
        }
    }, [filterValue, setGlobalFilter]);

    const firstPage = () => {
        gotoPage(0);
    };
    const lastPage = () => {
        gotoPage(pageCount - 1);
    };

    // add 'link' path to table row
    const handleRowClick = (row) => {
        if (typeof link === 'function') {
            const builtLink = link(row);
            if (builtLink) {
                navigateToLink(builtLink, row);
            }
        } else {
            navigateToLink(link, row);
        }
    };

    const navigateToLink = (link, row) => {
        if (typeof link === 'string') {
            return navigate(link);
        }
        if (link.suffix == 'id' && row.original.id) {
            navigate(`${link.url}${row.original.id}`);
        } else if (link.suffix == 'email' && row.original.email) {
            navigate(`${link.url}${row.original.email}`);
        } else if (
            link.suffix == 'view' &&
            row.original.id &&
            row.original.isScorm
        ) {
            navigate(`${link.url}p${row.original.id}/${link.suffix}`);
        } else if (link.suffix == 'view' && row.original.id) {
            navigate(`${link.url}${row.original.id}/${link.suffix}`);
        }
    };

    const manualLinkCheck = (row) => {
        if (link(row) != null) {
            return true;
        } else return null;
    };

    return (
        <>
            <ScrollableGrid
                container
                direction="row"
                justifyContent="flex-start"
                align="center"
                ref={gridContainerRef}
                $overflowInitial={overflowInitial}
            >
                <Grid item xs={12} align="center">
                    <MuiTable
                        {...getTableProps()}
                        className="table-common no-margin"
                    >
                        <Fragment>
                            {/* ###  HEAD  ### */}
                            <StickyTableHead>
                                {headerGroups.map((headerGroup, i) => (
                                    <TableRow
                                        {...headerGroup.getHeaderGroupProps()}
                                        key={i}
                                    >
                                        {headerGroup.headers.map(
                                            (column, j) => (
                                                <TableCell
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps(
                                                            // disables default tooltip
                                                            {
                                                                title: undefined,
                                                            }
                                                        )
                                                    )}
                                                    style={{
                                                        minWidth:
                                                            j === 0 &&
                                                            (!data ||
                                                                data.length ===
                                                                    0)
                                                                ? tableWidth /
                                                                      columns.length +
                                                                  'px'
                                                                : column.minWidth,
                                                        top: 57,
                                                        cursor:
                                                            column.canSort &&
                                                            !isTableEmpty
                                                                ? 'pointer'
                                                                : 'auto',
                                                    }}
                                                    key={j}
                                                    mr={4}
                                                >
                                                    <TextTiny
                                                        themed={
                                                            column.isSorted &&
                                                            !isTableEmpty
                                                        }
                                                        lighter={
                                                            !column.isSorted ||
                                                            isTableEmpty
                                                        }
                                                        bold
                                                        flex
                                                    >
                                                        {column.tooltip ? (
                                                            <TextWithTooltipIcon
                                                                tooltipText={
                                                                    column.tooltip
                                                                }
                                                                textComponent={
                                                                    column.Header !==
                                                                    ' '
                                                                        ? column.render(
                                                                              'Header'
                                                                          )
                                                                        : null
                                                                }
                                                            />
                                                        ) : column.Header !==
                                                          ' ' ? (
                                                            column.render(
                                                                'Header'
                                                            )
                                                        ) : null}
                                                        {column.isSorted &&
                                                        !isTableEmpty
                                                            ? column.isSortedDesc
                                                                ? ' ↓'
                                                                : ' ↑'
                                                            : ''}
                                                    </TextTiny>
                                                    {column.Filter && (
                                                        <div>
                                                            {column.render(
                                                                'Filter'
                                                            )}
                                                        </div>
                                                    )}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            </StickyTableHead>

                            {/* ###  BODY  ### */}
                            <TableBody {...getTableBodyProps()}>
                                {data.length > 0 ? (
                                    page.map((row, j) => {
                                        prepareRow(row);
                                        return (
                                            <TableBodyRow
                                                $link={
                                                    link &&
                                                    typeof link === 'function'
                                                        ? manualLinkCheck(row)
                                                        : link
                                                }
                                                {...row.getRowProps(
                                                    getRowProps(row)
                                                )}
                                                key={j}
                                                onClick={() => {
                                                    if (link !== null) {
                                                        handleRowClick(row);
                                                    }
                                                }}
                                            >
                                                {row.cells.map((cell, k) => {
                                                    // first column
                                                    if (k == 0) {
                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps()}
                                                                key={k}
                                                            >
                                                                <TextDefault
                                                                    bold
                                                                    color={
                                                                        palette
                                                                            .graphite
                                                                            .heavyMetal
                                                                    }
                                                                >
                                                                    {cell.render(
                                                                        'Cell'
                                                                    )}
                                                                </TextDefault>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps()}
                                                                key={k}
                                                            >
                                                                {cell.column
                                                                    .showOnHover ? (
                                                                    <span
                                                                        className="show-on-hover"
                                                                        style={{
                                                                            color: palette
                                                                                .graphite
                                                                                .charcoal,
                                                                        }}
                                                                    >
                                                                        {cell.render(
                                                                            'Cell'
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    <TextDefault
                                                                        color={
                                                                            palette
                                                                                .graphite
                                                                                .charcoal
                                                                        }
                                                                    >
                                                                        {cell.render(
                                                                            'Cell'
                                                                        )}
                                                                    </TextDefault>
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableBodyRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            {loadingData ? (
                                                <ThemedSpinner />
                                            ) : (
                                                <FlexDiv
                                                    justifyCenter
                                                    className="table-no-data-component"
                                                >
                                                    {NoDataComponent ? (
                                                        NoDataComponent
                                                    ) : (
                                                        <Heading3>
                                                            No data to display.
                                                        </Heading3>
                                                    )}
                                                </FlexDiv>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                            {/* ###  FOOTER  ### */}
                            {!hidePagination && (
                                <StickyTableFooter>
                                    <TableRow>
                                        <TablePagination
                                            style={{ background: 'white' }}
                                            rowsPerPageOptions={[
                                                {
                                                    label: '5 results per page',
                                                    value: 5,
                                                },
                                                {
                                                    label: '10 results per page',
                                                    value: 10,
                                                },
                                                {
                                                    label: '20 results per page',
                                                    value: 20,
                                                },
                                                {
                                                    label: '30 results per page',
                                                    value: 30,
                                                },
                                                {
                                                    label: '40 results per page',
                                                    value: 40,
                                                },
                                                {
                                                    label: '50 results per page',
                                                    value: 50,
                                                },
                                                {
                                                    label: '100 results per page',
                                                    value: 100,
                                                },
                                            ]}
                                            labelRowsPerPage={null}
                                            count={
                                                filterValue
                                                    ? rows.length
                                                    : data.length
                                            }
                                            className="table-common-pagination"
                                            rowsPerPage={pageSize}
                                            page={pageIndex}
                                            onPageChange={(e, newPage) => {
                                                gotoPage(newPage);
                                            }}
                                            onRowsPerPageChange={(e) => {
                                                setPageSize(
                                                    Number(+e.target.value)
                                                );
                                            }}
                                            ActionsComponent={() => (
                                                <Fragment>
                                                    <IconButton
                                                        onClick={firstPage}
                                                        disabled={
                                                            !canPreviousPage
                                                        }
                                                        aria-label="first page"
                                                    >
                                                        <FirstPageIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={previousPage}
                                                        disabled={
                                                            !canPreviousPage
                                                        }
                                                        aria-label="previous page"
                                                    >
                                                        <KeyboardArrowLeft />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={nextPage}
                                                        disabled={!canNextPage}
                                                        aria-label="next page"
                                                    >
                                                        <KeyboardArrowRight />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={lastPage}
                                                        disabled={!canNextPage}
                                                        aria-label="last page"
                                                    >
                                                        <LastPageIcon />
                                                    </IconButton>
                                                </Fragment>
                                            )}
                                        />
                                    </TableRow>
                                </StickyTableFooter>
                            )}
                        </Fragment>
                    </MuiTable>
                </Grid>
            </ScrollableGrid>
        </>
    );
}

export default observer(TableClient);
