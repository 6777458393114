import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './simple-report.scss';
import { BlockAnswer, SocialPost } from '@awarego/awarego-components';
import AnswersRow from './answers-row';
import { isObservableArray } from 'mobx';

function SimpleReport({ reviewData, totalParticipants }) {
    const { t } = useTranslation('assessment');

    function renderReportDataRow(i, dataRow) {
        return (
            <div className="answers" key={i}>
                {'subtitle' in dataRow ? (
                    <div className="answers-subtitle">
                        {t(dataRow.subtitle)}
                    </div>
                ) : (
                    ''
                )}
                {dataRow.question ? ( //A report rows can have an extra 'question' parent object above the 'answers' (e.g. Decision, Yes-No and Child (of group) blocks)
                    <>
                        <div className="header">
                            <span className="header-left">
                                {t(dataRow.question)}
                            </span>
                        </div>
                        {dataRow.options.map((option, j) => (
                            <AnswersRow
                                key={j}
                                data={option}
                                totalParticipants={totalParticipants}
                            />
                        ))}
                    </>
                ) : (
                    <AnswersRow
                        data={dataRow}
                        totalParticipants={totalParticipants}
                    />
                )}
            </div>
        );
    }

    return (
        <div className="report-block">
            {reviewData.condition && (
                <div className="condition" style={{ marginTop: 0 }}>
                    <span className="condition-heading">
                        This sub-question was shown to a sub-audience who have
                        selected:
                    </span>
                    <span className="condition-value">
                        {t(reviewData.condition.text)}
                    </span>
                </div>
            )}
            {reviewData.title && (
                <div className="header" style={{ marginTop: 0 }}>
                    <span className="header-left">Question:</span>
                </div>
            )}
            {reviewData.pretitle && (
                <div className="pretitle">{t(reviewData.pretitle)}</div>
            )}
            <div className="title">{t(reviewData.title)}</div>
            {reviewData.socialpost && (
                <div>
                    <SocialPost
                        socialPostData={reviewData.socialpost}
                    />
                </div>
            )}
            <div className="header">
                <span className="header-left">
                    {reviewData.title ? 'Answers' : 'Responses'}
                </span>
                {reviewData.blockParticipantCount ||
                reviewData.blockParticipantCount === 0 ? (
                    <span className="header-right">
                        Participants ({reviewData.blockParticipantCount}/
                        {totalParticipants})
                    </span>
                ) : (
                    <span className="header-right">
                        Participants ({totalParticipants})
                    </span>
                )}
            </div>
            {reviewData.report &&
                reviewData.report.map((dataRow, i) => {
                    if (isObservableArray(dataRow)) {
                        return dataRow.map((childDataRow, i) =>
                            renderReportDataRow(i, childDataRow)
                        );
                    } else {
                        return renderReportDataRow(i, dataRow);
                    }
                })}
            {reviewData.actionsReport && (
                <div className="header">
                    <span className="header-left">{'Actions'}</span>
                </div>
            )}
            {reviewData.actionsReport &&
                reviewData.actionsReport.map((dataRow, i) => (
                    <div className="answers" key={i}>
                        <AnswersRow
                            data={dataRow}
                            totalParticipants={totalParticipants}
                        />
                    </div>
                ))}
            {reviewData.answer && (
                <BlockAnswer
                    blockAnswers={reviewData.answer}
                    hideBlock={false}
                    question={null}
                    calculateColor={null}
                    legendStepCount={reviewData.answer.reviewLegendStepCount}
                />
            )}
        </div>
    );
}

export default observer(SimpleReport);
