import React from 'react';
import { observer } from 'mobx-react';


function  CompanyAppearanceLogoDisplay({logo, onRemoveLogo})  {
    //<!--div className="remove-logo-link"><img src="/static/img/icon-remove-circle.svg"/> remove</div-->

    const handleRemoveLogo = async () => {
        onRemoveLogo();
    };


    return (
        <div className="company-logo-display">
            <div
                className="d-flex flex-center flex-fill flex-column"
                style={{ height: '300px' }}
            >
                <div style={{ position: 'relative', minWidth: '128px' }}>
                    <img
                        style={{ width: '100%' }}
                        src={
                            import.meta.env.REACT_APP_RESOURCES_URL +
                            '/' +
                            import.meta.env.REACT_APP_COMPANY_RESOURCES_DIR +
                            '/' +
                            logo
                        }
                        alt="Your logo"
                    />
                    <div
                        className="remove-logo-link"
                        onClick={handleRemoveLogo}
                    >
                        <span className="remove-logo-icon" /> remove
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CompanyAppearanceLogoDisplay;
