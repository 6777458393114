import React, { useEffect, useMemo } from 'react';
import {
    Block,
    Heading3,
    Heading4,
    FlexDiv,
    TextDefault,
    TextTiny,
    palette,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import ThemedSpinner from '../../../../components/themed/ThemedSpinner';
import {
    calculateColor,
    replaceSpaceWithUnderscore,
} from '../../../../utils/helpers';
import { rgba } from 'polished';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

const TiltedTextTiny = styled(TextTiny)`
    transform: rotate(-40deg) translateX(-16px) translateY(61px);
    transform-origin: top left;
    position: absolute;
    top: 0;
`;

function EmployeeListScoresPerThreat({ companyId, assessmentId, hasStarted }) {
    const { assessmentStore, assessmentEmployeesStoresFactory, authStore } =
        useStores();
    const { anonymousTracking } = authStore;

    const { sortedListScoresPerThreatArea, loadingListScoresPerThreatArea } =
        assessmentStore;

    useEffect(() => {
        assessmentStore.loadListScoresPerThreatArea(companyId, assessmentId);
    }, [assessmentId, assessmentStore, companyId]);

    const maxListNameLength = 16;

    const shortenLongListName = (name) =>
        name.length >= maxListNameLength &&
        name.slice(maxListNameLength, maxListNameLength + 1)
            ? name.substring(0, maxListNameLength) + '...'
            : name;

    const content = useMemo(() => {
        if (!hasStarted)
            return (
                <Heading4 center lighter my={24}>
                    No employee list scores yet
                </Heading4>
            );
        if (
            loadingListScoresPerThreatArea ||
            sortedListScoresPerThreatArea.length === 0
        )
            return <ThemedSpinner />;
        return (
            <FlexDiv $noWrap margin={8}>
                <FlexDiv column $noWrap borderRight>
                    {/* empty area name on top */}
                    <FlexDiv
                        pt={
                            sortedListScoresPerThreatArea.length < 4
                                ? undefined
                                : 100
                        }
                        height={
                            sortedListScoresPerThreatArea.length < 4 ? 20 : 56
                        }
                    />

                    {/* area names */}
                    {sortedListScoresPerThreatArea[0].areas.map((area, i) => (
                        <FlexDiv
                            key={i}
                            alignCenter
                            justifyEnd
                            my={2}
                            mr={16}
                            positionSticky
                            height={56}
                        >
                            <TextDefault $noWrap bold link>
                                <Link
                                    to={replaceSpaceWithUnderscore(area.area)}
                                >
                                    {area.area}
                                </Link>
                            </TextDefault>
                        </FlexDiv>
                    ))}
                </FlexDiv>
                <FlexDiv $noWrap overflowXauto fullWidth>
                    {sortedListScoresPerThreatArea.map((list) => (
                        <FlexDiv column key={list.listId} $noWrap flexGrow={1}>
                            <FlexDiv
                                pt={
                                    sortedListScoresPerThreatArea.length < 4
                                        ? undefined
                                        : 100
                                }
                                column
                                positionRelative
                            >
                                {/* tilted list names */}
                                {sortedListScoresPerThreatArea.length < 4 ? (
                                    <TextTiny
                                        color={rgba(
                                            palette.graphite.heavyMetal,
                                            0.6
                                        )}
                                        bold
                                        center
                                    >{`${shortenLongListName(list.listName)} (${
                                        list.usersCount
                                    })`}</TextTiny>
                                ) : (
                                    <TiltedTextTiny
                                        color={rgba(
                                            palette.graphite.heavyMetal,
                                            0.6
                                        )}
                                        bold
                                        $noWrap
                                        mt={30}
                                    >{`${shortenLongListName(list.listName)} (${
                                        list.usersCount
                                    })`}</TiltedTextTiny>
                                )}

                                {/* list scores per threat area */}
                                {list.areas.map((area, i) => (
                                    <FlexDiv
                                        alignCenter
                                        key={i}
                                        justifyCenter
                                        margin={2}
                                        height={56}
                                        minWidth={72}
                                        link
                                        backgroundColor={rgba(
                                            list.started
                                                ? calculateColor(
                                                      area.averageScore
                                                  )
                                                : palette.graphite.heavyMetal,
                                            0.1
                                        )}
                                        onClick={() => {
                                            assessmentEmployeesStoresFactory.modalEmployeesStore.clearSelectedListsInFilter();
                                            assessmentEmployeesStoresFactory.modalEmployeesStore.toggleListSelected(
                                                list.listId
                                            );
                                            assessmentEmployeesStoresFactory.showEmployeesModal(
                                                'users',
                                                {
                                                    threatArea: area.area,
                                                    scoreName:
                                                        'Threat area score',
                                                    subheading: `Threat area: ${area.area}`,
                                                    listId: [list.listId],
                                                }
                                            );
                                        }}
                                    >
                                        <Tooltip title="View employee scores">
                                            <TextDefault
                                                bold
                                                color={
                                                    list.started
                                                        ? calculateColor(
                                                              area.averageScore
                                                          )
                                                        : rgba(
                                                              palette.graphite
                                                                  .heavyMetal,
                                                              0.6
                                                          )
                                                }
                                            >
                                                {list.started
                                                    ? area.averageScore
                                                    : 'N/A'}
                                            </TextDefault>
                                        </Tooltip>
                                    </FlexDiv>
                                ))}
                            </FlexDiv>
                        </FlexDiv>
                    ))}
                </FlexDiv>
            </FlexDiv>
        );
    }, [
        assessmentEmployeesStoresFactory,
        hasStarted,
        sortedListScoresPerThreatArea,
        loadingListScoresPerThreatArea,
    ]);

    return (
        <Block gap={24}>
            <Heading3>Employee list scores per threat area</Heading3>
            {content}
        </Block>
    );
}

export default observer(EmployeeListScoresPerThreat);
