import React, { Fragment, useState, useEffect, useMemo } from 'react';
import without from 'lodash/without';

import { Link, useParams } from 'react-router-dom';
import { ButtonGroup, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import Clipboard from 'react-clipboard.js';
import {
    ThemedRadiobutton,
    ThemedInputLabel,
    ThemedCheckmarkInputContainer,
} from '../components/themed/ThemedComponents';
import sanitizeHtml from 'sanitize-html';
import { Heading2, Heading3, ThemedButton } from '@awarego/awarego-components';
import { useStores } from '../hooks/use-stores';

const { REACT_APP_API_URL } = import.meta.env;

const PPVEmbed = ({}) => {
    const { store, companyStore, ppvStore } = useStores();
    const [size, setSize] = useState(2);
    const [location, setLocation] = useState('');
    const [lang, setLang] = useState('en');
    const [showUID, setShowUID] = useState(false);
    const [showLogo, setShowLogo] = useState(true);
    const [languagesSelection, setLanguagesSelection] = useState(
        store.languagesCodes
    );

    const languages = store.languagesIndex;

    const { currentCompany, currentCompanyUISettings, companyId } = store;
    const { subjectId } = useParams();
    const { summary } = ppvStore;
    const subject =
        summary && summary.find((x) => x.subject_id === Number(subjectId));

    const doLoadData = async () => {
        await Promise.all([
            ppvStore.loadCatalog(companyId),
            ppvStore.loadPPVStats(companyId),
        ]);
    };

    useEffect(() => {
        doLoadData();
    }, []);

    const onSizeChange = (value) => {
        setSize(value);
    };

    const selectLang = (value) => {
        if (languagesSelection.includes(value)) {
            setLanguagesSelection(without(languagesSelection, value));
        } else {
            setLanguagesSelection([...languagesSelection, value]);
        }
    };

    const subjectLanguages = useMemo(() => {
        return subject
            ? subject.languages.filter((lang) =>
                  languagesSelection.includes(lang)
              )
            : [];
    }, [subject, languages, languagesSelection]);

    const disableSnippet = () => {
        ppvStore.toggleSnippet(companyId, subjectId, false);
    };

    const enableSnippet = () => {
        ppvStore.toggleSnippet(companyId, subjectId, true);
    };

    const eventType = (t) => {
        switch (t) {
            case 1:
                return 'Signature';
            case 2:
                return 'View';
            case 3:
                return 'Error';
            default:
                return 'Other';
        }
    };

    let w, h;
    switch (size) {
        case 1:
            w = 375;
            h = 260;
            break;
        case 3:
            w = 720;
            h = 480;
            break;
        default:
            w = 560;
            h = 375;
            break;
    }
    let embedUrl =
        currentCompany.ppv &&
        `${REACT_APP_API_URL}/embed?lang=${languagesSelection[0] || 'en'}` +
            `&key=${currentCompany.ppv.apiKey}&s=${subjectId}&c=${currentCompany.ppv.company_id}` +
            `&langs=${subjectLanguages.join(',')}` +
            `&hideLogo=${showLogo ? '0' : '1'}` +
            `&location=${sanitizeHtml(location)}&uid=${
                showUID && 'PUT_YOUR_UID_HERE'
            }`;
    // Send relevant company settings as well
    if (
        currentCompany.settings &&
        currentCompanyUISettings['ui.preset_caption_language']
    ) {
        embedUrl = `${embedUrl}&presetCaption=${currentCompanyUISettings['ui.preset_caption_language']}`;
    }

    if (
        currentCompany.settings &&
        currentCompanyUISettings['ui.captions_settings']
    ) {
        let captionSettingsString = encodeURIComponent(
            currentCompanyUISettings['ui.captions_settings']
        );
        embedUrl = `${embedUrl}&captionSettings=${captionSettingsString}`;
    }

    const previewEmbedUrl = `${embedUrl}&preview=1`;

    const script =
        `<iframe width="${w}" height="${h}" src="${embedUrl}"` +
        ` frameborder="0"` +
        ` allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"` +
        ` allowfullscreen></iframe>`;

    const previewScript =
        `<iframe width="${w}" height="${h}" src="${previewEmbedUrl}"` +
        ` frameborder="0"` +
        ` allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"` +
        ` allowfullscreen></iframe>`;

    if (!(subject && currentCompany && currentCompany.ppv)) {
        return null;
    }

    return (
        <Fragment>
            <Link to={`/ppv`}>
                <div className={'navigation back-button'}>Back to videos</div>
            </Link>
            <div className="d-flex">
                <div className="block block-margin">
                    <div className="flex flex-column settings-block">
                        <Heading3 mb={8} displayBlock>
                            Select Player Size
                        </Heading3>

                        <ButtonGroup className="d-flex flex-column">
                            <label
                                htmlFor="email"
                                className="container-radiobutton"
                                onClick={(e) => {
                                    onSizeChange(1);
                                }}
                            >
                                <ThemedInputLabel
                                    checked={size === 1}
                                    className="d-flex flex-fill align-items-center justify-content-between"
                                >
                                    Small<span></span>320x240
                                </ThemedInputLabel>
                                <input
                                    type="radio"
                                    name="active"
                                    id="typeSchedule"
                                    className="styled-radiobutton"
                                    value={1}
                                    checked={size === 1}
                                    onChange={(e) => {
                                        onSizeChange(1);
                                    }}
                                />
                                <ThemedRadiobutton checked={size === 1} />
                            </label>
                            <label
                                htmlFor="email"
                                className="container-radiobutton"
                                onClick={(e) => {
                                    onSizeChange(2);
                                }}
                            >
                                <ThemedInputLabel
                                    checked={size === 2}
                                    className="d-flex flex-fill align-items-center justify-content-between"
                                >
                                    Medium<span></span>480x360
                                </ThemedInputLabel>
                                <input
                                    type="radio"
                                    name="active"
                                    id="typeSchedule"
                                    className="styled-radiobutton"
                                    value={2}
                                    checked={size === 2}
                                    onChange={(e) => {
                                        onSizeChange(2);
                                    }}
                                />
                                <ThemedRadiobutton checked={size === 2} />
                            </label>
                            <label
                                htmlFor="email"
                                className="container-radiobutton"
                                onClick={(e) => {
                                    onSizeChange(3);
                                }}
                            >
                                <ThemedInputLabel
                                    checked={size === 3}
                                    className="d-flex flex-fill align-items-center justify-content-between"
                                >
                                    Big<span></span>720x480
                                </ThemedInputLabel>
                                <input
                                    type="radio"
                                    name="active"
                                    id="typeSchedule"
                                    className="styled-radiobutton"
                                    value={3}
                                    checked={size === 3}
                                    onChange={(e) => {
                                        onSizeChange(3);
                                    }}
                                />
                                <ThemedRadiobutton checked={size === 3} />
                            </label>
                        </ButtonGroup>
                        <Heading3 mb={8} mt={24} displayBlock>
                            Location
                        </Heading3>
                        <Input
                            type={'text'}
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <div className={'settings-block'}>
                            <ThemedCheckmarkInputContainer
                                className={'container-checkbox small'}
                            >
                                <label htmlFor={'show_uid'}>
                                    <input
                                        type="checkbox"
                                        id={'show_uid'}
                                        checked={showUID}
                                        onChange={() => setShowUID(!showUID)}
                                    />
                                    Add UID param
                                    <span className="checkmark"></span>
                                </label>
                            </ThemedCheckmarkInputContainer>
                        </div>
                        <div className={'settings-block'}>
                            <ThemedCheckmarkInputContainer
                                className={'container-checkbox small'}
                            >
                                <label htmlFor={'show_logo'}>
                                    <input
                                        type="checkbox"
                                        id={'show_logo'}
                                        checked={showLogo}
                                        onChange={() => setShowLogo(!showLogo)}
                                    />
                                    Show Logo
                                    <span className="checkmark"></span>
                                </label>
                            </ThemedCheckmarkInputContainer>
                        </div>

                        <Heading3 mb={8} mt={24} displayBlock>
                            Languages
                        </Heading3>
                        <div className={'languages settings-block'}>
                            <ul>
                                {subject.languages
                                    .filter((lang) => languages[lang])
                                    .map((lang, i) => {
                                        return (
                                            <li key={i}>
                                                <ThemedCheckmarkInputContainer
                                                    className={
                                                        'container-checkbox small'
                                                    }
                                                >
                                                    <label
                                                        htmlFor={[
                                                            'checkbox-' +
                                                                i +
                                                                '-' +
                                                                lang,
                                                        ]}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            id={[
                                                                'checkbox-' +
                                                                    i +
                                                                    '-' +
                                                                    lang,
                                                            ]}
                                                            className="checkmark"
                                                            checked={languagesSelection.includes(
                                                                lang
                                                            )}
                                                            onChange={() => {
                                                                selectLang(
                                                                    lang
                                                                );
                                                            }}
                                                        />

                                                        <ThemedInputLabel
                                                            checked={languagesSelection.includes(
                                                                lang
                                                            )}
                                                        >
                                                            {
                                                                languages[lang]
                                                                    .name
                                                            }
                                                        </ThemedInputLabel>
                                                        <span
                                                            className={
                                                                'flag ' +
                                                                languages[lang]
                                                                    .icon
                                                            }
                                                            style={{
                                                                backgroundImage: `url(${languages[lang].flag_image})`,
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </ThemedCheckmarkInputContainer>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="block d-flex flex-fill flex-column">
                    {subject.disabled ? (
                        <Heading2 mt={8} mb={16} displayBlock>
                            This video
                        </Heading2>
                    ) : (
                        <Fragment>
                            <div className="d-flex justify-content-end">
                                {subject.status ? (
                                    <ThemedButton
                                        mode="primary"
                                        onClick={disableSnippet}
                                        text="Disable Snippet"
                                    />
                                ) : (
                                    <ThemedButton
                                        mode="primary"
                                        onClick={enableSnippet}
                                        text="Generate Snippet"
                                    />
                                )}
                            </div>
                        </Fragment>
                    )}

                    {!subject.disabled && subject.status && (
                        <Fragment>
                            <Heading2 mt={16} mb={8} displayBlock>
                                iFrame
                            </Heading2>
                            <div className="embed-code">
                                <xmp>{script}</xmp>
                                <Clipboard
                                    className="copy-to-clipboard-link"
                                    data-clipboard-text={script}
                                >
                                    Copy to clipboard
                                </Clipboard>
                            </div>
                            <Heading2 mt={16} mb={8} displayBlock>
                                Link to embed
                            </Heading2>
                            <div className="embed-code">
                                <xmp>{embedUrl}</xmp>
                                <Clipboard
                                    className="copy-to-clipboard-link"
                                    data-clipboard-text={embedUrl}
                                >
                                    Copy to clipboard
                                </Clipboard>
                            </div>
                        </Fragment>
                    )}
                    <div
                        className="embed-holder"
                        style={{
                            width: `${w + 40}px`,
                            height: `${h + 40}px`,
                        }}
                    >
                        <div
                            className="embed"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(previewScript, {
                                    allowedTags:
                                        sanitizeHtml.defaults.allowedTags.concat(
                                            ['iframe']
                                        ),
                                    allowedAttributes: Object.assign(
                                        sanitizeHtml.defaults.allowedAttributes,
                                        {
                                            iframe: [
                                                'width',
                                                'height',
                                                'src',
                                                'frameborder',
                                                'allow',
                                                'allowfullscreen',
                                            ],
                                        }
                                    ),
                                }),
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default observer(PPVEmbed);
