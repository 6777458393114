import { makeAutoObservable } from 'mobx';

function checkedOrDefault(value, defaultValue) {
    return value == null ? defaultValue : value;
}

class SelectionController {
    checked = {};
    unChecked = {};
    pagesSelected = {};
    allSelected = false;
    data = null;
    parent = null;
    usersTotal = 0;
    idAccessor = (x) => x.id;

    constructor(data, parent, idAccessor, usersTotal) {
        makeAutoObservable(this);
        this.data = data;
        this.parent = parent;
        this.usersTotal = usersTotal;
        if (idAccessor) this.idAccessor = idAccessor;
    }

    setData(data, usersTotal) {
        this.data = data;
        this.usersTotal = usersTotal;
    }

    setAllPagesSelected() {
        this.allSelected = true;
        this.unChecked = {};
        this.pagesSelected = {};
    }

    clearSelection() {
        this.allSelected = false;
        this.unChecked = {};
        this.checked = {};
        this.pagesSelected = {};
    }

    toggleCurrentPageSelected(checked) {
        if (!checked && this.allSelected) this.clearSelection();
        if (checked == null)
            checked = !this.pagesSelected[this.parent.pageIndex];

        this.pagesSelected[this.parent.pageIndex] = checked;
        if (!this.pagesSelected[this.parent.pageIndex]) {
            this.unChecked = {};
        } else {
            this.checked = {};
        }
    }

    toggleRow(x, checked) {
        if (
            (this.allSelected && !this.currentPageDeSelected) ||
            this.currentPageSelected
        ) {
            this.unChecked[this.idAccessor(x)] = !checked;
        } else {
            this.checked[this.idAccessor(x)] = checked;
        }
        if (this.selectionCountOnCurrentPage === 0)
            this.pagesSelected[this.parent.pageIndex] = false;

        if (this.selectionCountOnCurrentPage === this.parent.pageSize)
            this.toggleCurrentPageSelected(true);
    }

    get currentPageSelected() {
        return checkedOrDefault(
            this.pagesSelected[this.parent.pageIndex],
            false
        );
    }

    get currentPageDeSelected() {
        return this.pagesSelected[this.parent.pageIndex] === false;
    }

    get effectiveSelectionOnCurrentPage() {
        const result = {};
        this.data.forEach((x) => {
            result[this.idAccessor(x)] =
                (this.allSelected && !this.currentPageDeSelected) ||
                this.currentPageSelected
                    ? checkedOrDefault(
                          !this.unChecked[this.idAccessor(x)],
                          true
                      )
                    : checkedOrDefault(this.checked[this.idAccessor(x)], false);
        });

        return result;
    }

    get totalSelectionCountOnAllPages() {
        if (this.allSelected) {
            return (
                this.usersTotal -
                Object.values(this.unChecked).filter((x) => x).length
            );
        }
        if (this.countByPages > 0) {
            let correction = 0;
            if (!this.currentPageSelected) {
                correction = this.selectionCountOnCurrentPage;
            }

            return (
                this.countByPages -
                Object.values(this.unChecked).filter((x) => x).length +
                correction
            );
        }

        return Object.values(this.checked).filter((x) => x).length;
    }

    get countByPages() {
        if (Object.values(this.pagesSelected).filter((x) => x).length === 0)
            return 0;

        const lastPageSize = this.usersTotal % this.parent.pageSize;
        const lastPageIndex = Math.floor(
            this.usersTotal / this.parent.pageSize
        );
        let count =
            Object.values(this.pagesSelected).filter((x) => x).length *
            this.parent.pageSize;

        if (this.pagesSelected[lastPageIndex])
            count = count - this.parent.pageSize + lastPageSize;

        return count;
    }

    get haveDeselectedOnCurrentPage() {
        return (
            (this.allSelected || this.currentPageSelected) &&
            Object.values(this.effectiveSelectionOnCurrentPage).some((x) => !x)
        );
    }

    get haveDeselectedUsers() {
        return Object.values(this.unChecked).filter((x) => x).length > 0;
    }

    get selectionCountOnCurrentPage() {
        return Object.values(this.effectiveSelectionOnCurrentPage).filter(
            (x) => x
        ).length;
    }

    get selectionCountOnAllPages() {
        if (
            !this.allSelected &&
            Object.values(this.pagesSelected).filter((x) => x).length === 0 &&
            Object.values(this.checked).filter((x) => x).length === 0
        )
            return 0;
        return (
            this.usersTotal -
            Object.values(this.unChecked).filter((x) => x).length
        );
    }

    get totalSelected() {
        if (this.allSelected) {
            return this.haveDeselectedUsers
                ? this.selectionCountOnAllPages
                : this.usersTotal;
        } else {
            return this.totalSelectionCountOnAllPages;
        }
    }

    get selectionContext() {
        return {
            allSelected: this.allSelected,
            pagesSelected: this.pagesSelected,
            unCheckedUsers: this.unChecked,
            checkedUsers: this.checked,
        };
    }
}

export default SelectionController;
