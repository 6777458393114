import RestService from './RestService';
import BaseService from './BaseService';
import stores from '../stores';

export default class Resources extends RestService {
    constructor() {
        super(import.meta.env.REACT_APP_RESOURCES_URL);
    }

    /*
        /companies/:companyId/content
        /companies/:companyId/content/training
        /companies/:companyId/content/assessment
        /companies/:companyId/content/material
        /companies/:companyId/content/material/poster
        /companies/:companyId/content/material/sticker
     */

    all(companyId) {
        return this.get('companies/' + companyId + '/content');
    }

    posters() {
        if (
            stores.brandingStore.branding &&
            stores.brandingStore.branding['ui.posters_url']
        )
            return this.get(stores.brandingStore.branding['ui.posters_url']);
        return this.get(`${this.baseUrl}/posters.json`);
    }

    stickers() {
        if (
            stores.brandingStore.branding &&
            stores.brandingStore.branding['ui.stickers_url']
        )
            return this.get(stores.brandingStore.branding['ui.stickers_url']);
        return this.get(`${this.baseUrl}/stickers.json`);
    }

    addLogo(companyId, formData) {
        const result = this.put('companies/' + companyId + '/logo', formData, {
            headers: {
                ...BaseService.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    }

    removeLogo(companyId) {
        return this.post('companies/' + companyId + '/remove_logo');
    }
}
