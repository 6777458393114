import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../../components/errormessage';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import {
    Heading3,
    Block,
    FlexDiv,
    TextDefault,
    palette,
    TextInput,
    PrimaryOpenInNew,
    BigModal,
    Heading2,
    ThemedButton,
    DarkArrowTooltipIcon,
    CopyToClipboardWrapper,
    TextTiny,
    Tag,
} from '@awarego/awarego-components';
import TableClient from '../../components/table/TableClient';
import RequireRole from '../RequireRole';
import { Menu, MenuItem } from '@mui/material';

function APIKeys() {
    const [modalId, setModalId] = useState(0);
    const [modalName, setModalName] = useState('');
    const [modalShowLoader, setModalShowLoader] = useState(false);
    const [isModalOpen, setModalIsOpen] = useState(false);

    const { commonStore, companyStore, store, brandingStore } = useStores();
    const { currentCompany } = store;
    const { apiKeys, error, deletingApiKeys, loadingApiKeys } = companyStore;
    const { APIhelpUrl } = brandingStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetKey, setTargetKey] = useState(null);
    const rowsPerPage = 5;

    useEffect(() => {
        if (currentCompany) companyStore.loadApiKeys(currentCompany.company_id);
    }, [currentCompany, companyStore, apiKeys]);

    const createNew = () => {
        companyStore.createNewApiKey(currentCompany.company_id);
    };

    const saveName = async (id, name) => {
        await handleChangeName(id, name);
        setModalIsOpen(false);
    };

    const handleChangeName = React.useCallback(
        async (id, value) => {
            companyStore.setApiKeyName(currentCompany.company_id, id, value);
        },
        [companyStore, currentCompany]
    );

    const openModal = (id, name) => {
        setModalId(id);
        setModalName(name);
        setModalIsOpen(true);
    };

    const deleteKey = (id) => {
        commonStore.showConfirm(
            'Do you really want to delete this API key?',
            'Delete',
            'Delete API key?',
            () => {
                companyStore.deleteApiKey(id, null, {
                    companyId: currentCompany.company_id,
                });
            }
        );
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: observer((x) => {
                    return (
                        <>
                            {x.value && x.value.length > 0 ? (
                                x.value
                            ) : (
                                <Tag text="No Name" />
                            )}{' '}
                        </>
                    );
                }),
            },
            {
                Header: 'Access Key',
                accessor: 'value',
                Cell: observer((x) => {
                    return <TextTiny>{x.value}</TextTiny>;
                }),
                disableGlobalFilter: true,
            },
            {
                Header: '',
                accessor: 'id',
                Cell: observer((x) => (
                    <FlexDiv>
                        {deletingApiKeys.includes(x.value) ? (
                            <CircularProgress />
                        ) : (
                            buildExtraActionsColumn(x.row.original)
                        )}
                    </FlexDiv>
                )),
                disableGlobalFilter: true,
            },
        ];
    }, [commonStore, companyStore, currentCompany.company_id, deletingApiKeys]);

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={() => {
                            openModal(row.id, row.name);
                        }}
                        row={row}
                        title="Edit name"
                        icon={'edit'}
                    ></DarkArrowTooltipIcon>
                    <DarkArrowTooltipIcon
                        customFunction={() => deleteKey(row.id)}
                        row={row}
                        title="Delete API Key"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                    <DarkArrowTooltipIcon
                        customFunction={handleMenuOpen}
                        row={row}
                        title="More"
                        icon={'more'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
            </>
        );
    };

    const handleMenuOpen = (e, key) => {
        setTargetKey(key);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setTargetKey(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (
        <FlexDiv>
            <Block flexGrow="9" mr="20">
                <ErrorMessage error={error} />
                <FlexDiv column>
                    <Heading2 mb={8}>API Keys</Heading2>
                    <TextDefault>
                        This API is designed to allow access, creation and
                        modifications of trainings and assessments.
                    </TextDefault>
                    <TextDefault>
                        For information on how to use this API, refer to our{' '}
                        <Link
                            href={APIhelpUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            online documentation
                        </Link>
                        .
                    </TextDefault>
                </FlexDiv>
                <FlexDiv pt="32" pb="32">
                    <ThemedButton
                        onClick={createNew}
                        mode="primary"
                        text="Create API Key"
                    />
                </FlexDiv>
                {loadingApiKeys && <CircularProgress />}
                <TableClient
                    columns={columns}
                    initiallyLoaded={loadingApiKeys === false}
                    data={[...apiKeys]}
                    noItemFoundText={'No existing keys'}
                    hidePagination={apiKeys.length <= rowsPerPage}
                    overflowInitial={true}
                />
                {targetKey && (
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={targetKey != null}
                        onClose={handleMenuClose}
                        elevation={1}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleMenuClose(e);
                            }}
                        >
                            <CopyToClipboardWrapper
                                copyText={targetKey.value}
                                onCopied={commonStore.copied}
                            >
                                Copy API Key &nbsp;
                            </CopyToClipboardWrapper>
                        </MenuItem>
                    </Menu>
                )}
            </Block>
            <Block column flexGrow="3">
                <Heading3 color={palette.evergreen.aspargus} mb={24}>
                    Online documentation
                </Heading3>
                <TextDefault>
                    <Link
                        href={APIhelpUrl}
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        Learn more on how to use our API <PrimaryOpenInNew />
                    </Link>
                </TextDefault>
            </Block>

            <BigModal
                isOpen={isModalOpen}
                width={'500'}
                onRequestClose={() => setModalIsOpen(false)}
                heading="Re-name key"
                modalContent={
                    !modalShowLoader && (
                        <TextInput
                            label={'Name'}
                            type="text"
                            className={'rename-key'}
                            value={modalName}
                            onChange={(e) => setModalName(e.target.value)}
                        />
                    )
                }
                modalFooterContent={
                    <Fragment>
                        <ThemedButton
                            mode="primary"
                            text="Save"
                            onClick={async () => {
                                setModalShowLoader(true);
                                await saveName(modalId, modalName);
                                setModalShowLoader(false);
                            }}
                        />
                        <ThemedButton
                            mode="secondary"
                            onClick={() => {
                                setModalIsOpen(false);
                            }}
                            text="Close"
                        />
                    </Fragment>
                }
            />
        </FlexDiv>
    );
}

export default observer(APIKeys);
