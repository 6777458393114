import RestService from './RestService';

export default class Subscriptions extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/subscriptions`);
        } else super('/subscriptions');
    }

    collect() {
        return this.post(`${this.baseUrl}/collect`);
    }

    cancel() {
        return this.post(`${this.baseUrl}/cancel`);
    }

    plans() {
        return this.get(`${this.baseUrl}/plans`);
    }

    estimate(body) {
        return this.post(`${this.baseUrl}/estimate`, body);
    }

    overview() {
        return this.get(`${this.baseUrl}/overview`);
    }

    braintreetoken() {
        return this.get(`${this.baseUrl}/braintreetoken`);
    }
}
