import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    TextTiny,
    StatusBadge,
    ProgressWithNumber,
    Block,
} from '@awarego/awarego-components';
import NameAndSublistCell from '../../../components/manager/NameAndSublistCell';
import DateCell from '../../../components/manager/DateCell';
import { STATUS, LINKS } from '../../../utils/constants';
import TableClient from '../../../components/table/TableClient';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import {
    caseAndDiacriticsInsensitiveSort,
    statusAlphabeticalSort,
} from '../../../components/table/table-helpers';

function UserTrainingsTable({ trainings }) {
    const maxVisibleLength = 30;
    const rowsPerPage = 5;

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    const subjects = x.row.original.subjectsNames;
                    return (
                        <NameAndSublistCell
                            name={x.value}
                            maxVisibleNameLength={maxVisibleLength}
                            subListItems={subjects}
                            maxVisibleSubListCount={1}
                            linkName={`/trainings/${x.row.original.id}/view`}
                        />
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo?.color}
                        text={x.row.original.statusInfo?.label}
                    />
                ),
                sortType: statusAlphabeticalSort,
            },
            {
                Header: 'Start date',
                accessor: '_rawData.firstBatch',
                Cell: (x) => <DateCell date={x.value} />,
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Lessons delivered',
                accessor: 'lessonsDelivered',
                Cell: (x) => (
                    <TextTiny bold lighter>
                        {x.value}
                    </TextTiny>
                ),
                minWidth: 112,
                sortDescFirst: true,
            },
            {
                Header: 'Completion',
                accessor: 'singleUserScore',
                Cell: (x) =>
                    x.row.original.status === STATUS.ACTIVE ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.opened === 1 ? (
                        <ProgressWithNumber value={x.value} height={6} />
                    ) : (
                        <ProgressWithNumber showNA height={6} />
                    ),
                sortDescFirst: true,
            },
            {
                Header: (
                    <>
                        {' '}
                        Avg. incorrect <br /> answers count{' '}
                    </>
                ),
                accessor: '_rawData.averageIncorrectAnswerCount',
                Cell: (x) => (
                    <TextTiny bold lighter>
                        {x.value || '-'}
                    </TextTiny>
                ),
                minWidth: 112,
                sortDescFirst: true,
            },
        ],
        []
    );

    return (
        <Block noBorder resetPadding>
            <TableClient
                columns={columns}
                data={trainings}
                hidePagination={trainings && trainings.length <= rowsPerPage}
                defaultSortDescent={false}
                link={LINKS.training}
                NoDataComponent={
                    <NoItemsYetContent
                        img={'/static/img/icon-booklist.svg'}
                        imgAlt={'No trainings found'}
                        title={'No trainings found'}
                        text={'This employee has no trainings yet.'}
                    />
                }
            />
        </Block>
    );
}

export default observer(UserTrainingsTable);
