import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';

function EmployeeRemindersModal({ employeeCount, employeeName }) {
    const content = useMemo(() => {
        if (employeeName) return employeeName;
        else {
            return (
                <Fragment>
                    {employeeCount} {pluralize('employee', employeeCount)}
                    {employeeCount > 1 ? ", who haven't " : ", who hasn't "}
                    started the assessment,
                </Fragment>
            );
        }
    }, [employeeCount, employeeName]);
    return <Fragment>{content} will be reminded</Fragment>;
}

export default observer(EmployeeRemindersModal);
