import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ThemedLinkLink } from '../components/themed/ThemedComponents';
import { calculateColor } from '../utils/helpers';
import {
    Block,
    FlexDiv,
    Heading1,
    SearchInput,
    SpaceBetweenDiv,
    TextInput,
    TextLead,
    InlineProgressBar,
    ThemedButton,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import { useStores } from '../hooks/use-stores';
import TableClient from '../components/table/TableClient';
import { multipleStringsInRowSort } from '../components/table/table-helpers';
import NoItemsYetContent from '../components/manager/no-items-yet-content';

const PPVHome = ({ companyId }) => {
    const [nameFilter, setNameFilter] = useState('');

    const showPopup = () => {
        commonStore.showConfirm(
            <Fragment>
                To purchase more views please contact{' '}
                <a href="mailto:sales@awarego.com?subject=Purchase+more+views">
                    sales@awarego.com
                </a>
            </Fragment>,
            'Close',
            'Purchase views',
            () => {},
            false,
            false
        );
    };

    const onCategoryFilterChange = (e) => {
        ppvStore.setCategoryFilter(e.target.value);
    };

    const onNameFilterChange = (e) => {
        ppvStore.setNameFilter(e.target.value);
        setNameFilter(e.target.value);
    };

    const onNameFilterClear = () => {
        ppvStore.setNameFilter('');
        setNameFilter('');
    };

    const doLoadData = useCallback(async () => {
        return await Promise.all([
            ppvStore.loadCatalog(companyId),
            ppvStore.loadPPVStats(companyId),
        ]);
    }, [companyId]);

    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const { store, commonStore, ppvStore, brandingStore } = useStores();
    const { currentCompany } = store;
    const { supportEmail } = brandingStore;
    const {
        summary,
        categories,
        categoryFilter,
        loadingCatalog,
        loadingPPVStats,
    } = ppvStore;

    let purchased =
        (currentCompany &&
            currentCompany.ppv &&
            currentCompany.ppv.viewsPurchased) ||
        0;

    let used =
        (currentCompany &&
            currentCompany.ppv &&
            currentCompany.ppv.viewsUsed) ||
        0;
    let usage = purchased === 0 ? 100 : (100 * used) / purchased;

    const sortedCategories = categories.slice().sort((a, b) => {
        if (a === 'missing') return 1;
        if (b === 'missing') return -1;

        return a.localeCompare(b);
    });
    const rowsPerPage = 20;

    const columns = [
        {
            Header: 'Title',
            accessor: 'title',
            Cell: (x) =>
                x.row.original.disabled ? (
                    <span>{x.value}</span>
                ) : (
                    <ThemedLinkLink
                        to={`/ppv/embed/${x.row.original.subject_id}`}
                        className="d-flex align-items-center"
                    >
                        <img
                            alt={x.value}
                            className="ppv-thumb"
                            src={x.row.original.thumb}
                        />
                        <span>{x.value}</span>
                    </ThemedLinkLink>
                ),
        },
        {
            Header: 'Category',
            accessor: 'categories',
            Cell: (x) => {
                // remove duplicated categories, if any
                const originalCategories = x.value || [];
                const uniqueCategories = [
                    ...new Set(
                        originalCategories.filter(
                            (category) => category !== null
                        )
                    ),
                ];
                return uniqueCategories.join(', ');
            },
            sortType: multipleStringsInRowSort,
        },

        {
            Header: '#Views',
            accessor: 'views',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
    ];

    return (
        <>
            {currentCompany && !currentCompany.ppv && (
                <Block alignCenter>
                    <TextLead>
                        You do not have access to the Embed content. Please
                        contact{' '}
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>{' '}
                        for access.
                    </TextLead>
                </Block>
            )}
            {currentCompany && currentCompany.ppv && (
                <Fragment>
                    <Block>
                        <SpaceBetweenDiv gap={24} alignCenter>
                            <FlexDiv column flexGrow={2}>
                                <Heading1 displayBlock mb={8}>
                                    Your views
                                </Heading1>
                                <FlexDiv alignCenter>
                                    <InlineProgressBar
                                        value={usage}
                                        customColor={calculateColor(usage)}
                                        width={330}
                                    />
                                    <TextLead bold>
                                        {used} / {purchased}{' '}
                                    </TextLead>
                                    <Link to={`/ppv/usage`}>
                                        <TextLead themed bold ml={8}>
                                            Views Usage
                                        </TextLead>
                                    </Link>
                                </FlexDiv>
                            </FlexDiv>
                            <FlexDiv>
                                <ThemedButton
                                    mode="primary"
                                    onClick={showPopup}
                                    text="Purchase more views"
                                />
                            </FlexDiv>
                        </SpaceBetweenDiv>
                    </Block>
                    <Block gap={16}>
                        <SpaceBetweenDiv gap={24} alignCenter>
                            <Heading1 displayBlock>Videos</Heading1>
                            <FlexDiv gap={16} $noWrap>
                                <TextInput
                                    select
                                    inline
                                    onChange={(e) => onCategoryFilterChange(e)}
                                    value={categoryFilter}
                                >
                                    <MenuItem value="all">All videos</MenuItem>
                                    {sortedCategories.map((c) => (
                                        <MenuItem key={c} value={c}>
                                            {c == 'null' ? 'No category' : c}
                                        </MenuItem>
                                    ))}
                                </TextInput>
                                <SearchInput
                                    placeholder={'Search video'}
                                    onChange={(e) => onNameFilterChange(e)}
                                    onClear={onNameFilterClear}
                                    searchValue={nameFilter}
                                />
                            </FlexDiv>
                        </SpaceBetweenDiv>
                        <>
                            <TableClient
                                columns={columns}
                                data={summary}
                                hidePagination={
                                    !summary ||
                                    (summary && summary.length < rowsPerPage)
                                }
                                initialPageSize={rowsPerPage}
                                defaultSortBy={'title'}
                                defaultSortDescent={false}
                                loadingData={loadingCatalog || loadingPPVStats}
                                NoDataComponent={
                                    <NoItemsYetContent
                                        img={'/static/img/icon-booklist.svg'}
                                        imgAlt={'No videos found'}
                                        title={'No videos found'}
                                        text={
                                            'This embedded view does not cointain any videos.'
                                        }
                                    />
                                }
                            />
                        </>
                    </Block>
                    <Block>
                        <SpaceBetweenDiv>
                            <FlexDiv column>
                                <Heading1 mb={8}>Your API key</Heading1>
                                <TextLead mb={16}>
                                    {currentCompany &&
                                        currentCompany.ppv &&
                                        currentCompany.ppv.apiKey}
                                </TextLead>
                            </FlexDiv>
                        </SpaceBetweenDiv>
                    </Block>
                </Fragment>
            )}
        </>
    );
};

export default observer(PPVHome);
