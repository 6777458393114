import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import EmployeesLimitReachedAction from '../../components/manager/employees-limit-reached-action';
import { Block, FlexDiv, palette, Tag } from '@awarego/awarego-components';
import StyledLink from '../../components/StyledLink';
import { useStores } from '../../hooks/use-stores';
import ErrorMessage from '../../components/errormessage';
import CreateEditListModal from './modals/CreateEditListModal';
import UsersAddModal from './modals/UsersAddModal';
import TableClient from '../../components/table/TableClient';
import HeaderSection from '../../components/header/HeaderSection';
import SubHeaderDetailsList from '../../components/header/SubHeaderDetailsList';
import { ThemedButton } from '@awarego/awarego-components';
import { formatDate } from '../../utils/helpers';
import { caseAndDiacriticsInsensitiveSort } from '../../components/table/table-helpers';
import { LINKS } from '../../utils/constants';
import { createNoUsersView, createBadge } from './users-helpers';

function UsersListsTable({
    companyId,
    heading,
    createDisabled,
    currentCompany,
}) {
    const { userStore, listStore } = useStores();
    const [searchValue, setSearchValue] = useState('');
    const [isCreateModalOpen, setCreateModalIsOpen] = useState(false);
    const [isImportModalOpen, setImportModalIsOpen] = useState(false);
    const [error, setError] = useState(null);
    const [loadingState, setLoadingState] = useState(true);
    const [filteredListsCount, setFilteredListsCount] = useState(null);
    const rowsPerPage = 10;
    useEffect(() => {
        loadData();
    }, [
        companyId,
        listStore.lists,
        userStore.usersDeleted,
        userStore.usersAdded,
    ]);

    const loadData = useCallback(async () => {
        setLoadingState(true);
        await listStore.loadCompanyLists(companyId);
        setLoadingState(false);
    }, [companyId]);

    const buttons = useMemo(
        () => (
            <FlexDiv gap={16}>
                <ThemedButton
                    mode="secondary"
                    onClick={() => {
                        setCreateModalIsOpen(true);
                    }}
                    text="Create new"
                />
                <ThemedButton
                    mode="secondary"
                    text="Import"
                    onClick={() => {
                        setImportModalIsOpen(true);
                    }}
                    disabled={createDisabled}
                />
            </FlexDiv>
        ),
        [createDisabled]
    );

    const createDetailsString = useCallback(() => {
        let details = [];
        let filteredLists =
            filteredListsCount == null
                ? listStore.lists.length
                : filteredListsCount;
        let totalLists = listStore.lists ? listStore.lists.length : 0;

        if (filteredLists !== totalLists) {
            details.push(`${filteredLists}/${totalLists}`);
        } else {
            details.push(totalLists);
        }
        details.push(pluralize('list', listStore.lists.length));

        return details.join(' ');
    }, [listStore.lists, userStore.usersTotal, filteredListsCount]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    return (
                        <>
                            <StyledLink
                                to={`/users/list/${x.row.original.id}`}
                                $hoverColor={palette.graphite.onyx}
                                $underlined={true}
                            >
                                {x.value && x.value.length > 0 ? (
                                    x.value
                                ) : (
                                    <Tag text="No Name" />
                                )}{' '}
                            </StyledLink>
                            {x.row.original.source != null &&
                                x.row.original.source != '' &&
                                createBadge(x.row.original.source)}
                        </>
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Created on',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
                minWidth: 144,
            },
            {
                Header: 'Employees',
                accessor: 'usersCount',
            },
        ],
        [caseAndDiacriticsInsensitiveSort]
    );

    return (
        <Block gap={24} tablePadding>
            {error && <ErrorMessage error={error} />}
            <HeaderSection
                heading={heading}
                searchValue={searchValue}
                searchPlaceholder={'Search lists'}
                onSearch={(e) => {
                    const filteredLists = listStore.lists.filter((l) => {
                        return l.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase());
                    });
                    setFilteredListsCount(filteredLists.length);
                    setSearchValue(e.target.value || '');
                }}
                onClearSearch={() => {
                    setFilteredListsCount(listStore.lists.length);
                    setSearchValue('');
                }}
                buttons={buttons}
                subHeadings={
                    <SubHeaderDetailsList
                        key="details"
                        details={[createDetailsString()]}
                    />
                }
                excludeBlock
                marginLeft="16"
            />
            <EmployeesLimitReachedAction
                disabled={createDisabled}
                currentCompany={currentCompany}
            />

            <CreateEditListModal
                isModalOpen={isCreateModalOpen}
                errorSetter={setError}
                companyId={companyId}
                heading="Create Employee list"
                onRequestClose={() => setCreateModalIsOpen(false)}
            ></CreateEditListModal>
            <UsersAddModal
                isModalOpen={isImportModalOpen}
                companyId={companyId}
                isImportingLists
                onRequestClose={() => setImportModalIsOpen(false)}
            ></UsersAddModal>

            <TableClient
                data={listStore.lists.slice()}
                columns={columns}
                hidePagination={
                    listStore.lists && listStore.lists.length < rowsPerPage
                }
                defaultSortBy={'name'}
                defaultSortDescent={false}
                link={LINKS.list}
                loadingData={listStore.loadingLists || loadingState}
                NoDataComponent={createNoUsersView(
                    currentCompany,
                    createDisabled,
                    'lists'
                )}
                filterValue={searchValue}
                initialPageSize={rowsPerPage}
            />
        </Block>
    );
}

export default observer(UsersListsTable);
