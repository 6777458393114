import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    Fragment,
} from 'react';
import { observer } from 'mobx-react';
import { FlexDiv } from '@awarego/awarego-components';
import EventDate from './event-date';
import EventDescription from './event-description';
import Popover from '@mui/material/Popover';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

function ScheduledEvents({
    displayedEvents,
    readOnly,
    selectedDate,
    totalEvents,
    updateEventDate,
    maxNameCharacterLength,
    hideScrollbar = true,
    maxHeight = 264,
    adminTimeZone,
    startDate,
}) {
    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, displayedEvents.length);
    }, [displayedEvents]);

    const [currentEventHovered, setCurrentEventHovered] = useState(null);
    const [calendarModalDate, setCalendarModalDate] = useState(null);
    const [calendarEventEdited, setCalendarEventEdited] = useState(null);
    const [editDatePopoverAnchorEl, setEditDatePopoverAnchorEl] =
        useState(null);
    const itemsRef = useRef([]);

    const changeEventDate = useCallback(
        (newDate) => {
            if (moment(newDate).isBefore(moment(startDate))) return;
            setCalendarModalDate(newDate);
            calendarEventEdited &&
                updateEventDate(calendarEventEdited, newDate);
        },
        [calendarEventEdited, startDate, updateEventDate]
    );

    const handleEditDateClick = useCallback((e, j) => {
        setEditDatePopoverAnchorEl(itemsRef.current[j]);
        setCalendarEventEdited(e);
        setCalendarModalDate(e.date);
    }, []);

    const handleCloseEditDateCalendar = () => {
        setEditDatePopoverAnchorEl(null);
        setCalendarEventEdited(null);
        setCalendarModalDate(null);
    };

    const showButton = (e) => {
        setCurrentEventHovered(e);
    };
    const hideButton = () => {
        setCurrentEventHovered(null);
    };

    return (
        <Fragment>
            <FlexDiv
                padding={16}
                overflowYauto
                hideScrollbar={hideScrollbar}
                maxHeight={maxHeight}
                column
                $noWrap
                fullWidth
            >
                {displayedEvents.map((e, i) => (
                    <FlexDiv
                        py={4}
                        mb={8}
                        alignCenter
                        ref={(el) => (itemsRef.current[i] = el)}
                        key={i}
                        $noWrap
                        onMouseEnter={() => !readOnly && showButton(e)}
                        onMouseLeave={() => !readOnly && hideButton(e)}
                        fullWidth
                    >
                        <EventDate e={e} selectedDate={selectedDate} />
                        <EventDescription
                            e={e}
                            totalEvents={totalEvents}
                            handleEditDateClick={handleEditDateClick}
                            index={i}
                            readOnly={readOnly}
                            currentEventHovered={currentEventHovered}
                            maxNameCharacterLength={maxNameCharacterLength}
                            adminTimeZone={adminTimeZone}
                        />
                    </FlexDiv>
                ))}
            </FlexDiv>
            <Popover
                open={Boolean(editDatePopoverAnchorEl)}
                anchorEl={editDatePopoverAnchorEl}
                onClose={handleCloseEditDateCalendar}
                anchorOrigin={{
                    vertical: 'center',
                    // 17 because there's 16px padding and 1px border
                    horizontal: -17,
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                disableEnforceFocus
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        hiddenLabel={true}
                        value={
                            calendarModalDate ? moment(calendarModalDate) : null
                        }
                        onChange={changeEventDate}
                        ampm={false}
                        dayOfWeekFormatter={(day) => `${day}`}
                        disablePast={true}
                        format={`DD/MM/YYYY HH:mm[${adminTimeZone}]`}
                        views={['day', 'hours', 'minutes']}
                        slotProps={{
                            textField: {
                                placeholder: 'Select date & time',
                            },
                        }}
                    />
                </LocalizationProvider>
            </Popover>
        </Fragment>
    );
}

export default observer(ScheduledEvents);
