import React from 'react';
import { Row, Col } from 'reactstrap';

export default ({ children }) => (
    <Row>
        <Col md="12" xs="12">
            <div className="">{children}</div>
        </Col>
    </Row>
);
