import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import TableClient from '../../components/table/TableClient';
import { Menu, MenuItem } from '@mui/material';
import {
    Tag,
    palette,
    DarkArrowTooltipIcon,
} from '@awarego/awarego-components';
import { caseAndDiacriticsInsensitiveSort } from '../../components/table/table-helpers';
import { useStores } from '../../hooks/use-stores';
import RequireRole from '../RequireRole';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import StyledLink from '../../components/StyledLink';
import { createNoReportsView } from './reports-helpers';
import { LINKS } from '../../utils/constants';

function ReportsTable({ companyId, onEditReport, onDeleteReport }) {
    const { reportsStore } = useStores();
    const rowsPerPage = 5;
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetReport, setTargetReport] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [companyId]);

    useEffect(() => {
        // Cleanup run on component unmount
        return () => {
            reportsStore.error = null;
        };
    }, []);

    const loadData = useCallback(async () => {
        await reportsStore.loadAllReports(companyId);
    }, [companyId]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => {
                    return (
                        <StyledLink
                            to={`/reports/${x.row.original.id}`}
                            $hoverColor={palette.graphite.onyx}
                            $underlined={true}
                        >
                            {x.value && x.value.length > 0 ? (
                                x.value
                            ) : (
                                <Tag text="No Name" />
                            )}{' '}
                        </StyledLink>
                    );
                },
                sortType: caseAndDiacriticsInsensitiveSort,
            },
            {
                Header: 'Frequency',
                accessor: 'frequencyText',
                Cell: (x) => x.value,
                disableSortBy: true,
            },
            {
                Header: ' ',
                Cell: (x) => buildExtraActionsColumn(x.row.original),
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => x.value,
            },
        ],
        [caseAndDiacriticsInsensitiveSort]
    );

    const buildExtraActionsColumn = (row) => {
        return (
            <>
                <RequireRole>
                    <DarkArrowTooltipIcon
                        customFunction={onEditReport}
                        row={row}
                        title="Edit Report"
                        icon={'edit'}
                    ></DarkArrowTooltipIcon>
                    <DarkArrowTooltipIcon
                        customFunction={onDeleteReport}
                        row={row}
                        title="Delete Report"
                        icon={'delete'}
                    ></DarkArrowTooltipIcon>
                </RequireRole>
                <DarkArrowTooltipIcon
                    customFunction={handleMenuOpen}
                    row={row}
                    title="More"
                    icon={'more'}
                ></DarkArrowTooltipIcon>
            </>
        );
    };

    const handleMenuOpen = (e, report) => {
        setTargetReport(report);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setTargetReport(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    return (
        <>
            {reportsStore.error && (
                <ErrorMessage error={reportsStore.error} marginBottom />
            )}
            <>
                <TableClient
                    columns={columns}
                    data={reportsStore.reports.slice()}
                    hidePagination={
                        reportsStore.reports &&
                        reportsStore.reports.length <= rowsPerPage
                    }
                    defaultSortBy={'created'}
                    hiddenColumns={['created']}
                    loadingData={reportsStore.loadingReports}
                    NoDataComponent={createNoReportsView(
                        'No recurring reports yet'
                    )}
                    link={LINKS.reports}
                />
                {/* candidate for separate component MoreActionMenu */}
                {targetReport && (
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={targetReport != null}
                        onClose={handleMenuClose}
                        elevation={1}
                    >
                        <MenuItem
                            onClick={(e) => {
                                navigate(targetReport.link);
                                handleMenuClose(e);
                            }}
                        >
                            View report
                        </MenuItem>
                    </Menu>
                )}
            </>
        </>
    );
}

export default observer(ReportsTable);
