import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    clientSecret: {
        label: 'Client Secret',
        rules: 'string|required',
    },
    clientId: {
        label: 'Client App Id',
        rules: 'string|required',
    },
};

class VantaForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default VantaForm;
