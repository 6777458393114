import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { withTheme } from 'styled-components';

class ThemedEmailSentSVG extends React.Component {
    render() {
        return (
            <div>
                <SvgLoader path="/static/img/icon-mail-sent.svg">
                    <SvgProxy
                        selector="path"
                        fill={this.props.color || this.props.theme.primary}
                    />
                </SvgLoader>
            </div>
        );
    }
}

export default withTheme(ThemedEmailSentSVG);
