import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import TableServer from '../../../components/table/TableServer';

function EmployeesCheckboxTable({
    usersList,
    usersTotal,
    pageSize,
    onChangePagingParams,
    onChangeSort,
    loadingUsersList,
    columns,
    hiddenColumns,
    rowsPerPage,
    defaultSortBy = 'scorePercent',
    defaultSortDescent = false,
    hidePageSizeSelector = false,
}) {
    const pageCount = useMemo(() => {
        {
            if (!usersTotal || !pageSize) return 0;
            return Math.ceil(usersTotal / pageSize);
        }
    }, [pageSize, usersTotal]);

    return (
        <TableServer
            columns={columns}
            data={usersList.slice()}
            hiddenColumns={hiddenColumns}
            hidePagination={usersTotal <= rowsPerPage}
            defaultSortBy={defaultSortBy}
            defaultSortDescent={defaultSortDescent}
            rowsTotal={usersTotal}
            pagesTotal={pageCount}
            loadingData={loadingUsersList}
            onChangePagingParams={onChangePagingParams}
            onChangeSort={onChangeSort}
            initialPageSize={rowsPerPage}
            hidePageSizeSelector={hidePageSizeSelector}
        ></TableServer>
    );
}

export default observer(EmployeesCheckboxTable);
