import React from 'react';
import { TextTiny } from '@awarego/awarego-components';

function TermsOfService({ termsUrl, accountName }) {
    return (
        <TextTiny lighter mt={8}>
            By clicking Continue, you agree to {accountName}
            's{' '}
            <a href={termsUrl} rel="noopener noreferrer" target="_blank">
                <u>Terms of service</u>
            </a>
        </TextTiny>
    );
}

export default TermsOfService;
