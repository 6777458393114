import React from 'react';
import NoItemsYetContent from '../../components/manager/no-items-yet-content';
import EmployeesLimitReachedAction from '../../components/manager/employees-limit-reached-action';
import {
    TextDefault,
    FlexDiv,
    StatusBadge,
    palette,
} from '@awarego/awarego-components';
import { Link } from 'react-router-dom';

export function createNoUsersView(
    currentCompany,
    createDisabled,
    entity,
    showIntegrations = true
) {
    const imgName =
        entity == 'users' ? 'employees-empty.svg' : 'employee-list-empty.svg';
    const imgAlt =
        entity == 'users'
            ? 'empty employees view'
            : 'empty employee lists view';
    const title =
        entity == 'users' ? 'No employees yet' : 'No employee lists yet';
    return (
        <FlexDiv justifyCenter>
            <NoItemsYetContent
                img={`/static/img/${imgName}`}
                imgAlt={imgAlt}
                title={title}
                extraContent={
                    createDisabled ? (
                        <EmployeesLimitReachedAction
                            disabled={createDisabled}
                            currentCompany={currentCompany}
                            centered
                        />
                    ) : (
                        showIntegrations && (
                            <TextDefault mt={24}>
                                Or{' '}
                                <TextDefault themed bold>
                                    <Link to="/settings/integrations">
                                        set up integrations
                                    </Link>
                                </TextDefault>{' '}
                                to sync your employees automatically
                            </TextDefault>
                        )
                    )
                }
            />
        </FlexDiv>
    );
}

export function createBadge(source) {
    switch (source) {
        case 'azure':
            return (
                <StatusBadge
                    color={palette.evergreen.aspargus}
                    text={'AD imported'}
                />
            );
        case 'google':
            return (
                <StatusBadge
                    color={palette.evergreen.aspargus}
                    text={'Google imported'}
                />
            );

        case 'vanta':
            return (
                <StatusBadge
                    color={palette.evergreen.aspargus}
                    text={'Vanta imported'}
                />
            );
        default:
            break;
    }
}
