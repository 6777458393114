import React, { Fragment, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import ManagersTable from '../super/ManagersTable';
import AddManager from '../../components/super/add-manager';
import { useStores } from '../../hooks/use-stores';
import {
    Block,
    SpaceBetweenDiv,
    BigModal,
    ThemedButton,
    Heading2,
} from '@awarego/awarego-components';
import AddManagerDef from '../../forms/add-manager';

function CompanyManagers({
    company,
    addingManager,
    addManager,
    removeManager,
    sendLoginInfo,
    addManagerError,
}) {
    const [showAddManagerModal, setShowAddManagerModal] = useState(false);

    const { commonStore } = useStores();

    const localStore = useLocalObservable(() => {
        let managerForm = new AddManagerDef(
            {},
            {
                onSuccess() {
                    doAddManager(this);
                },
            }
        );
        return { managerForm };
    });

    const doAddManager = async (form) => {
        const result = await addManager(form);
        if (result) {
            setShowAddManagerModal(false);
            commonStore.success('Admin added!');
        }
    };

    const doRemoveManager = (companyId, name, email) => {
        commonStore.showConfirm(
            `Admin ${name ? name : email} will be removed from this company`,
            'Remove',
            `Remove ${name ? name : email}?`,
            async () => {
                const result = await removeManager(companyId, email);
                if (result) commonStore.success('Admin removed!');
            },
            true
        );
    };

    const doSendLoginInfo = (managerEmail, companyId) => {
        commonStore.showConfirm(
            `Do you want to send an email to ${managerEmail} with a login link for the LMS?`,
            'Send email',
            'Send login email',
            () => {
                const result = sendLoginInfo(managerEmail, companyId);
                if (result) commonStore.success('Login email sent!');
            },
            false
        );
    };

    return !company ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Block>
                <SpaceBetweenDiv ml={16} pr={32}>
                    <Heading2>Admins</Heading2>
                    <ThemedButton
                        mode="secondary"
                        text="Add admin"
                        onClick={() => {
                            localStore.managerForm.reset();
                            setShowAddManagerModal(true);
                        }}
                    />
                </SpaceBetweenDiv>
                {company && (
                    <ManagersTable
                        company={company}
                        removeManager={doRemoveManager}
                        sendLoginInfo={doSendLoginInfo}
                    />
                )}
            </Block>
            <BigModal
                isOpen={showAddManagerModal}
                onRequestClose={() => setShowAddManagerModal(false)}
                heading="Add new admin"
                modalContent={
                    <AddManager
                        error={addManagerError}
                        managerForm={localStore.managerForm}
                        addManagerError={addManagerError}
                    />
                }
                modalFooterContent={
                    addingManager ? (
                        <ThemedButton
                            mode="primary"
                            type="button"
                            disabled
                            $saving={true}
                            text="Saving"
                            $center={true}
                        />
                    ) : (
                        <Fragment>
                            <ThemedButton
                                mode="secondary"
                                text="Cancel"
                                onClick={() => setShowAddManagerModal(false)}
                            />
                            <ThemedButton
                                mode="primary"
                                text="Add"
                                onClick={() => {
                                    localStore.managerForm.submit();
                                }}
                            />
                        </Fragment>
                    )
                }
            />
        </Fragment>
    );
}

export default observer(CompanyManagers);
