import React from 'react';
import IntroductoryForm from '../wizard/introductory-form';
import { CheckboxInput, FlexDiv, TextLead } from '@awarego/awarego-components';
import { observer } from 'mobx-react';

function IntroMessage({ automation, introductoryForm, toggleIntroMessage }) {
    return (
        <FlexDiv column gap={24} mt={40}>
            <CheckboxInput
                checked={automation.introMessageEnabled}
                onChange={toggleIntroMessage}
                name="introMessage"
                label={<TextLead bold>Include introductory message</TextLead>}
                noYPadding
            />
            {automation.introMessageEnabled && (
                <IntroductoryForm form={introductoryForm} />
            )}
        </FlexDiv>
    );
}

export default observer(IntroMessage);
