import React, { useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import TableServer from '../../../components/table/TableServer';
import {
    Block,
    ThemedButton,
    LightTooltip,
    TextTiny,
    TextDefault,
    TextWithTooltipIcon,
    palette,
} from '@awarego/awarego-components';
import { caseAndDiacriticsInsensitiveSort } from '../../../components/table/table-helpers';
import { useStores } from '../../../hooks/use-stores';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FILE_TYPES, REPORT_EXECUTION_STATUS } from '../../../utils/constants';
import { createNoReportsView } from '../reports-helpers';

function ReportTable({ companyId, reportId }) {
    const { reportsStore } = useStores();
    const rowsPerPage = 5;

    useEffect(() => {
        void reportsStore.setQueryContext({
            p: 0,
            n: 5,
            order: 'id desc',
        });
    }, []);

    useEffect(() => {
        loadData();
        return () => {};
    }, [
        companyId,
        reportId,
        reportsStore.pageIndex,
        reportsStore.pageSize,
        reportsStore.order,
    ]);

    const loadData = useCallback(async () => {
        await reportsStore.loadExecutionsByReport(companyId, reportId);
    }, [
        companyId,
        reportId,
        reportsStore.pageIndex,
        reportsStore.pageSize,
        reportsStore.order,
    ]);

    const columns = useMemo(
        () => [
            {
                Header: 'Generated',
                accessor: 'generatedAt',
                Cell: (x) => {
                    return (
                        <>
                            {x.row.original.status ==
                            REPORT_EXECUTION_STATUS.ERROR ? (
                                <span
                                    style={{
                                        color: palette.graphite.ash,
                                        textDecoration: 'line-through',
                                    }}
                                >
                                    {moment(x.value).format('LL')}
                                </span>
                            ) : x.row.original.status ==
                              REPORT_EXECUTION_STATUS.PLANNED ? (
                                <span style={{ color: palette.graphite.ash }}>
                                    {moment(x.row.original.scheduledAt).format(
                                        'LL'
                                    )}
                                </span>
                            ) : (
                                <span>{moment(x.value).format('LL')}</span>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Recipients',
                accessor: 'recipients',
                Cell: (x) => {
                    if (x.value && x.value.length > 0) {
                        return (
                            <>
                                <TextDefault
                                    lighter={
                                        x.row.original.status !=
                                        REPORT_EXECUTION_STATUS.SUCCEEDED
                                    }
                                    style={
                                        x.row.original.status ==
                                        REPORT_EXECUTION_STATUS.ERROR
                                            ? {
                                                  textDecoration:
                                                      'line-through',
                                              }
                                            : null
                                    }
                                >
                                    {x.value.slice(0, 3).join(', ')}{' '}
                                </TextDefault>

                                {x.value.length > 3 && (
                                    <LightTooltip
                                        onClick={(e) => e.preventDefault()}
                                        title={x.value
                                            .slice(3, x.value.length)
                                            .map((email) => (
                                                <>
                                                    <span
                                                        style={{
                                                            textDecoration:
                                                                'line-through',
                                                            color: palette
                                                                .graphite.ash,
                                                        }}
                                                    >
                                                        {email}
                                                    </span>
                                                    <br />
                                                </>
                                            ))}
                                    >
                                        <TextTiny
                                            lighter
                                            underline
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            +{x.value.length - 3} more
                                        </TextTiny>
                                    </LightTooltip>
                                )}
                            </>
                        );
                    }
                    return null;
                },
                disableSortBy: true,
            },
            {
                Header: '',
                accessor: 'error',
                Cell: (x) => {
                    if (
                        x.row.original.status == REPORT_EXECUTION_STATUS.ERROR
                    ) {
                        return (
                            <TextWithTooltipIcon
                                tooltipText={
                                    'Something went wrong and we are working on getting it fixed'
                                }
                                textComponent={
                                    <TextDefault color={palette.vibrant.red}>
                                        No Report was generated
                                    </TextDefault>
                                }
                                icon={
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        htmlColor={palette.vibrant.red}
                                    />
                                }
                            />
                        );
                    }

                    return null;
                },
            },
            {
                Header: 'Download file',
                Cell: (x) => (
                    <>
                        {x.row.original.hasPDF && (
                            <ThemedButton
                                mode="secondary"
                                size="tiny"
                                text="PDF"
                                onClick={() => generatePDF(x.row.original.id)}
                                style={{ margin: '-9px 0' }}
                            />
                        )}
                        {x.row.original.hasCSV && (
                            <ThemedButton
                                mode="secondary"
                                size="tiny"
                                text="CSV"
                                $airy
                                onClick={() => generateCSV(x.row.original.id)}
                            />
                        )}
                    </>
                ),
                disableSortBy: true,
            },
        ],
        [caseAndDiacriticsInsensitiveSort]
    );

    const pagesTotal = useMemo(() => {
        {
            if (!reportsStore.reportExecutionsCount || !reportsStore.pageSize)
                return 0;
            const pageCount = Math.ceil(
                reportsStore.reportExecutionsCount / reportsStore.pageSize
            );
            return pageCount;
        }
    }, [reportsStore.pageSize, reportsStore.reportExecutionsCount]);

    const onChangePagingParams = (pageIndex, pageSize) => {
        if (
            reportsStore.pageIndex != pageIndex ||
            reportsStore.pageSize != pageSize
        ) {
            reportsStore.setPagingParams(pageIndex, pageSize);
        }
    };

    const onChangeSort = (sortBy) => {
        reportsStore.setSortBy(sortBy);
    };

    const generatePDF = (executionId) => {
        reportsStore.generateFile(
            companyId,
            reportId,
            executionId,
            FILE_TYPES.PDF
        );
    };
    const generateCSV = (executionId) => {};

    return (
        <Block gap={24} tablePadding>
            <TableServer
                columns={columns}
                data={reportsStore.reportExecutions.slice()}
                hidePagination={
                    reportsStore.reportExecutionsCount <= rowsPerPage
                }
                defaultSortBy={'generatedAt'}
                rowsTotal={reportsStore.reportExecutionsCount}
                pagesTotal={pagesTotal}
                onChangePagingParams={onChangePagingParams}
                onChangeSort={onChangeSort}
                loadingData={reportsStore.loadingExecutions}
                NoDataComponent={createNoReportsView(
                    'This report has not been executed nor scheduled'
                )}
            />
        </Block>
    );
}

export default observer(ReportTable);
