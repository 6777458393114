import React, { useMemo } from 'react';
import {
    ProgressWithNumber,
    Block,
    palette,
} from '@awarego/awarego-components';
import TableClient from '../../../components/table/TableClient';
import HeaderSection from '../../../components/header/HeaderSection';
import StyledLink from '../../../components/StyledLink';

function ListsProgress({ lists }) {
    const columns = useMemo(
        () => [
            {
                Header: 'List name',
                accessor: 'name',
                Cell: (x) => {
                    return (
                        <>
                            <StyledLink
                                to={`/users/list/${x.row.original.id}`}
                                $hoverColor={palette.graphite.onyx}
                                $underlined={true}
                            >
                                {x.value}
                            </StyledLink>
                        </>
                    );
                },
            },
            {
                Header: ' No. of employees',
                accessor: 'list_user_count',
                Cell: (x) => x.value,
            },
            {
                Header: 'Completion rate',
                accessor: 'score',
                Cell: (x) => (
                    <ProgressWithNumber
                        value={
                            x.row.original.total
                                ? Math.round(
                                      (100 * x.row.original.score) /
                                          x.row.original.total
                                  )
                                : 0
                        }
                        height={6}
                        width={330}
                    />
                ),
            },
        ],
        [lists]
    );

    return (
        <Block gap={24} tablePadding noBorder resetMargin>
            <HeaderSection
                heading={'Userlist progress'}
                excludeBlock
                marginLeft="16"
            />

            <TableClient
                columns={columns}
                data={lists}
                hidePagination={lists && lists.length <= 5}
            />
        </Block>
    );
}

export default ListsProgress;
