import RestService from './RestService';
import Questions from './QuestionsService';
import Users from './UsersService';
import BouncedEmails from './BouncedEmailsService';
import buildURL from 'axios/lib/helpers/buildURL';

class Recommendations extends RestService {
    /**
     * @param {RestService} parentService
     */
    constructor(parentService, parentId) {
        if (parentService) {
            super(`${parentService.baseUrl}/${parentId}/recommendations`);
        } else {
            throw new Error(
                'RecommendationsService can only be used as a nested service'
            );
        }
    }
}

export default class Assessments extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/assessments`);
        } else super('/assessments');
    }

    //Get latest ten assessments,
    listLatestTen() {
        return this.get(`${this.baseUrl}/latest-ten`);
    }

    //Get latest ten assessments,
    userAssessments(email) {
        return this.get(
            `${this.baseUrl}/user-assessments/${encodeURIComponent(email)}`
        );
    }

    restartAssessment(assessmentId, email) {
        return this.del(
            `${this.baseUrl}/${assessmentId}/progress/${encodeURIComponent(
                email
            )}`
        );
    }

    countReminders(assessmentId, params, body) {
        return this.post(
            buildURL(`${this.baseUrl}/${assessmentId}/count-reminders`, params),
            body
        );
    }

    remindersOverview(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/reminders-overview`);
    }

    employeeLists(assessmentId, filters, csv = false, options) {
        const params = { ...filters };
        if (csv) params.csv = true;
        return this.get(
            `${this.baseUrl}/${assessmentId}/lists`,
            params,
            options
        );
    }

    employeesReportList(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/employees`);
    }

    questionsReportList(assessmentId, filters) {
        return this.get(`${this.baseUrl}/${assessmentId}/questions`, filters);
    }

    keyBehavioursList(assessmentId, filters) {
        return this.get(`${this.baseUrl}/${assessmentId}/behaviours`, filters);
    }

    threatAreaDetail(assessmentId, area, listId) {
        return this.get(
            `${this.baseUrl}/${assessmentId}/areas/${area}`,
            listId
        );
    }

    threatAreasList(assessmentId, filters) {
        return this.get(`${this.baseUrl}/${assessmentId}/areas`, filters);
    }

    riskInsights(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/risk-insights`);
    }

    listScoresPerThreatArea(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/lists/areas`);
    }

    pauseAssessment(assessmentId) {
        return this.post(`${this.baseUrl}/${assessmentId}/pauseAssessment`);
    }

    renameAssessment(assessmentId, data) {
        return this.put(`${this.baseUrl}/${assessmentId}/name`, data);
    }

    updateDue(assessmentId, data) {
        return this.put(`${this.baseUrl}/${assessmentId}/due`, data);
    }

    saveDraft(data) {
        return this.post(`${this.baseUrl}/draft`, data);
    }

    updateDraft(data) {
        return this.put(`${this.baseUrl}/draft/${data.id}`, data);
    }

    sendPreview(data) {
        return this.post(`${this.baseUrl}/sendPreview`, data);
    }

    sendReminders(assessmentId, params, body) {
        return this.post(
            buildURL(`${this.baseUrl}/${assessmentId}/reminders`, params),
            body
        );
    }

    answersReport(assessmentId, params, options) {
        return this.get(
            `${this.baseUrl}/${assessmentId}/answers`,
            params,
            options
        );
    }

    getThreatAreaDefinitions() {
        return this.get(`${this.baseUrl}/threatAreaDefinitions`);
    }

    questionsService(assessmentId) {
        return new Questions(this, assessmentId);
    }

    usersService(assessmentId) {
        return new Users(this, assessmentId);
    }

    bouncedEmailsService(assessmentId) {
        return new BouncedEmails(this, assessmentId);
    }

    recommendationsService(assessmentId) {
        return new Recommendations(this, assessmentId);
    }
}
