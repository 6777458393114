import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Heading2, Heading3, TextDefault, Block } from '@awarego/awarego-components';
import ThemedSpinner from '../../themed/ThemedSpinner';
import NoItemsYetContent from '../no-items-yet-content';


class NewsFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogList: null,
            fetchingData: true,
        };
    }

    async componentDidMount() {
        let contentObject = await this.getBlogContent();
        if (contentObject) {
            this.setState({ blogList: contentObject, fetchingData: false });
        }
    }

    async getBlogContent() {
        const url =
            'https://www.awarego.com/wp-json/wp/v2/posts/?per_page=3&_embed';

        try {
            let res = await fetch(url);

            if (!res.ok) {
                throw new Error(
                    `Failed to fetch: ${res.status} ${res.statusText}`
                );
            }

            return await res.json();
        } catch (error) {
            console.error('Error fetching blog content:', error);
            return [];
        }
    }

    render() {
        return (
            <div className="block">
                <Heading2 mt={8} displayBlock>
                    From our blog
                </Heading2>

                {this.state.fetchingData && <ThemedSpinner />}
                {!this.state.blogList ||
                (this.state.blogList && this.state.blogList.length == 0) ? (
                    <Block noBorder>
                        <NoItemsYetContent
                            img={'/static/img/icon-booklist.svg'}
                            imgAlt={'No posts found'}
                            title={'No posts found'}
                        />
                    </Block>
                ) : (
                    this.state.blogList.map((item, key) => (
                        <div className="item tutorial" key={key}>
                            {key === 0 &&
                                item._embedded &&
                                item._embedded['wp:featuredmedia'] &&
                                item._embedded['wp:featuredmedia']['0'] &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes.medium &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes.medium.source_url && (
                                    <a
                                        href={sanitizeHtml(item.link)}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <div className="image">
                                            <img
                                                src={sanitizeHtml(
                                                    item._embedded[
                                                        'wp:featuredmedia'
                                                    ]['0'].media_details.sizes
                                                        .medium.source_url
                                                )}
                                                alt=""
                                            />
                                        </div>
                                    </a>
                                )}
                            <Heading3 displayBlock mt={16} mb={8}>
                                <a
                                    href={sanitizeHtml(item.link)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                item.title.rendered
                                            ),
                                        }}
                                    ></div>
                                </a>
                            </Heading3>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(item.excerpt.rendered),
                                }}
                            ></div>
                            <div className="cta">
                                <a
                                    href={sanitizeHtml(item.link)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <TextDefault themed bold>
                                        Read more
                                    </TextDefault>
                                </a>
                            </div>
                            <hr />
                        </div>
                    ))
                )}
            </div>
        );
    }
}

export default NewsFeed;
