import React from 'react';
import { FlexDiv, palette, TextDefault } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import FallbackImage from '../../../content/content-image';

function RecommendationCard({
    id,
    item,
    title,
    thumbnail,
    type,
    fullWidthThumb,
    handleClick,
}) {
    return (
        <FlexDiv onClick={() => handleClick(id, item)} link>
            <FlexDiv
                column
                borderRadius={4}
                backgroundColor={palette.graphite.white}
                $noWrap
                fullWidth
                border
                mt={8}
            >
                {/* thumbnail */}
                <FallbackImage
                    src={thumbnail}
                    type={type}
                    fullWidthThumb={fullWidthThumb}
                />

                {/* body */}
                <FlexDiv
                    column
                    gap={16}
                    fullWidth
                    padding={16}
                    style={{
                        flexWrap: 'nowrap',
                        overflow: 'auto',
                    }}
                >
                    {title && (
                        <FlexDiv alignCenter fullWidth>
                            <TextDefault bold maxLineCount={1}>
                                {title}
                            </TextDefault>
                        </FlexDiv>
                    )}
                </FlexDiv>
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(RecommendationCard);
