import React, { useMemo } from 'react';
import { FlexDiv, palette, TextLead } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';

function ListReminderModal({
    employeesToBeSentReminders,
    remindersSentRecently,
    lists,
    listName,
}) {
    const listInfo = useMemo(() => {
        if (!lists) return;
        if (listName) return ` from the list "${listName}"`;
        if (lists.length > 0) return ` from ${lists.length} lists`;
    }, [listName, lists]);

    return (
        <FlexDiv column>
            <TextLead>
                {employeesToBeSentReminders}{' '}
                {pluralize('employee', employeesToBeSentReminders)}
                {listInfo}
                {employeesToBeSentReminders > 1
                    ? ", who haven't "
                    : ", who hasn't "}
                started the assessment, will be reminded
            </TextLead>
            {remindersSentRecently.length > 0 && (
                <TextLead color={palette.vibrant.red} mt={16}>
                    There was a reminder sent in the last 24h. Are you sure you
                    want to send it again?
                </TextLead>
            )}
        </FlexDiv>
    );
}

export default observer(ListReminderModal);
