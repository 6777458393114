import React, { Fragment, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import UserCampaignDetails from '../../containers/users/components/UserCampaignDetails';
import MailLog from './MailLog';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { STATUS, TRANSPORTS } from '../../utils/constants';
import { isEnumSet } from '../../utils/helpers';

function AutomationUserDetails({ companyId }) {
    const { automationStore, commonStore, authStore } = useStores();
    const { id, email } = useParams();

    const {
        automationUserStatus,
        loadingAutomationUserDetails,
        editingAutomation,
        loadingAutomation,
    } = automationStore;

    const { currentUser, anonymousTracking } = authStore;

    const transport = useMemo(() => {
        let channelNamesArray = [];
        if (!automationUserStatus) return;
        if (!Object.values(automationUserStatus.trainings)[0]) return;
        if (
            isEnumSet(
                Object.values(automationUserStatus.trainings)[0]
                    .reminderTransport,
                TRANSPORTS.EMAIL
            )
        )
            channelNamesArray.push('Email');
        if (
            isEnumSet(
                Object.values(automationUserStatus.trainings)[0]
                    .reminderTransport,
                TRANSPORTS.SLACK
            )
        )
            channelNamesArray.push('Slack');
        if (
            isEnumSet(
                Object.values(automationUserStatus.trainings)[0]
                    .reminderTransport,
                TRANSPORTS.TEAMS
            )
        )
            channelNamesArray.push('MS Teams');
        return channelNamesArray;
    }, [automationUserStatus]);

    const doLoadData = useCallback(async () => {
        await automationStore.loadUserDetails(companyId, id, email);
    }, [companyId, id, email, automationStore]);
    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const loadAutomation = useCallback(async () => {
        await automationStore.loadAutomation(companyId, id);
    }, [companyId, id, automationStore]);
    useEffect(() => {
        !editingAutomation && loadAutomation();
    }, [editingAutomation, loadAutomation]);

    const onSendReminder = async () => {
        commonStore.showConfirm(
            `A reminder will be sent to ${transport.join(', ')}.`,
            'Proceed',
            `Remind ${email}`,
            async () =>
                await automationStore.resendEventMail(
                    companyId,
                    id,
                    automationUserStatus.subjects[0].eventId,
                    email
                ),
            false
        );
    };

    return !loadingAutomationUserDetails && automationUserStatus ? (
        <Fragment>
            <Link to="../../view" relative="path">
                <div className={'navigation back-button'}>Back</div>
            </Link>
            <UserCampaignDetails
                userDetails={automationUserStatus}
                anonymousTracking={anonymousTracking}
                onSendReminder={onSendReminder}
                email={email}
                canRemind={
                    editingAutomation &&
                    (editingAutomation.status === STATUS.ACTIVE ||
                        editingAutomation.status === STATUS.COMPLETED)
                }
                loadingCanRemind={loadingAutomation}
            />
            {currentUser.super_admin && automationUserStatus.log ? (
                <MailLog records={automationUserStatus.log} />
            ) : null}
        </Fragment>
    ) : (
        <ThemedSpinner />
    );
}

export default observer(AutomationUserDetails);
