import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CompanyAppearance from './CompanyAppearance';
import SettingsContainer from './Settings';

export default ({ companyId }) => {
    return (
        <Routes>
            <Route path="company/appearance" element={<CompanyAppearance />} />

            <Route
                path="*"
                element={<SettingsContainer companyId={companyId} />}
            />
        </Routes>
    );
};
