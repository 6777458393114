import React from 'react';
import {
    TextDefault,
    palette,
    FlexDiv,
    ThemedButton,
} from '@awarego/awarego-components';

const CallToActionBanner = ({
    isPartnerCompany,
    supportEmail,
    contentType,
    redirectToUpgrade,
}) => {
    return (
        <FlexDiv
            py={16}
            px={40}
            backgroundColor={palette.vibrant.yellow}
            alignCenter
            gap={16}
        >
            <img src="/static/img/lock-on-circle-icon.svg" alt="Unavailable" />
            <FlexDiv column flexGrow={1}>
                <TextDefault bold>
                    This content is unavailable in your plan
                </TextDefault>
                {contentType === 'question' ? (
                    <TextDefault>
                        Please upgrade so you can add this question to your
                        assessment.
                    </TextDefault>
                ) : (
                    <TextDefault>
                        Please upgrade so you can add this video to your
                        training.
                    </TextDefault>
                )}
            </FlexDiv>
            {isPartnerCompany ? (
                <a href={`mailto:${supportEmail}`}>
                    <ThemedButton
                        size="small"
                        mode="black"
                        text="Contact to upgrade"
                        $noTextTransform
                    />
                </a>
            ) : (
                <ThemedButton
                    size="small"
                    mode="black"
                    text="Upgrade now"
                    $noTextTransform
                    onClick={redirectToUpgrade}
                />
            )}
        </FlexDiv>
    );
};

export default CallToActionBanner;
