import React from 'react';
import { observer } from 'mobx-react';
import {
    FlexDiv,
    SpaceBetweenDiv,
    Block,
    Image,
    TextDefault,
    TextLead,
    Heading2,
    palette,
    ThemedButton,
} from '@awarego/awarego-components';
import {ButtonBase} from "@mui/material";
import Link from "@mui/material/Link";

function CompanyAppearanceEditor({
    onEditLogo,
    onEditColor,
    onResetToDefaults,
    company_id,
    company,
}) {
    return (
        <FlexDiv>
            <Block>
                <FlexDiv column>
                    <Heading2>Customize appearance</Heading2>
                    <TextDefault>
                        Change the appearance of {company.company_name} for
                        employees (employee view).
                    </TextDefault>
                </FlexDiv>
                <SpaceBetweenDiv bottomBorder py={16} mt={8}>
                    <FlexDiv column mr={16}>
                        <TextLead bold>Company Logo</TextLead>
                        <TextDefault>
                            Set a custom logo that will be shown on employee
                            view when they sign in.
                        </TextDefault>
                    </FlexDiv>
                    <ButtonBase
                        onClick={() => onEditLogo(company_id)}
                        disableRipple={true}
                        style={{flexDirection: 'column'}}
                    >
                        {company.logo_name ? (
                            <Image
                                width={150}
                                src={
                                    import.meta.env.REACT_APP_RESOURCES_URL +
                                    '/' +
                                    import.meta.env
                                        .REACT_APP_COMPANY_RESOURCES_DIR +
                                    '/' +
                                    company.logo_name
                                }
                                alt="Your logo"
                            />
                        ) : (
                            <Image
                                width={50}
                                src="/static/img/logo-image.svg"
                                alt="Add your logo"
                            />
                        )}
                        <TextDefault themed bold>
                            Change
                        </TextDefault>
                    </ButtonBase>
                </SpaceBetweenDiv>
                <SpaceBetweenDiv bottomBorder py={16} mb={24}>
                    <FlexDiv column mr={16}>
                        <TextLead bold>Color</TextLead>
                        <TextDefault>
                            Change the color of main buttons and links to your
                            brand color.
                        </TextDefault>
                    </FlexDiv>
                    <ButtonBase
                        onClick={() => onEditColor(company_id)}
                        disableRipple={true}
                        style={{flexDirection: 'column'}}
                    >
                        <FlexDiv
                            backgroundColor={
                                company.color_main || palette.evergreen.aspargus
                            }
                            width={32}
                            minHeight={32}
                            border
                        />
                        <TextDefault themed bold>
                            Change
                        </TextDefault>
                    </ButtonBase>
                </SpaceBetweenDiv>

                <Link
                    component="button"
                    onClick={() => onResetToDefaults(company_id)}
                >
                    <TextDefault
                        error
                        bold
                    >
                        Reset to default
                    </TextDefault>
                </Link>
            </Block>
            <Block ml={20}>
                <Heading2>Preview</Heading2>
                <TextDefault>
                    This is how buttons will look in the employee view:
                </TextDefault>
                <FlexDiv marginAuto column>
                    <FlexDiv mb={8}>
                        <ThemedButton
                            color={company.color_main}
                            mode="primary"
                            text="Button"
                        />
                    </FlexDiv>
                    <ThemedButton
                        color={company.color_main}
                        mode="secondary"
                        text="Button"
                    />
                </FlexDiv>
            </Block>
        </FlexDiv>
    );
}

export default observer(CompanyAppearanceEditor);
