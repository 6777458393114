import MuiAlert from '@mui/material/Alert';
import withStyles from '@mui/styles/withStyles';
import { palette } from '@awarego/awarego-components';

const Alert = withStyles(() => ({
    root: {
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: '600',
        fontSize: '14px',
        marginTop: '16px',
        marginBottom: '16px',
    },

    standardSuccess: {
        borderColor: palette.lightroom.aspargusLightHigh,
        color: palette.evergreen.aspargus,
    },

    standardError: {
        borderColor: palette.lightroom.redLightHigh,
        color: palette.vibrant.red,
    },

    message: {
        '& a': {
            textDecoration: 'underline',
        },
    },
}))(MuiAlert);

export default Alert;
