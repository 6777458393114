import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ThemedDropdownItem } from '../../components/themed/ThemedComponents';
import Setting from './Setting';
import SettingSelect from './SettingSelect';
import CompanyEditContainer from '../companies/CompanyEditor';
import Modal from 'react-modal';
import { useStores } from '../../hooks/use-stores';
import {
    Block,
    FlexDiv,
    Image,
    SpaceBetweenDiv,
    TextDefault,
    Heading2,
} from '@awarego/awarego-components';

export default observer(() => {
    const { store, commonStore, companyStore, authStore } = useStores();
    const { token, currentUser } = authStore;

    let navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);

    const toggleCompanyDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const openEditModal = () => {
        setShowEditCompanyModal(true);
    };

    const closeEditModal = () => {
        setShowEditCompanyModal(false);
    };

    const onCustomizeAppearance = () => {
        navigate('appearance');
    };

    const onChangeLanguage = async (languageKey) => {
        store.currentCompany.defaultLanguage = languageKey;
        await store.saveCurrentCompany({
            ...{ defaultLanguage: languageKey },
        });
    };

    const downloadCertificate = () => {
        store.downloadPreviewCertificate(
            store.currentCompany.company_id,
            currentUser.name,
            token
        );
    };

    const onMakeAnonymous = () => {
        commonStore.showConfirm(
            "Please note that once user logging has been disabled, you can't enable it again later. All previously collected data will be made anonymous.",
            'Disable user logging',
            'Are you sure you want to proceed?',
            async () => {
                await companyStore.switchTrackingAnonymous(
                    store.currentCompany.company_id
                );
                await store.saveCurrentCompany({});
            },
            true
        );
    };

    const onChangeNewEmployeeEnrolment = async () => {
        let value =
            store.currentCompanyUISettings['ui.sendEnrolment'] === '1'
                ? '0'
                : '1';
        await companyStore.saveEnrolmentSetting(
            store.currentCompany.company_id,
            value
        );
    };

    const createOptionsArray = (languages) => {
        let optionsArray = [];
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
            });
        }
        return optionsArray;
    };

    const onChangeCompany = async (id) => {
        await store.setCurrentCompany(id);
    };

    const { serverData, currentCompany, currentCompanyUISettings, languages } =
        store;
    const hasAnonymousLogging = currentCompany.anonymousTracking;

    return (
        <Block>
            <SpaceBetweenDiv>
                <FlexDiv column>
                    <FlexDiv alignCenter>
                        <Heading2 mb={4}>
                            {currentCompany && currentCompany.company_name}
                        </Heading2>
                        {serverData.length > 1 && (
                            <FlexDiv ml={4} mr={8} link>
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleCompanyDropdown}
                                >
                                    <DropdownToggle caret tag="span" />
                                    <DropdownMenu>
                                        {serverData.map((company) => {
                                            if (
                                                company.company_name !==
                                                currentCompany.company_name
                                            )
                                                return (
                                                    <ThemedDropdownItem
                                                        key={company.company_id}
                                                        onClick={() =>
                                                            onChangeCompany(
                                                                company.company_id
                                                            )
                                                        }
                                                    >
                                                        {company.company_name}
                                                    </ThemedDropdownItem>
                                                );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </FlexDiv>
                        )}
                    </FlexDiv>
                    <TextDefault lighter>Manage company settings</TextDefault>
                </FlexDiv>
                {currentCompany.logo_name ? (
                    <Image
                        link
                        src={
                            import.meta.env.REACT_APP_RESOURCES_URL +
                            '/' +
                            import.meta.env.REACT_APP_COMPANY_RESOURCES_DIR +
                            '/' +
                            currentCompany.logo_name
                        }
                        height={64}
                        alt="Your logo"
                        onClick={onCustomizeAppearance}
                    />
                ) : (
                    <Image
                        link
                        src="/static/img/logo-image.svg"
                        alt="Add your logo"
                        height={64}
                        onClick={onCustomizeAppearance}
                    />
                )}
            </SpaceBetweenDiv>

            <Setting
                name="Name"
                value={currentCompany.company_name}
                actionName="Change"
                action={openEditModal}
            />
            <Setting
                name="Appearance"
                actionName="Customize"
                action={onCustomizeAppearance}
            />
            {(currentCompanyUISettings['ui.certificates_enabled'] !== '0' ||
                !currentCompany.settings) && (
                <Setting
                    name="Certificates"
                    actionName="Preview"
                    action={downloadCertificate}
                />
            )}

            <SettingSelect
                name="Default language for employees"
                options={createOptionsArray(languages)}
                defaultValue={currentCompany.defaultLanguage || 'en'}
                action={onChangeLanguage}
            />
            <Setting
                name="User logging"
                value={hasAnonymousLogging ? 'Disabled' : 'Enabled'}
                actionName={hasAnonymousLogging ? '' : 'Disable'}
                action={onMakeAnonymous}
                helpText="PLEASE NOTE: Once user logging is disabled, you cannot enable it again."
                helpId={'PopoverUserLogging' + currentCompany.company_id}
            />
            <Setting
                name="Send enrolment email to new employees"
                value={
                    currentCompanyUISettings['ui.sendEnrolment'] === '1'
                        ? 'Enabled'
                        : 'Disabled'
                }
                actionName={
                    currentCompanyUISettings['ui.sendEnrolment'] === '1'
                        ? 'Disable'
                        : 'Enable'
                }
                action={onChangeNewEmployeeEnrolment}
                subtext="When Enabled, if a new employee is assigned to a list with a training, they get sent a welcome email with a sign in link"
                isLast={true}
            />
            <Modal
                isOpen={showEditCompanyModal}
                onRequestClose={closeEditModal}
                className={'Modal auto-size-modal'}
                ariaHideApp={false}
            >
                <CompanyEditContainer
                    company={currentCompany}
                    companyId={currentCompany.company_id}
                    fromNewSettings={true}
                    onSaved={closeEditModal}
                    onRequestClose={closeEditModal}
                />
            </Modal>
        </Block>
    );
});
