import React from 'react';
import {
    FlexDiv,
    Heading3,
    palette,
    StatusBadge,
    TextTiny,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import FallbackImage from './content-image';

function ContentCard({
    id,
    item,
    title,
    text,
    thumbnail,
    tags,
    type,
    fullWidthThumb,
    handleClick,
}) {
    return (
        <FlexDiv onClick={() => handleClick(id, item)} link>
            <FlexDiv
                column
                borderRadius={4}
                backgroundColor={palette.graphite.white}
                $noWrap
                fullWidth
            >
                {/* thumbnail */}
                <FallbackImage
                    src={thumbnail}
                    type={type}
                    fullWidthThumb={fullWidthThumb}
                />

                {/* body */}
                <FlexDiv
                    column
                    gap={16}
                    fullWidth
                    padding={16}
                    pb={32}
                    style={{
                        flexWrap: 'nowrap',
                        overflow: 'auto',
                    }}
                >
                    <FlexDiv
                        alignCenter
                        fullWidth
                        gap={4}
                        height={24} //height of a single badge
                        overflowHidden
                    >
                        {tags &&
                            tags.map((tag) => (
                                <StatusBadge
                                    key={tag}
                                    color={palette.graphite.ash}
                                    text={tag}
                                />
                            ))}
                    </FlexDiv>
                    {title && (
                        <FlexDiv alignCenter fullWidth>
                            <Heading3 maxLineCount={1}>{title}</Heading3>
                        </FlexDiv>
                    )}
                    {text && (
                        <TextTiny
                            lighter
                            maxLineCount={2}
                            minHeight={39} //height of 2 lines
                        >
                            {text}
                        </TextTiny>
                    )}
                </FlexDiv>
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(ContentCard);
