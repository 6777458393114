import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const PrivateRoute = ({ children, notLoggedUrl, authStore }) => {
    const location = useLocation();

    const isLoggedIn =
        authStore.isAuthenticated === true && authStore.token != null;

    if (!notLoggedUrl) {
        notLoggedUrl = `/auth/signin?redirect=${location.pathname}${location.search}`;
    }

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate replace={true} to={notLoggedUrl} />
    );
};

export default inject('authStore')(observer(PrivateRoute));
