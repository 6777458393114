import React from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { querystring } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';

const AuthRoute = ({
    children,
    //...rest
}) => {
    const { authStore } = useStores();

    let redirect = querystring('redirect') || '/';
    if (!redirect.startsWith('/')) redirect = `/${redirect}`;

    return !authStore.isAuthenticated ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to={redirect}
            //state={{ from: `${location.pathname}${location.search}` }}
        />
    );
};

export default observer(AuthRoute);
