import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Button } from 'reactstrap';
import FormDef from '../../forms/billing';
import Billing from '../../components/forms/billing';
import Summary from './summary';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Heading1 } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

function BillingForm({ company, onComplete, onCancel, onCouponEntered }) {
    const { authStore, subscriptionStore } = useStores();

    const onSuccess = async () => {
        onComplete(localStore._form.values());
    };

    const localStore = useLocalObservable(() => {
        let _form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess,
                },
            }
        );

        let name = authStore.currentUser.name || '';

        _form.update(
            (subscriptionStore && subscriptionStore.billingInfo) ||
                (company && company.billingInfo) || {
                    email: authStore.currentUser.email,
                    firstName: name.split(' ')[0],
                    lastName: name.split(' ')[1],
                }
        );

        _form.validate();

        _form.observe({
            path: 'coupon',
            key: 'value', // can be any field property
            call: ({ field }) => {
                onCouponEntered(field.value);
            },
        });

        _form.observe({
            path: 'email',
            key: 'value', // can be any field property
            call: () => {
                _form.validate();
            },
        });

        _form.observe({
            path: 'firstName',
            key: 'value', // can be any field property
            call: () => {
                _form.validate();
            },
        });

        _form.observe({
            path: 'lastName',
            key: 'value', // can be any field property
            call: () => {
                _form.validate();
            },
        });

        return { _form };
    });

    const {
        estimating,
        couponError,
        price,
        futureCredit,
        nextPlanPrice,
        nextBilling,
        my,
        planPeriod,
        selectedTier,
    } = subscriptionStore;
    let canProceed = localStore._form.isValid && !estimating && !couponError;
    return (
        <div className={'wizard-fullscreen lightbox-like'}>
            <div className={'wizard-fullscreen-header'}>
                <div className={'wizard-fullscreen-close'} onClick={onCancel}>
                    <img src="/static/img/close.svg" alt="Close" />
                </div>
                <Heading1>Your details</Heading1>
            </div>
            <div className={'wizard-fullscreen-body'}>
                <div className="d-flex flex-fill align-items-center justify-content-center">
                    <Billing
                        form={localStore._form}
                        couponError={couponError}
                    />
                </div>
            </div>
            <div className={'wizard-fullscreen-footer'}>
                <div className={'actions center'}>
                    <div className="d-flex flex-row align-items-center price">
                        <strong>Summary:</strong>
                        <div className="d-flex flex-column">
                            {estimating ? (
                                <ThemedSpinner size={35} />
                            ) : (
                                <Summary
                                    {...{
                                        price,
                                        futureCredit,
                                        nextPlanPrice,
                                        nextBilling,
                                        my,
                                        planPeriod,
                                    }}
                                />
                            )}
                            {selectedTier && (
                                <div className="price-description">
                                    {selectedTier.starting_unit}-
                                    {selectedTier.ending_unit || 500} employees
                                </div>
                            )}
                        </div>
                    </div>
                    <Button
                        color="primary"
                        disabled={!canProceed}
                        onClick={localStore._form.onSubmit}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default observer(BillingForm);
