import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Heading3 } from '@awarego/awarego-components';

function PersonalizeForm({ ongoing, form, editable }) {
    return (
        <Fragment>
            {editable ? (
                <div className={'form'}>
                    {Object.values(form.fields.toJSON()).map(([, field]) => {
                        return (
                            <div key={field.key} className="form-holder">
                                <p>
                                    <strong>{field.label}</strong>
                                </p>
                                <input
                                    className={
                                        field.isValid
                                            ? 'bigInput valid'
                                            : 'bigInput dark'
                                    }
                                    {...field.bind()}
                                    disabled={ongoing}
                                />
                                <div className="error">{field.error}</div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={'training-wizard-content block center'}>
                    <img
                        src="/static/img/icon-checkmark-gray.svg"
                        alt="No additional input required"
                    />
                    <div className="stats">
                        <Heading3 displayBlock mt={16} mb={8}>
                            No additional input required
                        </Heading3>
                        <p>
                            The set of questions you've selected doesn't require
                            any additional personalization
                        </p>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default observer(PersonalizeForm);
