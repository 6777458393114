import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import { FlexDiv } from '@awarego/awarego-components';

function SubjectNoteEditor({ initialValue, onChange }) {
    const [value, setValue] = useState(
        RichTextEditor.createValueFromString(initialValue || '', 'html') ||
            RichTextEditor.createEmptyValue()
    );

    const handleChange = (value) => {
        setValue(value);
        if (onChange) onChange(value.toString('html'));
    };

    return (
        <FlexDiv mb={24} justifyCenter>
            <RichTextEditor value={value} onChange={handleChange} />
        </FlexDiv>
    );
}

export default SubjectNoteEditor;
