import React from 'react';
import {
    FlexDiv,
    TextDefault,
    TextLabel,
    palette,
} from '@awarego/awarego-components';
import moment from 'moment-timezone';
import { rgba } from 'polished';
import { observer } from 'mobx-react';

function EventDate({ e, selectedDate }) {
    const getDateBackroundColor = (e) => {
        if (moment(e.date).isSame(moment(selectedDate), 'day'))
            return palette.evergreen.aspargus;
        if (moment(e.date).isSame(moment(), 'day'))
            return rgba(palette.evergreen.aspargus, 0.1);
        return 'white';
    };

    const getDateTextColor = (e) => {
        if (moment(e.date).isSame(moment(selectedDate), 'day')) return 'white';
        if (moment(e.date).isSame(moment(), 'day'))
            return palette.evergreen.aspargus;
        return palette.graphite.heavyMetal;
    };

    return (
        <FlexDiv pr={8} borderRight>
            <FlexDiv
                column
                borderRadius={100}
                width={48}
                height={48}
                alignCenter
                justifyCenter
                backgroundColor={getDateBackroundColor(e)}
            >
                <TextLabel extraBold color={getDateTextColor(e)}>
                    {moment(e.date).format('ddd').toUpperCase()}
                </TextLabel>
                <TextDefault color={getDateTextColor(e)} bold>
                    {moment(e.date).format('D')}
                </TextDefault>
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(EventDate);
