import React from 'react';
import { Heading3, palette, TextDefault } from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';

function ThreatAreaHeader({ title, totalDuration, subjectsByAreaCount }) {
    return (
        <>
            <Heading3>{title}</Heading3>
            <TextDefault>
                {subjectsByAreaCount} training{' '}
                {pluralize('subject', subjectsByAreaCount)}
                <TextDefault color={palette.graphite.charcoal}>
                    <span
                        style={{
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        }}
                    >
                        |
                    </span>
                    {totalDuration} {pluralize('minute', totalDuration)}{' '}
                </TextDefault>
            </TextDefault>
        </>
    );
}

export default observer(ThreatAreaHeader);
