import React from 'react';
import { TextTiny } from '@awarego/awarego-components';

/** A simple separator used in Headers' Sub-headings */
function SubHeaderSeparator({ separator = '|' }) {
    return (
        <TextTiny lineHeight={'initial'} lighter semiBold>
            {separator}
        </TextTiny>
    );
}

export default SubHeaderSeparator;
