import { observer } from 'mobx-react';
import { FlexDiv, TextLead } from '@awarego/awarego-components';
import React from 'react';

function AddCompanyConfirm({ partnerEstimate, companyForm }) {
    return (
        <FlexDiv column>
            <TextLead mb={16}>
                You will be charged $
                {partnerEstimate && partnerEstimate.newCompanyTotal} per month
                for a total of {companyForm && companyForm.values().seats} seats
                for {companyForm && companyForm.values().companyName}.
            </TextLead>
            <TextLead>
                By creating this company, your total cost including all
                companies will be updated to $
                {partnerEstimate &&
                    partnerEstimate.newPartnerTotal &&
                    partnerEstimate.newPartnerTotal}
                .
            </TextLead>
        </FlexDiv>
    );
}

export default observer(AddCompanyConfirm);
