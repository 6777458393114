import React from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import { observer, useLocalObservable } from 'mobx-react';

const LessonsDeliveredDonut = ({ lessonsDelivered, lessonsTotal }) => {
    const localStore = useLocalObservable(() => ({
        get chartOptions() {
            return {
                chart: {
                    type: 'pie',
                    height: 200,
                    width: 200,
                    backgroundColor: {
                        linearGradient: [0, 0, 0, 500],
                        stops: [
                            [0, 'rgb(253, 254, 255)'],
                            [1, 'rgb(253, 254, 255)'],
                        ],
                    },
                    dataLabels: {
                        format: '{point.name}: {y} %',
                        enabled: false,
                    },
                },
                title: {
                    verticalAlign: 'middle',
                    floating: true,
                    text: `${lessonsDelivered} / ${lessonsTotal} `,
                },
                colors: ['#A5DD9B', '#51829B', '#F6995C'],
                tooltip: {
                    valueSuffix: '%',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },

                credits: {
                    enabled: false,
                },
                colorByPoint: true,
                cursor: 'pointer',
                series: [
                    {
                        innerSize: '74%',
                        data: [
                            {
                                name: 'Delivered',
                                y: lessonsDelivered,
                                color: '#20A26B',
                            },
                            {
                                name: 'Not delivered',
                                y:  lessonsTotal- lessonsDelivered,
                                color: '#EDF8F3',
                            },
                        ],
                    },
                ],
            };
        },
    }));

    // Data for the chart

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={localStore.chartOptions}
            />
        </div>
    );
};

export default observer(LessonsDeliveredDonut);
