import React from 'react';
import GenericInsight from './GenericInsight';
import { calculateScoreTitle } from '../../../../utils/helpers';

function SingleThreatAreaInsight({ insight, onAction }) {
    return (
        <GenericInsight
            heading={`The average employee score in "${
                insight.context.name
            }" is ${calculateScoreTitle(insight.context.averageScore)}`}
            text={`${insight.context.employeesPercentage}% of employees are identified as Risky or Very Risky in this threat area`}
            actionName="View details"
            insight={insight}
            onAction={onAction}
        />
    );
}

export default SingleThreatAreaInsight;
