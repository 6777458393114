import { makeAutoObservable } from 'mobx';
import {
    getRRule,
    convertUTCWeekDayToLocal,
    convertUTCHourToLocalHour,
} from '../../containers/reports/reports-helpers';
import { SCHEDULE_DAYS, SCHEDULE_DAY_POS } from '../../utils/constants';
import { RRule } from 'rrule';

class Report {
    selectedUserListsIds = [];
    lists = [];
    allUserLists = false;
    date = null;
    name = 'Untitled report';
    id = null;
    recipients = null;
    customEmailSubject = null;

    rruleString = null;
    rruleObject = null;

    frequency = null;
    localWeekDay = null;
    localHourOfDay = 7;
    localHourOfDayString = '07:00 GMT';
    weekdayPosition = 1;

    utcWeekDay = null;
    utcHourOfDay = 7;

    frequencyText = '-';

    constructor(reportData, companyId) {
        makeAutoObservable(this);
        this.companyId = companyId;

        if (reportData) {
            this._rawData = reportData.id ? reportData : null;
            this.name = reportData.name;
            this.id = reportData.id;

            //----------------------------------------------
            //---- Coming from the form (New)
            if (reportData.allEmployees) {
                this.allUserLists = true;
            } else {
                this.selectedUserListsIds = reportData.employeeLists;
            }
            if (reportData.scheduleFrequency) {
                this.rruleObject = getRRule({
                    frequency: reportData.scheduleFrequency,
                    weekDay: reportData.scheduleWeekday,
                    hourOfDay: reportData.scheduleHour,
                    weekdayPosition: reportData.scheduleWeekdayPosition,
                });
                this.rruleString = this.rruleObject.toString();
            }
            if (reportData.scheduleFrequency) {
                this.frequency = reportData.scheduleFrequency;
                this.localWeekDay = reportData.scheduleWeekday;
                this.localHourOfDay = reportData.scheduleHour;
                this.weekdayPosition = reportData.scheduleWeekdayPosition;
            }
            this.recipients =
                reportData.recipientsEmails &&
                reportData.recipientsEmails.replace(/\s/g, '').split(/[;,]/);
            this.customEmailSubject = reportData.customEmailSubject;

            //----------------------------------------------
            //------ Coming from the server (Edit)
            if (reportData.definition) {
                if (reportData.definition.listIds === 'all') {
                    this.allUserLists = true;
                } else {
                    this.selectedUserListsIds = reportData.definition.listIds;
                }
                this.customEmailSubject = reportData.definition.emailSubject;
                this.recipients =
                    reportData.definition.recipients &&
                    reportData.definition.recipients.join(', ');
            }
            if (reportData.rrule) {
                this.rruleObject = RRule.fromString(reportData.rrule);
                this.resolveRRuleObject(this.rruleObject);
                this.rruleString = reportData.rrule;
            }
            this.lists = reportData.lists;

            if (this.rruleObject) {
                this.setFrequencyText(this.rruleObject);
            }
        }
    }

    resolveRRuleObject = (rruleObject) => {
        let freq = RRule.FREQUENCIES[rruleObject.origOptions.freq];
        if (freq === 'WEEKLY' && rruleObject.origOptions.interval === 2) {
            freq = 'BIWEEKLY';
        }
        this.frequency = freq;
        //This is stored in UTC
        this.utcWeekDay =
            SCHEDULE_DAYS[rruleObject.origOptions.byweekday[0].weekday].id;
        this.utcHourOfDay = rruleObject.origOptions.byhour;

        //Convert to local time to display
        this.localHourOfDayString = convertUTCHourToLocalHour(
            this.utcHourOfDay
        );
        this.localHourOfDay = extractHourFromTimeString(
            this.localHourOfDayString
        );

        this.localWeekDay = convertUTCWeekDayToLocal(
            this.utcWeekDay,
            this.utcHourOfDay,
            this.localHourOfDay
        );

        this.weekdayPosition = rruleObject.origOptions.bysetpos || 1;

        function extractHourFromTimeString(timeString) {
            const [hourString] = timeString.split(':');
            const hour = parseInt(hourString, 10);
            return hour;
        }
    };

    setName = (value) => {
        this.name = value;
    };

    setFrequencyText = (rrule) => {
        if (!rrule) return (this.frequencyText = '-');

        //--- Clone the rrule object to avoid changing the original
        const rruleObjectClone = rrule.clone();
        rruleObjectClone.origOptions.byweekday = RRule[this.localWeekDay];

        //--- Get a general text for the frequency
        const rruleAsText = rruleObjectClone.toText();
        this.frequencyText =
            rruleAsText.charAt(0).toUpperCase() + rruleAsText.slice(1);

        //--- If it's a monthly report, add the weekday position
        if (this.frequency === 'MONTHLY') {
            const weekdayPositionObject = SCHEDULE_DAY_POS.find(
                (pos) => pos.id === this.weekdayPosition
            );
            const weekdayPositionText = weekdayPositionObject
                ? weekdayPositionObject.name.toLowerCase()
                : this.weekdayPosition;

            this.frequencyText = this.frequencyText.replace(
                /(on\s)(\w+)/,
                `$1${' the ' + weekdayPositionText} $2`
            );
        }

        this.frequencyText =
            this.frequencyText + ' at ' + this.localHourOfDayString;
    };

    get link() {
        return `/reports/${this.id}`;
    }

    toDTO(extraData) {
        return {
            id: this.id && this.id !== '' ? this.id : null,
            name: this.name.replace('&nbsp;', ' '),
            definition: {
                listIds: this.allUserLists ? 'all' : this.selectedUserListsIds,
                emailSubject: this.customEmailSubject,
                recipients: this.recipients,
            },
            rrule: this.rruleString,
            ...extraData,
        };
    }
}

export default Report;
