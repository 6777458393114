import React from 'react';
import { observer } from 'mobx-react';
import RequireRole from '../containers/RequireRole';
import NotAuthorized from '../components/NotAuthorized';
import { USER_ROLE } from '../utils/constants';

function RoleRoute({ children, requiredRole = USER_ROLE.ADMIN }) {
    return (
        <RequireRole fallback={<NotAuthorized />} requiredRole={requiredRole}>
            <>{children}</>
        </RequireRole>
    );
}

export default observer(RoleRoute);
