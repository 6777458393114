import React, { Fragment } from 'react';
import {
    Heading3,
    TextDefault,
    TextTiny,
    FlexDiv,
    SpaceBetweenDiv,
    StatusBadge,
    palette,
    LightTooltip,
} from '@awarego/awarego-components';
import { observer } from 'mobx-react';
import VideoThumbnail from './video-thumbnail';
import ExclamationIcon from '@mui/icons-material/ReportProblemOutlined';
import { formatTime } from '../../../utils/helpers';
import { SUBJECT_TYPE } from '../../../utils/constants';
import pluralize from 'pluralize';

function VideoDescription({ previewVideo, isTrial, item }) {
    return (
        <FlexDiv gap={24} $noWrap flexGrow={1}>
            {/* thumbnail */}
            <VideoThumbnail
                previewVideo={previewVideo}
                isTrial={isTrial}
                item={item}
            />

            {/* mid part */}
            <FlexDiv column gap={8} fullWidth>
                <SpaceBetweenDiv>
                    {/* description heading */}
                    <FlexDiv alignCenter>
                        <Heading3 mr={4}>{item.title}</Heading3>
                        {((item.notes && item.notes.length > 0) ||
                            (item.trainings && item.trainings.length > 0)) && (
                            <LightTooltip
                                title={
                                    <Fragment>
                                        {item.notes && item.notes.length > 0 ? (
                                            <TextTiny bold>Modified</TextTiny>
                                        ) : (
                                            ''
                                        )}
                                        {item.trainings &&
                                        item.trainings.length > 0 ? (
                                            <FlexDiv column>
                                                <TextTiny bold>
                                                    Already included in:
                                                </TextTiny>
                                                {item.trainings.map((t, i) => (
                                                    <TextTiny key={i}>
                                                        • {t.name}
                                                    </TextTiny>
                                                ))}
                                            </FlexDiv>
                                        ) : (
                                            ''
                                        )}
                                    </Fragment>
                                }
                            >
                                <ExclamationIcon
                                    style={{
                                        color: palette.vibrant.orange,
                                    }}
                                    fontSize="small"
                                />
                            </LightTooltip>
                        )}
                    </FlexDiv>

                    {/* time & Interactive badge */}
                    <FlexDiv alignCenter gap={16} rowGap={4}>
                        {item.type === SUBJECT_TYPE.DECISION && (
                            <StatusBadge
                                text="Interactive"
                                color={palette.graphite.heavyMetal}
                                thinner
                            />
                        )}
                        {item.duration && item.type !== 4 ? (
                            <TextTiny bold>
                                {formatTime(item.duration)} min
                            </TextTiny>
                        ) : (
                            ''
                        )}
                        {item.type === SUBJECT_TYPE.SCORM_QUIZ ? (
                            <TextTiny bold $noWrap>
                                {item.questions_count}{' '}
                                {pluralize('question', item.questions_count)}{' '}
                                {formatTime(item.duration)}
                            </TextTiny>
                        ) : (
                            ''
                        )}
                    </FlexDiv>
                </SpaceBetweenDiv>
                {/* description */}
                <div style={{ maxHeight: '42px', overflow: 'hidden' }}>
                    <TextDefault lighter maxLineCount={2}>
                        {item.description}
                    </TextDefault>
                </div>
                {item.keyBehaviours ? (
                    <div style={{ marginTop: '-10px' }}>
                        <LightTooltip
                            title={item.keyBehaviours.map((kb) => (
                                <div key={kb.id}>• {kb.kbName}</div>
                            ))}
                        >
                            <TextTiny underline lighter link>
                                {item.keyBehaviours.length}{' '}
                                {pluralize(
                                    'key behaviour',
                                    item.keyBehaviours.length
                                )}
                            </TextTiny>
                        </LightTooltip>
                    </div>
                ) : null}
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(VideoDescription);
