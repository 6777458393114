import RestService from './RestService';

export default class ACL extends RestService {
    constructor() {
        super('/acl');
    }

    findPrincipal(value) {
        return this.get(`${this.baseUrl}/principal`, { value });
    }
}
