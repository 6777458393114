import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    cert: {
        label: 'Certificate (Base64)',
        rules: 'string|required',
    },
    appName: {
        label: 'Identifier (Entity ID)',
        rules: 'string|required',
    },
    domain: {
        label: 'Emails domain',
        rules: 'string|required',
    },
    tenant: {
        label: 'Tenant Id',
        rules: 'required|string',
    },
};

class AzureAdForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default AzureAdForm;
