import React from 'react';
import Upgrade from '../dashboard/upgrade';
import NoItemsYet from '../no-items-yet';
import { ThemedButton, SpaceBetweenDiv } from '@awarego/awarego-components';

function TrainingUpgrade({ upgradeAllowed, supportEmail, demoLink }) {
    return upgradeAllowed ? (
        <Upgrade
            heading={'Cybersecurity Awareness Training'}
            text={
                'Build security culture with behavior-changing training programs'
            }
            img={'/static/img/training-upgrade.svg'}
            imgAlt={'sneak peak into a training program'}
            upgradeLink={'/subscriptions/upgrade'}
            secondaryBtnLink={demoLink}
            secondaryBtnText={'Try demo'}
        />
    ) : (
        <NoItemsYet
            img={'/static/img/training-locked.svg'}
            imgAlt={'training programs are unavailable'}
            title={'Training programs are unavailable in your current plan'}
            text={
                'Please upgrade your subscription to access the training programs.'
            }
            heading={'Training programs'}
            buttons={
                <SpaceBetweenDiv gap={24}>
                    <ThemedButton
                        mode="primary"
                        text="Contact to upgrade"
                        as="a"
                        href={`mailto:${supportEmail}`}
                        flexGrow={1}
                        $center
                    />
                    <ThemedButton
                        as="a"
                        href={demoLink}
                        target="_blank"
                        rel="noreferrer"
                        mode="secondary"
                        text="Try demo"
                        flexGrow={1}
                        $center
                    />
                </SpaceBetweenDiv>
            }
        />
    );
}

export default TrainingUpgrade;
