import React, { Fragment } from 'react';
import { FlexDiv, SpaceBetweenDiv } from '@awarego/awarego-components';
import styled from 'styled-components';
import ResilienceScore from './resilience-score';
import CompletedAssessment from './completed-assessment';
import ResilienceScoreDistribution from './resilience-score-distribution';
import RiskInsights from './risk-insights';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/use-stores';
import RecommendationBanner from '../recommendation/RecommendationBanner';
import { useNavigate, useLocation } from 'react-router';

const HugeHeading = styled.h1`
    font-size: 56px;
    font-weight: 700;
    text-align: center;
    opacity: ${(props) => (props.lighter ? 0.6 : 1)};
`;

function Overview({
    editingAssessment,
    showReviewQuestionsModal,
    companyId,
    assessmentId,
    hasStarted,
    showInsights = true,
}) {
    const { brandingStore, assessmentEmployeesStoresFactory, store } =
        useStores();
    const location = useLocation();
    const navigate = useNavigate();

    const onBarClick = (index) => {
        const def = brandingStore.getScoreDefinitionByIndex(index);
        assessmentEmployeesStoresFactory.showEmployeesModal('users', {
            isFinished: true,
            minScore: def && def.from_score,
            maxScore: def && def.to_score,
            scoreName: 'Resilience score',
        });
    };

    const viewTraining = () => {
        navigate(location.pathname + '/recommendation');
    };

    return (
        <Fragment>
            <FlexDiv my={24}>
                {store.recommendationsEnabled &&
                    (editingAssessment.isScorm ||
                        editingAssessment.isCompleted) &&
                    store.isAdmin &&
                    editingAssessment.recommendations &&
                    editingAssessment.recommendations.videos && (
                        <RecommendationBanner
                            videos={editingAssessment.recommendations.videos}
                            onViewTraining={() => viewTraining()}
                        ></RecommendationBanner>
                    )}
            </FlexDiv>
            <SpaceBetweenDiv mt={20} gap={8} alignStretch>
                <FlexDiv column flexGrow={2} gap={8}>
                    <ResilienceScore
                        editingAssessment={editingAssessment}
                        score={editingAssessment.resilienceScore}
                        HugeHeading={HugeHeading}
                        headingText={'Resilience score'}
                        howIsThisCalculatedTooltip={
                            'The overall resilience score is the average resilience score of all employees who completed the assessment.'
                        }
                        scoreTooltip={
                            'To better understand the risk level of each score, these are descriptive grades for different score ranges:'
                        }
                        hasStarted={hasStarted}
                    />
                    <CompletedAssessment
                        editingAssessment={editingAssessment}
                        HugeHeading={HugeHeading}
                        hasStarted={hasStarted}
                        isScorm={editingAssessment.isScorm}
                    />
                </FlexDiv>
                <ResilienceScoreDistribution
                    flexGrow={3}
                    headingText={'Resilience score distribution'}
                    data={editingAssessment.resilienceScoreDistribution}
                    hasStarted={hasStarted}
                    onBarClick={onBarClick}
                />
            </SpaceBetweenDiv>
            {showInsights && (
                <RiskInsights
                    showReviewQuestionsModal={showReviewQuestionsModal}
                    companyId={companyId}
                    assessmentId={assessmentId}
                    threatAreaCount={editingAssessment.threatAreas.length}
                    hasStarted={hasStarted}
                />
            )}
        </Fragment>
    );
}

export default observer(Overview);
