import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Heading1, ThemedButton } from '@awarego/awarego-components';

function SaveChanges({
    onRequestClose,
    initiateSavingProgress,
    back,
    parentPath,
    editing,
    customBackUrl = null,
}) {
    const saveAndClose = () => {
        initiateSavingProgress();
    };

    return (
        <div className="modalWindow simple">
            <div className={'modal__header'}>
                <Heading1>Discard without saving changes?</Heading1>
                <img
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className="modal__text">
                You will lose all {editing ? 'changes' : 'entered data'} and we
                will not be able to recover it.
            </div>
            <div className={'modalFooter'}>
                <span
                    className="previous-btn"
                    style={{ marginRight: '20px' }}
                    onClick={saveAndClose}
                >
                    {editing ? 'Save and exit' : 'Save draft and exit'}
                </span>
                <Link to={customBackUrl ? customBackUrl : back || parentPath}>
                    <ThemedButton mode="gray" text="Yes, discard" />
                </Link>
            </div>
        </div>
    );
}

export default observer(SaveChanges);
