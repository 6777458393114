import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ReportDetails from './ReportDetails';
import { Container } from 'reactstrap';
import { useStores } from '../../../hooks/use-stores';
import ErrorMessage from '../../../components/errormessage';
import { Helmet } from 'react-helmet';

function ReportDetailsContainer() {
    const { reportsStore } = useStores();

    useEffect(() => {
        // Cleanup run on component unmount
        return () => {
            reportsStore.error = null;
            reportsStore.report = null;
            reportsStore.reportExecutions = [];
            reportsStore.reportExecutionsCount = 0;
        };
    }, []);

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Report</title>
            </Helmet>
            <Fragment>
                <Link to={`/reports`}>
                    <div className={'navigation back-button'}>
                        Back to reports
                    </div>
                </Link>

                <Fragment>
                    {reportsStore.error && (
                        <ErrorMessage error={reportsStore.error} />
                    )}
                    <ReportDetails />
                </Fragment>
            </Fragment>
        </Container>
    );
}

export default observer(ReportDetailsContainer);
