// the number of videos in recommendation is sometimes different than videosCount property. VideosCount property is the ideal number of videos.
// But if we don't have enough videos on certain KB/TA, the real count would be lower. Thats why we are not using the VideosCount prop here.
export function getSubjectsCount(videos) {
    let totalCount = 0;
    for (const key in videos) {
        if (Array.isArray(videos[key])) {
            totalCount += videos[key].length;
        }
    }

    return totalCount;
}

export function calculateTrainingDuration(subjectsCount, videosPerWeek) {
    const trainingDuration = Math.ceil(subjectsCount / videosPerWeek);

    return trainingDuration;
}
