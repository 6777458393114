import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';
import {
    Block,
    FlexDiv,
    Heading2,
    palette,
    TextDefault,
    TextTiny,
} from '@awarego/awarego-components';
import FallbackImage from '../content-image';
import { ThemedDropdownItem } from '../../../components/themed/ThemedComponents';
import { useStores } from '../../../hooks/use-stores';
import { formatDate } from '../../../utils/helpers';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import RequireRole from '../../RequireRole';
import { useNavigate } from 'react-router-dom';

function ScormContentHeader({ companyId, scormId, scorm, loadingData }) {
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const { commonStore, cmi5Store } = useStores();
    const navigate = useNavigate();

    const toggleExtraActionsDropdown = () => {
        setContextMenuOpen(!contextMenuOpen);
    };

    const onDeleteScormPackage = () => {
        commonStore.showConfirm(
            'Are you sure you want to remove this scorm package and all its language versions?',
            'Delete',
            'Delete Scorm Package',
            async () => {
                const success = await cmi5Store.deleteScormZipPackage(
                    companyId,
                    scormId
                );
                if (success) {
                    commonStore.success('Scorm package deleted!');
                    navigate('/content');
                }
            },
            true
        );
    };

    return (
        <Block>
            {loadingData ? (
                <ThemedSpinner />
            ) : (
                <FlexDiv>
                    <FlexDiv gap={8} ml={8}>
                        <FallbackImage
                            src={scorm.thumb}
                            fullWidthThumb={true}
                        />
                    </FlexDiv>
                    <FlexDiv justifyCenter ml={16} column flexGrow={2}>
                        <Heading2>{scorm.name}</Heading2>
                        <TextDefault color={palette.graphite.charcoal}>
                            {' '}
                            Created {formatDate(scorm.created)}{' '}
                        </TextDefault>
                    </FlexDiv>
                    <RequireRole>
                        <FlexDiv column justifyCenter>
                            <Dropdown
                                isOpen={contextMenuOpen}
                                toggle={toggleExtraActionsDropdown}
                            >
                                <DropdownToggle className="btn btn-three-dots" />
                                <DropdownMenu>
                                    <ThemedDropdownItem
                                        onClick={() => {
                                            onDeleteScormPackage();
                                        }}
                                        style={{ color: palette.vibrant.red }}
                                    >
                                        <TextTiny
                                            color={palette.vibrant.red}
                                            bold
                                        >
                                            Delete Scorm package
                                        </TextTiny>
                                    </ThemedDropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </FlexDiv>
                    </RequireRole>
                </FlexDiv>
            )}
        </Block>
    );
}

export default observer(ScormContentHeader);
