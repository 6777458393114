import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { IconButton, MenuItem } from '@mui/material';
import SelectChannel from '../wizard/select-channel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    CheckboxInput,
    FlexDiv,
    Heading3,
    TextDefault,
    TextInput,
    TextLead,
} from '@awarego/awarego-components';
import NumberChip from '../../NumberChip';
import {
    REMINDER_DELAY_TYPE,
    AUTOMATION_SCHEDULE_TYPE,
} from '../../../utils/constants';
import { getReminderDelayType } from '../../../utils/helpers';

function AutomationReminders({
    reminders_enabled,
    reminders_channel,
    reminders_steps,
    slackAvailable,
    teamsAvailable,
    slackConnected,
    teamsConnected,
    onChangeRemindersEnabled,
    onChangeRemindersChannel,
    onAddReminderStep,
    onDeleteReminderStep,
    scheduleType,
}) {
    return (
        <FlexDiv column mt={40}>
            <CheckboxInput
                checked={reminders_enabled}
                onChange={onChangeRemindersEnabled}
                name="sendReminders"
                label={
                    <TextLead bold>
                        Automatically send reminders after{' '}
                        {scheduleType === AUTOMATION_SCHEDULE_TYPE.FUTURE &&
                            'each '}
                        delivery
                    </TextLead>
                }
                noYPadding
            />
            {reminders_enabled && (
                <Fragment>
                    <FlexDiv column gap={16}>
                        {reminders_steps.length > 0 && (
                            <Heading3 mt={48}>Reminders</Heading3>
                        )}
                        {reminders_steps.map((x, i) => (
                            <FlexDiv
                                alignCenter
                                key={`reminder-option-${i}`}
                                gap={8}
                            >
                                <NumberChip size="small" label={i + 1} />
                                <TextDefault bold>Remind after</TextDefault>
                                <TextInput
                                    value={x.delay}
                                    onChange={(e) => {
                                        x.delay = Number(e.target.value);
                                    }}
                                    inline
                                    type="number"
                                    inputProps={{ min: 1, max: 1000 }}
                                />
                                <TextInput
                                    select
                                    inline
                                    name="delayType"
                                    value={x.delayType}
                                    onChange={(e) => {
                                        x.delayType = Number(e.target.value);
                                    }}
                                >
                                    {Object.values(REMINDER_DELAY_TYPE).map(
                                        (type) => (
                                            <MenuItem value={type} key={type}>
                                                {getReminderDelayType(
                                                    type,
                                                    x.delay
                                                )}
                                            </MenuItem>
                                        )
                                    )}
                                </TextInput>
                                {i > 0 && (
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            onDeleteReminderStep(x);
                                        }}
                                        size="large"
                                    >
                                        <DeleteForeverIcon
                                            fontSize="small"
                                            color="error"
                                        />
                                    </IconButton>
                                )}
                            </FlexDiv>
                        ))}
                        {reminders_steps.length < 5 && (
                            <TextDefault
                                themed
                                onClick={onAddReminderStep}
                                link
                                bold
                            >
                                + Add new reminder
                            </TextDefault>
                        )}
                    </FlexDiv>
                    <SelectChannel
                        channel={reminders_channel}
                        slackAvailable={slackAvailable}
                        teamsAvailable={teamsAvailable}
                        slackConnected={slackConnected}
                        teamsConnected={teamsConnected}
                        onChangeChannel={onChangeRemindersChannel}
                        isReminders={true}
                    />
                </Fragment>
            )}
        </FlexDiv>
    );
}

export default observer(AutomationReminders);
