import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import braintree from 'braintree-web';
import classes from 'classnames';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import Summary from './summary';
import ReactGA from 'react-ga';
import DropIn from '../../components/DropIn';
import { FlexDiv, Heading1 } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';

var modal = document.getElementById('modal');
var bankFrame = document.getElementById('bt-modal-body');
var closeFrame = document.getElementById('text-close');

function addFrame(err, iframe) {
    bankFrame.append(iframe);
    modal.removeClass('hidden');
}

function removeFrame() {
    var iframe = bankFrame.find('iframe');
    modal.addClass('hidden');
    iframe.remove();
}

function PaymentForm({ companyId, prevStep, onComplete }) {
    const [clientToken, setClientToken] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [instance, setInstance] = useState(null);

    const { subscriptionStore } = useStores();

    useEffect(() => {
        (async () => {
            const clientToken = await subscriptionStore.loadToken(companyId);
            setClientToken(clientToken);
        })();
    }, [companyId]);

    const buy = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Purchase',
            label: 'Button clicked',
        });

        // Send the nonce to your server
        let payload;
        setWaiting(true);
        try {
            payload = await instance.requestPaymentMethod({
                threeDSecure: instance.threeDSecure,
            });
        } catch (e) {
            setWaiting(false);
            console.error(e);
        }

        try {
            payload = await instance.threeDSecure.verifyCard({
                amount: subscriptionStore.price,
                nonce: payload.nonce,
                addFrame: addFrame,
                bin: payload.details.bin,
                removeFrame: removeFrame,
                onLookupComplete: (data, next) => {
                    setWaiting(false);
                    next();
                },
            });

            ReactGA.event({
                category: 'User',
                action: 'Purchase',
                label: 'Success',
            });

            onComplete(payload.nonce);
        } catch (e) {
            setWaiting(false);
            console.error(e);
            ReactGA.event({
                category: 'User',
                action: 'Purchase',
                label: 'Failed',
            });
        }
    };

    const instanceCreated = async (instanceArg) => {
        try {
            instanceArg.threeDSecure = await braintree.threeDSecure.create({
                version: 2, // Will use 3DS 2 whenever possible
                client: instanceArg._client,
            });
            closeFrame.click(function () {
                instanceArg.threeDSecure.cancelVerifyCard(removeFrame());
                //enablePayNow();
            });
            setInstance(instanceArg);
        } catch (e) {
            console.error(e);
        }
    };

    const {
        price,
        futureCredit,
        nextPlanPrice,
        nextBilling,
        my,
        planPeriod,
        payInProcess,
        selectedTier,
        error,
    } = subscriptionStore;

    if (!clientToken) {
        return (
            <div className={'wizard-fullscreen lightbox-like'}>
                <div className={'wizard-fullscreen-header'}>
                    <Heading1>Payment Details</Heading1>
                </div>
                <div className={'wizard-fullscreen-body'}>
                    <FlexDiv
                        fullHeight
                        fullWidth
                        alignCenter
                        justifyCenter
                        gap={16}
                        column
                    >
                        <Heading1 themed>One moment...</Heading1>
                        <ThemedSpinner />
                    </FlexDiv>
                </div>
                <div className={'wizard-fullscreen-footer'}>
                    <div className={'actions'}></div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={'wizard-fullscreen lightbox-like'}>
                <div className={'wizard-fullscreen-header'}>
                    <Heading1>Payment Details</Heading1>
                </div>
                <div
                    className={classes(
                        'wizard-fullscreen-body align-items-center justify-content-center flex-column',
                        { loading: waiting || payInProcess }
                    )}
                >
                    <div className="paymentError">
                        <ErrorMessage error={error} />
                    </div>

                    <DropIn
                        options={{
                            authorization: clientToken,
                            card: { cardholderName: true },
                        }}
                        onInstance={instanceCreated}
                    />

                    {(waiting || payInProcess) && (
                        <div className="wait-overlay d-flex align-items-center justify-content-center flex-fill flex-column">
                            <Heading1 themed>One moment...</Heading1>
                            <ThemedSpinner />
                        </div>
                    )}
                </div>

                <div className={'wizard-fullscreen-footer'}>
                    <div className={'actions center '}>
                        <Button color={'link'} size={'lg'} onClick={prevStep}>
                            Previous
                        </Button>

                        <div className="d-flex flex-row align-items-center price">
                            <strong>Summary:</strong>
                            <div className="d-flex flex-column">
                                <Summary
                                    {...{
                                        price,
                                        futureCredit,
                                        nextPlanPrice,
                                        nextBilling,
                                        my,
                                        planPeriod,
                                    }}
                                />

                                {selectedTier && (
                                    <div className="price-description">
                                        {selectedTier.starting_unit}-
                                        {selectedTier.ending_unit || 500}{' '}
                                        employees
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button color={'primary'} onClick={buy}>
                            Purchase
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentForm;
