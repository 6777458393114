import React from 'react';
import GenericInsight from './GenericInsight';

function LowestPerformingThreatAreaInsight({ insight, onAction }) {
    return (
        <GenericInsight
            heading={`Lowest performing threat area is "${insight.context.name}"`}
            text={`${insight.context.employeesPercentage}% of employees are identified as Risky or Very Risky in this threat area`}
            actionName="View details"
            insight={insight}
            onAction={onAction}
        />
    );
}

export default LowestPerformingThreatAreaInsight;
