import { BigModal } from '@awarego/awarego-components';
import React from 'react';
import { observer } from 'mobx-react';
import ReportSnapshotForm from '../forms/snapshot/ReportSnapshotForm';
import AddReportFooter from '../forms/ReportFormFooter';

function ReportSnapshotModal({ isOpen, onRequestClose, reportsForm, lists }) {
    return (
        <BigModal
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            heading="Generate snapshot"
            modalContent={
                <ReportSnapshotForm reportsForm={reportsForm} lists={lists} />
            }
            modalFooterContent={
                <AddReportFooter
                    reportsForm={reportsForm}
                    submitText={'Generate'}
                    onCancel={() => {
                        onRequestClose();
                    }}
                    onSubmit={() => {
                        reportsForm.submit();
                    }}
                />
            }
        />
    );
}

export default observer(ReportSnapshotModal);
