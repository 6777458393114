import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { formatDate } from '../../../utils/helpers';
import { SUBJECT_TYPE } from '../../../utils/constants';
import Box from '@mui/material/Box';
import {
    FlexDiv,
    ProgressWithNumber,
    SearchInput,
} from '@awarego/awarego-components';
import TableClient from '../../../components/table/TableClient';
function RemindersV2({ events, subject }) {
    let event = useMemo(() => {
        return events && events.find((x) => x.id === subject.eventId);
    }, [subject, events]);

    return event && event.reminders ? (
        <div>
            {event.reminders &&
                event.reminders.map((reminder, j) => (
                    <Box key={j}>{moment(reminder.send).format('MMMM D')}</Box>
                ))}
        </div>
    ) : null;
}

function SubjectsProgress({
    training,
    subjects,
    reminders,
    userCount,
    isPdfReport,
}) {
    const [searchValue, setSearchValue] = useState('');
    const rowsPerPage = 10;

    const columns = useMemo(
        () => [
            {
                Header: 'Delivery date',
                accessor: 'send',
                Cell: (x) => <span>{formatDate(x.value)}</span>,
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => <div className="row-title">{x.value}</div>,
            },
            {
                Header: 'Completion rate',
                accessor: 'score',
                Cell: (x) =>
                    x.row.original.type === SUBJECT_TYPE.SCORM_QUIZ ? (
                        <ProgressWithNumber
                            value={
                                x.row.original.questions_count
                                    ? Math.round(
                                          (100 * x.row.original.correctsStats) /
                                              (x.row.original.questions_count *
                                                  userCount)
                                      )
                                    : 0
                            }
                            width={150}
                            height={6}
                        />
                    ) : (
                        <ProgressWithNumber
                            value={
                                x.row.original.total
                                    ? Math.round(
                                          (100 * x.value) / x.row.original.total
                                      )
                                    : 0
                            }
                            width={150}
                            height={6}
                        />
                    ),
            },
            {
                Header: 'Reminders',
                Cell: (x) => (
                    <div>
                        {training && training.version === 1 ? (
                            reminders &&
                            Object.values(reminders).map((x, j) => (
                                <Box key={j}>{moment(x).format('MMMM D')}</Box>
                            ))
                        ) : (
                            <RemindersV2
                                subject={x.row.original}
                                events={training && training.events}
                            />
                        )}
                    </div>
                ),
                disableGlobalFilter: true,
            },
        ],
        [reminders, training, userCount]
    );

    return (
        <FlexDiv pt={16}>
            <FlexDiv flexGrow={1} justifyEnd my={10}>
                <SearchInput
                    label=""
                    placeholder="Search"
                    searchValue={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value || '');
                    }}
                    onClear={() => setSearchValue('')}
                />
            </FlexDiv>

            <TableClient
                columns={columns}
                data={subjects}
                initialPageSize={isPdfReport ? 1000 : rowsPerPage}
                hidePagination={
                    isPdfReport ||
                    (!isPdfReport && subjects && subjects.length <= rowsPerPage)
                }
                filterValue={searchValue}
            />
        </FlexDiv>
    );
}

export default SubjectsProgress;
