import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    Heading1,
    TextInput,
    ThemedButton,
    TextDefault,
    FlexDiv,
} from '@awarego/awarego-components';
import pluralize from 'pluralize';

function ChangeDuration({ onRequestClose, duration, saveChanges }) {
    const [minutes, setMinutes] = useState(duration);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveChanges(minutes);
        onRequestClose();
    };

    return (
        <div className="modalWindow simple">
            <form onSubmit={handleSubmit}>
                <div className={'modalHeader plain'}>
                    <Heading1>Change duration</Heading1>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent'} style={{ minHeight: '100px' }}>
                    <FlexDiv
                        gap={8}
                        justifyCenter
                        style={{ alignItems: 'baseline' }}
                    >
                        <TextInput
                            type="text"
                            id="duration"
                            name="duration"
                            value={minutes}
                            onChange={(e) => setMinutes(Number(e.target.value))}
                            autoFocus
                            inline
                            style={{ width: '50px' }}
                        />
                        <label htmlFor="duration">
                            {pluralize('minute', minutes)}
                        </label>
                    </FlexDiv>
                </div>
                <div className={'modalFooter'}>
                    <FlexDiv pr={40}>
                        <TextDefault
                            uppercase
                            extraBold
                            link
                            onClick={onRequestClose}
                        >
                            Cancel
                        </TextDefault>
                    </FlexDiv>
                    <ThemedButton
                        type="submit"
                        mode="primary"
                        disabled={minutes < 1}
                        text={'Confirm'}
                    />
                </div>
            </form>
        </div>
    );
}

export default observer(ChangeDuration);
