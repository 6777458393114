import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { GridDiv, FlexDiv } from '@awarego/awarego-components';
import SemiCircleProgress from '../../../../components/manager/assessment/semi-circle-progress';
import sumBy from 'lodash/sumBy';
import {
    calculateColor,
    defaultZero,
    calculateScoreTitle,
} from '../../../../utils/helpers';
import RecommendationCard from './RecommendationCard';
import ThreatAreaHeader from './ThreatAreaHeader';
import NoItemsYetContent from '../../../../components/manager/no-items-yet-content';

function RecommendationVideos({
    handleVideoClick,
    sortedAreas,
    recommendedVideos,
}) {
    return (
        <>
            {sortedAreas.length > 0 ? (
                sortedAreas.map((area, areaIndex) => {
                    const key = area.name;
                    if (!(key in recommendedVideos)) {
                        return null; // Skip non-existing keys
                    }
                    const videosByKey = recommendedVideos[key];
                    const duration = Math.round(
                        sumBy(videosByKey, 'duration') / 60
                    );
                    return (
                        <Fragment key={areaIndex}>
                            <FlexDiv mt={32}>
                                <FlexDiv>
                                    <SemiCircleProgress
                                        percentage={area.score}
                                        width={58}
                                        top={12}
                                        halfCircle
                                        percentageText
                                        percentageTextNotColored
                                    >
                                        <div
                                            style={{
                                                fontSize: '11px',
                                                marginTop: '30px',
                                                color: calculateColor(
                                                    defaultZero(area.score)
                                                ),
                                            }}
                                        >
                                            <b>
                                                {calculateScoreTitle(
                                                    defaultZero(area.score)
                                                )}
                                            </b>
                                        </div>
                                    </SemiCircleProgress>
                                </FlexDiv>
                                <FlexDiv
                                    ml={16}
                                    style={{
                                        display: 'grid',
                                    }}
                                >
                                    <ThreatAreaHeader
                                        title={area.name}
                                        totalDuration={duration}
                                        subjectsByAreaCount={videosByKey.length}
                                    ></ThreatAreaHeader>
                                </FlexDiv>
                            </FlexDiv>

                            <GridDiv
                                style={{
                                    paddingTop: '24px',
                                    paddingLeft: '64px',
                                }}
                                gap={8}
                                minItemWidth={240}
                                key={key}
                            >
                                {videosByKey.map((v, index) => (
                                    <RecommendationCard
                                        id={v.id}
                                        item={v}
                                        title={v.title}
                                        thumbnail={v.thumbnail}
                                        key={index}
                                        fullWidthThumb
                                        handleClick={() =>
                                            handleVideoClick(v.id)
                                        }
                                    ></RecommendationCard>
                                ))}
                            </GridDiv>
                        </Fragment>
                    );
                })
            ) : (
                <NoItemsYetContent
                    img={'/static/img/icon-booklist.svg'}
                    imgAlt={'No recommendations available'}
                    title={'No recommendations available'}
                />
            )}
        </>
    );
}

export default observer(RecommendationVideos);
