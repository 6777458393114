import React from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '@awarego/awarego-components';

function CompanyForm({ companyForm }) {
    const inputHasError = (input) =>
        companyForm.$(input).error && companyForm.$(input).error.length > 0;

    return (
        <TextInput
            error={inputHasError('companyName')}
            helperText={
                inputHasError('companyName')
                    ? 'Please enter name of the company'
                    : undefined
            }
            stretched
            {...companyForm.$('companyName').bind()}
        />
    );
}

export default observer(CompanyForm);
