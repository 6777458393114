import React from 'react';
import Upgrade from '../dashboard/upgrade';
import NoItemsYet from '../no-items-yet';
import { ThemedButton, SpaceBetweenDiv } from '@awarego/awarego-components';

function AssessmentUpgrade({ upgradeAllowed, supportEmail, demoLink }) {
    return upgradeAllowed ? (
        <Upgrade
            heading={'Human Risk Assessment'}
            text={
                'Measure and track employees’ cybersecurity knowledge and behavior'
            }
            img={'/static/img/assessment-upgrade.svg'}
            imgAlt={'sneak peak into assessment'}
            upgradeLink={'/subscriptions/upgrade'}
            secondaryBtnLink={demoLink}
            secondaryBtnText={'Try demo'}
        />
    ) : (
        <NoItemsYet
            img={'/static/img/assessment-locked.svg'}
            imgAlt={'assessments are unavailable'}
            title={'Assessments are unavailable in your current plan'}
            text={'Please upgrade your subscription to access the assessments.'}
            heading={'Assessments'}
            buttons={
                <SpaceBetweenDiv gap={24}>
                    <ThemedButton
                        mode="primary"
                        text="Contact to upgrade"
                        as="a"
                        href={`mailto:${supportEmail}`}
                        flexGrow={1}
                        $center
                    />
                    <ThemedButton
                        as="a"
                        href={demoLink}
                        target="_blank"
                        rel="noreferrer"
                        mode="secondary"
                        flexGrow={1}
                        $center
                        text="Try demo"
                    />
                </SpaceBetweenDiv>
            }
        />
    );
}

export default AssessmentUpgrade;
