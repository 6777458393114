import { removeStringDiacritics } from '../../utils/helpers';
import { STATUS } from '../../utils/constants';

// TABLE SORT TYPES

// use sortType: 'caseAndDiacriticsInsensitiveSort' in definition of columns,
// useful mainly for Name columns
export function caseAndDiacriticsInsensitiveSort(row1, row2, columnName) {
    return makeSortCaseAndDiacriticsInsensitive(
        row1.values[columnName],
        row2.values[columnName]
    );
}

// use sortType: 'statusAlphabetical' in definition of columns,
// used in STATUS columns (Active, Completed, Draft, Scheduled)
export function statusAlphabeticalSort(row1, row2, columnName) {
    let a = Object.keys(STATUS).find(
        (key) => STATUS[key] === row1.values[columnName]
    );

    let b = Object.keys(STATUS).find(
        (key) => STATUS[key] === row2.values[columnName]
    );

    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
}

function makeSortCaseAndDiacriticsInsensitive(a, b) {
    const r1 = removeStringDiacritics(a).toLowerCase();
    const r2 = removeStringDiacritics(b).toLowerCase();
    if (r1 < r2) {
        return -1;
    }
    if (r1 > r2) {
        return 1;
    }
    return 0;
}

export function multipleStringsInRowSort(a, b) {
    const c1 = a.values.categories.join('');
    const c2 = b.values.categories.join('');

    if (c1 > c2) {
        return 1;
    }
    if (c1 < c2) {
        return -1;
    }
    return 0;
}
