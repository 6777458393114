import { observer } from 'mobx-react';
import React from 'react';
import { FlexDiv, TextLead } from '@awarego/awarego-components';

function ReduceEmployeesConfirm({ canEditSubscription, subscriptionChanged }) {
    const changeText = subscriptionChanged
        ? `In order to change to your selected plan`
        : 'In order to reduce the number of seats';

    return (
        <FlexDiv column>
            {subscriptionChanged && (
                <TextLead mb={16}>
                    You selected a subscription plan that supports a lower
                    number of seats than you are currently using.
                </TextLead>
            )}
            <TextLead>
                {changeText},{' '}
                <b>
                    please delete at least {canEditSubscription.removeEmployees}{' '}
                    employees{' '}
                </b>{' '}
                through the client's Employees page. Keep in mind that employees
                you remove from the list will not be able to receive any
                trainings or assessments.
            </TextLead>
        </FlexDiv>
    );
}

export default observer(ReduceEmployeesConfirm);
