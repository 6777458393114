import RestService from './RestService';

export default class Packages extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/packages`);
        } else super('/packages');
    }

    updateVideos(packageId, videos) {
        return this.post(`${this.baseUrl}/${packageId}/videos`, { videos });
    }
}
