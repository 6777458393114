import React, { useState, Fragment } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import range from 'lodash/range';
import { reaction } from 'mobx';
import ListFormDef from '../../../forms/list';
import UsersFormDef from '../../../forms/list-users';
import ErrorMessage from '../../../components/errormessage';
import { ThemedLink } from '../../../components/themed/ThemedComponents';
import AddOrImport from '../AddOrImport';
import '../styles/ListEditorModal.scss';
import {
    Heading1,
    TextLead,
    TextWithTooltipIcon,
    FlexDiv,
    TextInput,
    ThemedButton,
} from '@awarego/awarego-components';
import UserModalTooltipText from './ModalTooltipText';
import { useStores } from '../../../hooks/use-stores';
import { handleSelectedFile } from '../../../components/file-handlers/file-helpers';

function ModalUsersListEditContainer({
    companyId,
    error,
    result,
    onRequestClose,
    createRecipientsList,
}) {
    const { listStore, userStore } = useStores();

    const [batchMode, setBatchMode] = useState([]);
    const [encoding, setEncoding] = useState('utf-8');
    const [files, setFiles] = useState([]);
    const [showEncodingSelect, setShowEncodingSelect] = useState(false);
    const [isNameTab, setIsNameTab] = useState(true);
    const [importError, setImportError] = useState(null);

    const localStore = useLocalObservable(() => {
        const _list_form = new ListFormDef({}, {});
        const _usersForm = new UsersFormDef({}, {});

        reaction(
            () => listStore.editingList,
            (list) => {
                _list_form.update(list ? { name: list.name } : {});
            }
        );

        if (!listStore.editingList) {
            range(3).forEach(() => {
                _usersForm.$('users').onAdd({
                    preventDefault: () => {},
                });
            });
        }
        return { _list_form, _usersForm };
    });

    const switchMode = () => {
        userStore.resetUploadData();
        setBatchMode(!batchMode);
    };

    const reUpload = async () => {
        userStore.resetUploadData();
    };

    const showEncodingOptions = () => {
        setShowEncodingSelect(true);
    };

    const changeEncoding = (e) => {
        setEncoding(e.target.value);
        const properties = { fileEndings: ['txt', 'csv'] };
        handleSelectedFile(files, e.target.value, properties);
    };

    const setAcceptedFiles = (files) => {
        setFiles(files);
    };

    const onCreate = () => {
        let usersValid = batchMode
            ? userStore.processedRecords &&
              userStore.processedRecords.newUsers &&
              userStore.processedRecords.newUsers.length > 0
            : localStore._usersForm.isValid;

        if (localStore._list_form.isValid && usersValid) {
            createRecipientsList({
                ...localStore._list_form.values(),
                ...(batchMode
                    ? { users: userStore.processedRecords.newUsers }
                    : localStore._usersForm.values()),
            });
        }
    };

    const importFiles = (files) => {
        if (files) {
            files.forEach(async (fileContent) => {
                await userStore.saveUsers(fileContent, companyId, null, true);
            });
        } else {
            let importErrorMessage = 'There was a problem importing file';
            setImportError(importErrorMessage);
        }
    };

    const { processedRecords } = userStore;

    if (result) {
        return (
            <Fragment>
                <div className={'modalHeader'}>
                    <FlexDiv column py={16} pl={16}>
                        <Heading1>
                            {isNameTab
                                ? 'Create employee list'
                                : `Add employees to list: ${
                                      localStore._list_form.$('name').value
                                  }`}
                        </Heading1>
                        <TextLead lighter mt={4}>
                            <TextWithTooltipIcon
                                tooltipText={<UserModalTooltipText />}
                                textComponent={
                                    'Organize employees into lists based on your goals.'
                                }
                            />
                        </TextLead>
                    </FlexDiv>
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                {isNameTab ? (
                    <Fragment>
                        <div className={'modalContent form'}>
                            <TextInput
                                {...localStore._list_form.$('name').bind()}
                                stretched
                            />
                            <ErrorMessage error={error || importError} />
                        </div>
                        <div className={'modalFooter'}>
                            <ThemedButton
                                mode="primary"
                                text="Create"
                                disabled={
                                    localStore._list_form.$('name').value === ''
                                }
                                onClick={() => setIsNameTab(false)}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className={'modalContent form'}>
                            <AddOrImport
                                showHeader={true}
                                companyId={companyId}
                                batchMode={batchMode}
                                processedRecords={processedRecords}
                                deleteObsolete={false}
                                showEncodingSelect={showEncodingSelect}
                                showEncodingOptions={showEncodingOptions}
                                encoding={encoding}
                                changeEncoding={changeEncoding}
                                importFiles={importFiles}
                                usersForm={localStore._usersForm}
                                hideListName={true}
                                isImportingLists={true}
                                setAcceptedFiles={(files) =>
                                    setAcceptedFiles(files)
                                }
                            />
                            <br />
                            <ErrorMessage error={error || importError} />
                        </div>
                        <div className={'modalFooter'}>
                            <div className="d-flex flex-justify-between flex-fill">
                                <ThemedLink
                                    className="no-wrap switch-mode"
                                    onClick={switchMode}
                                >
                                    {batchMode
                                        ? 'Switch to manual mode'
                                        : 'Switch to import mode'}
                                </ThemedLink>
                                {batchMode && processedRecords && (
                                    <Fragment>
                                        <ThemedButton
                                            mode="secondary"
                                            onClick={reUpload}
                                            style={{ marginRight: '16px' }}
                                            text="Reupload"
                                        />
                                    </Fragment>
                                )}
                            </div>
                            <ThemedButton
                                mode="primary"
                                text="Done"
                                onClick={onCreate}
                                disabled={
                                    batchMode
                                        ? processedRecords === null
                                        : !localStore._usersForm.values()
                                              .users ||
                                          !localStore._usersForm.values().users
                                              .length > 0 ||
                                          !localStore._usersForm.values()
                                              .users[0].email.length > 0 ||
                                          localStore._usersForm.errors()
                                              .users[0].email !== null
                                }
                            />
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    } else {
        return null;
    }
}

export default observer(ModalUsersListEditContainer);
