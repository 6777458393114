import RestService from './RestService';

export default class MetricsService extends RestService {
    /**
     * @param {RestService} parentService
     */
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/metric`);
        } else {
            throw new Error(
                'EventsService can only be used as a nested service'
            );
        }
    }
}
