import i18n from 'i18next';
import Backend from './backend';

let instance;

function init(commonStore) {
    instance = i18n.use(Backend).init({
        react: {
            useSuspense: false,
        },
        lng: window.localStorage.getItem('language') || 'en',
        fallbackLng: 'en',
        // load: "languageOnly",
        ns: ['common', 'assessment'],
        defaultNS: 'common',
        keySeparator: false,
        backend: {
            loadPath: `/static/locales/{{lng}}/{{ns}}.json`,
            commonStore,
        },
        debug: false,
    });
    return instance;
}

export default { init };
