import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useMemo } from 'react';
import DragAndDropImport from '../../components/file-handlers/DragAndDropImport';
import { BigModal, GridDiv, TextLead } from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import ErrorMessage from '../../components/errormessage';
import ScormContentForm from './ScormContentForm';
import ScormClass from './ScormClass';
import { extractErrorMessage } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

function ScormContentModal({
    isModalOpen,
    onRequestCloseModal,
    companyId,
    isAddLanguageContent = false,
    isEditLanguageContent = false,
    targetPackage = null,
}) {
    const [scorm, setScorm] = useState(null);
    const { languageStore, commonStore } = useStores();
    const [showReupload, setShowReupload] = useState(false);

    const [uploadingContent, setUploadingContent] = useState(false);
    const { cmi5Store } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (languageStore.languages && languageStore.languages.length == 0) {
            (async () => {
                await languageStore.loadAllLanguages();
            })();
        }

        if (targetPackage && isEditLanguageContent) {
            setScorm(targetPackage);
        }

        return () => {
            cmi5Store.error = null;
            setScorm(null);
        };
    }, []);

    // validation
    const importFiles = async (files) => {
        setUploadingContent(true);

        try {
            const formData = new FormData();
            if (Array.isArray(files) && files.length > 0) {
                formData.append('package', files[0]);
                const result = await cmi5Store.validateScormPackage(
                    companyId,
                    formData
                );

                if (result) {
                    result.filename = files[0].name;
                    result.zipFile = files[0];

                    // if adding new language, xapi is prefilled based on the main scorm package
                    if (isAddLanguageContent && targetPackage) {
                        result.xapi = targetPackage.xapi;
                    }
                }
                const scormResponse = new ScormClass(result);
                setScorm(scormResponse);
            } else {
                throw new Error('Error importing file');
            }
        } catch (error) {
            cmi5Store.error = extractErrorMessage(error);
        } finally {
            setUploadingContent(false);
        }
    };

    const onUploadScorm = async (form, editedLanguage) => {
        let result;
        try {
            // Get correct endpoint
            if (isAddLanguageContent) {
                result = await cmi5Store.uploadLanguagePackage(
                    companyId,
                    form,
                    targetPackage.subject_id
                );
            } else if (isEditLanguageContent) {
                form.append('isMainLanguage', targetPackage.isMainLanguage);
                result = await cmi5Store.editLanguagePackage(
                    companyId,
                    form,
                    targetPackage.subject_id,
                    editedLanguage
                );
            } else {
                result = await cmi5Store.uploadScormPackage(companyId, form);
            }
            setShowReupload(false);

            // Redirection & Updating view
            if (
                !isAddLanguageContent &&
                !isEditLanguageContent &&
                result &&
                result.id
            ) {
                navigate(`/content/${result.id}`);
            } else if (isAddLanguageContent && result) {
                commonStore.success('Language added successfully');
                onRequestCloseModal();
            } else if (isEditLanguageContent && result) {
                commonStore.success('Language edited successfully');
                if (targetPackage.isMainLanguage) {
                    onRequestCloseModal(result[0].name); // pass new name to the header
                } else {
                    onRequestCloseModal();
                }
            }
        } catch (error) {
            cmi5Store.error = extractErrorMessage(error);
            setShowReupload(true);
        } finally {
            setScorm(null);
        }
    };

    const onReuploadScorm = () => {
        cmi5Store.error = null;
        setShowReupload(false);
        setScorm(null);
    };

    const createHeading = useMemo(() => {
        let heading = 'Import scorm content';

        if (isAddLanguageContent && targetPackage) {
            const name = targetPackage.name;
            heading = `Add new language — ${name}`;
        } else if (isEditLanguageContent && targetPackage) {
            const name = targetPackage.name;
            const language = targetPackage.english_name
                ? targetPackage.english_name
                : targetPackage.language;
            heading = `Edit ${language} — ${name}`;
        }

        return heading;
    }, [
        isAddLanguageContent,
        isEditLanguageContent,
        targetPackage && targetPackage.name,
    ]);

    return (
        <>
            <BigModal
                isOpen={isModalOpen}
                width={800}
                onRequestClose={() => {
                    onRequestCloseModal();
                    setShowReupload(false);
                    setScorm(null);
                }}
                heading={createHeading}
                modalContent={
                    !uploadingContent ? (
                        <>
                            {cmi5Store.error && (
                                <ErrorMessage error={cmi5Store.error} />
                            )}
                            {scorm ? (
                                <ScormContentForm
                                    scorm={scorm}
                                    availableLanguages={languageStore.languages.slice()}
                                    acceptedFormats="image/png, image/jpeg"
                                    onUploadScorm={onUploadScorm}
                                    showReupload={showReupload}
                                    onReuploadScorm={onReuploadScorm}
                                    isAddLanguageContent={isAddLanguageContent}
                                    isEditLanguageContent={
                                        isEditLanguageContent
                                    }
                                />
                            ) : (
                                <DragAndDropImport
                                    fileType="zip"
                                    acceptedFormats=".zip"
                                    onSetAcceptedFiles={(files) => {
                                        cmi5Store.error = null;
                                        importFiles(files);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <GridDiv>
                            <ThemedSpinner />
                            <TextLead themed center>
                                Uploading
                            </TextLead>
                        </GridDiv>
                    )
                }
            />
        </>
    );
}

export default observer(ScormContentModal);
