import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { withTheme } from 'styled-components';

class ThemedEyeSVG extends React.Component {
    render() {
        return (
            <SvgLoader path="/static/img/icon-eye.svg">
                <SvgProxy
                    selector="path"
                    stroke={this.props.color || this.props.theme.primary}
                />
            </SvgLoader>
        );
    }
}

export default withTheme(ThemedEyeSVG);
