import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = ['users', 'users[].email', 'users[].name'];

const labels = {
    users: 'All Recipients',
    'users[].email': 'Email',
    'users[].name': 'Name',
};

const placeholders = {
    users: 'All Recipients',
    'users[].email': 'Email',
    'users[].name': 'Name',
};

const rules = {
    'users[].email': 'required_with:users[].name|email|string',
    'users[].name': 'string',
};

class ListForm extends Form {
    constructor(setup, options) {
        super(
            { fields, placeholders, rules, labels, ...setup },
            { plugins, ...options }
        );
    }
}

export default ListForm;
