import React from 'react';
import { FlexDiv, Heading1, Heading3, TextTiny } from '@awarego/awarego-components';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    root: {

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    section: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function NotFound() {
    const classes = useStyles();
    return (
        <FlexDiv justifyCenter mt={24} pb={24} className={classes.root} >
            <FlexDiv justifyCenter mt={24} pb={24} column  className={classes.section}>
                <Heading3>Ooooooops …</Heading3>
                <Heading1>Page not found</Heading1>
                <TextTiny>The page you are looking for doesn't exist, or another error occurred.</TextTiny>
            </FlexDiv>
            <img src="/static/img/notfound.svg" className={classes.section} />
        </FlexDiv>
    );
}

export default NotFound;
