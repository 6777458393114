import React, { Fragment } from 'react';
import {observer, useLocalObservable} from 'mobx-react';
import FormDef from '../../forms/company';
import CompanyEdit from '../../components/company/company-edit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Heading1, ThemedButton } from '@awarego/awarego-components';
import {useStores} from "../../hooks/use-stores";


function CompanyEditContainer ({accountId, company, onRequestClose, companyId, currentUser, fromNewSettings, onSaved}) {

    const {companyStore, authStore, store} = useStores();

    const onSuccess = async () => {
        if (fromNewSettings) {
            await store.saveCurrentCompany({
                ...localStore._form.values(),
            });
            onSaved(localStore._form.values().name);
        } else {
            company
                ? await companyStore.save(companyId, {
                    ...localStore._form.values(),
                })
                : await companyStore.create(
                    {
                        companyName: localStore._form.values().name,
                        managerEmail: currentUser.email,
                        managerName: currentUser.name,
                        accountId: accountId,
                    },
                    true
                );

            onSaved(localStore._form.values().name);
        }
    };

    const localStore = useLocalObservable(() => {
        let _form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess,
                },
            }
        );
        _form.update(
            company ? { name: company.company_name } : {}
        );

        return { _form };
    });





    const { error, savingCompany } = companyStore;
    const { trialError } = authStore;
    const isNew = !company;
    return (
        <Fragment>
            <div className={'modalHeader'}>
                <Heading1>
                    {isNew ? 'Add new company' : 'Rename company'}
                </Heading1>
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className={'modalContent'}>
                <CompanyEdit
                    form={localStore._form}
                    error={error || trialError}
                    email={
                        currentUser &&
                        currentUser.email
                    }
                    emailEditable={false}
                />
            </div>
            <div className={'modalFooter'}>
                {savingCompany ? (
                    <ThemedSpinner size={50} />
                ) : (
                    <Fragment>
                        <ThemedButton
                            mode="secondary"
                            onClick={localStore._form.onSubmit}
                            text={
                                currentUser ? 'Continue' : 'Done'
                            }
                        />
                    </Fragment>
                )}
            </div>
        </Fragment>
    );

}

export default observer(CompanyEditContainer);
