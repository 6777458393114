import {
    BigModal,
    FlexDiv,
    SpaceBetweenDiv,
    TextInput,
    TextTiny,
    ThemedButton,
} from '@awarego/awarego-components';
import { MenuItem } from '@mui/material';
import uniq from 'lodash/uniq';
import QuestionSelect from './question-select';
import pluralize from 'pluralize';
import React from 'react';
import { observer } from 'mobx-react';

function AddScenariosModal({
    isOpen,
    onRequestClose,
    area,
    setArea,
    behaviour,
    setBehaviour,
    questions,
    threatAreaDefinitions,
    selection,
    onQuestionSelected,
    showPreviewQuestionsModal,
}) {
    return (
        <BigModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            heading="Add scenarios"
            filterContent={
                <FlexDiv alignCenter>
                    <TextInput
                        select
                        value={area || 'all'}
                        onChange={(e) => {
                            setArea(e.target.value);
                            if (behaviour !== 'all') {
                                setBehaviour('all');
                            }
                        }}
                        inline
                    >
                        <MenuItem value="all">All Threat Areas</MenuItem>
                        {uniq(
                            questions.map((q) => {
                                return q.area;
                            })
                        ).map((a) => (
                            <MenuItem value={a} key={a}>
                                {a}
                            </MenuItem>
                        ))}
                    </TextInput>
                    <TextInput
                        select
                        value={behaviour || 'all'}
                        onChange={(e) => {
                            setBehaviour(e.target.value);
                        }}
                        disabled={area === 'all'}
                        inline
                        limitWidth
                        airy
                    >
                        <MenuItem value="all">All Key Behaviours</MenuItem>
                        {threatAreaDefinitions
                            .filter((a) => a.name === area)
                            .map((a) =>
                                a.behaviours.map((b) => (
                                    <MenuItem value={b.code} key={b.code}>
                                        {b.name}
                                    </MenuItem>
                                ))
                            )}
                    </TextInput>
                    {(area !== 'all' || behaviour !== 'all') && (
                        <ThemedButton
                            mode="secondary"
                            $noTextTransform
                            $airy
                            size="tiny"
                            text="Clear"
                            onClick={() => {
                                setArea('all');
                                setBehaviour('all');
                            }}
                        />
                    )}
                </FlexDiv>
            }
            modalContent={
                <QuestionSelect
                    selection={selection}
                    questions={questions}
                    onQuestionSelected={onQuestionSelected}
                    showPreviewQuestionsModal={showPreviewQuestionsModal}
                    area={area}
                    behaviour={behaviour}
                />
            }
            modalFooterContent={
                <SpaceBetweenDiv>
                    <TextTiny themed bold>
                        {selection.length}{' '}
                        {pluralize('scenario', selection.length)} added
                    </TextTiny>
                    <ThemedButton
                        mode="primary"
                        onClick={onRequestClose}
                        text="Done"
                    />
                </SpaceBetweenDiv>
            }
        />
    );
}

export default observer(AddScenariosModal);
