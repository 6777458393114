/* eslint-disable no-empty */
import { makeAutoObservable } from 'mobx';
import keyBy from 'lodash/keyBy';
import services from '../services';

class PPVStore {
    catalog = [];
    stat = [];
    loadingCatalog = false;
    loadingPPVStats = false;
    categoryFilter = 'all';
    nameFilter = '';

    constructor() {
        makeAutoObservable(this);
    }

    setCategoryFilter(value) {
        this.categoryFilter = value;
    }

    setNameFilter(value) {
        this.nameFilter = value;
    }

    async loadCatalog(companyId) {
        if (this.loadingCatalog) return;

        this.loadingCatalog = true;
        try {
            let res = await services.Companies.subjectsService(companyId).list({
                ppv: true,
            });
            this.catalog.replace(res);
        } finally {
            this.loadingCatalog = false;
        }
    }

    async loadPPVStats(companyId) {
        if (this.loadingPPVStats) return;

        this.loadingPPVStats = true;
        try {
            let res = await services.Companies.PPVService(companyId).status();
            this.stat.replace(res);
        } finally {
            this.loadingPPVStats = false;
        }
    }

    async toggleSnippet(companyId, subjectId, state) {
        if (this.togglingSnippet) return;
        this.togglingSnippet = true;
        try {
            let res = await services.Companies.PPVService(companyId).toggle(
                subjectId,
                state
            );
            let subject = this.stat.find(
                (x) => x.subject_id === Number(res.subject_id)
            );

            if (subject) {
                subject.enabled = res.enabled;
            }
        } finally {
            this.togglingSnippet = false;
        }
    }

    get categories() {
        if (this.loadingCatalog || this.loadingPPVStats) return [];
        return Object.keys(keyBy(this.catalog, 'category'));
    }

    get summary() {
        if (this.loadingCatalog || this.loadingPPVStats) return [];
        let result = [];

        try {
            this.catalog.forEach((s) => {
                if (
                    this.categoryFilter !== 'all' &&
                    ((typeof s.category === 'string' &&
                        this.categoryFilter !== s.category) ||
                        (typeof s.category === 'object' &&
                            this.categoryFilter !== 'null'))
                ) {
                    return;
                }
                if (
                    this.nameFilter &&
                    s.title
                        .toLowerCase()
                        .indexOf(this.nameFilter.toLowerCase()) === -1
                )
                    return;

                let r = this.stat.find((x) => x.subject_id === s.subject_id);
                let existing = result.find(
                    (x) => x.subject_id === s.subject_id
                );
                if (existing) {
                    existing.categories.push(s.category);
                } else
                    result.push({
                        ...s,
                        views: r && r.views,
                        status: r && r.enabled,
                        categories: [s.category],
                    });
            });
        } catch (e) {}

        return result;
    }
}

export default PPVStore;
