import { runInAction, reaction, makeAutoObservable } from 'mobx';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import services from '../services';
import Assessment from './models/assessment';
import { extractErrorMessage } from '../utils/helpers';
import { ASSESSMENT_WIZARD_STEPS, STATUS } from '../utils/constants';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';
import moment from 'moment';
import PersonalizeFormDef from '../forms/personalize';
import { translateAssessmentScheduleType } from '../utils/scheduleType';
import { getDeliveryChannels } from '../utils/deliveryChannel';
import React from 'react';
import { Buffer } from 'buffer';

const activeStatuses = { [STATUS.SCHEDULED]: 1, [STATUS.ACTIVE]: 1 };
const completedStatuses = {
    [STATUS.COMPLETED]: 1,
};

class AssessmentStore {
    assessments = [];
    latestTenAssessments = []; //Light-weight objects for the latest (10) updated assessments
    loadingAssessments = false;
    loadingAssessment = false;
    loadingInfosecSummary = false;
    loadingQuestionsReportList = false;
    loadingEmployeesReportList = false;
    loadingThreatAreaDetail = false;
    loadingRiskInsights = false;
    loadingListScoresPerThreatArea = false;
    loadingThreatAreasList = false;
    loadingKeyBehavioursList = false;
    loadingRemindersOverview = false;
    slackAvailable = false;
    slackConnected = false;
    teamsAvailable = false;
    teamsConnected = false;
    loadingCompanyData = false;
    templates = [];
    questions = [];
    employeesReportList = [];
    keyBehavioursList = [];
    allowedSteps = [];
    confirmedSteps = [];
    creatingAssessment = false;
    updatingAssessment = false;
    deletingAssessment = false;
    sendingPreview = false;
    remindersOverview = [];
    sendError = null;
    assessment = null;
    editingAssessment = null;
    infosecSummary = null;
    threatAreaDetail = [];
    threatAreasList = [];
    riskInsights = [];
    listScoresPerThreatArea = [];
    selectedTemplates = [];
    templateCustomized = false;
    error = '';
    step = ASSESSMENT_WIZARD_STEPS.GOAL;
    searchValue = '';
    areaFilter = [];
    employeeFilter = [];
    startDueDateFilter = null;
    endDueDateFilter = null;
    endDeliveryDateFilter = null;
    startDeliveryDateFilter = null;
    sortBy = '_rawData.last_updated';
    platform = 'all_platforms';
    personalizeForm = null;
    threatAreaDefinitions = [];
    loadingThreatAreaDefinitions = null;
    isCSVDownloading = false;
    dueError = null;

    //By user
    userAssessments = [];
    loadingUserAssessments = false;

    constructor(
        listStore,
        mainStore,
        commonStore,
        companyStore,
        authStore,
        brandingStore
    ) {
        makeAutoObservable(this);
        this.listStore = listStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        this.companyStore = companyStore;
        this.authStore = authStore;
        this.brandingStore = brandingStore;
    }

    setError(error, type) {
        switch (type) {
            case 'updateDue':
                this.dueError = error;
                break;
            case 'sendPreview':
                this.sendError = error;
                break;
            default:
                this.error = error;
                break;
        }
    }

    get filteredAssessments() {
        let orderedAssessments = orderBy(
            this.assessments,
            [this.sortBy],
            ['desc']
        );

        let filteredAssessments = orderedAssessments.filter((x) => {
            if (
                this.areaFilter.length > 0 &&
                intersection(x._rawData.definition.areas, this.areaFilter)
                    .length === 0
            )
                return false;
            if (
                this.employeeFilter.length > 0 &&
                intersection(
                    x._rawData.definition.userLists,
                    this.employeeFilter
                ).length === 0
            )
                return false;
            if (
                this.searchValue.length > 0 &&
                x.name !== null &&
                !x.name.toLowerCase().includes(this.searchValue.toLowerCase())
            )
                return false;
            if (
                this.startDueDateFilter !== null &&
                this.endDueDateFilter !== null &&
                !moment(x.due).isBetween(
                    this.startDueDateFilter,
                    this.endDueDateFilter
                )
            )
                return false;
            if (
                this.startDeliveryDateFilter !== null &&
                this.endDeliveryDateFilter !== null &&
                !moment(x.deliveryDate).isBetween(
                    this.startDeliveryDateFilter,
                    this.endDeliveryDateFilter
                )
            )
                return false;
            if (x.isScorm) return false;
            return true;
        });
        return filteredAssessments;
    }

    get isBothAssessmentTypes() {
        return (
            this.assessments.filter((x) => x.isScorm).length > 0 &&
            this.assessments.filter((x) => !x.isScorm).length > 0
        );
    }

    get isScormOnly() {
        return (
            this.assessments.filter((x) => x.isScorm).length > 0 &&
            this.assessments.filter((x) => !x.isScorm).length === 0
        );
    }

    get scormAssessments() {
        return this.assessments.filter((x) => x.isScorm);
    }

    get filteredScormAssessments() {
        return this.scormAssessments.filter((x) => {
            if (
                this.areaFilter.length > 0 &&
                intersection(x._rawData.definition.areas, this.areaFilter)
                    .length === 0
            )
                return false;
            if (
                this.searchValue.length > 0 &&
                x.name !== null &&
                !x.name.toLowerCase().includes(this.searchValue.toLowerCase())
            )
                return false;
            if (
                this.employeeFilter.length > 0 ||
                this.startDueDateFilter !== null ||
                this.endDueDateFilter !== null ||
                this.startDeliveryDateFilter !== null ||
                this.endDeliveryDateFilter !== null
            )
                return false;
            return true;
        });
    }

    get allTab() {
        return this.filteredAssessments;
    }

    get activeTab() {
        return this.filteredAssessments.filter((x) => {
            return activeStatuses[x.status];
        });
    }

    get completedTab() {
        return this.filteredAssessments.filter((x) => {
            return completedStatuses[x.status];
        });
    }

    get draftTab() {
        return this.filteredAssessments.filter((x) => {
            return x.status === STATUS.DRAFT;
        });
    }

    get summaryOutput() {
        const assessment = this.assessment;
        return (
            assessment && [
                {
                    name: 'Name',
                    detail: assessment.name,
                },
                {
                    name: 'Threat areas',
                    detail: (
                        <div>
                            {/* if more questions have the same area, show this area only once */}
                            {[
                                ...new Set(
                                    assessment.questionsSelected.map(
                                        (q) => q.area
                                    )
                                ),
                            ].map((area, j, arr) => (
                                <span key={j}>
                                    {/* don't put comma after last area */}
                                    {area}
                                    {j !== arr.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </div>
                    ),
                },
                ...(assessment.isScorm
                    ? []
                    : [
                          {
                              name: 'Delivery date',
                              detail: assessment.summaryDeliveryDateText,
                          },
                          {
                              name: 'Due date',
                              detail:
                                  assessment.due !== null
                                      ? moment(assessment.due).format(
                                            `L (LT) [${this.authStore.timeZone}]`
                                        )
                                      : '—',
                          },
                          {
                              name: 'Reminders',
                              detail: assessment.reminders_enabled
                                  ? assessment.reminders_steps.filter(
                                        (reminder) => reminder !== null
                                    ).length
                                  : '—',
                          },
                          {
                              name: `Employees (${assessment.selectedUserCount})`,
                              detail: (
                                  <div>
                                      {assessment.userListsSelected &&
                                          assessment.userListsSelected.map(
                                              (list, j, arr) => (
                                                  <span key={j}>
                                                      {/* don't put comma after last list name */}
                                                      {list && list.name}
                                                      {j !== arr.length - 1
                                                          ? ', '
                                                          : ''}
                                                  </span>
                                              )
                                          )}
                                  </div>
                              ),
                          },
                      ]),
            ]
        );
    }

    get steps() {
        return [
            {
                number: ASSESSMENT_WIZARD_STEPS.GOAL,
                title:
                    this.assessment && this.assessment.status === STATUS.ACTIVE
                        ? 'Template'
                        : 'Choose template',
            },
            {
                number: ASSESSMENT_WIZARD_STEPS.CONTENT,
                title: 'Review Scenarios',
            },
            {
                number: ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                title: 'Personalize',
            },
            ...(this.assessment && this.assessment.isScorm
                ? []
                : [
                      {
                          number: ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                          title: 'Select Employees',
                      },
                      {
                          number: ASSESSMENT_WIZARD_STEPS.SCHEDULE,
                          title: 'Schedule',
                      },
                      {
                          number: ASSESSMENT_WIZARD_STEPS.REMINDERS,
                          title: 'Reminders',
                      },
                  ]),

            { number: ASSESSMENT_WIZARD_STEPS.FINALIZE, title: 'Summary' },
        ];
    }

    get sortedListScoresPerThreatArea() {
        return this.listScoresPerThreatArea
            .slice()
            .sort((a, b) => b.usersCount - a.usersCount);
    }

    setAreaFilter(areaFilter) {
        this.areaFilter = areaFilter;
    }

    setSearchValue(searchValue) {
        this.searchValue = searchValue;
    }

    setThreatAreaFilter = (area) => {
        this.threatAreaFilter = area;
    };

    setEmployeeFilter(employeeFilter) {
        this.employeeFilter = employeeFilter;
    }

    setStartDueDateFilter(startDueDateFilter) {
        this.startDueDateFilter = startDueDateFilter;
    }

    setEndDueDateFilter(endDueDateFilter) {
        this.endDueDateFilter = endDueDateFilter;
    }

    setStartDeliveryDateFilter(startDeliveryDateFilter) {
        this.startDeliveryDateFilter = startDeliveryDateFilter;
    }

    setEndDeliveryDateFilter(endDeliveryDateFilter) {
        this.endDeliveryDateFilter = endDeliveryDateFilter;
    }

    setSortBy(sortBy) {
        this.sortBy = sortBy;
    }

    setStep(step) {
        this.error = '';
        this.step = step;
        this.commonStore.analyticsEvent(
            'Assessment-wizard',
            'step',
            `step-${step}`
        );
    }

    setTemplate(templateId) {
        this.selectedTemplates = [templateId];

        let template = this.templates.find((x) => x.id === templateId);

        if (template) {
            // in case we ever decide to enable selecting multiple templates at the same time
            // if (this.selectedTemplates.includes(templateId)) {
            //     this.selectedTemplates.splice(this.selectedTemplates.indexOf(templateId), 1)
            //     this.assessment.selectedQuestionsIds.replace(difference(this.assessment.selectedQuestionsIds, template.questions))
            // } else {
            this.assessment.templateCustomized = false;
            this.assessment.selectedQuestionsIds.replace(
                template.questions.concat(template.disabledQuestions)
            );
            // }
        }

        if (this.selectedTemplates.length > 0) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.CONTENT)) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.CONTENT);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.CONTENT);
        }
    }

    toggleFinalStep() {
        if (this.isScorm) {
            const questionsSelected = this.questions.length > 0;
            const personalizationValid =
                (!this.assessment.personalizeEnabled ||
                    (this.personalizeForm && this.personalizeForm.isValid)) &&
                this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.PERSONALIZE);

            if (questionsSelected && personalizationValid) {
                if (
                    !this.allowedSteps.includes(
                        ASSESSMENT_WIZARD_STEPS.FINALIZE
                    )
                )
                    this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.FINALIZE);
            } else {
                this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.FINALIZE);
            }

            return;
        }

        if (
            this.reminderStepValid &&
            this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)
        ) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.FINALIZE)) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.FINALIZE);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.FINALIZE);
        }
    }

    toggleRemindersStep() {
        if (
            this.assessment.due !== null &&
            (this.assessment.scheduleType === -1 ||
                this.assessment.scheduleType === 1 ||
                (this.assessment.scheduleType === 0 &&
                    this.assessment.date !== null &&
                    moment(this.assessment.due).isAfter(
                        this.assessment.date
                    ))) &&
            this.assessment.channel !== 0
        ) {
            if (
                !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)
            ) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.REMINDERS);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.REMINDERS);
        }
    }

    get reminderStepValid() {
        if (!this.assessment) return false;

        if (!this.assessment.reminders_enabled) return true;

        let stepsValid = this.assessment.reminders_steps.map(
            (reminder) =>
                reminder.send !== null &&
                (!reminder.alreadySet
                    ? moment(reminder.send).isAfter(
                          this.assessment.scheduleType === 0
                              ? this.assessment.date
                              : moment(),
                          'minute'
                      )
                    : true) &&
                moment(reminder.send).isBefore(this.assessment.due)
        );
        return (
            this.assessment.reminders_steps.length > 0 &&
            stepsValid.every((x) => x) &&
            this.assessment.reminders_channel !== 0
        );
    }

    toggleIntroMessageStep() {
        if (this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)) {
            return this.toggleFinalStep();
        }
    }

    toggleRecipientsStep(form) {
        if (
            (!this.assessment.personalizeEnabled || (form && form.isValid)) &&
            this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.PERSONALIZE)
        ) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.EMPLOYEES))
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
        } else {
            this.assessment.status !== STATUS.ACTIVE &&
                this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
        }
    }

    setTemplateAsCustom() {
        const customTemplate = this.templates.find((x) => x.custom);
        if (customTemplate) this.selectedTemplates = [customTemplate.id];
    }

    togglePersonalizeStep() {
        if (
            this.assessment &&
            this.assessment.selectedQuestionsIds.length > 0
        ) {
            if (
                !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.PERSONALIZE)
            ) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.PERSONALIZE);
                this.personalizeForm = null;
            }
            this.updatePersonalizeForm();
        }

        if (
            !this.assessment ||
            this.assessment.selectedQuestionsIds.length === 0
        ) {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.PERSONALIZE);
        }

        if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.EMPLOYEES))
            this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
    }

    toggleScheduleStep() {
        if (
            this.assessment &&
            this.assessment.selectedUserCount > 0 &&
            !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.SCHEDULE)
        )
            this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.SCHEDULE);

        if (!this.assessment || this.assessment.selectedUserCount === 0) {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.SCHEDULE);
        }
    }

    sendPreview = generateCreateEntity(
        'sendPreview',
        this,
        'sendingPreview',
        async (companyId, targetEmail, intro) => {
            const data = { ...intro, targetEmail };

            data.previewQuestions = this.assessment.selectedQuestionsIds;
            data.name = this.assessment.name;
            data.due = this.assessment.due;
            data.duration =
                this.assessment.overrideDuration ||
                this.assessment.selectedQuestionsDuration;

            await services.Companies.assessmentsService(companyId).sendPreview(
                data
            );
        },
        null,
        {
            onCreated: (result, companyId) => {
                this.commonStore.analyticsEvent(
                    'Assessment-wizard',
                    'preview-sent',
                    `company-${companyId}`
                );
            },
        }
    );

    loadRemindersOverview = generateLoadList(
        'remindersOverview',
        this,
        'loadingRemindersOverview',
        (companyId, assessmentId) => {
            return services.Companies.assessmentsService(
                companyId
            ).remindersOverview(assessmentId);
        },
        'remindersOverview'
    );

    async prepareWizard(companyId, copyFrom, editId, isScorm) {
        if (this.loadingCompanyData) return;
        this.loadingCompanyData = true;
        this.isScorm = isScorm;
        this.error = null;
        this.assessments.clear();
        this.assessment = null;
        this.selectedTemplates = [];
        try {
            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            let templates = await services.Companies.templatesService(
                companyId
            ).list({ type: 'assessment' });
            runInAction(async () => {
                this.templates.replace(templates);
                this.questions.replace(questions);

                await this.listStore.loadCompanyLists(companyId);
                await this.companyStore.loadIntegrationData(companyId, 'slack');
                await this.companyStore.loadIntegrationData(companyId, 'teams');
                let fromData;

                this.slackConnected =
                    this.companyStore.slackDataIndex[companyId] &&
                    this.companyStore.slackDataIndex[companyId].connected;
                this.teamsConnected =
                    this.companyStore.teamsDataIndex[companyId] &&
                    this.companyStore.teamsDataIndex[companyId].connected &&
                    this.companyStore.teamsDataIndex[companyId].enabled === '1';

                if (copyFrom) {
                    let fromAssessment =
                        await services.Companies.assessmentsService(
                            companyId
                        ).fetch(copyFrom);

                    fromData = {
                        ...pick(
                            fromAssessment,
                            'templateIds',
                            'template_customized',
                            'sendOptions.channel',
                            'definition',
                            'sendOptions.scheduleType'
                        ),
                        name: fromAssessment.name + ' Copy',
                        due: null,
                        id: null,
                    };

                    this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                    if (isScorm) {
                        this.allowedSteps.replace([
                            ASSESSMENT_WIZARD_STEPS.GOAL,
                            ASSESSMENT_WIZARD_STEPS.CONTENT,
                            ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                            // ASSESSMENT_WIZARD_STEPS.INTROMESSAGE,
                            ASSESSMENT_WIZARD_STEPS.FINALIZE,
                        ]);
                    } else
                        this.allowedSteps.replace([
                            ASSESSMENT_WIZARD_STEPS.GOAL,
                            ASSESSMENT_WIZARD_STEPS.CONTENT,
                            ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                            ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                            ASSESSMENT_WIZARD_STEPS.SCHEDULE,
                            ASSESSMENT_WIZARD_STEPS.REMINDERS,
                            // ASSESSMENT_WIZARD_STEPS.INTROMESSAGE,
                            ASSESSMENT_WIZARD_STEPS.FINALIZE,
                        ]);
                    this.selectedTemplates = fromAssessment.templateIds;
                } else if (editId) {
                    fromData = await services.Companies.assessmentsService(
                        companyId
                    ).fetch(editId);

                    this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                    this.allowedSteps.replace([
                        ASSESSMENT_WIZARD_STEPS.GOAL,
                        ASSESSMENT_WIZARD_STEPS.CONTENT,
                        ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                        ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                        ASSESSMENT_WIZARD_STEPS.SCHEDULE,
                        ASSESSMENT_WIZARD_STEPS.REMINDERS,
                        // ASSESSMENT_WIZARD_STEPS.INTROMESSAGE,
                        ASSESSMENT_WIZARD_STEPS.FINALIZE,
                    ]);
                    this.selectedTemplates = fromData.templateIds;
                } else {
                    this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                    this.allowedSteps.replace([ASSESSMENT_WIZARD_STEPS.GOAL]);
                }

                this.assessment = new Assessment(
                    this.listStore,
                    this.authStore,
                    this.mainStore,
                    this.questionsIndex,
                    fromData,
                    companyId
                );
                this.assessment.isScorm = isScorm;
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.loadingCompanyData = false;
            });
        }
    }

    loadLatestTenAssessments = generateLoadList(
        'loadingAssessments',
        this,
        'loadingAssessments',
        (companyId) => {
            return services.Companies.assessmentsService(
                companyId
            ).listLatestTen();
        },
        'latestTenAssessments',
        (result, companyId) =>
            result.map(
                (x) =>
                    new Assessment(
                        null,
                        null,
                        this.mainStore,
                        null,
                        x,
                        companyId
                    )
            )
    );

    // d = show drafts (boolean), p = page number, n = number of rows per page
    loadAssessments = generateLoadList(
        'loadingAssessments',
        this,
        'loadingAssessments',
        (companyId, d, p, n) => {
            return services.Companies.assessmentsService(companyId).list({
                d,
                p,
                n,
            });
        },
        'assessments',
        (result, companyId) =>
            result.map(
                (x) =>
                    new Assessment(
                        null,
                        null,
                        this.mainStore,
                        null,
                        x,
                        companyId
                    )
            )
    );

    loadUserAssessments = generateLoadList(
        'loadUserAssessments',
        this,
        'loadingUserAssessments',
        (companyId, email) => {
            return (
                email &&
                services.Companies.assessmentsService(
                    companyId
                ).userAssessments(email)
            );
        },
        'userAssessments',
        (result, companyId) =>
            result.map(
                (x) =>
                    new Assessment(
                        null,
                        null,
                        this.mainStore,
                        null,
                        x,
                        companyId
                    )
            )
    );

    get usersLatestAssessment() {
        if (!this.userAssessments || this.userAssessments < 1) return null;

        const ordered = this.userAssessments
            .slice()
            .sort(
                (a, b) =>
                    new Date(b._rawData.completed || 0) -
                    new Date(a._rawData.completed || 0)
            );
        return ordered[0];
    }

    loadAssessment = generateLoadEntity(
        'loadAssessment',
        this,
        'loadingAssessment',
        async (companyId, assessmentId) => {
            await this.listStore.loadCompanyLists(companyId);
            const result = await services.Companies.assessmentsService(
                companyId
            ).fetch(assessmentId);

            this.commonStore.analyticsEvent(
                'Assessment',
                'load',
                `company-${companyId}-automation-${assessmentId}`
            );
            return result;
        },
        'editingAssessment',
        null,
        (result, companyId) => {
            return new Assessment(
                this.listStore,
                this.authStore,
                this.mainStore,
                this.questionsIndex,
                result,
                companyId
            );
        }
    );

    loadRiskInsights = generateLoadList(
        'riskInsights',
        this,
        'loadingRiskInsights',
        (companyId, assessmentId) => {
            return services.Companies.assessmentsService(
                companyId
            ).riskInsights(assessmentId);
        },
        'riskInsights'
    );

    loadListScoresPerThreatArea = generateLoadList(
        'listScoresPerThreatArea',
        this,
        'loadingListScoresPerThreatArea',
        (companyId, assessmentId) => {
            return services.Companies.assessmentsService(
                companyId
            ).listScoresPerThreatArea(assessmentId);
        },
        'listScoresPerThreatArea'
    );

    updateSummary(data) {
        this.infosecSummary = data;
    }

    loadInfosecSummary = generateLoadEntity(
        'infosecSummary',
        this,
        'loadingInfosecSummary',
        () => {
            return services.Infosec.summary();
        },
        'infosecSummary'
    );
    loadThreatAreaDefinitions = generateLoadList(
        'threatAreaDefinitions',
        this,
        'loadingThreatAreaDefinitions',
        (companyId) => {
            return services.Companies.assessmentsService(
                companyId
            ).getThreatAreaDefinitions();
        },
        'threatAreaDefinitions',
        null,
        { shouldLoad: () => this.threatAreaDefinitions.length === 0 }
    );

    loadThreatAreaDetail = generateLoadEntity(
        'threatAreaDetail',
        this,
        'loadingThreatAreaDetail',
        (companyId, assessmentId, area, listId) => {
            return services.Companies.assessmentsService(
                companyId
            ).threatAreaDetail(assessmentId, area, listId);
        },
        'threatAreaDetail'
    );

    loadAssessmentEmployeesReport = generateLoadList(
        'employeesReport',
        this,
        'loadingEmployeesReportList',
        (companyId, assessmentId) => {
            return services.Companies.assessmentsService(
                companyId
            ).employeesReportList(assessmentId);
        },
        'employeesReportList'
    );

    loadAssessmentQuestionsReport = generateLoadList(
        'questionsReportList',
        this,
        'loadingQuestionsReportList',
        (companyId, assessmentId, filters) => {
            return services.Companies.assessmentsService(
                companyId
            ).questionsReportList(assessmentId, filters);
        },
        'questionsReportList'
    );

    loadThreatAreasList = generateLoadList(
        'threatAreasList',
        this,
        'loadingThreatAreasList',
        (companyId, assessmentId, listId) => {
            return services.Companies.assessmentsService(
                companyId
            ).threatAreasList(assessmentId, {
                listId,
            });
        },
        'threatAreasList'
    );
    loadKeyBehavioursList = generateLoadList(
        'keyBehavioursList',
        this,
        'loadingKeyBehavioursList',
        (companyId, assessmentId, threatArea, listId, riskScoreBase) => {
            return services.Companies.assessmentsService(
                companyId
            ).keyBehavioursList(assessmentId, {
                threatArea,
                listId,
                riskScoreBase,
            });
        },
        'keyBehavioursList'
    );

    delete = generateDeleteEntity(
        'deleteAssessment',
        this,
        'deletingAssessment',
        async (companyId, assessmentId) => {
            await services.Companies.assessmentsService(companyId).delete(
                assessmentId
            );
            this.editingAssessment = null;
            this.assessment = null;
            this.assessments.replace(
                this.assessments.filter((x) => x.id !== assessmentId)
            );
            return true;
        }
    );

    async startAssessment(companyId, extraData) {
        if (this.creatingAssessment) return;
        this.creatingAssessment = true;

        try {
            let result;

            const dto = this.assessment.toDTO({
                ...extraData,
                templateIds: this.selectedTemplates,
            });
            if (dto.scheduleType === -1) {
                dto.status = 2;
            }
            if (this.assessment.id) {
                result = await services.Companies.assessmentsService(
                    companyId
                ).update(dto);
            } else {
                result = await services.Companies.assessmentsService(
                    companyId
                ).create(dto);

                // await intentionally omitted
                this.sendAssessmentCreatedEvent(result.assessment.id);
            }
            this.commonStore.analyticsEvent(
                'assessment',
                'create',
                `company-${companyId}`
            );
            return result.assessment;
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.creatingAssessment = false;
            });
        }
    }

    async sendAssessmentCreatedEvent(assessmentId) {
        const today = new Date();
        const selectedQuestionIds = [...this.assessment.selectedQuestionsIds];
        const selectedQuestions = this.questions.filter((q) =>
            selectedQuestionIds.includes(q.id)
        );
        const threatAreas = new Set(selectedQuestions.map((q) => q.area));

        await this.commonStore.saveAnalyticsEvent('assessment', 'created', {
            assessmentId,
            questionSetName: (() => {
                const selected =
                    this.selectedTemplates.length > 0
                        ? this.selectedTemplates[0]
                        : null;
                if (!selected) return '';
                const template = this.templates.find((t) => t.id === selected);
                return template ? template.title : '';
            })(),
            questionSetId:
                this.selectedTemplates.length > 0
                    ? `${this.selectedTemplates[0]}`
                    : '',
            totalQuestions: selectedQuestionIds.length,
            questionIds: selectedQuestionIds,
            totalThreatAreas: threatAreas.size,
            threatAreaNames: Array.from(threatAreas),
            deliveryType: translateAssessmentScheduleType(
                this.assessment.scheduleType
            ),
            deliveryChannel: getDeliveryChannels(this.assessment.channel),
            totalReminders: this.assessment.reminders_steps.filter(
                (r) => r.send !== null
            ).length,
            totalEmployees: this.assessment.selectedUserCount,
            employeeListIds: [...this.assessment.selectedUserListsIds],
            startDate: this.assessment.date
                ? this.assessment.date.toISOString()
                : today.toISOString(),
            endDate: this.assessment.due.toISOString(),
        });
    }

    async saveDraft(companyId, extraData) {
        if (this.savingDraft) return;
        this.savingDraft = true;

        try {
            if (this.assessment.id) {
                await services.Companies.assessmentsService(
                    companyId
                ).updateDraft(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
            } else {
                let result = await services.Companies.assessmentsService(
                    companyId
                ).saveDraft(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
                runInAction(() => {
                    if (result.success)
                        this.assessment.setId(result.assessment.id);
                });
            }

            return true;
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.savingDraft = false;
            });
        }
    }

    async pauseAssessment(companyId, assessmentId) {
        if (this.updatingAssessment) return;
        this.updatingAssessment = true;

        try {
            await services.Companies.assessmentsService(
                companyId
            ).pauseAssessment(assessmentId);
            runInAction(() => {
                let respectiveAssessment = this.assessments.find(
                    (x) => x.id === assessmentId
                );
                respectiveAssessment.status = STATUS.DRAFT;
            });
            return true;
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.updatingAssessment = false;
            });
        }
    }

    updatePersonalizeForm() {
        if (!this.personalizeForm) this.initPersonalizeForm();
        else
            this.personalizeForm.updateFields(
                this.assessment.personalizeData,
                this.assessment.personalizedKeys
            );
    }

    initPersonalizeForm() {
        let personalizeForm = new PersonalizeFormDef(
            this.assessment.personalizeData,
            this.assessment.personalizedKeys,
            {}
        );

        reaction(
            () => {
                return this.assessment;
            },
            (assessment) => {
                if (assessment) {
                    let data = this.assessment.personalizeData || {};
                    personalizeForm.update(data);
                    personalizeForm.validate();
                }
            }
        );

        this.assessment.personalizedKeys.map((pk) =>
            personalizeForm.observe({
                path: pk,
                key: 'isValid',
                call: () => {
                    this.toggleRecipientsStep(personalizeForm);
                    if (this.isScorm) this.toggleFinalStep();
                },
            })
        );

        this.personalizeForm = personalizeForm;
    }

    get questionsIndex() {
        return keyBy(this.questions, 'question_id');
    }

    get riskInsightsSorted() {
        return this.riskInsights
            .map((risk) => {
                const number = () => {
                    if (risk.type === 'THREAT_AREA') return 0;
                    if (risk.type === 'KEY_BEHAVIOUR') return 1;
                    if (risk.type === 'EMPLOYEE_LIST') return 2;
                    if (risk.type === 'QUESTIONS') return 3;
                };
                return {
                    ...risk,
                    number: number(),
                };
            })
            .sort((a, b) => a.number - b.number);
    }

    get threatAreaDetailChartValues() {
        return (
            this.editingAssessment &&
            this.threatAreaDetail &&
            Object.values(this.threatAreaDetail.values).map((x) => ({
                ...x,
                employeePercent: (
                    (x.value / this.editingAssessment._rawData.usersFinished) *
                    100
                ).toFixed(1),
            }))
        );
    }

    get maxByAreaAndBehaviourForAllQuestions() {
        let maxByAreaAndBehaviour = {};
        this.questions.forEach((q) => {
            if (q.maxScoresByBehaviour) {
                Object.keys(q.maxScoresByBehaviour).forEach((b) => {
                    if (!maxByAreaAndBehaviour[`${q.area}-${b}`])
                        maxByAreaAndBehaviour[`${q.area}-${b}`] = 0;
                    maxByAreaAndBehaviour[`${q.area}-${b}`] +=
                        q.maxScoresByBehaviour[b];
                });
            }
        });
        return maxByAreaAndBehaviour;
    }

    async downloadCSV(companyId, assessmentId) {
        if (this.isCSVDownloading) return true;
        this.isCSVDownloading = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response;

            response = await services.Companies.assessmentsService(
                companyId
            ).answersReport(
                assessmentId,
                {
                    csv: true,
                },
                options
            );

            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'answers.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } finally {
            this.isCSVDownloading = false;
        }
    }

    async rename(companyId, assessmentId, data) {
        if (this.updatingAssessment) return;
        this.updatingAssessment = true;
        try {
            await services.Companies.assessmentsService(
                companyId
            ).renameAssessment(assessmentId, data);
            this.editingAssessment.name = data.name;
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAssessment = false;
        }
    }

    updateDue = generateUpdateEntity(
        'updateDue',
        this,
        'updatingDue',
        async (companyId, assessmentId, due) => {
            await services.Companies.assessmentsService(companyId).updateDue(
                assessmentId,
                { due }
            );
            return true;
        }
    );

    get demoAssessmentURL() {
        if (!this.mainStore.currentCompany || !this.authStore.currentUser)
            return null;
        const reportTo = `"${this.authStore.currentUser.name}" <${this.authStore.currentUser.email}>`;
        return `${this.brandingStore.myDomain}/assessment/try-demo/${
            this.mainStore.currentCompany.company_uid
        }/${Buffer.from(reportTo, 'utf8').toString('base64')}`;
    }
}

export default AssessmentStore;
