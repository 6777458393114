import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import CompanyAppearanceEditor from './CompanyAppearanceEditor';
import EditLogo from './edit-logo';
import EditColor from './edit-color';
import {
    TextLead,
    TextDefault,
    BigModal,
    ThemedButton,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../themed/ThemedSpinner';

function CustomizeAppearance({
    company,
    saveColor,
    resetToDefaults,
    importLogo,
    removeLogo,
    uploadingLogo,
}) {
    const [showEditLogoModal, setShowEditLogoModal] = useState(false);
    const [showEditColorModal, setShowEditColorModal] = useState(false);

    const { commonStore, store } = useStores();

    const { primaryColor } = store;

    const doSaveColor = () => {
        setShowEditColorModal(false);
        saveColor(primaryColor);
    };

    const doResetToDefaults = async () => {
        const result = await resetToDefaults();
        if (result) commonStore.success('Appearance has been reset!');
    };

    const showResetToDefaultsModal = () => {
        commonStore.showConfirm(
            <TextLead>
                You will lose all entered data and we will not be able to
                recover it.
            </TextLead>,
            'Yes, reset',
            'Reset company appearance to defaults',
            doResetToDefaults,
            true
        );
    };

    return !company ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <CompanyAppearanceEditor
                company={company}
                onEditLogo={() => setShowEditLogoModal(true)}
                onEditColor={() => setShowEditColorModal(true)}
                onResetToDefaults={() => showResetToDefaultsModal()}
            />
            <BigModal
                isOpen={showEditLogoModal}
                onRequestClose={() => setShowEditLogoModal(false)}
                heading="Change logo"
                modalContent={
                    <EditLogo
                        company={company}
                        companyId={company.company_id}
                        onSaved={() => setShowEditLogoModal(false)}
                        onRequestClose={() => setShowEditLogoModal(false)}
                        importLogo={importLogo}
                        removeLogo={removeLogo}
                        uploadingLogo={uploadingLogo}
                    />
                }
                modalFooterContent={
                    uploadingLogo ? (
                        <ThemedSpinner />
                    ) : (
                        <ThemedButton
                            mode="primary"
                            text="Done"
                            $airy
                            onClick={() => setShowEditLogoModal(false)}
                        />
                    )
                }
            />
            <BigModal
                isOpen={showEditColorModal}
                onRequestClose={() => setShowEditColorModal(false)}
                heading="Change color"
                modalContent={
                    <EditColor
                        primaryColor={primaryColor}
                        setPrimaryColor={store.setPrimaryColor}
                    />
                }
                modalFooterContent={
                    <Fragment>
                        <TextDefault
                            uppercase
                            extraBold
                            link
                            onClick={() => setShowEditColorModal(false)}
                        >
                            Cancel
                        </TextDefault>
                        <ThemedButton
                            mode="primary"
                            text="Save"
                            onClick={() => doSaveColor()}
                        />
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default observer(CustomizeAppearance);
