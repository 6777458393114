import React, { createRef, useEffect, useMemo, useCallback } from 'react';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import ThemedSpinner from '../../themed/ThemedSpinner';
import { useNavigate, useLocation } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import {
    palette,
    FlexDiv,
    Heading4,
    StatusBadge,
    TextDefault,
    LightTooltip,
    TextLabel,
} from '@awarego/awarego-components';
import { rgba } from 'polished';
import CalendarWithDots from '../wizard/delivery-calendar/calendar-with-dots';
import ScheduleHeading from '../wizard/delivery-calendar/schedule-heading';
import EventDate from '../wizard/delivery-calendar/event-date';
import styled, { css } from 'styled-components';

const EventRow = styled(FlexDiv)`
    ${(props) =>
        props.lighter &&
        css`
            opacity: 0.6;
        `}
`;

function DashboardCalendar({
    displayedEvents,
    onDayChange,
    setSelectedMonth,
    selectedDate,
    daysWithDot,
    isLoading,
}) {
    const navigate = useNavigate();
    const location = useLocation();

    let refs = useMemo(
        () =>
            displayedEvents.reduce((acc, value) => {
                acc[value.id] = createRef();
                return acc;
            }, {}),
        [displayedEvents]
    );

    useEffect(() => {
        !Object.prototype.hasOwnProperty.call(
            refs,
            moment(selectedDate).format('YYYY-MM-DD')
        ) && (refs[moment(selectedDate).format('YYYY-MM-DD')] = createRef());

        let current =
            refs[`${moment(selectedDate).format('YYYY-MM-DD')}`].current;
        if (current)
            current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
    }, [refs, selectedDate]);

    const groupedEventsByDay = useMemo(
        () =>
            groupBy(displayedEvents, (e) =>
                moment(e.date).format('YYYY-MM-DD')
            ),
        [displayedEvents]
    );

    const getEventDescription = useCallback(
        (e) =>
            e.name !== 'No events scheduled' ? (
                <FlexDiv column $noWrap pl={8}>
                    <Heading4
                        bold
                        mb={4}
                        displayBlock
                        link
                        onClick={() => {
                            navigate(
                                `${
                                    e.type === 'training'
                                        ? 'trainings'
                                        : 'assessment'
                                }/${e.eventId}/view`
                            );
                        }}
                    >
                        {e.name.length > 28 ? (
                            <LightTooltip title={e.name}>
                                <span>
                                    {e.name.substring(0, 28)}
                                    ...
                                </span>
                            </LightTooltip>
                        ) : (
                            e.name
                        )}
                    </Heading4>
                    <FlexDiv gap={4} alignCenter>
                        <StatusBadge
                            color={rgba(palette.graphite.heavyMetal, 0.6)}
                            text={capitalize(e.type)}
                            transparent
                        />
                        <TextLabel lighter>
                            {moment(e.date).format('h:mma')}{' '}
                            {/*moment
                                                            .tz(
                                                                moment.tz.guess()
                                                            )
                                                            .format(' z')*/}
                            ({e.status})
                        </TextLabel>
                    </FlexDiv>
                </FlexDiv>
            ) : (
                <TextDefault ml={8} lighter>
                    {e.name}
                </TextDefault>
            ),
        [location]
    );

    const scheduledEvents = useCallback(
        () => (
            <FlexDiv
                overflowYauto
                hideScrollbar
                maxHeight={304}
                column
                $noWrap
                padding={16}
                fullWidth
            >
                {Object.entries(groupedEventsByDay).map(([key, value]) =>
                    value.map((e, j) => (
                        <EventRow
                            key={j}
                            ref={refs[key]}
                            py={4}
                            mb={8}
                            alignCenter
                            $noWrap
                            fullWith
                            lighter={moment(key).isBefore(moment(), 'day')}
                        >
                            {j !== 0 ? (
                                <FlexDiv width={56} />
                            ) : (
                                <EventDate e={e} selectedDate={selectedDate} />
                            )}
                            {getEventDescription(e)}
                        </EventRow>
                    ))
                )}
            </FlexDiv>
        ),
        [getEventDescription, groupedEventsByDay, refs, selectedDate]
    );

    const scheduleContent = useMemo(() => {
        if (isLoading) return <ThemedSpinner />;
        if (daysWithDot.length > 0) return scheduledEvents();
        return (
            <TextDefault lighter center mx={16} my={32}>
                You have nothing scheduled this month
            </TextDefault>
        );
    }, [daysWithDot.length, isLoading, scheduledEvents]);

    return (
        <FlexDiv
            column
            border
            maxWidth={328}
            alignCenter
            backgroundColor="white"
            mt={20}
            pt={20}
        >
            <CalendarWithDots
                setSelectedDate={onDayChange}
                setSelectedMonth={setSelectedMonth}
                daysWithDot={daysWithDot}
                isLoading={isLoading}
            />
            {daysWithDot.length > 0 && <ScheduleHeading />}
            {scheduleContent}
        </FlexDiv>
    );
}

export default DashboardCalendar;
